import {
    giphyBlack,
    giphyDarkCharcoal,
    giphyDarkestGrey,
    giphyLightCharcoal,
    giphyLightGrey,
    giphyWhite,
} from '@giphy/colors'
import { Button as BrandButton } from 'shared/components/buttons'
import { grid12 } from 'shared/util/grid'
import styled from 'styled-components'
import ChannelListItem from './add-to-collection/channel-list-item'

const otherGrey = `rgba(0, 0, 0, 0.25)`

export const Container = styled.div<{ height?: number; top?: number; marginLeft?: number; width?: number }>`
    position: absolute;
    top: ${(props) => `${props.top}px` || 'auto'};
    display: flex;
    flex-direction: column;
    background: ${giphyDarkestGrey};
    padding-left: 10px;
    padding-right: 10px;
    width: ${(props) => (props.width ? `${props.width}px` : '500px')};
    margin-left: ${(props) => `${props.marginLeft}px` || 'auto'};
    height: ${(props) => props.height || 390}px;
`

export const ScrollableContainer = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
`

export const DisableModal = styled.div`
    background: black;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
export const GifSelectorContainer = styled(Container)`
    width: ${grid12 + 60}px;
    height: 650px;
    max-width: inherit;
    max-height: inherit;
    padding-bottom: 0;
`

export const Copy = styled.div`
    font-family: 'interface', Helvetica Neue, helvetica, sans-serif;
    color: ${giphyLightGrey};
`

export const Label = styled(Copy)`
    font-family: 'interface', Helvetica Neue, helvetica, sans-serif;
    font-weight: 600;
    color: ${giphyLightGrey};
    font-size: 15px;
    margin-bottom: 4px;
`

export const Optional = styled(Label)`
    color: ${giphyDarkCharcoal};
    font-size: 12px;
`

export const Section = styled.div`
    margin-top: 16px;
`

export const Input = styled.input`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background: ${otherGrey};
    cursor: text;
    color: inherit;
    resize: none;
    -webkit-appearance: none;
    border-width: 0;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 9px 12px;
    position: relative;
    ::placeholder {
        color: ${giphyLightCharcoal};
    }
    color: ${giphyWhite};
`

export const NameField = styled(Input)`
    font-size: 17px;
    font-weight: bold;
    background: ${giphyBlack};
`

export const DescriptionField = styled(Input)`
    font-weight: bold;
    font-size: 15px;
    min-height: 115px;
    background: ${giphyBlack};
`

export const GifThumbnailDiv = styled.div`
    margin: auto;
    margin-top: 10px;
`

export const Thumbnail = styled(ChannelListItem)`
    cursor: auto;
    margin: 0;
`

export const AddMultipleButton = styled(BrandButton)`
    position: absolute;
    background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
    width: 400px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 3;
`
