import styled, { css } from 'styled-components'
import { SubheaderSmall } from '../typography'
import { distanceInWordsToNow, differenceInMonths, format } from 'date-fns'
import { giphyDarkestGrey, giphyWhite } from '../../css/colors'
import hexToRgba from 'hex-rgba'

const getCreationDate = (publishDateTime) => {
    const now = new Date()
    const publishDate = new Date(publishDateTime)
    const timeDiff = Math.abs(now - publishDate)

    // less than an hour show Just now
    if (timeDiff < 60 * 60 * 1000) {
        return 'Just now'
    }

    // if the date is longer than a month ago, just show the date
    if (differenceInMonths(publishDate, now) < -1) {
        return format(publishDate, 'MMM D, YYYY')
    }

    return distanceInWordsToNow(publishDateTime, { addSuffix: true }) // ex: about 1 hour ago
        .replace(/about /gi, '') // remove 'about'
        .replace(/ minutes?/gi, 'm') // 1m
        .replace(/ hours?/gi, 'h') // 1h
        .replace(/ days?/gi, 'd') // 1d
        .replace(/ months?/gi, 'mo') // 1mo
}

const regularCard = css`
    margin-top: 7px;
    margin-bottom: 1px;
`
// large card is for desktop
const largeCard = css`
    margin-top: 6px;
    font-size: 15px;
    margin-bottom: 4px;
`
// slim card is long and narrow
const slimCard = css`
    margin-top: 5px;
    margin-bottom: -1px;
`
const Text = styled(SubheaderSmall)`
    color: ${hexToRgba(giphyWhite, 75)};
    line-height: initial;
    user-select: none;
    ${({ theme: { isLargeCard, isSlimCard } }) => (isLargeCard ? largeCard : isSlimCard ? slimCard : regularCard)};
`

const Ad = styled(Text)`
    background-color: ${hexToRgba(giphyDarkestGrey, 75)};
    border-radius: 6px;
    display: inline-block;
    padding: 2px 10px;
    margin: 4px 0 -1px 0;
`

const Footer = ({ user, isAd, publishDateTime }) =>
    isAd ? (
        <Ad>Ad</Ad>
    ) : (
        <Text>
            {getCreationDate(publishDateTime)}
            {user ? ` by @${user.username}` : ''}
        </Text>
    )

export default Footer
