import { IGif } from '@giphy/js-types'
import { getAltText, getBestRenditionUrl, getSpecificRendition } from '@giphy/js-util'
import { pull } from 'lodash'
import { createRef, Fragment } from 'react'
import * as React from 'react'
import { InView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'

const documentVisibleCallbacks: (() => void)[] = []
let lastVisibilityState = document.visibilityState
document.addEventListener('visibilitychange', () => {
    const currentState = document.visibilityState
    if (lastVisibilityState !== currentState) {
        lastVisibilityState = currentState
        if (currentState === 'visible') {
            documentVisibleCallbacks.forEach((cb) => cb())
        }
    }
})

const mediaCss = css`
    display: block;
    left: 0;
    position: absolute;
    top: 0;
`
const ImageSC = styled.img<{ isSticker?: boolean }>`
    ${mediaCss};
    object-fit: ${(props) => (props.isSticker ? 'contain' : 'cover')};
`

// if we want to letter box, background: black and object-fit: contain
const VideoSC = styled.video`
    ${mediaCss};
    object-fit: cover;
`

const getRendition = (
    gif: IGif,
    isAnimating: boolean,
    width: number,
    height: number,
    rendition?: string,
    useVideo?: boolean
) =>
    rendition
        ? getSpecificRendition(gif, rendition, !isAnimating, useVideo)
        : getBestRenditionUrl(gif, width, height, { isStill: !isAnimating, useVideo })

type Props = {
    gif: IGif
    height: number
    width: number
    rendition: string
    isAnimating: boolean
    onLoad: (event: React.SyntheticEvent<HTMLElement, Event>) => void
    type: 'video' | 'gif' | 'sticker'
    onLoop?: (loopCount: number) => void
    loop: boolean
    onEnded?: () => void
}

type State = {
    src?: string
    alt?: string
}

class Media extends React.PureComponent<Props, State> {
    state: State = {}
    static getDerivedStateFromProps(
        { gif, height, width, rendition, isAnimating = true, type }: Props,
        prevState: State
    ) {
        const src = getRendition(gif, isAnimating || type === 'video', width, height, rendition, type === 'video')
        const alt = getAltText(gif)

        if (src !== prevState.src || alt !== prevState.alt) {
            return { src, alt }
        }
        return null
    }

    videoEl = createRef<HTMLVideoElement>()
    unmounted = false
    loopCount = 0
    isAd = false

    componentDidMount() {
        const { type } = this.props
        if (type === 'video') {
            documentVisibleCallbacks.push(this.onDocumentVisible)
        }
    }

    componentDidUpdate(lastProps: Props) {
        const { type, isAnimating, loop } = this.props
        if (lastProps.type !== type) {
            pull(documentVisibleCallbacks, this.onDocumentVisible)
            if (type === 'video') {
                documentVisibleCallbacks.push(this.onDocumentVisible)
            }
        }
        if (type === 'video') {
            if (lastProps.isAnimating !== isAnimating) {
                if (isAnimating) {
                    this.playVideo()
                } else {
                    this.pauseVideo()
                }
            }
            if (lastProps.loop !== loop && loop && isAnimating) {
                this.playVideo()
            }
        }
    }

    componentWillUnmount() {
        pull(documentVisibleCallbacks, this.onDocumentVisible)
    }

    playVideo() {
        const video = this.videoEl.current
        if (video) {
            video.play()
        }
    }

    pauseVideo() {
        const video = this.videoEl.current
        if (video) {
            video.pause()
        }
    }

    onDocumentVisible = () => this.playVideo()
    onEnded = () => {
        const { loop, onLoop, onEnded, isAnimating } = this.props

        // instead of the loop property, manual loop so we can fire onEnded
        if (loop && isAnimating) this.playVideo()

        // callback for ended
        if (onEnded) onEnded()

        this.loopCount++
        // maybe this should be called view count?
        if (onLoop) onLoop(this.loopCount)
    }

    render() {
        const { height, width, onLoad, type, isAnimating } = this.props
        const { src, alt } = this.state
        return (
            <InView>
                {({ inView, ref }) => {
                    return (
                        <div ref={ref} style={{ height, width }}>
                            {inView ? (
                                type === 'video' ? (
                                    <Fragment>
                                        <VideoSC
                                            autoPlay={isAnimating}
                                            height={height}
                                            muted
                                            onCanPlay={onLoad}
                                            onEnded={this.onEnded}
                                            onLoadedData={(e) => {
                                                onLoad && onLoad(e)
                                            }}
                                            playsInline
                                            preload="metadata"
                                            src={src}
                                            width={width}
                                        />
                                    </Fragment>
                                ) : (
                                    <ImageSC
                                        alt={alt}
                                        height={height}
                                        isSticker={type === 'sticker'}
                                        onLoad={(e) => {
                                            onLoad && onLoad(e)
                                        }}
                                        src={src}
                                        width={width}
                                    />
                                )
                            ) : null}
                        </div>
                    )
                }}
            </InView>
        )
    }
}

export default Media
