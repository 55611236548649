import { useDraggable } from '@dnd-kit/core'
import { uniqueId } from 'lodash'
import { useEffect, useRef } from 'react'
import { Range } from 'types'
import { Handle } from './style'

type Props = {
    className?: string
    controlColor: string
    controlId: string
    controlType: string
    controlValue: Range
    disabled?: boolean
    max: number
    min: number
    onDrag?: (x: number) => void
    onDragEnd?: () => void
}

const RangeSliderDndHandle = ({
    className,
    controlColor,
    controlId,
    controlType,
    controlValue,
    disabled,
    max,
    min,
    onDrag,
    onDragEnd,
}: Props) => {
    const idRef = useRef<string>(`${controlId}-${uniqueId('handle')}`)
    const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
        disabled,
        id: idRef.current,
        data: { control: 'range-slider', controlId, controlType, controlValue },
    })

    useEffect(() => {
        if (!isDragging) {
            onDragEnd?.()
        }
    }, [isDragging])

    useEffect(() => {
        if (typeof transform?.x === 'number') {
            const newValue = Math.min(max, Math.max(min, transform.x))
            onDrag?.(newValue)
        }
    }, [transform?.x])

    return <Handle {...listeners} {...attributes} $color={controlColor} className={className} ref={setNodeRef} />
}

export default RangeSliderDndHandle
