import * as colors from '@giphy/colors'
import { giphyWhiteSmoke } from '@giphy/colors'
import { messagesZIndex } from 'shared/css/z-indexes'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'

export const SearchBarDropDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.giphyWhite};
    color: ${colors.giphyBlack};
    text-align: left;
    line-height: 40px;
    z-index: ${messagesZIndex};
    font-size: 16px;

    h5 {
        padding: 0 12px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 14px;
        color: ${colors.giphyLightGrey};
    }
    ${mobile.css`
        background-color: ${colors.giphyBlack};
        color: ${colors.giphyWhite};
        margin-top: 4px;
    `}
`

export const DividingHeader = styled.h5`
    background-color: ${giphyWhiteSmoke};
    color: ${colors.giphyLightGrey};
    line-height: 30px;
    ${mobile.css`
        background-color: ${colors.giphyBlack};
    `}
`

export const CarouselContainer = styled.div`
    margin-top: 20px;
`

export const TrendingSearchHeader = styled.h5<{ isHome: boolean }>`
    line-height: 14px;
    margin-top: ${({ isHome }) => (isHome ? 18 : 12)}px;
    margin-bottom: 10px;
`
