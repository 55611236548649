import * as s from './style'
import { useState } from 'react'
import HeaderAnimation from './index'

const ReportTwentyTwo = () => {
    const [secondSlide, setSecondSlide] = useState(true)
    return (
        <>
            <s.HeroSection>
                <HeaderAnimation />

                <h1>GIPHY Transparency Report</h1>
                <p>
                    GIPHY&apos;s annual transparency reports provide metrics around government and non-government
                    requests for user data as well as enforcement measures we have taken on content on our platform
                    between January 1st 2022 and December 31st 2022. GIPHY is committed to respecting the
                    internationally recognized human rights guidelines set out in the United Nations Guiding Principles
                    on Business and Human Rights (UNGPs), privacy, and free expression. Individuals can appeal if they
                    believe no violation has occurred.
                </p>
                <s.AnchorLinkContainer wide>
                    <ul>
                        <li>
                            <a href="#removals">Content Removals for Prohibited Content</a>
                        </li>
                        <li>
                            <a href="#dmca">DMCA Requests and Takedowns</a>
                        </li>
                        <li>
                            <a href="#preservation">Preservation Requests</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="#emergency-disclosure-requests">Emergency Disclosure</a>
                        </li>
                        <li>
                            <a href="#information-requests">Government Information Requests</a>
                        </li>
                        <li>
                            <a href="#civil-requests">Civil Requests</a>
                        </li>
                    </ul>
                    <s.GlassPeeker src="/static/img/transparency-report/glass_peeker.gif" />
                </s.AnchorLinkContainer>
            </s.HeroSection>
            <s.FullWidthContainer id="removals">
                <s.CenteredSection>
                    <s.SlideshowPane>
                        <s.Slideshow>
                            <s.SlideContainer whichSlide={secondSlide}>
                                <s.Slide>
                                    <img src="/static/img/transparency-report/2022-graph-1.png" />
                                </s.Slide>
                                <s.Slide>
                                    <s.PieChart src="/static/img/transparency-report/2022-piechart.png" />
                                </s.Slide>
                            </s.SlideContainer>
                            <s.SlideIndicatorContainer>
                                <s.SlideIndicator active={!secondSlide} />
                                <s.SlideIndicator active={secondSlide} />
                            </s.SlideIndicatorContainer>
                        </s.Slideshow>

                        <s.LeftControl onClick={() => setSecondSlide(!secondSlide)} />
                        <s.RightControl onClick={() => setSecondSlide(!secondSlide)} />
                    </s.SlideshowPane>
                    <s.Pane>
                        <h2>Content Removals for Prohibited Content</h2>
                        <p>
                            The following section displays the total amount of content removed from the GIPHY platform,
                            along with the corresponding policy violation category. GIPHY identifies content that
                            violates its Community Guidelines with both human moderators and machine learning models. In
                            addition, Community members are encouraged to use the tools provided to report any content
                            they believe violates the guidelines, which is then reactively reviewed by human moderators.
                            GIPHY is committed to respecting the internationally recognized human rights guidelines set
                            out in the United Nations Guiding Principles on Business and Human Rights (UNGPs), privacy,
                            and free expression. Individuals can appeal if they believe no violation has occurred.
                        </p>
                        <p>
                            For more information, please review{' '}
                            <a href="https://support.giphy.com/hc/en-us/articles/360020287371-Report-An-Inappropriate-GIF-">
                                how to report an inappropriate Gif
                            </a>
                            .{' '}
                        </p>
                    </s.Pane>
                </s.CenteredSection>
            </s.FullWidthContainer>
            <s.FullWidthContainer id="dmca">
                <s.CenteredSection>
                    <s.Pane>
                        <h2>DMCA Requests and Takedowns</h2>
                        <p>
                            DMCA stands for Digital Millennium Copyright Act. DMCA requests refers to notices requesting
                            the removal of content that infringes on a copyright sent by or on behalf of the copyright
                            owner. When GIPHY receives a complete and valid copyright notice, we comply with the notice
                            and send a counter notice when appropriate.
                        </p>
                        <p>
                            For more information, please review the{' '}
                            <a href="https://support.giphy.com/hc/en-us/articles/360020287091-GIPHY-DMCA-Copyright-Policy">
                                GIPHY DMCA Copyright Policy
                            </a>
                            .
                        </p>
                    </s.Pane>
                    <s.Pane horizontal>
                        <s.Dot>
                            <s.SmallInnerText>Total Requested:</s.SmallInnerText>
                            <s.LargeInnerText>293</s.LargeInnerText>
                        </s.Dot>
                        <s.RedDot>
                            <s.SmallInnerText>Total Removed:</s.SmallInnerText>
                            <s.LargeInnerText>293</s.LargeInnerText>
                        </s.RedDot>
                    </s.Pane>
                </s.CenteredSection>
            </s.FullWidthContainer>
            <s.FullWidthContainer short id="preservation">
                <s.CenteredSection>
                    <s.Pane horizontal>
                        <s.LockImage src="/static/img/transparency-report/lock.gif" />
                    </s.Pane>
                    <s.Pane>
                        <h2>Preservation Requests</h2>
                        <p>
                            Preservation requests refers to government and law enforcement requests that require GIPHY
                            to preserve account information pending the issuance of valid legal process to obtain that
                            user data. In accordance with the law, GIPHY will temporarily preserve such data and will
                            disclose the preserved records when we receive formal and valid legal process.
                        </p>

                        <p>
                            <strong>Total Requested: 0</strong>
                        </p>
                    </s.Pane>
                </s.CenteredSection>
            </s.FullWidthContainer>
            <s.FullWidthContainer id="information-requests">
                <s.CenteredSection>
                    <s.Pane>
                        <h2>Government Information Requests</h2>
                        <p>
                            Government information requests refers to demands for account information issued by law
                            enforcement and other government agencies. GIPHY reviews requests to ensure that the
                            requests have a valid legal basis. If they do, we comply by providing data responsive to the
                            request.
                        </p>
                    </s.Pane>
                    <s.Pane top>
                        <s.ReportImage src="/static/img/transparency-report/2022-graph-2.png" />
                    </s.Pane>
                </s.CenteredSection>
            </s.FullWidthContainer>
            <s.FullWidthContainer short id="emergency-disclosure-requests">
                <s.CenteredSection>
                    <s.Pane horizontal>
                        <s.BlueDot>
                            <s.SmallInnerText>Total:</s.SmallInnerText>
                            <s.LargeInnerText>1</s.LargeInnerText>
                        </s.BlueDot>
                    </s.Pane>
                    <s.Pane>
                        <h2>Emergency Disclosures</h2>
                        <p>
                            We may voluntarily disclose information to law enforcement when the matter poses a credible
                            threat to human life
                        </p>
                    </s.Pane>
                </s.CenteredSection>
            </s.FullWidthContainer>
            <s.FullWidthContainer id="civil-requests">
                <s.CenteredSection>
                    <s.Pane>
                        <s.CivilRequestsHeader>Civil Requests</s.CivilRequestsHeader>
                        <p>
                            Civil requests refer to non-government requests (e.g., a subpoena or court order) submitted
                            by users, and third parties seeking account information from GIPHY on behalf of a defendant
                            in a criminal case. Non-government requests may include requests made by government entities
                            as a party in civil litigation.
                        </p>
                        <p></p>
                        <p>
                            <strong>GIPHY did not have any Civil requests in 2022.</strong>
                        </p>
                    </s.Pane>
                    <s.Pane horizontal>
                        <s.BackgroundBox src="/static/img/transparency-report/dog-background.png" />
                        <s.DogImg src="/static/img/transparency-report/judge.gif" />
                    </s.Pane>
                </s.CenteredSection>
            </s.FullWidthContainer>
        </>
    )
}

export default ReportTwentyTwo
