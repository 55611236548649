import styled from 'styled-components'
import HoverItem from '../info/hover-item'
import { ValueContentDescription } from './sc'

const StyledHoverItem = styled(HoverItem)`
    font-weight: bold;
`

export const valuesList = [
    {
        name: 'Alpha (0,0,0,0)',
        image: 'Alpha.gif',
        gradient: 'linear-gradient(-135deg, rgb(0, 255, 153) 0%, rgb(0, 204, 255) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY is <StyledHoverItem hoverId={'transparent'}>transparent</StyledHoverItem>. We embrace the why and
                the freedom of information act.
            </ValueContentDescription>
        ),
        linkText: 'Transparent',
        link: '/stickers',
    },
    {
        name: 'Animated',
        image: 'Animated.gif',
        gradient: 'linear-gradient(45deg, rgb(97, 87, 255) 0%, rgb(0, 204, 255) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY is <StyledHoverItem hoverId={'animated'}>alive</StyledHoverItem>. We bring things to life. We only
                work with and build things that move.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Balance',
        image: 'Balance.gif',
        gradient: 'linear-gradient(45deg, rgb(97, 87, 255) 0%, rgb(230, 70, 182) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY is <StyledHoverItem hoverId={'balance'}>fair</StyledHoverItem>. We believe the world, the
                internet, the company, everything should be balanced. We strive to be a diverse group of weirdos who
                create things for everyone.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Diversity',
        image: 'Diversity.gif',
        gradient: 'linear-gradient(45deg, rgb(255, 102, 102) 0%, rgb(255, 243, 92) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY is a diverse community of people. We believe in investing in diversity of thought and experiences
                within our team to help us build products and make decisions that best serve GIPHY users around the
                world.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Experimental',
        image: 'Experimental.gif',
        gradient: 'linear-gradient(45deg, rgb(97, 87, 255) 0%, rgb(0, 204, 255) 100%);',
        text: (
            <ValueContentDescription>
                There are no rules (okay, maybe a few). GIPHY strives to do and make things that have never been, that
                should be.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Expressive',
        image: 'Expressive.gif',
        gradient: 'linear-gradient(-135deg, rgb(255, 102, 102) 0%, rgb(230, 70, 182) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY <StyledHoverItem hoverId={'expressive'}>feels</StyledHoverItem>. We are sensitive. We laugh. We
                cry. We are creators who use expression as our medium, and everything we build is an expression of who
                we are and what we believe.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Friends',
        image: 'Friends.gif',
        gradient: 'linear-gradient(-135deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY is more than casual friends. We <StyledHoverItem hoverId={'friends'}>hug</StyledHoverItem>. Our
                products hug. We make the world a friendlier place.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Help',
        image: 'Help.gif',
        gradient: 'linear-gradient(-135deg, rgb(0, 255, 153) 0%, rgb(0, 204, 255) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY <StyledHoverItem hoverId={'help'}>helps</StyledHoverItem>. We always offer friendly help to anyone
                who needs it.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Inclusion',
        image: 'Inclusion.gif',
        gradient: 'linear-gradient(-135deg, rgb(255, 243, 92) 0%, rgb(153, 51, 255) 100%);',
        text: (
            <ValueContentDescription>
                We aim to build <StyledHoverItem hoverId={'inclusion'}>inclusive</StyledHoverItem> communities,
                products, and environments where everyone is seen, heard, and valued. GIPHY should be a safe, open space
                where everyone is welcome to share and succeed.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Infinite',
        image: 'Infinite.gif',
        gradient: 'linear-gradient(45deg, rgb(255, 102, 102) 0%, rgb(255, 243, 92) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY never stops. Never stops building, rebuilding, rebuilding, laughing, looping, being who we are.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Love',
        image: 'Love.gif',
        gradient: 'linear-gradient(-135deg, rgb(255, 102, 102) 0%, rgb(230, 70, 182) 100%);',
        text: (
            <ValueContentDescription>
                We <StyledHoverItem hoverId={'love'}>love</StyledHoverItem> the people we work with. We only build
                things that we love. We work and build with passion.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Weird',
        image: 'Weird.gif',
        gradient: 'linear-gradient(45deg, rgb(97, 87, 255) 0%, rgb(230, 70, 182) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY is <StyledHoverItem hoverId={'weird'}>weird</StyledHoverItem>. But in that good weird kind of way.
                We make weird things that people love. The weirder the better.
            </ValueContentDescription>
        ),
    },
    {
        name: 'Witty',
        image: 'Witty.gif',
        gradient: 'linear-gradient(-135deg, rgb(0, 204, 255) 0%, rgb(153, 51, 255) 100%);',
        text: (
            <ValueContentDescription>
                GIPHY has a sense of <StyledHoverItem hoverId={'witty'}>humor</StyledHoverItem>. We’re fun and playful
                but with a post-intellectual laugh.
            </ValueContentDescription>
        ),
    },
]
