import * as colors from '@giphy/colors'
import Media from 'react-media'
import BrandTooltip from 'shared/components/ui/tooltip'
import { desktop, mobile } from 'shared/util/media-query'
import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${desktop.css`
        justify-content: flex-end;
    `};
`
const Label = styled.div`
    color: ${colors.giphyLightestGrey};
    display: flex;
    align-items: center;
    ${desktop.css`
        flex-direction: column;
        align-items: flex-end;
    `};
`

const LabelWithTag = styled.div`
    color: ${colors.giphyLightestGrey};
    display: flex;
    flex-direction: row;
    align-items: center;
`

const LabelDiv = styled.div<{ width: number | 'auto' }>`
    width: ${(props) => (props.width === 'auto' ? 'auto' : `${props.width}px`)};
`

const gradientSlide = keyframes`
    0% {background-position: 0%;}
    100% {background-position: -300%;}
`

const NewPill = styled.div`
    background: linear-gradient(to right, rgb(49, 145, 255), rgb(0, 255, 153), rgb(49, 145, 255)) 0% 100% / 300%;
    animation: ${gradientSlide} 5s linear 0s infinite;
    border-radius: 3.35px;
    height: 21px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    & h4 {
        font-size: 10px;
        font-weight: bolder;
        color: ${colors.giphyBlack};
    }
`

export const Optional = styled.div`
    font-weight: normal;
`

const Tooltip = styled(BrandTooltip)`
    font-size: 11px;
    padding: 5px;
    margin-top: 3px;
    ${(props) =>
        props.optional &&
        css`
            display: flex;
            align-self: flex-start;
            margin-top: 2px;
        `};
`

type Props = {
    label?: string
    tooltip?: string
    optional?: boolean
    className?: string
    info?: string
    tooltipWidth?: number
    isNew?: boolean
    labelWidth?: number | 'auto'
}

const FormLabel = ({
    label,
    tooltip = '',
    optional = false,
    className = '',
    info = '',
    tooltipWidth = 200,
    isNew = false,
    labelWidth = 125,
}: Props) => {
    const OptionalComponent = () => (optional || info ? <Optional>{info || 'Optional'}</Optional> : null)
    const TooltipComponent = () =>
        tooltip ? (
            <Tooltip label={tooltip} maxWidth={tooltipWidth} optional={optional}>
                <div className="ss-help" />
            </Tooltip>
        ) : null

    return (
        <Container className={className}>
            {isNew ? (
                <LabelWithTag>
                    <LabelDiv width={labelWidth}>{label}</LabelDiv>
                    <NewPill>
                        <h4>NEW</h4>
                    </NewPill>
                    <Media query={desktop.query}>
                        {(matches) => (matches ? <OptionalComponent /> : <TooltipComponent />)}
                    </Media>
                </LabelWithTag>
            ) : (
                <Label>
                    <LabelDiv width={labelWidth}>{label}</LabelDiv>
                    <Media query={desktop.query}>
                        {(matches) => (matches ? <OptionalComponent /> : <TooltipComponent />)}
                    </Media>
                </Label>
            )}
            <Media query={mobile.query}>{(matches) => (matches ? <OptionalComponent /> : <TooltipComponent />)}</Media>
        </Container>
    )
}

export default FormLabel
