import { format } from 'date-fns'
import { giphyRed, giphyYellow } from '../css/colors'

export const getIconData = ({ publish_datetime, is_private, publishDateTime, isPrivate, hideIcons }) => {
    if (hideIcons) {
        return {}
    }
    // fallback for raw story data from api vs camelCased nice story data
    publishDateTime = publishDateTime || publish_datetime
    isPrivate = isPrivate || is_private

    const now = new Date()
    const published = new Date(publishDateTime)
    const isUnpublished = now - published < 0

    if (isUnpublished) {
        return {
            color: giphyYellow,
            icon: 'ss-clock',
            message: `This story ${isPrivate ? 'is private and ' : ''}will be published on ${format(
                published,
                'MMMM D'
            )}.`,
        }
    }

    if (isPrivate) {
        return {
            color: giphyRed,
            icon: 'ss-lock',
            message: 'This story is private, only you can see it.',
        }
    }

    return {}
}
