import styled from 'styled-components'
import { format, distanceInWordsToNow } from 'date-fns'
import { checkIfToday, checkIfYesterday } from '../../util/date'
import { giphyWhite } from '../../css/colors'

const getCreationDate = (publishDateTime) => {
    const now = new Date()
    const publishDate = new Date(publishDateTime)
    const timeDiff = now.getTime() - publishDate.getTime()
    const isToday = checkIfToday(publishDate)
    const isYesterday = checkIfYesterday(publishDate)
    const isFuture = timeDiff < 0

    if (isFuture) {
        return format(publishDate, 'dddd, MMMM D, h A')
    }

    if (Math.abs(timeDiff) < 60 * 60 * 1000) {
        return 'Just now'
    }

    if (isToday) {
        return distanceInWordsToNow(publishDate, { addSuffix: true }).replace('about', '')
    }

    if (isYesterday) {
        return `Yesterday, ${format(publishDate, 'h A')}`
    }

    return format(publishDate, 'dddd, MMMM D, h A')
}

const TitleText = styled.a`
    color: ${giphyWhite};
    display: block;
    font-weight: bold;
    margin-right: 7px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        color: ${giphyWhite};
    }
`

const DateText = styled.span`
    flex-shrink: 0;
    margin-right: auto;
`

const StoryItemTitle = ({ story = {} }) => {
    const { title, publish_datetime: publishDateTime, slug } = story
    return (
        <>
            <TitleText href={`/stories/${slug}`} rel="noopener noreferrer" target="_blank">
                {title}
            </TitleText>
            <DateText>{getCreationDate(publishDateTime)}</DateText>
        </>
    )
}

export default StoryItemTitle
