exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css"), undefined);
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, "._3uDhfcprMHtey1Bw2ZABYR {\n    border-bottom: 2px solid " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyDarkGrey"] + ";\n    padding: 24px 0;\n}\n\n._3uDhfcprMHtey1Bw2ZABYR ul {\n        display: flex;\n        flex-direction: column;\n        text-align: left;\n        margin: 0;\n        padding-top: 8px;\n    }\n\n._10wG-cfL1GmLBhUeF64DFE {\n    text-align: left;\n}\n\n._3Xo2eoV5Xd-_q03HkkrN0n {\n    list-style: none;\n    display: inline-block;\n}\n\n._1UsI1wsdX5jERl_SifPFdn {\n    padding: 4px 0px;\n    font-weight: 600;\n}\n\n._1UsI1wsdX5jERl_SifPFdn:hover {\n        color: " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyAqua"] + ";\n    }", ""]);

// exports
exports.locals = {
	"typography": "'shared/css/typography.css'",
	"sectionHeading": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["sectionHeading"] + "",
	"listItem": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["listItem"] + "",
	"colors": "'shared/css/colors.css'",
	"giphyDarkGrey": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyDarkGrey"] + "",
	"giphyAqua": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyAqua"] + "",
	"container": "_3uDhfcprMHtey1Bw2ZABYR",
	"title": "_10wG-cfL1GmLBhUeF64DFE " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["sectionHeading"] + "",
	"tagListItemWrapper": "_3Xo2eoV5Xd-_q03HkkrN0n",
	"tagListItem": "_1UsI1wsdX5jERl_SifPFdn " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["listItem"] + ""
};