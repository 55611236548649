import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { fontSizeFromControlSize, heightFromControlSize } from 'utils'

export const Container = styled.div<{ $size: ControlSize }>`
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 0.8em;
    position: relative;
    text-align: left;
    white-space: nowrap;

    ${({ $size }) => css`
        font-size: ${fontSizeFromControlSize($size)}px;
        line-height: ${heightFromControlSize($size)}px;
        min-width: ${heightFromControlSize($size)}px;
    `}
`

export const Input = styled.input`
    background: inherit;
    border-radius: inherit;
    box-sizing: border-box;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    height: 100%;
    left: 0;
    line-height: inherit;
    margin: 0;
    padding: inherit;
    position: absolute;
    text-align: inherit;
    top: 0;
    width: 100%;
`

export const Label = styled.span`
    line-height: inherit;
    opacity: 0;
    pointer-events: none;
`
