import { ControlSize } from 'types/src/controls'
import { Gradient } from 'types/src/gradient'
import SelectList, { SelectListProps } from '../../select-list'
import { PortalTooltip, useTooltip } from '../../tooltip'
import { TooltipProps } from '../../tooltip/hooks/use-tooltip'
import { Button } from './style'

type Props = {
    activeIndex?: number
    backgroundColor: string
    className?: string
    controlColor?: string
    controlId: string
    controlSize: ControlSize
    gradient?: Gradient
    options: string[]
    selectListOptions?: Partial<SelectListProps>
    tooltipOptions?: Partial<TooltipProps>
    onChange: (index: number) => void
}

const SegmentedControlMenu = ({
    activeIndex,
    backgroundColor,
    className,
    controlColor,
    controlId,
    controlSize,
    options,
    selectListOptions = {},
    tooltipOptions = {},
    onChange,
}: Props) => {
    const { isOpen, listeners } = useTooltip({
        color: backgroundColor,
        direction: 'up',
        id: `${controlId}-menu`,
        ...tooltipOptions,
    })

    return (
        <>
            <Button {...listeners} className={className} color={backgroundColor} icon="ellipsis" size={controlSize} />
            <PortalTooltip isOpen={isOpen}>
                <SelectList
                    iconColor={controlColor}
                    {...selectListOptions}
                    defaultIndex={activeIndex}
                    options={options}
                    onChange={onChange}
                />
            </PortalTooltip>
        </>
    )
}

export default SegmentedControlMenu
