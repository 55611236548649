import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import UserContext from 'shared/contexts/user'
import resizeAvatar from 'shared/util/resize-avatar'
import styled, { css } from 'styled-components'
import { HeaderContext } from '../header/header'
import CreateIcon from './create-icon'
import MenuIcon from './menu-icon'
import SearchIcon from './search-icon'
import UserIcon from './user-icon'

const NavContainer = styled.div`
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    align-content: center;
`

const Button = styled.a`
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    width: 38px;
    height: 40px;
    margin-left: 8px;
`

const UserButton = styled(Button)`
    overflow: hidden;
    height: 40px;
`

const Avatar = styled.div<{ avatarURL: string; isActive: boolean }>`
    height: 100%;
    width: 100%;
    border-radius: 2px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-out;
    background-image: url(${(props) => props.avatarURL});
    ${(props) =>
        props.isActive &&
        css`
            transform: scale(1.3);
        `};
`

const CategoryButton = styled(Button)`
    margin-right: 6px;
    vertical-align: top;
    box-sizing: border-box;
    height: 40px;
`

const Nav = () => {
    const { pathname } = useLocation()
    const {
        user: { loggedIn, avatarURL: existingAvatarURL },
    } = useContext(UserContext)
    useEffect(() => {
        setMenuState(undefined)
    }, [pathname])
    const avatarURL = resizeAvatar(existingAvatarURL, 40)
    const { isSearchVisible, menu, setIsSearchVisible, setMenuState } = useContext(HeaderContext)
    const userClick = () => {
        if (loggedIn) {
            setMenuState(menu === 'user' ? undefined : 'user')
        } else {
            setMenuState(menu === 'logged out user' ? undefined : 'logged out user')
        }
    }
    return (
        <NavContainer>
            <SearchIcon
                isSearchVisible={isSearchVisible}
                onClick={() => {
                    setMenuState(undefined)
                    userClick()
                    if (menu) {
                        // just give it a 100ms to remove other menu
                        setTimeout(() => setIsSearchVisible(!isSearchVisible), 100)
                    } else {
                        setIsSearchVisible(!isSearchVisible)
                    }
                }}
            />
            <Button href="/create/gifmaker">
                <CreateIcon />
            </Button>
            <UserButton
                onClick={() => {
                    setIsSearchVisible(false)
                    setMenuState(undefined)
                    userClick()
                }}
            >
                {loggedIn ? <Avatar avatarURL={avatarURL} isActive={menu === 'user'} /> : <UserIcon />}
            </UserButton>
            <CategoryButton
                onClick={() => {
                    setIsSearchVisible(false)
                    setMenuState(menu === 'category' ? undefined : 'category')
                }}
            >
                <MenuIcon isActive={menu === 'category'} />
            </CategoryButton>
        </NavContainer>
    )
}

export default Nav
