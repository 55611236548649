export const GIFMAKER_CREATION_LOCATIONS = {
    DESKTOP: {
        GIF: 'desktopweb_gifmaker_create_gif',
        STICKER: 'desktopweb_gifmaker_create_sticker',
        VIDEO: 'desktopweb_gifmaker_create_video',
    },
    MOBILE: {
        GIF: 'mobileweb_gifmaker_create_gif',
        STICKER: 'mobileweb_gifmaker_create_sticker',
        VIDEO: 'mobileweb_gifmaker_create_video',
    },
}
// Currently no way to determine if sticker or regular gif until after upload.
// Mobile upload does not currently exist.
export const UPLOAD_CREATION_LOCATIONS = {
    DESKTOP: {
        GIF: 'desktopweb_uploadtool_upload',
        STICKER: 'desktopweb_uploadtool_upload',
        VIDEO: 'desktopweb_uploadtool_upload_video',
    },
}

export const SUPERCUT_CREATION_LOCATIONS = {
    GIF: 'desktopweb_supercut_upload_gif',
    VIDEO: 'desktopweb_supercut_upload_video',
}
