exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* fonts */", ""]);

// exports
exports.locals = {
	"bodyFont": "interface, Helvetica Neue, helvetica, sans-serif",
	"titleFont": "nexablack, sans-serif"
};