import { giphyLightGrey } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import Arrow from 'shared/components/giphy-marketers/icons/arrow'
import PlayBoxIcon from 'shared/components/icons/video/play'
import { SectionHeader } from 'shared/components/text-elements'
import VideoPreviewPlayInline from 'shared/components/video-inline-play'
import styled from 'styled-components'

const SEARCH_WIDTH = 161
const ARROW_ROTATE = 270

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`

const ContainerHeader = styled(SectionHeader)`
    display: flex;
    align-items: center;
    color: ${giphyLightGrey};
`
const ArrowContainer = styled.div`
    margin-left: 5px;
`
const PlayArrowContainer = styled(ArrowContainer)``

const Carousel = styled.div`
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
    margin-bottom: 5px;
`

const PreviewContainer = styled.div`
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }
`

type VideoPreviewCarouselProps = {
    previews: IGif[]
    hideHeader?: boolean
    onClipClick?: (gif: IGif, index: number) => void
}

const VideoPreviewCarousel = ({ previews, hideHeader = false, onClipClick }: VideoPreviewCarouselProps) => {
    return (
        <Container>
            {!hideHeader && (
                <Title>
                    <ContainerHeader>
                        {`Today's Clips`}
                        <PlayArrowContainer>
                            <PlayBoxIcon size={{ width: 25, height: 25 }} />
                        </PlayArrowContainer>
                    </ContainerHeader>
                    <ContainerHeader>
                        See All
                        <ArrowContainer>
                            <Arrow rotate={ARROW_ROTATE} />
                        </ArrowContainer>
                    </ContainerHeader>
                </Title>
            )}
            <Carousel>
                {previews.map((preview, index) => (
                    <PreviewContainer key={preview.slug}>
                        <VideoPreviewPlayInline
                            gif={preview}
                            width={SEARCH_WIDTH}
                            volumeSize={36}
                            withOverlay={false}
                            showClipTitle={false}
                            playOnHover={false}
                            onClick={() => onClipClick?.(preview, index)}
                        />
                    </PreviewContainer>
                ))}
            </Carousel>
        </Container>
    )
}

export default VideoPreviewCarousel
