import styled from 'styled-components'

const Container = styled.div`
    display: inline-block;
    height: 28px;
    margin: -7px 7px 0 0;
    vertical-align: middle;
`

const CopyIcon = () => (
    <Container>
        <svg width="17" height="28" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <path
                    d="M21.448 13.613c1.3 0 2.249 1.043 2.249 2.35 0 .348-.012 1.102-.034 2.18l-.008.393c-.01.532-.041 1.938-.041 1.953 0 4.527-3.626 8.2-8.105 8.2-4.476 0-8.188-3.769-8.188-8.298 0-1.473 1.026-2.79 2.422-3.2V7.396a2.357 2.357 0 0 1 2.364-2.35c1.3 0 2.25 1.043 2.25 2.35v4.437c.267-.104.559-.162.864-.162.776 0 1.427.372 1.826.95.37-.24.813-.378 1.287-.378 1.033 0 1.843.656 2.134 1.581.298-.135.63-.21.98-.21zM16.75 6a.75.75 0 1 1-1.5 0c0-1.866-1.293-3.25-3.102-3.25C10.265 2.75 8.75 4.211 8.75 6a.75.75 0 0 1-1.5 0c0-2.63 2.199-4.75 4.898-4.75 2.66 0 4.602 2.077 4.602 4.75zm-1.24 21.19c3.645 0 6.604-2.998 6.604-6.7l.042-1.985.007-.392c.022-1.066.034-1.816.034-2.15 0-.509-.31-.85-.75-.85a.857.857 0 0 0-.864.85v.861c0 1-1.5 1-1.5 0v-2.232c0-.508-.31-.85-.749-.85a.857.857 0 0 0-.864.85v.862c0 1-1.5 1-1.5 0V14.02c0-.509-.31-.85-.75-.85a.857.857 0 0 0-.864.85v.91c0 1-1.5 1-1.5 0V7.396c0-.508-.31-.85-.749-.85a.857.857 0 0 0-.864.85v10.419a.75.75 0 0 1-.701.748c-.946.062-1.72.902-1.72 1.828 0 3.706 3.045 6.798 6.687 6.798z"
                    id="fingerpath"
                />
            </defs>
            <use fill="#FFF" fillRule="nonzero" xlinkHref="#fingerpath" transform="translate(-7 -1)" />
        </svg>
    </Container>
)

export default CopyIcon
