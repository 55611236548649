import { giphyYellow } from '../../css/colors'
import styled from 'styled-components'

const ClockContainer = styled.div`
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
`
const Icon = styled.div`
    margin-left: 6px;
    margin-bottom: 4px;
    font-size: 15px;
    line-height: 15px;
    color: ${giphyYellow};
`
const Clock = () => (
    <ClockContainer>
        <Icon className="ss-clock" />
    </ClockContainer>
)

export default Clock
