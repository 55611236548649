import { createContext } from 'react'

const noop = (...args) => console.log(...args)

export type BulkEditModeProps = {
    isBulkEditMode: boolean
    toggleBulkEditMode: () => void
    setBulkEditGifIds: (gifs: (string | number)[]) => void
    bulkEditGifIds: (string | number)[]
    toggleEditMode: (isOn: boolean) => void
}

const BulkEditModeContext = createContext({
    isBulkEditMode: false,
    toggleBulkEditMode: noop,
    setBulkEditGifIds: noop,
    toggleEditMode: noop,
    bulkEditGifIds: [],
} as BulkEditModeProps)

export default BulkEditModeContext
