import { FunctionComponent } from 'react'
import { IconProps } from '.'
import * as icons from '../../icons/ui'

export const uiIcons = ['rotator', 'scaler'] as const

type UiIcon = typeof uiIcons[number]

export function uiIconComponent(icon: UiIcon): FunctionComponent<IconProps> {
    switch (icon) {
        case 'rotator':
            return icons.RotatorIcon
        case 'scaler':
            return icons.ScalerIcon
    }
}

export default UiIcon
