import { useContext } from 'react'
import styled from 'styled-components'
import { giphyLightestGrey } from '../../../css/colors'
import ToggleButton from '../../toggle-button'
import VideoContext from '../video-context'
import Preview from './embed-preview'
import Label from './section-label'

const Container = styled.div`
    display: flex;
    width: 100%;
`

const Content = styled.div`
    color: ${giphyLightestGrey};
    font-size: 16px;
    width: 100%;
`

const Description = styled.p`
    display: flex;
    line-height: 1.4;
    margin: 0 0 30px;
    max-width: 500px;
`

const Toggle = styled.div`
    margin-bottom: 20px;
    margin-right: 20px;
    width: 100%;

    &:last-child {
        margin-right: 0;
    }
`

const Toggles = styled.div<{ isVertical: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
    margin-bottom: 10px;
    width: 100%;
`

const ShareEmbed = () => {
    const { dimensions, isEmbedResponsive, isEmbedInfoBelow, setIsEmbedInfoBelow, setIsEmbedResponsive } =
        useContext(VideoContext)
    const isPortrait = dimensions.height > dimensions.width
    const isSlim = dimensions.height <= 300
    const isReallySlim = dimensions.height <= 200
    return (
        <Container>
            <Content>
                {!isSlim && (
                    <Description>
                        Want to embed this clip on your website or blog? Just drop in the embed code below and
                        you&apos;re done!
                    </Description>
                )}
                {!isReallySlim && (
                    <Toggles isVertical={isPortrait || dimensions.width < 250}>
                        <Toggle>
                            <Label>Responsive</Label>
                            <ToggleButton isOn={isEmbedResponsive} onToggle={setIsEmbedResponsive} />
                        </Toggle>
                        <Toggle>
                            <Label>Show info below clip</Label>
                            <ToggleButton
                                isOn={isEmbedInfoBelow}
                                offLabel="Hide"
                                onLabel="Show"
                                onToggle={setIsEmbedInfoBelow}
                            />
                        </Toggle>
                    </Toggles>
                )}
            </Content>
            {!isSlim && dimensions.width >= 640 && <Preview isPortrait={isPortrait} />}
        </Container>
    )
}

export default ShareEmbed
