import { useEffect, useState } from 'react'
import { Coordinates, Transform } from 'types'
import { isInsidePolygon } from 'utils'

const useIsOverflow = (coordinates: Coordinates, transform: Transform, clipPath?: string) => {
    const [isOverflow, setIsOverflow] = useState<boolean>(false)

    useEffect(() => {
        if (!clipPath || !clipPath.includes('polygon')) {
            setIsOverflow(false)
            return
        }

        const { scaleX, scaleY } = transform
        const scaledCoordinates = { x: Math.round(coordinates.x * scaleX), y: Math.round(coordinates.y * scaleY) }
        const polygon = clipPath
            .replace('polygon(', '')
            .replace(')', '')
            .split(',')
            .map((point) => {
                const values = point.split(' ').map((value) => parseInt(value))

                return { x: values[0], y: values[1] }
            })

        const isInside = isInsidePolygon(scaledCoordinates, polygon)

        setIsOverflow(!isInside)
    }, [clipPath, coordinates.x, coordinates.y, transform.scaleX, transform.scaleY])

    return isOverflow
}

export default useIsOverflow
