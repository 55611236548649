import { useContext, useState } from 'react'
import { getGifDetailPermissions } from 'shared/api/detail'
import ChannelPageContext from 'shared/contexts/channel-page'
import GridTypeContext from 'shared/contexts/grid-type'
import UserContext from 'shared/contexts/user'
import { GifPermissions } from 'types'
import useAsyncEffect from './use-async-effect'

export const useCanBulkEdit = () => {
    const { user } = useContext(UserContext)
    const { isOwner } = useContext(ChannelPageContext)
    const { gridType } = useContext(GridTypeContext)
    if (gridType !== 'grid') {
        return false
    }
    return !!(user?.loggedIn && user?.permissions?.bulk_edit && (user?.isStaff || isOwner))
}

export const useCanCurateCollection = () => {
    const { isOwner } = useContext(ChannelPageContext)
    const { user } = useContext(UserContext)
    const { curate_all_channels } = user?.permissions || {}
    return curate_all_channels || isOwner
}

export const useCanManageCollection = () => {
    const { isOwner } = useContext(ChannelPageContext)
    const { user } = useContext(UserContext)
    const { manage_all_subchannels } = user?.permissions || {}
    return manage_all_subchannels || isOwner
}

export const useCanAddGifToCollection = (id: string | number) => {
    const gifPerms = useGifDetailPermissions(id)
    const { user } = useContext(UserContext)
    const userPerms = user?.permissions
    if (userPerms?.curate_all_channels) {
        return true
    }
    if (gifPerms?.edit) {
        return true
    }
    return false
}

export const useGifDetailPermissions = (id: string | number) => {
    const { user } = useContext(UserContext)
    const [permissions, setPermissions] = useState<GifPermissions>()
    useAsyncEffect(async () => {
        if (user?.loggedIn) {
            try {
                const { permissions } = await getGifDetailPermissions(id)
                setPermissions(permissions)
            } catch (e) {
                console.error(`Error fetching gif detail permissions for ${id}`, e)
            }
        }
    }, [id])
    return permissions
}
