import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { ControlSize, NumberFormat, TextLength } from 'types'
import { displayTextToNumber, numberToDisplayText } from 'utils'
import { Container, Input, Label } from './style'

type Props = {
    className?: string
    disabled?: boolean
    extension?: ReactNode
    format?: NumberFormat
    length?: TextLength
    placeholder?: string
    size: ControlSize
    step?: number
    value?: number
    onChange?: (value: number) => void
}

const NumberInputLabel = ({
    className,
    disabled,
    extension,
    format,
    length,
    placeholder = '--',
    size,
    step = 1,
    value,
    onChange,
}: Props) => {
    const [text, setText] = useState<string>('')
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const updateTextToValue = useCallback(() => {
        if (typeof value === 'number' && format) {
            setText(numberToDisplayText(value, format, length))
            return
        }

        setText(typeof value === 'number' ? `${value}` : '')
    }, [format, length, value])

    const onBlur = () => {
        const parsedText = parseFloat(text)

        if (!isNaN(parsedText)) {
            if (format) {
                const formattedValue = displayTextToNumber(text, format)
                onChange?.(formattedValue && !isNaN(formattedValue) ? formattedValue : parseFloat(text))
            } else {
                onChange?.(parseFloat(text))
            }
        } else {
            updateTextToValue()
        }

        setIsFocused(false)
    }

    const onFocus = (e: SyntheticEvent<HTMLInputElement, Event>) => {
        e.currentTarget.select()
        setIsFocused(true)
    }

    const onKeyDown = (e: SyntheticEvent<HTMLInputElement, KeyboardEvent>) => {
        const arrowStep = e.nativeEvent.shiftKey ? step * 10 : step

        switch (e.nativeEvent.key) {
            case 'Enter':
                onBlur()
                break
            case 'ArrowUp':
                onChange?.((value || 0) + arrowStep)
                break
            case 'ArrowDown':
                onChange?.((value || 0) - arrowStep)
                break
        }
    }

    const onInput = (e: SyntheticEvent<HTMLInputElement, Event>) => {
        setText(e.currentTarget.value)
    }

    useEffect(() => {
        updateTextToValue()
    }, [format, length, value])

    return (
        <Container $size={size} className={className}>
            <Label style={{ opacity: isFocused ? 0 : 1 }}>
                {text || placeholder}
                {text ? extension : null}
            </Label>
            <Input
                placeholder={placeholder}
                style={{ opacity: isFocused ? 1 : 0 }}
                tabIndex={disabled ? -1 : undefined}
                type="text"
                value={text}
                onBlur={onBlur}
                onChange={onInput}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
            />
        </Container>
    )
}

export default NumberInputLabel
