import { giphyLightGrey } from '@giphy/colors'
import { SyntheticEvent } from 'react'
import { useHistory } from 'react-router'
import { desktop } from 'shared/util/media-query'
import styled, { css } from 'styled-components'

// Chris says this is it, universal font size for section headers
const SECTION_HEADER_FONT_SIZE = 15

const containerCss = css`
    font-size: ${SECTION_HEADER_FONT_SIZE}px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    color: ${giphyLightGrey};
    padding: 0 8px;
    ${desktop.css`
        padding: 0 8px 0 0;
    `}
    font-family: interface;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 6px;
`

const H2Container = styled.h2<{ onClick?: any }>`
    ${containerCss}
`

const LinkContainer = styled.a`
    ${containerCss}
    cursor: pointer;
    justify-content: flex-start;
`

const Arrow = styled.div`
    font-size: 11px;
    margin-left: 3px;
    height: 13px;
    display: inline-block;
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
`

type Props = {
    label: string
    url?: string
    className?: string
    isHistoryPush?: boolean
    historyState?: any
    seeAllLabel?: string
}

const SectionHeader = ({ label, url = '', className = '', isHistoryPush, historyState = {} }: Props) => {
    const history = useHistory()
    const onClick = (e: SyntheticEvent) => {
        if (isHistoryPush) {
            e.preventDefault()
            history.push(url, historyState)
        }
    }
    return url ? (
        <LinkContainer href={url} className={className} onClick={onClick}>
            <h2>
                {label} <Arrow className="ss-icon ss-navigateright" />
            </h2>
        </LinkContainer>
    ) : (
        <H2Container className={className}>{label}</H2Container>
    )
}

export const SectionHeaderWithSeeAll = ({
    label,
    url = '',
    className = '',
    isHistoryPush,
    historyState,
    seeAllLabel = 'See All',
}: Props) => {
    return (
        <Title className={className}>
            <SectionHeader label={label} isHistoryPush={isHistoryPush} historyState={historyState} />
            <SectionHeader label={seeAllLabel} url={url} isHistoryPush={isHistoryPush} historyState={historyState} />
        </Title>
    )
}
export default SectionHeader
