import styled, { css } from 'styled-components'
import { giphyGreen } from '../css/colors'
import Avatar from './avatar'
import Verified from './verified-badge'

const Link = styled.a`
    box-sizing: content-box;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    max-width: 100%;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Username = styled.span`
    color: rgba(255, 255, 255, 0.8);
    display: block;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const ForHireText = styled(Username)`
    color: ${giphyGreen};

    &:before {
        content: '●';
        line-height: 18px;
        font-size: 18px;
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
`

const DisplayName = styled(Username)`
    color: #fff;
    font-size: 16px;
    font-weight: 900;
`

const UsernameWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`

const StyledVerified = styled(Verified)`
    margin-left: 4px;
`

const StyledAvatar = styled(Avatar)`
    ${(props) =>
        props.compact &&
        css`
            width: 25px;
            height: 25px;
            margin-right: 8px;
        `}
`

const Attribution = ({
    user,
    compact = false,
    noAvatar = false,
    target = '_blank',
    canViewForHire = false,
    className = undefined,
}) => {
    const { avatar, avatar_url, is_freelance } = user
    const userAvatar = avatar_url || avatar
    const displayName = user.display_name || user.username
    const username = user.username
    const height = noAvatar ? 'auto' : compact ? 25 : 36
    return !user ? null : (
        <Link
            className={className}
            href={user.profile_url}
            rel="noopener noreferrer"
            style={{ height }}
            target={target}
        >
            {!noAvatar && userAvatar && <StyledAvatar avatar={userAvatar} compact={compact} />}
            <Info>
                {!compact && !noAvatar && <DisplayName>{displayName}</DisplayName>}
                {canViewForHire && !compact && !noAvatar && is_freelance ? (
                    <UsernameWrapper>
                        <ForHireText>Available for Work</ForHireText>
                    </UsernameWrapper>
                ) : (
                    <UsernameWrapper>
                        <Username>{compact || noAvatar ? displayName : `@${username}`}</Username>
                        {user.is_verified ? <StyledVerified size={14} /> : null}
                    </UsernameWrapper>
                )}
            </Info>
        </Link>
    )
}

export default Attribution
