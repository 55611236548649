import { useState } from 'react'
import useWindowSize from 'shared/hooks/window-size'
import Contact from './contact'
import { AboutPageContext } from './context'
import Footer from './footer'
import Header from './header'
import Info from './info'
import News from './news'
import Philosophies from './our-philosophies'
import Values from './values'
import { AboutPageContainer, AboutPageGlobalStyles, AboutPageGradient } from './style'
import Team from './team'
import { IHoverImage } from './types'

const AboutPage = () => {
    const { innerWidth, innerHeight } = useWindowSize()
    const [hoverImages, setHoverImages] = useState<IHoverImage[]>([])
    const addHoverImage = (image: IHoverImage) => {
        setHoverImages([...hoverImages, image])
    }
    const clearHoverImages = () => {
        setHoverImages([])
    }
    return (
        <AboutPageContext.Provider
            value={{
                hoverImages,
                addHoverImage,
                clearHoverImages,
                windowWidth: innerWidth,
                windowHeight: innerHeight,
            }}
        >
            <AboutPageGlobalStyles />
            <AboutPageContainer>
                <Header />
                <AboutPageGradient>
                    <Info />
                    <News />
                </AboutPageGradient>
                <Philosophies />
                <Values />
                <Contact />
                <Team />
                <Footer />
            </AboutPageContainer>
        </AboutPageContext.Provider>
    )
}

export default AboutPage
