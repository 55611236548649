import styled from 'styled-components'
import { WhenInView } from 'shared/util/in-view'
import { IMAGE_PATH } from '../constants'
import { AboutPageSticker } from '../style'
import Posts from './posts'

const STICKER_HEIGHT: number = 150

const StickerWrapper = styled.div`
    margin-bottom: -1.4em;
`

const Sticker = styled(AboutPageSticker)`
    height: ${STICKER_HEIGHT}px;
`

const AboutPageNews = () => {
    return (
        <section id="recent-news">
            <StickerWrapper>
                <WhenInView height={STICKER_HEIGHT}>
                    <Sticker src={`${IMAGE_PATH}/stickers/computer.gif`} alt="" />
                </WhenInView>
            </StickerWrapper>
            <h1>Recent News</h1>
            <WhenInView dontUnmount>
                <Posts />
            </WhenInView>
        </section>
    )
}

export default AboutPageNews
