import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import { useState } from 'react'
import Social from 'shared/components/channel-info/social'
import { PublicUser } from 'shared/components/users'
import { relativeUrl } from 'shared/util/url'
import styled from 'styled-components'
import { IProfilePowerUser } from 'types'

const Container = styled.div`
    border-bottom: 2px solid dividerColor;
    padding: 24px 0;
    text-align: left;
    width: 210px;
    overflow-wrap: break-word;
    &:last-child {
        border-bottom: 0;
    }
`

const PublicUserDiv = styled.div`
    margin: 0 0 10px;
`

const Description = styled.p<{ descriptionOpen: boolean }>`
    color: ${giphyLightGrey};
    white-space: pre-wrap;
    margin: 0;
    font-size: 14px;
    line-height: 19px;
    transition: max-height 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding-top: 20px;
    max-height: ${({ descriptionOpen }) => (descriptionOpen ? 1000 : 77)}px;
    overflow: hidden;
    margin-bottom: 4px;
`

const ReadMoreButton = styled.p<{ onClick: any }>`
    cursor: pointer;
    color: ${giphyLightGrey};
    display: flex;
    margin: 0px 0 16px 0;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:hover {
        color: ${giphyWhite};
    }
`

const ArrowIcon = styled.i<{ directionUp: boolean }>`
    &:before {
        font-size: 8px;
        margin-left: 6px;
        font-weight: 600;
    }
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: ${({ directionUp }) => directionUp && `translateX(6px) translateY(1px) rotate(-180deg)`};
`

const SocialDiv = styled.div`
    margin-top: 30px;
    padding-top: 5px;
    text-align: left;
`

type Props = {
    user: IProfilePowerUser
}

const PublicUserDetails = ({
    user,
    user: {
        description,
        display_name: displayName,
        username,
        avatar_url: avatar,
        facebook_url,
        instagram_url,
        twitter_url,
        tumblr_url,
        tiktok_url,
        youtube_url,
    },
}: Props) => {
    const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false)
    const userName = displayName || username
    const channel = relativeUrl(user.profile_url || '')
    const hasSocial = !!(facebook_url || instagram_url || twitter_url || tumblr_url || tiktok_url || youtube_url)
    const socialLinks = [
        { type: 'facebook', url: facebook_url },
        { type: 'instagram', url: instagram_url },
        { type: 'twitter', url: twitter_url },
        { type: 'tumblr', url: tumblr_url },
        { type: 'tiktok', url: tiktok_url },
        { type: 'youtube', url: youtube_url },
    ]

    return (
        <Container>
            {avatar && userName && (
                <PublicUserDiv>
                    <PublicUser {...{ user, userName, avatar, channel }} />
                </PublicUserDiv>
            )}
            {description && <Description descriptionOpen={descriptionOpen}>{description}</Description>}
            {description && (
                <ReadMoreButton onClick={() => setDescriptionOpen(!descriptionOpen)}>
                    {descriptionOpen ? 'Read less' : 'Read more'}
                    <ArrowIcon className={'ss-navigatedown'} directionUp={descriptionOpen} />
                </ReadMoreButton>
            )}
            {hasSocial && (
                <SocialDiv>
                    <Social links={socialLinks.filter((link) => !!link.url)} />
                </SocialDiv>
            )}
        </Container>
    )
}

export default PublicUserDetails
