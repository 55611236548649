import { Button as BrandButton } from 'shared/components/buttons'
import { useCallback, useContext, useState } from 'react'
import { webFetch } from 'shared/api'
import { fetchChannel, useChannelWithNormalGifFetch } from 'shared/api/channels'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { IChannel } from 'shared/types/channel'
import styled from 'styled-components'
import EditAPIContext from 'shared/contexts/edit-api'
import Grid from '../grid'
import { SearchBar } from '../search-bar'
import { assertIsDefined } from 'shared/types/asserts'
import { IGif } from '@giphy/js-types'

const ContainerForButtonAndGifs = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`
const GifSelectorScroll = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
`
const Button = styled(BrandButton)`
    position: absolute;
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
`
type Props = {
    channelId: number
    searchChannelId: number
    onComplete?: (addedGifs: (string | number)[]) => void
}
const AddGifsToCollection = ({ channelId, onComplete, searchChannelId }: Props) => {
    const [selectedGifs, setSelectedGifs] = useState<IGif[]>([])
    const [channel, setChannel] = useState<IChannel>()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const { saveGifsToCollection } = useContext(EditAPIContext)
    const fetchGifs = useChannelWithNormalGifFetch(searchChannelId)
    const onClick = async () => {
        await saveGifsToCollection(selectedGifs, channelId)
        onComplete?.(selectedGifs.map((gif) => gif.id))
    }
    useAsyncEffect(async () => {
        setChannel(await fetchChannel(searchChannelId))
    }, [channelId])
    const searchFetch = useCallback(
        async (offset: number) => {
            assertIsDefined(channel)
            return webFetch.search(`@${channel.slug} ${searchTerm}`, { offset, rating: 'pg-13' })
        },
        [searchTerm, channel]
    )
    return (
        <ContainerForButtonAndGifs>
            {channel?.user?.is_verified && (
                <SearchBar onChange={(value) => setSearchTerm(value)} placeholder={`Search ${channel.display_name}`} />
            )}
            <GifSelectorScroll key={searchTerm}>
                <Grid
                    fetchGifs={searchTerm ? searchFetch : fetchGifs}
                    standaloneSelectionMode
                    onGifsSelectionUpdate={setSelectedGifs}
                />
            </GifSelectorScroll>
            <Button disabled={selectedGifs.length === 0} onClick={onClick}>
                Add Selected Gifs
            </Button>
        </ContainerForButtonAndGifs>
    )
}

export default AddGifsToCollection
