import { groupBy, isEmpty } from 'lodash'
import { getRouteFromPathname } from 'mobile/components/router'
import TrendingGifsCarousel from 'mobile/components/trending-gifs-carousel'
import routes from 'shared/routes'
import { Item } from '../types'
import SearchDropdownItem from './search-drop-down-item'
import {
    CarouselContainer,
    DividingHeader,
    SearchBarDropDownContainer,
    TrendingSearchHeader,
} from './search-drop-down-sc'

type SearchDropdownProps = {
    items: Item[]
    searchType: string
    onChannelSelected: (channel: string, img: string, isSearchPage?: boolean) => void
    onGifSelected: () => void
    focusedIndex: number
    searchTerm?: string
}

const SearchDropdown = ({
    items,
    searchType,
    onChannelSelected,
    onGifSelected,
    focusedIndex,
    searchTerm,
}: SearchDropdownProps) => {
    const mappedItems = items.map((item, index) => {
        return { ...item, id: index }
    })
    const { tags, channels, trending } = groupBy(mappedItems, ({ type }) => type)
    const isChannelSearch = searchType === 'channel'
    const isHome = getRouteFromPathname(location.pathname) === routes.HOME
    return (
        <SearchBarDropDownContainer>
            {isChannelSearch ? (
                <>
                    {!isEmpty(channels) && (
                        <ul>
                            {channels.map((channel) => (
                                <SearchDropdownItem
                                    key={channel.name}
                                    isFocusedIndexLink={channel.id === focusedIndex}
                                    isChannelSearch={isChannelSearch}
                                    item={channel}
                                    callback={onChannelSelected}
                                />
                            ))}
                        </ul>
                    )}
                </>
            ) : (
                <>
                    {!isEmpty(trending) && (
                        <>
                            {!isHome && (
                                <CarouselContainer>
                                    <TrendingGifsCarousel
                                        hideSeeAll
                                        headerText={'Trending Now'}
                                        marginBottom={10}
                                        onGifSelected={onGifSelected}
                                        isInDropdown
                                    />
                                </CarouselContainer>
                            )}
                            <TrendingSearchHeader isHome={isHome}>Trending Searches</TrendingSearchHeader>
                            <ul>
                                {trending.map((trendingItem) => (
                                    <SearchDropdownItem
                                        key={trendingItem.name}
                                        isFocusedIndexLink={trendingItem.id === focusedIndex}
                                        isChannelSearch={isChannelSearch}
                                        item={trendingItem}
                                        isTrending
                                    />
                                ))}
                            </ul>
                        </>
                    )}
                    {!isEmpty(tags) && (
                        <ul>
                            {tags.map((tag) => (
                                <SearchDropdownItem
                                    key={tag.name}
                                    isFocusedIndexLink={tag.id === focusedIndex}
                                    isChannelSearch={isChannelSearch}
                                    item={tag}
                                    searchTerm={searchTerm}
                                />
                            ))}
                        </ul>
                    )}
                    {!isEmpty(channels) && (
                        <>
                            <DividingHeader>Channels</DividingHeader>
                            <ul>
                                {channels.map((channel) => (
                                    <SearchDropdownItem
                                        key={channel.name}
                                        isFocusedIndexLink={channel.id === focusedIndex}
                                        isChannelSearch={isChannelSearch}
                                        item={channel}
                                    />
                                ))}
                            </ul>
                        </>
                    )}
                </>
            )}
        </SearchBarDropDownContainer>
    )
}

export default SearchDropdown
