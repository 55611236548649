import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useGifDetailPermissions } from 'shared/hooks/use-permissions'
import ModalContext from 'shared/contexts/modal'
import { Edit, Tile, Fullscreen } from './sc'

type Props = {
    gif: any
    className: string
}

const ViewOptions = ({ gif, className }: Props) => {
    const permissions = useGifDetailPermissions(gif.id)
    const { path } = gif
    const { openGifEditPanel } = useContext(ModalContext)
    return (
        <div className={className}>
            {permissions && permissions.edit && (
                <div onClick={() => openGifEditPanel(gif)}>
                    <Edit />
                </div>
            )}
            <Link to={`${path}/tile`}>
                <Tile />
            </Link>
            <Link to={`${path}/fullscreen`}>
                <Fullscreen />
            </Link>
        </div>
    )
}

export default ViewOptions
