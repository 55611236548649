import { FC, useContext } from 'react'
import * as React from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import UserContext from 'shared/contexts/user'
import { highestZIndex } from 'shared/css/z-indexes'
import styled, { keyframes } from 'styled-components'
import { useHistory } from 'react-router'
import { CloseIcon } from 'ui/src/icons/navigation'
import { tosBannerLocalStorageId } from 'ui/src/constants'

const slideIn = keyframes`
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(57px);
    }
`
const InnerContainer = styled.div`
    text-align: left;
    width: 1040px;
    min-width: 1040px;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.21px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        font-weight: bold;
    }
`

const Banner = styled.div`
    width: 100%;
    position: absolute;
    top: -57px;
    left: 0;
    height: 57px;
    background: linear-gradient(-135deg, rgb(255, 102, 102) 0%, rgb(97, 87, 255) 100%);
    z-index: ${highestZIndex};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 0.3s ${slideIn} ease-out;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
`

const CloseButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    top: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
`

const Emoji = styled.span`
    position: relative;
    font-size: 20px;
    margin-right: 8px;
`

const LeftContent = styled.div`
    display: flex;
    align-items: center;
`

const slideInForCTA = `(max-width: 1100px)`
const RightContent = styled.div`
    @media ${slideInForCTA} {
        transform: translateX(-70px);
        transition: transform 300ms ease-out;
    }
`

const RightArrow = styled.div`
    display: inline;
    position: relative;
    top: 3px;
`

type Props = {
    storageLabel: string
    linkUrl?: string
    linkLabel?: string
    isPortfolios?: boolean
    titleEmoji?: string
    children?: React.ReactNode
}

const PromoMessage: FC<Props> = ({ children, storageLabel, linkUrl, linkLabel, isPortfolios, titleEmoji }) => {
    const { isLoggedIn, user } = useContext(UserContext)
    const [hasSeenBanner, setHasSeenBanner] = useLocalStorage(storageLabel, false)
    const history = useHistory()
    const hide = () => {
        setHasSeenBanner(true)
    }

    if (
        (!hasSeenBanner && isLoggedIn && (user.permissions.access_dvr || user.isStaff)) ||
        (!hasSeenBanner && storageLabel === tosBannerLocalStorageId)
    ) {
        return (
            <Banner>
                <InnerContainer>
                    <LeftContent>
                        <Emoji>{titleEmoji || '👋'}</Emoji>
                        {children}
                    </LeftContent>
                    <RightContent>
                        {isPortfolios ? (
                            <div
                                onClick={() => {
                                    history.push('/settings', {
                                        showFreelance: true,
                                        title: 'Edit Your Settings | GIPHY',
                                    })
                                    hide()
                                }}
                                style={{ cursor: `pointer` }}
                            >
                                {linkLabel} <RightArrow className="ss-icon ss-navigateright" />
                            </div>
                        ) : linkUrl ? (
                            <a
                                onClick={() => {
                                    hide()
                                }}
                                href={linkUrl}
                            >
                                {linkLabel} <RightArrow className="ss-icon ss-navigateright" />
                            </a>
                        ) : null}
                    </RightContent>
                </InnerContainer>
                <CloseButton onClick={hide}>
                    <CloseIcon height={20} />
                </CloseButton>
            </Banner>
        )
    } else {
        return null
    }
}

export default PromoMessage
