import styled from 'styled-components'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { giphyBlack } from '../../css/colors'
import Dropdown from '../dropdown'

const IconWrapper = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:before {
        background: ${giphyBlack};
        content: '';
        height: 100%;
        left: 0;
        opacity: ${(props) => (props.noBackground ? 0 : 0.5)};
        position: absolute;
        top: 0;
        width: 100%;
    }
`

const Icon = styled.div`
    background: ${giphyBlack};
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    color: ${(props) => props.color};
    font-size: 22px;
    height: 50px;
    left: 10px;
    line-height: 54px;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 50px;
    ${({ theme: { iconTheme } }) => iconTheme};
`

const Tooltip = styled.div`
    bottom: 100%;
    left: -10px;
    line-height: 1.3;
    margin-bottom: 5px;
    position: absolute;
`

export default class StoryPreviewIcon extends PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        color: PropTypes.string,
        message: PropTypes.string,
        hasWatched: PropTypes.bool,
        hideIcons: PropTypes.bool,
    }

    state = {
        showDropdown: false,
    }

    onMouseOver = () => {
        this.setState({ showDropdown: true })
    }

    onMouseOut = () => {
        this.setState({ showDropdown: false })
    }

    render() {
        const { hasWatched, hideIcons, icon, color, message } = this.props
        const { showDropdown } = this.state
        return icon || hasWatched ? (
            <IconWrapper hasWatched={hasWatched} noBackground={hideIcons && !hasWatched}>
                {!hideIcons && icon && (
                    <Icon className={icon} color={color} onMouseOut={this.onMouseOut} onMouseOver={this.onMouseOver} />
                )}
                {showDropdown && (
                    <Tooltip>
                        <Dropdown isExtendUp nipX="45px">
                            {message}
                        </Dropdown>
                    </Tooltip>
                )}
            </IconWrapper>
        ) : null
    }
}
