import { FunctionComponent } from 'react'
import { IconProps } from '.'
import * as icons from '../../icons/player'

export const playerIcons = [
    'auto-play',
    'exit-fullscreen',
    'fullscreen',
    'loop',
    'pause',
    'play',
    'sound-off',
    'sound-on',
] as const

type PlayerIcon = typeof playerIcons[number]

export function playerIconComponent(icon: PlayerIcon): FunctionComponent<IconProps> {
    switch (icon) {
        case 'auto-play':
            return icons.AutoPlayIcon
        case 'exit-fullscreen':
            return icons.ExitFullscreenIcon
        case 'fullscreen':
            return icons.FullscreenIcon
        case 'loop':
            return icons.LoopIcon
        case 'pause':
            return icons.PauseIcon
        case 'play':
            return icons.PlayIcon
        case 'sound-off':
            return icons.SoundOffIcon
        case 'sound-on':
            return icons.SoundOnIcon
    }
}

export default PlayerIcon
