import { IGif } from '@giphy/js-types'
import loadable from '@loadable/component'
import { FC, useCallback, useState, useEffect } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import { useSameObjectRef } from 'shared/hooks/use-same-ref'
import { toggleEditMode, updateEditMode } from 'shared/redux/edit-mode'
import { getEditModeIsEditing } from 'shared/redux/reducers'
import AttributionPanel from 'mobile/components/attribution-panel'
import { IChannel } from 'shared/types/channel'
import {
    ChannelGifSelector,
    CollectionCabinetModal,
    EditCollectionModal,
    NewCollectionModal,
    NewCollectionProps,
} from 'shared/components/collections/modal'
import { EditPanelSections } from 'shared/components/edit-panel/gif-detail'
import ModalContext from './modal'
import { CurationContextManager } from 'site/curation-station/components/curation-context'
import { useLocalStorage } from 'react-use'

const GifEditPanel = loadable(
    () => import(/* webpackChunkName: "gifEditPanel" */ 'shared/components/edit-panel/gif-modal')
)

type Props = {
    isEditing: Boolean
    updateEditMode: (gif?: Object, channel?: Object) => void
    toggleEditMode: (isOn?: Boolean) => void
    children: React.ReactNode
}

type ChannelGifSelectorProps = { channelId: number; searchChannelId: number }

export type SetCollectionsProps = { isOpen: boolean; channelId?: number }

const ModalContextManager: FC<Props> = ({ isEditing, updateEditMode, toggleEditMode, children }) => {
    // new collection
    const [newCollectionProps, setShowNewCollection] = useState<NewCollectionProps | undefined>()
    const [collectionCabinetProps, setCollectionCabinetProps] = useState<SetCollectionsProps>({
        isOpen: false,
    })
    const openNewCollectionPanel = useCallback(
        (newCollectionProps: NewCollectionProps) => setShowNewCollection(newCollectionProps),
        []
    )
    // edit collection
    const [editCollection, setEditCollection] = useState<IChannel | undefined>()
    const openEditCollectionPanel = useCallback((channel: IChannel) => setEditCollection(channel), [])
    // add gifs from channel

    const [mobileActionsPanelGif, setMobileActionsPanelGif] = useState<IGif | undefined>()

    const [channelGifSelectorProps, setChannelGifsSelectorProps] = useState<ChannelGifSelectorProps | undefined>()
    const [editModeSection, setEditModeSection] = useState<EditPanelSections | undefined>()
    const openAddGifsToCollectionPanel = useCallback(
        (channelId: number, searchChannelId: number) => setChannelGifsSelectorProps({ searchChannelId, channelId }),
        []
    )
    // gif edit
    const openGifEditPanel = useCallback(
        (gif: IGif, section?: EditPanelSections) => {
            updateEditMode(gif)
            toggleEditMode(true)
            if (section) {
                setEditModeSection(section)
            }
        },
        [toggleEditMode, updateEditMode]
    )

    const [openEditPanel] = useLocalStorage('open-edit-panel')

    useEffect(() => {
        openEditPanel && toggleEditMode(true)
    }, [openEditPanel])

    const [allowBulkEditMode, setAlowBulkEditMode] = useState<boolean>(false)
    // gif edit
    const openBulkEditPanel = useCallback(
        (allowBulkEditMode) => {
            setAlowBulkEditMode(allowBulkEditMode)
            toggleEditMode(true)
        },
        [toggleEditMode]
    )

    const openCollectionCabinet = useCallback(
        (channelId?: number) => setCollectionCabinetProps({ isOpen: true, channelId }),
        []
    )

    const openMobileActionsPanel = useCallback((gif?: IGif) => {
        setMobileActionsPanelGif(gif)
    }, [])

    const value = useSameObjectRef({
        openNewCollectionPanel,
        openEditCollectionPanel,
        openGifEditPanel,
        openCollectionCabinet,
        openAddGifsToCollectionPanel,
        openBulkEditPanel,
        openMobileActionsPanel,
        setCollectionCabinetProps,
    })
    return (
        <ModalContext.Provider
            value={{
                ...value,
                isEditing,
            }}
        >
            {children}
            {isEditing && (
                <CurationContextManager>
                    <GifEditPanel
                        allowBulkEditMode={allowBulkEditMode}
                        activeSection={editModeSection}
                        onClose={() => {
                            setEditModeSection(undefined)
                            updateEditMode(undefined)
                        }}
                    />
                </CurationContextManager>
            )}
            {!!newCollectionProps && (
                <NewCollectionModal
                    newCollectionProps={newCollectionProps}
                    onClose={() => setShowNewCollection(undefined)}
                />
            )}
            {!!editCollection && (
                <EditCollectionModal channel={editCollection} onClose={() => setEditCollection(undefined)} />
            )}
            {collectionCabinetProps.isOpen && (
                <CollectionCabinetModal
                    channelId={collectionCabinetProps.channelId}
                    onClose={() => setCollectionCabinetProps({ isOpen: false })}
                />
            )}
            {channelGifSelectorProps && (
                <ChannelGifSelector
                    channelId={channelGifSelectorProps.channelId}
                    searchChannelId={channelGifSelectorProps.searchChannelId}
                    onClose={() => setChannelGifsSelectorProps(undefined)}
                />
            )}
            {mobileActionsPanelGif && <AttributionPanel gif={mobileActionsPanelGif} />}
        </ModalContext.Provider>
    )
}

export default connect(
    (state) => ({
        isEditing: getEditModeIsEditing(state),
    }),
    {
        updateEditMode,
        toggleEditMode,
    }
)(ModalContextManager)
