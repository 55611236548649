import { Container, Outline } from './style'

type Props = {
    animated?: boolean
    className?: string
    clipPath?: string
    outlineColor: string
    overflowColor: string
}

const DraggableBackground = ({ animated = false, className, clipPath, outlineColor, overflowColor }: Props) => (
    <Container className={className}>
        {clipPath && <Outline animated={animated} color={overflowColor} />}
        <Outline animated={animated} color={outlineColor} style={{ clipPath }} />
    </Container>
)

export default DraggableBackground
