import { GifOverlayProps } from '@giphy/js-components/dist/components/gif'
import { IGif } from '@giphy/js-types'
import { Gif } from '@giphy/react-components'
import { VideoPreviewLabel } from '@giphy/react-giphy-brand'
import { useCallback, useContext } from 'react'
import isMobile from 'shared/util/is-mobile'
import { parseUrl } from 'shared/util/url'
import styled, { css, keyframes } from 'styled-components'
import { classes } from 'ui'
import GifSelectionOverlay from '../gif-selector-overaly'
import { GridOverlayContext } from '../grid-overlay'
import Video from './video'
import Volume from './volume'

export const ASPECT_RATIO = 16 / 9

const EnterOverlay = keyframes`
    0% {
        filter: brightness(100%);
    }
    100% {
        filter: brightness(50%);
    }
`

const LeaveOverlay = keyframes`
    0% {
        filter: brightness(50%);
    }
    100% {
        filter: brightness(100%);
    }
`

const PreviewContainer = styled.div<{ withOverlay?: boolean; width?: number; height?: number; animation: boolean }>`
    position: relative;
    width: ${(props) => `${props.width}px` || 'auto'};
    height: ${(props) => `${props.height}px` || 'auto'};
    display: flex;
    cursor: pointer;
    ${(props) =>
        props.withOverlay
            ? css`
                  filter: brightness(50%);
                  animation: 0.4s ${props.animation && EnterOverlay} ease-in-out;
              `
            : css`
                  animation: 0.4s ${props.animation && LeaveOverlay} ease-in-out;
              `}

    img {
        object-fit: cover;
    }
`

const StyledPreviewLabel = styled(VideoPreviewLabel)`
    margin-left: auto;
    margin-right: auto;

    h4 {
        font-size: 17px;
        font-weight: bold;
    }
`

type Props = {
    gif: IGif
    width: number
    height?: number
    animation?: boolean
    withOverlay?: boolean
    className?: string
    showClipTitle?: boolean
    playOnHover?: boolean
    volumeSize?: number
    gridPosition?: number
    showAttribution?: boolean
    hideLinkAndCopy?: boolean
    noLink?: boolean
    onClick?: () => void
}

const VideoPlayOnHover = ({
    gif,
    width,
    height,
    className = '',
    animation = true,
    withOverlay = false,
    showClipTitle = false,
    showAttribution = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    noLink = false,
    playOnHover = !isMobile(),
    volumeSize = 38,
    gridPosition,
    hideLinkAndCopy = false,
    onClick = () => {},
    ...rest
}: Props) => {
    const previewHeight = height || width / ASPECT_RATIO
    const overlay = useCallback(
        (props: GifOverlayProps) => (
            <Video
                {...props}
                width={width}
                volumeSize={volumeSize}
                gridPosition={gridPosition}
                hideLinkAndCopy={hideLinkAndCopy}
            />
        ),
        [gif.id]
    )
    const overlayWithoutAutoPlay = useCallback(
        () => <Volume muted isHovered={false} volumeSize={volumeSize} gif={gif} hideLinkAndCopy={hideLinkAndCopy} />,
        []
    )
    const { onGifClick, selectedGifs, iSelectionMode } = useContext(GridOverlayContext)
    const { title, user } = gif
    const { pathname } = parseUrl(gif.url)
    return (
        <div className={className} onClick={onClick} {...rest}>
            <PreviewContainer
                onClick={() => (document.location.href = pathname)}
                animation={animation}
                withOverlay={withOverlay}
                className={`${classes.CY_CLIP_PREVIEW} ${gif?.id}`}
            >
                <Gif
                    gif={gif}
                    width={width}
                    height={previewHeight}
                    overlay={playOnHover ? overlay : overlayWithoutAutoPlay}
                    noLink
                />
                {iSelectionMode && (
                    <GifSelectionOverlay gif={gif} index={selectedGifs.indexOf(gif.id)} onClick={onGifClick} />
                )}
            </PreviewContainer>
            {showClipTitle && (
                <StyledPreviewLabel
                    href={gif.url}
                    onClick={() => (document.location.href = pathname)}
                    showAttribution={showAttribution}
                    title={title}
                    user={user}
                    width={width}
                />
            )}
        </div>
    )
}

export default VideoPlayOnHover
