import { createContext, useRef, MutableRefObject } from 'react'

type ItemsContext = {
    items: MutableRefObject<HTMLDivElement[]>
    getItemPosition: (index: number) => number
}

export const ItemsContext = createContext({} as ItemsContext)

const ItemsContextProvider = ({ children }) => {
    const items = useRef<HTMLDivElement[]>([])

    const getItemPosition = (index: number) => {
        if (items.current) {
            const el = items.current[index]
            const top = el.offsetTop
            const height = el.offsetHeight
            return top - (window.innerHeight - height) / 2
        }

        return 0
    }

    return (
        <ItemsContext.Provider
            value={{
                items,
                getItemPosition,
            }}
        >
            {children}
        </ItemsContext.Provider>
    )
}

export default ItemsContextProvider
