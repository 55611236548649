import { giphyWhite } from '@giphy/colors'
import { mobile } from 'shared/util/media-query'
import styled, { css, keyframes } from 'styled-components'

const SHADOW = `drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25))`

const EnterAnimation = keyframes`
    from {
        transform: translateY(125px);
        opacity: 0;
        z-index: -1;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
`

const LeaveAnimation = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        transform: translateY(125px);
        opacity: 0;
        z-index: -1;
    }
`

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 125px;
    margin-top: 50px;
`

export const SectionImage = styled.img`
    height: 149px;
    width: 149px;
`

export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const Title = styled.h1`
    font-family: NexaBlack;
    font-size: 46px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 251px;
    color: ${giphyWhite};
`

export const SubTitle = styled.h4`
    font-size: 22px;
    font-family: 'interface';
    opacity: 0.8;
    font-weight: normal;
    margin-top: 17px;
`

export const ValuesBox = styled.div`
    display: flex;
    width: 1182px;
    height: 622px;
    border-radius: 8px;
    background: #212121;
    margin-top: 44px;
    ${mobile.css`
        width: 344px;
        height: 427px;
        margin-top: 15px;
    `};
`

export const LeftColumn = styled.div<{ gradient: string }>`
    display: flex;
    flex-direction: column;
    width: 592px;
    border-radius: 8px;
    background: ${(props) => props.gradient};
    overflow: hidden;
`

export const ValueImageBox = styled.div`
    height: 312px;
    width: 592px;
    position: relative;
    ${mobile.css`
        height: 133px;
        width: 344px;
        top: 0;
        left: 0;
    `}
`

export const ValueImage = styled.img<{ active: boolean }>`
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.25s;
    border-radius: 6px;
`

export const ValueContent = styled.div`
    padding: 39px 39px 25px 39px;
    flex: 1;
    text-align: left;
    position: relative;
    ${mobile.css`
        padding: 30px 0 20px 0;
        text-align: center;
    `}
`

export const ValueContentTitle = styled.div`
    font-family: NexaBlack;
    font-size: 28px;
    filter: ${SHADOW};
    ${mobile.css`
        width: 240px;
    `}
`

export const ValueContentDescription = styled.div`
    margin-top: 17px;
    margin-bottom: 18px;
    font-family: 'interface';
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.18px;
    line-height: 22px;
    filter: ${SHADOW};
    ${mobile.css`
        height: 153px;
        font-size: 17px;
        margin: 0;
        padding: 15px 30px 0 30px;;
    `}
`

export const ValueAnimation = styled.div<{ active: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 39px 39px 25px 39px;
    animation: ${({ active }) => (active ? EnterAnimation : LeaveAnimation)} 325ms;
    animation-fill-mode: forwards;
    transform: translateY(200px);
    ${mobile.css`
        padding: 39px 0px 25px 0px;
    `}
`

export const ValueSeeAllButton = styled.a`
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    span {
        opacity: 0.7;
        margin-right: 8px;
    }

    i {
        font-size: 12px;
    }
`

export const Nav = styled.button`
    background: none;
    line-height: inherit;
    height: auto;
    width: auto;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    i {
        font-size: 12px;
    }
    ${mobile.css`
        font-weight: normal;
        color: ${giphyWhite};
        padding-top: 8px;
        i {
            font-size: 25px;
        }
    `}
`

export const NavText = styled.span`
    font-size: 18px;
    font-weight: bold;
    opacity: 0.7;
`

export const NavLeft = styled(Nav)`
    position: absolute;
    left: 39px;
    bottom: 25px;
    span {
        margin-left: 14px;
    }
`

export const NavRight = styled(Nav)`
    position: absolute;
    right: 39px;
    bottom: 25px;
    span {
        margin-right: 14px;
    }
`

export const NavTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 42px;
`

export const RightColumn = styled.div`
    flex: 1;
    padding: 147px 74px 102px 74px;
    display: flex;
    position: relative;
`

export const List = styled.div`
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin: -5px;
`

export const ListItem = styled.div`
    position: relative;
    height: 44px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5px;
    margin-bottom: 7px;
    font-family: NexaBlack;
    font-size: 20px;
`

export const ListItemButton = styled.div<{ active: boolean; gradient: string }>`
    height: 44px;
    width: 100%;
    border-radius: 22px;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.25s;
    ${(props) =>
        props.active &&
        css<{ gradient: string }>`
            background: ${(props) => props.gradient};
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
        `}

    &:hover {
        background: ${(props) => props.gradient};
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
    }
`

export const ButtonText = styled.div`
    cursor: pointer;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
`

export const BoxImage = styled.img`
    height: 177px;
    width: 223px;
    position: absolute;
    bottom: -22px;
    right: 53px;
`
