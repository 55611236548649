import { lazy, Suspense } from 'react'

const LazyVideo = lazy(() => import(/* webpackChunkName: "desktopVideos" */ './videos'))
export const VideosRoute = () => (
    <Suspense fallback={null}>
        <LazyVideo />
    </Suspense>
)

const VideoDetail = lazy(() => import(/* webpackChunkName: "desktopVideoDetail" */ './video-detail'))
export const VideoDetailRoute = () => (
    <Suspense fallback={null}>
        <VideoDetail />
    </Suspense>
)

export default VideosRoute
