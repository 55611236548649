import { useDraggable } from '@dnd-kit/core'
import { uniqueId } from 'lodash'
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { ControlSize } from 'types'
import { useNodeWidth } from 'utils'
import { SliderLayoutType } from '..'
import { Container, Fill, HandleCircle, HandleRectangle } from './style'

type Props = {
    children?: ReactNode
    className?: string
    disabled?: boolean
    dndId?: string
    fillColor?: string
    handleColor: string
    layout: SliderLayoutType
    size: ControlSize
    trackColor: string
    value?: number
    onChange?: (value: number) => void
    onSliderValueChange?: (value: number) => void
    customHandleTransform?: string
}

const SliderDnd = ({
    className,
    disabled = false,
    dndId,
    fillColor,
    handleColor = '#fff',
    layout,
    size,
    trackColor,
    value: valueProp = 0,
    onChange,
    onSliderValueChange,
    customHandleTransform = '',
}: Props) => {
    const idRef = useRef<string>(dndId || uniqueId('slider'))
    const baseValueRef = useRef<number>(valueProp)
    const Handle = layout === 'circle' ? HandleCircle : HandleRectangle

    const [value, setValue] = useState<number>(valueProp)
    const { attributes, isDragging, listeners, node, setNodeRef, transform } = useDraggable({
        disabled,
        id: idRef.current,
        data: { control: 'slider', controlId: idRef.current, controlValue: value },
    })
    const width = useNodeWidth(node)

    const onMouseDown = (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
        const { pageX } = e.nativeEvent
        const { left } = e.currentTarget.getBoundingClientRect()

        baseValueRef.current = (pageX - left) / width
        setValue(baseValueRef.current)
    }

    useEffect(() => {
        if (!transform || !isDragging) return

        const valueShift = transform.x / width
        const newValue = Math.max(0, Math.min(1, baseValueRef.current + valueShift))
        setValue(newValue)
        onSliderValueChange?.(newValue)
    }, [transform?.x])

    useEffect(() => {
        baseValueRef.current = valueProp
        setValue(valueProp)
    }, [valueProp])

    useEffect(() => {
        if (!isDragging && value !== valueProp) {
            onChange?.(value)
        }
    }, [isDragging])

    return (
        <Container
            {...listeners}
            {...attributes}
            $layout={layout}
            $size={size}
            $trackColor={trackColor}
            className={className}
            ref={setNodeRef}
            onMouseDown={onMouseDown}
        >
            {fillColor && <Fill style={{ backgroundColor: fillColor, width: `${width * value}px` }} />}
            <Handle
                style={{
                    backgroundColor: handleColor,
                    transform: `translateX(${width * value}px) ${customHandleTransform}`,
                }}
            />
        </Container>
    )
}

export default SliderDnd
