import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import Radio from '../inputs/radio'
import RatingData from 'shared/util/ratings'
import css from './rating.css'
import { filter } from 'lodash'

const Rating = ({ className, value, onChange, allowUnrated = true }) => {
    const data = allowUnrated ? RatingData : filter(RatingData, (d) => d.value !== '')
    return (
        <fieldset className={className} onChange={(e) => onChange(e.target.value)}>
            {data.map(({ rating, value: ratingValue, description }) => (
                <Radio
                    key={rating}
                    className={css.rating}
                    group="rating"
                    value={ratingValue}
                    title={description}
                    isChecked={ratingValue === value}
                >
                    {rating.toUpperCase()}
                </Radio>
            ))}
        </fieldset>
    )
}

Rating.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allowUnrated: PropTypes.bool,
}

export default Rating
