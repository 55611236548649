import styled from 'styled-components'

export const Handle = styled.div<{ $color: string }>`
    align-items: center;
    box-sizing: border-box;
    cursor: ew-resize;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;

    &:before {
        background: ${({ $color }) => $color};
        border-radius: 2px;
        content: '';
        display: block;
        height: 60%;
        pointer-events: none;
        width: 4px;
    }
`
