import { useContext } from 'react'
import styled from 'styled-components'
import { giphyWhite } from '../../../css/colors'
import { SLIDER } from './z-index'
import VideoContext from '../video-context'

const Container = styled.div`
    height: 100%;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: ${SLIDER};
`

const Value = styled.div`
    background: ${giphyWhite};
    height: 5px;
    position: absolute;
    width: 5px;
    bottom: -1px;
    left: 0;
`

const Slider = () => {
    const { isSmall, playhead, getDuration } = useContext(VideoContext)
    const duration = getDuration()
    if (duration < 6) return null
    const progress = playhead / duration
    const progressValue = `${Math.round(progress * 100)}%`
    const style = { width: progressValue }
    return !isSmall ? (
        <Container>
            <Value style={style} />
        </Container>
    ) : null
}

export default Slider
