import { getResults } from 'shared/api'
import { GET, POST } from 'shared/util/fetch-methods'

export const getExperiences = () => {
    return getResults('/api/v3/users/experiences/mine/', GET)
}

export const updateExperience = (experience_id: string, status: number) => {
    let params = {
        experience_id,
        status,
    }
    return getResults('/api/v3/users/experiences', POST(params, true))
}
