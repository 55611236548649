import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import CreationToolGradient from '../types/gradient'
import { GradientBlock } from './blocks'

const Container = styled.div`
    background: #000;
    border-radius: 17px;
    cursor: pointer;
    display: flex;
    height: 34px;
    overflow: hidden;
    position: relative;
    width: 100%;
`

const Option = styled.div<{ active: boolean }>`
    color: ${({ active }) => (active ? '#000' : '#fff')};
    font-size: 15px;
    font-weight: bold;
    line-height: 34px;
    pointer-events: none;
    position: relative;
    text-align: center;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    transition: color 0.3s ease-out;
    width: 100%;
`

const Gradient = styled(GradientBlock)<{ active: boolean }>`
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width: 50%;

    ${({ active }) =>
        !active &&
        css`
            transform: translate3d(100%, 0, 0);
        `}
`

type Props = {
    className?: string
    isOn: boolean
    onLabel?: ReactNode
    offLabel?: ReactNode
    onToggle: (isOn: boolean) => void
    reverseOrder?: boolean
}

const CreationToolToggle = ({
    className,
    isOn,
    onLabel = 'On',
    offLabel = 'Off',
    onToggle,
    reverseOrder = false,
}: Props) => {
    const gradient = CreationToolGradient.colors(
        reverseOrder
            ? isOn
                ? CreationToolGradient.PurpleRed
                : CreationToolGradient.BlueGreen
            : isOn
            ? CreationToolGradient.BlueGreen
            : CreationToolGradient.PurpleRed
    )
    return (
        <Container className={className} onClick={() => onToggle(!isOn)}>
            <Gradient colors={gradient} active={isOn} />
            {reverseOrder ? (
                <>
                    <Option active={isOn}>{offLabel}</Option>
                    <Option active={!isOn}>{onLabel}</Option>
                </>
            ) : (
                <>
                    <Option active={isOn}>{onLabel}</Option>
                    <Option active={!isOn}>{offLabel}</Option>
                </>
            )}
        </Container>
    )
}

export default CreationToolToggle
