import { IGif } from '@giphy/js-types'
import { Loader } from '@giphy/react-components'
import EditModal from 'mobile/components/gif-detail/primary/edit/edit-modal'
import { useContext, useEffect, useState } from 'react'
import { mobileFetch } from 'shared/api'
import UserContext from 'shared/contexts/user'
import { getGifId } from 'shared/util/url'
const EditGif = ({
    match: {
        params: { id },
    },
}: {
    match: { params: { id: string } }
}) => {
    const { user } = useContext(UserContext)
    const [gif, setGif] = useState<IGif | undefined>()
    const gifId = getGifId(id)

    useEffect(() => {
        const getGif = async () => {
            const { data } = await mobileFetch.gif(gifId)
            setGif(data)
        }
        getGif()
    }, [id])
    return gif && user?.loggedIn && user?.permissions ? (
        <div>
            <EditModal gif={gif} loggedInUser={user} />
        </div>
    ) : (
        <Loader />
    )
}
export default EditGif
