import { giphyWhite, giphyPurple, giphyIndigo } from '@giphy/colors'
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    float: left;
    display: flex;
    margin: 11px 1px;
`

const Anchor = styled.a`
    width: 80px;
    height: 36px;
    background: ${giphyPurple};
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 400% !important;
    border-radius: 2px;

    &:first-child {
        background: ${giphyIndigo};
        margin-right: 4px;
    }

    span {
        color: ${giphyWhite};
        font-size: 14px;
        font-weight: 600;
    }
`

const CreationButton = () => {
    const [startingMousePosition, setStartingMousePosition] = useState<number>(0)
    const [backgroundPosition, setBackgroundPosition] = useState<number>(-400)

    return (
        <Container
            onMouseEnter={(e) => {
                setStartingMousePosition(e.pageX)
            }}
            onMouseMove={(e) => {
                const mousePositionDiff = e.pageX - startingMousePosition
                const percent = (mousePositionDiff * 100) / 150 - 106

                setBackgroundPosition(percent)
            }}
        >
            <Anchor rel="nofollow" href="/upload" style={{ backgroundPositionX: `${backgroundPosition}px` }}>
                <span>Upload</span>
            </Anchor>
            <Anchor rel="nofollow" href="/create/gifmaker" style={{ backgroundPositionX: `${backgroundPosition}px` }}>
                <span>Create</span>
            </Anchor>
        </Container>
    )
}

export default CreationButton
