import { IGif } from '@giphy/js-types'

const getAspectRatio = ({ video }: IGif) => {
    if (!video) {
        return 1
    }
    const {
        assets: { source },
    } = video
    return source.width / source.height
}

export default getAspectRatio
