import { giphyBlue, giphyIndigo, giphyLightGrey } from '@giphy/colors'
import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import LogoShapesAnimation from 'ui/src/components/logo-shapes-animation'
import { WhenInView } from 'shared/util/in-view'
import styled from 'styled-components'
import { ANIMATION_SPEED, COASTER_VIDEO_RATIO, CONTENT_WIDTH, IMAGE_PATH } from '../constants'
import { AboutPageContext } from './../context'
import Navigation from './navigation'

const Container = styled.div`
    position: relative;
`

const Content = styled.div`
    color: ${giphyLightGrey};
    position: relative;

    h2 {
        background: linear-gradient(45deg, ${giphyIndigo} 0%, ${giphyBlue} 100%);
        background-clip: text;
        display: inline-block;
        font-family: inherit;
        font-size: 0.8em;
        font-weight: bold;
        margin: 0 auto 0.8em;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    strong {
        color: #fff;
        font-weight: bold;
        text-decoration: underline;
    }
`

const Logo = styled.img`
    display: block;
    height: 7em;
    margin: 0 auto;
    width: auto;
`

const AnimationWrapper = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

const VideoWrapper = styled.div`
    display: block;
    margin: -4em auto 0;
    max-width: ${CONTENT_WIDTH}px;
    position: relative;
    transform: translateY(6em);
    width: 100%;
    z-index: 10;
`

const Video = styled.video`
    background: #000;
    cursor: pointer;
    display: block;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-out;
    width: 100%;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
`

const AboutPageHeader = () => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { windowWidth, windowHeight } = useContext(AboutPageContext)
    const { y: windowScroll } = useWindowScroll()
    const [animationHeight, setAnimationHeight] = useState<number>(400)
    const [videoTransform, setVideoTransform] = useState<string | null>(null)
    const videoHeight: number =
        (windowWidth ? Math.min(windowWidth, CONTENT_WIDTH) : CONTENT_WIDTH) * COASTER_VIDEO_RATIO

    useEffect(() => {
        setVideoTransform(null)
    }, [windowScroll])

    useEffect(() => {
        if (!containerRef.current) return
        setAnimationHeight(containerRef.current.offsetHeight)
    }, [containerRef, windowWidth])

    const onVideoClick = (e: SyntheticEvent) => {
        const isFullscreen: boolean = !!videoTransform
        if (isFullscreen) {
            setVideoTransform(null)
            return
        }
        const { top, height } = e.currentTarget.getBoundingClientRect()
        const scale: number = Math.max(windowHeight / videoHeight, windowWidth / CONTENT_WIDTH)
        const translateY: number = top + height / 2 - windowHeight / 2
        setVideoTransform(`scale(${scale}) translate3d(0, ${-translateY / scale}px, 0)`)
    }

    return (
        <Container ref={containerRef}>
            {!videoTransform && (
                <AnimationWrapper>
                    <WhenInView height={animationHeight}>
                        <LogoShapesAnimation width={windowWidth} height={animationHeight} speed={ANIMATION_SPEED} />
                    </WhenInView>
                </AnimationWrapper>
            )}
            <Navigation />
            <Content data-padded>
                <Logo src={`${IMAGE_PATH}/stickers/logo-spin.gif`} alt="" />
                <h2>Animated Search Engine</h2>
                <h1>GIPHY is the platform that animates your world.</h1>
                <p>
                    GIPHY is not just GIFs... we are Stickers, Clips, and much more. Every day, we are serving{' '}
                    <strong>10+&nbsp;billion</strong> pieces of GIPHY content to a daily user reach of{' '}
                    <strong>1+&nbsp;billion</strong> people, helping them express themselves and make their everyday
                    conversations more entertaining.
                </p>
                <VideoWrapper>
                    <WhenInView height={videoHeight}>
                        <Video
                            src={`${IMAGE_PATH}/coaster.mp4`}
                            style={videoTransform ? { transform: videoTransform } : {}}
                            onClick={onVideoClick}
                            autoPlay
                            playsInline
                            loop
                            muted
                        />
                    </WhenInView>
                </VideoWrapper>
            </Content>
        </Container>
    )
}

export default AboutPageHeader
