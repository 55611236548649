import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import cookie from 'cookie'
import { omit } from 'lodash'
import * as colors from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import { Button } from 'shared/components/buttons'
import { DarkFields } from 'shared/components/form/apply-form'
import { FIELDS, FieldContainer as FieldContainer_ } from 'shared/components/form/fields'
import SelectList from 'shared/components/form/ui/select-list'
import UserContext from 'shared/contexts/user'
import { getReportingOptions } from 'shared/api/detail'

export const ColoredStrip = styled.div`
    background: linear-gradient(240deg, #f66 7.79%, #e646b6 92.21%);
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 4px 0 0;
`

const StyledDarkFields = styled(DarkFields)<{ isHalf: boolean }>`
    ${FieldContainer_} {
        width: ${({ isHalf }) => (isHalf ? '275px' : '100%')};

        input {
            margin: 0;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }
`

const StyledSelectList = styled(SelectList)`
    span {
        font-size: 17px;
    }
`

const Checkbox = styled.div<{ checked: boolean }>`
    background: ${colors.giphyDarkGrey};
    border-radius: 3px;
    height: 24px;
    width: 24px;
    margin: 0 7px;
    cursor: pointer;

    border-radius: 4px;
    border: 2px solid ${(props) => (props.checked ? '#0f9' : '#5c5c5c')};
    background: #121212;

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        filter: grayscale(1);
        ${(props) =>
            props.checked &&
            css`
                background-image: url('/static/img/check-mark.png');
                background-size: 64%;
                background-repeat: no-repeat;
                background-position: center;
            `}

        @media (max-width: 768px) {
            transform: translate(-10px, 1px);
        }
    }
`

const CheckboxLabel = styled.span<{ checked: boolean }>`
    color: ${(props) => (props.checked ? '#FFF' : '#616060')};

    font-family: InterFace;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
`

const CancelButton = styled.p`
    color: var(--Grey-07-Brooklyn-Snow, #a6a6a6);
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.3px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;

    font-family: InterFace;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
`
const SubmitButton = styled(Button)<{ disabled: boolean }>`
    width: 100%;
    font-size: 16px;
    line-height: 48px;
    height: auto;
    border-radius: 2px;
    margin-top: 42px;
    ${({ disabled }) => !disabled && 'background: linear-gradient(45deg, rgb(0, 204, 255) 0%, rgb(97, 87, 255) 100%);'}
    &:hover {
        filter: brightness(1.1);
    }
`

const ReportModalContainer = styled.div`
    position: static;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
    margin-bottom: 150%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`
const ReportDialogForm = styled.form`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 663px;
    height: auto;
    padding: 40px;
    background-color: green;
    z-index: 999;
    background: ${colors.giphyBlack};
    transition: all 1s ease-out;
    border-radius: 4px;
    border: 1px solid #3e3e3e;

    @media (max-width: 768px) {
        width: 80%;
        transform: none;
        top: 0px;
        left: 0px;
    }
`

const FieldRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        display: inline;
    }
`

const CheckboxFieldRow = styled(FieldRow)`
    @media (max-width: 768px) {
        display: flex;
    }
`

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &:nth-child(2) {
        margin-left: 30px;
        @media (max-width: 768px) {
            margin-left: 0px;
        }
    }
`
const DropdownFieldContainer = styled(FieldContainer)<{ fixedWidth?: number }>`
    ${({ fixedWidth }) => fixedWidth && 'width:' + fixedWidth + 'px;'}
    margin-bottom: 20px;

    & > div > div:first-child > div {
        background-color: #2e2e2e;
        height: 42px;
        width: 42px;
    }

    & > div > div {
        background-color: #212121;
    }
`

export const FieldExplanation = styled.span`
    color: ${colors.giphyLightGrey};
    font-size: 16px;
    align-items: center;
`
const RowLabelWithAsterisk = styled.div<{ displayAsterisk: boolean }>`
    color: white;
    font-size: 15px;
    margin: 8px 0px;
    font-weight: bold;
    justify-content: flex-start;

    div {
        flex-direction: row;
    }
    span {
        display: flex;
    }
    &::after {
        content: ' *';
        color: var(--color-grey-5-greg, #4e4e4e);
        font-family: InterFace;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        display: ${({ displayAsterisk }) => (displayAsterisk ? 'inline' : 'none')};
    }

    @media (max-width: 768px) {
        text-align: left;
    }
`
const MessagingHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: ${colors.giphyLightestGrey};
    font-family: interface;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 40px;
`

const HeadingText = styled.span`
    color: white;
    font-family: nexablack;
    font-size: 34px;
    line-height: 42px;
    margin-top: 3px;
    font-weight: normal;
    display: table;
    margin: 0 auto;
`

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: white;
    background-color: ${colors.giphyDarkestGrey};
    margin-top: 5px;
    padding: 10px;
    resize: none;
    border: none;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${colors.giphyLightGrey};
    }

    @media (max-width: 768px) {
        width: auto;
    }
`

const CheckboxContainer = styled.div`
    gap: 15px;
    width: 300px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5px;
`

const CheckboxTemp = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const OrganizationSection = styled.div<{ isExpand: boolean }>`
    height: ${(props) => (props.isExpand ? 'auto' : '0')};
    opacity: ${(props) => (props.isExpand ? '1' : '0')};
    overflow: hidden;
    transition: all 1s ease-out;
`

// success

const ReportSubmittedContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 672px;
    height: 390px;
    padding: 40px 40px 20px;
    z-index: 999;
    background: rgb(18, 18, 18);
    overflow-y: hidden;

    @media (max-width: 768px) {
        width: 80%;
        height: 330px;
        overflow: hidden;
    }
`

export const CloseButton = styled.span`
    width: 18px;
    height: 18px;
    position: absolute;
    top: 25px;
    right: 25px;
    background-size: 100%;
    cursor: pointer;
    background-image: url('/static/img/thick-close-btn.png');
`

const FormCloseButton = styled(CloseButton)`
    display: none;

    @media (max-width: 768px) {
        display: inherit;
    }
`

export const InputField = styled.span`
    margin-top: -21px;
    width: 260px;

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const FullInputField = styled.span`
    margin-top: -20px;
`

const Inner = styled.div`
    text-align: center;
    width: 560px;
    margin: auto;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
        color: var(--color-grey-9-white, #fff);

        text-align: center;
        font-family: nexablack;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%;
    }

    p {
        color: var(--color-grey-8-almost-white, #d8d8d8);
        text-align: center;

        font-family: interface;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
    }

    @media (max-width: 768px) {
        width: 80%;
    }
`

const Footer = styled.div`
    background-color: #212121;
    height: 70px;
    width: 673px;
    margin-left: -40px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        width: 485px;
        text-align: center;
        color: var(--color-symantic-paragraph, #a6a6a6);
        text-align: center;
        font-family: InterFace;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    b {
        font-weight: 700;
    }

    @media (max-width: 768px) {
        background-color: #212121;
        height: 70px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 40px;
    }
`

type Props = {
    toggleForm: () => void
    gif: string
    platform?: string
}

type ReporterReasonChoices = {
    [key: string]: string
}

const CheckboxOption = ({ label, isChecked, onClick }) => (
    <CheckboxTemp>
        <Checkbox checked={isChecked} onClick={onClick} />
        <CheckboxLabel checked={isChecked}>{label}</CheckboxLabel>
    </CheckboxTemp>
)

const ANONYMUS_REASONS = ['grooming', 'csam']
const ILLEGAL_REASONS = ['csam', 'grooming', 'terrorism', 'sale', 'activity']

const ReportSubmitted = ({ toggleForm, reason }) => {
    const modalRef = useRef<HTMLDivElement | null>(null)
    const DefaultCopy = () => (
        <p>
            GIPHY&apos;s moderation team will review your report and take appropriate action. This typically takes 24
            hours.
        </p>
    )
    const IllegalCopy = () => (
        <p>
            Thank you! If you have provided an email address, you will receive an email to inform you of the action
            we&apos;ve taken in response to your report once it has been reviewed. This typically takes 24 hours.
        </p>
    )

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                toggleForm()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [modalRef, toggleForm])

    const isIllegal = useMemo(() => ILLEGAL_REASONS.includes(reason), [reason])
    return (
        <ReportSubmittedContainer ref={modalRef}>
            <ColoredStrip />
            <CloseButton onClick={toggleForm}></CloseButton>
            <Inner>
                <h4>Thank You!</h4>
                {isIllegal ? <IllegalCopy /> : <DefaultCopy />}
            </Inner>
            <Footer>
                <p>
                    If you would like to report another piece of content, please select the `<b>Report this GIF</b>`
                    button that appears next to the content in question.
                </p>
            </Footer>
        </ReportSubmittedContainer>
    )
}

const ReportModal = ({ toggleForm, gif, platform = 'web' }: Props) => {
    const formRef = useRef<HTMLFormElement | null>(null)
    const user = useContext(UserContext)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isOrgatisation, setIsOrgatisation] = useState<boolean | null>(null)
    const [isRelief, setIsRelief] = useState<boolean | null>(null)
    const [selectedReason, setSelectedReason] = useState<string | null>(null)
    const [readyForSubmit, setReadyForSubmit] = useState<boolean>(false)
    const [responseErrors, setResponseErrors] = useState<object>({})

    const [reportReasons, setReportReasons] = useState<ReporterReasonChoices>({})

    const [nameValid, setNameValid] = useState<boolean>(!!user?.user.displayName)
    const [emailValid, setEmailValid] = useState<boolean>(!!user?.user.email)
    const [reasonValid, setReasonValid] = useState<boolean>(false)
    const [isOrgValid, setOrgValid] = useState<boolean>(false)

    const isAnonymus = useMemo(() => ANONYMUS_REASONS.includes(selectedReason as string), [selectedReason])

    const askServerSideErrors = (field) => {
        if (field in responseErrors) {
            setResponseErrors(omit(responseErrors, field))
        }
    }

    const handleFieldValidation = (event) => {
        setReasonValid(event.target.value !== '')
    }

    useEffect(() => {
        const isOrganizationValid = isOrgatisation !== null && (isOrgatisation ? isOrgValid : true)
        const isFormValid =
            nameValid && emailValid && reasonValid && selectedReason && isOrganizationValid && isRelief === true

        setReadyForSubmit(!!(isAnonymus && isRelief) || !!isFormValid)
    }, [nameValid, emailValid, isOrgatisation, selectedReason, isOrgValid, isRelief, isAnonymus, reasonValid])

    useEffect(() => {
        setEmailValid(!!user?.user?.email)
    }, [user?.user?.email])

    useEffect(() => {
        setNameValid(!!user?.user.displayName)
    }, [user?.user.displayName])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (formRef.current && !formRef.current.contains(event.target)) {
                toggleForm()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [formRef, toggleForm])

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (!formRef.current) {
            return
        }

        const { csrftoken } = cookie.parse(document.cookie)
        const formData = new FormData(formRef.current)
        formData.append('reporter_reason', selectedReason as string)
        formData.append('platform', platform)
        formData.append('gif_id', gif)

        let payload = {}
        formData.forEach((value, key) => (payload[key] = value))

        const response = await fetch(`/api/v3/reports/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(payload),
        })

        if (response.ok) {
            setFormSubmitted(true)
        } else {
            const data = await response.json()
            setResponseErrors(data)
        }
    }

    useEffect(() => {
        const fetchReportReasons = async () => {
            const response = await getReportingOptions()
            const choices = response.actions.POST.reporter_reason.choices
            const reasons = Object.fromEntries(choices.map((item) => [item.display_name, item.value]))
            setReportReasons(reasons)
        }

        fetchReportReasons()
    }, [])

    const RowLabel = (props) => (
        <RowLabelWithAsterisk displayAsterisk={!isAnonymus}>{props.children}</RowLabelWithAsterisk>
    )

    return (
        <>
            <ReportModalContainer>
                {!formSubmitted ? (
                    <ReportDialogForm method="POST" onSubmit={submitForm} ref={formRef}>
                        <ColoredStrip />
                        <FormCloseButton onClick={toggleForm} />
                        <MessagingHeader>
                            <HeadingText>Report this Content</HeadingText>
                        </MessagingHeader>
                        <DropdownFieldContainer>
                            <RowLabel>Reason for reporting this content</RowLabel>
                            <StyledSelectList
                                onChange={(choice) => setSelectedReason(reportReasons[choice])}
                                options={Object.keys(reportReasons)}
                                placeholder="Must select an option"
                                themeId={'dark'}
                                width={'100%'}
                                height={'42px'}
                                name={'reporter_reason'}
                                allowMultiple={false}
                            />
                        </DropdownFieldContainer>
                        <FieldRow style={{ marginBottom: 15 }}>
                            <FieldContainer>
                                <RowLabel>Name</RowLabel>
                                <InputField>
                                    <StyledDarkFields
                                        isHalf={true}
                                        noTooltip
                                        onValidChange={(isValid) => setNameValid(isValid)}
                                        showFields={[FIELDS.contact]}
                                        customFieldProps={[
                                            [
                                                FIELDS.contact,
                                                {
                                                    placeholder: `Enter Name`,
                                                    defaultValue: user?.user.displayName,
                                                    name: 'reporter_name',
                                                },
                                            ],
                                        ]}
                                        user={user as unknown as IUser}
                                        ackResponseError={askServerSideErrors}
                                        aggroErrorMessages={!isAnonymus}
                                        responseErrors={responseErrors}
                                    />
                                </InputField>
                            </FieldContainer>
                            <FieldContainer>
                                <RowLabel>Email</RowLabel>
                                <InputField>
                                    <StyledDarkFields
                                        isHalf={true}
                                        noTooltip
                                        onValidChange={(isValid) => setEmailValid(isValid || !!user?.user.email)}
                                        showFields={[FIELDS.email]}
                                        customFieldProps={[
                                            [
                                                FIELDS.email,
                                                {
                                                    defaultValue: user?.user.email,
                                                    name: 'reporter_email',
                                                    optional: true,
                                                },
                                            ],
                                        ]}
                                        user={user as unknown as IUser}
                                        ackResponseError={askServerSideErrors}
                                        aggroErrorMessages={!isAnonymus}
                                        responseErrors={responseErrors}
                                    />
                                </InputField>
                            </FieldContainer>
                        </FieldRow>

                        <FieldRow>
                            <FieldContainer>
                                <RowLabel>Please explain why you believe this content should be removed</RowLabel>
                                <StyledTextArea
                                    placeholder="Enter details"
                                    name="reporter_comment"
                                    onInput={handleFieldValidation}
                                />
                            </FieldContainer>
                        </FieldRow>
                        <CheckboxFieldRow>
                            <RowLabel>
                                Are you contacting us from an organization designated as a Trusted Flagger by the
                                Digital Services Coordinator?
                            </RowLabel>
                            <CheckboxContainer>
                                <CheckboxOption
                                    label="Yes"
                                    isChecked={isOrgatisation === true}
                                    onClick={() => setIsOrgatisation(true)}
                                />
                                <CheckboxOption
                                    label="No"
                                    isChecked={isOrgatisation === false}
                                    onClick={() => setIsOrgatisation(false)}
                                />
                            </CheckboxContainer>
                        </CheckboxFieldRow>
                        <OrganizationSection isExpand={isOrgatisation || false}>
                            <FieldRow>
                                <FieldContainer>
                                    <RowLabel>What is the name of your organization?</RowLabel>
                                    <FullInputField>
                                        <StyledDarkFields
                                            noTooltip
                                            onValidChange={setOrgValid}
                                            showFields={[FIELDS.contact]}
                                            customFieldProps={[
                                                [
                                                    FIELDS.contact,
                                                    {
                                                        name: 'trusted_flagger_org',
                                                        placeholder: `Enter Organization Name`,
                                                    },
                                                ],
                                            ]}
                                            user={user as unknown as IUser}
                                            ackResponseError={askServerSideErrors}
                                            aggroErrorMessages={!isAnonymus}
                                            responseErrors={responseErrors}
                                        />
                                    </FullInputField>
                                </FieldContainer>
                            </FieldRow>
                        </OrganizationSection>
                        <CheckboxFieldRow>
                            <RowLabelWithAsterisk displayAsterisk={true}>
                                Do you confirm that you have a bona fide belief that the allegations in this report are
                                accurate?
                            </RowLabelWithAsterisk>
                            <CheckboxContainer>
                                <CheckboxOption
                                    label="Yes"
                                    isChecked={isRelief === true}
                                    onClick={() => setIsRelief(true)}
                                />
                                <CheckboxOption
                                    label="No"
                                    isChecked={isRelief === false}
                                    onClick={() => setIsRelief(false)}
                                />
                            </CheckboxContainer>
                        </CheckboxFieldRow>

                        <SubmitButton disabled={!readyForSubmit}>Submit</SubmitButton>
                        <CancelButton onClick={toggleForm}>Cancel</CancelButton>
                    </ReportDialogForm>
                ) : (
                    <ReportSubmitted toggleForm={toggleForm} reason={selectedReason} />
                )}
            </ReportModalContainer>
        </>
    )
}

export default ReportModal
