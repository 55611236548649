import { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Bubble, { Nip as OGNip, dropdownDown, dropdownUp } from '../dropdown'

const Nip = styled(OGNip)`
    left: 50%;
    ${(props) =>
        props.isExtendUp
            ? css`
                  bottom: 100%;
              `
            : css`
                  top: 100%;
              `};
`

const Container = styled.div(
    ({ isExtendUp, delay, offsetY, offsetX }) => css`
        z-index: 2147483647;
        opacity: 0;
        animation: ${isExtendUp ? dropdownDown : dropdownUp} 0.2s ease-out ${delay}s forwards;
        pointer-events: none;
        position: absolute;
        top: ${isExtendUp ? -offsetY : 0}px;
        left: ${offsetX}px;
        right: 0;
        bottom: 0;
    `
)
const BubbleWrapper = styled.div(
    ({ isExtendUp, align, isMultiWord, maxWidth, bubbleOffset }) => css`
        ${isExtendUp
            ? css`
                  bottom: 100%;
                  margin-bottom: 6px;
              `
            : css`
                  top: 100%;
                  margin-top: 6px;
              `}
        left: ${align === 'right' ? `-${bubbleOffset}px` : 'auto'};
        position: absolute;
        right: ${align === 'left' ? `-${bubbleOffset}px` : 'auto'};
        width: ${isMultiWord ? `${maxWidth}px` : 'auto'};
        ${align === 'center' &&
        css`
            left: 50%;
            transform: translateX(-50%);
        `};
    `
)

const Label = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    -webkit-font-smoothing: antialiased;
`

class TooltipView extends PureComponent {
    static defaultProps = {
        align: 'center',
        label: '',
        theme: 'light',
        delay: 0,
        isVisible: false,
    }

    render() {
        const {
            align,
            label,
            theme,
            delay,
            isVisible,
            isExtendUp = true,
            maxWidth,
            bubbleOffset,
            offsetY,
            offsetX,
        } = this.props
        return (
            <Container delay={delay / 1000} {...{ isExtendUp, offsetY, offsetX }}>
                <BubbleWrapper
                    align={align}
                    bubbleOffset={bubbleOffset}
                    isExtendUp={isExtendUp}
                    isMultiWord={String(label).split(' ').length > 1}
                    isVisible={isVisible}
                    maxWidth={maxWidth}
                >
                    <Bubble
                        {...{
                            theme,
                            autoSize: true,
                            isExtendUp,
                            hasNip: false,
                            shadow: false,
                            animate: false,
                        }}
                    >
                        <Label>{label}</Label>
                    </Bubble>
                </BubbleWrapper>
                <Nip isExtendUp={isExtendUp} />
            </Container>
        )
    }
}

export default TooltipView
