import { giphyRed } from '@giphy/colors'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
`

const Wrapper = styled.div<{ cutout: boolean }>`
    clip-path: ${(props) => (props.cutout ? `url('#giphycutout')` : 'none')};
    height: 100%;
    width: 100%;
`

const Cutout = styled.div<{ cutout: boolean }>`
    box-shadow:
        16px 16px 0 ${giphyRed},
        16px 32px 0 ${giphyRed},
        32px 32px 0 ${giphyRed},
        16px 48px 0 ${giphyRed},
        32px 48px 0 ${giphyRed},
        48px 48px 0 ${giphyRed};
    height: 16px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -64px;
    width: 16px;
    transform: ${(props) => (props.cutout ? 'translate3d(-48px, 48px, 0)' : 'translate3d(0, 0, 0)')};
    transition: transform 0.2s ease-out;
`

const CutoutSVG = styled.svg`
    height: 100%;
    left: -9999px;
    position: absolute;
    top: -9999px;
    width: 100%;
`

type Props = { className?: string; isVisible?: boolean; children?: ReactNode }
const CornerCutout = ({ children, className, isVisible = false }: Props) => (
    <Container className={className}>
        <Wrapper cutout={isVisible}>{children}</Wrapper>
        <Cutout cutout={isVisible} />
        <CutoutSVG>
            <defs>
                <clipPath id="giphycutout">
                    <rect height="100%" width="100%" x="-32" y="0" />
                    <rect height="100%" width="100%" x="0" y="32" />
                </clipPath>
            </defs>
        </CutoutSVG>
    </Container>
)

export default CornerCutout
