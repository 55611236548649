import { giphyBlack, giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { ControlSize, Gradient } from 'types'
import { colorFromGradientPosition, useNodeWidth, useRefRect } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import { IconButtonProps } from '../icon-button'
import Dropdown from './dropdown'
import { ArrowButton, Button, Container } from './style'
import { DropdownButtonTriggerMode, DropdownOptions } from './types'

export type DropdownButtonProps = {
    children?: ReactNode
    className?: string
    color?: string
    disabled?: boolean
    dropdownOptions?: Partial<DropdownOptions>
    gradient?: Gradient
    iconButtonOptions?: Partial<IconButtonProps>
    isOpen?: boolean
    label: ReactNode
    labelStyle?: CSSProperties
    size?: ControlSize
    style?: CSSProperties
    textColor?: string
    triggerMode?: DropdownButtonTriggerMode
    onToggle?: (isOpen: boolean) => void
}

const DropdownButton = ({
    children,
    className,
    color = giphyDarkGrey,
    disabled = false,
    dropdownOptions = {},
    gradient,
    iconButtonOptions = {},
    isOpen: isOpenProp = false,
    label,
    labelStyle,
    size = 'small',
    style,
    textColor: textColorProp,
    triggerMode = 'full',
    onToggle,
}: DropdownButtonProps) => {
    const arrowButtonRef = useRef<HTMLButtonElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const defaultIsOpenRef = useRef<boolean | null>(false)
    const [isOpen, setIsOpen] = useState<boolean>(isOpenProp)
    const containerWidth = useNodeWidth(containerRef, !isOpen)
    const triggerRect = useRefRect(arrowButtonRef, !isOpen)

    const buttonActive = triggerMode === 'full'
    const buttonWidth = containerWidth - (triggerRect?.width || 0)
    const offset = Math.round(-buttonWidth / 2)
    const gradientColor = gradient ? colorFromGradientPosition(gradient, 0.5) : undefined
    const textColor = textColorProp || textColorFromBackgroundColor(gradientColor || color)

    function onClick() {
        setIsOpen((state) => !state)
    }

    useEffect(() => {
        setIsOpen(isOpenProp)
    }, [isOpenProp])

    useEffect(() => {
        if (isOpen !== defaultIsOpenRef.current) {
            defaultIsOpenRef.current = null
            onToggle?.(isOpen)
        }
    }, [isOpen])

    return (
        <>
            <Container
                $color={color}
                $disabled={disabled}
                $gradient={gradient}
                $size={size}
                $triggerMode={triggerMode}
                className={className}
                ref={containerRef}
                style={style}
            >
                <Button
                    $active={buttonActive}
                    $color={textColor}
                    $size={size}
                    style={labelStyle}
                    onClick={buttonActive ? onClick : undefined}
                >
                    {label}
                </Button>
                <ArrowButton
                    $triggerMode={triggerMode}
                    color={gradient ? 'transparent' : giphyBlack}
                    iconColor={gradient ? textColor : undefined}
                    {...iconButtonOptions}
                    icon="navigate-down"
                    ref={arrowButtonRef}
                    size={size}
                    onClick={onClick}
                />
            </Container>
            {isOpen && (
                <Dropdown
                    minWidth={dropdownOptions.width ? undefined : containerWidth}
                    offset={offset}
                    options={dropdownOptions}
                    triggerRect={triggerRect}
                    onClose={() => setIsOpen(false)}
                >
                    {children}
                </Dropdown>
            )}
        </>
    )
}

export default DropdownButton
