import {
    giphyAqua,
    giphyBlack,
    giphyBlue,
    giphyDarkestGrey,
    giphyGreen,
    giphyIndigo,
    giphyPink,
    giphyPurple,
    giphyRed,
    giphyYellow,
} from '@giphy/colors'

enum CreationToolGradient {
    IndigoPurple = 'CT_GRADIENT_INDIGO_PURPLE',
    IndigoBlue = 'CT_GRADIENT_INDIGO_BLUE',
    PinkIndigo = 'CT_GRADIENT_PINK_INDIGO',
    BlueGreen = 'CT_GRADIENT_BLUE_GREEN',
    PurpleRed = 'CT_GRADIENT_PURPLE_RED',
    RedPink = 'CT_GRADIENT_RED_PINK',
    RedYellow = 'CT_GRADIENT_RED_YELLOW',
    AquaBlue = 'CT_GRADIENT_AQUA_BLUE',
    BlackGrey = 'CT_GRADIENT_BLACK_GREY',
    OrangeYellow = 'CT_GRADIENT_ORANGE_YELLOW',
    BlackBlack = 'CT_GRADIENT_BLACK_BLACK',
    Red = 'CT_GRADIENT_BLACK_BLACK',
}

namespace CreationToolGradient {
    /**
     * Returns the two colors for a gradient.
     */
    export function colors(gradient: CreationToolGradient): string[] {
        switch (gradient) {
            case CreationToolGradient.IndigoPurple:
                return [giphyIndigo, giphyPurple]
            case CreationToolGradient.IndigoBlue:
                return [giphyIndigo, giphyBlue]
            case CreationToolGradient.PinkIndigo:
                return [giphyPink, giphyIndigo]
            case CreationToolGradient.BlueGreen:
                return [giphyBlue, giphyGreen]
            case CreationToolGradient.PurpleRed:
                return [giphyPurple, giphyRed]
            case CreationToolGradient.RedPink:
                return [giphyRed, giphyPink]
            case CreationToolGradient.RedYellow:
                return [giphyRed, giphyYellow]
            case CreationToolGradient.AquaBlue:
                return [giphyAqua, giphyBlue]
            case CreationToolGradient.BlackGrey:
                return [giphyBlack, giphyDarkestGrey]
            case CreationToolGradient.OrangeYellow:
                return ['#fff35c', '#ff6666']
            case CreationToolGradient.BlackBlack:
                return [giphyBlack, '#000000']
        }
    }
}

export default CreationToolGradient
