import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ControlSize } from 'types/src/controls'
import { borderRadiusFromControlSize, heightFromControlSize } from 'utils/src/control-size'

const Preview = styled.div<{ $color: string; $size: ControlSize }>`
    border-radius: inherit;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;

    ${({ $color, $size }) => {
        const borderRadius = borderRadiusFromControlSize($size)
        const height = heightFromControlSize($size)
        const padding = height < heightFromControlSize('medium') ? 0 : 4

        return css`
            background: ${$color};
            height: ${height}px;
            padding: ${padding}px 0 ${padding}px ${padding}px;
            width: ${height - padding}px;

            > img {
                border-radius: ${borderRadius}px;
                border-bottom-right-radius: ${padding ? borderRadius : 0}px;
                border-top-right-radius: ${padding ? borderRadius : 0}px;
                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        `
    }}
`

type Props = {
    children?: ReactNode
    className?: string
    color: string
    size: ControlSize
}

const TextInputPreview = ({ children, className, color, size }: Props) => {
    return (
        <Preview $color={color} $size={size} className={className}>
            {children}
        </Preview>
    )
}

export default TextInputPreview
