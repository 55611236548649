import { Button } from 'shared/components/buttons'
import BrandInput from 'shared/components/form/ui/input'

import styled from 'styled-components'
import { mobile } from '../../util/media-query'

export const FormContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    width: 330px;
    ${Button} {
        width: 100%;
    }
`

export const FormInput = styled(BrandInput)`
    margin-top: 0;
    border-radius: 3px;
`

export const CTAButton = styled(Button)`
    margin-bottom: 7px;
    &:disabled {
        cursor: default;
        opacity: 1;
    }
`

// can make theme-able if needed
const sectionMargin = 17

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${sectionMargin}px;
`

export const OptionalText = styled.div`
    font-size: 12px;
`

export const Title = styled.div`
    -webkit-font-smoothing: antialiased;
    font-family: 'nexabook';
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 36px;
`

export const MobileBackButton = styled.div`
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: rgba(70, 70, 70, 0.4);
    padding: 15px;
    line-height: 1;

    ${mobile.css`
    display: block;
  `}
`
