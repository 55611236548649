import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, fontSizeFromControlSize, heightFromControlSize } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import BackgroundComponent from './background'
import SliderComponent from './dnd'

export const Background = styled(BackgroundComponent)`
    left: 0;
    position: absolute;
    top: 0;
`

export const Children = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`

export const Container = styled.div<{
    $background: string
    $color?: string
    $size: ControlSize
    $textColor?: string
}>`
    font-weight: bold;
    overflow: hidden;
    position: relative;

    ${({ $background, $color, $size, $textColor }) => {
        let color = $textColor || '#fff'

        if (!$textColor && $color) {
            color = textColorFromBackgroundColor($color)
        }

        return css`
            background: ${$background};
            border-radius: ${borderRadiusFromControlSize($size)}px;
            color: ${color};
            font-size: ${fontSizeFromControlSize($size)}px;
            height: ${heightFromControlSize($size)}px;
        `
    }}
`

export const Slider = styled(SliderComponent)<{ $background: string }>`
    bottom: 0;
    box-shadow: 0 0 0 9999px ${({ $background }) => $background};
    position: absolute;
    top: 0;
`
