import { useCallback, useState, ReactNode, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ModalContext from 'shared/contexts/modal'
import UserContext from 'shared/contexts/user'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import CollectionsEditor from './collections-editor'
import { ScrollableContainer } from './sc'
import CollectionsSuccessMessage from './collections-message'
import CollectionStyleContextManager from './context/collection-style-context'
import Header from './header'
import CollectionsDnd from './add-to-collection/dnd/dnd-context'
import Droppable from './add-to-collection/dnd/droppable'

const MESSAGE_HEIGHT = 52

const BottomOverlay = styled.div`
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(18, 18, 18, 0.6));
    cursor: default;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;
    pointer-events: none;
`

const StyledSuccessMessage = styled(CollectionsSuccessMessage)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`

type CollectionsCabinetProps = {
    onClose?: () => void
    channelId?: number
    selectedChannelIds?: number[]
    itemWidth?: number
    onClickAddTo?: (id: number, searchChannelId?: number) => void
    onClickChannel?: (id: number, searchChannelId?: number) => void
    showSearch?: boolean
    showGifsBelow?: boolean
    isInCreationTool?: boolean

    // Header options
    headerTitle?: string | ReactNode

    // Collapse options
    isOpen?: boolean
    onClickCollapse?: () => void
}

const CollectionsCabinet = ({
    onClose = () => {},
    channelId,
    onClickAddTo = () => {},
    onClickChannel = () => {},
    itemWidth,
    showSearch = true,
    headerTitle,
    selectedChannelIds,
    isOpen,
    onClickCollapse,
    showGifsBelow = true,
    isInCreationTool = false,
}: CollectionsCabinetProps) => {
    const { user } = useContext(UserContext)
    const { openNewCollectionPanel } = useContext(ModalContext)
    const {
        channels,
        activeChannelId,
        setActiveChannelId,
        gif,
        loadChannels,
        combineCollectionsSuccessMessage,
        undoCombineCollections,
        addGifsToCollectionSuccessMessage,
        undoAddGifsToCollection,
        undoCreatingCollections,
        collectionCreatedSuccessMessage,
        rootChannelId,
    } = useContext(CollectionContext)
    const [loading, setLoading] = useState(true)
    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const id = channelId || user.channelId

    const loadCollections = async () => {
        setLoading(true)
        const channel = await loadChannels()
        setLoading(false)

        if (channel && !channel.has_children && !isInCreationTool) {
            openNewCollectionPanel({
                parentChannel: channel,
                userId: user?.id,
                gif,
            })
        }
    }

    useEffect(() => {
        loadCollections()
    }, [])

    useEffect(() => {
        !!scrollContainerRef.current && scrollContainerRef.current.scrollTo(0, 0)
    }, [activeChannelId, isOpen])

    const CabinetHeader = useCallback(() => {
        if (channels[activeChannelId]) {
            const parentId = channels[activeChannelId].parent
            if (parentId) {
                return (
                    <Droppable id={`${parentId}`}>
                        <Header
                            onBack={() => {
                                setActiveChannelId(parentId)
                            }}
                            channel={channels[activeChannelId]}
                            parent={channels[parentId].display_name}
                            onClose={onClose}
                            isOpen={isOpen}
                            onClickCollapse={onClickCollapse}
                        />
                    </Droppable>
                )
            }
            if (activeChannelId !== rootChannelId) {
                return (
                    <Header
                        onBack={() => {
                            setActiveChannelId(rootChannelId)
                        }}
                        title={channels[activeChannelId].display_name}
                        onClose={onClose}
                        isOpen={isOpen}
                        onClickCollapse={onClickCollapse}
                    />
                )
            }
        }
        return (
            <Header
                title={headerTitle || (gif ? 'Add to Collection' : 'Collections Editor')}
                onClose={onClose}
                isOpen={isOpen}
                onClickCollapse={onClickCollapse}
            />
        )
    }, [activeChannelId, channels, isOpen, onClickCollapse, headerTitle])

    const SuccessMoveCollectionsMessage = useCallback(() => {
        if (combineCollectionsSuccessMessage) {
            return <StyledSuccessMessage height={MESSAGE_HEIGHT} undoAction={undoCombineCollections} />
        }
        return <></>
    }, [combineCollectionsSuccessMessage])

    const SuccessAddGifsMessage = useCallback(() => {
        if (addGifsToCollectionSuccessMessage) {
            return <StyledSuccessMessage height={MESSAGE_HEIGHT} undoAction={undoAddGifsToCollection} />
        }
        return <></>
    }, [addGifsToCollectionSuccessMessage])

    const SuccessCreateCollection = useCallback(() => {
        if (collectionCreatedSuccessMessage) {
            return <StyledSuccessMessage height={MESSAGE_HEIGHT} undoAction={undoCreatingCollections} />
        }
        return <></>
    }, [collectionCreatedSuccessMessage])

    if (!id) {
        return <></>
    }

    return (
        <CollectionStyleContextManager
            itemWidth={itemWidth}
            showSearch={showSearch}
            selectedChannelIds={selectedChannelIds}
            showGifsBelow={showGifsBelow}
            channelsLength={channels[activeChannelId]?.children?.length}
        >
            <CollectionsDnd>
                <SuccessMoveCollectionsMessage />
                <SuccessAddGifsMessage />
                <SuccessCreateCollection />
                <CabinetHeader />
                <ScrollableContainer>
                    {!loading && <CollectionsEditor onClickChannel={onClickChannel} onClickAddTo={onClickAddTo} />}
                </ScrollableContainer>
                <BottomOverlay />
            </CollectionsDnd>
        </CollectionStyleContextManager>
    )
}

export default CollectionsCabinet
