import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
import styled from 'styled-components'
import VideoContext from '../video-context'

const SIZE = 110

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 20px;
    margin-left: 40px;
`

const Preview = styled.img`
    display: block;
    margin-bottom: 6px;
    width: 100%;
`

type Props = {
    isPortrait?: boolean
}

const getPreviewUrl = ({ images, video }: IGif) => {
    if (images) {
        return images.fixed_width.url
    }
    if (video) {
        const values = Object.values(video.previews)
        return values.length ? values[0].url : ''
    }
    return ''
}

const ShareEmbedPreview = ({ isPortrait }: Props) => {
    const { videoData, dimensions, isEmbedInfoBelow } = useContext(VideoContext)
    const aspectRatio = dimensions.width / dimensions.height
    const width = isPortrait ? SIZE : SIZE * aspectRatio
    const height = isPortrait ? SIZE / aspectRatio : SIZE
    return (
        <Container style={{ width }}>
            <Preview alt="" src={getPreviewUrl(videoData)} style={{ height }} />
            {isEmbedInfoBelow && (
                <svg height="28" width="100" xmlns="http://www.w3.org/2000/svg">
                    <g fill="#FFF" fillRule="evenodd">
                        <path d="M15 .5v15H0V.5h15zM7.5 3.929a1.835 1.835 0 00-1.288.534A1.835 1.835 0 005.68 5.75c0 .31.032.614.097.909.066.295.139.535.22.719.08.183.172.358.277.522.104.165.177.272.218.32.042.05.075.085.098.11 0 .163-.05.308-.15.435a1.19 1.19 0 01-.392.315c-.16.083-.338.162-.532.236-.195.075-.389.153-.583.235a3.78 3.78 0 00-.532.272c-.16.1-.29.23-.392.388a1 1 0 00-.15.548v.302c0 .043.014.08.044.109.03.03.065.044.108.044h6.98a.148.148 0 00.108-.044.148.148 0 00.045-.109v-.302a1 1 0 00-.151-.548 1.222 1.222 0 00-.392-.388 3.78 3.78 0 00-.532-.272c-.194-.082-.388-.16-.583-.235a5.235 5.235 0 01-.532-.236 1.19 1.19 0 01-.391-.315.681.681 0 01-.151-.436l.05-.053c.033-.036.076-.091.13-.167.053-.076.112-.166.177-.269.065-.103.13-.229.196-.377.065-.148.124-.305.178-.471.053-.166.096-.358.13-.575.033-.217.05-.44.05-.667a1.835 1.835 0 00-.534-1.288A1.835 1.835 0 007.5 3.93zM19 4h51v4H19z" />
                        <path d="M19 11h38v2H19zM0 21h100v2H0zM0 26h90v2H0z" opacity=".5" />
                    </g>
                </svg>
            )}
        </Container>
    )
}

export default ShareEmbedPreview
