import styled from 'styled-components'
import { Gradient } from 'types'
import { gradientToCss } from 'utils'

const BORDER_RADIUS = 8
const DEFAULT_HEIGHT = 60

export const Container = styled.div<{ $gradient: Gradient; $textColor: string }>`
    background: ${({ $gradient }) => gradientToCss($gradient)};
    border-bottom-left-radius: ${BORDER_RADIUS}px;
    border-bottom-right-radius: ${BORDER_RADIUS}px;
    box-sizing: border-box;
    color: ${({ $textColor }) => $textColor};
    display: flex;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.7));
    min-height: ${DEFAULT_HEIGHT}px;
    padding: 10px 0;
    width: 100%;
`

export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
    line-height: 1.3;
    max-width: 700px;
    text-align: center;
    width: 100%;

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }
`

export const Spacer = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: ${DEFAULT_HEIGHT}px;

    &:first-child {
        margin-right: auto;
    }

    &:last-child {
        margin-left: auto;
    }
`
