import { useRef } from 'react'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import styled from 'styled-components'

const FPS = 15

export type Frame = {
    canvas: HTMLCanvasElement
    frameCount?: number
}

const CANVAS_SCALE = 3 // this is used to prevent scaling down images too far (looks blurry)

const Container = styled.div`
    background: #000;
    border-radius: inherit;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    pointer-events: none;
`

const Canvas = styled.canvas`
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
`

type Props = {
    source: string
    sourceWidth: number
    sourceHeight: number
}

const FrameStrip = ({ source, sourceWidth, sourceHeight }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const drawToCanvas = (video: HTMLVideoElement): HTMLCanvasElement => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        context?.drawImage(video, 0, 0)
        return canvas
    }

    const getVideoFrames = (source: string, length: number = 0): Promise<Frame[]> => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video')
            const frames: Frame[] = []
            let totalFrames = length
            let currentFrame = 0

            video.onseeked = () => {
                video.pause()
                frames.push({ canvas: drawToCanvas(video) })
                currentFrame++

                if (currentFrame < totalFrames) {
                    video.currentTime = (currentFrame / totalFrames) * video.duration
                } else {
                    video.onseeked = null
                    if (typeof source !== 'string') {
                        URL.revokeObjectURL(video.src)
                    }
                    resolve(frames)
                }
            }

            video.oncanplaythrough = () => {
                if (!video.videoWidth || !video.videoHeight) {
                    reject(`Invalid dimensions`)
                } else {
                    totalFrames = totalFrames || Math.floor(video.duration * FPS)
                    video.oncanplaythrough = null
                    video.currentTime = 0.01
                }
            }

            video.onerror = (err) => {
                reject(err)
            }

            video.preload = 'auto'
            video.src = typeof source === 'string' ? source : URL.createObjectURL(source)
        })
    }

    useAsyncEffect(async () => {
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')

        if (!canvas || !context) return

        const { height, width } = canvas.getBoundingClientRect()

        canvas.width = width * CANVAS_SCALE
        canvas.height = height * CANVAS_SCALE

        const aspectRatio = sourceWidth / sourceHeight
        const frameWidth = canvas.height * aspectRatio
        const frameCount = Math.ceil(canvas.width / frameWidth)
        let frames: Frame[] = []

        try {
            if (source) {
                frames = await getVideoFrames(source, frameCount)
            }
        } catch (err) {
            console.log(err)
        }

        // if no frames, draw placeholders
        if (!frames.length) {
            const gradient = context.createLinearGradient(0, 0, frameWidth, 0)
            gradient.addColorStop(0, '#FF0000')
            context.fillStyle = gradient

            for (let i = 0; i < frameCount; i++) {
                context.fillRect(0, 0, frameWidth, canvas.height)
                context.translate(frameWidth, 0)
            }
        } else {
            frames.forEach((frame, i) => {
                context.drawImage(frame.canvas, i * frameWidth, 0, frameWidth, canvas.height)
            })
        }
    }, [canvasRef, source])

    return (
        <Container>
            <Canvas ref={canvasRef} />
        </Container>
    )
}

export default FrameStrip
