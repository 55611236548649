import styled from 'styled-components'

const Container = styled.div<{ width: number; height: number }>`
    display: flex;
    position: relative;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    overflow: hidden;
`

export default Container
