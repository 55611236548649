import { GifsResult, GiphyFetch, Result } from '@giphy/js-fetch-api'
import { IChannel, IGif } from '@giphy/js-types'
import appendQuery from 'append-query'
import { IStory, StoryResult } from 'shared/types/story'
import isMobile from 'shared/util/is-mobile'
import slugify from 'slugify'
import * as urlUtil from 'url'
import { DELETE, GET, PATCH, POST } from '../util/fetch-methods'
import { ResponseError } from './errors'
export const relatedUrl = (gifId, page = 1) => `/gifs/${gifId}/related/${page}`
export const gceUrl = '//rxjthjm1pofte.com'
const requestMap: { [key: string]: Promise<Result> } = {}

declare global {
    interface Window {
        GIPHY_API_URL: string
        SERVICE_GATEWAY_URL: string
        IMAGING_SERVICE_URL: string
        GIPHY_FE_MOBILE_API_KEY: string
        GIPHY_FE_WEB_API_KEY: string
        GIPHY_FE_FOUR_O_FOUR_API_KEY: string
        GIPHY_FE_TAGGER_API_KEY: string
        GIPHY_FE_STORIES_AND_GIPHY_TV_API_KEY: string
        GIPHY_FE_DEFAULT_API_SERVICE_KEY: string
        GIPHY_FE_GET_POST_HEADERS_KEY: string
        GIPHY_FE_MEDIUM_BLOG_API_KEY: string
    }
}

let {
    // https://api-cached-staging.giphy.com/v1/
    // https://api.giphy.com/v1/
    GIPHY_API_URL = `https://api.giphy.com/v1/`,
    // GIPHY_IMAGING_URL = `https://imaging.giphy.com`,
    // https://x-dev.giphy.com/
    // https://x.giphy.com/
    // SERVICE_GATEWAY_URL = `https://x.giphy.com/`,
} = window

const GIPHY_API_V2_URL = GIPHY_API_URL.replace('v1', 'v2')

const SERVICE_GATEWAY_URL = `${window.SERVICE_GATEWAY_URL}v1/`

const trendingStoriesApiUrl = `${SERVICE_GATEWAY_URL}stories/trending`
const trendingScheduleApiUrl = `${SERVICE_GATEWAY_URL}trendingscheduler`
export const API_URL = GIPHY_API_URL
export const IMAGING_URL = `${window.IMAGING_SERVICE_URL}`
export const ANALYTICS_URL_LEGACY = `/api/v1/analytics/`
export const ANALYTICS_URL = `/api/v3/analytics/`

export const MOBILE_API_KEY = window.GIPHY_FE_MOBILE_API_KEY
export const WEB_API_KEY = window.GIPHY_FE_WEB_API_KEY
export const FOUR_O_FOUR_API_KEY = window.GIPHY_FE_FOUR_O_FOUR_API_KEY
export const TAGGER_API_KEY = window.GIPHY_FE_TAGGER_API_KEY
export const STORIES_AND_GIPHY_TV_API_KEY = window.GIPHY_FE_STORIES_AND_GIPHY_TV_API_KEY

export const mobileFetch = new GiphyFetch(MOBILE_API_KEY)
export const webFetch = new GiphyFetch(WEB_API_KEY)

const checkStatus = (res: Response) => {
    const { status, ok } = res
    if (status === 204) {
        return ok
    }
    if (status >= 200 && status < 300) {
        return res
    }

    return res.json().then((fieldErrors) => {
        throw new ResponseError(res, fieldErrors)
    })
}

export const request = (url: string, config?: any, token?, format: boolean = true) => {
    if (token) {
        const { headers } = config
        config['headers'] = { ...headers, Authorization: `Token ${token}` }
    }
    return fetch(url, config)
        .then(checkStatus)
        .then((response: Response) => (format ? response.json() : response))
        .catch((err) => console.error(err))
}

export const getResults = (...request) => {
    const [url, method] = request
    return fetch(url, method)
        .then(checkStatus)
        .then((response: Response) => (response.json ? response.json() : response))
        .then(({ status, result, ...whatever }) => {
            if (!result) {
                // just return the whole response object
                return whatever
            }
            // some responses have a nested "result"
            return { status, ...result }
        })
}

async function getResultsWithDelay(url, method, assertFunction: (res: any) => boolean, { retries, delay }) {
    const res = await request(url, method)
    try {
        if (!assertFunction(res)) {
            throw new Error()
        }
        return res
    } catch (e) {
        if (retries === 1) {
            return res
        }
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve()
            }, delay)
        })
        return getResultsWithDelay(url, method, assertFunction, { retries: retries - 1, delay })
    }
}

export const jsonToFormData = (json): FormData => {
    const formData = new FormData()
    for (let key in json) {
        formData.append(key, json[key])
    }
    return formData
}

export const contentTakedown = (
    gifIds: (string | number)[],
    reason: string,
    additionalInfo: string,
    shouldEmailUser: boolean
) => {
    let params = {
        takedowns: gifIds.map((gifId) => ({
            reason: reason,
            gif_id: gifId,
        })),
        send_email: shouldEmailUser,
    }
    if (additionalInfo) {
        params['note'] = additionalInfo
    }
    const requestInit = POST(JSON.stringify(params))
    requestInit.headers!['Content-Type'] = 'application/json'
    return getResults('/api/v3/content-takedown/takedown ', requestInit as RequestInit)
}

export const reportSearch = (
    reasons: string,
    additionalInfo: string,
    searchResponseId: string,
    searchTerm: string,
    searchContentType: string
) => {
    const params = {
        reasons: reasons,
        additional_info: additionalInfo,
        search_response_id: searchResponseId,
        search_term: searchTerm,
        search_content_type: searchContentType,
    }

    return getResults('/ajax/report-search', POST(params, true))
}

export const getTrendingSearchTerms = (): Promise<{
    data: { name: string; analytics_response_payload: string }
    meta: { response_id: string }
}> =>
    getResults(
        appendQuery(`${GIPHY_API_V2_URL}trending/searches`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
        })
    )

type SearchClipsResult = Result & {
    data: IGif[]
}
type SearchClipsOptions = { q: string; offset?: number; limit?: number; pingbackId?: string; rating?: string }
export const searchClips = async ({ q, offset, limit, pingbackId, rating = 'pg-13' }: SearchClipsOptions) => {
    return (await getResults(
        appendQuery(`${GIPHY_API_URL}videos/search`, {
            q: q,
            offset: offset,
            limit: limit,
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            pingback_id: pingbackId,
            rating: rating,
        }),
        GET
    )) as SearchClipsResult
}

export const relatedClips = async (gifId: string, pingbackId?: string, limit?: number) => {
    return getResults(
        appendQuery(`${GIPHY_API_URL}videos/related`, {
            gif_id: gifId,
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            pingback_id: pingbackId,
            limit: limit,
        }),
        GET
    )
}
export const login = () => getResults(`/login/check`, GET)

export const instagram = (gifId, email) => fetch(`/ajax/instashare/`, POST({ gif_id: gifId, to: email }, true))

export function follow(userId) {
    return getResults(`/users/${userId}/follows`, POST())
}

export function unfollow(userId) {
    return getResults(`/users/${userId}/follows`, DELETE())
}

export function follows() {
    return getResults('/users/self/follows', GET)
}

export function getBetaEnvData() {
    return getResults('/api/v3/env/build', GET)
}

export function getGifChannels(gifIds = []) {
    return getResults(`/api/v4/channel-gifs/?gif=${gifIds.join(',')}`, GET)
}

export function deleteUser(id: number, password: string) {
    const requestInit = DELETE()
    if (password) {
        requestInit.headers!['X-Password'] = password
    }
    return getResults(`/api/v1/users/${id}/`, requestInit as RequestInit)
}

export async function gifs(
    url: string,
    processor: Function,
    pingback_id: string,
    apiKey: string,
    init: RequestInit = GET
) {
    const urlToFetch = appendQuery(url, {
        is: 1,
        json: true,
        pingback_id,
        api_key: apiKey,
    } as any)
    const res: Response = await fetch(urlToFetch, init)
    if (res.ok) {
        let json
        try {
            json = await res.json()
        } catch (e) {
            throw `Error parsing json for ${url}`
        }
        return processor(json, pingback_id, url, apiKey)
    }
}

type ArtistChannelsOptions = {
    filter: string
    freelance: boolean
    order: 'asc' | 'desc'
    sort: string
    offset?: number
}
export const getArtistChannels = ({
    filter = '',
    freelance = false,
    order = 'desc',
    sort = 'updatetime',
    offset,
}: ArtistChannelsOptions) => {
    return getResults(appendQuery(`/api/v4/artists/`, { filter, freelance, order, sort, offset }), GET)
}

export function getChannelById(channelId, fetchChildren = true) {
    let url = `/api/v4/channels/${channelId}`
    if (fetchChildren) {
        url = `${url}?fetch_children=true`
    }
    return getResults(url, GET)
}

export function createChannel(channel) {
    return getResults(`/api/v4/channels/`, POST(channel, true))
}

export function moveChannel(channelId, siblingId, side) {
    return getResults(`/api/v4/channels/${channelId}/move/`, POST({ position: side, target_channel: siblingId }, true))
}

export function retrieveCacheStatus(jobUUID) {
    const enum CachemirJobStatus {
        NOT_EXISTS = 'NOT_EXISTS',
        WAITING = 'WAITING',
        DONE = 'DONE',
        ERROR = 'ERROR',
    }

    return getResultsWithDelay(
        `/api/v4/channels/retrieve-job/?job=${jobUUID}`,
        GET,
        (res) => (res.status as CachemirJobStatus) === CachemirJobStatus.DONE,
        { retries: 8, delay: 500 }
    )
}

export function editChannelById(channelId, channel, isJson) {
    return getResults(`/api/v4/channels/${channelId}/`, PATCH(channel, isJson)).then(async (ch) => {
        await retrieveCacheStatus(ch.flush_uuid)
        return ch
    })
}

export function deleteChannelById(channelId = '') {
    return getResults(`/api/v4/channels/${channelId}/`, DELETE())
}

export function getChannelGifs(channelId, offset) {
    return getResults(
        appendQuery(`/api/v4/channels/${channelId}/feed/`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            offset: offset ?? 0,
        })
    )
}

export function getAssociatedStickers(id) {
    return getResults(
        appendQuery(`${GIPHY_API_URL}gifs/${id}/associations`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
        })
    )
}

export function searchUsername(userName = '') {
    return getResults(`/api/v1/users/?search=${userName}`, GET)
}

export function getUserById(userId = '') {
    return getResults(`/api/v1/users/${userId}/`, GET)
}

export function editUserById(userId, user, isJson = false) {
    return getResults(`/api/v1/users/${userId}/`, PATCH(user, isJson))
}

export function createRankedTag(tag) {
    return getResults(`/api/v4/channel-ranked-tags/`, POST(tag, true))
}

export function editRankedTag(tagId, rank) {
    return getResults(`/api/v4/channel-ranked-tags/${tagId}/`, PATCH(rank, true))
}

export function deleteRankedTag(tagId) {
    return getResults(`/api/v4/channel-ranked-tags/${tagId}/`, DELETE())
}

export function createSyncableTag(tag) {
    return getResults(`/api/v4/channel-magic-tags/`, POST(tag, true))
}

export function deleteSyncableTag(tagId) {
    return getResults(`/api/v4/channel-magic-tags/${tagId}/`, DELETE())
}

type SearchName = { analytics_response_payload: string; name: string; name_encoded: string; response_id: string }
type SearchTagsResult = { data: SearchName[] } & Result
export const searchTags = async (term: string) => {
    const results = (await getResults(
        appendQuery(`${GIPHY_API_URL}gifs/search/tags`, {
            q: term,
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
        }),
        GET
    )) as SearchTagsResult
    results.data = results.data.map(({ name, analytics_response_payload }) => ({
        name,
        name_encoded: slugify(name),
        response_id: results.meta.response_id,
        analytics_response_payload,
    }))
    return results
}

type ChannelsResult = Result & {
    data: IChannel[]
}
export async function channelSearch(query): Promise<IChannel[]> {
    const results = (await getResults(
        appendQuery(`${GIPHY_API_URL}channels/search`, {
            q: query,
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
        }),
        GET
    )) as ChannelsResult
    return results.data.map((channel) => {
        // @ts-ignore maybe add response_id to IChannel, it is on IGif
        // makes it easier
        channel.response_id = results.meta.response_id
        return channel
    })
}

export function fetchUserAnalytics(userId) {
    return getResults(`/api/v1/users/${userId}/view-count/`, GET)
}

export function fetchGifDetailViewCount(id) {
    return getResults(`/api/v1/proxy-gif/${id}/view-count/`, GET)
}

export function getGifById(gifId) {
    return getResults(
        appendQuery(`${GIPHY_API_URL}gifs/${gifId}`, { api_key: window.GIPHY_FE_STORIES_AND_GIPHY_TV_API_KEY })
    )
}

export function getGifsById(gifIds) {
    return getResults(
        appendQuery(`${GIPHY_API_URL}gifs`, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
            ids: Array.isArray(gifIds) ? gifIds.join(',') : gifIds,
        })
    )
}

export function fetchBulkGifTrending(gifIds) {
    return getResults(`/ajax/gifs/trending?gif_ids=${gifIds.join(',')}`, GET)
}

export function fetchTagCounts(gifId) {
    return getResults(`/ajax/gif/${gifId}/tag-counts`, GET)
}

export function fetchMetadata(gifId) {
    return getResults(`/ajax/gif/${gifId}/metadata`, GET)
}

export function fetchExtendedMetadata(gifId) {
    return getResults(`/ajax/gif/${gifId}/extended-metadata`, GET)
}

export function fetchBulkMetadata(gifIds) {
    return getResults(`/ajax/gifs/metadata?gif_ids=${gifIds.join(',')}`, GET)
}

export function fetchBulkExtendedMetadata(gifIds) {
    return getResults(`/ajax/gifs/extended-metadata?gif_ids=${gifIds.join(',')}`, GET)
}

export function fetchAllGifTagTranslations(gifId) {
    return getResults(`/ajax/gif/${gifId}/tag-translations`, GET)
}

export function fetchLanguageTags(gifIds, lang) {
    return getResults(`/ajax/gif/bulk/language-tags?gif_ids=${gifIds.join(',')}&lang=${lang}`)
}

export function addLanguageTags(gifId, tags, lang) {
    const params = {
        tags: tags,
        lang: lang,
    }
    return getResults(`/ajax/gif/${gifId}/language-tags`, POST(params, true))
}

export function removeLanguageTag(gifId, tag, lang) {
    return getResults(`/ajax/gif/${gifId}/language-tags?tag=${encodeURIComponent(tag)}&lang=${lang}`, DELETE())
}

export function channelGifs(channelId, limit) {
    return getResults(`/api/v4/channels/${channelId}/feed/?limit=${limit}`, GET)
}

export function trackGifShare(id, type) {
    return fetch(`/ajax/gif/${id}/analytic/${type}`, POST())
}

export function tweet(formData) {
    return getResults('/ajax/twitter_upload', POST(formData))
}

export function allChannelSearch(slug = '', fetchChildren = true) {
    let url = `/api/v4/channels?slug=${slug}`
    if (fetchChildren) {
        url = `${url}&fetch_children=true`
    }
    return getResults(url, GET)
}

export function updateGif(gifId, prop, value) {
    const params = {
        gif_id: gifId,
        key: prop,
        value,
    }
    return getResults('/ajax/gif/update', POST(params, true))
}

export function favoriteMilestones() {
    return fetch('/api/v1/favorite-milestones/', GET)
        .then(checkStatus)
        .then((response: Response) => response.json())
}

export function giphyAnalytics(shareType, gifId) {
    return getResults(`/ajax/gif/${gifId}/analytic/${shareType}/`, POST())
}

export function updateChannelGif(gifId, channelId, isDelete) {
    const params = {
        channel: channelId,
        gif: gifId,
    }

    return getResults(`/api/v4/channel-gifs/${isDelete ? 'remove/' : ''}`, POST(params, true))
}

export const channels = () => request(`/api/v3/channels/`, GET)

export const channelChildren = (id) => {
    if (!id) {
        throw new Error("We cannot fetch a channel's children without its parent's id.")
    }
    return request(`/api/v4/channels/${id}/children/`, GET)
}

export const flag = (gifId, body) => request(`/api/v1/gifs/${gifId}/moderation`, POST(body))

export const trimVideoEmbed = (url, start, duration) =>
    fetch(
        appendQuery(`${IMAGING_URL}/imaging/trim`, {
            media_url: url.replace(/(^\w+:|^)\/\//, ''),
            start,
            duration,
        }),
        GET
    )

export const trimVideo = (source: File | string, start: number, duration: number): Promise<Blob> => {
    if (typeof source === 'string') {
        return trimVideoEmbed(source, start, duration).then((response) => response.blob())
    }

    return fetch(`${IMAGING_URL}/imaging/trim`, {
        method: 'POST',
        body: jsonToFormData({ file: source, start, duration }),
    }).then((response) => response.blob())
}

export type SearchServiceParams = {
    sort?:
        | 'asc'
        | 'desc'
        | 'relevant'
        | 'trending'
        | 'flags'
        | 'source_popularity'
        | 'ios_search'
        | 'ios_imessage'
        | 'android_search'
        | 'desktop_web'
        | 'mobile_web'
    query?: string
    channelIdFilter?: string
}
export const searchService = async (offset: number, params: SearchServiceParams) => {
    const {
        count,
        gps_keywords,
        results: data,
    } = await getResults(
        appendQuery('/api/v1/search-service/search/', { ...params, offset }, { encodeComponents: false }),
        GET
    )
    return {
        data,
        pagination: {
            count: data.length,
            total_count: count,
            offset,
        },
        gps_keywords,
        meta: {
            msg: '',
            response_id: '',
            status: 200,
        },
    } as GifsResult & { gps_keywords: string }
}

export const getStoryById = (id, token) =>
    getResults(
        appendQuery(`${SERVICE_GATEWAY_URL}stories/${id}`, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
        }),
        {
            method: 'get',
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        }
    )
export const getStory = (slug: string, token: string) =>
    getResults(
        appendQuery(`${SERVICE_GATEWAY_URL}stories/slug/${slug}`, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
        }),
        {
            method: 'get',
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        }
    )

export const saveStory = (story: IStory, token: string) => {
    const isUpdate = !!story.story_id
    return getResults(
        appendQuery(`${SERVICE_GATEWAY_URL}stories${isUpdate ? '/' + story.story_id : ''}`, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
        }),
        {
            method: isUpdate ? 'put' : 'post',
            body: JSON.stringify({ story }),
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        }
    )
}

export const deleteStory = (storyId, token: string) =>
    getResults(
        appendQuery(`${SERVICE_GATEWAY_URL}stories/${storyId}`, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
        }),
        {
            method: 'delete',
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        }
    )

type FetchUserStoriesOptions = { showUnpublished?: boolean; fetchCoverOnly?: boolean; limit?: number; cursor?: string }
export const getUserStories = (
    username: string,
    token: string,
    options: FetchUserStoriesOptions = { showUnpublished: false, fetchCoverOnly: false }
) => {
    if (!username) {
        console.error(`No username specified for getUserStories`)
        return
    }
    const { showUnpublished, fetchCoverOnly, limit, cursor } = options
    const qs = {
        username,
        api_key: STORIES_AND_GIPHY_TV_API_KEY,
        fetch_unpublished: showUnpublished,
        limit,
        next_cursor: cursor,
    }
    if (fetchCoverOnly) {
        qs['gif_hydration_method'] = 'COVER_ONLY'
    }
    const url = appendQuery(`${SERVICE_GATEWAY_URL}stories`, qs as any)
    if (!requestMap[url]) {
        requestMap[url] = getResults(url, {
            method: 'get',
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        })
    }
    return requestMap[url] as Promise<StoryResult>
}

export const getArtistAndPartnerStories = (token: string, since, until) => {
    const params = {
        limit: 50,
        api_key: STORIES_AND_GIPHY_TV_API_KEY,
        since: undefined,
        until: undefined,
        ts: Date.now(),
    }

    if (since) {
        params.since = since
    }

    if (until) {
        params.until = until
    }

    return getResults(appendQuery(`${SERVICE_GATEWAY_URL}stories/partnerArtist`, params as any), {
        method: 'get',
        headers: token
            ? {
                  Accept: 'application/json',
                  Authorization: `Token ${token}`,
              }
            : {},
    })
}

export const getTrendingStories = (token: string, sort = 'desc', fetchCoverOnly: boolean = false) => {
    const qs = {
        api_key: STORIES_AND_GIPHY_TV_API_KEY,
        sort,
    }
    if (fetchCoverOnly) {
        qs['gif_hydration_method'] = 'COVER_ONLY'
    }
    return getResults(appendQuery(trendingStoriesApiUrl, qs), {
        method: 'get',
        headers: token
            ? {
                  Accept: 'application/json',
                  Authorization: `Token ${token}`,
              }
            : {},
    })
}

export const getStoriesByUrl = (url: string, token: string) =>
    getResults(url, {
        method: 'get',
        headers: token
            ? {
                  Accept: 'application/json',
                  Authorization: `Token ${token}`,
              }
            : {},
    })

export const saveTrendingStory = (storyId, trendingDatetime, token: string) =>
    getResults(
        appendQuery(trendingStoriesApiUrl, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
        }),
        {
            method: 'post',
            body: JSON.stringify({ trending_story: { story_id: storyId, publish_datetime: trendingDatetime } }),
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        }
    )

export const removeTrendingStory = (trendingId, token: string) =>
    getResults(
        appendQuery(`${trendingStoriesApiUrl}/${trendingId}`, {
            api_key: STORIES_AND_GIPHY_TV_API_KEY,
        }),
        {
            method: 'delete',
            headers: token
                ? {
                      Accept: 'application/json',
                      Authorization: `Token ${token}`,
                  }
                : {},
        }
    )

export const emailSearchResultsCsv = (searchParams) => {
    return fetch(appendQuery('/api/v1/search-service/csv/', searchParams), POST())
        .then(checkStatus)
        .then((response: Response) => response.json())
}

export const scheduleTrendingGifs = (gifs: IGif[], timestamp: number, token: string) =>
    getResults(
        appendQuery(`${trendingScheduleApiUrl}/schedule`, {
            api_key: TAGGER_API_KEY,
            access_token: token,
            timestamp,
        }),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                gifs: gifs.map((gif) => ({ id: gif.id, is_sticker: gif.is_sticker, type: gif.type })),
            }),
        }
    )

export const fetchScheduledTrendingGifs = (token: string, type?: string, limit?: number, offset?: number) =>
    getResults(
        appendQuery(`${trendingScheduleApiUrl}/schedule`, {
            api_key: TAGGER_API_KEY,
            access_token: token,
            limit: limit ?? 25,
            offset: offset ?? 0,
            type: type ?? 'gif',
        })
    ).then((results) => {
        if (typeof results === 'object') {
            const keys = Object.keys(results)
            return keys.map((key) => results[key])
        }
        return results
    })

export const fetchTrendingScheduleActivity = (token: string) =>
    getResults(
        appendQuery(`${trendingScheduleApiUrl}/activity`, {
            api_key: TAGGER_API_KEY,
            access_token: token,
        })
    )

export const removeGifFromTrendingSchedule = (gifId: string, token: string) =>
    request(
        appendQuery(`${trendingScheduleApiUrl}/schedule/${gifId}`, {
            api_key: TAGGER_API_KEY,
            access_token: token,
        }),
        DELETE()
    )

export function fetchRelatedKeywords(keyword: string, score = true) {
    return getResults(
        appendQuery(`${GIPHY_API_URL}tags/related/${encodeURIComponent(keyword)}`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            score: score.toString(),
        }),
        GET
    )
}

export function fetchAutoTags(gifId: string) {
    return getResults(`/ajax/tags/related/gif/${encodeURIComponent(gifId)}/`, GET)
}

export function fetchAutoTagsWithFrames(frames: string[]) {
    const params = { frames: frames }
    return getResults('/ajax/tags/prediction/', POST(params, true))
}

export function updateCTA(gifId: string, ctaURL: string, ctaText: string) {
    return getResults(
        appendQuery(`${GIPHY_API_URL}gifs/${gifId}/cta`, {
            api_key: WEB_API_KEY,
            link: ctaURL,
            text: ctaText,
        }),
        {
            method: ctaText ? 'put' : 'delete',
        }
    )
}

export async function fetchSpotifyArtist(url: string) {
    const response = await getResults(
        appendQuery(`/api/v3/spotify/artist`, {
            spotify_url: encodeURI(url),
        }),
        GET
    )
    return response['name']
}

export function fetchDashboardTrendingSearches(username = '') {
    const usernameQuery = username ? `?username=${username}` : ''
    return getResults(`${ANALYTICS_URL}dashboard-trending-searches/` + usernameQuery, GET)
}

/**
 * @param {String} pathname pathname appended to https://api.giphy.com
 * @param {Object} query query params
 * @returns {Promise}
 */
export default (pathname, query = {}) =>
    fetch(
        urlUtil.format({
            protocol: `https`,
            host: `api.giphy.com`,
            pathname,
            query: { api_key: window.GIPHY_FE_DEFAULT_API_SERVICE_KEY, ...query },
        })
    )
        .then(checkStatus)
        .then((response: Response) => response.json())

// Auth Block
export const createUser = (user) => getResults(`/api/v3/register`, POST(user, true))

export const verifyEmail = (data) => getResults(`/api/v3/register/confirm`, POST(data, true))

export const resendEmail = (data) => getResults(`/api/v3/register/resend`, POST(data, true))
