import { IChannel } from '@giphy/js-types'

export type TypeaheadState = {
    query?: string
    terms?: string[]
    channels?: IChannel[]
}
export const initialState: TypeaheadState = {
    query: '',
    terms: [],
    channels: [],
}

export type TypeaheadAction =
    | { type: 'RESET_TYPEAHEAD' }
    | { type: 'SET_TYPEAHEAD_QUERY'; query: string }
    | { type: 'SET_TYPEAHEAD_TERMS'; terms: string[] }
    | { type: 'SET_TYPEAHEAD_CHANNELS'; channels: IChannel[] }

export default (state: TypeaheadState = initialState, action: TypeaheadAction): TypeaheadState => {
    switch (action?.type) {
        case 'SET_TYPEAHEAD_QUERY':
            return { ...state, query: action.query || '' }
        case 'SET_TYPEAHEAD_TERMS':
            return { ...state, terms: action.terms || [] }
        case 'SET_TYPEAHEAD_CHANNELS':
            return { ...state, channels: action.channels || [] }
        case 'RESET_TYPEAHEAD':
            return { ...initialState }
        default:
            return state
    }
}

export const hasTypeahead = (state) => !!state.terms.length || !!state.channels.length
