import styled, { css } from 'styled-components'

export const Fill = styled.div<{ $disabled?: boolean }>`
    box-sizing: border-box;
    height: 100%;
    min-width: 0;
    width: 100%;

    ${({ $disabled }) =>
        !$disabled &&
        css`
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        `}
`
