import { useContext } from 'react'
import Test from 'shared/components/test'
import { CY_CREATE_COLLECTION__BUTTON } from 'ui'
import { CollectionStyleContext } from './../context/collection-style-context'
import { Item, ItemGif, ItemText } from './sc'

type Props = {
    displayName: string
    backgroundText: string | undefined
    onClick: () => void
}

const AddToButton = ({ displayName, onClick, backgroundText }: Props) => {
    const { itemWidth } = useContext(CollectionStyleContext)

    return displayName ? (
        <Test id={CY_CREATE_COLLECTION__BUTTON}>
            <Item onClick={onClick} gutter itemWidth={itemWidth}>
                <ItemGif src="https://media.giphy.com/media/fTytsfKCVyb6lB75jM/giphy.gif" alt="New collection" />
                <ItemText background={backgroundText || ''}>{displayName}</ItemText>
            </Item>
        </Test>
    ) : null
}

export default AddToButton
