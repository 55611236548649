import { IChannel } from '@giphy/js-types'
import { take } from 'lodash'
import { channelSearch, searchTags } from 'shared/api'
import { ChannelItem, Item } from '../types'

const searchResultsCache = { '': [] }
const channelSearchResultsCache = { '': [] }

function getChannelName(searchTerm: string) {
    return searchTerm.split(' ')[0].substring(1)
}

const getChannels = (channels: IChannel[], maxChannels: number): ChannelItem[] =>
    take(channels, maxChannels).map(({ display_name, slug, user, id, ...rest }) => ({
        name: display_name,
        img: user.avatar_url,
        slug,
        link: `/channels/id/${id}`,
        type: 'channels',
        // @ts-ignore IChannel doesn't have a response_id yet
        response_id: rest.response_id,
        analytics_response_payload: rest.analytics_response_payload,
        channelId: id,
    }))

const getDropDownItems = (
    searchTerm: string,
    searchType: string,
    maxChannels: number,
    maxItems: number,
    maxSocialChannels: number
) => {
    // check the cache if the cache isnt avail make the request
    if (searchType === 'regularSearch') {
        if (searchResultsCache[searchTerm]) {
            return searchResultsCache[searchTerm]
        } else {
            // request results
            return Promise.all([searchTags(searchTerm), channelSearch(searchTerm)]).then(([tags, channels]) => {
                // take MAX_NUMBER_OF_CHANNELS and map them to the dropdown format
                const channelResults: Item[] = getChannels(channels, maxChannels)

                // based on channelResults, choose a number of tags, and map them to dropdown format
                const tagResults = take(tags.data, maxItems - channelResults.length).map(
                    ({ name, name_encoded, response_id, analytics_response_payload }) => ({
                        name: name.toLowerCase(),
                        link: `/search/${name_encoded}`,
                        type: 'tags',
                        response_id,
                        analytics_response_payload,
                    })
                )

                // store the result so we can display immediately next time
                searchResultsCache[searchTerm] = [...tagResults, ...channelResults]
                return [...tagResults, ...channelResults]
            })
        }
    } else if (searchType === 'socialSearch') {
        if (channelSearchResultsCache[searchTerm]) {
            return channelSearchResultsCache[searchTerm]
        } else {
            // request results
            return Promise.resolve(channelSearch(getChannelName(searchTerm))).then((channels) => {
                // take MAX_NUMBER_OF_CHANNELS and map them to the dropdown format
                const channelResults: Item[] = getChannels(channels, maxSocialChannels)
                channelSearchResultsCache[searchTerm] = [...channelResults]
                return channelResults
            })
        }
    }
}

export default getDropDownItems
