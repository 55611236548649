import { giphyBlack, giphyDarkCharcoal, giphyRed } from '@giphy/colors'
import { useContext, useState } from 'react'
import FileAccept from 'shared/components/file-portal/file-accept'
import InputWrapper from 'shared/components/inputs/file-input'
import MessageContext from 'shared/contexts/message'
import styled, { css } from 'styled-components'
import { STATUS_MESSAGES } from '../message/flash-message'
import { CollectionEditorContext } from './editor'
import OptionalLabel from './optional-label'
import { Label, Section } from './sc'

export const MAX_FILE_SIZE = 5 // MB what you upload is what you get back so need to keep this small-ish
const inputCss = css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
`

const Double = styled(Section)`
    display: flex;
    input[type='file'] {
        ${inputCss}
    }
`

export const ChooseFile = styled.div<{ width: number }>`
    font-family: 'interface', sans-serif;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    background: ${giphyBlack};
    display: flex;
    justify-content: center;
    min-width: ${({ width }) => `${width}px`};
    margin-bottom: 4px;
    padding: 8px;
    height: 36px;
    input[type='file'] {
        ${inputCss}
    }
`

export const BannerImage = styled.div<{ src: string }>`
    height: 36px;
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${(props) => props.src}');
`

const TrashBanner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
`

const BannerContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    margin-left: 20px;
    margin-top: 18px;
    align-items: center;
    &:hover {
        ${TrashBanner} {
            visibility: visible;
        }
    }
`

const Trash = styled.div`
    background: black;
    width: 24px;
    height: 26px;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    color: ${giphyRed};
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`

const MaxInfo = styled(Label)`
    position: absolute;
    margin-left: 52px;
    margin-bottom: 3px;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    color: ${giphyDarkCharcoal};
`

const BannerUpload = ({ onFileAccepted, onError, bannerPreview, removeBanner }) => {
    const { channel } = useContext(CollectionEditorContext)
    const inputProps = {
        name: 'banner',
        onFileAccepted,
        onError,
        maxFilesize: MAX_FILE_SIZE,
        acceptMultiple: false,
        mimeTypes: ['image/*'],
    }
    const { ancestors = [] } = channel
    const channelWithBanner = [...ancestors, channel].reverse().find((c) => c.banner_image)
    const preview = bannerPreview || channelWithBanner?.banner_image
    const ownsBanner = bannerPreview || channelWithBanner?.id === channel.id
    return (
        <Double>
            <div>
                <OptionalLabel label="Banner">
                    <MaxInfo>5 mb max, 1040x160</MaxInfo>
                </OptionalLabel>
                <ChooseFile width={preview ? 220 : 480}>
                    Choose File
                    <InputWrapper {...inputProps} />
                </ChooseFile>
            </div>
            {preview && (
                <BannerContainer>
                    <BannerImage src={preview} />
                    {ownsBanner && (
                        <TrashBanner>
                            <Trash className="ss-trash" onClick={removeBanner} />
                        </TrashBanner>
                    )}
                </BannerContainer>
            )}
        </Double>
    )
}

const Banner = FileAccept(BannerUpload)

const BannerManager = () => {
    const [bannerPreview, setBannerPreview] = useState()
    const { sendMessage } = useContext(MessageContext)
    const { updateChannel } = useContext(CollectionEditorContext)
    const onPreviewReady = (files: any[]) => {
        const [file] = files
        setBannerPreview(file.preview)
        updateChannel({ banner_image: file.file })
    }
    const onError = (message) => {
        sendMessage(STATUS_MESSAGES[message], 'error')
    }
    const removeBanner = () => {
        setBannerPreview(undefined)
        updateChannel({ banner_image: '' })
    }
    return <Banner {...{ onPreviewReady, onError, bannerPreview, removeBanner }} />
}

export default BannerManager
