import styled from 'styled-components'
import { Button } from 'shared/components/buttons'
import { mobile } from 'shared/util/media-query'
import { TEAM_GUTTER_WIDTH } from '../constants'

const JobsButton = styled(Button).attrs({ as: 'a' })`
    width: 260px;
`

const TeamButton = styled(JobsButton)`
    margin-right: ${TEAM_GUTTER_WIDTH}px;
`

const Container = styled.div`
    align-items: center;
    display: flex;
    margin-top: 2em;
    justify-content: center;

    ${mobile.css`
        flex-direction: column;

        ${TeamButton} {
            margin-bottom: ${TEAM_GUTTER_WIDTH}px;
            margin-right: 0;
        }
    `}
`

const AboutPageTeamLinks = () => (
    <Container data-padded>
        <TeamButton href="https://giphy.com/team" target="_blank">
            See the Full Team
        </TeamButton>
        <JobsButton href="https://boards.greenhouse.io/giphy/" target="_blank">
            Browse Open Jobs
        </JobsButton>
    </Container>
)

export default AboutPageTeamLinks
