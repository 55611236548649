import { FIELDS } from 'shared/components/form/fields'
import { map } from 'lodash'
const create = (placeholder, key) => [key, { placeholder }]
const SocialHandlePlaceholders = {
    usernameOnly: map(
        {
            [FIELDS.socialHandle]: 'Your social media username',
        },
        create
    ),
    needFullLink: map(
        {
            [FIELDS.socialHandle]: 'Your social media URL',
        },
        create
    ),
}

export default SocialHandlePlaceholders
