import { giphyLightestGrey } from '@giphy/colors'
import { SyntheticEvent } from 'react'
import Logo from 'shared/components/icons/logo'
import { titleFont } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import VisitLink from '../visit-giphy'

const GiphyLogo = styled.a`
    display: flex;
    font-family: ${titleFont};
    font-size: 24px;
    justify-content: center;

    span {
        display: inline-block;
        line-height: 32px;
        padding: 0 5px;
    }
`

const Links = styled.div`
    margin: 0 auto;

    a {
        color: ${giphyLightestGrey};
        display: inline-block;
        font-weight: bold;
        margin: 0 0.8em;
        text-decoration: none;
    }
`

const Container = styled.div`
    align-items: center;
    display: flex;
    font-size: 0.7em;
    height: 60px;
    justify-content: center;
    margin: 0 auto 3em;
    max-width: 1040px;
    position: relative;
    width: 100%;

    ${mobile.css`
        ${Links} {
            display: none;
        }
        ${GiphyLogo} {
            margin-right: auto;
        }
    `}
`

const AboutPageNavigation = () => {
    const onLinkClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
        const bits: string[] = e.currentTarget.href.split('#')
        const selector: string = bits[bits.length - 1] || ''
        const el: HTMLElement | null = document.getElementById(selector)
        e.preventDefault()
        el && el.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <Container data-padded>
            <GiphyLogo href="/">
                <Logo />
                <span>GIPHY</span>
            </GiphyLogo>
            <Links>
                <a href="#about-giphy" onClick={onLinkClick}>
                    About GIPHY
                </a>
                <a href="#recent-news" onClick={onLinkClick}>
                    Recent News
                </a>
                <a href="#philosophies-and-values" onClick={onLinkClick}>
                    Philosophies and Values
                </a>
                <a href="#contact" onClick={onLinkClick}>
                    Contact
                </a>
                <a href="#our-team" onClick={onLinkClick}>
                    Our Team
                </a>
            </Links>
            <VisitLink />
        </Container>
    )
}

export default AboutPageNavigation
