import { giphyBlue } from '@giphy/colors'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 30px;
    margin-top: 10px;
    padding: 0 13px;
    width: 100%;
`
const ClockImage = styled.span`
    color: ${(props) => props.theme.color};
    margin: 6px 7px 0 0;
    font-size: 12px;
`
const InputField = styled.input`
    border: none;
    background-color: transparent;
    color: white;
    font-size: 22px;
    font-family: 'interface', sans-serif;
    font-weight: bold;
    line-height: 22px;
    margin: 0;
    padding: 0;
    width: 60px;

    &:focus {
        border: none;
        outline: none;
    }
    &::-webkit-clear-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-datetime-edit-ampm-field {
        display: none;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
`

const ToggleContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 6px 0 0 3px;
`

const MidnightToggle = styled.p`
    color: #ffffff;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
    font-size: 12px;
    font-weight: bold;
    justify-content: space-around;
    margin: 0 8px 0 0;
    cursor: pointer;
`

const NowButton = styled.div`
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 2px 0 0 auto;
`

export default class TimeSelector extends PureComponent {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        iconColor: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        allowMultiple: true,
        iconColor: giphyBlue,
    }

    state = {
        time: '00:00',
        isPm: false,
    }

    static getDerivedStateFromProps({ date }, { lastDate }) {
        if (date !== lastDate) {
            return {
                time: date ? format(date, 'hh:mm') : '00:00',
                isPm: date ? date.getHours() > 11 : false,
                lastDate: date,
            }
        }
        return null
    }

    updateTime() {
        if (!this.timeInput) return

        const { onChange } = this.props
        const { isPm } = this.state
        const time = this.timeInput.value
        const bits = time.split(':')
        const hours = bits[0] % 12
        const minutes = bits[1]
        onChange({
            hours: hours + (isPm ? 12 : 0),
            minutes,
        })
    }

    onChange = () => {
        this.updateTime()
    }

    onAmClick = () => {
        this.setState({ isPm: false }, () => {
            this.updateTime()
        })
    }

    onPmClick = () => {
        this.setState({ isPm: true }, () => {
            this.updateTime()
        })
    }

    onNowClick = () => {
        const { onChange } = this.props
        const now = new Date()
        onChange({
            hours: now.getHours(),
            minutes: now.getMinutes(),
            isNowClick: true,
        })
    }

    render() {
        const { iconColor } = this.props
        const { time, isPm } = this.state
        return (
            <Wrapper>
                <ClockImage className={'ss-clock'} iconColor={iconColor} />
                <InputField onChange={this.onChange} ref={(c) => (this.timeInput = c)} type="time" value={time} />
                <ToggleContainer>
                    <MidnightToggle active={!isPm} onClick={this.onAmClick}>
                        AM
                    </MidnightToggle>
                    <MidnightToggle active={isPm} onClick={this.onPmClick}>
                        PM
                    </MidnightToggle>
                </ToggleContainer>
                <NowButton onClick={this.onNowClick}>Now</NowButton>
            </Wrapper>
        )
    }
}
