import { giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { ControlSize, Gradient } from 'types'
import {
    borderRadiusFromControlSize,
    colorFromGradientPosition,
    fontSizeFromControlSize,
    gradientToCss,
    gradientToHexColors,
    heightFromControlSize,
} from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import LoaderComponent from '../../loader/blocks'

export const Label = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`

export const Icon = styled.span`
    display: inline-block;
    font-size: 0.9em;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;

    > i {
        display: inline-block;
        height: 1em;
        margin-top: 0.2em;
        width: 1em;
    }

    > svg {
        display: inline-block;
        flex-shrink: 0;
    }

    &:first-child {
        margin-right: 0.6em;
    }

    &:last-child {
        margin-left: 0.6em;
    }

    &:only-child {
        margin-left: 0;
        margin-right: 0;
    }
`

export const Button = styled.button<{
    $color: string
    $dimmed: boolean
    $disabled: boolean
    $gradient?: Gradient
    $iconGradient?: Gradient | undefined
    $size: ControlSize
    $textColor?: string
}>`
    align-items: center;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: center;
    line-height: normal;
    text-align: center;
    user-select: none;

    &:active {
        opacity: 0.8;
    }

    &:focus-visible {
        outline: 2px solid ${giphyYellow};
    }

    ${({ $color, $dimmed, $disabled, $gradient, $iconGradient, $size, $textColor }) => {
        const background = $gradient ? colorFromGradientPosition($gradient, 0.5) : $color
        const color = $textColor || textColorFromBackgroundColor(background)

        return css`
            background: ${$gradient ? gradientToCss($gradient) : $color};
            border-radius: ${borderRadiusFromControlSize($size)}px;
            color: ${color};
            font-size: ${fontSizeFromControlSize($size)}px;
            height: ${heightFromControlSize($size)}px;
            min-width: ${heightFromControlSize($size)}px;
            padding: 0 0.8em;

            ${Icon} > svg {
                height: ${Math.round(heightFromControlSize($size) * 0.4)}px;
                width: ${Math.round(heightFromControlSize($size) * 0.5)}px;

                #gradient-stop-1 {
                    stop-color: ${$iconGradient && gradientToHexColors($iconGradient)[0]};
                }
                #gradient-stop-2 {
                    stop-color: ${$iconGradient && gradientToHexColors($iconGradient)[1]};
                }

                path {
                    fill: ${!$iconGradient && color};
                }
            }

            ${$dimmed &&
            css`
                opacity: 0.5;
            `}

            ${$disabled &&
            css`
                pointer-events: none;
            `}
        `
    }}
`

export const Loader = styled(LoaderComponent)``
