import * as colors from '@giphy/colors'
import { Button } from 'shared/components/buttons'
import { Title as BrandTitle } from 'shared/components/text-elements'
import isMobile from 'shared/util/is-mobile'
import { desktop, mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { AmplifyIcon, CollectionsIcon, DistributionIcon, InsightsIcon, VideoIcon } from './icons'

const Container = styled.div`
    text-align: left;
    width: 1040px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    backdrop-filter: blur(10px);

    ${mobile.css`
        width: 100%;
        flex-direction:column;
        margin-bottom: 10px;
    `};
`

const PartnerTypeDescription = styled.div<{ chosen: boolean; isBrand?: boolean }>`
    padding: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: ${({ chosen }) => (chosen ? '2px solid rgb(0, 255, 153);' : '2px solid rgb(62, 62, 62)')};
    height: 480px;
    width: 510px;

    ${desktop.css` 
        ${({ chosen, isBrand }) =>
            chosen &&
            `
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 3px 0px 8px 0px;
                height: 61px;
                width: 67px;
                background: url(/static/img/apply/${isBrand ? 'brand.png' : 'arts.png'}) center no-repeat;
                background-color: rgb(0, 255, 153);
                background-size: 35px;
            }
        `}
    `}

    p {
        width: 300px;
        font-size: 16px;
        text-align: center;
        margin: 8px auto 45px;

        ${mobile.css`
            width: 65%;
            font-weight: normal;
            font-size: 14px;
            margin: 8px auto 25px;
        `}

        a {
            color: ${colors.giphyBlue};

            &:hover {
                color: white;
            }
        }
    }

    ${mobile.css`
    width: 100%;
    height: auto;
    padding: 25px 0px 30px;
    margin: 5px 0px;
`};
`

const TypeIcon = styled.img`
    width: 35px;
    height: 35px;
    margin: 0px auto 20px;
    display: block;
    object-fit: contain;
`

export const TypeTitle = styled(BrandTitle)`
    text-align: center;
    font-size: 32px;
    font-weight: 400;

    ${mobile.css`
        font-size: 24px;
    `}
`

const TypeFeature = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    ${mobile.css`
        display: none;
    `};
`

const Description = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
`

const Lede = styled.span`
    margin-bottom: 3px;
`

const Details = styled.span`
    color: ${colors.giphyLightGrey};
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
`

export const TypeButton = styled(Button)<{ notChosen?: boolean; chosen?: boolean }>`
    width: 180px;
    background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
    margin: auto;
    ${({ notChosen }) => notChosen && `background: ${colors.giphyDarkGrey};`}
    ${({ chosen }) =>
        chosen &&
        `
    background: linear-gradient(45deg, rgb(0, 255, 153) 0%, rgb(0, 172, 215) 100%);
    color: black;
    pointer-events: none;
    `}

    ${mobile.css`
        margin: 0 auto;
        background: none;
        height: auto;
        padding: 0;
        line-height: 14px;
        color: ${colors.giphyGreen}; 
        ${({ notChosen }) => notChosen && `color: ${colors.giphyDarkGrey};`}
    `}
`
type Props = {
    applyType: string
    formShown?: boolean
    chooseType: (whichType: string) => void
}

const CreatorOrBrandContainer = ({ applyType, formShown, chooseType }: Props) => (
    <Container>
        <PartnerTypeDescription
            onClick={() => (isMobile() ? chooseType('creator') : null)}
            chosen={applyType === 'creator'}
        >
            {isMobile() && <TypeIcon src="/static/img/apply/arts-white.png" />}
            <TypeTitle>Creator</TypeTitle>
            <p>Best for Creatives, Streamers, and Content Creators</p>
            <TypeFeature>
                <DistributionIcon />
                <Description>
                    <Lede>Access Your Content Everywhere</Lede>
                    <Details>Distribute your work via our Search and API Network</Details>
                </Description>
            </TypeFeature>
            <TypeFeature>
                <VideoIcon />
                <Description>
                    <Lede>Show Off Your Skills</Lede>
                    <Details>Experiment with new content types like Stories and Clips</Details>
                </Description>
            </TypeFeature>
            <TypeFeature>
                <InsightsIcon />
                <Description>
                    <Lede>Track Your Progress</Lede>
                    <Details>See which pieces of content are performing the best</Details>
                </Description>
            </TypeFeature>
            <TypeButton
                type="button"
                notChosen={applyType !== 'creator' && formShown}
                chosen={applyType === 'creator' && formShown}
                onClick={() => chooseType('creator')}
            >
                {applyType === 'creator' ? 'Creator Selected!' : 'Select Creator'}
            </TypeButton>
        </PartnerTypeDescription>
        <PartnerTypeDescription
            onClick={() => (isMobile() ? chooseType('brand') : null)}
            isBrand
            chosen={applyType === 'brand'}
        >
            {isMobile() && <TypeIcon src="/static/img/apply/brand-white.png" />}
            <TypeTitle>Brand</TypeTitle>
            <p>Best for Organizations, Musicians, and Public Figures</p>
            <TypeFeature>
                <AmplifyIcon />
                <Description>
                    <Lede>Grow Your Fanbase</Lede>
                    <Details>Distribute your work via our Search and API Network</Details>
                </Description>
            </TypeFeature>
            <TypeFeature>
                <CollectionsIcon />
                <Description>
                    <Lede>Build Your Library</Lede>
                    <Details>Turn video into GIFs with bulk content & curation tools</Details>
                </Description>
            </TypeFeature>
            <TypeFeature>
                <InsightsIcon />
                <Description>
                    <Lede>Track Your Progress</Lede>
                    <Details>Get access to daily analytics and search insights</Details>
                </Description>
            </TypeFeature>
            <TypeButton
                type="button"
                notChosen={!!(applyType !== 'brand' && formShown)}
                chosen={!!(applyType === 'brand' && formShown)}
                onClick={() => chooseType('brand')}
            >
                {applyType === 'brand' ? 'Brand Selected!' : 'Select Brand'}
            </TypeButton>
        </PartnerTypeDescription>
    </Container>
)

export default CreatorOrBrandContainer
