import { giphyBlack, giphyDarkGrey, giphyLightestGrey, giphyWhite } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL, BUTTON_ACTIVE_OPACITY } from '../constants'
import { GradientBlock } from './blocks'

export const ToolButton = styled.div<{ disabled?: boolean }>`
    border-radius: ${BORDER_RADIUS_SMALL}px;
    cursor: pointer;
    user-select: none;

    &:active {
        opacity: ${BUTTON_ACTIVE_OPACITY};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.25;
            pointer-events: none;
        `}
`

export const BlackButton = styled(ToolButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    background: ${giphyBlack};
    color: ${giphyWhite};
    border-radius: ${BORDER_RADIUS_MEDIUM}px;
    font-size: 17px;
    padding-left: 29px;
    padding-right: 29px;
    font-weight: bold;
`

export const FileButton = styled(ToolButton)`
    position: relative;

    input[type='file'] {
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`

export const SquareButton = styled(ToolButton)`
    align-items: center;
    background: #000;
    color: #fff;
    font-size: 15px;
    height: 32px;
    line-height: 34px;
    text-align: center;
    transition: background 0.15s ease-out;
    width: 32px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

export const UtilityButton = styled(ToolButton)`
    background: #000;
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    i {
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        line-height: 38px;
        vertical-align: middle;
    }

    i:first-child {
        margin-right: 6px;
    }

    i:last-child {
        margin-left: 6px;
    }
`

export const GradientButton = styled(GradientBlock)<{ disabled?: boolean }>`
    align-items: center;
    border-radius: ${BORDER_RADIUS_MEDIUM}px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 42px;
    justify-content: center;
    padding: 0 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    width: 100%;

    input[type='file'] {
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &:active {
        opacity: ${BUTTON_ACTIVE_OPACITY};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background: ${giphyDarkGrey};
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
        `}
`

export const MiniIcon = styled.div`
    background: ${giphyLightestGrey};
    border-radius: 50%;
    color: #000;
    display: inline-block;
    font-size: 10px;
    font-weight: normal;
    height: 16px;
    line-height: 18px;
    text-align: center;
    width: 16px;
`

export const WelcomeButton = styled(UtilityButton)`
    background: rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
`
