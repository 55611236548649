import { giphyRed } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { useContext, useRef, useState } from 'react'
import { favoritesByUser, useLocalFavorites } from 'shared/api/favorites'
import NoFavorites from 'shared/components/favorite/no-favorites'
import Grid from 'shared/components/grid'
import { Title } from 'shared/components/text-elements'
import UserContext from 'shared/contexts/user'
import styled from 'styled-components'

const FavHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
`

const HeartIcon = styled.i`
    color: ${giphyRed};
    display: inline-block;
    margin-right: 10px;
    font-size: 25px;
    height: 27px;
`

const LocalFavoritesBanner = styled.div`
    text-align: left;
    background: linear-gradient(-135deg, rgb(255, 102, 102) 0%, rgb(153, 51, 255) 100%);
    border-radius: 0px;
    padding: 12px;
    font-family: InterFace;
    font-size: 15px;
    margin-bottom: 6px;
    box-sizing: content-box;
    h5 {
        font-family: InterFace;
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 6px;
    }
    a {
        font-weight: bold;
        margin-top: 6px;
        display: inline-block;
    }
    .ss-icon {
        font-weight: normal;
        display: inline-block;
        font-size: 11px;
        height: 13px;
    }
`

const LocalFavorites = () => {
    const limit = 25
    const { localFavorites, fetchGifs } = useLocalFavorites(limit)
    return (
        <div>
            {localFavorites && localFavorites.length > 0 ? (
                <>
                    <LocalFavoritesBanner>
                        <h5>Create a GIPHY account to access your favs from anywhere!</h5>
                        <p>
                            Your favorites are safer with a GIPHY account, these are only saved locally on this phone &
                            could be lost.
                        </p>
                        <a href="/login">
                            Login or Sign Up <div className="ss-icon ss-navigateright" />
                        </a>
                    </LocalFavoritesBanner>
                    <FavHeader>
                        <HeartIcon className="ss-heart" />
                        <Title>
                            {localFavorites.length} Favorite{localFavorites.length > 1 && 's'}
                        </Title>
                    </FavHeader>
                    <Grid key={localFavorites.length} fetchGifs={fetchGifs} columns={4} />
                </>
            ) : (
                <NoFavorites />
            )}
        </div>
    )
}

const UserFavorites = () => {
    const { user } = useContext(UserContext)
    const [noFavorites, setNoFavorites] = useState<boolean>(false)

    const gifs = useRef<IGif[]>([])
    const fetchGifs = async () => {
        const result = await favoritesByUser(user, gifs.current.length)
        gifs.current = [...gifs.current, ...result.data]

        if (gifs.current.length === 0) {
            setNoFavorites(true)
        }

        return result
    }

    if (noFavorites) {
        return <NoFavorites />
    }
    return (
        <div>
            <div>
                <div style={{ clear: 'both' }} />
            </div>
            <Grid fetchGifs={fetchGifs} columns={4} />
        </div>
    )
}

const Favorites = () => {
    const { user } = useContext(UserContext)
    return user.loggedIn ? <UserFavorites /> : <LocalFavorites />
}

export default Favorites
