import Dropdown from 'shared/components/form/ui/dropdown'
import { useContext, useEffect, useState } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { useCanBulkEdit } from 'shared/hooks/use-permissions'
import styled from 'styled-components'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import { BulkEditButton } from './edit-panel/buttons/bulk-edit'
import ModalContext from 'shared/contexts/modal'
import isMobile from 'is-mobile'
import { bulkEditorButton } from 'shared/css/z-indexes'

const Container = styled.div`
    position: fixed;
    right: 5px;
    bottom: 35px;
    z-index: ${bulkEditorButton};
`

const StyledBulkEditButton = styled(BulkEditButton)`
    height: 80px;
    width: 80px;
    padding: 10px;
`

const Tooltip = styled.div`
    position: absolute;
    top: -100px;
    left: -150px;
    width: 225px;
`

const TooltipText = styled.div`
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    line-height: 20px;
`

const LearnMoreLink = styled.a`
    font-weight: bold;
    text-decoration: underline;
    pointer-events: all;
`

const BulkEditorToggle = () => {
    const [hasSeenTooltip, setHasSeenTooltip] = useLocalStorage(`has-seen-bulk-edit-tooltip`, false)
    const [showToolTip] = useState(!hasSeenTooltip)
    const { toggleBulkEditMode, isBulkEditMode } = useContext(BulkEditModeContext)
    const { openBulkEditPanel } = useContext(ModalContext)
    const hasPermission = useCanBulkEdit()
    // We only want to show this tooltip once
    useEffect(() => {
        if (hasPermission) {
            setHasSeenTooltip(true)
        }
    }, [])

    useEffect(() => {
        if (isBulkEditMode) {
            // open the bulk edit mode on page load if isBulkEditMode
            openBulkEditPanel(true)
        }
    }, [isBulkEditMode])

    if (!hasPermission || isBulkEditMode || isMobile()) {
        return null
    }

    return (
        <Container>
            {showToolTip && (
                <Tooltip>
                    <Dropdown isExtendUp={true} nipX="calc(100% - 40px)">
                        <TooltipText>
                            <div>Say hi 👋 to Bulk Editing!</div>
                            <div>Click the pencil to get started, or</div>
                            <div>
                                <LearnMoreLink
                                    target="_blank"
                                    href="https://support.giphy.com/hc/en-us/articles/360030451532"
                                >
                                    Learn More
                                </LearnMoreLink>
                            </div>
                        </TooltipText>
                    </Dropdown>
                </Tooltip>
            )}
            <StyledBulkEditButton onClick={toggleBulkEditMode} />
        </Container>
    )
}

export default BulkEditorToggle
