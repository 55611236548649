// This component can be used to created things like Modals, Tooltips, etc...
import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children, className = 'root-portal', element = 'div' }) => {
    const [container] = useState(() => {
        const el = document.createElement(element)
        el.classList.add(className)
        return el
    })

    useEffect(() => {
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return ReactDOM.createPortal(children, container)
}

export default Portal
