import { useContext } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { giphyBlue, giphyWhite } from '../../../css/colors'
import VideoContext from '../video-context'

const closeButtonIn = keyframes`
    0% { transform: rotate(-180deg); }
    100% { transform: rotate(0deg); }
`

const Tab = styled.div`
    box-shadow: ${(props) => (props.active ? `inset 0 -4px 0 ${giphyBlue}` : 'none')};
    color: ${(props) => (props.active ? giphyBlue : giphyWhite)};
    cursor: pointer;
    font-weight: bold;
    margin-left: 20px;
    user-select: none;

    &:first-child {
        margin-left: 0;
    }
`

const Container = styled.div`
    border-bottom: 1px solid rgba(216, 216, 216, 0.16);
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    margin: 0 ${(props) => (props.noPadding ? 20 : 70)}px 0 25px;

    ${(props) =>
        props.isSmall &&
        css`
            border-bottom: 0;
            margin: 0 10px;

            ${Tab} {
                box-shadow: none;
            }
        `};
`

const CloseButton = styled.div`
    animation: ${closeButtonIn} 0.2s ease-out;
    color: ${giphyWhite};
    cursor: pointer;
    font-size: 16px;
    height: 60px;
    line-height: 64px;
    margin-left: auto;
    margin-right: -10px;
    text-align: center;
    width: 60px;
`

const ShareHeader = () => {
    const { isFullscreen, isSmall, setIsShare, noCutout, activeShareTab, setActiveShareTab } = useContext(VideoContext)
    return (
        <Container isSmall={isSmall} noPadding={isFullscreen || noCutout}>
            <Tab active={activeShareTab === 'social'} onClick={() => setActiveShareTab('social')}>
                Share Link
            </Tab>
            <Tab active={activeShareTab === 'embed'} onClick={() => setActiveShareTab('embed')}>
                Embed Code
            </Tab>
            <CloseButton className="ss-delete" onClick={() => setIsShare(false)} />
        </Container>
    )
}

export default ShareHeader
