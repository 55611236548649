import { useEffect, useState } from 'react'
import { Dimensions } from 'types'
import { TooltipDirection } from '../../types'

const useTooltipDirection = (
    triggerRect: DOMRect | null,
    dimensions: Dimensions | null,
    defaultDirection: TooltipDirection
) => {
    const [direction, setDirection] = useState<TooltipDirection>(defaultDirection)

    useEffect(() => {
        if (!triggerRect || !dimensions?.height || !dimensions.width) {
            return
        }

        const { bottom, left, right, top } = triggerRect
        const { height, width } = dimensions

        switch (direction) {
            case 'down':
                setDirection(bottom + height > window.innerHeight ? 'up' : 'down')
                break
            case 'left':
                setDirection(left - width < 0 ? 'right' : 'left')
                break
            case 'right':
                setDirection(right + width > window.innerWidth ? 'left' : 'right')
                break
            case 'up':
                setDirection(top - height < 0 ? 'down' : 'up')
                break
        }
    }, [dimensions?.height, dimensions?.width, !!triggerRect])

    return direction
}

export default useTooltipDirection
