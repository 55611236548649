import * as colors from '@giphy/colors'
import styled from 'styled-components'

export const FILTERS = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
]

const Container = styled.div`
    display: flex;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
`

const Filter = styled.div<{ active: boolean }>`
    color: ${(props) => (props.active ? colors.giphyWhite : colors.giphyLightCharcoal)};
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;

    &:hover {
        color: ${colors.giphyWhite};
    }
`

type Props = {
    filter: string
    setLetter: (arg0: string) => void
}

const ArtistFilters = ({ filter, setLetter }: Props) => (
    <Container>
        <Filter active={!filter} onClick={() => setLetter('')}>
            All
        </Filter>
        <Filter active={filter === '#'} onClick={() => setLetter('#')}>{`#`}</Filter>
        {FILTERS.map((letter) => (
            <Filter key={letter} active={letter === filter} onClick={() => setLetter(letter)}>
                {letter}
            </Filter>
        ))}
    </Container>
)

export default ArtistFilters
