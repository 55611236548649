import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getIconData } from '../../util/story-utils'
import Gif from '../gif/media'
import Dropdown from '../dropdown'

const CoverGif = styled.div`
    flex-shrink: 0;
    height: ${(props) => props.size}px;
    margin-right: 15px;
    position: relative;
    width: ${(props) => props.size}px;
`

const GifIcon = styled.div`
    background: rgba(33, 33, 33, 0.8);
    color: ${(props) => props.color};
    height: 100%;
    font-size: 16px;
    left: 0;
    line-height: ${(props) => props.size + 2}px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
`

const Tooltip = styled.div`
    bottom: 100%;
    left: -10px;
    line-height: 1.3;
    margin-bottom: 5px;
    position: absolute;
`

export default class StoryItemGif extends PureComponent {
    static propTypes = {
        story: PropTypes.object.isRequired,
        size: PropTypes.number.isRequired,
        onLoad: PropTypes.func,
        onUnload: PropTypes.func,
    }

    state = {
        showDropdown: false,
    }

    onMouseOver = () => {
        this.setState({ showDropdown: true })
    }

    onMouseOut = () => {
        this.setState({ showDropdown: false })
    }

    render() {
        const { size, story, onLoad, onUnload } = this.props
        const { showDropdown } = this.state
        const { cover_gif, publish_datetime: publishDateTime, is_private: isPrivate } = story
        const { color, message, icon } = getIconData({ publishDateTime, isPrivate })
        return (
            <CoverGif size={size}>
                {cover_gif && (
                    <Gif gif={cover_gif.gif} height={size} onLoad={onLoad} onUnload={onUnload} width={size} />
                )}
                {icon && (
                    <GifIcon
                        className={icon}
                        color={color}
                        onMouseOut={this.onMouseOut}
                        onMouseOver={this.onMouseOver}
                        size={size}
                    />
                )}
                {message && showDropdown && (
                    <Tooltip>
                        <Dropdown isExtendUp nipX="35px">
                            {message}
                        </Dropdown>
                    </Tooltip>
                )}
            </CoverGif>
        )
    }
}
