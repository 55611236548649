exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38SOpMLQw5kklyvDVg3LA5 {\n    display: flex;\n}\n\n.sHWDHJHWZ_qN-i70BHB-5 {\n    width: 45px;\n    height: 45px;\n    -o-object-fit: cover;\n       object-fit: cover;\n    margin-right: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"avatar": "_38SOpMLQw5kklyvDVg3LA5",
	"avatarImage": "sHWDHJHWZ_qN-i70BHB-5"
};