import {
    CSSProperties,
    ForwardedRef,
    ReactNode,
    SyntheticEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { useDebounce } from 'react-use'
import { ControlSize, TextFormat } from 'types'
import { formatText, heightFromControlSize } from 'utils'
import { Input, Preview, Textarea, Wrapper } from './style'

export type TextInputRef = {
    focus: () => void
}

export type TextInputProps = {
    autoComplete?: string
    autoCorrect?: boolean
    autoFocus?: boolean
    children?: ReactNode
    className?: string
    color?: string
    defaultValue?: string
    delay?: number
    disabled?: boolean
    forceDisable1Password?: boolean
    format?: TextFormat
    maxLength?: number
    multiline?: boolean
    placeholder?: string
    preview?: ReactNode
    readOnly?: boolean
    selectOnFocus?: boolean
    size?: ControlSize
    spellCheck?: boolean
    style?: CSSProperties
    tabIndex?: number
    textColor?: string
    value?: string
    onBlur?: () => void
    onChange?: (value: string) => void
    onEnterKeyPress?: () => void
    onFocus?: () => void
    onKeyPress?: (key: string, shift: boolean) => void
}

const TextInput = (
    {
        autoComplete,
        autoCorrect = false,
        autoFocus,
        children,
        className,
        color = '#fff',
        defaultValue = '',
        delay = 0,
        disabled = false,
        forceDisable1Password = false,
        format,
        maxLength,
        multiline = false,
        placeholder,
        preview,
        readOnly = false,
        selectOnFocus = false,
        size = 'small',
        spellCheck = false,
        style = {},
        tabIndex,
        textColor,
        value: valueProp,
        onBlur,
        onChange,
        onKeyPress,
        onEnterKeyPress,
        onFocus,
    }: TextInputProps,
    ref: ForwardedRef<TextInputRef>
) => {
    const initialValueRef = useRef<string | undefined>(valueProp || defaultValue)
    const inputRef = useRef<HTMLInputElement>(null)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [value, setValue] = useState<string>(valueProp || defaultValue)
    const [textareaHeight, setTextareaHeight] = useState<number | undefined>()

    const formatInputValue = () => {
        if (value.length > 0 && format) {
            setValue(formatText(value, format))
        }
    }

    const onInputBlur = () => {
        formatInputValue()
        onBlur?.()
    }

    const onInputChange = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        setValue(e.currentTarget.value)
    }

    const onInputFocus = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        onFocus?.()

        if (selectOnFocus) {
            e.currentTarget.select()
        }
    }

    const onInputKeyDown = (e: SyntheticEvent<HTMLElement, KeyboardEvent>) => {
        onKeyPress?.(e.nativeEvent.key, e.nativeEvent.shiftKey)

        if (e.nativeEvent.key !== 'Enter') {
            return
        }

        if (multiline && !!value) {
            if (!onEnterKeyPress || e.nativeEvent.shiftKey) {
                return
            }
        }

        e.preventDefault()
        formatInputValue()
        onEnterKeyPress?.()
    }

    const inputProps = {
        autoComplete,
        autoCorrect: autoCorrect ? 'on' : 'off',
        maxLength,
        placeholder,
        readOnly,
        tabIndex: disabled ? -1 : tabIndex,
        spellCheck,
        value,
        'data-1p-ignore': forceDisable1Password ? true : undefined,
        onBlur: onInputBlur,
        onChange: onInputChange,
        onFocus: onInputFocus,
        onKeyDown: onInputKeyDown,
    }

    useImperativeHandle(ref, () => ({
        focus() {
            if (multiline) {
                textareaRef.current?.focus()
            } else {
                inputRef.current?.focus()
            }
        },
    }))

    useDebounce(
        () => {
            if (value !== initialValueRef.current) {
                initialValueRef.current = undefined

                if (format) {
                    onChange?.(formatText(value, format))
                } else {
                    onChange?.(value)
                }
            }
        },
        delay,
        [value]
    )

    useEffect(() => {
        if (multiline && textareaRef.current) {
            const height = heightFromControlSize(size)
            textareaRef.current.style.height = '0px'
            setTextareaHeight(Math.max(height, textareaRef.current.scrollHeight))
            textareaRef.current.style.height = ''
        }
    }, [size, value])

    useEffect(() => {
        if (typeof valueProp === 'string' && valueProp !== value) {
            setValue(valueProp)
        }
    }, [valueProp])

    useEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus()
            textareaRef.current?.focus()
        }
    }, [autoFocus])

    return (
        <Wrapper
            $color={color}
            $disabled={disabled}
            $size={size}
            $textColor={textColor}
            className={className}
            style={{ ...style, height: textareaHeight }}
        >
            {preview && (
                <Preview color={color} size={size}>
                    {preview}
                </Preview>
            )}
            {multiline ? <Textarea {...inputProps} ref={textareaRef} /> : <Input {...inputProps} ref={inputRef} />}
            {children}
        </Wrapper>
    )
}

export default forwardRef(TextInput)
