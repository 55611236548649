import { useContext, useEffect, useState, ReactNode } from 'react'
import RefreshDataContext, { RefreshChannelOptions } from 'shared/contexts/refresh-data'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { IChannel } from 'shared/types/channel'
import { Container } from './sc'
import { LoaderListItem } from './channel-list-item'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { CollectionStyleContext } from '../context/collection-style-context'
import SortableChannel from './dnd/sortable-channel'

type ChannelListProps = {
    channel: IChannel
    isRoot: boolean
    addButton?: ReactNode
    onChannelClick: (collectionId: number) => void
    showTooltip?: boolean
}

const ChannelList = ({ channel, addButton, onChannelClick, isRoot, showTooltip }: ChannelListProps) => {
    const { getChannelChildren, channels, setActiveChannelColor } = useContext(CollectionContext)
    const { onRefreshChannel } = useContext(RefreshDataContext)
    const { channelColors } = useContext(CollectionStyleContext)
    const [isLoading, setLoading] = useState(true)
    const [children, setChildren] = useState<IChannel[]>(channel.children || [])
    const fetchChildren = async (channel) => {
        const channelListChildren = await getChannelChildren(channel)
        setChildren(channelListChildren)
    }

    useAsyncEffect(async () => {
        if (channel.id !== 0) {
            setLoading(true)
            try {
                await fetchChildren(channel)
            } catch (error) {
                console.error(`Error fetching collection cabinet channel ${channel.id}`)
            }
        }
        setLoading(false)
        isRoot && setActiveChannelColor(undefined)
    }, [channel.id])

    useEffect(() => {
        const off = onRefreshChannel((updatedChannel, options: RefreshChannelOptions) => {
            if (channel.id === updatedChannel.id) {
                if (updatedChannel.has_children) {
                    if (options.isReordering) {
                        setChildren(updatedChannel.children)
                    } else {
                        fetchChildren(updatedChannel)
                    }
                } else {
                    setChildren([])
                }
            }
        })
        return off
    }, [channel.id])

    if (isLoading) {
        return <LoaderListItem />
    }

    return (
        <>
            {channel.has_children ? (
                <Container>
                    {addButton && addButton}
                    {children.map((channel, index) =>
                        channels[channel.id] ? (
                            <SortableChannel
                                id={`${channel.id}`}
                                key={`${channel.id}`}
                                index={index}
                                channel={channel}
                                color={channelColors[index]}
                                onChannelClick={onChannelClick}
                                isRoot={isRoot}
                                showTooltip={showTooltip}
                            />
                        ) : null
                    )}
                </Container>
            ) : (
                <Container>{addButton && addButton}</Container>
            )}
        </>
    )
}

export default ChannelList
