import appendQuery from 'append-query'
import cookie from 'cookie'

const getPostHeaders = () => {
    const { csrftoken } = cookie.parse(document.cookie)
    return {
        Accept: 'application/json',
        'X-CSRFToken': csrftoken,
        'X-Requested-With': 'XMLHttpRequest',
    }
}

const toDataString = (json) => appendQuery('', json).substr(1)

export const GET = {
    method: 'get',
    headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
} as RequestInit

export const USER_DELETE = (body: any, userToken: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Token ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    }
    return {
        method: 'delete',
        body: toDataString(body),
        headers,
        credentials: 'same-origin',
    } as RequestInit
}

export const USER_POST = (body: any, userToken: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Token ${userToken}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    }
    return {
        method: 'post',
        body: toDataString(body),
        headers,
        credentials: 'same-origin',
    } as RequestInit
}

export const POST = (body?: any, isJSON?: boolean) => {
    const headers = getPostHeaders()
    if (isJSON) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
    }
    return {
        method: 'post',
        body: isJSON ? toDataString(body) : body,
        headers,
        credentials: 'same-origin',
    } as RequestInit
}
export const PATCH = (body?: any, isJSON?: boolean) => {
    const headers = getPostHeaders()
    if (isJSON) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
    }
    return {
        method: 'PATCH',
        body: isJSON ? toDataString(body) : body,
        headers,
        credentials: 'same-origin',
    } as RequestInit
}
export const DELETE = (body?: any, isJSON?: boolean) => {
    const headers = getPostHeaders()
    if (isJSON) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
    }
    return {
        method: 'delete',
        body: isJSON ? toDataString(body) : body,
        headers,
        credentials: 'same-origin',
    } as RequestInit
}
export const PUT = (body?: any, isJSON?: boolean) => {
    const headers = getPostHeaders()
    if (isJSON) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
    }
    return {
        method: 'put',
        body: isJSON ? toDataString(body) : body,
        headers,
        credentials: 'same-origin',
    } as RequestInit
}
export const OPTIONS = {
    method: 'options',
    headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    credentials: 'same-origin',
} as RequestInit
