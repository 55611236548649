import * as colors from '@giphy/colors'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { trackGA4NavigationEvent } from './navigation-ga4'

const Container = styled.div`
    padding-top: 5px;
    ${mobile.css`
        padding: 0;
        margin-bottom: 6px;
        a {
            margin-bottom: 0;
        }
    `}
`

const FollowLabel = styled.p`
    color: ${colors.giphyLightGrey};
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 10px;
    -webkit-font-smoothing: antialiased;
`

const SocialButton = styled.a<{ name: string }>`
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 5px;
    padding: 7px;
    background-image: url('/static/img/soc-icons/${(props) => props.name}@3x.png');
    background-size: 100%;

    &:last-child {
        margin-right: 0px;
    }
`

type SocialLink = {
    type: string
    url: string | undefined
}

type Props = {
    links: SocialLink[]
    isCompact?: boolean | undefined
}

const Social = ({ links, isCompact }: Props) => {
    return (
        <Container>
            {!isCompact && <FollowLabel>Follow on:</FollowLabel>}
            {links.map(({ type, url }) => (
                <SocialButton
                    onClick={() => trackGA4NavigationEvent(url, type)}
                    key={type}
                    rel="noopener noreferrer"
                    href={url}
                    target="_blank"
                    name={type}
                />
            ))}
        </Container>
    )
}

export default Social
