import * as colors from '@giphy/colors'
import { Link } from 'react-router-dom'
import Checkbox from 'shared/components/checkbox'
import { Title as BrandTitle } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled, { css } from 'styled-components'

export const Copy = styled.div`
    font-size: ${(props) => (props.large ? '16px' : '13px')};
    ${mobile.css`
    font-size: 14px;
    `}
    color: ${(props) => (props.grey ? colors.giphyLightGrey : 'white')};
    ${(props) =>
        props.regular &&
        css`
            font-weight: normal;
        `};
    a {
        color: ${colors.giphyBlue};
        :hover {
            color: ${colors.giphyWhite};
        }
    }
`

const Terms = styled(Copy)`
    text-align: center;
    font-family: 'interface';
    font-weight: bold;
    font-size: 13px;

    ${mobile.css`
        font-size: 13px;
        line-height: 15px;
    `};
    margin: 10px 0;
`

const TermsContainer = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    ${mobile.css`
        align-items: flex-start;
        margin-bottom: 0px;
    `}
    p {
        font-size: 16px;
        text-align: left;
        margin: auto;
        margin-left: 20px;

        ${mobile.css`
            font-size: 14px;
            margin-left: 15px;
        `}

        a {
            color: ${colors.giphyBlue};
            :hover {
                color: ${colors.giphyWhite};
            }
        }
    }
`

export const TermsCheckbox = styled(Checkbox)`
    width: 45px; // NOTE: The width here appears to be 30px. //Cant figure out why it is wonky.
    height: 30px;
    border: 4px solid #212121;
    background-color: ${colors.giphyBlack} ${mobile.css`
        width: 74px;
        margin-top: 5px;
    `};
`

const Centered = styled(Copy)`
    text-align: center;
    margin: 20px 0;
`

export const DesktopTitle = styled(BrandTitle)`
    font-size: 38px;
    text-align: center;
    ${mobile.css`
        display: none;
    `};
`

export const ApplyTitle = () => <DesktopTitle>Upgrade Your Account!</DesktopTitle>

export const TermsOfService = ({ className = '' }) => (
    <Terms className={className}>
        I agree to the <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>
    </Terms>
)

export const TermsOfServiceApply = ({ className, isChecked, onChange }) => (
    <TermsContainer>
        <TermsCheckbox isChecked={isChecked} onChange={onChange} />
        <p className={className}>
            By checking this box you confirm that all of the work on your GIPHY Channel belongs to you, and you agree to
            our{' '}
            <a
                href="https://support.giphy.com/hc/en-us/articles/360020286811-GIPHY-Community-Guidelines"
                target="_blank"
                rel="noopener noreferrer"
            >
                Community Guidelines
            </a>{' '}
            and
            <a href="/terms" target="_blank" rel="noopener noreferrer">
                {' '}
                Terms of Service
            </a>
            .
        </p>
    </TermsContainer>
)

export const TermsOfServiceLogin = () => (
    <Terms>
        By logging in you agree to GIPHY&apos;s <a href="/terms">Terms of Service</a> and{' '}
        <a href="https://support.giphy.com/hc/en-us/articles/360032872931">Privacy Policy</a>
    </Terms>
)

export const LearnMore = ({ className }) => (
    <Centered className={className}>
        Not sure where you fit? Learn more about Artists & Brands on GIPHY{' '}
        <a
            href="https://support.giphy.com/hc/en-us/sections/360003006231-GIPHY-For-Brands-and-Artists-"
            target="_blank"
            rel="noopener noreferrer"
        >
            here
        </a>
        .
    </Centered>
)

export const ArtistOrBrand = ({ className }) => (
    <div className={className}>
        <Copy style={{ marginBottom: 5 }}>Are you applying as a brand or artist?</Copy>
        <Copy regular style={{ marginBottom: 15 }}>
            Learn more about{' '}
            <a
                href="https://support.giphy.com/hc/en-us/articles/360019977992-Apply-for-a-Brand-Channel"
                target="_blank"
                rel="noopener noreferrer"
            >
                Brands
            </a>{' '}
            and{' '}
            <Link to="/artists" target="_blank">
                Artists
            </Link>{' '}
            on GIPHY.
        </Copy>
    </div>
)

export const NeedHelp = ({ className }) => (
    <Copy className={className} large>
        <Link to="/support" target="_blank">
            Need Help?
        </Link>
    </Copy>
)

export const ApplicationSubmitWarning = () => (
    <Copy regular grey large>
        Please note: the information provided above will update your profile upon submission.
    </Copy>
)
