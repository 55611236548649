import { giphyDarkCharcoal, giphyDarkestGrey, giphyLightCharcoal, giphyWhite } from '@giphy/colors'
import { Gif as Gif_ } from '@giphy/react-components'
import styled, { css } from 'styled-components'

// need this so long names truncate
const containerWidth = 164

export const MenuContainer = styled.div<{ hasChannelAccounts: boolean }>`
    display: none;
    position: absolute;
    right: 0;
    z-index: 100;
    padding-top: 10px;

    i {
        position: absolute;
        right: 20px;
        top: -3px;
        transform: rotate(180deg);
        color: ${giphyDarkestGrey};
    }

    ${(props) =>
        !!props.hasChannelAccounts
            ? css`
                  width: calc(100% + 26px);
                  left: -13px;
              `
            : css`
                  width: 100%;
                  left: 0;
              `}
`

export const NetworkChannelsContent = styled.div`
    height: 245px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }
`

export const NetworkChannelsContainer = styled.div`
    height: 245px;
    position: relative;

    &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 38px;
        background: linear-gradient(360deg, #212121 0%, rgba(33, 33, 33, 0) 100%);
    }
`

export const ChannelAccessAccountsNumber = styled.div`
    background: ${giphyDarkCharcoal};
    border-radius: 2px;
    width: 20px;
    height: 20px;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
`

export const Menu = styled.ul`
    border-radius: 2px;
    overflow: hidden;
    background-color: ${giphyDarkestGrey};
    padding: 10px 0;

    li {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 5px 15px;
        text-align: justify;
        transition: opacity 0.2s ease-in-out;
        opacity: 0.75;

        &:hover {
            opacity: 1;
        }

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        a {
            display: flex;
            align-items: center;
            background-color: transparent;
            float: none;
            margin: 0;
            font-weight: 400;
            cursor: pointer;
            transition: background-color 0.1s ease-in-out;
            font-size: 15px;
            line-height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 145px;
            white-space: nowrap;

            span {
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        svg {
            position: absolute;
            right: 10px;
            height: 16px;
            width: 16px;
            margin-top: 2px;
        }
    }
`

export const Container = styled.div<{ isSupercut: boolean }>`
    ${({ isSupercut }) =>
        !isSupercut &&
        css`
            margin: 11px 0 0;
        `}
    float: right;
    color: ${giphyWhite};
    font-weight: bold;
    position: relative;
    width: ${containerWidth}px;

    &:hover ${MenuContainer} {
        display: block;
    }
`

export const CaretDown = () => (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5 2.75329C15.5 2.81918 15.4707 2.88507 15.4122 2.95095L8.2306 9.42093C8.16715 9.47364 8.09151 9.5 8.00366 9.5C7.91581 9.5 7.84017 9.47364 7.77672 9.42093L0.595168 2.95095C0.531722 2.89824 0.5 2.83126 0.5 2.75C0.5 2.66874 0.531722 2.60176 0.595168 2.54905L2.78404 0.579063C2.84261 0.526354 2.91703 0.499999 3.00732 0.499999C3.09761 0.499999 3.17204 0.526354 3.2306 0.579063L8.00366 4.87482L12.7767 0.579063C12.8353 0.526354 12.9097 0.5 13 0.5C13.0903 0.5 13.1647 0.526354 13.2233 0.579063L15.4122 2.54905C15.4707 2.61493 15.5 2.68302 15.5 2.75329Z"
            fill="white"
        />
    </svg>
)

export const CaretUp = () => (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 7.24671C0.5 7.18082 0.529282 7.11493 0.587848 7.04905L7.7694 0.579064C7.83285 0.526355 7.90849 0.5 7.99634 0.5C8.08419 0.5 8.15983 0.526355 8.22328 0.579064L15.4048 7.04905C15.4683 7.10176 15.5 7.16874 15.5 7.25C15.5 7.33126 15.4683 7.39824 15.4048 7.45095L13.216 9.42094C13.1574 9.47365 13.083 9.5 12.9927 9.5C12.9024 9.5 12.828 9.47365 12.7694 9.42094L7.99634 5.12518L3.22328 9.42094C3.16471 9.47365 3.09029 9.5 3 9.5C2.90971 9.5 2.83529 9.47365 2.77672 9.42094L0.587848 7.45095C0.529282 7.38507 0.5 7.31698 0.5 7.24671Z"
            fill="white"
        />
    </svg>
)

export const CheckMarkIcon = () => (
    <svg viewBox="0 0 24 24">
        <defs>
            <linearGradient id="a" x1="100%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#0F9" />
                <stop offset="100%" stopColor="#0CF" />
            </linearGradient>
        </defs>
        <path
            fill="url(#a)"
            fillRule="evenodd"
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm5.245 6a.257.257 0 00-.201.089l-5.98 6.732-2.608-2.944a.257.257 0 00-.2-.09.257.257 0 00-.201.09l-1.97 2.212a.312.312 0 00-.085.225c0 .092.029.167.086.226l4.774 5.371c.075.06.143.089.204.089.062 0 .13-.03.204-.089l8.146-9.159a.312.312 0 00.086-.225.312.312 0 00-.086-.226l-1.969-2.212a.257.257 0 00-.2-.089z"
        />
    </svg>
)

export const AccessUserIcon = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5 13.6146C14.5 10.6609 9.18748 11.2558 9.18748 8.89005C9.18748 8.89005 10.9583 7.11922 10.9583 3.87499C10.9583 1.92431 9.36733 0.333328 7.41665 0.333328C5.46596 0.333328 3.87498 1.92431 3.87498 3.87499C3.87498 7.11922 5.64581 8.89005 5.64581 8.89005C5.64581 11.2558 0.333313 10.6609 0.333313 13.6146V14.2026C0.333313 14.3686 0.464742 14.5 0.630758 14.5H14.2025C14.3686 14.5 14.5 14.3686 14.5 14.2026V13.6146Z"
            fill="#D8D8D8"
        />
    </svg>
)

export const Divider = styled.div`
    background-color: ${giphyLightCharcoal};
    height: 1px;
    margin: 5px 0;
    opacity: 0.33;
    transform: matrix(1, 0, 0, -1, 0, 0);
`

export const Username = styled.div`
    height: 36px;
    display: inline-block;
    line-height: 36px;
    min-width: 100px;
    text-transform: none;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 15px;
    user-select: none;
    flex: 1;
    padding-left: 10px;
`

export const UserContainer = styled.div`
    display: flex;
    background: ${giphyDarkCharcoal};
    border-radius: 2px;
    cursor: pointer;
`

export const Caret = styled.div`
    color: ${giphyWhite};
    font-size: 12px;
    height: 36px;
    line-height: 41px;
    overflow: hidden;
    padding: 0;
    text-align: center;
    width: 40px;
`

export const Gif = styled(Gif_)`
    img {
        object-fit: cover;
    }
`

export const LoginOnly = styled(UserContainer)`
    ${Username} {
        margin-right: 10px;
        padding-left: 0;
    }
`

export const UserIcon = styled.div`
    display: flex;
    height: 36px;
    width: 36px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.1);
    .ss-user {
        height: 14px;
    }
`
