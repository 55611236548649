import styled from 'styled-components'

type SVGArrowProps = {
    rotate: number
}
const SVGArrow = styled.svg<SVGArrowProps>`
    transform: rotate(${({ rotate }) => rotate}deg);
`

type ArrowProps = {
    rotate?: number
    color?: string
    height?: number
}
const Arrow = ({ rotate = 0, color = '#FFFFFF', height = 10 }: ArrowProps) => (
    <SVGArrow
        rotate={rotate}
        width="14px"
        height={`${height}px`}
        viewBox="0 0 14 10"
        version="1.1"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Landing-Page-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="mobile-header" transform="translate(-181.000000, -695.000000)">
                <g
                    id="icons/utility/next"
                    transform="translate(188.000000, 699.000000) rotate(-270.000000) translate(-188.000000, -699.000000) translate(173.000000, 684.000000)"
                >
                    <mask id="mask-2" fill="white">
                        <path d="M13.8506611,8.07692308 C13.9182696,8.07692308 13.9858771,8.10396608 14.0534856,8.15805288 L20.6926082,14.7904147 C20.746695,14.8490087 20.773738,14.9188698 20.773738,15 C20.773738,15.0811302 20.746695,15.1509913 20.6926082,15.2095853 L14.0534856,21.8419471 C13.9993988,21.9005412 13.9306645,21.9298377 13.8472806,21.9298377 C13.7638968,21.9298377 13.6951625,21.9005412 13.6410757,21.8419471 L11.6195913,19.8204627 C11.5655045,19.7663759 11.5384615,19.6976416 11.5384615,19.6142578 C11.5384615,19.530874 11.5655045,19.4621397 11.6195913,19.4080529 L16.0276442,15 L11.6195913,10.5919471 C11.5655045,10.5378603 11.5384615,10.469126 11.5384615,10.3857422 C11.5384615,10.3023584 11.5655045,10.2336241 11.6195913,10.1795373 L13.6410757,8.15805288 C13.7086842,8.10396608 13.7785453,8.07692308 13.8506611,8.07692308 Z" />
                    </mask>
                    <path
                        d="M13.8506611,8.07692308 C13.9182696,8.07692308 13.9858771,8.10396608 14.0534856,8.15805288 L20.6926082,14.7904147 C20.746695,14.8490087 20.773738,14.9188698 20.773738,15 C20.773738,15.0811302 20.746695,15.1509913 20.6926082,15.2095853 L14.0534856,21.8419471 C13.9993988,21.9005412 13.9306645,21.9298377 13.8472806,21.9298377 C13.7638968,21.9298377 13.6951625,21.9005412 13.6410757,21.8419471 L11.6195913,19.8204627 C11.5655045,19.7663759 11.5384615,19.6976416 11.5384615,19.6142578 C11.5384615,19.530874 11.5655045,19.4621397 11.6195913,19.4080529 L16.0276442,15 L11.6195913,10.5919471 C11.5655045,10.5378603 11.5384615,10.469126 11.5384615,10.3857422 C11.5384615,10.3023584 11.5655045,10.2336241 11.6195913,10.1795373 L13.6410757,8.15805288 C13.7086842,8.10396608 13.7785453,8.07692308 13.8506611,8.07692308 Z"
                        fill={color}
                        fillRule="evenodd"
                    />
                    <g id="colors/primary/_master" mask="url(#mask-2)">
                        <g id="colors/primary/white" />
                    </g>
                </g>
            </g>
        </g>
    </SVGArrow>
)

export default Arrow
