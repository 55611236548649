import { IGif } from '@giphy/js-types'
import { createContext } from 'react'
import { IChannel } from 'shared/types/channel'
import { NewCollectionProps } from 'shared/components/collections/modal'
import { EditPanelSections } from 'shared/components/edit-panel/gif-detail'
import { SetCollectionsProps } from './modal-manager'

const noop = (...args) => console.log(...args)

type ModalContextProps = {
    openGifEditPanel: (gif: IGif, section?: EditPanelSections) => void
    openBulkEditPanel: (allowBulkEditMode: boolean) => void
    openNewCollectionPanel: (newCollectionProps: NewCollectionProps) => void
    openEditCollectionPanel: (channel: IChannel) => void
    openCollectionCabinet: (channelId?: number) => void
    openAddGifsToCollectionPanel: (channelId: number, searchChannelId: number) => void
    openMobileActionsPanel: (gif?: IGif) => void
    setCollectionCabinetProps: (props: SetCollectionsProps) => void
    isEditing: Boolean
}

const ModalContext = createContext({
    openCollectionCabinet: noop,
    openEditCollectionPanel: noop,
    openNewCollectionPanel: noop,
    openGifEditPanel: noop,
    openBulkEditPanel: noop,
    openAddGifsToCollectionPanel: noop,
    openMobileActionsPanel: noop,
    setCollectionCabinetProps: noop,
} as ModalContextProps)

export default ModalContext
