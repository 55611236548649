import styled from 'styled-components'
import { Label, Optional } from './sc'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const OptionalLabel = ({ label, children }: { label: string; children?: any }) => (
    <Container>
        <Label>{label}</Label>
        {children}
        <Optional>Optional</Optional>
    </Container>
)

export default OptionalLabel
