import styled from 'styled-components'

export const titleFont = "'nexablack', sans-serif"
export const smallTitleSize = '24px'
export const titleSize = '28px'
export const largeTitleSize = '36px'
export const subheaderSize = '17px'
export const smallSubheaderSize = '13px'

export const Title = styled.div`
    font-family: ${titleFont};
    -webkit-font-smoothing: antialiased;
    font-size: ${titleSize};
`
export const TitleLarge = styled(Title)`
    font-size: ${largeTitleSize};
`
export const TitleSmall = styled.div`
    font-size: ${smallTitleSize};
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
`
export const Subheader = styled.div`
    font-size: ${subheaderSize};
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
`
export const SubheaderSmall = styled(Subheader)`
    font-size: ${smallSubheaderSize};
`
export const SectionHeader = styled.div`
    font-size: 15px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
`
