import { giphyRed } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { GifOverlayProps } from '@giphy/react-components'
import { isSafari } from 'mobile/shared/environment'
import { useContext, useState } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { checkIsFavorite } from 'shared/api/favorites'
import Lock from 'shared/components/icons/lock'
import Test from 'shared/components/test'
import MessageContext from 'shared/contexts/message'
import ModalContext from 'shared/contexts/modal'
import UserContext from 'shared/contexts/user'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { getShareUrl } from 'shared/util/gif-extract'
import { removeAllQueryParams } from 'shared/util/url-utils'
import styled, { css } from 'styled-components'
import { Clipboard } from 'ts-clipboard'
import Favorite from './favorite/favorite'

export const LockOverlay = ({ gif, isHovered }: GifOverlayProps) =>
    !isHovered && gif?.is_hidden ? (
        <LockContainer>
            <Lock />
        </LockContainer>
    ) : null

export const FavoriteOverlay = ({ gif, isHovered }: GifOverlayProps) => {
    const [isFavorite, setIsFavorite] = useState<boolean>()
    const { removeFavorite, user, isLoggedIn } = useContext(UserContext)
    const [localFavorites] = useLocalStorage<(string | number)[]>('local-favorites', [])
    useAsyncEffect(async () => {
        if (isHovered) {
            let result: boolean
            if (isLoggedIn) {
                result = await checkIsFavorite(gif.id, user.userToken)
            } else {
                result = !(localFavorites?.indexOf(gif.id) === -1)
            }
            setIsFavorite(result)
        }
    }, [gif, isHovered])

    if (!isHovered) {
        return null
    }

    return (
        <Favorite
            id={gif.id}
            url={gif.url}
            isFavorite={!!isFavorite}
            onClick={(props) => {
                if (props.is_favorite) {
                    removeFavorite(props.id)
                }
            }}
        />
    )
}

const GifMenuOverlay = ({ gif, isHovered }) => {
    return (
        <>
            <LockOverlay gif={gif} isHovered={isHovered} />
            <Overlay isHovered={isHovered}>
                <EditButton gif={gif} />
                <ClipboardButton gif={gif} />
                <FavoriteOverlay isHovered={isHovered} gif={gif} />
            </Overlay>
        </>
    )
}

const HoverButton = styled.div`
    &:hover {
        transform: scale(1.2);
        transition: transform 200ms ease-out;
    }
`

const Edit = styled(HoverButton)`
    margin: 10px 18px 0 0;
`
const EditButton = ({ gif }) => {
    const { user } = useContext(UserContext)
    const { openGifEditPanel } = useContext(ModalContext)
    return user.isStaff ? (
        <Test id="gif-overlay__edit-button">
            <div>
                <Edit
                    className="ss-write"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        openGifEditPanel(gif)
                    }}
                />
            </div>
        </Test>
    ) : null
}

type LinkProps = {
    size?: number
}

const Link = styled(HoverButton)<LinkProps>`
    margin: 10px 10px 0 0;
    ${(props) =>
        props.size &&
        css`
            font-size: ${props.size}px;
        `}
`

type Props = {
    gif: IGif
    isClip?: boolean
    copyLink?: () => void
} & LinkProps

export const ClipboardButton = ({ gif, isClip = false, copyLink = () => {}, size }: Props) => {
    const { sendMessage } = useContext(MessageContext)
    const {
        url,
        images: {
            original: { url: mediaUrl },
        },
    } = gif
    const clipboardUrl = !isClip ? getShareUrl(mediaUrl) : url

    return (
        <Link
            className="ss-link"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                try {
                    isSafari()
                        ? navigator.clipboard.writeText(clipboardUrl)
                        : Clipboard.copy(removeAllQueryParams(clipboardUrl))
                    sendMessage('Link copied to clipboard!', 'success')
                    if (isClip) {
                        copyLink()
                    }
                } catch (error) {
                    sendMessage('Error copying to clipboard :(', 'error')
                }
            }}
            size={size}
        />
    )
}

const Overlay = styled.div<{ isHovered: boolean }>`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    ${(props) =>
        !props.isHovered &&
        css`
            display: none;
        `}
`

export const LockContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: ${giphyRed};
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
`

export default GifMenuOverlay
