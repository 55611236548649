import { giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, fontSizeFromControlSize, heightFromControlSize } from 'utils'

export const Container = styled.button<{ $backgroundColor: string; $disabled: boolean; $size: ControlSize }>`
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    overflow: hidden;
    padding: 0;
    position: relative;
    user-select: none;
    width: 100%;

    &:active {
        opacity: 0.9;
    }

    &:focus-visible {
        outline: 2px solid ${giphyYellow};
    }

    ${({ $backgroundColor, $size }) => css`
        background: ${$backgroundColor};
        border-radius: ${borderRadiusFromControlSize($size, true)}px;
        height: ${heightFromControlSize($size)}px;
        font-size: ${fontSizeFromControlSize($size)}px;
    `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`

export const Highlight = styled.div`
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition:
        background 0.4s ease-out,
        transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width: 50%;
`

export const Label = styled.div`
    align-items: center;
    border-radius: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    padding: 0 1em;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    transition: color 0.2s ease-out;
    white-space: nowrap;
    width: 100%;
`
