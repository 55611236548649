import { useEffect, useState } from 'react'
import useWindowSize from 'shared/hooks/window-size'
import styled from 'styled-components'
import { TEAM_ANIMATION_HEIGHT, TEAM_GUTTER_WIDTH } from '../constants'
import { ITeamColumn } from '../types'
import Column from './animation-column'

const Container = styled.div`
    height: ${TEAM_ANIMATION_HEIGHT}px;
    overflow: hidden;
    margin-top: 2em;
    position: relative;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
`

type Props = {
    columns: ITeamColumn[]
}

const AboutPageTeamAnimation = ({ columns }: Props) => {
    const { innerWidth } = useWindowSize()
    const [scrollX, setScrollX] = useState<number>(0)
    const [totalWidth, setTotalWidth] = useState<number>(0)
    const [columnPositions, setColumnPositions] = useState<number[]>(columns.map((col) => col.x))

    useEffect(() => {
        const totalWidth: number =
            columns.map((col) => col.size).reduce((a, b) => a + b, 0) + TEAM_GUTTER_WIDTH * columns.length
        let animationId: number | null = null
        let wrapperX: number = 0
        const draw = () => {
            setScrollX(wrapperX)
            wrapperX--
            animationId = requestAnimationFrame(draw)
        }

        setTotalWidth(totalWidth)
        draw()

        return () => {
            animationId && cancelAnimationFrame(animationId)
        }
    }, [columns])

    useEffect(() => {
        const newPositions: number[] = columnPositions.map((x, i) => {
            if (x + scrollX + columns[i].size < 0) {
                return x + totalWidth
            }
            return x
        })
        setColumnPositions(newPositions)
    }, [scrollX, totalWidth])

    return (
        <Container>
            <Wrapper style={{ transform: `translate3d(${scrollX}px, 0, 0)` }}>
                {columns.map((column: ITeamColumn, i: number) => (
                    <Column
                        {...column}
                        key={`teamcolumn${i}`}
                        x={columnPositions[i]}
                        isVisible={column.x + scrollX < innerWidth}
                    />
                ))}
            </Wrapper>
        </Container>
    )
}

export default AboutPageTeamAnimation
