import { giphyBlack, giphyBlue, giphyGreen, giphyRed } from '@giphy/colors'
import { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

const STATUS_PADDING = 15
const STATUS_OFFSET = STATUS_PADDING * 2
const STATUS_DELAY = 3000

const pulse = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0.5; }
`

const Container = styled.div<{ isSaving: boolean }>`
    height: ${(props) => props.theme.headerHeight - STATUS_OFFSET}px;
    font-size: 14px;
    font-weight: bold;
    line-height: ${(props) => props.theme.headerHeight - STATUS_OFFSET}px;
    padding: 0 ${STATUS_PADDING}px;
    pointer-events: none;
    position: fixed;
    right: ${STATUS_PADDING}px;
    top: ${STATUS_PADDING}px;
    transition: opacity 0.2s ease-out;

    ${(props) =>
        props.isSaving &&
        css`
            animation: 1s ${pulse} linear infinite alternate;
        `}
`

export enum Status {
    None = 'NONE',
    Saving = 'SAVING',
    Error = 'ERROR',
    Success = 'SUCCESS',
}

type Props = {
    status: Status
}

const getMessage = (status: Status) => {
    switch (status) {
        case 'SAVING':
            return 'Saving...'
        case 'ERROR':
            return 'Error'
        case 'SUCCESS':
            return 'Saved'
        default:
            return ''
    }
}

const getStyle = (status: Status) => {
    switch (status) {
        case 'ERROR':
            return { background: giphyRed }
        case 'SUCCESS':
            return { background: giphyGreen, color: giphyBlack }
        default:
            return { background: giphyBlue }
    }
}

const EditStatusIndicator = ({ status }: Props) => {
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        if (status === 'NONE') {
            setOpacity(0)
            return
        }

        // fade out only if status is not SAVING
        const delay =
            status !== 'SAVING' &&
            setTimeout(() => {
                setOpacity(0)
            }, STATUS_DELAY)

        setOpacity(1)

        return () => {
            delay && clearTimeout(delay)
        }
    }, [status])

    return (
        <Container
            isSaving={status === 'SAVING'}
            style={{
                ...getStyle(status),
                opacity,
            }}
        >
            {getMessage(status)}
        </Container>
    )
}

export default EditStatusIndicator
