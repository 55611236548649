import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SelectDropdown from '../select-dropdown'
import Item from './item'
import css from './select.css'

const { arrayOf, bool, func, number, string } = PropTypes

export default class SelectList extends PureComponent {
    static propTypes = {
        options: arrayOf(string).isRequired,
        label: string,
        placeholder: string,
        title: string,
        width: number,
        height: string,
        allowMultiple: bool,
        // The default list of active items
        active: arrayOf(string),
        // Items to be rendered with a 'new' pill
        new: arrayOf(string),
        isDark: bool,
        onChange: func.isRequired,
        extendUp: bool,
        nipX: string,
        // allows only picking of one option that clears all others that might be active
        exclusivePick: bool,
        themeId: string,
    }

    static defaultProps = {
        active: [],
        new: [],
        placeholder: 'Options',
        allowMultiple: true,
        extendUp: false,
        exclusivePick: false,
        height: '30px',
    }

    state = {
        activeItems: this.props.active,
        newItems: this.props.new,
        // keeps the state of options to be un-selected when an option is hovered
        unwantedItems: [],
    }

    onItemClick(item) {
        const { allowMultiple, exclusivePick, onChange } = this.props
        const { activeItems } = this.state
        const index = activeItems.indexOf(item)
        let items = [...activeItems]

        if (exclusivePick) {
            items = [item]
        } else if (allowMultiple) {
            index > -1 ? items.splice(index, 1) : items.push(item)
        } else {
            items = index > -1 ? [] : [item]
        }

        this.setState({ activeItems: items })
        onChange(allowMultiple ? items : items[0] || null)
    }

    // this manages which items are currently selected but not hovered - so 'x' icon can be displayed
    onItemHover(item) {
        const { exclusivePick } = this.props
        const { activeItems } = this.state
        let items = [...activeItems]

        if (exclusivePick) {
            if (!item) {
                this.setState({ unwantedItems: [] })
            } else {
                let newUnwanted = items.filter((i) => i !== item)
                this.setState({ unwantedItems: newUnwanted })
            }
        }
    }

    render() {
        const {
            children,
            placeholder,
            options,
            label,
            title,
            width,
            themeId,
            extendUp,
            className,
            nipX,
            exclusivePick,
            height,
        } = this.props
        const { activeItems, newItems, unwantedItems } = this.state
        return (
            <SelectDropdown
                className={className}
                extendUp={extendUp}
                label={label || activeItems.join(', ')}
                nipX={nipX}
                placeholder={placeholder}
                themeId={themeId}
                width={width}
                height={height}
            >
                <div>
                    {title && <div className={css.listName}>{title}</div>}
                    {options.map((option, i) => (
                        <Item
                            isActive={activeItems.indexOf(option) > -1}
                            isDark={themeId === 'dark' || themeId === 'transparentDark'}
                            key={i}
                            label={option}
                            newPill={newItems.indexOf(option) > -1}
                            onClick={() => this.onItemClick(option)}
                            onHover={(label) => this.onItemHover(label)}
                            exclusivePick={exclusivePick}
                            showRemove={unwantedItems.indexOf(option) > -1}
                        />
                    ))}
                    {children}
                </div>
            </SelectDropdown>
        )
    }
}
