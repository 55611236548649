import { useDraggable } from '@dnd-kit/core'
import { useEffect, useState } from 'react'
import { Coordinates } from 'types'
import useModifierKeys from '../hooks/use-modifier-keys'
import { Icon, Scaler } from './style'
import { getRotatedCoordinates, getRotatedCursor } from './utils'

type Props = {
    aspectRatio?: number
    className?: string
    center: Coordinates
    color: string
    rotation?: number
    dndId: string
    onScale: (scale: Coordinates) => void
}

const DraggableScaler = ({ aspectRatio, center, className, color, dndId, rotation = 0, onScale }: Props) => {
    const [cursor, setCursor] = useState<string>('default')
    const { listeners, setNodeRef, transform } = useDraggable({
        id: dndId,
        data: { control: 'draggable', type: 'scale' },
    })
    const { shift: isShiftKeyPressed } = useModifierKeys()

    useEffect(() => {
        setCursor(getRotatedCursor(rotation))
    }, [rotation])

    useEffect(() => {
        if (!transform) return

        const { x, y } = transform
        const rotated = getRotatedCoordinates({ x, y }, rotation)
        const scaleX = rotated.x / center.x
        let scaleY = 1

        if (!aspectRatio || isShiftKeyPressed) {
            scaleY = rotated.y / center.y
        } else {
            scaleY = scaleX / aspectRatio
        }

        onScale({
            x: scaleX,
            y: scaleY,
        })
    }, [transform?.x, transform?.y])

    return (
        <Scaler {...listeners} className={className} ref={setNodeRef} style={{ cursor }}>
            <Icon color={color} />
        </Scaler>
    )
}

export default DraggableScaler
