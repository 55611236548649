import { FunctionComponent } from 'react'
import { IconProps } from '.'
import * as icons from '../../icons/creation'

export const creationIcons = [
    'align-center',
    'align-left',
    'align-right',
    'animated-text',
    'camera',
    'camera-roll',
    'caption',
    'crop',
    'eye-dropper',
    'fill',
    'fill-off',
    'filter',
    'flash',
    'flip',
    'flip-camera',
    'layers',
    'motion',
    'pin',
    'rotate',
    'scissors',
    'send-back',
    'send-forward',
    'stroke',
    'stroke-off',
    'trash',
] as const

type CreationIcon = typeof creationIcons[number]

export function creationIconComponent(icon: CreationIcon): FunctionComponent<IconProps> {
    switch (icon) {
        case 'align-center':
            return icons.AlignCenterIcon
        case 'align-left':
            return icons.AlignLeftIcon
        case 'align-right':
            return icons.AlignRightIcon
        case 'animated-text':
            return icons.AnimatedTextIcon
        case 'camera':
            return icons.CameraIcon
        case 'camera-roll':
            return icons.CameraRollIcon
        case 'caption':
            return icons.CaptionIcon
        case 'crop':
            return icons.CropIcon
        case 'eye-dropper':
            return icons.EyeDropperIcon
        case 'fill':
            return icons.FillIcon
        case 'fill-off':
            return icons.FillOffIcon
        case 'filter':
            return icons.FilterIcon
        case 'flash':
            return icons.FlashIcon
        case 'flip':
            return icons.FlipIcon
        case 'flip-camera':
            return icons.FlipCameraIcon
        case 'layers':
            return icons.LayersIcon
        case 'motion':
            return icons.MotionIcon
        case 'pin':
            return icons.PinIcon
        case 'rotate':
            return icons.RotateIcon
        case 'scissors':
            return icons.ScissorsIcon
        case 'send-back':
            return icons.SendBackIcon
        case 'send-forward':
            return icons.SendForwardIcon
        case 'stroke':
            return icons.StrokeIcon
        case 'stroke-off':
            return icons.StrokeOffIcon
        case 'trash':
            return icons.TrashIcon
    }
}

export default CreationIcon
