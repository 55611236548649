import styled from 'styled-components'
import { RotatorIcon } from '../../../icons/ui'

export const Rotator = styled.div`
    cursor: grab;
    height: 25px;
    transform-origin: 50% 100%;
    user-select: none;
    width: 18px;

    &:active {
        cursor: grabbing;
    }
`

export const Icon = styled(RotatorIcon)`
    display: block;
    height: 100%;
    width: 100%;
`
