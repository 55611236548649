import styled from 'styled-components'
import { Button as BrandButton } from 'shared/components/buttons'
import { getUrl } from 'shared/util/gif-extract'

const Button = styled(BrandButton).attrs({ as: 'div' })`
    position: relative;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        -webkit-touch-callout: default;
    }
`

const TapAndHoldButton = ({ gif, rendition, CTA, onShareHappen }) => {
    const onShare = () => {
        onShareHappen?.()
        const url = getUrl(gif, rendition)

        if (navigator.share) {
            navigator.share({
                title: gif.title,
                text: gif.title,
                url: url,
            })
        }
    }

    return (
        <Button primary onClick={onShare}>
            <span>{CTA}</span>
        </Button>
    )
}

export default TapAndHoldButton
