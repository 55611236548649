import { giphyIndigo } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, fontSizeFromControlSize, heightFromControlSize } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import PreviewComponent from './preview'

const baseStyle = css`
    background: inherit;
    border-radius: inherit;
    box-sizing: border-box;
    color: inherit;
    font-size: inherit;
    height: 100%;
    margin: 0;
    padding: 0 0.8em;
    width: 100%;

    &::placeholder {
        color: inherit;
        opacity: 0.5;
    }

    &::selection {
        background: ${giphyIndigo};
        color: #fff;
    }
`

export const Input = styled.input`
    ${baseStyle}
`

export const Textarea = styled.textarea`
    ${baseStyle}
    resize: none;
`

export const Preview = styled(PreviewComponent)`
    flex-shrink: 0;
`

export const Wrapper = styled.div<{
    $color: string
    $disabled: boolean
    $size: ControlSize
    $textColor?: string
}>`
    display: flex;
    flex-direction: row;

    ${({ $color, $size, $textColor }) => {
        const height = heightFromControlSize($size)
        const fontSize = fontSizeFromControlSize($size)
        const lineHeight = Math.round(fontSize * 1.3)
        const padding = Math.floor((height - lineHeight) / 2)

        return css`
            background: ${$color};
            border-radius: ${borderRadiusFromControlSize($size)}px;
            color: ${$textColor || textColorFromBackgroundColor($color)};
            font-size: ${fontSize}px;

            input {
                height: ${height}px;
            }

            textarea {
                line-height: ${lineHeight}px;
                min-height: ${height}px;
                padding-bottom: ${padding}px;
                padding-top: ${padding}px;
            }
        `
    }}

    &:has(input + button),
    &:has(textarea + button) {
        background: transparent;
    }

    input:has(+ button),
    textarea:has(+ button) {
        background: ${({ $color }) => $color};
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    ${Preview} + input,
    ${Preview} + textarea {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    input + button,
    textarea + button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        flex-shrink: 0;
    }

    textarea + button {
        height: 100%;
    }

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
        `}
`
