import * as React from 'react'
import { SetStateAction, useContext, useState } from 'react'
import { checkIsFavorite } from 'shared/api/favorites'
import Test from 'shared/components/test'
import UserContext from 'shared/contexts/user'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { useCanAddGifToCollection, useGifDetailPermissions } from 'shared/hooks/use-permissions'
import { CY_GIF_DETAIL__DOWNLOAD_BUTTON } from 'ui'
import GifDetailAddToCollection from './gif-detail-add-to-colllection'
import {
    Button,
    ButtonDiv,
    Container,
    EmbedIcon,
    FavoriteStyled,
    Icon,
    LeftEmbed,
    LeftIconContainer,
    LeftShare,
    ShareIcon,
} from './sc'
import { setGADataLayer, getContentOfGif, getCreatorOfGif } from 'analytics'
import { IVideo } from 'shared/types/video'
import { IGif } from '@giphy/js-types'

type Props = {
    gif: IVideo | IGif
    setOverlayType: React.Dispatch<SetStateAction<string | null>>
}

const GifDetailButtons = ({ gif: { id, url }, gif, setOverlayType }: Props) => {
    const [embedHover, setEmbedHover] = useState<boolean>(false)
    const [shareHover, setShareHover] = useState<boolean>(false)
    const [isFavorite, setIsFavorite] = useState<boolean>(false)
    const canAddToCollection = useCanAddGifToCollection(id)
    const permissions = useGifDetailPermissions(id)
    const { user, isLoggedIn } = useContext(UserContext)

    useAsyncEffect(async () => {
        if (isLoggedIn) {
            const isFavorite = await checkIsFavorite(gif.id, user.userToken)
            setIsFavorite(isFavorite)
        }
    }, [gif.id])

    return (
        <Container>
            <ButtonDiv>
                <Button>
                    <FavoriteStyled id={id} isFavorite={!!isFavorite} url={url} showLabel={true} />
                </Button>
                <Button
                    onClick={() => {
                        setGADataLayer({
                            event: 'share_open',
                            options: {
                                content: getContentOfGif(gif),
                                creator: getCreatorOfGif(gif),
                            },
                        })
                        setOverlayType('share')
                    }}
                    onMouseEnter={() => {
                        setShareHover(true)
                    }}
                    onMouseLeave={() => {
                        setShareHover(false)
                    }}
                >
                    <LeftShare>
                        <ShareIcon active={shareHover} />
                    </LeftShare>
                    <span>Share</span>
                </Button>
                <Button
                    onClick={() => {
                        setGADataLayer({
                            event: 'embed_open',
                            options: {
                                content: getContentOfGif(gif),
                                creator: getCreatorOfGif(gif),
                            },
                        })
                        setOverlayType('embed')
                    }}
                    onMouseEnter={() => {
                        setEmbedHover(true)
                    }}
                    onMouseLeave={() => {
                        setEmbedHover(false)
                    }}
                >
                    <LeftEmbed>
                        <EmbedIcon style={{ marginTop: `2px` }} active={embedHover} />
                    </LeftEmbed>
                    <span>Embed</span>
                </Button>
                {permissions?.can_download && (
                    <Test id={CY_GIF_DETAIL__DOWNLOAD_BUTTON}>
                        <Button onClick={() => setOverlayType('download')}>
                            <LeftIconContainer>
                                <Icon className={'ss-download'} style={{ paddingTop: `3px`, fontSize: `23px` }} />
                            </LeftIconContainer>
                            <span>Download</span>
                        </Button>
                    </Test>
                )}
                {canAddToCollection && (
                    <Button>
                        <GifDetailAddToCollection gif={gif} />
                    </Button>
                )}
            </ButtonDiv>
        </Container>
    )
}

export default GifDetailButtons
