import { ReactNode } from 'react'
import styled from 'styled-components'
import TestAttributes from 'react-test-attributes'

type TestProps = {
    children: ReactNode
    id: string
}

const StyledTestAttributes = styled(TestAttributes)<TestProps>``

const Test = (props: TestProps) => <StyledTestAttributes {...props} />

export default Test
