import styled, { css } from 'styled-components'
import { gifOverlayColor, giphyWhite } from '../../css/colors'
import AdPillSVG from './ad-pill'

export const LockContainer = styled.div`
    align-items: center;
    background: ${gifOverlayColor};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease-out;
    width: 100%;
`

export const GifWrapper = styled.div<{ isSticker: boolean }>(
    ({ isSticker }) => css`
        display: block;
        overflow: hidden;
        position: relative;
        text-align: left;

        &:hover ${LockContainer} {
            opacity: 0;
        }
        ${isSticker &&
        css`
            background-image: linear-gradient(45deg, #222 25%, transparent 25%, transparent 75%, #222 75%, #222 100%),
                linear-gradient(45deg, #222 25%, transparent 25%, transparent 75%, #222 75%, #222 100%);
            background-position: 0 0, 19px 19px;
            background-size: 38px 38px;
        `}
    `
)

export const GifLink = styled.a`
    display: block;
    height: 100%;
    width: 100%;
`
type ToggleVisible = { isVisible: boolean }
export const AdPill = styled(AdPillSVG)<ToggleVisible>(
    ({ isVisible }) => css`
        position: absolute;
        bottom: 5px;
        left: 5px;
        opacity: ${isVisible ? 1 : 0};
        transition: opacity 0.33s ease-in-out;
    `
)

export const Tags = styled.div<ToggleVisible>(
    ({ isVisible }) => css`
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        overflow: hidden;
        transform: translateY(${isVisible ? 0 : '100%'});
        transition: transform 0.2s ease-out;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
        padding: 0 5px;
        width: 100%;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    `
)

export const Tag = styled.a`
    color: ${giphyWhite};
    display: inline-block;
    font-size: inherit;
    font-weight: bold;
    margin-right: 5px;
    text-decoration: none;
    text-transform: lowercase;

    &:hover {
        color: ${giphyWhite};
    }

    &:before {
        content: '#';
    }
`
