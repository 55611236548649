import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import css from './item.css'

export default class SelectListItem extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        isDark: PropTypes.bool,
        onClick: PropTypes.func,
        newPill: PropTypes.bool,
        // exclusive pick mode means multiple can be active, but you can only pick one
        exclusivePick: PropTypes.bool,
        showRemove: PropTypes.bool,
        onHover: PropTypes.func,
    }

    state = {
        isHovered: false,
    }

    onMouseOver() {
        this.setState({ isHovered: true })
    }

    onMouseOut() {
        this.setState({ isHovered: false })
    }

    getActiveIcon(isActive, exclusivePick) {
        if (isActive) {
            if (exclusivePick) {
                return css.addIcon
            }
            return css.removeIcon
        }
        return css.addIcon
    }

    getCurrentIcon(isActive, exclusivePick, showRemove, isHovered) {
        const activeIcon = this.getActiveIcon(isActive, exclusivePick)
        // if showRemove then another item is hovered so the removeIcon is shown
        if (showRemove) {
            return css.removeIcon
        }
        if (isHovered) {
            return activeIcon
        }
        if (isActive) {
            return css.checkIcon
        }
        return css.icon
    }
    render() {
        const { children, label, isActive, isDark, onClick, newPill, exclusivePick, showRemove, onHover } = this.props
        const { isHovered } = this.state
        const iconClass = this.getCurrentIcon(isActive, exclusivePick, showRemove, isHovered)
        return (
            <div
                className={isActive ? css.itemActive : css.item}
                data-giphyisdark={isDark}
                onClick={() => onClick && onClick(label)}
                onMouseOut={() => {
                    this.onMouseOut()
                    onHover(undefined)
                }}
                onMouseOver={() => {
                    this.onMouseOver()
                    onHover(label)
                }}
            >
                {label}
                {newPill && <span className={css.newPill}>NEW</span>}
                {children || <i className={iconClass} data-giphyisdark={isDark} />}
            </div>
        )
    }
}
