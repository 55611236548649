import { IUser } from '@giphy/js-types'
import styled from 'styled-components'
import { convertToImagingService } from '../../util/url'

const Img = styled.img`
    object-fit: cover;
    width: 36px;
    height: 36px;
    border-radius: 2px;
`

type Props = { user: IUser; className?: string }
const Avatar = ({ user, className = '' }: Props) => {
    const url = user.avatar_url
        ? convertToImagingService(user.avatar_url, '80h')
        : `https://media.giphy.com/avatars/default0.gif`
    return <Img src={url} className={className} />
}

export default Avatar
