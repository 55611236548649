import { lazy } from 'react'
import AboutPage from '../../shared/components/about-page'
import routes from '../../shared/routes'
import TransparencyReport from '../../site/transparency-reports'
import AppDetailPage from '../pages/apps/app-detail'
import AppsPage from '../pages/apps/apps'
import ArSceneLandingPage from '../pages/ar-scene-landing-page'
import ArtistsPage from '../pages/artists'
import EditGifPage from '../pages/edit-gif'
import GifMakerPage from '../pages/gif-maker'
import PostPage from '../pages/post'
import { FiveHundred, FourTwoThree, StaticPage, StaticPageNoHeader } from '../pages/static'
import StoryPage from '../pages/story'
import StudiosPage from '../pages/studios'
import SupportPage from '../pages/support'

// const FavoritesPage = lazy(() => import(/* webpackChunkName: "mobileFavoritesPage" */ '../pages/user/favorites'))

const ExploreCategoryPage = lazy(() =>
    import(/* webpackChunkName: "mobileExploreCategoryPage" */ '../pages/explore-category')
)
const ApplyPage = lazy(() => import(/* webpackChunkName: "mobileApplyPage" */ '../pages/user/apply'))
const SettingsPage = lazy(() => import(/* webpackChunkName: "mobileSettingsPage" */ '../pages/user/settings'))
const TakedownAppealPage = lazy(() =>
    import(/* webpackChunkName: "mobileLoginPage" */ '../../site/user/takedown-appeal')
)

const TeamPage = lazy(() => import(/* webpackChunkName: "mobileTeamPage" */ '../pages/team'))
const DashboardPage = lazy(() => import(/* webpackChunkName: "mobileDashboard" */ '../pages/user/dashboard'))
const FourZeroFourPage = lazy(() => import(/* webpackChunkName: "mobile404" */ '../pages/404'))
const FourZeroThreePage = lazy(() => import(/* webpackChunkName: "mobile403" */ '../pages/403'))
const VideosPage = lazy(() => import(/* webpackChunkName: "mobile404" */ '../pages/video/videos'))
const VideoDetailPage = lazy(() => import(/* webpackChunkName: "mobile404" */ '../pages/video/video-detail'))

// use a Map so we can use objects as keys
const map = new Map([
    ['about', AboutPage],
    ['appdetail', AppDetailPage],
    [routes.APPLY, ApplyPage],
    ['apps', AppsPage],
    ['appterms', StaticPageNoHeader],
    ['app_privacy', StaticPageNoHeader],
    [routes.CATEGORIES, ExploreCategoryPage],
    ['categories_category', ExploreCategoryPage],
    [routes.DASHBOARD, DashboardPage],
    ['dmca', StaticPage],
    ['community_guidelines', StaticPage],
    ['faq', StaticPage],
    [routes.EDIT_GIF, EditGifPage],
    ['gif_maker', GifMakerPage],
    [routes.SETTINGS, SettingsPage],
    ['post', PostPage],
    [routes.STUDIOS, StudiosPage],
    ['support', SupportPage],
    ['fourzerofour', FourZeroFourPage],
    ['fourzerothree', FourZeroThreePage],
    ['fourtwothree', FourTwoThree],
    ['fivehundred', FiveHundred],
    ['privacy', StaticPage],
    ['terms', StaticPage],
    ['team', TeamPage],
    [routes.STORY, StoryPage],
    [routes.ARTISTS, ArtistsPage],
    [routes.CLIPS, VideosPage],
    [routes.CLIPS_DETAIL, VideoDetailPage],
    [routes.AR_SCENE_LANDING_PAGE, ArSceneLandingPage],
    [routes.TRANSPARENCY, TransparencyReport],
    [routes.TAKEDOWN_APPEAL, TakedownAppealPage],
])

export default map
