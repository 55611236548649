import { FC, SVGProps } from 'react'

const PendingIcon: FC<SVGProps<SVGSVGElement>> = ({ width, height }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" width={width} height={height}>
        <defs>
            <linearGradient id="PendingIcon__gradient" x1="-15.466%" x2="127.445%" y1="113.326%" y2="-27.207%">
                <stop offset="0%" stopColor="#F66" />
                <stop offset="100%" stopColor="#FFF35C" />
            </linearGradient>
        </defs>
        <path
            fill="url(#PendingIcon__gradient)"
            fillRule="nonzero"
            d="M12 24c1.633 0 3.19-.316 4.67-.95 1.48-.632 2.756-1.484 3.826-2.554 1.07-1.07 1.922-2.346 2.555-3.826.633-1.48.949-3.037.949-4.67s-.316-3.19-.95-4.67c-.632-1.48-1.484-2.756-2.554-3.826-1.07-1.07-2.346-1.922-3.826-2.555A11.759 11.759 0 0012 0c-1.633 0-3.191.316-4.676.95-1.476.632-2.75 1.484-3.82 2.554C2.434 4.574 1.582 5.85.949 7.33A11.759 11.759 0 000 12c0 1.633.316 3.19.95 4.67.632 1.48 1.484 2.756 2.554 3.826 1.07 1.07 2.346 1.922 3.826 2.555 1.48.633 3.037.949 4.67.949zm4.7-8l-5.852-3.027c-.15-.111-.254-.19-.311-.236-.358-.327-.537-.724-.537-1.19V4h1.836l1.675 6.713L18 14.81 16.7 16z"
            transform="translate(.98)"
        />
    </svg>
)

export default PendingIcon
