import { useState } from 'react'
import styled, { css } from 'styled-components'
import { IGif } from '@giphy/js-types'
import { getEmbedCode } from 'shared/util/gif-embed'
import ToggleButton from 'shared/components/toggle-button'
import { CopyButton } from '@giphy/react-giphy-brand'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'

const MESSAGE_DELAY = 2500

const CopyWrapper = styled.div<{ copied?: boolean }>`
    button {
        height: 40px;
        font-size: 16px;
        width: 150px;
    }

    ${({ copied }) =>
        !copied &&
        css`
            button {
                background-image: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
            }
        `}
`

const StyledToggle = styled(ToggleButton)`
    background: #000;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 30px;
`

const Subheader = styled.h3`
    font-weight: bold;
    font-size: 15px;
    margin: 20px 0 10px 0;
`

const ExplanationText = styled.p`
    font-size: 15px;
    line-height: 19px;
`

const ToggleContainer = styled.div`
    width: 200px;

    *:first-child {
        // force child to have 0 margin
        margin: 0 !important;
    }
`

const EmbedCodeContainer = styled.div`
    width: calc(100% - 150px);
    background-color: white;
    padding: 11px;
    overflow: hidden;
    color: black;
    font-size: 14px;
    white-space: nowrap;
    position: relative;

    &:after {
        content: '';
        width: 65px;
        height: 100%;
        right: 0;
        top: 0;
        position: absolute;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1));
    }
`

const EmbedControls = styled.div`
    display: flex;
    flex-direction: row;
`

type Props = {
    gif: IGif
}

const EmbedPanel = ({ gif }: Props) => {
    const [isResponsive, setIsResponsive] = useState<boolean>(false)
    const [copied, setCopied] = useState<boolean>(false)
    const embedCode = getEmbedCode(gif, { video: 1 }, isResponsive)

    const onOptionsChange = () => {
        setIsResponsive(!isResponsive)
    }

    return (
        <Container>
            <ExplanationText>
                Want to embed this GIF on your website or blog?
                <br />
                {`Just drop in the embed code below and you're done!`}
            </ExplanationText>
            <Subheader>Responsive</Subheader>
            <ToggleContainer>
                <StyledToggle
                    onText={'On'}
                    offText={'Off'}
                    isOn={isResponsive}
                    width={200}
                    height={32}
                    toggle={() => onOptionsChange()}
                    background={'#000'}
                />
            </ToggleContainer>
            <Subheader>Embed Code</Subheader>
            <EmbedControls>
                <EmbedCodeContainer>{embedCode}</EmbedCodeContainer>
                <CopyWrapper
                    copied={copied}
                    onClick={() => {
                        setCopied(true)
                        setTimeout(() => {
                            setCopied(false)
                        }, MESSAGE_DELAY)
                        setGADataLayer({
                            event: 'embed_success',
                            options: {
                                social: {
                                    socialChannel: 'copy',
                                },
                                content: {
                                    isResponsive,
                                    ...getContentOfGif(gif),
                                },
                                creator: getCreatorOfGif(gif),
                            },
                        })
                    }}
                >
                    <CopyButton primary CTA={'Copy Code'} url={embedCode} />
                </CopyWrapper>
            </EmbedControls>
        </Container>
    )
}

export default EmbedPanel
