import { giphyIndigo, giphyPurple } from '@giphy/colors'
import { titleFont } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { CONTENT_WIDTH, CONTENT_WIDTH_WIDE } from './constants'

export const bloop = keyframes`
    0% { transform: scale(0); }
    100% { transform: scale(1); }
`

export const AboutPageSticker = styled.img`
    animation: ${bloop} 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s 1 normal backwards;
    display: block;
    margin: 0 auto;
    width: auto;
`

export const AboutPageGlobalStyles = createGlobalStyle`
    body {
        background-color: #000 !important;
        min-height: 100vh !important;
        min-width: 0 !important;
        width: 100% !important;
    }
`

export const AboutPageContainer = styled.div`
    font-size: 22px;
    position: relative;
    text-align: center;
    overflow: hidden;

    h1,
    h2,
    h3 {
        color: #fff;
        font-family: ${titleFont};
        font-size: 1.65em;
        line-height: 1.2;
        margin: 0.2em auto 0.5em;
        max-width: ${CONTENT_WIDTH_WIDE}px;
        -webkit-font-smoothing: antialiased;
    }

    h2 {
        font-size: 1.3em;
    }

    h3 {
        font-family: inherit;
        font-size: 1em;
    }

    div[data-padded],
    section[data-padded] {
        box-sizing: border-box;
        padding: 0 12px;
    }

    p,
    small {
        display: block;
        line-height: 1.5;
        margin: 0 auto 3em;
        max-width: ${CONTENT_WIDTH}px;
        text-align: inherit;
    }

    small {
        font-size: 0.8em;
    }

    section {
        position: relative;
        margin: 0;
        padding: 0 0 4em;
    }

    section a {
        color: #fff;
        font-weight: bold;
        text-decoration: underline;
    }

    ${mobile.css`
        font-size: 18px;
    `}
`

export const AboutPageGradient = styled.div`
    background: linear-gradient(0deg, ${giphyPurple} 0%, ${giphyIndigo} 100%);
    padding-top: 10em;
`
