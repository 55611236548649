import { giphyBlack, giphyPink, giphyPurple, giphyWhite } from '@giphy/colors'
import styled, { keyframes } from 'styled-components'
import useFooterLinks from './footer-links'
import { trackGA4NavigationEvent } from './navigation-ga4'

const gridSpacing = 16
const dropDownWidth = 1040

const MenuDropDown = styled.div<{ fadeIn: ReturnType<typeof keyframes> }>`
    display: flex;
    animation: ${({ fadeIn }) => fadeIn} 0.5s ease;
    border-bottom: 8px solid ${giphyBlack};
    border-top: 8px solid ${giphyBlack};
    width: ${dropDownWidth}px;
    position: absolute;
    top: 36px;
    left: 100%;
    margin-left: -675px;
    flex-direction: column;
    cursor: default;
    background: linear-gradient(135deg, ${giphyPink} 0%, ${giphyPurple} 100%);

    li {
        line-height: 32px;
    }
`

const Columns = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`

const SmallColumn = styled.div`
    flex: 1;

    h2,
    ul {
        margin-left: 0px;
    }
`

const LargeColumn = styled.div`
    flex: 2;

    ul {
        columns: 2;
    }

    h2,
    ul {
        margin-left: 0px;
        margin-right: 60px;
    }
`

const MenuCategories = styled.div`
    flex: 2;

    ul {
        columns: 2;
    }

    h2,
    ul {
        margin-left: 60px;
    }
`

const DropDownLinks = styled.ul`
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: ${gridSpacing}px;
    margin: 10px 36px;

    a {
        font-family:
            'interface',
            Helvetica Neue,
            helvetica,
            sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: ${giphyWhite};
        cursor: pointer;
        opacity: 1;
        -webkit-font-smoothing: antialiased;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.25s;
        }
    }
`

const MenuHeader = styled.h2`
    font-size: 26px;
    font-family: 'nexablack', sans-serif;
    text-align: left;
    margin: 32px 0px 0px 60px;
    display: flex;
    flex: 1;
    -webkit-font-smoothing: antialiased;

    a {
        color: ${giphyWhite};
        opacity: 1;
        text-transform: capitalize;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.25s;
        }
    }
`

const Footer = styled.div`
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    font-size: 14px;
    height: 60px;
    line-height: 20px;
    padding: 20px 60px;
    width: 100%;

    a {
        margin-right: 30px;
    }

    span {
        cursor: pointer;
    }
`
const NewPill = styled.img`
    position: relative;
    top: 4px;
    left: 5px;
    display: inline-block;
`

const Copyright = styled.div`
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    font-family: 'nexablack', sans-serif;
    margin-right: 30px;
    padding-right: 30px;
`

const ColumnChildren = ({ title, items }) => {
    return (
        <>
            <MenuHeader>
                <a onClick={() => trackGA4NavigationEvent('none', `/${title}`, title)} href={`/${title}`}>
                    {title}
                </a>
            </MenuHeader>
            <DropDownLinks>
                {items.map(({ label, path, parent, newPill = false }, i) => (
                    <li key={i}>
                        <a onClick={() => trackGA4NavigationEvent(label, path, parent)} href={path}>
                            {label}
                        </a>
                        {newPill && <NewPill src="/static/img/svg/pill-new.svg" alt="" />}
                    </li>
                ))}
            </DropDownLinks>
        </>
    )
}

type DropdownProps = {
    fadeIn: ReturnType<typeof keyframes>
}
const Dropdown = ({ fadeIn }: DropdownProps) => {
    const footerLinks = useFooterLinks()

    return (
        <MenuDropDown fadeIn={fadeIn}>
            <Columns>
                <MenuCategories>
                    <ColumnChildren
                        title="categories"
                        items={[
                            { parent: `Categories`, label: `GIPHY Studios`, path: `/giphystudios` },
                            { parent: `Categories`, label: `Animals`, path: `/categories/animals` },
                            { parent: `Categories`, label: `Actions`, path: `/categories/actions` },
                            { parent: `Categories`, label: `Anime`, path: `/categories/anime` },
                            { parent: `Categories`, label: `Cartoons`, path: `/categories/cartoons-comics` },
                            { parent: `Categories`, label: `Emotions`, path: `/categories/emotions` },
                            { parent: `Categories`, label: `Food/Drink`, path: `/categories/food-drink` },
                            { parent: `Categories`, label: `Gaming`, path: `/gaming` },
                            { parent: `Categories`, label: `Holidays/Greetings`, path: `/greetings` },
                            { parent: `Categories`, label: `Memes`, path: `/categories/memes` },
                            { parent: `Categories`, label: `Clips`, path: `/clips` },
                        ]}
                    />
                </MenuCategories>
                <SmallColumn>
                    <ColumnChildren
                        title="stickers"
                        items={[
                            { parent: 'Stickers', label: `Originals`, path: `/originals/stickers/studios-stickers` },
                            { parent: 'Stickers', label: `Trending`, path: `/stickers/trending` },
                            { parent: 'Stickers', label: `Reactions`, path: `/stickers/reactions` },
                            { parent: 'Stickers', label: `Packs`, path: `/stickers/packs` },
                        ]}
                    />
                </SmallColumn>
                <SmallColumn>
                    <ColumnChildren
                        title="apps"
                        items={[
                            { parent: `Apps`, label: `GIPHY`, path: `/apps/giphy` },
                            { parent: `Apps`, label: `GIPHY Capture`, path: `/apps/giphycapture` },
                        ]}
                    />
                </SmallColumn>
                <LargeColumn>
                    <ColumnChildren
                        title="about"
                        items={[
                            { parent: `About`, label: `Team`, path: `/team` },
                            { parent: `About`, label: `Studios`, path: `/studios` },
                            { parent: `About`, label: `Developers`, path: `https://developers.giphy.com/` },
                            { parent: `About`, label: `FAQ`, path: `/faq` },
                            { parent: `About`, label: `Support`, path: `/support` },
                            {
                                parent: `About`,
                                label: `Jobs`,
                                path: `https://careers.shutterstock.com/us/en/search-results?keywords=giphy`,
                            },
                            { parent: `About`, label: `DMCA`, path: `/dmca` },
                            { parent: `About`, label: `Guidelines`, path: `/community-guidelines` },
                            { parent: `About`, label: `Transparency`, path: `/transparency/2022` },
                        ]}
                    />
                </LargeColumn>
            </Columns>
            <Footer>
                <Copyright>&copy; {new Date().getFullYear()} GIPHY, Inc.</Copyright>
                <div>
                    {footerLinks.map(({ url, label }) => (
                        <a key={label.toLowerCase().replace(/ /g, '-')} href={url} target="_blank" rel="noreferrer">
                            {label}
                        </a>
                    ))}
                    <span
                        onClick={() => {
                            // @ts-ignore unless they can provide ts definitions
                            window.Didomi && window.Didomi.preferences.show()
                        }}
                    >
                        Manage Cookies
                    </span>{' '}
                </div>
            </Footer>
        </MenuDropDown>
    )
}

export default Dropdown
