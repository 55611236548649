import { giphyLightGrey } from '@giphy/colors'
import { useState } from 'react'
import styled from 'styled-components'
import SearchIcon from './search-icon'

const Arrow = styled.div`
    background: transparent;
    color: #fff;
    font-size: 12px;
    height: 30px;
    line-height: 32px;
    text-align: center;
    text-indent: 2px;
    width: 30px;
    position: absolute;
    right: 0;
    pointer-events: none;
    cursor: pointer;
    top: 7px;
`

const InputContainer = styled.div`
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid rgba(155, 155, 155, 0.15);
    height: 37px;
`

const InputInput = styled.input<{ showSearchIcon: boolean }>`
    background: transparent;
    -webkit-appearance: none;
    color: #fff;
    font-weight: bold;
    width: 100%;
    padding-left: ${(props) => (props.showSearchIcon ? '20px' : '0')};
    cursor: pointer;
    padding-right: 0;
    margin-right: 0;

    &:hover {
        font-weight: normal;
    }

    &:focus {
        font-weight: normal;
        cursor: initial;
        max-width: calc(100% - 22px);
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${(props) => (props.showSearchIcon ? giphyLightGrey : '#fff')};
        opacity: 1; /* Firefox */
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearnce: none;
    }
`

const StyledSearchIcon = styled(SearchIcon)`
    position: absolute;
    left: 0;
    bottom: 7px;
    width: 14.5px;
    height: 15px;
    pointer-events: none;
`

type ValueProps = {
    disabled: boolean
    onBlur: () => void
    onChange: () => void
    onFocus: () => void
    onKeyDown: () => void
    onKeyPress: () => void
    onKeyUp: () => void
    onMouseDown: () => void
    placeholder: string
    readOnly: boolean
    tabIndex: number
    value: string
}

type Props = {
    valueProps: ValueProps
    focused: boolean
}

const LanguageInput = ({ valueProps, focused }: Props) => {
    const { placeholder, ...otherProps } = valueProps
    const [isHovered, setIsHovered] = useState(false)

    const handleHover = () => {
        setIsHovered(!isHovered)
    }

    const displayedPlace = isHovered
        ? focused
            ? placeholder
            : placeholder.substr(placeholder.indexOf(' ') + 1).trim()
        : placeholder
    const showSearchIcon = isHovered || focused

    const newProps = {
        ...otherProps,
        placeholder: displayedPlace,
        showSearchIcon: showSearchIcon,
    }
    return (
        <InputContainer className="select-search__value" onMouseOver={handleHover} onMouseOut={handleHover}>
            <Arrow className={focused ? 'ss-directup' : 'ss-dropdown'}></Arrow>
            {showSearchIcon && <StyledSearchIcon className="lil-search-icon" isGradient={true}></StyledSearchIcon>}
            <InputInput className="select-search__input" {...newProps} />
        </InputContainer>
    )
}

export default LanguageInput
