import styled from 'styled-components'
import ButtonComponent from '../../button'

export const Button = styled(ButtonComponent)`
    border-radius: inherit;
    min-width: auto;
    position: relative;
    transition: color 0.2s ease-out;
    width: 100%;

    &:focus-visible {
        outline-offset: -2px;
    }
`

export const Highlight = styled.div`
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition:
        width 0.4s ease-out,
        transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
`

export const Options = styled.div`
    border-radius: inherit;
    display: flex;
    flex-direction: row;
    position: relative;
`
