import styled, { css, keyframes } from 'styled-components'

const outlineAnimation = keyframes`
    0% { background-position: 0 0, 100% 100%, 0 100%, 100% 0; }
    100% { background-position: 14px 0, calc(100% - 14px) 100%, 0 calc(100% - 14px), 100% 14px; }
`

export const Container = styled.div`
    pointer-events: none;
    position: relative;

    &:before {
        background-color: #000;
        background-image: linear-gradient(45deg, #0e0e0e 25%, transparent 25%, transparent 75%, #0e0e0e 75%),
            linear-gradient(45deg, #0e0e0e 25%, transparent 25%, transparent 75%, #0e0e0e 75%);
        background-size: 24px 24px;
        background-position: 0 0, 12px 12px;
        border-radius: inherit;
        content: '';
        opacity: 0.3;
    }
`

export const Outline = styled.div<{ animated?: boolean; color: string }>`
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 14px 2px, 14px 2px, 2px 14px, 2px 14px;
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    ${({ animated, color }) => css`
        background-image: linear-gradient(90deg, ${color} 50%, transparent 50%),
            linear-gradient(90deg, ${color} 50%, transparent 50%), linear-gradient(0deg, ${color} 50%, transparent 50%),
            linear-gradient(0deg, ${color} 50%, transparent 50%);

        ${animated &&
        css`
            animation: ${outlineAnimation} 0.5s linear infinite;
        `}
    `}
`
