import { useContext } from 'react'
import styled from 'styled-components'
import CornerCutout from '../corner-cutout'
import Share from './share'
import Controls from './ui/controls'
import Poster from './ui/poster'
import DocumentVisibilityManager from './util/document-visibility-manager'
import ShareManager from './util/share-manager'
import VideoEl from './video'
import VideoContext from './video-context'

const Wrapper = styled.div`
    background: #000;
    height: 100%;
`

const Components = () => {
    const { showPoster, isFullscreen, isShare, isOverlayVisible, noCutout } = useContext(VideoContext)
    return showPoster ? (
        <>
            <Poster />
            {isShare && <Share />}
        </>
    ) : (
        <CornerCutout isVisible={(isShare || isOverlayVisible) && !isFullscreen && !noCutout}>
            <Wrapper>
                <VideoEl />
                <ShareManager />
                <DocumentVisibilityManager />
                {isShare ? <Share /> : <Controls />}
            </Wrapper>
        </CornerCutout>
    )
}

export default Components
