import { giphyBlue, giphyWhite } from '@giphy/colors'
import { trim } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { IChannel } from 'shared/types/channel'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    display: flex;
    cursor: pointer;
    span:hover {
        color: ${giphyBlue};
    }
`

const Slash = styled.span`
    color: ${giphyWhite};
    padding: 0 5px;
`

export const singleColumnNumberOfCrumbs = 2

type Props = {
    channel: IChannel
    // the max legnth of each crumb
    maxCrumbLength?: number
    // the max number of crumbs to show at once
    numberOfCrumbs?: number
    // show root as crumb
    showRoot?: boolean
}
const Breadcrumbs = ({ channel, maxCrumbLength = 25, numberOfCrumbs = 4, showRoot }: Props) => {
    const { channels, setActiveChannelId, rootChannelId } = useContext(CollectionContext)
    const [breadcrumbs, setBreadcrumbs] = useState<IChannel[]>([])
    useEffect(() => {
        const getCrumbs = (channel: IChannel, crumbs: IChannel[] = []): IChannel[] => {
            const parent = channels[channel.parent]
            if (parent) {
                return [...getCrumbs(parent, crumbs), channel]
            }
            return showRoot ? [channel, ...crumbs] : crumbs
        }
        const crumbs = getCrumbs(channel)
        setBreadcrumbs(crumbs.slice(-numberOfCrumbs))
    }, [channel])
    let slack = 0 // keep track of crumbs' character surplus
    const truncateCrumb = (c: string) => {
        // some combination of limiting items
        if (breadcrumbs.length < numberOfCrumbs) {
            return c
        }
        // and truncation should be enough
        const result = c.length > maxCrumbLength + slack ? trim(c.slice(0, maxCrumbLength)) + '...' : c
        if (c.length < maxCrumbLength) {
            slack += maxCrumbLength - c.length
        }
        return result
    }

    return (
        <Container>
            {breadcrumbs.map(({ id, display_name, url }, index) => {
                return (
                    <div
                        key={id}
                        onClick={() => {
                            id === rootChannelId ? setActiveChannelId(rootChannelId) : setActiveChannelId(id)
                        }}
                    >
                        <a href={url} target="_blank" rel="noreferrer">
                            <span>{truncateCrumb(display_name)}</span>
                        </a>
                        {index !== breadcrumbs.length - 1 && <Slash>/</Slash>}
                    </div>
                )
            })}
        </Container>
    )
}

export default Breadcrumbs
