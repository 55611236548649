import { Video as VideoSDK } from '@giphy/react-components'
import { useContext } from 'react'
import styled from 'styled-components'
import VideoContext from './video-context'

const VideoStyled = styled(VideoSDK)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const VideoWrapper = () => {
    const {
        onError,
        onTimeUpdate,
        onStateChange,
        onEnded,
        setVideoEl,
        isMuted,
        isLoop,
        dimensions,
        onEndFullscreen,
        onQuartile,
        onFirstPlay,
        onWaiting,
        videoData,
        onLoop,
        onCanPlay,
        setMutedBySDK,
    } = useContext(VideoContext)

    return (
        <VideoStyled
            gif={videoData}
            height={dimensions.height}
            key={videoData.id}
            loop={isLoop}
            muted={isMuted}
            onCanPlay={onCanPlay}
            onEndFullscreen={onEndFullscreen}
            onEnded={onEnded}
            onError={onError}
            onFirstPlay={onFirstPlay}
            onLoop={onLoop}
            onMuted={(muted) => {
                setMutedBySDK(muted)
            }}
            onQuartile={onQuartile}
            onStateChange={onStateChange}
            onTimeUpdate={onTimeUpdate}
            onWaiting={onWaiting}
            setVideoEl={(element) => {
                setVideoEl(element)
            }}
            width={dimensions.width}
        />
    )
}

export default VideoWrapper
