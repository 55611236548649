import { IImage } from '@giphy/js-types'
import { bestfit } from '@giphy/js-util'
import { useContext } from 'react'
import styled from 'styled-components'
import { giphyWhite } from '../../../css/colors'
import socialLinks from '../../../util/social-links'
import VideoContext from '../video-context'
import Label from './section-label'

const Container = styled.div<{ isSmall: boolean }>`
    margin-bottom: ${(props) => (props.isSmall ? 10 : 20)}px;
    width: 100%;
`

const Buttons = styled.div`
    display: flex;
    height: 36px;
    width: 100%;
`

const Button = styled.a`
    display: block;
    color: ${giphyWhite};
    font-size: 18px;
    height: 36px;
    justify-content: center;
    line-height: 40px;
    margin-left: 10px;
    text-align: center;
    width: 100%;

    &:first-child {
        margin-left: 0;
    }
`

const getBestFit = (assets, { width, height }): IImage => {
    const keys = Object.keys(assets)
    return bestfit(
        keys.filter((key) => key !== 'source').map((key) => assets[key]),
        width,
        height
    ) as IImage
}

const ShareSocial = () => {
    const {
        videoData: {
            url,
            title,
            video: { assets },
        },
        dimensions,
        isSmall,
        onShareAction,
    } = useContext(VideoContext)
    return (
        <Container isSmall={isSmall}>
            {!isSmall && <Label>Share to Socials</Label>}
            <Buttons>
                {socialLinks.map(
                    ({ backgroundColor, className, getUrl, id }) =>
                        id !== 'instagram' && (
                            <Button
                                className={className}
                                href={getUrl(url, getBestFit(assets, dimensions).url, title)}
                                key={id}
                                onClick={() => onShareAction(id)}
                                rel="noopener noreferrer"
                                style={{ backgroundColor }}
                                target="_blank"
                            />
                        )
                )}
            </Buttons>
        </Container>
    )
}

export default ShareSocial
