import { createContext } from 'react'
import { IChannel } from 'shared/types/channel'

export type ChannelType =
    | 'branch_channel'
    | 'leaf_channel'
    | 'root_channel'
    | 'tagged_channel'
    | 'search_channel'
    | 'video_channel'

export type ChannelPageContextProps = {
    isOwner: boolean
    channelType: ChannelType
    channel: IChannel
    searchQuery: string
    isSearch: boolean
    isTagView: boolean
}

const ChannelPageContext = createContext({
    isOwner: false,
} as ChannelPageContextProps)

export default ChannelPageContext
