import { giphyBlue, giphyIndigo, giphyPink, giphyPurple } from '@giphy/colors'
import styled from 'styled-components'
import { CONTENT_WIDTH } from '../constants'

const Container = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: ${CONTENT_WIDTH}px;
    padding: 2em 15px 0;
    text-align: left;
    width: 100%;
`

const Block = styled.div`
    box-sizing: border-box;
    display: inline-block;
    padding: 0 15px;
    vertical-align: top;
    width: 50%;

    &:nth-child(1) a {
        color: ${giphyPurple};
    }
    &:nth-child(2) a {
        color: ${giphyIndigo};
    }
    &:nth-child(3) a {
        color: ${giphyBlue};
    }
    &:nth-child(4) a {
        color: ${giphyPink};
    }
`

const AboutPageContactBlocks = () => (
    <Container>
        <Block>
            <h3>Do you have a press inquiry?</h3>
            <small>
                Email us at <a href="mailto:press@giphy.com">press@giphy.com</a>
            </small>
        </Block>
        <Block>
            <h3>Have an account or product question?</h3>
            <small>
                Check out our{' '}
                <a href="https://support.giphy.com/hc/en-us" target="_blank" rel="noopener noreferrer">
                    support page
                </a>
            </small>
        </Block>
        <Block>
            <h3>Need an official logo?</h3>
            <small>
                See{' '}
                <a
                    href="https://support.giphy.com/hc/en-us/articles/360022283772-GIPHY-Brand-Guidelines"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    GIPHY&lsquo;s brand guidelines
                </a>
            </small>
        </Block>
    </Container>
)

export default AboutPageContactBlocks
