import { giphyBlue, giphyPink } from '@giphy/colors'
import { isObject, map, memoize } from 'lodash'
import { HeaderContext } from 'mobile/components/header/header'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import UserContext from 'shared/contexts/user'
import { LoggedInUser } from 'types'
import MenuComponent from '../components/menu/menu'
import UserMenu from '../components/menu/user-menu'

export type UserMenuItem = {
    path: string
    label: string
    permission?: string
}

export type MainMenuLink = {
    title: string
    link: string
    newPill?: boolean
}
export type MainMenuItem = {
    header: MainMenuLink
    items: (MainMenuLink | string)[]
}

const Menu = () => {
    const { menu, setMenuState } = useContext(HeaderContext)
    const { user } = useContext(UserContext)
    const history = useHistory()

    switch (menu) {
        case 'logged out user':
            return (
                <UserMenu
                    color={giphyBlue}
                    items={loggedOutUserItems}
                    itemClick={(path) => {
                        if (path === '/favorites') {
                            setMenuState(undefined)
                            setTimeout(() => history.push(path), 0)
                        } else {
                            location.href = path
                        }
                    }}
                />
            )
        case 'user':
            return (
                <UserMenu color={giphyBlue} items={userMenuItems(user)} itemClick={(path) => (location.href = path)} />
            )
        case 'category':
            return <MenuComponent color={giphyPink} items={mainMenuItems()} />
        default:
            return null
    }
}

const loggedOutUserItems: UserMenuItem[] = [
    { path: '/login', label: 'Login' },
    { path: '/join', label: 'Join' },
    { path: '/favorites', label: 'Favorites' },
]

const userMenuItems = (user: LoggedInUser): UserMenuItem[] => {
    const { channel, permissions } = user
    const items: UserMenuItem[] = [
        {
            path: '/settings',
            label: 'Settings',
        },
        {
            path: channel,
            label: 'Channel',
        },
        {
            path: '/favorites',
            label: 'Favorites',
        },
        {
            path: '/logout',
            label: 'Log Out',
        },
    ]

    const gatedItems = [
        {
            path: '/dashboard',
            label: 'Dashboard',
            permission: 'dashboard_analytics',
        },
    ]

    return [...gatedItems.filter((item) => permissions[item.permission]), ...items]
}

const getItem =
    (prefix: string, joiner = '') =>
    (title: string | object): MainMenuLink | string =>
        isObject(title)
            ? (title as unknown as string)
            : {
                  title,
                  link: `${prefix}${encodeURIComponent(title.replace(' ', joiner || ''))}`.toLowerCase(),
              }

const mainMenuItems = memoize((): MainMenuItem[] => [
    {
        header: { title: 'Categories', link: '/categories' },
        items: [
            { title: `GIPHY Studios`, link: `/giphystudios` },
            { title: `Animals`, link: `/categories/animals` },
            { title: `Artists`, link: `/artists` },
            { title: `Emotions`, link: `/categories/emotions` },
            { title: `Entertainment`, link: `/entertainment` },
            { title: `Gaming`, link: `/gaming` },
            { title: `Holidays/Greetings`, link: `/greetings` },
            { title: `Reactions`, link: `/categories/reactions` },
            { title: `Sports`, link: `/sports` },
            { title: `Clips`, link: `/clips` },
        ],
    },
    {
        header: { title: 'Stickers', link: '/stickers' },
        items: map(
            [
                {
                    title: 'Originals',
                    link: '/originals/stickers/studios-stickers',
                },
                'Trending',
                'Reactions',
                'Packs',
            ],
            getItem('/stickers/', '-')
        ),
    },
    {
        header: { title: 'Apps', link: '/apps' },
        items: map(['GIPHY', 'GIPHY Keys'], getItem('/apps/')),
    },
    {
        header: { title: 'About', link: '/about' },
        items: map(
            [
                'DMCA',
                'FAQ',
                {
                    title: 'Jobs',
                    link: 'https://careers.shutterstock.com/us/en/search-results?keywords=giphy',
                },
                {
                    title: 'Community Guidelines',
                    link: '/community-guidelines',
                },
            ],
            getItem('/')
        ),
    },
])

export default Menu
