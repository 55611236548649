import { useEffect, useState } from 'react'
import { Coordinates } from 'types'
import { TooltipDirection } from '../../types'

const useTooltipCoordinates = (triggerRect: DOMRect | null, direction: TooltipDirection) => {
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null)

    useEffect(() => {
        if (!triggerRect) return

        const { bottom, height, left, right, top, width } = triggerRect
        const mid = { x: left + width / 2, y: top + height / 2 }
        const scroll = {
            x: document.scrollingElement?.scrollLeft || document.body.scrollLeft,
            y: document.scrollingElement?.scrollTop || document.body.scrollTop,
        }

        switch (direction) {
            case 'down':
                setCoordinates({ x: mid.x + scroll.x, y: bottom + scroll.y })
                break
            case 'left':
                setCoordinates({ x: left + scroll.x, y: mid.y + scroll.y })
                break
            case 'right':
                setCoordinates({ x: right + scroll.x, y: mid.y + scroll.y })
                break
            case 'up':
                setCoordinates({ x: mid.x + scroll.x, y: top + scroll.y })
                break
        }
    }, [direction, !!triggerRect])

    return coordinates
}

export default useTooltipCoordinates
