import { IChannel } from '@giphy/js-types'
import appendQuery from 'append-query'
import { isEmpty, omitBy } from 'lodash'
import queryString from 'query-string'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { channelSearch, getChannelById, searchTags } from 'shared/api'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import { setSiteContainerStyle } from 'shared/redux/site-container-style'
import { useAsyncEffect } from 'utils'
import typeahead, {
    TypeaheadAction,
    initialState as typeaheadDefaults,
    TypeaheadState,
} from '../../../shared/redux/typeahead'

const CURATION_PATH = '/edit/gifs'

type CurationContextProps = {
    currentGifCount: number
    setCurrentGifCount: (count: number) => void
    typeaheadDispatch: (action: TypeaheadAction) => void
    typeaheadState: TypeaheadState
    updateTypeahead: (query: string) => void
    hasTypeahead: () => boolean
    soundOn: boolean
    setSoundOn: (on: boolean) => void
    setGPSTrendingKeywords: (words: string[]) => void
    gpsKeywords: string[]
    activeDatePickerButton: string
    setActiveDatePickerButton: Dispatch<SetStateAction<string>>
    startDate?: string
    setStartDate: Dispatch<SetStateAction<string | undefined>>
    endDate?: string
    setEndDate: Dispatch<SetStateAction<string | undefined>>
    params: ParamObject
    setParams: Dispatch<SetStateAction<any>>
    updateParams: (paramObject: ParamObject) => void
    onParamChange: (paramObject: ParamObject) => void
    channel?: IChannel
    sidebarOpen: boolean
    setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

export const CurationContext = createContext({} as CurationContextProps)

export type ParamObject = {
    query?: string
    sort?: 'asc' | 'desc' | 'relevant'
    stickers?: 'ON' | 'OFF' | 'ONLY' | 'TEXT'
    videos?: 'ON' | 'OFF' | 'ONLY'
    flagAndFilters?: string
    channelIdFilter?: string
    since?: string
    until?: string
    channelIdExclude?: string
    excludeTags?: string
    validUserTypes?: string
    validRatings?: string
    searchField?: string
    visibility?: string
    publicSearch?: 'Yes' | 'No'
    relatedId?: string
}

type Props = {
    children: ReactNode
}

export const CurationContextManager = ({ children }: Props) => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
    const [currentGifCount, setCurrentGifCount] = useState<number>(0)
    const [gpsKeywords, setGPSTrendingKeywords] = useState<string[]>([])

    const [params, setParams] = useState<ParamObject>({
        stickers: undefined,
        videos: 'ON',
    })

    const [activeDatePickerButton, setActiveDatePickerButton] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const { search } = useLocation()
    const [soundOn, setSoundOn] = useState(false)

    const [channel, setChannel] = useState<IChannel | undefined>()
    const history = useHistory()

    const { isBulkEditMode } = useContext(BulkEditModeContext)
    const dispatch = useDispatch()

    useEffect(() => {
        const currentParams = queryString.parse(search)

        if (currentParams.publicSearch === 'Yes') {
            currentParams.sort = undefined
        }

        if (location.pathname === CURATION_PATH) {
            setSidebarOpen(true)

            if (isEmpty(currentParams)) {
                const defaultParams: ParamObject = {
                    flagAndFilters: '[{"flag":"VERIFIED","value":true}]',
                    videos: 'ON',
                }
                updateParams(defaultParams)
                setParams(defaultParams)
                return
            }
        }

        setParams(currentParams)
    }, [])

    useEffect(() => {
        const params = queryString.parse(location.search)
        if (params.since && params.until) {
            setStartDate(new Date(params.since).toISOString())
            setEndDate(new Date(params.until).toISOString())
        } else if (params.since && !params.until) {
            setStartDate(new Date(params.since).toISOString())
            setActiveDatePickerButton('after')
        } else if (params.until && !params.since) {
            setEndDate(new Date(params.until).toISOString())
            setActiveDatePickerButton('before')
        }
    }, [])

    useEffect(() => {
        const direction = sidebarOpen ? (isBulkEditMode ? 'shrink' : 'right') : isBulkEditMode ? 'left' : ''
        dispatch(
            setSiteContainerStyle({
                translateDirection: direction,
            })
        )
    }, [sidebarOpen, isBulkEditMode])

    useAsyncEffect(async () => {
        if (!!params.channelIdFilter) {
            const data = await getChannelById(params.channelIdFilter)
            !!data && setChannel(data)
        }
    }, [params])

    const updateParams = (paramObject: ParamObject) => {
        history.push(
            appendQuery(
                location.pathname,
                omitBy(paramObject, (value) => String(value).length < 1),
                {
                    encodeComponents: false,
                }
            )
        )
    }

    const onParamChange = (paramObject: ParamObject) => {
        const { search } = location
        const params = { ...queryString.parse(search), ...paramObject }
        if (paramObject.channelIdFilter) {
            params.query = ''
        }
        setParams(params)
        updateParams(params)
    }

    useEffect(() => {
        if (!!activeDatePickerButton || !!startDate || !!endDate) {
            updateDateParams()
        }
    }, [activeDatePickerButton, startDate, endDate])

    function updateDateParams() {
        if (activeDatePickerButton && !startDate && !endDate) return
        if (activeDatePickerButton === 'before') {
            if (!!startDate && !!endDate) {
                setEndDate('')
                onParamChange({ until: startDate, since: undefined })
            } else if (!!startDate) {
                onParamChange({ until: startDate, since: undefined })
            } else {
                onParamChange({ until: endDate, since: undefined })
                setStartDate(endDate)
                setEndDate('')
            }
        } else if (activeDatePickerButton === 'after') {
            // handle case where if they select BEFORE or AFTER with a range selected we use the end of the range
            if (endDate) {
                onParamChange({ since: endDate, until: undefined })
                setStartDate(endDate)
                setEndDate('')
            } else {
                onParamChange({ since: startDate, until: undefined })
                setEndDate('')
            }
        } else {
            if (endDate) {
                onParamChange({
                    since: startDate,
                    until: endDate,
                })
            } else {
                // Have to add 24hrs to the startDate to capture all hours in the full day
                let plusOneDate
                plusOneDate = new Date(startDate)
                plusOneDate.setDate(plusOneDate.getDate() + 1)
                plusOneDate = plusOneDate.toISOString()

                onParamChange({ until: plusOneDate, since: startDate })
                setEndDate(plusOneDate)
            }
        }
    }

    const [typeaheadState, typeaheadDispatch] = useReducer(typeahead, typeaheadDefaults)
    const hasTypeahead = () => !!typeaheadState.terms?.length || !!typeaheadState.channels?.length
    function updateTypeahead(query) {
        const isChannel = query.indexOf('@') === 0
        if (!query || query.length < 2) {
            return typeaheadDispatch({ type: 'RESET_TYPEAHEAD' })
        }

        typeaheadDispatch({ type: 'RESET_TYPEAHEAD' })
        typeaheadDispatch({ type: 'SET_TYPEAHEAD_QUERY', query })

        if (isChannel) {
            return channelSearch(query.substr(1)).then((data) => {
                typeaheadDispatch({ type: 'SET_TYPEAHEAD_CHANNELS', channels: data })
            })
        } else {
            return searchTags(query).then((results) => {
                typeaheadDispatch({ type: 'SET_TYPEAHEAD_TERMS', terms: results.data as any[] })
            })
        }
    }
    return (
        <CurationContext.Provider
            value={{
                currentGifCount,
                setCurrentGifCount,
                typeaheadDispatch,
                typeaheadState,
                updateTypeahead,
                hasTypeahead,
                soundOn,
                setSoundOn,
                setGPSTrendingKeywords,
                gpsKeywords,
                activeDatePickerButton,
                setActiveDatePickerButton,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                params,
                setParams,
                updateParams,
                onParamChange,
                channel,
                sidebarOpen,
                setSidebarOpen,
            }}
        >
            {children}
        </CurationContext.Provider>
    )
}
