import { useMemo } from 'react'
import { Transform } from 'types'

const useDragTransform = (defaultPosition: Transform, transform: Transform): Transform => {
    const { x, y, rotation, scaleX, scaleY } = defaultPosition
    const position = useMemo(
        () => ({
            x: x + transform.x,
            y: y + transform.y,
            rotation: (rotation + transform.rotation) % 360,
            scaleX: scaleX + transform.scaleX,
            scaleY: scaleY + transform.scaleY,
        }),
        [
            x,
            y,
            rotation,
            scaleX,
            scaleY,
            transform.x,
            transform.y,
            transform.rotation,
            transform.scaleX,
            transform.scaleY,
        ]
    )

    return position
}

export default useDragTransform
