import { giphyGreen } from '@giphy/colors'
import styled, { css } from 'styled-components'

const RADIO_SIZE = 22
const RADIO_COLOR = `rgba(166, 166, 166, .4)`

export const InputGroup = styled.div`
    height: ${RADIO_SIZE}px;
    margin-bottom: 10px;
    padding-left: 32px;
    position: relative;
`

export const RadioInput = styled.input`
    position: absolute;
    visibility: hidden;
`

export const RadioButton = styled.div<{ $isChecked: boolean }>`
    background: transparent;
    border: 2px solid ${RADIO_COLOR};
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: ${RADIO_SIZE}px;
    left: 0;
    position: absolute;
    top: 0;
    width: ${RADIO_SIZE}px;
    &:after {
        background: ${RADIO_COLOR};
        border-radius: inherit;
        bottom: 2px;
        content: '';
        left: 2px;
        position: absolute;
        right: 2px;
        top: 2px;
        transition: background-color 0.2s ease-out;
        ${({ $isChecked }) =>
            $isChecked &&
            css`
                background-color: ${giphyGreen};
            `}
    }
`

export const Label = styled.label`
    display: block;
    font-weight: bold;
    line-height: ${RADIO_SIZE}px;
    text-align: left;
`
