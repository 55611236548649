import { createContext } from 'react'
import { LoggedInUser } from 'types'

type GifId = string | number
export type UserContextProps = {
    user: LoggedInUser
    isLoggedIn: boolean
    favorites: GifId[] | undefined
    localFavorites: GifId[] | undefined
    addFavorite(id: GifId): void
    removeFavorite(id: GifId): void
}

const UserContext = createContext({
    user: {} as LoggedInUser,
    localFavorites: [],
    favorites: [],
    isLoggedIn: false,
    addFavorite: (_: GifId) => {
        console.error(`UserContext not initilized ${location.pathname}`)
    },
    removeFavorite: (_: GifId) => {
        console.error(`UserContext not initilized ${location.pathname}`)
    },
} as UserContextProps)

export default UserContext
