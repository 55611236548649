import styled from 'styled-components'
import { ScalerIcon } from '../../../icons/ui'

export const Scaler = styled.div`
    cursor: nwse-resize;
    height: 20px;
    user-select: none;
    width: 20px;
`

export const Icon = styled(ScalerIcon)`
    display: block;
    height: 100%;
    width: 100%;
`
