import styled from 'styled-components'
import { messagesZIndex } from '../../css/z-indexes'
import { mobile } from '../../util/media-query'

type FlashMessageWrapperProps = {
    gradientColors?: string[]
    textColor?: string
    index: number
}

export const FlashMessageWrapper = styled.div<FlashMessageWrapperProps>`
    background-image: linear-gradient(
        to right,
        ${({ gradientColors }) => (gradientColors ? gradientColors.join(',') : '#000')}
    );
    color: ${({ textColor }) => textColor};
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ index }) => messagesZIndex - index};
    display: flex;
    align-items: center;
    padding: 0 20px;
    transform: translateY(${({ index }) => index * 12}px);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: 0.3s;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);

    ${mobile.css`
    padding: 0 10px;
  `}
`

export const FlashMessageContent = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1040px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    ${mobile.css`
    flex-flow: column nowrap;
    align-items: flex-start;
    max-width: calc(100% - 24px);
  `}
`

export const FlashMessageContentTitleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

export const FlashMessageContentIcon = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 30px;
    margin-right: 4px;
    height: 35px;
    display: flex;
    align-items: center;

    ${mobile.css`
    margin: 12px 4px 8px -2px
  `}
`

export const FlashMessageContentTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-right: 4px;
`

export const FlashMessageContentMessage = styled.div`
    font-size: 15px;
    margin-top: 2px;

    ${mobile.css`
    margin-top: -5px;
    margin-left: 0
  `}
`

export const FlashMessageLink = styled.a`
    margin-left: auto;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
        margin-left: 10px;
    }

    ${mobile.css`
    margin-top: 8px;
    margin-left: 0;
    margin-bottom: 12px
  `}
`

export const FlashMessageDismiss = styled.div`
    cursor: pointer;
    min-width: 60px;
`

export const FlashMessageSpacer = styled.div`
    width: 60px;

    ${mobile.css`
    width: 0
  `}
`
