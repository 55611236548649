import { FunctionComponent } from 'react'
import { IconProps } from '.'
import * as icons from '../../icons/navigation'

export const navigationIcons = [
    'nav-close',
    'nav-create',
    'nav-gear',
    'nav-hamburger',
    'nav-home',
    'nav-planet',
    'nav-search',
    'nav-user',
] as const

type NavigationIcon = typeof navigationIcons[number]

export function navigationIconComponent(icon: NavigationIcon): FunctionComponent<IconProps> {
    switch (icon) {
        case 'nav-close':
            return icons.CloseIcon
        case 'nav-create':
            return icons.CreateIcon
        case 'nav-gear':
            return icons.GearIcon
        case 'nav-hamburger':
            return icons.HamburgerIcon
        case 'nav-home':
            return icons.HomeIcon
        case 'nav-planet':
            return icons.PlanetIcon
        case 'nav-search':
            return icons.SearchIcon
        case 'nav-user':
            return icons.UserIcon
    }
}

export default NavigationIcon
