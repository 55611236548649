import { useContext } from 'react'
import styled from 'styled-components'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import PromoMessage from 'shared/components/promo-message'
import UserContext from 'shared/contexts/user'
import { tosBannerLocalStorageId } from 'ui/src/constants'

const PromoTitle = styled.span`
    font-size: 20px;
    font-weight: 900;
`

const PromoBody = styled.span`
    font-size: 15px;
`

const NewPill = styled.img`
    position: relative;
    padding: 0px 8px;
`

const A = styled.a`
    text-decoration: underline;
    color: white;
    font-weight: 600;
`

const PromoMessages = () => {
    const {
        user: { isArtist, isPartner, permissions },
    } = useContext(UserContext)
    const canViewDashboardTrendingSearches = permissions && permissions.dashboard_analytics
    const [hasSeenPrivacyUpdate] = useLocalStorage<boolean>(tosBannerLocalStorageId, false)
    return (
        <>
            {!hasSeenPrivacyUpdate ? (
                <PromoMessage storageLabel={tosBannerLocalStorageId}>
                    <PromoTitle>Hi! </PromoTitle>
                    <PromoBody style={{ paddingLeft: `10px`, fontSize: `18px` }}>
                        {' '}
                        We&apos;ve recently updated our <A href="/privacy">Privacy Policy</A> and{' '}
                        <A href="/terms">Terms of Service</A>. By continuing to use GIPHY, you are agreeing to these
                        updates.
                    </PromoBody>
                </PromoMessage>
            ) : canViewDashboardTrendingSearches ? (
                <PromoMessage
                    storageLabel="has-seen-dashboard-trending-searches-v1-banner"
                    linkUrl="/dashboard?source=banner"
                    linkLabel="Open Dashboard"
                    titleEmoji="🔥"
                >
                    <PromoTitle>Search Insights</PromoTitle>
                    <NewPill src="/static/img/svg/pill-new.svg" alt="" />
                    <PromoBody>
                        Your dashboard now has search insights that will take your content creation to the next level.
                    </PromoBody>
                </PromoMessage>
            ) : isArtist ? (
                <PromoMessage storageLabel="has-seen-portfolios-banner" linkLabel="View Settings" isPortfolios={true}>
                    <PromoTitle>Available For Work? </PromoTitle>
                    <PromoBody style={{ marginLeft: `14px`, paddingTop: `2px` }}>
                        Display Your Work Availability To Brands, Businesses and Talent on GIPHY.
                        <a
                            href="https://support.giphy.com/hc/en-us/articles/360051709851-Freelance-GIF-Artist-Guide"
                            style={{ color: `#fff`, marginLeft: `5px` }}
                        >
                            {' '}
                            Learn More
                        </a>
                    </PromoBody>
                </PromoMessage>
            ) : isPartner ? (
                <PromoMessage
                    storageLabel="has-seen-partners-banner"
                    linkUrl="/artists"
                    linkLabel="View Artist Directory"
                >
                    <PromoTitle>Looking to Hire an Artist?</PromoTitle>
                    <NewPill src="/static/img/svg/pill-new.svg" alt="" />
                    <PromoBody>Browse & contact GIF Artists looking for their next project!</PromoBody>
                </PromoMessage>
            ) : (
                <PromoMessage storageLabel="has-seen-supercut-banner" linkUrl="/supercut" linkLabel="Open Super Cut">
                    <PromoTitle>GIPHY Super Cut: </PromoTitle>
                    <NewPill src="/static/img/svg/pill-new.svg" alt="" />
                    <PromoBody>Bulk GIF creation for our most cherished partners.</PromoBody>
                </PromoMessage>
            )}
        </>
    )
}

export default PromoMessages
