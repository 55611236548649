import { getBestRenditionUrl } from '@giphy/js-util'
import mobileCheck from 'is-mobile'
import { FunctionComponent, ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { getColor } from '../gif'
import { PlayIcon } from '../video/ui/icons/play-pause'
import getPosterUrl from '../video/util/poster-url'
import Label from './label'

const ASPECT_RATIO = 16 / 9
const IS_MOBILE = mobileCheck()

const Container = styled.div`
    position: relative;
`

const Preview = styled.a`
    display: block;
    position: relative;

    img {
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
`

const Play = styled.div<{ centered: boolean }>`
    position: absolute;
    ${(props) =>
        props.centered
            ? css`
                  align-items: center;
                  display: flex;
                  height: 100%;
                  left: 0;
                  justify-content: center;
                  top: 0;
                  width: 100%;
              `
            : css`
                  bottom: 12px;
                  left: 12px;
              `}
`
type Props = {
    video: any
    height?: number
    width: number
    showAttribution?: boolean
    playButtonSize?: number
    className?: string
    onHover?: () => void
    onClick?: () => void
    children?: ReactNode
}

const VideoPreview: FunctionComponent<Props> = ({
    children,
    className = '',
    video = {},
    height = 0,
    width = 0,
    showAttribution = true,
    playButtonSize = 45,
    onHover = () => {},
    onClick = () => {},
}) => {
    const {
        media: { previews },
        url,
        title,
        user,
    } = video
    const videoHeight = height || width / ASPECT_RATIO
    const [backgroundColor] = useState(getColor())
    const [ref, inView] = useInView()
    const posterUrl = video.images
        ? getBestRenditionUrl(video, width, videoHeight, { isStill: false, useVideo: false })
        : getPosterUrl(previews, width, videoHeight)
    return (
        <Container className={className} style={{ width }}>
            <div ref={ref} style={{ height: videoHeight, width }}>
                {inView && (
                    <Preview
                        href={url}
                        onClick={onClick}
                        onMouseOver={onHover}
                        style={{ height: videoHeight, backgroundColor }}
                    >
                        <img src={posterUrl} />
                        <Play centered={IS_MOBILE}>
                            <PlayIcon size={playButtonSize} />
                        </Play>
                        {children}
                    </Preview>
                )}
            </div>
            <Label
                href={url}
                onClick={onClick}
                showAttribution={showAttribution}
                title={title}
                user={user}
                width={width}
            />
        </Container>
    )
}

export default VideoPreview
