import { giphyDarkestGrey, giphyGreen, giphyLightGrey } from '@giphy/colors'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { Gif } from '@giphy/react-components'
import { useEffect, useState } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import styled from 'styled-components'
import Button from 'ui/src/components/controls/button'
import Overlay from 'ui/src/components/overlay'
import { tosBannerLocalStorageId } from '../constants'

const Container = styled.div`
    height: 100%;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Content = styled.div`
    height: 295px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
    background-color: ${giphyDarkestGrey};
    position: relative;
    h1 {
        font-weight: bold;
        font-size: 22px;
        margin: 24px 0;
    }
    p {
        max-width: 90%;
        line-height: 19px;
        font-size: 14px;
        margin-bottom: 24px;
    }
`

const Peeker = styled(Gif)`
    position: absolute;
    top: -108px;
    height: inherit;
    width: 258px;
`

const Btn = styled(Button)`
    width: 100%;
    margin-bottom: 8px;
    border-radius: 0;
`

type Props = {
    apiKey: string
}
/**
 * @deprecated
 */
const TosModal = ({ apiKey }: Props) => {
    const [hasSeenTosModal, setHasSeenTosModal] = useLocalStorage(tosBannerLocalStorageId, false)
    const [gif, setGif] = useState<IGif | undefined>()
    const giphyFetch = new GiphyFetch(apiKey)

    useEffect(() => {
        const getGif = async () => {
            let result
            try {
                result = await giphyFetch.gif('qo4T3YNcaT2IMGZ8tY')
            } catch (error) {}
            if (result) {
                setGif(result.data)
            }
        }
        if (!hasSeenTosModal) {
            getGif()
        }
    }, [])
    const onClose = () => {
        setHasSeenTosModal(true)
    }
    return (
        <>
            {!hasSeenTosModal ? (
                <Overlay closeOverlay={onClose} hideCancel showCloseHeader={false}>
                    <Container>
                        <Content>
                            {!!gif && (
                                <Peeker
                                    gif={gif!}
                                    width={258}
                                    backgroundColor={'rgba(0,0,0,0)'}
                                    noLink
                                    hideAttribution
                                />
                            )}
                            <h1>Privacy Policy Updates</h1>
                            <p>
                                We&apos;ve recently updated our Privacy Policy. By continuing to use GIPHY, you are
                                agreeing to these updates
                            </p>
                            <div id={'continue-button'} style={{ width: `100%` }}>
                                <Btn color={`${giphyGreen}`} size={'small'} onClick={onClose}>
                                    Continue
                                </Btn>
                            </div>
                            <Btn color={`${giphyDarkestGrey}`} textColor={`${giphyLightGrey}`} href="/terms">
                                View Terms of Service
                            </Btn>
                            <Btn color={`${giphyDarkestGrey}`} textColor={`${giphyLightGrey}`} href="/privacy">
                                View Privacy Policy
                            </Btn>
                        </Content>
                    </Container>
                </Overlay>
            ) : null}
        </>
    )
}

export default TosModal
