import { ComponentType, ReactNode, useCallback } from 'react'
import { connect } from 'react-redux'
import useSameObjectRef, { useSameArrayRef } from 'shared/hooks/use-same-ref'
import { toggleBulkEditMode } from 'shared/redux/bulk-edit-mode'
import { setBulkEditGifIds, toggleEditMode } from 'shared/redux/edit-mode'
import { receivedGifs } from 'shared/redux/gifs'
import { gridType as setGridType } from 'shared/redux/grid-type'
import { message } from 'shared/redux/message'
import {
    getAutoPlay,
    getEditGifIds,
    getGifs,
    getGridType,
    getIsBulkEditMode,
    getRemovedGifs,
    getUser,
    getUserLoggedIn,
    getUserPermissions,
} from 'shared/redux/reducers'
import BulkEditModeContext, { BulkEditModeProps } from './bulk-edit-mode'
import GifContext, { GifContextProps } from './gif'
import GridTypeContext, { GridTypeContextProps } from './grid-type'
import MessageContext, { MessageContextProps } from './message'
import { UserContextProps } from './user'
import UserManager from './user-manager'

type Props = GifContextProps &
    GridTypeContextProps &
    UserContextProps &
    MessageContextProps &
    BulkEditModeProps & {
        children: ReactNode
        receivedGifs: any
        setGridType: any
        sendMessage: any
        toggleBulkEditMode: any
        setBulkEditGifIds: any
        toggleEditMode: any
    }
// Put things in contexxt for legacy redux stuff
const ReduxBridgeManager = ({
    user,
    isLoggedIn,
    receivedGifs,
    children,
    gifs,
    gridType,
    setGridType,
    getGifById,
    removedGifs,
    autoPlay,
    sendMessage,
    isBulkEditMode,
    toggleBulkEditMode,
    setBulkEditGifIds,
    bulkEditGifIds,
    toggleEditMode,
}: Props) => {
    const gridTypeValue = useSameObjectRef({ gridType, setGridType })
    const bulkEditGifIdsRef = useSameArrayRef(bulkEditGifIds)
    const bulkEditValue = useSameObjectRef({
        isBulkEditMode,
        toggleBulkEditMode,
        setBulkEditGifIds,
        bulkEditGifIds: bulkEditGifIdsRef,
        toggleEditMode,
    })
    const getGifByIdCb = useCallback((id) => getGifById[id], [getGifById])
    return (
        <MessageContext.Provider value={{ sendMessage }}>
            <BulkEditModeContext.Provider value={bulkEditValue}>
                <UserManager isLoggedIn={isLoggedIn} user={user}>
                    <GifContext.Provider
                        value={{
                            receivedGifs,
                            gifs,
                            getGifById: getGifByIdCb,
                            removedGifs,
                            autoPlay,
                        }}
                    >
                        <GridTypeContext.Provider value={gridTypeValue}>{children}</GridTypeContext.Provider>
                    </GifContext.Provider>
                </UserManager>
            </BulkEditModeContext.Provider>
        </MessageContext.Provider>
    )
}

export default connect(
    (state) => ({
        user: getUser(state),
        gifs: getGifs(state),
        gridType: getGridType(state),
        // @ts-ignore
        getGifById: state.gifs.byId,
        isLoggedIn: getUserLoggedIn(state),
        userPermissions: getUserPermissions(state),
        removedGifs: getRemovedGifs(state),
        autoPlay: getAutoPlay(state),
        isBulkEditMode: getIsBulkEditMode(state),
        bulkEditGifIds: getEditGifIds(state),
    }),
    {
        receivedGifs,
        setGridType,
        sendMessage: message,
        toggleBulkEditMode,
        setBulkEditGifIds,
        toggleEditMode,
    }
)(ReduxBridgeManager) as ComponentType<{ children: ReactNode }>
