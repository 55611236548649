import { giphyBlack, giphyRed } from '@giphy/colors'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import { useContext } from 'react'
import { useLocation } from 'react-router'
import { addGifs, deleteChannel, editChannel, fetchChannel, saveChannel } from 'shared/api/channels'
import { Button } from 'shared/components/buttons'
import Toggle from 'shared/components/form/ui/toggle'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import MessageContext from 'shared/contexts/message'
import ModalContext from 'shared/contexts/modal'
import RefreshDataContext from 'shared/contexts/refresh-data'
import { IChannel } from 'shared/types/channel'
import styled, { css } from 'styled-components'
import { CollectionEditorContext } from './editor'
import { Label } from './sc'

const PUBLIC_GRADIENT = `linear-gradient(-135deg, rgb(0, 255, 153) 0%, rgb(0, 204, 255) 100%)`
const PRIVATE_GRADIENT = `linear-gradient(45deg, rgb(255, 102, 102) 0%, rgb(230, 70, 182) 100%)`

const Container = styled.div`
    padding: 4px 0;
`

const Row = styled.div<{ isNewChannel?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    ${(props) =>
        !props.isNewChannel &&
        css`
            &:first-of-type {
                margin-bottom: 20px;
            }
        `}

    &:last-of-type {
        border-top: 1px solid rgb(216, 216, 216, 0.16);
    }
`

const Right = styled.div<{ hide: boolean }>`
    text-align: right;
    min-width: 150px;
    ${Label} {
        color: ${giphyRed};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
            margin-right: 6px;
        }
    }
    ${(props) =>
        props.hide &&
        css`
            display: none;
        `}
    ${Label} {
        cursor: pointer;
    }
`

const PrivacyToggle = styled(Toggle)`
    width: 200px;
    height: 36px;
    border-radius: 100px;
`

const ToggleText = styled.div`
    font-size: 15px;
    font-weight: bold;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
`

const DeleteButton = styled(Button)`
    width: 240px;
    background: ${giphyBlack};
    margin-top: 20px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
        color: ${giphyRed};
        font-size: 16px;
        margin-right: 10px;
        margin-top: 5px;
    }
`

const SaveButton = styled(Button)`
    width: 100%;
    background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
    border-radius: 2px;
`

const Footer = () => {
    const { updateChannel, channel, newCollectionProps, onComplete, setDisabled } = useContext(CollectionEditorContext)
    const { pathname } = useLocation()
    const { setAddGifToCollectionSuccessMessage, setCollectionCreatedSuccessMessage } = useContext(CollectionContext)
    const { refreshChannel } = useContext(RefreshDataContext)
    const { sendMessage } = useContext(MessageContext)
    const { setCollectionCabinetProps } = useContext(ModalContext)

    const togglePrivacy = (isOn: boolean) => {
        updateChannel({ is_private: isOn })
    }
    const save = async () => {
        setDisabled(true)
        if (newCollectionProps) {
            const { parentChannel: parent, userId, redirectOnCreate, gif } = newCollectionProps
            try {
                const result = await saveChannel({
                    display_name: channel.display_name!,
                    description: channel.description!,
                    slug: '',
                    user: userId,
                    parent: parent.id,
                    is_private: channel.is_private,
                })

                // Add GIF to new collection
                if (gif) {
                    setGADataLayer({
                        event: 'add_to_collection',
                        options: {
                            content: getContentOfGif(gif),
                            creator: getCreatorOfGif(gif),
                        },
                    })
                    await addGifs([
                        {
                            gif: gif.id,
                            channel: result.id,
                        },
                    ])
                }

                // the parent channel will refresh fetching the children
                refreshChannel({ ...parent, has_children: true, children: [...(parent.children || []), result] })
                if (channel.display_name && gif) {
                    // Set success message
                    setAddGifToCollectionSuccessMessage({
                        featuredGif: gif,
                        channel: result,
                    })
                } else if (channel.display_name) {
                    setCollectionCreatedSuccessMessage({
                        channel: result,
                        channelParent: parent.id,
                    })
                }

                if (gif) {
                    setCollectionCabinetProps({ isOpen: false })
                    sendMessage(`${channel.display_name} collection created`, 'success')
                }

                if (redirectOnCreate) {
                    setTimeout(() => (location.href = result.url), 1500)
                } else {
                    // this closes the panel and we don't want that on redirect.
                    // but maybe we'd want to respond in some other way?
                    onComplete?.()
                }
            } catch (error) {
                const detail = error?.details?.detail || 'Error creating collection'
                console.error(detail, error)
                sendMessage(detail, 'error')
            }
        } else if (channel) {
            try {
                const result = await editChannel(channel as IChannel)
                // if our url matches the current pathname
                if (channel.url === location.pathname) {
                    // and now we have a new url, replace it
                    location.replace(result.url)
                }
                sendMessage(`Collection saved`, 'success')
                // refresh the cabinet
                const parentChannel = await fetchChannel(channel.parent!)
                refreshChannel(parentChannel)
                onComplete?.()
                // location.reload()
            } catch (error) {
                const detail = error?.details?.detail || 'Error editing collection'
                console.error(detail, error)
                sendMessage(detail, 'error')
            }
        }
        if (!newCollectionProps?.redirectOnCreate) {
            setDisabled(false)
        }
    }
    const deleteCollection = async () => {
        if (window.confirm(`Are you sure you want to delete this collection?`)) {
            setDisabled(true)
            try {
                await deleteChannel(channel.id!)
                const parentChannel = await fetchChannel(channel.parent!)
                // remove children from the deleted channel
                refreshChannel({ ...(channel as IChannel), has_children: false })
                refreshChannel(parentChannel)
                sendMessage(`Collection deleted!`, 'success')
                if (pathname === channel.url) {
                    // if we're on the channel we just deleted, redirect
                    setTimeout(() => (location.href = parentChannel.url), 1500)
                } else {
                    onComplete?.()
                }
            } catch (error) {
                console.error(`Collection delete error`, error)
                sendMessage(`Error deleting collection`, 'error')
                setDisabled(false)
            }
        }
    }
    const isNewChannel = !!newCollectionProps
    const isRootChannel = !channel.parent // parent 0 means we're on the user's root
    return (
        <Container>
            {!isNewChannel && (
                <Row>
                    <div>
                        <Label>Visibility</Label>
                        <PrivacyToggle
                            isOn={!channel.is_private}
                            width={200}
                            height={34}
                            toggle={togglePrivacy}
                            borderRadius={100}
                            onElement={<ToggleText>Public</ToggleText>}
                            offElement={<ToggleText>Private</ToggleText>}
                            onColor={PUBLIC_GRADIENT}
                            offColor={PRIVATE_GRADIENT}
                            backgroundColor={giphyBlack}
                        />
                    </div>
                    <Right hide={isNewChannel || isRootChannel}>
                        <DeleteButton onClick={deleteCollection}>
                            <span className="ss-trash" /> Delete Collection
                        </DeleteButton>
                    </Right>
                </Row>
            )}
            <Row isNewChannel={isNewChannel}>
                <SaveButton disabled={!channel.display_name} onClick={save}>
                    {isNewChannel ? 'Create' : 'Save'} Collection
                </SaveButton>
            </Row>
        </Container>
    )
}

export default Footer
