import { giphyBlack, giphyDarkCharcoal, giphyDarkestGrey, giphyLightGrey, giphyWhite } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { BORDER_RADIUS_LARGE, BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL } from '../constants'

export const WrapperBlock = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 70px);
    width: 100%;
`

export const ContainerBlock = styled.div`
    background: ${giphyBlack};
    border-radius: ${BORDER_RADIUS_LARGE}px;
    box-shadow: 0 11px 30px rgba(0, 0, 0, 0.5);
    display: flex;
    font-size: 17px;
    margin: 0 auto;
    min-height: 500px;
    position: relative;
    width: 100%;
`

export const PillBlock = styled.div<{ isWide?: boolean }>`
    background: ${giphyDarkCharcoal};
    border-radius: ${BORDER_RADIUS_MEDIUM}px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    height: 21px;
    line-height: 22px;
    padding: 0 7px;
    text-align: center;
    text-transform: capitalize;

    ${({ isWide }) =>
        isWide &&
        css`
            min-width: 50px;
        `}
`

export const SplitBlock = styled.div`
    border-radius: inherit;
    display: flex;
    width: 100%;

    > div {
        box-sizing: border-box;
        min-width: 0;
        padding: 50px 30px 30px;
        width: 100%;

        &:last-child {
            background: ${giphyDarkestGrey};
            border-radius: inherit;
        }
    }
`

export const ColumnBlock = styled.div`
    display: flex;
    flex-direction: column;

    > div:first-child {
        height: 100%;
        margin-bottom: 30px;
    }
`

export const WelcomeBlock = styled.div`
    background: rgba(236, 236, 236, 0.1);
    border-radius: ${BORDER_RADIUS_MEDIUM}px;
    font-size: 15px;
    margin-right: 15px;
    padding: 20px 40px 30px;
    position: relative;
    width: 100%;

    p:last-child,
    input:last-child {
        margin-bottom: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`

export const OverlayBlock = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

export const StickerBlock = styled.div`
    background-color: #000;
    background-image: linear-gradient(45deg, #0e0e0e 25%, transparent 25%, transparent 75%, #0e0e0e 75%),
        linear-gradient(45deg, #0e0e0e 25%, transparent 25%, transparent 75%, #0e0e0e 75%);
    background-size: 24px 24px;
    background-position:
        0 0,
        12px 12px;
`

export const GridBlock = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    gap: 10px;
`

export const UploadFieldBlock = styled.div`
    color: ${giphyLightGrey};
    position: relative;

    input,
    textarea {
        border-radius: ${BORDER_RADIUS_SMALL}px;
        font-size: 15px;
        height: 36px;
        margin: 0;
        padding: 0 12px;
    }
    textarea {
        height: 72px;
        padding: 10px 12px;
        resize: none;
    }
`

const gradientAnimation = keyframes`
    0% { background-position: 0 100%; }
    100% { background-position: 100% 0; }
`
export const GradientBlock = styled.div<{ colors: string[] }>`
    background: ${({ colors }) =>
        `linear-gradient(45deg, ${colors[0]} 0%, ${colors[1]} 33%, ${colors[0]} 66%, ${colors[1]} 100%)`};
    background-position: 0 100%;
    background-size: 300% 300%;

    &:hover {
        animation: ${gradientAnimation} 1.5s linear 0s infinite;
    }
`

export const GradientStatusPill = styled.div<{ colors: string[] }>`
    display: inline-block;
    color: ${giphyWhite};
    font-weight: bold;
    font-size: 14px;
    line-height: 34px;
    border-radius: 8px;
    text-align: center;
    width: 100px;

    background: ${({ colors }) => `linear-gradient(-135deg, ${colors[0]} 0%, ${colors[1]} 100%)`};
`
