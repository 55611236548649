export const margin = 6

// Z-Indexes
export const modalZIndex = 2147483646
export const privacyTermsIndex = 2147483638
export const menuZIndex = 2000
export const headerZIndex = 1000
export const belowHeaderZIndex = headerZIndex - 1
export const aboveHeaderZIndex = headerZIndex + 2

export const fontFamily = {
    title: 'nexablack, sans-serif',
    body: 'interface, Helvetica Neue, helvetica, sans-serif;',
}

export const desktopContent = 776
export const desktopWidth = 1040

export const tosBannerLocalStorageId = 'has-seen-privacy-banner-04-10-24'
