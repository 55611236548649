import { IconButtonProps } from '../../icon-button'
import { Button, Stepper } from './style'

type Props = {
    className?: string
    arrowOptions: Partial<IconButtonProps>
    onDecrement: () => void
    onIncrement: () => void
}

const NumberInputStepper = ({ className, arrowOptions, onDecrement, onIncrement }: Props) => (
    <Stepper className={className}>
        <Button {...arrowOptions} icon="navigate-up" size="mini" onClick={() => onIncrement()} />
        <Button {...arrowOptions} icon="navigate-down" size="mini" onClick={() => onDecrement()} />
    </Stepper>
)

export default NumberInputStepper
