import { GifOverlayProps } from '@giphy/react-components'
import isMobile from 'is-mobile'
import { useContext, useRef } from 'react'
import styled from 'styled-components'
import ModalContext from 'shared/contexts/modal'

const LongPressCapture = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    -webkit-touch-callout: none;
`

const StickerAttributionGridOverlay = ({ gif }: GifOverlayProps) => {
    const { openMobileActionsPanel } = useContext(ModalContext)
    const longPressRef = useRef<any | null>(null)
    const onTouchStart = () => {
        longPressRef.current = setTimeout(() => {
            openMobileActionsPanel(gif)
        }, 500)
    }
    const onTouchMove = () => {
        clearTimeout(longPressRef.current)
    }
    const onTouchEnd = () => {
        clearTimeout(longPressRef.current)
    }
    return isMobile() ? (
        <LongPressCapture onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} />
    ) : null
}

export default StickerAttributionGridOverlay
