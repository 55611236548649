import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { ControlSize } from 'types/src/controls'
import { Gradient } from 'types/src/gradient'
import { useDebouncedValue } from 'utils'
import { colorToRgba } from 'utils/src/color'
import { colorFromGradientPosition } from 'utils/src/gradient'
import useNodeWidth from 'utils/src/hooks/use-node-width'
import { textColorFromBackgroundColor } from '../../../../utils/color'
import { Button } from './style'

type Props = {
    active?: boolean
    children?: ReactNode
    className?: string
    color: string
    disabled?: boolean
    gradient?: Gradient
    size: ControlSize
    title?: string
    onClick?: () => void
    onWidthChange?: (width: number) => void
}

export default ({
    active = false,
    children,
    className,
    color,
    disabled = false,
    gradient,
    size,
    title,
    onClick,
    onWidthChange,
}: Props) => {
    const ref = useRef<HTMLElement>(null)
    const width = useNodeWidth(ref)
    const debouncedWidth = useDebouncedValue(width, 10)

    const textColor = useMemo(() => {
        const buttonColor = gradient ? colorFromGradientPosition(gradient, 0.5) : color
        const targetColor = textColorFromBackgroundColor(buttonColor)
        return active ? targetColor : colorToRgba(targetColor, 0.5)
    }, [active, color, gradient])

    useEffect(() => {
        onWidthChange?.(debouncedWidth)
    }, [debouncedWidth])

    return (
        <Button
            className={className}
            color="transparent"
            ref={ref}
            size={size}
            tabIndex={disabled ? -1 : undefined}
            textColor={textColor}
            title={title}
            onClick={() => onClick?.()}
        >
            {children}
        </Button>
    )
}
