import styled, { css } from 'styled-components'
import { giphyBlack, giphyWhite, giphyBlue, giphyPurple, giphyPink, giphyGreen } from '@giphy/colors'

export const MenuList = styled.ul`
    margin: 0;
    padding: 0;
    background: linear-gradient(
        to right,
        ${giphyBlue},
        ${giphyPurple} 31%,
        ${giphyPink} 52%,
        #fff9aa 77%,
        ${giphyGreen},
        ${giphyBlue}
    );
    background-size: 200% 50%;
    background-position: 0% 50%;
    display: flex;
    flex: 1;
    justify-content: space-around;
    transform: translateZ(0);
    position: relative;
`

export const MenuItem = styled.li`
    cursor: pointer;
    height: 36px;
    border-right: 4px solid ${giphyBlack};
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    text-transform: capitalize;

    &:before {
        content: '';
        position: absolute;
        background-color: ${giphyBlack};
        opacity: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 4px;
        transition: opacity 0.35s;
        left: -1px;
        right: -1px;
    }

    &:hover {
        background-color: transparent;
        transition: background-color 0.25s;

        &:before {
            opacity: 0.3;
        }

        a {
            color: ${giphyWhite};
        }
    }
`

export const MenuLink = styled.a`
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: ${giphyWhite};
    height: 100%;
    display: block;
    position: relative;
    -webkit-font-smoothing: antialiased;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Ellipsis = styled(MenuItem)<{ showDropdown?: boolean }>`
    border-right: 0;
    width: 4px;
    max-width: 36px;

    ${({ showDropdown }) =>
        showDropdown &&
        css`
            &:after {
                cursor: pointer;
                opacity: 1;
                height: 33px;
                border-bottom: 3px solid ${giphyWhite};
                background-color: ${giphyPink};
                bottom: 0;
            }
        `}
`
