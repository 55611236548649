import PropTypes from 'prop-types'
import css from './confirmation.css'

/**
 * Edit Panel - Confirmation: You sure you wanna do whatever it is you're doing?
 *
 */
const Confirmation = ({
    className,
    title = 'Trend',
    prompt = 'Are you sure you want to delete this GIF?',
    yesCta = 'Delete',
    noCta = 'Cancel',
    onConfirmClick,
    onCancelClick,
    yesColor = '',
    noColor = '',
}) => {
    const panel = (
        <div className={css.container}>
            <div className={css.mascot} />
            <div className={css.title}>{title}</div>
            <div className={css.prompt}>{prompt}</div>
            <div className={css.buttons}>
                <div className={css.yeah} style={yesColor ? { background: yesColor } : {}} onClick={onConfirmClick}>
                    {yesCta}
                </div>
                <div className={css.naw} style={noColor ? { background: noColor } : {}} onClick={onCancelClick}>
                    {noCta}
                </div>
            </div>
        </div>
    )
    return <div className={css.confirm}>{className ? <div className={className}>{panel}</div> : panel}</div>
}

Confirmation.propTypes = {
    className: PropTypes.string,
    prompt: PropTypes.string,
    yesCta: PropTypes.string,
    noCta: PropTypes.string,
    onConfirmClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
}

export default Confirmation
