import { useState, useEffect, useRef } from 'react'
import * as React from 'react'
import { getExplorePath } from 'shared/util/search'
import { Pill, TagText } from './sc'

type Props = {
    name: string
    isSticker: boolean
    userToggled: boolean
    maxHeight: number
    toggleContainer: () => void
}

const Tag = ({ name, isSticker, userToggled, maxHeight, toggleContainer }: Props) => {
    const [isEllipsis, setIsEllipsis] = useState<boolean>(false)
    const [isHidden, setIsHidden] = useState<boolean>(false)
    const tagEl = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        setIsHidden(false)
        updateTag()
    }, [maxHeight, userToggled])

    const updateTag = () => {
        const tag = tagEl.current
        if (tag) {
            if (!userToggled) {
                if (tag.getBoundingClientRect().top < maxHeight) {
                    // tag is in first 2 lines
                    setIsHidden(false)
                    if (tag.nextElementSibling && tag.nextElementSibling.getBoundingClientRect().top > maxHeight) {
                        setIsEllipsis(true)
                    }
                } else if (maxHeight !== 0) {
                    // make sure tags that jump up to 2nd line after setting ellipsis are not visible
                    setIsHidden(true)
                }
            }
        }
    }
    const click = (e: React.MouseEvent) => {
        if (!isEllipsis) return

        e.preventDefault()
        setIsEllipsis(false)
        toggleContainer()
    }
    return (
        <Pill ref={tagEl} href={getExplorePath(name, isSticker)} onClick={(e) => click(e)} isHidden={isHidden}>
            <TagText>{isEllipsis ? '...' : `#${name}`}</TagText>
        </Pill>
    )
}

export default Tag
