import { map, flatten, intersection, intersectionBy, intersectionWith, uniq, findIndex, get } from 'lodash'
import { IGif, IUser } from '@giphy/js-types'

const getPropFromGifs = (gifs: IGif[], prop) => {
    if (prop === 'user') {
        // Need to use a deeper comparator to intersect the user property.
        // We specify our own here, which compares the usernames of the users.
        // @ts-ignore
        return intersectionWith(map(gifs, 'user'), (a: IUser, b: IUser) => a && b && a.username === b.username)
            .length === 1
            ? gifs[0].user
            : null
    } else {
        return intersectionBy(gifs, (gif) => get(gif, prop)).length === 1 ? get(gifs[0], prop) : null
    }
}

const getTagsFromGifs = (gifs: IGif[]) => {
    const isMultipleGifs = gifs.length > 1
    const gifTags = gifs.map((gif) => gif.tags || [])
    const allTags = uniq(flatten(gifTags))
    const commonTags = intersection(...gifTags)
    const allFeaturedTags = gifs.map((gif) => (gif as any).featured_tags || [])
    const commonFeaturedTags = intersection(...allFeaturedTags)

    return allTags
        .map((tag) => ({
            tag,
            isCommon: isMultipleGifs && commonTags.indexOf(tag) > -1,
            isFeatured: commonFeaturedTags.indexOf(tag) > -1,
        }))
        .sort((a, b) => a.tag.toLowerCase().localeCompare(b.tag.toLowerCase())) // sort tags alphabetically
}

// Returns true if any of the GIFs belong to a private channel, false otherwise.
const containsPrivateChannel = (gifs: IGif[]) => {
    return findIndex(gifs, (g) => g.user && !g.user.is_public) !== -1
}

export { getPropFromGifs, getTagsFromGifs, containsPrivateChannel }
