import { giphyDarkCharcoal } from '@giphy/colors'
import styled, { css } from 'styled-components'

export const List = styled.ul<{ $textColor?: string }>`
    color: inherit;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 250px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: ${giphyDarkCharcoal};
        border-radius: 3px;
    }

    ${({ $textColor }) =>
        $textColor &&
        css`
            color: ${$textColor};
        `}
`

export const Option = styled.li<{ $active: boolean; $color?: string }>`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    user-select: none;

    > span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    > svg {
        display: block;
        height: 1em;
        margin-left: 0.4em;
        transition:
            opacity 0.15s ease-out,
            transform 0.15s ease-out;
    }

    ${({ $active }) => css`
        font-weight: ${$active ? 'bold' : 'normal'};

        > svg {
            opacity: ${$active ? 1 : 0};
            transform: scale(${$active ? 1 : 0});
            transition-duration: ${$active ? '0.3s' : '0.15s'};
            transition-timing-function: ${$active ? 'cubic-bezier(0.175, 0.885, 0.32, 1.275)' : 'ease-out'};
        }
    `}

    ${({ $color }) =>
        $color &&
        css`
            color: ${$color};
        `}
`
