import { giphyIndigo, giphyWhite } from '@giphy/colors'
import styled, { keyframes } from 'styled-components'

const nibletIn = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0);
 }
 `

const backgroundIn = keyframes`
    0% {
        transform: rotateX(90deg);
    }
    100% {
        transform: rotateX(0deg);
    }
    
`

export const nibleSize = `8px`

export const Item = styled.div`
    padding: 17px 20px 6px 20px;
    background: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    text-align: left;
    list-style: none;
    &:nth-child(1) {
        background: rgba(0, 0, 0, 0);
    }
    &:nth-child(2) {
        background: rgba(0, 0, 0, 0.1);
    }
    &:nth-child(3) {
        background: rgba(0, 0, 0, 0.2);
    }
    &:nth-child(4) {
        background: rgba(0, 0, 0, 0.3);
    }
    &:nth-child(5) {
        background: rgba(0, 0, 0, 0.4);
    }
    a {
        color: ${giphyWhite};
        text-decoration: none;
        font-weight: bold;
    }
`

export const Niblet = styled.div`
    animation: ${nibletIn} 0.3s ease-out 0.7s 1 normal backwards;
    border-bottom: ${nibleSize} solid;
    border-left: ${nibleSize} solid transparent;
    border-right: ${nibleSize} solid transparent;
    position: absolute;
    right: 22px;
    top: -6px;
`

export const Background = styled.div`
    animation: ${backgroundIn} 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    background: linear-gradient(0deg, ${giphyIndigo} 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    transform-style: preserve-3d;
    transition: background-color 0.2s ease-out;
    width: 100%;
`

export const BackgroundWrapper = styled.div`
    bottom: 0;
    left: 0;
    overflow: hidden;
    perspective: 1000;
    position: absolute;
    right: 0;
    top: 0px;
    height: 100vh;
`
