import { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import VideoContext from '../video-context'
import Embed from './embed'
import Header from './header'
import CopyLink from './link'
import Social from './social'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const Container = styled.div`
    animation: ${fadeIn} 0.2s ease-out;
    background: rgba(21, 11, 31, 0.9);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

const Content = styled.div<{ isSmall: boolean }>`
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    padding: ${(props) => (props.isSmall ? 10 : 30)}px;
    width: 100%;
`

const ShareMenu = () => {
    const { activeShareTab, isSmall } = useContext(VideoContext)
    return (
        <Container>
            <Header />
            <Content isSmall={isSmall}>
                {activeShareTab === 'social' && <Social />}
                {activeShareTab === 'embed' && <Embed />}
                <CopyLink />
            </Content>
        </Container>
    )
}

export default ShareMenu
