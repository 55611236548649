import produce from 'immer'
import { useRef } from 'react'
const spread = produce(Object.assign)

export function useSameObjectRef<T extends object>(nextValue: T) {
    const ref = useRef<T>(nextValue)
    // if the values are already equal, skip the checks
    if (ref.current !== nextValue) {
        const keysOld = Object.keys(ref.current)
        const keysNew = Object.keys(nextValue)
        if (keysOld.length !== keysNew.length) {
            ref.current = nextValue
        } else {
            if (keysOld.some((val, index) => val !== keysNew[index])) {
                // unlikely that we have the same number of keys
                // and the name has changed, but just to be sure
                ref.current = nextValue
            } else {
                // if we don't change keys, use immer produce to maintain referential equality
                ref.current = spread(ref.current, nextValue) as T
            }
        }
    }
    return ref.current
}

export function useSameArrayRef<T>(nextValue: T[]) {
    const ref = useRef(nextValue)
    // if the values are already equal, skip the checks
    if (ref.current !== nextValue) {
        if (nextValue.length !== ref.current.length) {
            // skip the some loop below and just compare based on length
            ref.current = nextValue
        } else {
            // if any values are in equal, return the next value
            if (ref.current.some((val, index) => val !== nextValue[index])) {
                ref.current = nextValue
            }
        }
    }
    return ref.current
}

export default useSameObjectRef
