// creation
export const FPS = 30 // FPS to use for downsampling, display, creation, etc...
export const FRAME_DELAY = Math.round(1000 / FPS) // delay between frames (in milliseconds)
export const MAX_FILE_DURATION = 600 // max allowed video duration for input files (in seconds)
export const MAX_FILE_SIZE = 100 // max file size allowed for input files (in MBs)
export const MAX_FILE_COUNT = 100 // max number of files that can be added
export const MIN_STICKER_TRANSPARENCY = 0.2 // min percentage of sticker that must be transparent (0-1)
export const MAX_STICKER_TRANSPARENCY = 0.95 // min percentage of sticker that must be transparent (0-1)
export const MIN_OUTPUT_WIDTH = 200 // minimum width for output GIF (in pixels)
export const MAX_OUTPUT_WIDTH = 1080 // maximum width for output GIF (in pixels)
export const MAX_OUTPUT_GIF_DURATION_FOR_CAPTIONS = 15 // maximum output GIF duration (in seconds) for captions
export const MAX_OUTPUT_GIF_DURATION = 30 // maximum output GIF duration (in seconds)
export const MAX_OUTPUT_STICKER_DURATION = 10 // maximum output sticker duration (in seconds)
export const MAX_OUTPUT_VIDEO_DURATION = 30 // maximum output video duration (in seconds)
export const MIN_OUTPUT_VIDEO_DURATION = 1 // minimum output video duration (in seconds)
export const MAX_TAG_LIMIT = 20 // maximum number of non-magic tag (+transparent) tags that a user can add to a gif

// imaging services values
export const MIN_OUTPUT_VIDEO_DIMENSION = 240 // minimum output video dimension (in pixels)
export const MIN_OUTPUT_VIDEO_ASPECT_RATIO = 0.5624 // minimum output video aspect ratio
export const MAX_OUTPUT_VIDEO_ASPECT_RATIO = 2.5 // maximum output video aspect ratio

// file types
export const STILL_MIME_TYPES = ['image/jpeg', 'image/png'] // list of accepted "still" MIME types
export const STILL_FILE_EXTENSIONS = ['.jpeg', '.jpg', '.png'] // list of accepted "still" file extensions
export const VIDEO_MIME_TYPES = ['video/mp4', 'video/mov', 'video/quicktime', 'video/webm', 'youtube', 'vimeo'] // list of accepted video MIME types
export const VIDEO_FILE_EXTENSIONS = ['.mp4', '.mov', '.webm', 'youtube', 'vimeo'] // list of accepted video file extensions
export const ANIMATED_MIME_TYPES = ['image/gif'] // list of accepted animated image MIME types
export const ANIMATED_FILE_EXTENSIONS = ['.gif'] // list of accepted animated image file extensions
export const CAPTION_FILE_MIME_TYPES = ['text/plain', 'application/x-subrip', 'text/vtt', 'application/octet-stream'] // list of accepted caption file MIME types
export const CAPTION_FILE_EXTENSIONS = ['.srt', '.vtt'] // list of accepted caption file extensions
export const FONT_MIME_TYPES = ['font/ttf'] // list of accepted font file MIME types
export const FONT_FILE_EXTENSIONS = ['.ttf', '.otf', '.woff', '.woff2'] // list of accepted font file extensions
export const ALL_ANIMATED_MIME_TYPES = [...ANIMATED_MIME_TYPES, ...VIDEO_MIME_TYPES] // list of all accepted animated MIME types (video included)
export const ALL_ANIMATED_FILE_EXTENSIONS = [...ANIMATED_FILE_EXTENSIONS, ...VIDEO_FILE_EXTENSIONS] // list of all accepted animated file extensions (video included)
export const ALL_MIME_TYPES = [...STILL_MIME_TYPES, ...ANIMATED_MIME_TYPES, ...VIDEO_MIME_TYPES] // list of all accepted MIME types
export const ALL_FILE_EXTENSIONS = [...STILL_FILE_EXTENSIONS, ...ANIMATED_FILE_EXTENSIONS, ...VIDEO_FILE_EXTENSIONS] // list of all accepted file extensions
export const FILE_EXTENSIONS_MIME_TYPE_MAP = {
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.png': 'image/png',
    '.gif': 'image/gif',
    '.mp4': 'video/mp4',
    '.mov': 'video/quicktime',
    '.mpg': 'video/mpeg',
    '.mpeg': 'video/mpeg',
    '.m4v': 'video/m4v',
    '.webm': 'video/webm',
    '.x-m4v': 'video/m4v',
    youtube: 'youtube',
    vimeo: 'vimeo',
    '.ttf': 'font/ttf',
    '.vtt': 'text/vtt',
    '.srt': 'text/plain',
}

// general
export const UPLOAD_BUTTON_LABEL = 'Upload to GIPHY' // label for the upload button
export const BUTTON_ACTIVE_OPACITY = 0.8 // button opacity when being pressed
export const BORDER_RADIUS_SMALL = 2 // border radius for smaller elements (in pixels)
export const BORDER_RADIUS_MEDIUM = 4 // border radius for medium elements (in pixels)
export const BORDER_RADIUS_LARGE = 6 // border radius for large elements (in pixels)

// analytics
export const UGE_GA_PREFIX = `UGE-` // the prefix string added to all UGE google analytics events

// supercut
export const SUPER_CUT_MIME_TYPES = [...VIDEO_MIME_TYPES, 'video/m4v', 'video/x-m4v', 'video/mpg', 'video/mpeg'] // list of accepted video MIME types for Super Cut
export const SUPER_CUT_FILE_EXTENSIONS = [...VIDEO_FILE_EXTENSIONS, '.m4v', '.x-m4v', '.mpg', '.mpeg'] // list of accepted video file extensions for Super Cut
export const SUPER_CUT_MAX_FILE_SIZE = 5000 // max file size allowed for super cut files (in MBs)
export const SUPER_CUT_MIN_FILE_DURATION = 1 // min file duration allowed for super cut files (in seconds)
export const SUPER_CUT_MAX_FILE_DURATION = 7200 // max file duration allowed for super cut files (in seconds)
export const SUPER_CUT_DEFAULT_GET_CAPTURE_LIMIT = 50 // number of captures to fetch per request from DVR

// upload
export const FALLBACK_CLIP_TITLE = 'GIPHY Clip' // the fallback title to use for clip uploading (if source is url or title is empty)
export const DUPLICATE_UPLOAD_ID_EXTENSION = '-duplicate' // the extension added to the upload item's ID when duplicated

// cut out tool
export const CUT_OUT_MINIMUM_PATH_LENGTH = 3 // minimum number of points required before a path can close

// animate tool
export const ANIMATE_FRAME_PADDING = 0.15 // amount of padding to apply to the animate tool's animations (in percentage [0 - 0.5] of preview size)
export const DEFAULT_ANIMATE_STROKE_SIZE = 4 // default stroke size in the animate step (in pixels)

// watermarks
export const WATERMARK_MAX_OUTPUT_WIDTH = 500 // maximum width (in pixels) for downsizing watermarks
export const WATERMARK_MARGIN = 0.04 // distance (in percentage of source width) watermark appears from edges
export const DVR_MEDIA_CDN_URL = 'https://dvr.media.giphy.com'
