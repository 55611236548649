import { giphyLightestGrey } from '@giphy/colors'
import * as React from 'react'
import { FC } from 'react'
import styled from 'styled-components'
import LogoShapesAnimation from 'ui/src/components/logo-shapes-animation'

const Container = styled.div`
    align-items: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232E2E2EFF' stroke-width='4' stroke-dasharray='9%2c 9' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 4px;
    box-sizing: border-box;
    color: ${giphyLightestGrey};
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    text-align: center;
`

const Wrapper = styled.div`
    bottom: 1px;
    left: 1px;
    position: absolute;
    right: 1px;
    top: 1px;
`

const Content = styled.div`
    position: relative;
    width: 100%;
`

type Props = {
    height: number
    width: number
    colors?: string[]
    children?: React.ReactNode
}

const EmptyStatePlaceholder: FC<Props> = ({ height, width, colors, children }) => {
    return (
        <Container style={{ height, width }}>
            <Wrapper>
                <LogoShapesAnimation
                    height={height - 2}
                    width={width - 2}
                    blockSize={Math.min(30, width / 10)}
                    colors={colors}
                />
            </Wrapper>
            <Content>{children}</Content>
        </Container>
    )
}

export default EmptyStatePlaceholder
