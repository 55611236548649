import styled, { css } from 'styled-components'
import { modalZIndex } from 'ui/src/constants'
import { Tooltip as TooltipComponent } from '../../tooltip'

export const HitZone = styled.div`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${modalZIndex - 1};
`

export const Tooltip = styled(TooltipComponent)<{ $up: boolean }>`
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    ${({ $up }) =>
        $up
            ? css`
                  bottom: 100%;
              `
            : css`
                  top: 100%;
              `}
`

export const Wrapper = styled.div`
    color: inherit;
    overflow: visible;
    position: fixed;
    transition: opacity 0.15s ease-out;
    z-index: ${modalZIndex};
`
