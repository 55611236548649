import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { useRefDimensions } from 'utils'
import { Content, Wrapper } from './style'

type Props = {
    children?: ReactNode
    className?: string
    isOpen?: boolean
    style?: CSSProperties
    onTransitionEnd?: () => void
}

const CollapseContent = ({ children, className, isOpen = false, style, onTransitionEnd }: Props) => {
    const contentRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState<number | undefined>(0)
    const dimensions = useRefDimensions(contentRef)

    useEffect(() => {
        if (!dimensions?.height) return

        setHeight(isOpen ? dimensions.height : 0)
    }, [dimensions?.height, isOpen])

    return (
        <Wrapper style={{ height }} onTransitionEnd={onTransitionEnd}>
            <Content className={className} ref={contentRef} style={style}>
                {children}
            </Content>
        </Wrapper>
    )
}

export default CollapseContent
