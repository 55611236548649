import { FC, SVGProps } from 'react'

const CloseIcon: FC<SVGProps<SVGSVGElement>> = ({ width, height }) => {
    return (
        <svg viewBox="0 0 22 21" width={width} height={height}>
            <defs>
                <path
                    id="CloseIcon__a"
                    d="M24.4 5L5 24 24.4 5zm.714-.7a1 1 0 01-.014 1.414L16.129 14.5l8.97 8.786a1 1 0 01.098 1.319l-.083.095a1 1 0 01-1.414.014l-9-8.815-9 8.815a1 1 0 11-1.4-1.428l8.97-8.786L4.3 5.714a1 1 0 01-.097-1.319l.083-.095A1 1 0 015.7 4.286l9 8.814 9-8.814a1 1 0 011.414.014z"
                />
            </defs>
            <use fill="currentColor" fillRule="evenodd" transform="translate(-4 -4)" href="#CloseIcon__a" />
        </svg>
    )
}

export default CloseIcon
