// @ts-nocheck
import mobileCheck from 'is-mobile'
import { PureComponent } from 'react'
import styled from 'styled-components'
import FullScreenManager from '../util/fullscreen-manager'
import VideoContext from '../video-context'
import Footer from './footer'
import Header from './header'
import PlayPause from './play-pause'
import Slider from './slider'

const CONTROLS_DIM_SIZE = 125
const IS_MOBILE = mobileCheck()

const Container = styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    transition: opacity 0.2s ease-out;
    flex-direction: column;
    justify-content: flex-end;
`

const HitZone = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`

type Props = { className?: string }

class Controls extends PureComponent<Props> {
    static contextType = VideoContext
    static defaultProps = {
        dimensions: {},
        play: () => {},
        pause: () => {},
        mediaState: {},
        isOverlayVisible: false,
        setIsOverlayVisible: () => {},
    }

    overlayTimeout: ReturnType<typeof setTimeout>
    hasPeeked = false

    componentDidMount() {
        // show overlay for first 2 seconds and then hide
        this.peekOverlay()
    }

    componentWillUnmount() {
        clearTimeout(this.overlayTimeout)
    }

    peekOverlay = () => {
        this.showOverlay()
        this.overlayTimeout = setTimeout(() => {
            this.hasPeeked = true
            this.hideOverlay()
        }, 2000)
    }

    hideOverlay = () => {
        if (!this.hasPeeked) return

        const { setIsOverlayVisible } = this.context
        clearTimeout(this.overlayTimeout)
        setIsOverlayVisible(false)
    }

    showOverlay = () => {
        const { setIsOverlayVisible } = this.context
        clearTimeout(this.overlayTimeout)
        setIsOverlayVisible(true)
    }

    toggleOverlay = () => {
        const { isOverlayVisible } = this.context
        isOverlayVisible ? this.hideOverlay() : this.peekOverlay()
    }

    toggleMute = () => {
        const { isMuted, setMuted } = this.context
        setMuted(!isMuted)
    }

    onHitClick = () => {
        if (IS_MOBILE) {
            this.toggleOverlay()
        } else {
            this.toggleMute()
        }
    }

    render() {
        const { className } = this.props
        const { isOverlayVisible } = this.context
        return (
            <Container className={className} isVisible={isOverlayVisible} onMouseOut={this.hideOverlay}>
                <FullScreenManager />
                <HitZone onClick={this.onHitClick} onMouseMove={IS_MOBILE ? undefined : this.peekOverlay} />
                <Header dimSize={CONTROLS_DIM_SIZE} isVisible={isOverlayVisible} onMouseOver={this.showOverlay} />
                {IS_MOBILE && (
                    // removed onClick={this.showOverlay} because it doesn't look like it did anything
                    <PlayPause centered />
                )}
                <Footer dimSize={CONTROLS_DIM_SIZE} isVisible={isOverlayVisible} onMouseOver={this.showOverlay} />
                <Slider />
            </Container>
        )
    }
}

export default Controls
