import { IGif } from '@giphy/js-types'
import { GET, OPTIONS } from 'shared/util/fetch-methods'
import { GifPermissions } from 'types'
import { getResults } from '.'

type Result = {}
type GifDetailResult = {
    permissions: GifPermissions
    gif: IGif
    has_permissions: boolean
}
let requestMap: { [key: string]: Promise<Result> } = {}

function request<T>(url: string) {
    if (!requestMap[url]) {
        requestMap[url] = getResults(url, GET)
    }
    return requestMap[url] as Promise<T>
}

export function getGifDetailPermissions(gifId) {
    return request<GifDetailResult>(`/ajax/gif/${gifId}/detail`)
}

export function getReportingOptions() {
    return getResults(`/api/v3/reports/`, OPTIONS)
}
