import { createContext, ReactNode } from 'react'
// import ChannelAccessModal from 'shared/components/channel-access/modal'
import ChannelAccessBottomBanner from 'shared/components/channel-access/bottom-banner'
import isMobile from 'shared/util/is-mobile'
import { IUser } from '@giphy/js-types'

export type Access = {
    channel: {
        avatar_url: string
        display_name: string
        id: number
        slug: string
        url: string
    }
    create_datetime: string
    dashboard_url: string
    id: number
    last_login_datetime: string
    login_url: string
    permission_level: number
    update_datetime: string
}

export type AccessUser = {
    avatar: string
    avatar_url: string
    display_name: ''
    id: number
    is_verified: boolean
    profile_url: string
    username: string
    token: string
}

export type ChannelAccess = {
    access: Access[]
    emulated_user?: AccessUser | null
    user: AccessUser
}

export type DashboardUser = IUser & {
    channel_id: number
}

export type AccountsManagerContextProps = {
    channel_access?: ChannelAccess
    dashboard_user?: DashboardUser
    dashboard_channel_access?: Access[]
}

export const AccountsManagerContext = createContext({} as AccountsManagerContextProps)

const AccountsManagerContextManager = ({
    channel_access,
    dashboard_user,
    dashboard_channel_access,
    children,
}: AccountsManagerContextProps & { children: ReactNode }) => {
    return (
        <AccountsManagerContext.Provider value={{ channel_access, dashboard_user, dashboard_channel_access }}>
            {channel_access && <>{!isMobile() && <ChannelAccessBottomBanner />}</>}
            {children}
        </AccountsManagerContext.Provider>
    )
}

export default AccountsManagerContextManager
