import * as React from 'react'
import { IGif } from '@giphy/js-types'
import { getUrl } from './gif-extract'

declare global {
    interface Window {
        FB: any
    }
}

const getScrollbarWidth = () => {
    let sw = 0
    const el: HTMLElement = document.createElement('div')
    el.style.height = '100'
    el.style.width = '100'
    el.style.overflow = 'scroll'

    document.body.appendChild(el)
    sw = el.offsetWidth - el.clientWidth
    document.body.removeChild(el)

    return sw
}

export interface SocialUrls {
    facebook?: string
    twitter?: string
    tumblr?: string
    reddit?: string
    pinterest?: string
}

export function getSocialUrls(gif: IGif): SocialUrls | void {
    const { url, bitly_url, tags } = gif
    const tumblrImageUrl = getUrl(gif, 'downsized')
    const imageUrl = getUrl(gif)
    const tagString = tags ? tags.join(',') : ''
    const shortUrl = bitly_url || url

    if (!url) return

    return {
        facebook: `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
        twitter: `http://twitter.com/share?url=${encodeURIComponent(shortUrl)}?tc=1&via=giphy`,
        tumblr: `http://www.tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
            url
        )}&content=${encodeURIComponent(
            tumblrImageUrl
        )}&caption=(via <a href='${shortUrl}'>GIPHY</a>)&clickthroughUrl=${encodeURIComponent(
            shortUrl
        )}&posttype=photo&tags=giphy,${tagString}`,
        pinterest: `http://www.pinterest.com/pin/create/link/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(
            imageUrl
        )}&description=via GIPHY`,
        reddit: `http://www.reddit.com/submit?url=${encodeURIComponent(url)}`,
    }
}

export enum SOCIAL_TYPES {
    TWITTER = 'twitter',
    FACEBOOK = 'facebook',
    PINTEREST = 'pinterest',
    LINK = 'link',
    EMBED = 'embed',
}

export function openPopup(url: string, type: string, width?: number, height?: number, left?: number, top?: number) {
    if (!url) return

    switch (type) {
        case SOCIAL_TYPES.TWITTER:
            height = 350
            width = 750
            break
        default:
            height = height || 480
            width = width || 480
            break
    }

    let options = ''
    const scrollbarWidth = getScrollbarWidth()
    const defaults = {
        height: height,
        left: left ? left - scrollbarWidth / 2 : 0,
        location: 0,
        menubar: 0,
        resizable: 1,
        status: 0,
        toolbar: 0,
        top: top ? top + 25 : 0,
        width: width + scrollbarWidth,
    }

    for (let option in defaults) {
        options += option + '=' + defaults[option] + ','
    }

    window.open(url, '_blank', options)
}

export const socialClick = {
    mobile: (url: string, fb, gif: IGif) => (window.FB && fb ? fb.share({ href: gif.url }) : window.open(url)),
    desktop: (url: string, type: SOCIAL_TYPES, e: React.MouseEvent) => {
        if (e?.preventDefault) e.preventDefault()
        openPopup(url, type)
    },
}

export default {
    getSocialUrls,
    openPopup,
    socialClick,
}
