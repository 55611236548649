import { giphyBlue, giphyIndigo, giphyPink, giphyPurple, giphyYellow } from '@giphy/colors'
import appendQuery from 'append-query'
import { IAboutPageImages } from './types'

export const IS_MOBILE: boolean = window && window.innerWidth < 768

export const IMAGE_PATH: string = '/static/img/about'
export const COASTER_VIDEO_RATIO: number = 0.57

export const CONTENT_WIDTH: number = 700
export const CONTENT_WIDTH_WIDE: number = 900

export const ANIMATION_MIN_SCALE = 0.5
export const ANIMATION_MAX_SCALE = 1
export const ANIMATION_SCALE_DIFF = ANIMATION_MAX_SCALE - ANIMATION_MIN_SCALE
export const ANIMATION_SPEED = 2
export const ANIMATION_DRIFT_SPEED = 0.2

export const TEAM_COLUMNS: number[] = [2, 2, 3]
export const TEAM_COLUMN_WIDTHS: number[] = IS_MOBILE ? [200, 160, 120] : [350, 260, 200]
export const TEAM_GUTTER_WIDTH: number = IS_MOBILE ? 12 : 20
export const TEAM_ANIMATION_HEIGHT: number = (IS_MOBILE ? 400 : 700) + TEAM_GUTTER_WIDTH

export const NEWS_LINK_COLORS: string[] = [giphyPurple, giphyIndigo, giphyBlue, giphyPink, giphyYellow]

export const MEDIUM_API_URL: string = appendQuery(`https://api.rss2json.com/v1/api.json`, {
    rss_url: 'https://medium.com/feed/@Giphy',
    api_key: window.GIPHY_FE_MEDIUM_BLOG_API_KEY,
    order_by: 'pubDate',
    count: 5,
})

export const INFO_IMAGE_URLS: IAboutPageImages = {
    imessage: [`${IMAGE_PATH}/hovers/imessage.gif`],
    facebook: [`${IMAGE_PATH}/hovers/facebook.gif`],
    instagram: [`${IMAGE_PATH}/hovers/instagram.gif`],
    snapchat: [`${IMAGE_PATH}/hovers/snapchat.gif`],
    tiktok: [`${IMAGE_PATH}/hovers/tiktok.gif`],
    artist: [
        'https://media.giphy.com/media/5zjdwaDzHyOxqpiM9d/200.gif',
        'https://media.giphy.com/media/3ov9k9vVSTn6DevZsY/200.gif',
        'https://media.giphy.com/media/11bE8qLtHGXsCQ/200.gif',
        'https://media.giphy.com/media/13Jf2FXPlmB78s/200.gif',
        'https://media.giphy.com/media/J07CpXhx3odUCyma8l/200.gif',
    ],
    brand: [
        'https://media.giphy.com/media/9u3p4JE9uY2Ld59KZp/200.gif',
        'https://giphy.com/static/img/giphy-marketers/starbucks-cropped.gif',
        'https://giphy.com/static/img/giphy-marketers/amazon-cropped.gif',
        'https://media.giphy.com/media/gJ3I3d5Ug1zzX7xd7J/200.gif',
    ],
    partner: [
        'https://media.giphy.com/media/Kc2NXnrs8WHbHL5ujB/200.gif',
        'https://media.giphy.com/media/l3q2BhukQ7kNkDj9u/200.gif',
        'https://media.giphy.com/media/YnBntKOgnUSBkV7bQH/200.gif',
        'https://media.giphy.com/media/RlNAr0o5jXLFk2HVRE/200.gif',
        'https://media.giphy.com/media/avLTylsUahJMa8uWiP/200.gif',
    ],
    reactions: [
        'https://media.giphy.com/media/xUA7biUek77Zx2r9oA/200.gif',
        'https://media.giphy.com/media/Jrf6QkGpuob4qHAoxL/200.gif',
        'https://media.giphy.com/media/ZEU9ryYGZzttn0Cva7/200.gif',
        'https://media.giphy.com/media/l4HnKwiJJaJQB04Zq/200.gif',
    ],
    realtime: [
        'https://media.giphy.com/media/l2SpK1VclCGxzo2Oc/200.gif',
        'https://media.giphy.com/media/Vf8img8rlIANGV6xhs/200.gif',
        'https://media.giphy.com/media/lr7OOqwozWLtOhNo7C/200.gif',
    ],
    stickers: [
        'https://media.giphy.com/media/3oFzlUc0W2IKnD4cAE/200.gif',
        'https://media.giphy.com/media/l1J3ruwCiO7cmj6Zq/200.gif',
        'https://media.giphy.com/media/3o6vXWtYHTu2qkF332/200.gif',
        'https://media.giphy.com/media/l41K1gfrUz1HDohvG/200.gif',
    ],
    video: [
        'https://media.giphy.com/media/S8O1cBb3PwW32ScYyV/200.gif',
        'https://media.giphy.com/media/j52sPn4EG2TIgIUoCf/200.gif',
        'https://media.giphy.com/media/WUlgxMAeoD8yVSKOr1/200.gif',
    ],
    you: [
        'https://media.giphy.com/media/JlpjgShzDsrMIyFu5U/200.gif',
        'https://media.giphy.com/media/pcKlyVsfMZkRtyZ5Hz/200.gif',
    ],
    transparent: ['https://media.giphy.com/media/xT9IgxlTcZ4TcFr2x2/200.gif'],
    alive: ['https://media.giphy.com/media/l0MYsC1UC0BGwG2SQ/200.gif'],
    balance: ['https://media.giphy.com/media/xTiN0Emq5EO6z94A3C/200.gif'],
    expressive: ['https://media.giphy.com/media/l3q2tmyH06o86kLqU/200.gif'],
    friends: ['https://media.giphy.com/media/f9wr4nkei7s9k32ppD/200.gif'],
    love: ['https://media.giphy.com/media/3o7qDJKIO5rSeyHhvO/200.gif'],
    help: ['https://media.giphy.com/media/ZdHH6TCcF07MaOXI9E/200.gif'],
    inclusion: ['https://media.giphy.com/media/mucJtzk2V8MrWqteU1/200.gif'],
    weird: ['https://media.giphy.com/media/3o6ozzc24Ikxi1WXo4/200.gif'],
    witty: ['https://media.giphy.com/media/1aV6Msff7W3IzoxHOy/200.gif'],
    people: ['https://media.giphy.com/media/3ohhwxtchVEHfSPj0c/200.gif'],
    eyes: ['https://media.giphy.com/media/xUA7aW7KWW289LvRdK/200.gif'],
    hearts: ['https://media.giphy.com/media/xUPGcgvoipFxRtpMl2/200.gif'],
    relationships: ['https://media.giphy.com/media/RhrHptWkT2VaFtgPuM/200.gif'],
    solutions: ['https://media.giphy.com/media/MSCxcPerWykHyR11EB/200.gif'],
    post: ['https://media.giphy.com/media/l3UcjBJUov1gCRGbS/200.gif'],
    good: ['https://media.giphy.com/media/kmUvauX8TMWg0OsqKW/200.gif'],
}
