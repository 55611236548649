import { giphyDarkGrey, giphyGreen, giphyRed, giphyWhite } from '@giphy/colors'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface BackgroundProps {
    isOn: boolean | undefined
    height: number
    width: number
    activeColor: string
    offColor: string
    borderRadius?: number
}

const ToggleBackground = styled.div<BackgroundProps>`
    position: absolute;
    top: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    ${({ isOn, activeColor, offColor }) => {
        if (isOn == undefined) {
            return ''
        } else if (isOn) {
            return css`
                background: ${activeColor};
            `
        } else {
            return css`
                background: ${offColor};
            `
        }
    }}
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width / 2}px`};
    left: ${(props) => (props.isOn ? 0 : `${props.width / 2}px`)};
    border-radius: ${(props) => props.borderRadius && `${props.borderRadius}px`};
`

const Icon = styled.div<{ isOn?: boolean; height: number; width: number; iconOn: string; iconOff: string }>`
    cursor: pointer;
    display: inline-block;
    line-height: ${(props) => props.height}px;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: ${(props) => props.width / 2}px;
    height: ${(props) => props.height}px;
    transition: color 0.2s ease-in-out 0.2s;
    color: ${(props) => (props.isOn ? `${props.iconOn}` : `${props.iconOff}`)};
    i {
        font-size: 8px;
        top: -1px;
        position: relative;
    }
`

const Container = styled.div<{ backgroundColor: string }>`
    background: ${(props) => `${props.backgroundColor}`};
    display: inline-block;
    position: relative;
    vertical-align: top;
    page-break-inside: avoid;
    break-inside: avoid;
`

type Props = {
    toggle: Function
    clickOn?: () => void
    clickOff?: () => void
    isOn: boolean | undefined
    backgroundColor?: string
    onColor?: string
    offColor?: string
    onElement?: ReactNode
    offElement?: ReactNode
    height?: number
    width?: number
    className?: string
    borderRadius?: number
    iconOn?: string
    iconOff?: string
}

const Toggle = ({
    toggle,
    clickOn = () => {},
    clickOff = () => {},
    isOn,
    backgroundColor = giphyDarkGrey,
    onColor = giphyGreen,
    offColor = giphyRed,
    onElement,
    offElement,
    height = 25,
    width = 50,
    className,
    borderRadius,
    iconOn = giphyDarkGrey,
    iconOff = giphyWhite,
}: Props) => {
    const toggleAction = () => {
        toggle(isOn)
    }
    return (
        <Container backgroundColor={backgroundColor} className={className} onClick={toggleAction}>
            <ToggleBackground
                activeColor={onColor}
                borderRadius={borderRadius}
                height={height}
                isOn={isOn}
                offColor={offColor}
                width={width}
            />
            <Icon onClick={clickOn} height={height} iconOff={iconOff} iconOn={iconOn} isOn={isOn} width={width}>
                {onElement}
            </Icon>
            <Icon onClick={clickOff} height={height} iconOff={iconOff} iconOn={iconOn} isOn={!isOn} width={width}>
                {offElement}
            </Icon>
        </Container>
    )
}

export default Toggle
