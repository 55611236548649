import mobileCheck from 'is-mobile'
import styled, { css } from 'styled-components'
import {
    giphyDarkCharcoal,
    giphyDarkestGrey,
    giphyDarkGrey,
    giphyGreen,
    giphyBlack,
    giphyWhite,
} from '../../css/colors'
import Button from '../button'

const isMobile = mobileCheck()

const getColor = (index) => {
    if (index === 0) {
        return giphyDarkCharcoal
    }
    if (index % 2 === 0) {
        return giphyDarkestGrey
    }
    if (index % 1 === 0) {
        return giphyDarkGrey
    }
    return giphyDarkCharcoal
}

const SelectableButton = styled(Button)(
    ({ index, selected, isOtherHovered }) => css`
        position: relative;
        background: ${selected ? giphyGreen : getColor(index)};
        color: ${selected ? giphyBlack : giphyWhite};
        ${!isMobile &&
        css`
            &:hover {
                background: ${giphyGreen};
                color: ${giphyBlack};
            }
        `};
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: black;
            opacity: ${isOtherHovered && selected ? 0.1 : 0};
            pointer-events: none;
        }
    `
)

const ButtonGroupButton = ({ className, index, selected, label, onClick, isOtherHovered, onMouseOver, children }) => (
    <SelectableButton
        type="button"
        {...{ className, index, selected, onClick, onTouchEnd: onClick, isOtherHovered, onMouseOver }}
    >
        {label || children}
    </SelectableButton>
)

export default ButtonGroupButton
