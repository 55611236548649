import { giphyDarkCharcoal, giphyWhite } from '@giphy/colors'
import { bodyFont } from 'shared/css/fonts'
import styled from 'styled-components'

export const Button = styled.button`
    border: 0;
    box-sizing: border-box;
    color: ${giphyWhite};
    cursor: pointer;
    font-family: ${bodyFont};
    font-size: 14px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    min-width: 36px;
    outline: none;
    padding: 0 15px;
    text-align: center;
    user-select: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    &:hover {
        color: ${giphyWhite};
    }
    &:active {
        opacity: 0.9;
    }
    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`

export const ButtonLarge = styled(Button)`
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    min-width: 50px;
    padding: 0 20px;
`

export const ButtonGrow = styled(Button)`
    display: flex;
    align-items: center;
    height: 100%;
    width: auto;
    line-height: inherit;
`

export const AnimatedButton = styled(Button)`
    background: url('/static/img/svg/icon-sheet-6-column.svg') no-repeat;
    background-size: 180px 300px;
    padding: 0;
    width: 36px;
`

export const PaginationButton = styled(Button)`
    background: ${giphyDarkCharcoal};
    display: inline-block;
    font-size: 11px;
    font-weight: normal;
    height: 22px;
    line-height: 24px;
    padding: 0;
`

export const NextButton = styled(PaginationButton)`
    margin-left: 3px;
`

export const PreviousButton = styled(PaginationButton)``
