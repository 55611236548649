import styled from 'styled-components'

export const Mover = styled.div`
    cursor: grab;
    height: 100%;
    user-select: none;
    width: 100%;

    &:active {
        cursor: grabbing;
    }
`
