import { FC, createContext, useState, useMemo } from 'react'
import * as React from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'

const MINUTES_TO_RESET = 5

type VideoOverlayContextProps = {
    onStartHover?: (gifId: string) => void
    onFinishHover?: (gifId: string) => void
    onFinishVolumeIconAnimation?: () => void
    setMuted: (muted: boolean, isMutedByBrowser?: boolean) => void
    muted?: boolean | undefined
    finishVolumeIconAnimation?: boolean
    hoveredClip?: string | null

    // Prevent hover to work after just start another video
    timeWhenVideoStarted: number | undefined
    setTimeWhenVideoStarted: (time: number) => void
}

type Props = {
    children?: React.ReactNode
}

export const VideoInlinePlayContext = createContext({} as VideoOverlayContextProps)

const VideoInlinePlayContextManager: FC<Props> = ({ children }) => {
    // Prevent hover to work after just start another video
    const [timeWhenVideoStarted, setTimeWhenVideoStarted] = useState<number | undefined>()

    // Deal with mute state of videos
    const [lastTimeMuted, setLastTimeChangeMuted] = useLocalStorage<number | null>('has-clips-muted', null)
    const startsMuted = useMemo(() => {
        // Check if user set muted for more than MINUTES_TO_RESET minutes, IF yes, reset the mute state
        if (lastTimeMuted === null) return undefined

        const minutesOfDifference = (new Date().getTime() - lastTimeMuted!) / 60000

        if (minutesOfDifference > MINUTES_TO_RESET) {
            return undefined
        } else {
            return true
        }
    }, [])
    const [hoveredClip, setHoveredClip] = useState<null | string>(null)
    const [muted, setIsMuted] = useState<boolean | undefined>(startsMuted)
    const [finishVolumeIconAnimation, setFinishVolumeIconAnimation] = useState(false)
    const handleMuted = (muted: boolean, isMutedByBrowser?: boolean) => {
        setIsMuted(muted)

        if (muted && !isMutedByBrowser) {
            setLastTimeChangeMuted(new Date().getTime())
        } else {
            setLastTimeChangeMuted(null)
        }
    }

    return (
        <VideoInlinePlayContext.Provider
            value={{
                onStartHover: (gifId: string) => setHoveredClip(gifId),
                onFinishHover: () => setHoveredClip(null),
                onFinishVolumeIconAnimation: () => setFinishVolumeIconAnimation(true),
                finishVolumeIconAnimation,
                hoveredClip,
                muted,
                setMuted: (muted, isMutedByBrowser) => handleMuted(muted, isMutedByBrowser || false),

                // Prevent hover to work after just start another video
                timeWhenVideoStarted,
                setTimeWhenVideoStarted: (time) => setTimeWhenVideoStarted(time),
            }}
        >
            {children}
        </VideoInlinePlayContext.Provider>
    )
}

export default VideoInlinePlayContextManager
