import { CSSProperties, useEffect, useState } from 'react'
import { Gradient } from 'types'
import { gradientToCss } from 'utils'

export function useHighlightStyle(isOn: boolean, color: string, gradient?: Gradient) {
    let background = color
    if (gradient) {
        background = gradientToCss(gradient)
    }
    const transform = `translate3d(${isOn ? 100 : 0}%, 0, 0)`
    const [value, setValue] = useState<CSSProperties>({
        background,
        transform,
    })
    useEffect(() => {
        setValue({
            background,
            transform,
        })
    }, [background, transform, isOn])
    return value
}
