import { useDraggable } from '@dnd-kit/core'
import { useEffect } from 'react'
import { Coordinates } from 'types'
import useModifierKeys from '../hooks/use-modifier-keys'
import { Icon, Rotator } from './style'

const SHIFT_ROTATION_INCREMENT = 45

type Props = {
    center: Coordinates
    className?: string
    color: string
    dndId: string
    onRotate: (rotation: number) => void
}

const DraggableRotator = ({ center, className, color, dndId, onRotate }: Props) => {
    const { listeners, setNodeRef, transform } = useDraggable({
        id: dndId,
        data: { control: 'draggable', type: 'rotate' },
    })
    const { shift: isShiftKeyPressed } = useModifierKeys()

    useEffect(() => {
        if (!transform) return

        const { x, y } = transform
        const radians = Math.atan2(x, y - center.y)
        let rotation = Math.round((radians * -180) / Math.PI)

        if (isShiftKeyPressed) {
            rotation = Math.floor(rotation / SHIFT_ROTATION_INCREMENT) * SHIFT_ROTATION_INCREMENT
        }

        onRotate(rotation + 180)
    }, [transform?.x, transform?.y])

    return (
        <Rotator {...listeners} className={className} ref={setNodeRef}>
            <Icon color={color} />
        </Rotator>
    )
}

export default DraggableRotator
