import { createContext, ReactNode, RefObject, useCallback, useRef, useState } from 'react'
import { Coordinates } from 'types'
import { useNodeRef } from 'utils'
import Portal from '../../../portal'
import { TooltipData } from '../types'
import { TriggerCoordinatesPlaceholder } from './style'

type ContextProps = {
    activeId?: string
    activeTriggerRef: RefObject<HTMLElement | null>
    tooltips: Map<string, TooltipData>
    closeTooltip: () => void
    openTooltip: (triggerNode: HTMLElement | null, id: string) => void
    openTooltipAtCoordinates: (triggerCoordinates: Coordinates, id: string) => void
}

type Props = {
    children?: ReactNode
}

export const PortalTooltipContext = createContext({} as ContextProps)

const PortalTooltipContextManager = ({ children }: Props) => {
    const tooltipsRef = useRef<Map<string, TooltipData>>(new Map())
    const [activeId, setActiveId] = useState<string | undefined>()
    const [activeTriggerRef, setActiveTriggerRef] = useNodeRef()
    const [activeTriggerCoordinates, setActiveTriggerCoordinates] = useState<Coordinates | undefined>()

    const closeTooltip = useCallback(() => {
        setActiveTriggerCoordinates(undefined)
        setActiveTriggerRef(null)
        setActiveId(undefined)
    }, [])

    const openTooltip = useCallback((triggerNode: HTMLElement | null, id: string) => {
        setActiveTriggerRef(triggerNode)
        setActiveId(id)
    }, [])

    const openTooltipAtCoordinates = useCallback((triggerCoordinates: Coordinates, id: string) => {
        setActiveTriggerCoordinates(triggerCoordinates)
        setActiveId(id)
    }, [])

    return (
        <PortalTooltipContext.Provider
            value={{
                activeId,
                activeTriggerRef,
                tooltips: tooltipsRef.current,
                closeTooltip,
                openTooltip,
                openTooltipAtCoordinates,
            }}
        >
            {children}
            {activeTriggerCoordinates && (
                <Portal>
                    <TriggerCoordinatesPlaceholder
                        ref={setActiveTriggerRef}
                        style={{ left: activeTriggerCoordinates.x, top: activeTriggerCoordinates.y }}
                    />
                </Portal>
            )}
        </PortalTooltipContext.Provider>
    )
}

export default PortalTooltipContextManager
