import { GifOverlayProps } from '@giphy/react-components'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { VideoInlinePlayContext } from 'shared/contexts/video-inline-play-manager'
import { VideoLoopContext } from 'shared/contexts/video-loop-manager'
import GAVideo from '../video-player/sdk-video-with-ga'
import Volume from './volume'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { LockOverlay } from '../gif-menu-overlay'

const VideoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
`

const VideoStyled = styled(GAVideo)<{ isReady: boolean }>`
    height: 100%;
    object-fit: contain;
    background: ${(props) => (props.isReady ? 'black' : 'transparent')};
`

const TIME_TO_HOVER_WORK_AFTER_START_A_NEW_MAIN_VIDEO = 3 // use seconds here

const VideoOverlay = ({
    gif,
    width = 248,
    height,
    isHovered,
    volumeSize,
    clipboardSize,
    gridPosition,
    hideLinkAndCopy = false,
}: GifOverlayProps & {
    gridPosition?: number
    width?: number
    height?: number
    volumeSize?: number
    clipboardSize?: number
    hideLinkAndCopy?: boolean
}) => {
    const [mutedByBrowser, setMutedByBrowser] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const { onStartHover, onFinishHover, setMuted, muted, timeWhenVideoStarted } = useContext(VideoInlinePlayContext)
    const { setPreventLoopCounter } = useContext(VideoLoopContext)
    const [copied, setCopied] = useState<boolean>()
    const [hasCopiedLink, setHasCopiedLink] = useLocalStorage(`${gif.id}`, false)

    const toggleMute = () => {
        if (mutedByBrowser) {
            setMutedByBrowser(false)
            setMuted(false, true)
        } else {
            setMuted(!muted)
        }
    }
    const copyLink = () => {
        if (!copied && !hasCopiedLink) {
            setCopied(true)
            setHasCopiedLink(true)
        }
    }
    const checkIfJustStartedAnotherClip = () => {
        const unAvailableToPlay =
            timeWhenVideoStarted &&
            (new Date().getTime() - timeWhenVideoStarted) / 1000 < TIME_TO_HOVER_WORK_AFTER_START_A_NEW_MAIN_VIDEO

        if (unAvailableToPlay) onFinishHover?.(gif.id.toString())

        return unAvailableToPlay
    }
    useEffect(() => {
        if (isHovered) {
            onStartHover?.(gif.id.toString())

            // Prevent to loop videos
            setPreventLoopCounter?.(true)
        } else {
            // Remove prevent to loop videos
            setPreventLoopCounter?.(false)

            setIsReady(false)
            onFinishHover?.(gif.id.toString())
            setCopied(false)
        }
    }, [isHovered])

    if (!isHovered || checkIfJustStartedAnotherClip()) {
        return (
            <>
                <LockOverlay gif={gif} isHovered={isHovered} />
                <Volume
                    muted
                    isHovered={false}
                    volumeSize={volumeSize}
                    clipboardSize={clipboardSize}
                    gif={gif}
                    hideLinkAndCopy={hideLinkAndCopy}
                />
            </>
        )
    }

    return (
        <VideoContainer>
            <VideoStyled
                gif={gif}
                height={height}
                key={gif.id}
                loop
                muted={muted}
                width={width}
                isReady={isReady}
                onTimeUpdate={(time) => {
                    if (time > 0) {
                        setIsReady(true)
                    }
                }}
                onMuted={(muted: boolean) => setMutedByBrowser(muted)}
                gridPosition={gridPosition}
                copied={copied}
            />
            <Volume
                toggleMute={toggleMute}
                muted={muted || mutedByBrowser}
                isHovered={true}
                volumeSize={volumeSize}
                clipboardSize={clipboardSize}
                gif={gif}
                copyLink={copyLink}
                hideLinkAndCopy={hideLinkAndCopy}
            />
        </VideoContainer>
    )
}
export default VideoOverlay
