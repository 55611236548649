import { FunctionComponent, SVGAttributes } from 'react'
import ContentTypeIcon, { contentTypeIconComponent, contentTypeIcons } from './content-type'
import CreationIcon, { creationIconComponent, creationIcons } from './creation'
import NavigationIcon, { navigationIconComponent, navigationIcons } from './navigation'
import PlayerIcon, { playerIconComponent, playerIcons } from './player'
import UiIcon, { uiIconComponent, uiIcons } from './ui'
import UtilityIcon, { utilityIconComponent, utilityIcons } from './utility'

type Icon = ContentTypeIcon | CreationIcon | NavigationIcon | PlayerIcon | UiIcon | UtilityIcon

export const allIcons: Icon[] = [
    ...utilityIcons,
    ...playerIcons,
    ...navigationIcons,
    ...creationIcons,
    ...contentTypeIcons,
].sort()

export type IconProps = SVGAttributes<SVGElement>

export function iconComponent(icon: Icon): FunctionComponent<IconProps> {
    if (contentTypeIcons.indexOf(icon as ContentTypeIcon) > -1) {
        return contentTypeIconComponent(icon as ContentTypeIcon)
    }

    if (creationIcons.indexOf(icon as CreationIcon) > -1) {
        return creationIconComponent(icon as CreationIcon)
    }

    if (navigationIcons.indexOf(icon as NavigationIcon) > -1) {
        return navigationIconComponent(icon as NavigationIcon)
    }

    if (playerIcons.indexOf(icon as PlayerIcon) > -1) {
        return playerIconComponent(icon as PlayerIcon)
    }

    if (uiIcons.indexOf(icon as UiIcon) > -1) {
        return uiIconComponent(icon as UiIcon)
    }

    if (utilityIcons.indexOf(icon as UtilityIcon) > -1) {
        return utilityIconComponent(icon as UtilityIcon)
    }

    return () => null
}

export default Icon
