import styled from 'styled-components'
import HandleComponent from './handle'

export const HANDLE_WIDTH = 34
export const HANDLE_MID = HANDLE_WIDTH / 2

export const Handle = styled(HandleComponent)`
    flex-shrink: 0;
    width: ${HANDLE_MID}px;
`

export const Slider = styled.div`
    align-items: center;
    border-radius: inherit;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
`
