import { IGif } from '@giphy/js-types'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-use'
import { webFetch } from 'shared/api'
import { AccountsManagerContext } from 'shared/contexts/accounts-manager'
import ModalContext from 'shared/contexts/modal'
import UserContext from 'shared/contexts/user'
import useAsyncEffect from 'shared/hooks/use-async-effect'

import routes from 'shared/routes'
import Avatar from './avatar'
import NetworkChannels from './network-channels'
import {
    Caret,
    Container,
    Divider,
    Gif,
    LoginOnly,
    Menu,
    MenuContainer,
    UserContainer,
    UserIcon,
    Username,
} from './style'

const LoggedInMenu = () => {
    const { openCollectionCabinet } = useContext(ModalContext)
    const { user } = useContext(UserContext)
    const { channel_access } = useContext(AccountsManagerContext)

    useEffect(() => {
        const url = new URL(location.href)
        if (url.searchParams.get('openCollectionCabinet') === 'true') {
            openCollectionCabinet()
        }
    }, [])

    if (!user.permissions) {
        return null
    }

    const {
        isStaff,
        permissions: { dashboard_analytics, access_dvr },
    } = user

    return (
        <MenuContainer hasChannelAccounts={!!channel_access}>
            <Menu>
                <i className="ss-dropdown" />
                {dashboard_analytics && (
                    <li>
                        <a href={routes.DASHBOARD.path}>Dashboard</a>
                    </li>
                )}
                <li>
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault()
                            openCollectionCabinet()
                        }}
                    >
                        Collections
                    </a>
                </li>
                <li>
                    <a href={routes.FAVORITES.path}>Favorites</a>
                </li>
                {isStaff && (
                    <li>
                        <a href="/edit/channels">Channels</a>
                    </li>
                )}
                {(access_dvr || isStaff) && (
                    <li>
                        <a href="/supercut">Super Cut</a>
                    </li>
                )}
                {!!channel_access && <NetworkChannels user={user} channel_access={channel_access} />}
                <Divider />
                <li>
                    <a href={routes.SETTINGS.path}>Settings</a>
                </li>
                <li>
                    <a href="/logout">Log Out</a>
                </li>
            </Menu>
        </MenuContainer>
    )
}

const LoggedOutMenu = () => {
    return (
        <MenuContainer hasChannelAccounts={false}>
            <Menu>
                <li>
                    <a href="/login">Login</a>
                </li>
                <li>
                    <a href="/join">Join</a>
                </li>
                <li>
                    <a href={routes.FAVORITES.path}>Favorites</a>
                </li>
            </Menu>
        </MenuContainer>
    )
}

const GifAvatar = () => {
    const [gif, setGif] = useState<IGif | undefined>()
    useAsyncEffect(async () => {
        // TODO randomize and have cooler dummy avatar GIFs than just the eyes
        const { data: gif } = await webFetch.gif('sDWOPiXURPdxiLlwpA')
        setGif(gif)
    }, [])
    return gif ? <Gif width={36} height={36} gif={gif} hideAttribution noLink /> : null
}

type Props = {
    isSupercut: boolean
}

const Login = ({ isSupercut = false }: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>()
    const { user, localFavorites = [] } = useContext(UserContext)
    const { displayName, username } = user
    const { pathname } = useLocation()
    const isLogin = pathname === `/login`
    const isJoin = pathname === `/join`
    if (isLogin || isJoin) {
        return null
    }
    const justLogin = !user.loggedIn && localFavorites.length === 0
    return (
        <Container
            isSupercut={isSupercut}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {justLogin ? (
                <LoginOnly as="a" href="/login">
                    <UserIcon>
                        <div className="ss-user" />
                    </UserIcon>
                    <Username>Log in</Username>
                </LoginOnly>
            ) : (
                <>
                    <UserContainer
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (user.loggedIn) {
                                document.location.href = `/channel/${username}`
                            }
                        }}
                    >
                        {user.loggedIn ? <Avatar user={user} /> : <GifAvatar />}
                        <Username>{displayName || username || 'GIPHY User'}</Username>
                        <Caret className={isHovering ? 'ss-navigateup' : 'ss-navigatedown'} />
                    </UserContainer>
                    {user.loggedIn ? <LoggedInMenu /> : <LoggedOutMenu />}
                </>
            )}
        </Container>
    )
}

export default Login
