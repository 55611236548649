import { FC, useContext } from 'react'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { OffscreenContext } from 'mobile/components/router'

const Container = styled.div<{ isHidden: boolean }>`
    margin: 0 0 100px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    ${(props) =>
        props.isHidden &&
        css`
            display: none;
        `}
`

type Props = {
    cacheOffScreen?: boolean
    children?: React.ReactNode
}
const Page: FC<Props> = ({ children, cacheOffScreen = true }) => {
    const isOffScreen = useContext(OffscreenContext)
    if (isOffScreen && !cacheOffScreen) {
        // don't cache this view off screen, destroy it
        return null
    }
    return <Container isHidden={isOffScreen && cacheOffScreen}>{children}</Container>
}

export default Page
