import { uniqueId } from 'lodash'
import { useContext, useEffect, useRef } from 'react'
import { PortalAlertContext } from '../portal-alert/context'
import { AlertData } from '../types'

type AlertHookData = {
    isOpen: boolean
    closeAlert: () => void
    openAlert: () => void
}

export function useAlert(data: AlertData): AlertHookData {
    const idRef = useRef<string>(uniqueId('portal-alert-'))
    const {
        activeId,
        closeAlert,
        openAlert: contextOpenAlert,
        registerAlert,
        unregisterAlert,
    } = useContext(PortalAlertContext)

    useEffect(() => {
        registerAlert(idRef.current, data)

        return () => {
            unregisterAlert(idRef.current)
        }
    }, [idRef])

    useEffect(() => {
        registerAlert(idRef.current, data)
    }, [data.closeable, data.description, data.stickerUrl, data.title])

    function openAlert() {
        contextOpenAlert(idRef.current)
    }

    return {
        isOpen: idRef.current === activeId,
        closeAlert,
        openAlert,
    }
}
