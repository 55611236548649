import { giphyBlack } from '@giphy/colors'
import { CSSProperties, ReactNode } from 'react'
import { ControlSize } from 'types'
import Icon from '../../../../types/icons'
import { IconButtonProps } from '../../icon-button'
import { Button, Header, Label, LabelText } from './style'

type Props = {
    buttonOptions?: Partial<IconButtonProps>
    className?: string
    color: string
    icon?: Icon
    isOpen?: boolean
    label?: ReactNode
    size?: ControlSize
    style?: CSSProperties
    textColor?: string
    onToggle?: (open: boolean) => void
}

const CollapseHeader = ({
    buttonOptions,
    className,
    color,
    icon = 'navigate-up',
    isOpen = false,
    label,
    size,
    style,
    textColor,
    onToggle,
}: Props) => {
    return (
        <Header $color={color} $textColor={textColor} className={className} style={style}>
            <Label>{typeof label === 'string' ? <LabelText>{label}</LabelText> : label}</Label>
            <Button
                $open={isOpen}
                color={giphyBlack}
                iconScale={0.8}
                {...buttonOptions}
                icon={icon}
                size={size}
                onClick={() => onToggle?.(!isOpen)}
            />
        </Header>
    )
}

export default CollapseHeader
