import { createContext } from 'react'
import { IHoverImage } from './types'

type AboutPageContextProps = {
    hoverImages: IHoverImage[]
    addHoverImage: (image: IHoverImage) => void
    clearHoverImages: () => void
    windowHeight: number
    windowWidth: number
}

export const AboutPageContext = createContext({} as AboutPageContextProps)
