import { giphyBlack, giphyYellow } from '@giphy/colors'
import { IGif, IProfileUser } from '@giphy/js-types'
import resizeAvatar from 'shared/util/resize-avatar'
import styled from 'styled-components'
import { ClipboardButton, FavoriteOverlay } from '../gif-menu-overlay'

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
`

const HoverComponents = styled.div<{ isHovered: boolean }>`
    transition: opacity 150ms ease-in;
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
`

const ButtonDiv = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
`

const Icon = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 11px;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 15px;
    color: ${giphyYellow};
`

const Avatar = styled.img`
    position: absolute;
    bottom: 0;
    height: 32px;
    width: 32px;
    object-fit: cover;
    margin-bottom: 8px;
    margin-left: 8px;
    box-shadow: 4px 4px 24px ${giphyBlack};
`

const TrendingOverlay = ({ gif, isHovered }: { gif: IGif; isHovered: boolean }) => (
    <Container>
        <HoverComponents isHovered={isHovered}>
            <ButtonDiv>
                <ClipboardButton gif={gif} />
                <FavoriteOverlay gif={gif} isHovered={isHovered} />
            </ButtonDiv>
            {gif.user && (gif.user as IProfileUser).profile_url ? (
                <div
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        location.href = (gif.user as IProfileUser).profile_url.slice(0, -1)
                    }}
                >
                    {isHovered && <Avatar src={resizeAvatar(gif.user.avatar_url, 36)} />}
                </div>
            ) : null}
        </HoverComponents>
        {gif.is_scheduled ? <Icon className="ss-clock" /> : null}
    </Container>
)

export default TrendingOverlay
