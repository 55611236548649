import { ReactNode } from 'react'
import { useDroppable } from '@dnd-kit/core'

type Props = {
    id: string
    children: ReactNode
}

const Droppable = ({ id, children }: Props) => {
    const { setNodeRef } = useDroppable({
        id: id,
    })
    return <div ref={setNodeRef}>{children}</div>
}

export default Droppable
