import { useContext, useState, useEffect, useCallback } from 'react'
import { SearchBar } from 'shared/components/search-bar'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import styled from 'styled-components'
import AddToCollection from './add-to-collection'
import UserContext from 'shared/contexts/user'
import { search } from 'shared/api/channels'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { VerifiedLogo } from 'shared/components/users/verified-badge'
import { ResponseError } from 'shared/api/errors'
import { CollectionStyleContext } from './context/collection-style-context'

const ChannelBoxContainer = styled.div`
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    cursor: text;
    font-size: 17px;
    font-weight: bold;
    align-items: center;
`

const ChannelBox = styled.div`
    display: flex;
    align-items: center;
    margin-left: 5px;
    img {
        height: 30px;
        width: 30px;
        margin-right: 7px;
        border-radius: 2px;
    }
    span {
        margin-right: 7px;
    }
`

const VerifiedBadge = styled.div`
    width: 17px;
    height: 17px;
`

type Props = {
    onClickChannel?: (channelId: number, searchChannelId: number) => void
    onClickAddTo?: (channelId: number, searchChannelId: number) => void
}

type ChannelBoxProps = {
    avatar: string
    displayName: string
    isVerified: boolean
}

const CollectionsEditor = ({ onClickChannel, onClickAddTo }: Props) => {
    const { user } = useContext(UserContext)
    const { showSearch } = useContext(CollectionStyleContext)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [showChannelBox, setShowChannelBox] = useState<boolean>(true)
    const [channelBoxProps, setChannelBoxProps] = useState<ChannelBoxProps>()

    const { activeChannelId, setActiveChannelId, loadChannels, channels, rootChannelId } = useContext(CollectionContext)

    useEffect(() => {
        if (!activeChannelId || activeChannelId === rootChannelId) {
            setChannelBoxProps({
                avatar: channels[rootChannelId].user.avatar_url,
                displayName: channels[rootChannelId].user.display_name,
                isVerified: channels[rootChannelId].user.is_verified,
            })
        }
    }, [activeChannelId, searchTerm])

    useAsyncEffect(async () => {
        if (!searchTerm) {
            setActiveChannelId(rootChannelId)
            return
        }
        // conduct search
        try {
            const channel = await search(searchTerm)
            if (channel.display_name) {
                setChannelBoxProps({
                    avatar: channel.user.avatar_url,
                    displayName: channel.user.display_name,
                    isVerified: channel.user.is_verified,
                })
                setActiveChannelId(channel.id)
                loadChannels(channel.id)
                setShowChannelBox(true)
            } else {
                setActiveChannelId(rootChannelId)
            }
        } catch (e) {
            if (e === ResponseError) {
                setActiveChannelId(rootChannelId)
            }
        }
    }, [searchTerm])

    const onFocus = () => {
        setShowChannelBox(false)
    }

    const onBlur = () => {
        setShowChannelBox(true)
    }

    const ChannelIcon = useCallback(() => {
        return (
            <ChannelBoxContainer>
                <ChannelBox>
                    <img src={`${channelBoxProps?.avatar}`} alt={''} />
                    <span>{channelBoxProps?.displayName}</span>
                    <VerifiedBadge>
                        <VerifiedLogo />
                    </VerifiedBadge>
                </ChannelBox>
            </ChannelBoxContainer>
        )
    }, [channelBoxProps])

    return (
        <>
            {showSearch && user.permissions.manage_all_subchannels && (
                <SearchBar
                    onChange={(value) => setSearchTerm(value)}
                    showChannelBox={showChannelBox}
                    channelBox={<ChannelIcon />}
                    placeholder={!showChannelBox ? 'Search Channels' : ''}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            )}
            {onClickChannel && <AddToCollection onClickChannel={onClickChannel} onClickAddTo={onClickAddTo} />}
        </>
    )
}

export default CollectionsEditor
