import { giphyIndigo, giphyRed, giphyYellow } from '@giphy/colors'
import { Attribution } from '@giphy/react-giphy-brand'
import { distanceInWordsToNow } from 'date-fns'
import styled from 'styled-components'

const GifSelectionOverlay = ({ gif, index = -1, showDetails = false, onClick }) => {
    const isSelected = index !== -1
    const hasUser = !!gif.user
    const style = !isSelected ? gif.style || {} : null
    const isPrivate = hasUser && !gif.user.is_public
    return (
        <div>
            <Overlay
                isSelected={isSelected}
                showDetails={showDetails}
                style={style}
                data-gifurl={gif.url}
                onClick={(e) => onClick(gif, e)}
            >
                {showDetails && (
                    <Details
                        padded={hasUser}
                        title={`Uploaded ${distanceInWordsToNow(new Date(gif.create_datetime))} ago`}
                    >
                        {gif.rating && <RatingLabel>{gif.rating.toUpperCase()}</RatingLabel>}
                        {hasUser && (
                            <AttributionWrapper>
                                <Attribution user={gif.user} />
                                {isPrivate && <LockIcon className="ss-lock" />}
                            </AttributionWrapper>
                        )}
                    </Details>
                )}
            </Overlay>
            {!showDetails && isSelected && <IndexLabel>{index + 1}</IndexLabel>}
        </div>
    )
}

const Overlay = styled.div<{ isSelected: boolean; showDetails: boolean }>`
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: ${(props) => (props.isSelected ? `6px solid ${giphyIndigo}` : '6px solid transparent')};
    background: ${(props) => (props.showDetails ? 'rgba(0, 0, 0, 0.4)' : 'transparent')};
`

const IndexLabel = styled.div`
    font-family: 'NexaBlack';
    pointer-events: none;
    background-color: ${giphyYellow};
    color: black;
    font-weight: 900;
    font-size: 18px;
    height: 34px;
    width: 34px;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 34px;
    text-align: center;
`

const RatingLabel = styled.div`
    pointer-events: none;
    color: #fff;
    font-weight: 900;
    font-size: 36px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Details = styled.div<{ padded: boolean }>`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    padding-bottom: ${(props) => (props.padded ? '48px' : '0px')};
`

const AttributionWrapper = styled.div`
    bottom: 6px;
    left: 6px;
    position: absolute;
    transform: scale(0.8);
    transform-origin: left bottom;
`

const LockIcon = styled.div`
    background-color: rgba(0, 0, 0, 0.66);
    color: ${giphyRed};
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 42px;
    font-size: 18px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
`

export default GifSelectionOverlay
