import { giphyBlack } from '@giphy/colors'
import { createContext, useState } from 'react'
import styled from 'styled-components'
import { Logo } from 'ui'
import Nav from '../../components/nav'
import MenuState from '../../containers/menu'

const margin = 6

const containerPadding = `${margin * 2}px 0 ${margin}px ${margin}px;`

const Container = styled.div`
    position: relative;
    z-index: 1001;
    padding: ${containerPadding};
    background: ${giphyBlack};
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
`

type MenuState = undefined | 'user' | 'logged out user' | 'category'
type HeaderContextProps = {
    menu: MenuState
    setMenuState: (menu: MenuState) => void
    setIsSearchVisible: (visible: boolean) => void
    isSearchVisible: boolean
}
export const HeaderContext = createContext({} as HeaderContextProps)

const Header = () => {
    const [menu, setMenuState] = useState<MenuState>(undefined)
    const [isSearchVisible, setIsSearchVisible] = useState(false)
    return (
        <>
            <HeaderContext.Provider
                value={{
                    menu,
                    setMenuState,
                    isSearchVisible,
                    setIsSearchVisible,
                }}
            >
                <Container>
                    <HeaderContainer>
                        <Logo />
                        <Nav />
                    </HeaderContainer>
                    <MenuState />
                </Container>
            </HeaderContext.Provider>
        </>
    )
}

export default Header
