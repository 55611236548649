import { giphyDarkestGrey, giphyLightGrey } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { without } from 'lodash'
import { useEffect, useState } from 'react'
import { Button } from 'shared/components/buttons'
import { Subheader } from 'shared/components/text-elements'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
    margin: 0px 0px 40px;
    width: 100%;
`

const Title = styled(Subheader)`
    color: ${giphyLightGrey};
    font-size: 14px;
    margin-bottom: 6px;
`

const flyUp = (amount) => keyframes`
    0% {
        transform: translate(0,0})
    }
    100% {
        transform: translate(0,${amount}px)
    }
`

const Image = styled.div`
    position: relative;
    display: block;
    height: auto;
    height: 138px;
    background-color: grey;
    pointer-events: none;
    width: 100%;
    margin: 14px 0px;
    background-image: url('/static/img/giphy-arcade-small-grid.png');
    overflow: hidden;
`

const MiniSticker = styled.img`
    object-fit: contain;
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 1;

    &:nth-child(2) {
        bottom: -100px;
        left: 2px;
        animation: ${flyUp(-400)} 5s linear infinite;
        animation-delay: 1.25s;
    }

    &:nth-child(3) {
        bottom: -100px;
        right: 2px;
        animation: ${flyUp(-400)} 5s linear infinite;
        animation-delay: 2.5s;
    }

    &:nth-child(4) {
        bottom: -100px;
        left: 5px;
        animation: ${flyUp(-400)} 5s linear infinite;
        animation-delay: 3.75s;
    }

    &:nth-child(5) {
        bottom: -100px;
        left: 50%;
        transform: translate(-50%);
        animation: ${flyUp(-400)} 5s linear infinite;
        animation-delay: 5s;
    }
`

const ArcadeLogo = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const LearnMore = styled(Button)`
    background: ${giphyDarkestGrey};
    display: block;
    width: 100%;
`

type Props = {
    gif: IGif
    relatedStickers: IGif[]
    remix?: boolean
}

const ArcadeCTA = ({ gif, relatedStickers, remix }: Props) => {
    const [miniStickerURLs, setMiniStickerURLs] = useState<string[]>([])

    useEffect(() => {
        const mainStickerURL = `https://media.giphy.com/media/${gif.id}/100.gif`
        const stickerURLs: string[] = []
        for (let i = 0; i < 4; i++) {
            stickerURLs.push(
                i === 0 || !relatedStickers[i - 1]
                    ? mainStickerURL
                    : `https://media.giphy.com/media/${relatedStickers[i - 1].id}/100.gif`
            )
        }

        setMiniStickerURLs(stickerURLs)
    }, [gif, relatedStickers])

    const getSeedWord = (gif) => {
        // Determine a 'seed word' for the game's title. Use the first tag if it exists.
        let seedWord
        let sanitizedTags = without(gif.tags, 'transparent')
        let shortSanitizedTags = sanitizedTags.filter((tag) => tag.length < 7)
        if (shortSanitizedTags.length) {
            seedWord = shortSanitizedTags[0]
        } else {
            seedWord = 'none'
        }
        return seedWord
    }

    const determineGifCount = (relatedStickers: IGif[]) => {
        if (relatedStickers?.length < 3) {
            return relatedStickers.length
        } else {
            return 3
        }
    }

    const createGame = (seedWord: string) => {
        // Take the first three related GIFs, or, if fewer than that, however many there are.
        let gifsToTake = determineGifCount(relatedStickers)

        // TODO: Make sure to test gifs with no related gifs!
        let gif_ids_string = [gif, ...relatedStickers]
            .slice(0, gifsToTake + 1)
            .map((g) => g.id)
            .toString()
        let url = 'http://arcade.giphy.com/newgame/' + seedWord + '/?ids=' + gif_ids_string
        // Bounce to arcade!
        window.location.href = url
    }

    const createRemix = () => {
        // Take the first three related GIFs, or, if fewer than that, how ever many there are.
        let gifsToTake = determineGifCount(relatedStickers)

        // TODO: Make sure to check gifs with no related gifs!
        let gif_ids_string = [gif, ...relatedStickers]
            .slice(0, gifsToTake + 1)
            .map((g) => g.id)
            .toString()
        let url = 'http://arcade.giphy.com/newremix?ids=' + gif_ids_string
        // Bounce to arcade!
        window.location.href = url
    }

    // Get a seed word for use in remixing
    const seedWord = getSeedWord(gif)
    const capitalizedSeedWord = seedWord.charAt(0).toUpperCase() + seedWord.slice(1)
    let ctaText = 'Play games on GIPHY Arcade'
    if (seedWord !== 'none') {
        ctaText = capitalizedSeedWord + ' games on GIPHY Arcade'
    }

    return (
        <Container>
            <Title>{remix ? 'Create your own GIPHY Arcade game' : ctaText}</Title>
            <Image>
                <ArcadeLogo src="/static/img/giphy-arcade-logo-transparent.png" />
                {miniStickerURLs.map((url, i) => (
                    <MiniSticker key={`ministicker${i}`} src={url} />
                ))}
            </Image>
            <LearnMore onClick={remix ? () => createRemix() : () => createGame(seedWord)}>
                {remix ? 'Create Now' : 'Play Now'}
            </LearnMore>
        </Container>
    )
}

export default ArcadeCTA
