import { giphyLightCharcoal } from '@giphy/colors'
import { useContext } from 'react'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { IChannel } from 'shared/types/channel'
import { getWebpOrUrl } from 'shared/util/gif-extract'
import styled from 'styled-components'
import { Item, ItemGif, ItemQuantityBox, ItemText } from './sc'

const LoadingItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 27px;
    cursor: pointer;
    user-select: none;
    color: ${giphyLightCharcoal};
`

type Props = {
    channel: IChannel
    isDragging?: boolean
    isDisabled?: boolean
}

export const LoaderListItem = () => {
    return <LoadingItem>Loading...</LoadingItem>
}

const ChannelListItem = ({ channel }: Props) => {
    const { setActiveChannelId } = useContext(CollectionContext)
    const webp = getWebpOrUrl(channel.featured_gif)

    return (
        <Item
            onClick={() => {
                setActiveChannelId(channel.id)
            }}
            gutter
        >
            {channel.has_children && <ItemQuantityBox>{channel.num_children}</ItemQuantityBox>}
            <ItemGif src={webp} alt={channel?.featured_gif?.title} />
            <ItemText background="#6157ff">{channel.display_name}</ItemText>
        </Item>
    )
}

export default ChannelListItem
