import { IGif } from '@giphy/js-types'
import { flatten, intersection, map, uniq } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { addLanguageTags, fetchLanguageTags, removeLanguageTag } from 'shared/api/index'
import EditPanelContext from 'shared/components/edit-panel/context'
import SuggestedTagWrapper from 'shared/components/suggested-tags'
import TaggingTips from 'shared/components/suggested-tags/tagging-tips'
// @ts-ignore
import { deleteColor, dividerColor, giphyBlack, giphyGreen, giphyGrey, giphyIndigo } from 'shared/css/colors.css'
import { message, SendMessageAction } from 'shared/redux/message'
import { TAG_LIMIT } from 'shared/variables'
import styled, { css as styledCss } from 'styled-components'
import { LoggedInUser, UserPermissions } from 'types'
import TextInput from '../../inputs/text'
import LanguageSelect from './language-support/language-select'
// @ts-ignore
import css from './tags.css'

const getLanguageTags = async (gifIds: string[], tagLanguage: string): Promise<TagMeta[] | []> => {
    const isMultipleGifs = gifIds.length > 1
    const tagsData = await fetchLanguageTags(gifIds, tagLanguage)

    if (tagsData.gifs) {
        const gifTags = tagsData.gifs.map((g) => g.tags.map((t) => t.text))
        const allTags: string[] = uniq(flatten(gifTags))
        const commonTags = gifTags.length !== gifIds.length ? [] : intersection(...gifTags)

        return allTags.map((tag) => ({
            tag: tag,
            isCommon: isMultipleGifs && commonTags.indexOf(tag) > -1,
            isFeatured: false,
        }))
    }

    return []
}

const TagLanguages = {
    EN: '🇬🇧  English (en)',
    KO: '🇰🇷  Korean (ko)',
    PT: '🇵🇹  Portuguese (pt)',
    RU: '🇷🇺  Russian (ru)',
    ES: '🇪🇸  Spanish (es)',
    JA: '🇯🇵  Japanese (ja)',
    AR: '🇸🇦  Arabic (ar)',
    BN: '🇧🇩  Bengali (bn)',
    ZH_CN: '🇨🇳  Chinese Simplified (zh-CN)',
    ZH_TW: '🇹🇼  Chinese Traditional (zh-TW)',
    CS: '🇨🇿  Czech (cs)',
    DA: '🇩🇰  Danish (da)',
    NL: '🇳🇱  Dutch (nl)',
    FA: '🇮🇷  Farsi (fa)',
    FI: '🇫🇮  Finnish (fi)',
    FR: '🇫🇷  French (fr)',
    DE: '🇩🇪  German (de)',
    IW: '🇮🇱  Hebrew (iw)',
    HI: '🇮🇳  Hindi (hi)',
    HU: '🇭🇺  Hungarian (hu)',
    ID: '🇮🇩  Indonesian (id)',
    IT: '🇮🇹  Italian (it)',
    MS: '🇲🇾  Malay (ms)',
    NO: '🇳🇴  Norwegian (no)',
    PL: '🇵🇱  Polish (pl)',
    RO: '🇷🇴  Romanian (ro)',
    SV: '🇸🇪  Swedish (sv)',
    TH: '🇹🇭  Thai (th)',
    TL: '🇵🇭  Tagalog (tl)',
    TR: '🇹🇷  Turkish (tr)',
    UK: '🇺🇦  Ukranian (uk)',
    VI: '🇻🇳  Vietnamese (vi)',
}

const TagLanguagesAlt = map(TagLanguages, (value, key) => {
    return { name: value, value: key }
})

const Tag = styled.div<{
    isCommon: boolean
    bulkEditMode: boolean
    isMultipleGifs: boolean
    isFeatured: boolean
    isTagFeaturable: boolean
    permissions: UserPermissions
}>`
    background: ${dividerColor};
    color: ${giphyGrey};
    border: ${(props) => (props.isCommon ? `1px solid ${giphyIndigo}` : 'none')};
    border-radius: 18px;
    display: ${(props) => (props.bulkEditMode ? 'block' : 'inline-block')};
    font-style: italic;
    height: 36px;
    line-height: 36px;
    margin: ${(props) => (props.bulkEditMode ? '0 0 6px 0;' : '0 8px 6px 0')};
    text-transform: none;
    user-select: none;

    ${(props) =>
        (props.isCommon || !props.isMultipleGifs) &&
        props.permissions.feature_tags &&
        props.isTagFeaturable &&
        styledCss`
        background: ${props.isFeatured ? giphyGreen : dividerColor};
        color: ${props.isFeatured ? giphyBlack : giphyGrey};
        cursor: pointer;

        &:hover {
            background: ${props.isFeatured ? dividerColor : giphyGreen};
            color: ${props.isFeatured ? giphyGrey : giphyBlack};
        }
    `};
`

const TagsContainer = styled.div`
    margin-bottom: 20px;
`

const LanguageContainer = styled.div<{
    bulkEditMode: boolean
}>`
    display: inline-block;

    ${(props) =>
        props.bulkEditMode
            ? styledCss`
        width: 100%;
        `
            : styledCss`
            width: 242px;
        `};
`
interface TagMeta {
    tag: string
    isCommon: boolean
    isFeatured: boolean
}

type Props = {
    className: string
    user: LoggedInUser
    gifs: IGif[]
    name: string
    tagCounts: object
    isMultipleGifs: boolean
    onAddTags: (tags: string[]) => void
    onRemoveTag: (tag: string) => void
    onRemoveAllTags: () => void
    onFeatureTag: (tag: string) => void
    onUnfeatureTag: (tag: string) => void
    fixedWidth: boolean
    gaEventCategory: string
    tags: TagMeta[]
}

type ReduxProps = {
    message: SendMessageAction
}

const EditPanelTags = ({
    className,
    user,
    tagCounts,
    onFeatureTag,
    onUnfeatureTag,
    name,
    isMultipleGifs,
    gifs,
    onRemoveTag,
    onAddTags,
    fixedWidth,
    gaEventCategory,
    tags,
    message,
}: Props & ReduxProps) => {
    const { bulkEditMode } = useContext(EditPanelContext)
    const [tags_, setTags] = useState<TagMeta[]>(tags)
    const [tagLanguage, setTagLanguage] = useState<string>('EN')
    const [lastEnteredTags, setLastEnteredTags] = useState<string[]>([])
    const { permissions } = user
    const isTagFeaturable = tagLanguage === 'EN'

    // Permissions is being set by redux and is not being refreshed with updated permissions. Therefore, we
    // are fetching permissions off the User object being passed in.
    const showSuggestedTags = permissions.view_suggested_tags || user.isStaff
    const gifIds = gifs.map((x) => x.id.toString())

    useEffect(() => {
        updateTags()
    }, [])

    useEffect(() => {
        updateTags()
    }, [tags, tagLanguage])

    const updateTags = async () => {
        let languageTags: TagMeta[] = []

        if (tagLanguage !== 'EN') {
            try {
                languageTags = await getLanguageTags(gifIds, tagLanguage)
            } catch (error) {
                message(`There was a problem fetching ${tagLanguage} tags.`, 'error')
            }
        } else {
            languageTags = tags
        }
        setLastEnteredTags(languageTags.slice(0, 2).map((i) => i.tag))
        setTags(languageTags)
    }

    const onRemove = (tag) => {
        if (tagLanguage === 'EN') {
            onRemoveTag(tag)
        } else {
            for (const id of gifIds) {
                removeLanguageTag(id, tag, tagLanguage).then(() => updateTags())
            }
        }
    }

    const onAdd = (newTag: string, isSuggested = false) => {
        const tagExists = tags_.find((t) => t.tag === newTag && t.isCommon)

        if (tagExists) {
            message('That tag already exists!', 'error')
            return
        }

        let newTags = newTag.split(',')
        newTags = uniq(newTags.map((tag) => tag.replace(/#/g, '').trim()))

        if (newTags.length + tags_.length > TAG_LIMIT && !user.isStaff) {
            message('Sorry! Max number of tags reached.', 'error')
            return
        }

        if (tagLanguage === 'EN') {
            onAddTags(newTags)
        } else {
            for (const id of gifIds) {
                addLanguageTags(id, newTags, tagLanguage).then(() => updateTags())
            }
        }
        if (!isSuggested) {
            setLastEnteredTags(newTags)
        }
    }

    const onTagLanguageChange = (lang) => {
        setTagLanguage(lang || 'EN')
    }

    return (
        <div className={className}>
            {name && <span className={css.name}>{name}</span>}
            <div className={!bulkEditMode ? css.headerFluid : css.header}>
                {bulkEditMode && (
                    <div className={css.tagsHeader}>
                        <span>Tags</span>
                        <TaggingTips isVertical={bulkEditMode} />
                    </div>
                )}
                <TextInput
                    className={!fixedWidth ? css.tagInputFluid : css.tagInput}
                    type="text"
                    autoSelect={true}
                    placeholder={'Add a tag'}
                    buttonLabel={<i className={css.inputAdd} />}
                    onSubmit={onAdd}
                />
                {isMultipleGifs && (
                    <TextInput
                        className={!fixedWidth ? css.tagInputFluid : css.tagInput}
                        type="text"
                        autoSelect={true}
                        placeholder={'Remove a tag'}
                        buttonLabel={<i className={css.inputRemove} />}
                        buttonColor={deleteColor}
                        onSubmit={onRemove}
                    />
                )}

                {permissions.edit_language_tags && (
                    <LanguageContainer bulkEditMode={bulkEditMode}>
                        <LanguageSelect
                            options={TagLanguagesAlt}
                            defaultValue="EN"
                            onChange={(value) => onTagLanguageChange(value)}
                        />
                    </LanguageContainer>
                )}
                {!bulkEditMode && <TaggingTips isVertical={true} />}
            </div>
            <TagsContainer>
                {tags_.length >= 1 &&
                    tags_.map(({ tag, isCommon, isFeatured }) => (
                        <Tag
                            key={tag}
                            isCommon={isCommon}
                            isFeatured={isFeatured}
                            permissions={permissions}
                            isMultipleGifs={isMultipleGifs}
                            isTagFeaturable={isTagFeaturable}
                            onClick={() => {
                                if (isTagFeaturable && permissions.feature_tags) {
                                    if (isMultipleGifs && !isCommon) {
                                        return
                                    }
                                    isFeatured ? onUnfeatureTag(tag) : onFeatureTag(tag)
                                }
                            }}
                            bulkEditMode={bulkEditMode}
                        >
                            <span className={css.label}>
                                {tag.length > 28 ? `${tag.slice(0, 28)}...` : tag}{' '}
                                {tagCounts[tag] && (
                                    <span className={css.tagCount}>{tagCounts[tag].toLocaleString()}</span>
                                )}
                            </span>
                            <i
                                className={css.remove}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onRemove(tag)
                                }}
                            />
                        </Tag>
                    ))}
            </TagsContainer>
            {showSuggestedTags && gifIds.length === 1 && (
                <SuggestedTagWrapper
                    currentTags={tags_}
                    lastEnteredTags={lastEnteredTags}
                    endpoint={gaEventCategory}
                    gifIds={gifIds}
                    addSugTag={(tag) => onAdd(tag, true)}
                    isVertical={bulkEditMode}
                />
            )}
        </div>
    )
}

export default connect(undefined, { message })(EditPanelTags)
