import { useState, useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import { modalZIndex } from '../constants'

const Container = styled.div<{ noCenter: boolean }>`
    z-index: ${modalZIndex};
    position: ${(props) => (props.noCenter ? 'absolute' : 'fixed')};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.8);
    display: ${(props) => (props.noCenter ? 'inline' : 'flex')};
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`

const defaultElementId = 'react-target'

type Props = {
    elementId?: string
    noCenter?: boolean
    scrollToTop?: boolean
    className?: string
    children?: ReactNode
}

const Modal = ({
    elementId = defaultElementId,
    noCenter = false,
    scrollToTop = true,
    className = '',
    children,
}: Props) => {
    const [modalElement, setModalElement] = useState<HTMLElement | null>(null)
    const [currentOverflow, setCurrentOverflow] = useState<string>('')
    useEffect(() => {
        setModalElement(document.getElementById(elementId))
        if (scrollToTop) {
            setCurrentOverflow(document.body.style.overflow)
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
            document.body.style.overflow = 'hidden'
        }
        return () => {
            if (scrollToTop) {
                document.body.style.overflow = currentOverflow
            }
        }
    }, [modalElement, elementId])

    return (
        <Container noCenter={noCenter} className={className}>
            {children}
        </Container>
    )
}

export default Modal
