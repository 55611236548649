import { giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, ForwardedRef, forwardRef, isValidElement, ReactNode, SyntheticEvent } from 'react'
import { ControlSize, Gradient } from 'types'
import { Button as ButtonComponent, Icon, Label, Loader } from './style'

export type ButtonProps = {
    children?: ReactNode
    className?: string
    color?: string
    disabled?: boolean
    gradient?: Gradient
    href?: string
    icon?: ReactNode
    iconLocation?: 'left' | 'right'
    loading?: boolean
    loadingColor?: string
    role?: string
    roleDescription?: string
    size?: ControlSize
    style?: CSSProperties
    tabIndex?: number
    target?: string
    textColor?: string
    title?: string
    type?: 'button' | 'submit' | 'reset'
    iconGradient?: Gradient | undefined
    onClick?: (e: SyntheticEvent<HTMLButtonElement, Event>) => void
}

const Button = (
    {
        children,
        color = giphyDarkGrey,
        disabled = false,
        gradient,
        href,
        icon,
        iconLocation = 'right',
        loading = false,
        loadingColor,
        size = 'small',
        tabIndex,
        target,
        textColor,
        iconGradient = undefined,
        type = 'button',
        ...rest
    }: ButtonProps,
    ref: ForwardedRef<any>
) => (
    <ButtonComponent
        {...rest}
        $color={color}
        $dimmed={disabled}
        $disabled={disabled || loading}
        $gradient={gradient}
        $iconGradient={iconGradient}
        $size={size}
        $textColor={textColor}
        as={href ? 'a' : 'button'}
        href={href}
        ref={ref}
        tabIndex={disabled ? -1 : tabIndex}
        target={href ? target : undefined}
        type={href ? undefined : type}
    >
        {loading ? (
            <Loader color={loadingColor} size="small" />
        ) : (
            <>
                {icon && iconLocation === 'left' && <Icon>{icon}</Icon>}
                {children && (isValidElement(children) ? children : <Label>{children}</Label>)}
                {icon && iconLocation === 'right' && <Icon>{icon}</Icon>}
            </>
        )}
    </ButtonComponent>
)

export default forwardRef(Button)
