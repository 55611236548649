import { giphyWhite } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import VideoPreviewCarousel from 'mobile/components/video-carousel'
import { useContext, useEffect } from 'react'
import SharedCarousel from 'shared/components/carousel/carousel'
import SharedVideoPreviewPlayInline from 'shared/components/video-inline-play'
import GifContext from 'shared/contexts/gif'
import { VideoInlinePlayContext } from 'shared/contexts/video-inline-play-manager'
import { gridWidth } from 'shared/util/grid'
import isMobile from 'shared/util/is-mobile'
import styled, { keyframes } from 'styled-components'

const MARGIN = 16
const MAX_CAROUSEL_ITEMS = 4

const channelIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(60px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

const ShowClips = styled.div`
    display: block;
`

const Carousel = styled(SharedCarousel)`
    margin-bottom: 15px;
    & h2 {
        color: ${giphyWhite};
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.3px;
        font-family: InterFace;
    }
    & h3 {
        font-family: interface;
        font-weight: bold;
        box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.25);
    }
`

const VideoStyle = `
    display: inline-block;
    margin-bottom: ${MARGIN}px;
    margin-right: ${MARGIN}px;
    &:last-child {
        margin-right: 0;
    }
    
    &:nth-child(2n) {
        animation-delay: 0.05s;
    }

    &:nth-child(3n) {
        animation-delay: 0.1s;
    }

    &:nth-child(4n) {
        animation-delay: 0.15s;
    }

    &:last-of-type {
        margin: 0px;
    }
`

const VideoPreviewPlayInline = styled(SharedVideoPreviewPlayInline)`
    animation: ${channelIn} 0.3s ease-in-out 0s 1 normal backwards;
    ${VideoStyle}
`

const getItemWidth = (width: number) => {
    const margin = (MAX_CAROUSEL_ITEMS - 1) * MARGIN
    return Math.floor((width - margin) / MAX_CAROUSEL_ITEMS)
}

type Props = {
    clips: IGif[]
    title?: string
    maxItems?: number
    hideHeader?: boolean
    onClipClick?: (gif: IGif, index: number) => void
}

const ClipsCarousel = ({
    clips,
    title = 'GIPHY Clips',
    maxItems = MAX_CAROUSEL_ITEMS,
    hideHeader = true,
    onClipClick,
}: Props) => {
    const { receivedGifs } = useContext(GifContext)
    const { hoveredClip } = useContext(VideoInlinePlayContext)
    const itemWidth = getItemWidth(gridWidth)

    useEffect(() => {
        if (clips.length > 0) {
            receivedGifs({ gifs: clips })
        }
    }, [clips])

    return (
        <ShowClips>
            {isMobile() ? (
                <VideoPreviewCarousel previews={clips} hideHeader={hideHeader} onClipClick={onClipClick} />
            ) : (
                <Carousel maxBlocks={maxItems} title={title}>
                    {clips.map((gif: IGif, key) => (
                        <VideoPreviewPlayInline
                            key={key}
                            gif={gif}
                            width={itemWidth}
                            withOverlay={hoveredClip !== gif.id && hoveredClip !== null}
                            showClipTitle={false}
                            gridPosition={key + 1}
                            onClick={() => onClipClick?.(gif, key)}
                        />
                    ))}
                </Carousel>
            )}
        </ShowClips>
    )
}

export default ClipsCarousel
