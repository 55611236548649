import { giphyLightGrey } from '../../css/colors'
import styled, { keyframes, ThemeProvider, css } from 'styled-components'
import * as themes from '../themes.js'

export const dropdownUp = keyframes`
     0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

export const dropdownDown = keyframes`
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

const DropdownContainer = styled.div`
    ${(props) =>
        props.animate &&
        css`
            animation: ${(props) => (props.isExtendUp ? dropdownDown : dropdownUp)} 0.2s ease-out;
        `};
    background: ${(props) => props.theme.dropdownBackgroundColor};
    ${(props) =>
        props.shadow &&
        css`
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
        `};
    box-sizing: border-box;
    color: ${(props) => props.theme.dropdownTextColor};
    min-width: ${(props) => (props.autoSize ? 0 : 165)}px;
    padding: 15px 20px;
    position: relative;
    text-align: left;
    z-index: 2147483646;

    hr {
        background: ${giphyLightGrey};
        clear: both;
        height: 1px;
        margin: 10px 0;
        width: 100%;
    }
`

export const Nip = styled.div`
    border-bottom: 6px solid ${(props) => props.theme.dropdownBackgroundColor};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    height: 0;
    margin-left: -6px;
    position: absolute;
    top: ${(props) => (props.isExtendUp ? null : '-6px')};
    bottom: ${(props) => (props.isExtendUp ? '-6px' : null)};
    transform: ${(props) => (props.isExtendUp ? 'rotate(180deg) translateY(1px)' : null)};
    width: 0;
`

const Dropdown = ({
    isExtendUp,
    children,
    autoSize = false,
    nipX = '50%',
    hasNip = true,
    shadow = true,
    animate = true,
    theme = 'light',
}) => (
    <ThemeProvider theme={themes[theme]}>
        <DropdownContainer animate={animate} autoSize={autoSize} shadow={shadow}>
            {hasNip && <Nip isExtendUp={isExtendUp} style={{ left: nipX }} />}
            {children}
        </DropdownContainer>
    </ThemeProvider>
)

export default Dropdown
