import { giphyBlue, giphyGreen, giphyPurple, giphyRed, giphyYellow } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { LoaderSize } from '../types'
import { borderRadiusFromLoaderSize, heightFromLoaderSize } from '../utils'

const blockAnimation = keyframes`
    0% { transform: scale(0.9) translateY(80%); }
    100% { transform: scale(1.75) translateY(-80%); }
`

export const Block = styled.div`
    animation: ${blockAnimation} cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.75s infinite alternate backwards;
    flex-shrink: 0;

    &:nth-child(1) {
        animation-delay: -0.4s;
        background: ${giphyGreen};
    }

    &:nth-child(2) {
        animation-delay: -0.3s;
        background: ${giphyBlue};
    }

    &:nth-child(3) {
        animation-delay: -0.2s;
        background: ${giphyPurple};
    }

    &:nth-child(4) {
        animation-delay: -0.1s;
        background: ${giphyRed};
    }

    &:nth-child(5) {
        background: ${giphyYellow};
    }
`

export const Container = styled.div<{ loaderSize: LoaderSize }>`
    align-items: center;
    display: flex;
    justify-content: center;

    ${({ loaderSize }) => {
        const size = heightFromLoaderSize(loaderSize)

        return css`
            ${Block} {
                border-radius: ${borderRadiusFromLoaderSize(loaderSize)}px;
                height: ${size}px;
                margin-right: ${size}px;
                width: ${size}px;

                &:last-child {
                    margin-right: 0;
                }
            }
        `
    }}
`
