import styled from 'styled-components'
import { UserMenuItem } from '../../containers/menu'
import { Background, BackgroundWrapper, Item, nibleSize, Niblet } from './sc'

const Container = styled.div`
    animation: menuIn 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-size: 16px;
    margin: 6px -6px;
    padding-top: ${nibleSize};
    position: relative;
`

const Wrapper = styled.div`
    position: relative;
`

const UserItem = styled(Item)`
    padding: 20px;
`

type Props = { color: string; items: UserMenuItem[]; itemClick: Function }
const Menu = ({ items, itemClick, color }: Props) => {
    return (
        <Container>
            <Niblet style={{ color, right: 70 }} />
            <BackgroundWrapper>
                <Background style={{ backgroundColor: color }} />
            </BackgroundWrapper>
            <Wrapper>
                {items.map(({ path, label }, index) => (
                    <UserItem key={index} onClick={() => itemClick(path)}>
                        {label}
                    </UserItem>
                ))}
            </Wrapper>
        </Container>
    )
}

export default Menu
