import { CSSProperties, useMemo } from 'react'
import { Gradient } from 'types/src/gradient'
import { gradientToCss } from 'utils/src/gradient'

export function useHighlightStyle(
    widths: number[],
    color: string,
    index?: number,
    gradient?: Gradient
): CSSProperties | undefined {
    return useMemo(() => {
        if (typeof index === 'undefined') {
            return { display: 'none' }
        }

        const width = widths[index] || 0
        let offset = 0

        for (let i = 0; i < index; i++) {
            offset += widths[i] || 0
        }

        return {
            background: gradient ? gradientToCss(gradient) : color,
            transform: `translate3d(${offset}px, 0, 0)`,
            width,
        }
    }, [color, gradient, index, widths])
}
