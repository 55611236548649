import { FC, ReactNode, SVGProps, useContext, useEffect, useState } from 'react'
import { Button } from 'shared/components/buttons'

import Test from 'shared/components/test'
import MessageContext from 'shared/contexts/message'
import { CY_USER_SETTINGS__EMAIL_VALIDATION } from 'ui'
import CancelIcon from '../../../../modules/search-bar/components/icons/cancel'
import {
    cancelEmailChangeValidation,
    resendEmailChangeValidation,
    validateLegacy,
} from './email-verification-message.api'
import {
    EmailVerificationMessageButtons,
    EmailVerificationMessageContent,
    EmailVerificationMessageIconOnly,
    EmailVerificationMessageWrapper,
} from './email-verification-message.styles'
import CheckmarkIcon from './icons/checkmark'
import ErrorIcon from './icons/error'
import PendingIcon from './icons/pending'

type EmailVerificationMessageProps = {
    isEmailValid?: boolean
    pendingEmail?: string
    currentlyEditting?: boolean
    saveCallback?: () => any
    isSaving?: boolean
    saveResponse?: {
        pending_email?: string
    }
}

interface StatusObject {
    icon: FC<SVGProps<SVGSVGElement>>
    title: ReactNode
    message: ReactNode
    buttonText: ReactNode
    showCancel: boolean
    cancelAction?: () => void
    showButton: boolean
    buttonAction?: () => void
}

interface StatusListObject {
    ALL_GOOD: StatusObject
    PENDING_VALIDATION: StatusObject
    NOT_VALIDATED: StatusObject
    CURRENTLY_EDITTING: StatusObject
    IS_SAVING: StatusObject
    VALIDATION_PENDING: StatusObject
}

const STATUS: StatusListObject = {
    ALL_GOOD: {
        icon: CheckmarkIcon,
        title: '',
        message: '',
        buttonText: '',
        showCancel: false,
        cancelAction: () => {},
        showButton: false,
        buttonAction: () => {},
    },
    PENDING_VALIDATION: {
        icon: PendingIcon,
        title: 'Validation Pending',
        message: "We've emailed you a confirmation link",
        buttonText: 'Resend',
        showCancel: true,
        cancelAction: () => {},
        showButton: true,
        buttonAction: () => {},
    },
    NOT_VALIDATED: {
        icon: ErrorIcon,
        title: 'User email not validated',
        message: 'Please confirm email address',
        buttonText: 'Confirm',
        showCancel: false,
        cancelAction: () => {},
        showButton: true,
        buttonAction: () => {},
    },
    CURRENTLY_EDITTING: {
        icon: ErrorIcon,
        title: 'Email Address Change',
        message: 'Please confirm new email address',
        buttonText: 'Confirm',
        showCancel: false,
        cancelAction: () => {},
        showButton: true,
        buttonAction: () => {},
    },
    IS_SAVING: {
        icon: PendingIcon,
        title: 'Saving...',
        message: 'Updating your profile',
        buttonText: 'Saving',
        showCancel: false,
        cancelAction: () => {},
        showButton: true,
        buttonAction: () => {},
    },
    VALIDATION_PENDING: {
        icon: PendingIcon,
        title: 'Validation Pending',
        message: "We've emailed you a confirmation link",
        showButton: false,
        showCancel: false,
        cancelAction: () => {},
        buttonAction: () => {},
        buttonText: '',
    },
}

const EmailVerificationMessage: FC<EmailVerificationMessageProps> = ({
    isEmailValid,
    pendingEmail: propPendingEmail,
    currentlyEditting,
    saveCallback,
    isSaving,
    saveResponse,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const pendingEmail = saveResponse?.pending_email ? saveResponse.pending_email : propPendingEmail

    const [dismiss, setDismiss] = useState(false)
    const [pending, setPending] = useState(false)

    const [isSavingHeld, setIsSavingHeld] = useState(false)

    const { sendMessage } = useContext(MessageContext)

    // This will hold the "saving..." status for at least N seconds, so the user has time to read it
    const holdSaving = () => {
        setIsSavingHeld((isSavingHeld) => {
            if (isSavingHeld) {
                return isSavingHeld
            }
            setTimeout(() => {
                setIsSavingHeld(false)
            }, 3000)
            return true
        })
    }

    useEffect(() => {
        if (isSaving) {
            holdSaving()
        }
    }, [isSaving])

    let status = STATUS.NOT_VALIDATED
    status.buttonAction = () => {
        setIsLoading(true)
        validateLegacy(1)
            .then(() => {
                sendMessage('Verification email sent', 'success')
                setPending(true)
            })
            .catch((e) => {
                let err_message
                if ([400, 429].indexOf(e.response?.status) > -1) {
                    err_message = e.details.detail
                } else {
                    err_message = 'There was an error while sending the verification email, please try again.'
                }
                sendMessage(err_message, 'error')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    if (isEmailValid && (!pendingEmail || dismiss) && !currentlyEditting) status = STATUS.ALL_GOOD

    if (pendingEmail && !dismiss) {
        status = STATUS.PENDING_VALIDATION
        status.message = `Check ${pendingEmail} to validate this change.`
        status.cancelAction = () => {
            setIsLoading(true)
            cancelEmailChangeValidation()
                .then(() => {
                    sendMessage('Email verification cancelled.', 'success')
                    setDismiss(true)
                })
                .catch(() => {
                    sendMessage('There was an error while cancelling your email validation, please try again.', 'error')
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

        status.buttonAction = () => {
            setIsLoading(true)
            resendEmailChangeValidation()
                .then(() => {
                    sendMessage(`Verification Email Message resent to ${pendingEmail}. Check your inbox.`, 'success')
                })
                .catch(() => {
                    sendMessage(
                        `There was an error while re-sending the verification message to ${pendingEmail}, please try again.`,
                        'success'
                    )
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    if (currentlyEditting) {
        status = STATUS.CURRENTLY_EDITTING
        status.buttonAction = saveCallback
    }

    if (isSaving || isLoading || isSavingHeld) {
        status = STATUS.IS_SAVING
    }

    if (pending && !isLoading) {
        status = STATUS.VALIDATION_PENDING
    }

    const Icon = status.icon

    if (status === STATUS.ALL_GOOD) {
        return (
            <EmailVerificationMessageIconOnly>
                <Icon width={25} height={25} />
            </EmailVerificationMessageIconOnly>
        )
    }

    return (
        <EmailVerificationMessageWrapper>
            <Icon width={25} height={25} />
            <Test id={CY_USER_SETTINGS__EMAIL_VALIDATION}>
                <EmailVerificationMessageContent>
                    <h5>{status.title}</h5>
                    <p>{status.message}</p>
                </EmailVerificationMessageContent>
            </Test>
            <EmailVerificationMessageButtons>
                {status.showCancel && (
                    <div onClick={status.cancelAction}>
                        <CancelIcon />
                    </div>
                )}
                {status.showButton && (
                    <Button onClick={status.buttonAction} disabled={isSaving}>
                        {isSaving ? 'Saving...' : status.buttonText}
                    </Button>
                )}
            </EmailVerificationMessageButtons>
        </EmailVerificationMessageWrapper>
    )
}

export default EmailVerificationMessage
