import { giphyLightGrey } from '@giphy/colors'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    text-align: center;
    & h3 {
        text-align: center;
    }
    & span {
        color: ${giphyLightGrey};
        display: block;
        margin-top: 4px;
    }
`

const Emoji = styled.img`
    height: 127px;
`

const NoResults = () => (
    <Container>
        <Emoji src={'/static/img/sad-tears-emoji.gif'} />
        <h3>
            No Results
            <span>Try adjusting the filters</span>
        </h3>
    </Container>
)

export default NoResults
