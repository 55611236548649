import { useEffect, useState } from 'react'
import { Coordinates, Dimensions } from 'types'
import { TooltipDirection } from '../../types'

const MAX_OFFSET = 30

const useTooltipOffset = (
    coordinates: Coordinates | null,
    dimensions: Dimensions | null,
    direction: TooltipDirection
) => {
    const [offset, setOffset] = useState<Partial<Coordinates> | undefined>()

    useEffect(() => {
        if (!coordinates || !dimensions) return

        const bounds = document.body.getBoundingClientRect()
        const { x, y } = coordinates
        const { height, width } = dimensions
        const mid = { x: width / 2, y: height / 2 }

        if (direction === 'left' || direction === 'right') {
            const bottom = y + mid.y
            const top = y - mid.y

            if (top < bounds.top) {
                setOffset({ y: Math.min(mid.y - MAX_OFFSET, bounds.top - top) })
            } else if (bottom > bounds.bottom) {
                setOffset({ y: Math.max(-mid.y + MAX_OFFSET, bounds.bottom - bottom) })
            } else {
                setOffset({ y: 0 })
            }
        } else {
            const left = x - mid.x
            const right = x + mid.x

            if (left < bounds.left) {
                setOffset({ x: Math.min(mid.x - MAX_OFFSET, bounds.left - left) })
            } else if (right > bounds.right) {
                setOffset({ x: Math.max(-mid.x + MAX_OFFSET, bounds.right - right) })
            } else {
                setOffset({ x: 0 })
            }
        }
    }, [coordinates, dimensions])

    return offset
}

export default useTooltipOffset
