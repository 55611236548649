import { useEffect, useState } from 'react'
import { Dimensions } from 'types'
import { TooltipDirection } from '../../../tooltip'

export function useDropdownDirection(triggerRect: DOMRect | null, dimensions: Dimensions | null) {
    const [state, setState] = useState<TooltipDirection>('down')

    useEffect(() => {
        if (!dimensions || !triggerRect) return

        const { bottom, top } = triggerRect
        const { height } = dimensions

        if (bottom + height > window.innerHeight) {
            if (top - height < 0) {
                setState('down')
            } else {
                setState('up')
            }
        } else {
            setState('down')
        }
    }, [!!dimensions, !!triggerRect])

    return state
}
