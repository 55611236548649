import { connect } from 'react-redux'
import styled from 'styled-components'
import sharing from '../sharing'
import shareCSS from '../share.css'
import { setInstagramVisible } from '../reducers/gif-detail'
import { getUserIsLoggedIn } from '../reducers'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'

const BUTTON_MARGIN = 5

const Container = styled.div`
    margin: 0px 0px 10px;
    display: flex;
    width: 100%;
`

const Button = styled.div`
    flex-shrink: 1;
    font-size: 16px;
    height: 42px;
    line-height: 44px;
    margin: 0 ${BUTTON_MARGIN}px 0 0;
    width: 100%;
`

const Social = ({
    isGif = false,
    gif,
    types = [],
    isLoggedIn,
    url,
    setInstagramVisible,
    setInstagramVisibleAction,
}) => {
    const shareUrl = isGif ? gif.url : url || location.href
    const buttonWidth = `calc((100% - ${BUTTON_MARGIN * (types.length - 1)}px) / ${types.length})`
    return (
        <Container>
            {types.map((type) => (
                <Button
                    key={type}
                    style={{ width: buttonWidth, minWidth: buttonWidth }}
                    className={shareCSS[type]}
                    onClick={() => {
                        setGADataLayer({
                            event: 'share_success',
                            options: {
                                social: {
                                    socialChannel: type,
                                },
                                content: getContentOfGif(gif),
                                creator: getCreatorOfGif(gif),
                            },
                        })
                        if (type === `instagram` && isLoggedIn) {
                            // If component calling this mobile has defined an action, use that. Otherwise, use the default.
                            if (setInstagramVisibleAction !== undefined) {
                                setInstagramVisibleAction(true)
                            } else {
                                setInstagramVisible(true)
                            }
                        } else {
                            sharing[type](shareUrl)
                        }
                    }}
                />
            ))}
        </Container>
    )
}

export default connect((state) => ({ isLoggedIn: getUserIsLoggedIn(state) }), { setInstagramVisible })(Social)
