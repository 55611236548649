import { giphyGreen } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { LoaderSize } from '../types'
import { borderRadiusFromLoaderSize, heightFromLoaderSize } from '../utils'

const snakeAnimation = keyframes`
    0% { transform: translateX(-100%); }
    100% { transform: translateX(400%); }
`

export const Snake = styled.div`
    animation: ${snakeAnimation} 2s cubic-bezier(0.83, 0, 0.17, 1) 0.15s infinite backwards;
    background: ${giphyGreen};
    border-radius: 2px;
    height: 100%;
    left: 0;
    opacity: 0.25;
    position: absolute;
    top: 0;
    width: 30%;

    &:nth-child(2) {
        animation-delay: 0.1s;
        opacity: 0.5;
    }

    &:nth-child(3) {
        animation-delay: 0.05s;
        opacity: 0.75;
    }

    &:nth-child(4) {
        animation-delay: 0s;
        opacity: 1;
    }
`

export const Container = styled.div<{ loaderSize: LoaderSize }>`
    overflow: hidden;
    position: relative;

    ${({ loaderSize }) => css`
        height: ${heightFromLoaderSize(loaderSize)}px;

        ${Snake} {
            border-radius: ${borderRadiusFromLoaderSize(loaderSize)}px;
        }
    `}
`
