import PropTypes from 'prop-types'
import ButtonGroup from '../button-group'

const InputButton = ({ name, index, selected, val, label, onClick, isOtherHovered, onMouseOver, className }) => (
    <>
        <ButtonGroup.Button
            type="button"
            {...{ index, selected, onClick, onTouchEnd: onClick, isOtherHovered, onMouseOver, className }}
        >
            {label || val}
        </ButtonGroup.Button>
        {selected && <input hidden name={name} readOnly value={val} />}
    </>
)

InputButton.propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    selected: PropTypes.bool,
    isOtherHovered: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
}

export default InputButton
