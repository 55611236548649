import { GifsResult, PaginationOptions } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import appendQuery from 'append-query'
import jsonToFormData from 'json-form-data'
import { compact, isNumber, isString } from 'lodash'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import GifContext from 'shared/contexts/gif'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { IChannel } from 'shared/types/channel'
import { DELETE, GET, PATCH, POST } from 'shared/util/fetch-methods'
import { getChannelsSearchPath } from 'shared/util/search'
import { getResults, retrieveCacheStatus } from '.'
const identity = (i: any) => i

type Result = {}
type ChannelResult = IChannel
type AddGifResults = {
    channel: number
    create_datetime: string
    gif: number
    id: number
}

// we can invoke the fetch methods over and over and not worry about
// extra network requests
let requestMap: { [key: string]: Promise<Result> } = {}
function clearCache() {
    requestMap = {}
}
function request<T>(url: string, normalize = identity) {
    if (!requestMap[url]) {
        requestMap[url] = normalize(getResults(url, GET))
    }
    return requestMap[url] as Promise<T>
}

const normalize = async (p: Promise<any>) => {
    const result = await p
    // usually this is called data, so let's stick with that and use GifsResult
    result.data = result.results
    delete result.results
    return result
}

interface ChannelGifsResult {
    data: IGif[]
    next?: string
    count: number
}

export const PATHNAME = `/api/v4/channels/`

type ChannelFetchOptions = {
    term?: string
    isFeaturedTag?: boolean
}

export const getChannelUrl = (channelId: number, options?: ChannelFetchOptions) => {
    const path = PATHNAME
    const term = options?.term
    return term ? getChannelsSearchPath(term, channelId, options?.isFeaturedTag) : `${path}${channelId}/feed/`
}

export const fetchChannelGifs = (channelId: number, options?: ChannelFetchOptions) => {
    return request<ChannelGifsResult>(getChannelUrl(channelId, options), normalize)
}

function channelGifPaginator(url: string) {
    const gifs: IGif[] = []
    // for deduping
    const gifIds: (string | number)[] = []
    let next: string | undefined
    let isDoneFetching = false
    let total_count = 0
    return async () => {
        if (isDoneFetching) {
            return { gifs, isDoneFetching, total_count }
        }

        let result: ChannelGifsResult
        if (next) {
            result = await request<ChannelGifsResult>(next, normalize)
        } else {
            result = await request<ChannelGifsResult>(url, normalize)
        }
        const { data: newGifs, next: nextUrl, count } = result

        total_count = count
        next = nextUrl
        newGifs.forEach((gif: IGif) => {
            const { id } = gif
            if (!gifIds.includes(id)) {
                // add gifs and gifIds
                gifs.push(gif)
                gifIds.push(id)
            }
        })
        isDoneFetching = !next
        return { gifs: [...gifs], isDoneFetching, total_count }
    }
}

// TODO copy of shared/hooks/use-gif-paginator
// we can refactor useGifPaginator in utils to take a paginator,
// instead of a fetch that's passed to a gifPaginator
// but really it'd be great if all gif pagination used offset (instead of next, or next_url)
export function useChannelGifPaginator(
    channelId: number,
    options?: ChannelFetchOptions
): [IGif[], () => Promise<IGif[]>, boolean] {
    // all the gifs that the fetchGifs has returned
    const [gifs, setGifs] = useState<IGif[]>([])
    // when we fetch and there are no more gifs
    const [isDoneFetching, setDoneFetching] = useState<boolean>(false)
    // we put all the gis in the gif context for other components
    const { receivedGifs } = useContext(GifContext)
    const url = getChannelUrl(channelId, options)
    // paginator for gifs or videos
    const paginator = useRef(channelGifPaginator(url))

    // make the request
    const doFetch = async () => {
        // put gifs in our local state here
        // and also in the context
        const addGifs = (gifs: IGif[]) => {
            setGifs(gifs)
            receivedGifs({ gifs })
        }
        // use the paginator to fetch gifs
        const { gifs: updateGifs, isDoneFetching } = await paginator.current()

        // if this is true we've reached the end
        setDoneFetching(isDoneFetching)
        //
        addGifs(updateGifs)
        // return just in case it makes it easier for
        // a consuming component, but it probably doesn't it
        return updateGifs
    }
    return [gifs, doFetch, isDoneFetching]
}

export function useChannelWithNormalGifFetch(channelId: number, options?: ChannelFetchOptions)
export function useChannelWithNormalGifFetch(url: string, options?: ChannelFetchOptions)
export function useChannelWithNormalGifFetch(urlOrId: number | string, options?: ChannelFetchOptions) {
    let url = urlOrId as string
    if (isNumber(urlOrId)) {
        url = getChannelUrl(urlOrId as number, options)
    }
    // fake our gif pagination which includes an offset and not
    // not this `next` nonsense
    const paginator = useRef(channelGifPaginator(url))
    const offsetRef = useRef(0)
    useEffect(() => {
        paginator.current = channelGifPaginator(url)
        offsetRef.current = 0
    }, [options])
    const fetchGifs = useCallback(async (): Promise<GifsResult> => {
        const offset = offsetRef.current
        const { gifs, total_count } = await paginator.current()
        offsetRef.current = gifs.length
        const pagination = {
            count: gifs.length,
            total_count,
            offset,
        }
        return {
            data: gifs,
            meta: {
                msg: '',
                response_id: '',
                status: 200,
            },
            pagination,
        }
    }, [])
    return fetchGifs
}

export const getChannelGifsByUsername = async (username: string, options: PaginationOptions) => {
    const f = await fetch(`/api/v4/channels/${username}/clips/feed?offset=${options.offset}&limit=${options.limit}`)
    return await f.json()
}

type ChannelOptions = {
    fetch_children?: boolean
}
export const fetchChannel = async (channelId: number, options: ChannelOptions = {}) => {
    const result = await request<ChannelResult>(appendQuery(`${PATHNAME}${channelId}/`, options))
    if (!result.children) {
        result.children = []
    }
    return result
}

type ChannelChildrenOptions = { offset?: number; limit?: number; fetch_associated_channels?: boolean }
export const fetchChannelChildren = async (channelId: number, options: ChannelChildrenOptions = {}) => {
    const { results } = await request<{ results: ChannelResult[] }>(
        appendQuery(`${PATHNAME}${channelId}/children`, options)
    )
    return results
}

const LIMIT = 50
export const fetchAllChannelChildren = async (channelId: number): Promise<ChannelResult[]> => {
    const [data1, data2] = await Promise.all([
        request<{ results: ChannelResult[] }>(
            appendQuery(`${PATHNAME}${channelId}/children`, {
                offset: 0,
                limit: LIMIT,
                fetch_associated_channels: true,
            })
        ),
        request<{ results: ChannelResult[] }>(
            appendQuery(`${PATHNAME}${channelId}/children`, {
                offset: LIMIT,
                limit: LIMIT,
                fetch_associated_channels: true,
            })
        ),
    ])

    return [...data1.results, ...data2.results]
}

export const fetchChannelByUsername = async (username: string) => {
    return request<ChannelResult>(`${PATHNAME}?slug=${username}`)
}

type SaveChannel = {
    user: number
    parent: number
    slug: string
    display_name: string
    short_display_name?: string
    metadata_description?: string
    description: string
    is_private?: boolean
    live_until_datetime?: string
    live_since_datetime?: string
}

export const saveChannel = async (channel: SaveChannel) => {
    clearCache()
    return getResults(PATHNAME, POST(channel, true)) as Promise<IChannel>
}

export const deleteChannel = (channelId: number) => {
    clearCache()
    return getResults(`${PATHNAME}${channelId}/`, DELETE())
}

export const editChannel = async (editChannel: IChannel) => {
    const channel = { ...editChannel }
    // @ts-ignore
    delete channel.user
    // @ts-ignore
    delete channel.ancestors
    // @ts-ignore
    delete channel.children
    if (channel.featured_gif?.id) {
        // @ts-ignore
        channel.featured_gif = channel.featured_gif?.id
    }
    const { banner_image } = channel
    // if banner image has a url
    if (isString(banner_image) && banner_image.length > 0) {
        // @ts-ignore we want to delete it
        delete channel.banner_image
    }
    const patch = PATCH(jsonToFormData(channel))
    // if banner image is an empty string
    if (banner_image === '') {
        // we want to make sure it's falsy value is included in the form data
        ;(patch.body as FormData).set('banner_image', '')
    }

    clearCache()
    return getResults(`${PATHNAME}${channel.id}/`, patch).then(async (ch) => {
        await retrieveCacheStatus(ch.flush_uuid)
        return ch
    })
}

export const moveChannel = (
    channelId: number,
    siblingId: number,
    side: 'left' | 'right' | 'first-child' | 'last-child'
) => {
    clearCache()
    return getResults(`/api/v4/channels/${channelId}/move/`, POST({ position: side, target_channel: siblingId }, true))
}

export const search = async (term: string) => request<ChannelResult>(`${PATHNAME}?slug=${encodeURIComponent(term)}`)

type Gifs = { gif: string | number; channel: number }
export const addGifs = (addGifs: Gifs[], isDelete?: boolean) => {
    const post = POST(`items=${JSON.stringify(addGifs)}`)
    // @ts-ignore not sure how this is typed
    post.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'

    const result = getResults(`/api/v4/channel-gifs/${isDelete ? 'remove/' : ''}`, post) as Promise<AddGifResults>
    // don't cache these in the map
    clearCache()
    return result
}

export const removeGif = (removeGif: Gifs) => {
    const post = POST(`items=${JSON.stringify(removeGif)}`)
    // @ts-ignore not sure how this is typed
    post.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
    const result = getResults(`/api/v4/channel-gifs/remove`, post)
    // don't cache these in the map
    clearCache()
    return result
}

export function useChannelFetch(id: number): IChannel | undefined {
    const [fetchedChannel, setChannel] = useState<IChannel | undefined>()
    useAsyncEffect(async () => {
        setChannel(await fetchChannel(id))
    })
    return fetchedChannel
}

// consistent way of getting a channel even if it hasn't been fetched yet
export function useChannel(channel: IChannel): [IChannel | undefined, () => Promise<IChannel>] {
    const [fetchedChannel, setChannel] = useState<IChannel | undefined>()
    const fc = async () => {
        const kids = channel.children || []
        // do we need to fetch?
        if (channel.has_children && kids.length === 0) {
            // fetch the channel
            const fetchedChannel = await fetchChannel(channel.id)
            setChannel(fetchedChannel)
            return fetchedChannel
        } else {
            setChannel(channel)
            return channel
        }
    }
    return [fetchedChannel, fc]
}

// TODO I think this was replaced by useChannelGifPaginator
export function useChannelGifs(channel: IChannel): [IGif[] | undefined, () => any] {
    // ensure we've fetched
    const [fetchedChannel, fc] = useChannel(channel)
    const { receivedGifs } = useContext(GifContext)
    const [gifs, setGifs] = useState<IGif[] | undefined>()
    const url = getChannelUrl(channel.id)
    const paginator = channelGifPaginator(url)
    const addGifs = (gifs: IGif[]) => {
        setGifs(gifs)
        receivedGifs({ gifs })
    }
    const fetchGifs = async () => {
        const { gifs } = await paginator()
        addGifs(gifs)
    }
    const currentFetch = useRef<any>(fc)
    useAsyncEffect(async () => {
        if (fetchedChannel) {
            if (fetchedChannel.has_children) {
                // if channels paginated, we'd set currentFetch here (maybe)
                addGifs(compact(fetchedChannel.children.map((channel: IChannel) => channel.featured_gif)))
            } else {
                fetchGifs()
                currentFetch.current = fetchGifs
            }
        }
    }, [fetchedChannel])
    return [gifs, currentFetch.current]
}
