exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/grid.css"), undefined);
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!../detail.css"), undefined);

// module
exports.push([module.id, "._1M8xq1jPOAHRc0OSZxxS8_ {\n    display: block;\n    position: relative;\n}\n\n._1M8xq1jPOAHRc0OSZxxS8_:hover ._3XsaOar44V4FPVTuTLqVU {\n            opacity: 1;\n            transform: translateY(0);\n        }\n._20f1HGz5MW_KOrDOB5cb3Y {\n    background: url('/static/img/backgrounds/bg-tile-dark.png') left top;\n    box-sizing: border-box;\n}\n.KRS9L9BsuEdhF-ACKiX8x {\n    position: relative;\n    animation: _1Q5B0AS-SFilgItAciVKey .2s ease-out;\n}\n.KRS9L9BsuEdhF-ACKiX8x video {\n        display: block;\n        position: relative;\n        z-index: -1;\n    }\n.KRS9L9BsuEdhF-ACKiX8x video + img {\n        height: 100%;\n        left: 0;\n        position: absolute;\n        top: 0;\n        width: 100%;\n    }\n._2e8VMsXvtwwOTebVU08mai {\n    pointer-events: none;\n}\n._3XsaOar44V4FPVTuTLqVU {\n    box-sizing: border-box;\n    bottom: 0;\n    left: 0;\n    opacity: 0;\n    padding: 10px;\n    position: absolute;\n    width: 100%;\n    transform: translateY(" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/grid.css").locals["gridSpacing"] + ");\n    transition: opacity .2s ease-out, transform .2s ease-out;\n}\n._24VXFKFghodc09xm_VRxdF {\n    background-image: no-repeat url('/static/img/loader_purple.gif') center center;\n    width: 100%;\n    height: 250px;\n    animation: _1Q5B0AS-SFilgItAciVKey .2s ease-out .3s 1 normal backwards;\n}\n\n@keyframes _1Q5B0AS-SFilgItAciVKey {\n    0% { opacity: 0; }\n    100% { opacity: 1; }\n}\n\n._3xYY6QR2VuQXUgyDTl0hyu {\n}\n\n._3xYY6QR2VuQXUgyDTl0hyu img,\n    ._3xYY6QR2VuQXUgyDTl0hyu video {\n        left: 0;\n        position: absolute;\n        pointer-events: none;\n        top: 0;\n        z-index: auto;\n    }\n\n._3xYY6QR2VuQXUgyDTl0hyu img {\n        -o-object-fit: contain;\n           object-fit: contain;\n    }\n", ""]);

// exports
exports.locals = {
	"grid": "\"shared/css/grid.css\"",
	"gridSpacing": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/grid.css").locals["gridSpacing"] + "",
	"gif": "_1M8xq1jPOAHRc0OSZxxS8_",
	"overlay": "_3XsaOar44V4FPVTuTLqVU",
	"sticker": "_20f1HGz5MW_KOrDOB5cb3Y _1M8xq1jPOAHRc0OSZxxS8_",
	"image": "KRS9L9BsuEdhF-ACKiX8x",
	"fadeIn": "_1Q5B0AS-SFilgItAciVKey",
	"sourceDisabled": "_2e8VMsXvtwwOTebVU08mai",
	"preload": "_24VXFKFghodc09xm_VRxdF",
	"fullscreen": "_3xYY6QR2VuQXUgyDTl0hyu " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!../detail.css").locals["takeover"] + ""
};