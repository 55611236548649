import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, fontSizeFromControlSize, heightFromControlSize } from 'utils'
import DndComponent from './dnd'

export const Dnd = styled(DndComponent)`
    border-radius: inherit;
`

export const Container = styled.div<{
    $disabled: boolean
    $size: ControlSize
}>`
    align-items: center;
    display: flex;
    position: relative;
    user-select: none;

    ${({ $disabled, $size }) => css`
        border-radius: ${borderRadiusFromControlSize($size)}px;
        font-size: ${fontSizeFromControlSize($size)}px;
        height: ${heightFromControlSize($size)}px;

        ${$disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
    `}
`
