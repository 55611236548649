import { IconProps } from '../types/icons'

export const AutoPlayIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.819 3.97116L10.8634 5.62633L16.7084 5.528L13.8711 0.41687L12.8868 2.12173C8.76888 0.0851771 3.72184 1.57289 1.39231 5.60775C-1.03349 9.80936 0.406086 15.1819 4.6077 17.6077C8.80931 20.0336 14.1819 18.594 16.6077 14.3924L14.7634 13.3276C12.9257 16.5106 8.85554 17.6012 5.6725 15.7635C2.48946 13.9257 1.39887 9.85559 3.2366 6.67255C4.97779 3.65672 8.72302 2.51927 11.819 3.97116ZM12.5992 10.9545L8.07382 13.6165C7.54145 13.9297 6.87031 13.5458 6.87031 12.9282V7.60419C6.87031 6.98654 7.54145 6.60269 8.07382 6.91585L12.5992 9.57785C13.1242 9.88663 13.1242 10.6458 12.5992 10.9545Z"
            fill={color}
        />
    </svg>
)

export const ExitFullscreenIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4819 0.124989C10.4325 0.174107 10.4002 0.240137 10.3852 0.323356L10.313 9.40437C10.3108 9.48063 10.3356 9.54441 10.3873 9.5957C10.4391 9.64699 10.5035 9.67154 10.5805 9.66936L19.6277 9.70343C19.7047 9.70125 19.7737 9.66993 19.8347 9.60946C19.8958 9.549 19.9274 9.48063 19.9296 9.40437L19.9993 6.44437C20.0044 6.37096 19.9803 6.3079 19.9271 6.25518C19.8739 6.20247 19.8102 6.17863 19.7361 6.18365L13.8618 6.15407L13.9234 0.261374C13.9284 0.187961 13.9044 0.124898 13.8511 0.0721842C13.7979 0.0194709 13.7343 -0.00437151 13.6602 0.000656829L10.6871 0.0242968C10.6031 0.0391304 10.5347 0.0726945 10.4819 0.124989ZM9.51806 19.875C9.56746 19.8259 9.59978 19.7599 9.61476 19.6766L9.68702 10.5956C9.68922 10.5194 9.66443 10.4556 9.61265 10.4043C9.56087 10.353 9.49649 10.3285 9.4195 10.3306L0.372336 10.2966C0.295346 10.2988 0.22633 10.3301 0.165287 10.3905C0.104246 10.451 0.0726247 10.5194 0.070425 10.5956L0.000663089 13.5556C-0.00441318 13.629 0.0196565 13.6921 0.0728729 13.7448C0.126089 13.7975 0.189754 13.8214 0.263868 13.8163L6.1382 13.8459L6.07664 19.7386C6.07157 19.812 6.09564 19.8751 6.14885 19.9278C6.20207 19.9805 6.26573 20.0044 6.33985 19.9993L9.31285 19.9757C9.39686 19.9609 9.46526 19.9273 9.51806 19.875Z"
            fill={color}
        />
    </svg>
)

export const FullscreenIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.12059 7.99626C4.21182 7.99004 4.2902 8.01953 4.35571 8.08475C4.42122 8.14996 4.45085 8.22798 4.4446 8.3188L4.36882 15.6089L11.6002 15.6455C11.6915 15.6393 11.7698 15.6688 11.8353 15.734C11.9009 15.7992 11.9305 15.8773 11.9242 15.9681L11.8384 19.63C11.8356 19.7244 11.7967 19.8089 11.7216 19.8838C11.6464 19.9586 11.5615 19.9973 11.4667 20L0.329486 19.9579C0.234709 19.9606 0.155452 19.9302 0.0917119 19.8667C0.0279728 19.8033 -0.0025424 19.7244 0.000165538 19.63L0.0891141 8.39548C0.107549 8.29253 0.14723 8.21073 0.208157 8.15007C0.273147 8.08538 0.357352 8.04386 0.460772 8.0255L4.12059 7.99626ZM8.5333 0L19.6705 0.042146C19.7653 0.0394504 19.8445 0.0698278 19.9083 0.133279C19.972 0.196732 20.0025 0.27563 19.9998 0.369979L19.9109 11.6045C19.8925 11.7075 19.8528 11.7893 19.7918 11.8499C19.7269 11.9146 19.6426 11.9561 19.5392 11.9745L15.8794 12.0037C15.7882 12.01 15.7098 11.9805 15.6443 11.9153C15.5788 11.85 15.5492 11.772 15.5554 11.6812L15.6312 4.39107L8.39977 4.35447C8.30854 4.36069 8.23017 4.3312 8.16466 4.26598C8.09915 4.20077 8.06952 4.12275 8.07577 4.03193L8.16164 0.369979C8.16435 0.27563 8.20328 0.191055 8.27842 0.116249C8.35357 0.0414452 8.43853 0.00269567 8.5333 0Z"
            fill={color}
        />
    </svg>
)

export const LoopIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.321 7.06367L20.3751 7.17797L20.5029 7.47171C20.8254 8.26626 21 9.14604 21 10.0004C21 13.7988 17.9074 16.997 14.0307 17.2286L13.8054 17.2387L13.5834 17.2419L6.05309 17.2416L5.96132 17.3312L5.96122 20L1.89133 16.0255L5.96122 12.05L5.96132 14.719L6.05308 14.8086L13.5077 14.8091L13.7154 14.8049C16.1959 14.705 18.2123 12.8225 18.415 10.4009L18.4275 10.201L18.4317 10.0022L18.429 9.68064L18.4222 9.51572L18.4061 9.31991L18.3948 9.23242L18.3562 9.03796L18.4035 8.92288L20.321 7.06367ZM15.0379 0L19.1088 3.97544L15.0379 7.94999L15.0378 5.28061L14.946 5.19102L7.41518 5.19177L7.20744 5.19595C4.72702 5.29594 2.71062 7.17838 2.50785 9.60002L2.49535 9.79993L2.49119 9.99865L2.49174 10.1362L2.49758 10.3622C2.50238 10.46 2.5103 10.5375 2.52268 10.6065L2.55379 10.7363L2.57818 10.8085L2.66126 10.9988L0.677245 12.9363L0.624919 12.8229L0.497088 12.5292C0.174564 11.7346 0 10.8549 0 10.0004C0 6.20208 3.0926 3.00389 6.96932 2.77229L7.19458 2.76221L7.41659 2.75895L14.946 2.75896L15.0378 2.66937L15.0379 0Z"
            fill={color}
        />
    </svg>
)

export const PauseIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 20H1.01562C0.454711 20 0 19.5278 0 18.9453V1.05469C0 0.4722 0.454711 0 1.01562 0L3.98437 0.0546875C4.54529 0.0546875 5 0.526887 5 1.10938V19C5 19.5825 4.56091 20 4 20Z"
            fill={color}
        />
        <path
            d="M12.0156 20H15C15.5609 20 16 19.5825 16 19V1.10938C16 0.526887 15.5453 0.0546875 14.9844 0.0546875L12.0156 0C11.4547 0 11 0.4722 11 1.05469V18.9453C11 19.5278 11.4547 20 12.0156 20Z"
            fill={color}
        />
    </svg>
)

export const PlayIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.75 0C22.9926 0 24 1.00736 24 2.25V21.75C24 22.9926 22.9926 24 21.75 24H2.25C1.00736 24 0 22.9926 0 21.75V2.25C0 1.00736 1.00736 0 2.25 0H21.75ZM8.44596 5.78246C8.36945 5.78246 8.29533 5.80384 8.2236 5.8466L8.21053 18.513C8.26791 18.5593 8.34084 18.5825 8.4293 18.5825C8.43362 18.5825 8.45441 18.5716 8.49167 18.55L8.53442 18.5248C9.12943 18.1691 12.2302 16.1685 17.8367 12.523C17.8941 12.4767 17.9228 12.4215 17.9228 12.3573C17.9228 12.2932 17.8941 12.2379 17.8367 12.1916L8.66115 5.8466C8.58942 5.80384 8.51769 5.78246 8.44596 5.78246Z"
            fill={color}
        />
    </svg>
)

export const SoundOffIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.0035 12.3341C21.0363 12.3757 21.0526 12.4217 21.0526 12.4722C21.0526 12.5197 21.0363 12.5642 21.0035 12.6058L19.6549 13.951C19.6133 13.9837 19.5686 14 19.521 14C19.4704 14 19.4242 13.9837 19.3825 13.951L17.0426 11.6169L14.7027 13.951C14.661 13.9837 14.6149 14 14.5643 14C14.5166 14 14.472 13.9837 14.4303 13.951L13.0817 12.6058C13.049 12.5642 13.0326 12.5197 13.0326 12.4722C13.0326 12.4217 13.049 12.3757 13.0817 12.3341L15.4216 10L13.0817 7.66593C13.049 7.62435 13.0326 7.57832 13.0326 7.52784C13.0326 7.48033 13.049 7.43578 13.0817 7.39421L14.4303 6.049C14.472 6.01633 14.5166 6 14.5643 6C14.6149 6 14.661 6.01633 14.7027 6.049L17.0426 8.38307L19.3825 6.049C19.4242 6.01633 19.4704 6 19.521 6C19.5686 6 19.6133 6.01633 19.6549 6.049L21.0035 7.39421C21.0363 7.43578 21.0526 7.48033 21.0526 7.52784C21.0526 7.57832 21.0363 7.62435 21.0035 7.66593L18.6636 10L21.0035 12.3341ZM10.5194 0.502322V19.4977C10.5194 19.7241 10.4177 19.8802 10.2142 19.9661C9.97936 20.0364 9.79543 19.9974 9.66237 19.849L4.28525 13.9601H0.504838C0.363953 13.9601 0.244593 13.9113 0.146755 13.8137C0.0489179 13.7161 0 13.597 0 13.4565V6.53567C0 6.39513 0.0489179 6.27607 0.146755 6.17848C0.244593 6.08088 0.363953 6.03209 0.504838 6.03209H4.28525L9.66237 0.15099C9.79543 0.00264892 9.97936 -0.0363882 10.2142 0.0338786C10.4177 0.11976 10.5194 0.275907 10.5194 0.502322Z"
            fill={color}
        />
    </svg>
)

export const SoundOnIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5757 0.502322V19.4977C10.5757 19.7241 10.4734 19.8802 10.2689 19.9661C10.0328 20.0364 9.84787 19.9974 9.7141 19.849L4.3082 13.9601H0.507541C0.365901 13.9601 0.245902 13.9113 0.147541 13.8137C0.0491799 13.7161 0 13.597 0 13.4565V6.53567C0 6.39513 0.0491799 6.27607 0.147541 6.17848C0.245902 6.08088 0.365901 6.03209 0.507541 6.03209H4.3082L9.7141 0.15099C9.84787 0.00264892 10.0328 -0.0363882 10.2689 0.0338786C10.4734 0.11976 10.5757 0.275907 10.5757 0.502322ZM16.2555 2.2349C19.3184 3.59149 21.3333 6.61338 21.3333 10C21.3333 13.3942 19.3093 16.4216 16.2361 17.7737L15.2828 15.6406C17.5133 14.6593 18.9816 12.463 18.9816 10C18.9816 7.54245 17.5198 5.35016 15.2969 4.36558L16.2555 2.2349ZM13.9419 5.88932C15.5632 6.6074 16.6299 8.20724 16.6299 10C16.6299 11.7968 15.5584 13.3995 13.9316 14.1152L12.9783 11.9822C13.7623 11.6373 14.2782 10.8656 14.2782 10C14.2782 9.13632 13.7646 8.36607 12.9833 8.01999L13.9419 5.88932Z"
            fill={color}
        />
    </svg>
)
