import { giphyWhite } from '@giphy/colors'
import { isEmpty, isNumber } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchGifDetailViewCount } from 'shared/api/index'
import CollectionsMessage from 'shared/components/collections/collections-message'
import CTA from 'shared/components/cta'
import Tags from 'shared/components/tags/tags'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { PaginationButton } from 'shared/css/buttons'
import * as selectors from 'shared/redux/reducers'
import { IVideo } from 'shared/types/video'
import log from 'shared/util/log'
import { fitGifToSize } from 'shared/util/sizing'
import getViewCounterDisplay from 'shared/util/view-count-formatter'
import styled, { css } from 'styled-components'
import Gif from './gif'
import NextPrev from './next-prev'
import GifDetailOverlay from './overlays'
import Sidebar from './sidebar'
import ReportForm from './report-form'

const GIF_WIDTH = 500
const GIF_HEIGHT = 573
const MINIMUM_HEIGHT = 270

const Container = styled.div<{ isPrivate: boolean }>`
    color: ${giphyWhite};
    padding-top: 24px;
    position: relative;
    text-align: left;
    &:after {
        clear: both;
        content: '';
        display: block;
    }
    margin-bottom: ${({ isPrivate }) => isPrivate && `64px`};
`

const ContentWrapper = styled.div`
    float: left;
    margin: 0 0 16px;
    position: relative;
    width: ${GIF_WIDTH};
    vertical-align: top;
`

const Content = styled.div`
    position: relative;
    input[type='text'] {
        box-sizing: border-box;
        cursor: text;
        height: 30px;
        line-height: 30px;
    }
`

const Navigation = styled.div<{ gifHeight: number }>`
    float: right;
    margin-bottom: 16px;
    vertical-align: top;
    width: 243px;
    height: ${({ gifHeight }) => `${gifHeight + 36}px`};
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    height: 26px;
`

const ViewCountContainer = styled.div`
    margin-bottom: 6px;
    color: #a6a6a6;
    font-size: 14px;
    font-weight: regular;
    font-family: InterFace;
    text-transform: none;
    -webkit-font-smoothing: antialiased;

    &:before {
        display: inline-block;
        font-size: 16px;
        margin-right: 8px;
        vertical-align: top;
        color: #d8d8d8;
        padding-right: 2px;
    }
`

const BottomBar = styled.div`
    margin-top: auto;
    margin-left: 17px;
    transform: translateY(-1px);
`

const CTAContainer = styled.div`
    margin-top: 50px;
`

const TagsContainer = styled.div<{ isSmall: boolean }>`
    ${({ isSmall }) =>
        isSmall &&
        css`
            width: ${GIF_WIDTH}px;
        `}

    div {
        max-height: auto;
    }
`

const EMBED_MIN_REQUIRED_WIDTH = 500
const EMBED_MIN_REQUIRED_HEIGHT = 330

type Props = {
    gif: IVideo
    prevGif: IVideo
    nextGif: IVideo
    children: any
    gifId: string
    groupId: string
    tags: []
    isSticker: boolean
}

const GifDetailGif = ({ gif, prevGif, nextGif, children, tags = [], isSticker }: Props) => {
    const [views, setViews] = useState<number>(0)
    const [overlayType, setOverlayType] = useState<string | null>(null)
    const { addGifToCollectionSuccessMessage, addGifToMultipleCollectionsSuccessMessage } =
        useContext(CollectionContext)

    useEffect(() => {
        setViews(0)
        fetchAnalytics(gif.id)
    }, [gif.id])

    const fetchAnalytics = async (id: string | number) => {
        try {
            const { viewCount } = await fetchGifDetailViewCount(id)
            !!viewCount && setViews(viewCount)
        } catch (e) {
            log.error(e)
        }
    }

    const fitToSize = fitGifToSize(gif, GIF_WIDTH, GIF_HEIGHT)

    const gifSize = {
        ...{
            ...(overlayType === 'embed' && fitToSize.height <= EMBED_MIN_REQUIRED_HEIGHT
                ? {
                      width: EMBED_MIN_REQUIRED_WIDTH,
                      height: EMBED_MIN_REQUIRED_HEIGHT,
                  }
                : fitToSize),
        },
        left: 0,
        top: 0,
    }

    const { text, link } = gif.cta || { text: '', link: '' }

    return (
        <>
            <Container isPrivate={gif.is_hidden}>
                <ContentWrapper>
                    <Content>
                        {overlayType ? (
                            <>
                                <Gif
                                    {...{
                                        gif,
                                        gifSize,
                                    }}
                                />
                                <GifDetailOverlay
                                    gif={gif}
                                    height={gifSize.height}
                                    overlayType={overlayType}
                                    closeOverlay={setOverlayType}
                                />
                            </>
                        ) : (
                            <>
                                <Gif
                                    {...{
                                        gif,
                                        gifSize,
                                    }}
                                />
                                {(addGifToCollectionSuccessMessage || addGifToMultipleCollectionsSuccessMessage) && (
                                    <CollectionsMessage top={26} width={GIF_WIDTH} height={gifSize.height} showGif />
                                )}
                            </>
                        )}
                        {children}
                    </Content>
                    {text && link && (
                        <CTAContainer>
                            <CTA gif={gif} width={GIF_WIDTH} />
                        </CTAContainer>
                    )}
                </ContentWrapper>
                <Navigation gifHeight={gifSize.height}>
                    <Header style={{ textAlign: `right` }}>
                        <NextPrev gif={prevGif}>
                            <PaginationButton className={'ss-navigateleft'} />
                        </NextPrev>
                        <NextPrev gif={nextGif}>
                            <PaginationButton className={'ss-navigateright'} style={{ marginLeft: `3px` }} />
                        </NextPrev>
                    </Header>
                    <Sidebar gif={gif} setOverlayType={setOverlayType} />
                    <BottomBar>
                        {isNumber(views) && views > 0 && (
                            <ViewCountContainer className={'ss-view'}>
                                {getViewCounterDisplay(views)}
                            </ViewCountContainer>
                        )}
                        <ReportForm gif={gif.id} isSticker={isSticker} />
                    </BottomBar>
                </Navigation>
            </Container>
            {!isEmpty(tags) && (
                <TagsContainer isSmall={!!(fitToSize.height < MINIMUM_HEIGHT)}>
                    <Tags tags={tags} isSticker={isSticker} />
                </TagsContainer>
            )}
        </>
    )
}

export default connect((state, { gifId, groupId }: Props) => ({
    gif: selectors.getGifById(state, gifId),
    prevGif: selectors.getPrevGif(state, gifId, groupId),
    nextGif: selectors.getNextGif(state, gifId, groupId),
}))(GifDetailGif)
