import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import styled from 'styled-components'

export const Container = styled.div`
    font-size: 16px;
    display: flex;
    margin-right: 30px;
`

export const Content = styled.div`
    margin-left: 12px;
`

export const Title = styled.h2`
    font-size: 1.125em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
`

export const ContentDescription = styled.div`
    display: flex;
    align-items: center;
`

export const Description = styled.div`
    transition: color 0.25s ease;
    display: inline-block;
    margin-left: -1px;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${giphyLightGrey};

    &:hover {
        color: ${giphyWhite};
    }
`

export const IconContainer = styled.div`
    width: 14px;
    height: 14px;
    line-height: normal;
`
