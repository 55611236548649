/* grid */
export const gridSpacing = 16
export const gridWidth = 1040
export const gridColumnWidth = 72
export const gridPushWidth = 88

/* grid columns */
export const grid1 = 72
export const grid2 = 160
export const grid3 = 248
export const grid4 = 336
export const grid5 = 424
export const grid6 = 512
export const grid7 = 600
export const grid8 = 688
export const grid9 = 776
export const grid10 = 864
export const grid11 = 952
export const grid12 = 1040

/* grid push */
export const gridPush1 = 88
export const gridPush2 = 176
export const gridPush3 = 264
export const gridPush4 = 352
export const gridPush5 = 440
export const gridPush6 = 528
export const gridPush7 = 616
export const gridPush8 = 704
export const gridPush9 = 792
export const gridPush10 = 880
export const gridPush11 = 968
