import { Active, Collision, DragOverlay, UniqueIdentifier, useDndContext } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useContext, useState } from 'react'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import ModalContext from 'shared/contexts/modal'
import { IChannel } from 'shared/types/channel'
import { getWebpOrUrl } from 'shared/util/gif-extract'
import { CollectionStyleContext } from '../../context/collection-style-context'
import { DashedLineDiv, HoverIcons, Item, ItemGif, ItemQuantityBox, ItemText, PrivateOverlay } from '../sc'
import { getOpacity } from '../utils'
import { checkCollisionRatio } from './dnd-context'

const GIPHY_GIF =
    'https://media.giphy.com/media/Id66GDfKacJzxSvhqV/giphy.gif?cid=ecf05e477y344su9eci6tyt830qvgk85i4z6lxky2lcbqpv4&rid=giphy.gif&ct=g'

const getShouldCombine = (
    id: string,
    items: UniqueIdentifier[],
    newIndex: number,
    collisions: Collision[] | null,
    active: Active | null
) => {
    const newId = items[newIndex]

    if (!collisions || collisions.length < 2) {
        return false
    }

    const { id: secondCollidingId, data: collisionData } = collisions[1]
    if (!collisionData) {
        return false
    }
    const collisionRatio = collisionData.value

    return (
        secondCollidingId === newId &&
        collisionRatio != null &&
        id !== active?.id &&
        checkCollisionRatio(collisionRatio)
    )
}

type Props = {
    id: string
    channel: IChannel
    index: number
    color: string | undefined
    onChannelClick: (collectionId: number) => void
    isRoot: boolean
    showTooltip?: boolean
}

const SortableChannel = ({ id, channel, index, color = '3e3e3e', onChannelClick, isRoot, showTooltip }: Props) => {
    const { itemWidth, selectedChannelIds } = useContext(CollectionStyleContext)
    const { activeChannelColor, setActiveChannelColor } = useContext(CollectionContext)
    const { openEditCollectionPanel } = useContext(ModalContext)
    const [hoveringChannelEdit, setHoveringChannelEdit] = useState<number | undefined>()
    const [hoveringChannel, setHoveringChannel] = useState<number | undefined>()
    const { attributes, listeners, setNodeRef, transform, transition, newIndex, items, active, isDragging } =
        useSortable({
            id: id,
        })
    const { collisions } = useDndContext()

    const shouldCombine = getShouldCombine(id, items, newIndex, collisions, active)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const shouldAnimate = index === 0 && showTooltip

    return (
        <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                <Item
                    onClick={() => {
                        if (isRoot) {
                            setActiveChannelColor(color)
                        }

                        onChannelClick?.(channel.id)
                    }}
                    itemWidth={itemWidth}
                    selected={selectedChannelIds?.includes(channel.id)}
                    isDragging={isDragging}
                    gutter
                    darken={
                        !!(
                            // Check if its hovering edit pencil
                            (
                                hoveringChannelEdit === channel.id ||
                                // Check if its hovering another channel
                                (hoveringChannel && channel.id !== hoveringChannel)
                            )
                        )
                    }
                    onMouseEnter={() => {
                        setHoveringChannel(channel.id)
                    }}
                    onMouseLeave={() => {
                        setHoveringChannel(undefined)
                    }}
                    shouldCombine={shouldCombine}
                    shouldAnimate={shouldAnimate}
                >
                    {!isDragging ? (
                        <>
                            {channel.has_children && <ItemQuantityBox>{channel.num_children || 1}</ItemQuantityBox>}
                            <ItemGif
                                src={getWebpOrUrl(channel.featured_gif) || GIPHY_GIF}
                                alt={channel?.featured_gif?.title}
                            />
                            <ItemText
                                background={!activeChannelColor || isRoot ? color : activeChannelColor}
                                opacity={isRoot ? 1 : getOpacity(index + 1)}
                            >
                                <a href={channel.url} target="_blank" rel="noreferrer">
                                    {channel.display_name}
                                </a>
                            </ItemText>
                            {channel.is_private && (
                                <PrivateOverlay>
                                    <span className="ss-lock" />
                                </PrivateOverlay>
                            )}
                            <HoverIcons>
                                <span
                                    onMouseEnter={() => {
                                        setHoveringChannelEdit(channel.id)
                                    }}
                                    onMouseLeave={() => {
                                        setHoveringChannelEdit(undefined)
                                    }}
                                    className="ss-write"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        openEditCollectionPanel(channel)
                                    }}
                                />
                            </HoverIcons>
                        </>
                    ) : (
                        <DashedLineDiv />
                    )}
                </Item>
            </div>
            {isDragging && (
                <DragOverlay
                    dropAnimation={{
                        duration: 50,
                        easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                    }}
                >
                    <Item isDragging={isDragging}>
                        {channel.has_children && <ItemQuantityBox>{channel.num_children || 1}</ItemQuantityBox>}
                        <ItemGif
                            src={getWebpOrUrl(channel.featured_gif) || GIPHY_GIF}
                            alt={channel?.featured_gif?.title}
                        />
                        <ItemText background={activeChannelColor || color} opacity={1}>
                            <a href={channel.url} target="_blank" rel="noreferrer">
                                {channel.display_name}
                            </a>
                        </ItemText>
                        {channel.is_private && (
                            <PrivateOverlay>
                                <span className="ss-lock" />
                            </PrivateOverlay>
                        )}
                    </Item>
                </DragOverlay>
            )}
            {shouldAnimate && <DashedLineDiv shouldAnimate={shouldAnimate} />}
        </>
    )
}

export default SortableChannel
