import { giphyBlack, giphyWhite } from '@giphy/colors'
import { css } from 'styled-components'

export const bodyFont = `interface, Helvetica Neue, helvetica, sans-serif`
export const titleFont = `nexablack, sans-serif;`

export const desktop = (s: ReturnType<typeof css>) => css`
    @media ${desktopMediaQuery} {
        ${s};
    }
`
desktop.breakpointWidth = 768
export const desktopMediaQuery = `(min-width: ${desktop.breakpointWidth}px)`

// https://www.joshwcomeau.com/css/custom-css-reset/
export const baseCss = css`
    * {
        border-radius: 0;
        border: 0;
        outline: none;
        box-sizing: border-box;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /*
  1. Use a more-intuitive box-sizing model.
*/
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    /*
  2. Remove default margin
*/
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    figure,
    figcaption,
    blockquote,
    dl,
    dd {
        margin: 0;
        padding: 0;
    }

    /*
  3. Allow percentage-based heights in the application
*/
    html,
    body {
        /* height: 100%; */
    }
    /*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
    body {
    }
    /*
  6. Improve media defaults
    remove: max-width: 100%;
  Seemed to cause a flicker on the grid after focusing the input
*/
    img,
    picture,
    video,
    canvas,
    svg {
        display: block;
    }
    /*
  7. Remove built-in form typography styles
*/
    input,
    button,
    textarea,
    select {
        font: inherit;
    }
    /*
  8. Avoid text overflows
*/
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        overflow-wrap: break-word;
    }
    /*
  9. Create a root stacking context
*/
    #root,
    #__next {
        isolation: isolate;
    }
`

export const giphyCss = css`
    body {
        color: #fff;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${giphyBlack};
        line-height: 22px;
    }
    a {
        text-decoration: none;
        color: ${giphyWhite};
    }
`

export const ssStandardCss = (fontFamily = 'ss-standard') => css`
    html:hover [class^='ss-'] {
        -ms-zoom: 1;
        zoom: 1;
    }
    .ss-icon,
    .ss-icon.ss-standard,
    [class^='ss-']:before,
    [class*=' ss-']:before,
    [class^='ss-'].ss-standard:before,
    [class*=' ss-'].ss-standard:before,
    [class^='ss-'].right:after,
    [class*=' ss-'].right:after,
    [class^='ss-'].ss-standard.right:after,
    [class*=' ss-'].ss-standard.right:after {
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        text-rendering: optimizeLegibility;
        white-space: nowrap; /*-webkit-font-feature-settings: "liga" Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
        -moz-font-feature-settings: 'liga=1';
        -moz-font-feature-settings: 'liga';
        -ms-font-feature-settings: 'liga' 1;
        -o-font-feature-settings: 'liga';
        font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }
    [class^='ss-'].right:before,
    [class*=' ss-'].right:before {
        display: none;
        content: '';
    }
    .ss-navigatedown:before,
    .ss-navigatedown.right:after {
        content: '';
    }
    .ss-navigateright:before,
    .ss-navigateright.right:after {
        content: '▻';
    }

    .ss-check:before,
    .ss-check.right:after {
        content: '✓';
    }

    .ss-heart:before,
    .ss-heart.right:after {
        content: '♥';
    }

    .ss-link:before,
    .ss-link.right:after {
        content: '🔗';
    }
    .ss-flag:before,
    .ss-flag.right:after {
        content: '⚑';
    }
    .ss-write:before,
    .ss-write.right:after {
        content: '✎';
    }
`
