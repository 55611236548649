import { FC, createContext, useState } from 'react'
import * as React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { VideoLoopContext } from './video-loop-manager'
import { IGif, IRendition } from '@giphy/js-types'
import VideoPlayerAnalytics from 'shared/components/video-player/analytics'

export type ActiveShareTab = 'embed' | 'social'

type VideoMainContextProps = {
    // Main video
    videoData: IGif | undefined
    setVideoData: (videoData: IGif) => void
    setShowControls: (visible: boolean) => void
    showControls: boolean
    setProgressValue: (value: number | string) => void
    progressValue: number | string
    setMainMuted: (muted: boolean) => void
    isMainMuted: boolean
    setShowFullscreen: (showFullscreen: boolean) => void
    showFullscreen: boolean
    setIsShare: (isShare: boolean) => void
    isShare: boolean
    setActiveShareTab: (activeShareTab: ActiveShareTab) => void
    activeShareTab: ActiveShareTab | undefined
    setIsEmbedResponsive: (isEmbedResponsive: boolean) => void
    isEmbedResponsive: boolean
    setIsEmbedInfoBelow: (isEmbedInfoBelow: boolean) => void
    isEmbedInfoBelow: boolean
    setIsSmall: (isSmall: boolean) => void
    isSmall: boolean
    onCopyLink: () => void
    setDimensions: (ionrendition: IRendition) => void
    dimensions: IRendition | undefined
    setEvents: (events: VideoPlayerAnalytics) => void
    events: VideoPlayerAnalytics | undefined
    onShareAction: (action: string) => void
    isReporting: boolean
    setIsReporting: (isReporting: boolean) => void
    showClosedCaption: boolean | undefined
    setShowClosedCaption: (showClosedCaption: boolean) => void
}

type Props = {
    children?: React.ReactNode
}

export const VideoMainContext = createContext({} as VideoMainContextProps)

const VideoMainContextManager: FC<Props> = ({ children }) => {
    // Main video
    const [videoData, setVideoData] = useState<IGif | undefined>()
    const [showControls, setShowControls] = useState<boolean>(false)
    const [isMainMuted, setMainMuted] = useState<boolean>(false)
    const [isShare, setIsShare] = useState<boolean>(false)
    const [showFullscreen, setShowFullscreen] = useState<boolean>(false)
    const [activeShareTab, setActiveShareTab] = useState<ActiveShareTab | undefined>()
    const [progressValue, setProgressValue] = useState<number | string>(0)
    const [isEmbedResponsive, setIsEmbedResponsive] = useState<boolean>(false)
    const [isEmbedInfoBelow, setIsEmbedInfoBelow] = useState<boolean>(false)
    const [isSmall, setIsSmall] = useState<boolean>(false)
    const [events, setEvents] = useState<VideoPlayerAnalytics | undefined>()
    const [dimensions, setDimensions] = useState<IRendition | undefined>()
    const [isReporting, setIsReporting] = useState<boolean>(false)
    const [showClosedCaption, setShowClosedCaption] = useLocalStorage(`show-closed caption`, false)

    // Reset loop when start share or Fullscreen actions
    const { resetLoopToNextVideo, setPreventLoopCounter } = useContext(VideoLoopContext)
    useEffect(() => {
        if (isShare || showFullscreen) {
            resetLoopToNextVideo()
            setPreventLoopCounter?.(true)
        } else {
            setPreventLoopCounter?.(false)
        }
    }, [isShare, showFullscreen])

    // Ga Share Events
    function onShareAction(action) {
        events?.onShareAction(action)
    }

    function onCopyLink() {
        events?.onCopyLink()
    }

    return (
        <VideoMainContext.Provider
            value={{
                videoData,
                setVideoData: (videoData: IGif) => setVideoData(videoData),
                showControls,
                setShowControls: (visible: boolean) => setShowControls(visible),
                progressValue,
                setProgressValue: (progress: number | string) => setProgressValue(progress),
                isMainMuted,
                setMainMuted: (isMuted: boolean) => setMainMuted(isMuted),
                showFullscreen,
                setShowFullscreen: (showFullscreen: boolean) => setShowFullscreen(showFullscreen),
                isShare,
                setIsShare: (isShare: boolean) => {
                    if (!activeShareTab) {
                        events?.onShareAction('social')
                        setActiveShareTab('social')
                    }

                    setIsShare(isShare)
                    events?.onShare?.(isShare)
                },
                activeShareTab,
                setActiveShareTab: (activeShareTab: ActiveShareTab) => setActiveShareTab(activeShareTab),
                isEmbedResponsive,
                setIsEmbedResponsive: (isEmbedResponsive: boolean) => setIsEmbedResponsive(isEmbedResponsive),
                isEmbedInfoBelow,
                setIsEmbedInfoBelow: (isEmbedInfoBelow: boolean) => setIsEmbedInfoBelow(isEmbedInfoBelow),
                isSmall,
                setIsSmall: (isSmall: boolean) => setIsSmall(isSmall),
                dimensions,
                setDimensions: (dimensions: IRendition) => setDimensions(dimensions),
                events,
                setEvents: (events: VideoPlayerAnalytics) => setEvents(events),
                onShareAction: (action: string) => {
                    events?.onShareAction(action)
                    onShareAction(action)
                },
                onCopyLink: () => onCopyLink(),
                isReporting,
                setIsReporting,
                showClosedCaption,
                setShowClosedCaption,
            }}
        >
            {children}
        </VideoMainContext.Provider>
    )
}

export default VideoMainContextManager
