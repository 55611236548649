import {
    giphyBlack,
    giphyBlue,
    giphyIndigo,
    giphyLightBlue,
    giphyPink,
    giphyPurple,
    giphyRed,
    giphyYellow,
} from '@giphy/colors'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { fetchChannel } from 'shared/api/channels'
import Toggle from 'shared/components/form/ui/toggle'
import { IChannel } from 'shared/types/channel'
import { getWebpOrUrl } from 'shared/util/gif-extract'
import styled from 'styled-components'
import { Item, ItemGif, ItemText, NewCollectionHeader, PrivateOverlay } from './add-to-collection/sc'
import BannerUpload from './banner-upload'
import Footer from './footer'
import { NewCollectionProps } from './modal'
import OptionalLabel from './optional-label'
import { DescriptionField, DisableModal, Label, NameField, Section } from './sc'

const DEFAULT_GIF = '/static/img/backgrounds/floating-shapes-transparent.gif'
const PUBLIC_GRADIENT = `linear-gradient(-135deg, rgb(0, 255, 153) 0%, rgb(0, 204, 255) 100%)`
const PRIVATE_GRADIENT = `linear-gradient(45deg, rgb(255, 102, 102) 0%, rgb(230, 70, 182) 100%)`

const COLOR_GRADIENTS = [
    {
        gardientTop: giphyRed,
        gardientBottom: giphyPink,
        color: giphyPink,
    },
    {
        gardientTop: giphyPurple,
        gardientBottom: giphyIndigo,
        color: giphyIndigo,
    },
    {
        gardientTop: giphyIndigo,
        gardientBottom: giphyLightBlue,
        color: giphyLightBlue,
    },
    {
        gardientTop: giphyYellow,
        gardientBottom: giphyRed,
        color: giphyRed,
    },
    {
        gardientTop: giphyLightBlue,
        gardientBottom: giphyBlue,
        color: giphyBlue,
    },
    {
        gardientTop: giphyPink,
        gardientBottom: giphyPurple,
        color: giphyPurple,
    },
]

const defaultChannel: Partial<IChannel> = {
    is_private: false,
}

const PrivacyToggle = styled(Toggle)`
    width: 100px;
    height: 36px;
    border-radius: 100px;
    position: absolute;
    right: 40px;
    top: 5.5px;
    z-index: 2;
`

const Overlay = styled.div<{ backgroundBottom: string; backgroundTop: string }>`
    background: linear-gradient(0deg, ${(props) => props.backgroundBottom}, ${(props) => props.backgroundTop}) no-repeat;
    position: absolute;
    width: 100%;
    height: 88px;
    opacity: 0.3;
    border-radius: 6px 6px 0px 0px;
`

type CollectionEditorContextProps = {
    updateChannel: (channel: Partial<IChannel>) => void
    channel: Partial<IChannel>
    newCollectionProps?: NewCollectionProps
    onComplete?: () => void
    setDisabled: (disabled: boolean) => void
}
export const CollectionEditorContext = createContext({} as CollectionEditorContextProps)

type Props = {
    channel?: Partial<IChannel>
    newCollectionProps?: NewCollectionProps
    title?: string
    onComplete?: () => void
}

const CollectionEditor = ({ channel: currentChannel, newCollectionProps, onComplete, title }: Props) => {
    const isNewChannel = !!newCollectionProps
    const [channel, setChannel] = useState<Partial<IChannel>>(currentChannel || defaultChannel)
    const [isDisabled, setDisabled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const updateChannel = (updateProps: Partial<IChannel>) => setChannel({ ...channel, ...updateProps })
    const value = {
        updateChannel,
        channel,
        newCollectionProps,
        onComplete,
        setDisabled,
    }
    const focusRef = useRef<HTMLInputElement>(null)

    const togglePrivacy = (isOn: boolean) => {
        updateChannel({ is_private: isOn })
    }

    const gifSrc = isNewChannel ? getWebpOrUrl(newCollectionProps?.gif) : getWebpOrUrl(channel?.featured_gif)

    const randomColor = useMemo(() => {
        return COLOR_GRADIENTS[Math.floor(Math.random() * COLOR_GRADIENTS.length)]
    }, [COLOR_GRADIENTS])

    const completeChannelInfo = async () => {
        if (!newCollectionProps && channel.id) {
            const updatedChannelInfo = await fetchChannel(channel.id)
            updateChannel(updatedChannelInfo)
        }
        setLoading(false)
    }

    useEffect(() => {
        focusRef.current?.focus()
        completeChannelInfo()
    }, [])

    if (loading) {
        return <></>
    }

    return (
        <CollectionEditorContext.Provider value={value}>
            {isNewChannel && (
                <PrivacyToggle
                    isOn={!channel.is_private}
                    width={100}
                    height={34}
                    toggle={togglePrivacy}
                    borderRadius={100}
                    onElement={<span className="ss-unlock"></span>}
                    offElement={<span className="ss-lock"></span>}
                    onColor={PUBLIC_GRADIENT}
                    offColor={PRIVATE_GRADIENT}
                    backgroundColor={giphyBlack}
                />
            )}
            {isNewChannel ? (
                <NewCollectionHeader>
                    <Item>
                        {!gifSrc && (
                            <Overlay
                                backgroundTop={randomColor.gardientTop}
                                backgroundBottom={randomColor.gardientBottom}
                            />
                        )}
                        <ItemGif
                            src={gifSrc || DEFAULT_GIF}
                            alt={newCollectionProps?.gif?.title}
                            showStickerBg={false}
                        />
                        <ItemText background={randomColor.color}>{title}</ItemText>
                        {channel.is_private && (
                            <PrivateOverlay>
                                <span className="ss-lock" />
                            </PrivateOverlay>
                        )}
                    </Item>
                </NewCollectionHeader>
            ) : (
                <NewCollectionHeader>
                    <Item>
                        <ItemGif
                            src={gifSrc || DEFAULT_GIF}
                            alt={channel.featured_gif?.title}
                            showStickerBg={channel.featured_gif?.is_sticker}
                        />
                        <ItemText background={randomColor.color}>{channel.display_name}</ItemText>
                        {channel.is_private && (
                            <PrivateOverlay>
                                <span className="ss-lock" />
                            </PrivateOverlay>
                        )}
                    </Item>
                </NewCollectionHeader>
            )}
            <Section>
                <Label>Name</Label>
                <NameField
                    ref={focusRef}
                    maxLength={50}
                    placeholder="Collection Name"
                    value={channel.display_name || ''}
                    onChange={({ target: { value } }) => {
                        updateChannel({ display_name: value })
                    }}
                ></NameField>
            </Section>
            {!isNewChannel && (
                <>
                    <Section>
                        <OptionalLabel label="Description" />
                        <DescriptionField
                            maxLength={200}
                            placeholder="Add a brief description about your collection."
                            as="textarea"
                            value={channel.description || ''}
                            onChange={({ target: { value } }) => {
                                updateChannel({ description: value })
                            }}
                        ></DescriptionField>
                    </Section>
                    <BannerUpload />
                </>
            )}
            <Footer />
            {isDisabled && <DisableModal />}
        </CollectionEditorContext.Provider>
    )
}
export default CollectionEditor
