import { ReactNode } from 'react'
import Portal from '../../portal'
import Message from '../message'
import { MessageProps } from '../types'
import { Container } from './style'

interface Props extends MessageProps {
    children?: ReactNode
    className?: string
    isOpen?: boolean
    onCloseEnd?: () => void
}

const PortalMessageContextManager = ({ children, isOpen = false, onCloseEnd, ...rest }: Props) => {
    function onTransitionEnd() {
        if (!isOpen) {
            onCloseEnd?.()
        }
    }

    return (
        <Portal>
            <Container $open={isOpen} onTransitionEnd={onTransitionEnd}>
                <Message {...rest}>{children}</Message>
            </Container>
        </Portal>
    )
}

export default PortalMessageContextManager
