import { FunctionComponent } from 'react'
import { IconProps } from '.'
import * as icons from '../../icons/content-types'

export const contentTypeIcons = [
    'clip',
    'clip-outline',
    'emoji',
    'emoji-outline',
    'gif',
    'gif-outline',
    'sticker',
    'sticker-outline',
    'text',
    'text-outline',
] as const

type ContentTypeIcon = typeof contentTypeIcons[number]

export function contentTypeIconComponent(icon: ContentTypeIcon): FunctionComponent<IconProps> {
    switch (icon) {
        case 'clip':
            return icons.ClipIcon
        case 'clip-outline':
            return icons.ClipOutlineIcon
        case 'emoji':
            return icons.EmojiIcon
        case 'emoji-outline':
            return icons.EmojiOutlineIcon
        case 'gif':
            return icons.GifIcon
        case 'gif-outline':
            return icons.GifOutlineIcon
        case 'sticker':
            return icons.StickerIcon
        case 'sticker-outline':
            return icons.StickerOutlineIcon
        case 'text':
            return icons.TextIcon
        case 'text-outline':
            return icons.TextOutlineIcon
    }
}

export default ContentTypeIcon
