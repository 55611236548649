import { IGif, IRendition } from '@giphy/js-types'
import mobileCheck from 'is-mobile'
import { PureComponent, ReactNode } from 'react'
import { withStorageContext } from 'react-storage-context'
import { PlayerEvents } from '.'
import checkIfHasAudio from './util/has-audio'
import VideoContext, { VideoContextProps } from './video-context'

const IS_MOBILE = mobileCheck()

// state that will change for every video
const getDefaultMediaState = () => ({
    playhead: 0,
    scrubProgress: 0,
    ended: false,
    isReady: false,
    hasAudio: false,
})

export type SessionProps = { isMuted?: boolean; isLoop?: boolean; volume?: number }
type Props = {
    saveSession: (prop: SessionProps) => void
    session: SessionProps
    forceMute: boolean
    autoPlay: boolean
    videoData: any // I think this is film fest
    dimensions: IRendition
    playerElement: HTMLElement
    noShare: boolean
    noCutout: boolean
    showAttribution: boolean
    toggleShare: boolean
    toggleEmbed: boolean
    events: PlayerEvents
    children?: ReactNode
}

export type ShareActions = 'facebook' | 'twitter' | 'reddit' | 'pinterest' | 'instagram' | 'sms'

export type ActiveShareTab = 'embed' | 'social'

export type VideoContextState = {
    showPoster: boolean
    isReady: boolean
    hasAudio: boolean
    scrubProgress: number
    mediaState?: MEDIA_STATE
    activeShareTab: ActiveShareTab
    showTooltips: boolean
    isOverlayVisible: boolean
    isFullscreen: boolean
    isEmbedResponsive: boolean
    isEmbedInfoBelow: boolean
    isShare: boolean
    toggleShare: boolean
    toggleEmbed: boolean
    isLoop: boolean
    videoData: any
    ended: boolean
    error?: number
    playhead: number
    shareAction?: ShareActions
    copyLink?: boolean
}

export type VideoContextAPI = {
    play: () => void
    pause: () => void
    fullscreenVideoEl: () => void
    getDuration: () => number
    getVideoURL: () => string
    getCurrentTime: () => number
    getCurrentProgress: () => number
    getLoopCount: () => number
    getIsMutedState: () => boolean
    scrub: (scrubProgress: number) => void
    setVideoEl: (videoEl: HTMLVideoElement | null) => void
    setPoster: (showPoster: boolean) => void
    onStateChange: (mediaState: MEDIA_STATE) => void
    onTimeUpdate: (playhead: number) => void
    onCanPlay: () => void
    onError: (error: number) => void
    onEnded: () => void
    onQuartile: (qt: any) => void
    onWaiting: (count: number) => void
    onFirstPlay: (ms: number) => void
    onEndFullscreen: () => void
    onLoop: (count: number) => void
    setMuted: (isMuted: boolean) => void
    setMutedBySDK: (isMuted: boolean) => void
    setFullscreen: (isFullscreen: boolean) => void
    setLoop: (isLoop: boolean) => void
    setVolume: (volume: number) => void
    setIsShare: (isShare: boolean) => void
    onShareAction: (shareAction: ShareActions) => void
    onCopyLink: (copyLink: boolean) => void
    setIsOverlayVisible: (isOverlayVisible: boolean) => void
    setActiveShareTab: (activeShareTab: ActiveShareTab) => void
    setIsEmbedResponsive: (isEmbedResponsive: boolean) => void
    setIsEmbedInfoBelow: (isEmbedInfoBelow: boolean) => void
    getVideoData: () => IGif
}

type MEDIA_STATE = 'playing' | 'paused'

class VideoContextManager extends PureComponent<Props, VideoContextState> {
    static propTypes = {}
    static getDerivedStateFromProps(
        { videoData, toggleShare, toggleEmbed }: any,
        prevState: { videoData: { id: number }; toggleShare: any; toggleEmbed: any; isShare: boolean }
    ) {
        if (videoData.id !== prevState.videoData.id) {
            // reset state that pertains to the video
            return {
                ...getDefaultMediaState(),
                videoData,
                isShare: false,
            }
        }
        if (toggleShare !== prevState.toggleShare || toggleEmbed !== prevState.toggleEmbed) {
            return {
                isShare: toggleShare || toggleEmbed ? true : !prevState.isShare,
                activeShareTab: toggleEmbed ? 'embed' : 'social',
                toggleShare,
                toggleEmbed,
            }
        }
        return null
    }

    constructor(props: Readonly<Props>) {
        super(props)
        const { forceMute, saveSession } = this.props
        // or the user has muted
        if (forceMute) {
            saveSession({ isMuted: true })
        }
    }

    state: VideoContextState = {
        ...getDefaultMediaState(),
        activeShareTab: 'social',
        showPoster: !this.props.autoPlay,
        showTooltips: !IS_MOBILE,
        isOverlayVisible: false,
        isEmbedResponsive: true,
        isEmbedInfoBelow: false,
        isFullscreen: false,
        isShare: false,
        toggleShare: false,
        toggleEmbed: false,
        isLoop: true,
        videoData: this.props.videoData,
    }

    videoEl: HTMLVideoElement | null
    isWaiting = false
    whenStalledTimeStamp = 0
    loopCount = 0
    value = {}
    api: VideoContextAPI = {
        play: () => {
            if (this.videoEl) {
                this.videoEl.play()
                // check audio again, onCanPlay doesn't
                // seem to be accurate
                const hasAudio = checkIfHasAudio(this.videoEl)
                this.props.events.onPlay?.()
                this.setState({ hasAudio })
            }
        },
        getLoopCount: () => this.loopCount,
        pause: () => {
            if (this.videoEl) {
                this.videoEl.pause()
                this.props.events.onPause?.()
            }
        },
        getIsMutedState: () => {
            return !!this.videoEl?.muted
        },
        fullscreenVideoEl: () => {
            if (this.videoEl) {
                if (this.videoEl.requestFullscreen) {
                    this.videoEl.requestFullscreen()
                    // @ts-ignore
                } else if (this.videoEl.webkitEnterFullscreen) {
                    // @ts-ignore
                    this.videoEl.webkitEnterFullscreen()
                }
            }
        },
        getVideoURL: () => this.videoEl?.src || '',
        getDuration: () => {
            if (this.videoEl && !isNaN(this.videoEl.duration)) {
                return this.videoEl.duration
            }
            return 0
        },
        getCurrentTime: () => {
            if (this.videoEl && !isNaN(this.videoEl.currentTime)) {
                return this.videoEl.currentTime
            }
            return 0
        },
        getCurrentProgress: () => {
            if (this.videoEl && !isNaN(this.videoEl.currentTime) && !isNaN(this.videoEl.duration)) {
                return this.videoEl.currentTime / this.videoEl.duration
            }
            return 0
        },
        scrub: (scrubProgress: number) => {
            if (this.videoEl) {
                this.videoEl.currentTime = scrubProgress * this.videoEl.duration
                this.setState({ scrubProgress })
            }
        },
        setVideoEl: (videoEl: HTMLVideoElement | null) => {
            // possibly, all videoEl stuff would be encapsulated in the video.js file,
            // but then would we need state for isPlaying etc.?
            this.videoEl = videoEl
            if (videoEl) {
                this.props.events.onPlayerReady?.(this.api)
                if (this.props.autoPlay) {
                    this.props.events.onPlay?.()
                }
            }
        },
        setPoster: (showPoster: boolean) => this.setState({ showPoster }),
        onStateChange: (mediaState: MEDIA_STATE) => {
            this.setState({ mediaState })
        },
        onTimeUpdate: (playhead: number) => {
            // this.props.events?.onTimeUpdate?.(playhead)
            this.setState({ playhead })
        },
        onCanPlay: () => {
            if (this.isWaiting) {
                this.isWaiting = false
                this.props.events?.onStalledResume?.(Date.now() - this.whenStalledTimeStamp)
            }
            const hasAudio = checkIfHasAudio(this.videoEl)
            this.setState({ isReady: true, hasAudio })
        },
        onError: (error: number) => this.setState({ error }),
        onEnded: () => {
            this.setState({ ended: true })
            this.props.events?.onEnded?.()
        },
        setMutedBySDK: (isMuted: boolean) => {
            if (this.videoEl?.paused) {
                this.props.events.onMute?.(isMuted)
                this.props.saveSession({ isMuted: isMuted })
            }
        },
        setMuted: (isMuted: boolean) => {
            const { session } = this.props
            if (session?.isMuted !== isMuted) {
                this.props.events.onMute?.(isMuted)
                this.props.saveSession({ isMuted })
            }
        },
        setFullscreen: (isFullscreen: boolean) => {
            this.props.events.onFullscreen?.(isFullscreen)
            this.setState({ isFullscreen })
        },
        onEndFullscreen: () => {
            this.props.events.onFullscreen?.(false)
            this.setState({ isFullscreen: false })
        },
        setLoop: (isLoop: boolean) => this.setState({ isLoop }),
        onLoop: (count: number) => {
            this.loopCount = count
            this.props.events.onLoop?.(count)
        },
        setVolume: (volume: number) => this.props.saveSession({ volume }),
        setIsShare: (isShare: boolean) => {
            this.props.events.onShare?.(isShare)
            this.setState({ isShare })
        },
        onShareAction: (shareAction: ShareActions) => {
            this.props.events.onShareAction?.(shareAction)
            this.setState({ shareAction })
        },
        onFirstPlay: (ms: number) => {
            this.loopCount = 0
            this.props.events.onFirstPlay?.(ms)
        },
        onQuartile: (qt: any) => this.props.events.onQuartile?.(qt),
        onCopyLink: (copyLink: boolean) => {
            this.props.events.onCopyLink?.(copyLink)
            this.setState({ copyLink })
        },
        setIsOverlayVisible: (isOverlayVisible: boolean) => this.setState({ isOverlayVisible }),
        setActiveShareTab: (activeShareTab: ActiveShareTab) => this.setState({ activeShareTab }),
        setIsEmbedResponsive: (isEmbedResponsive: boolean) => this.setState({ isEmbedResponsive }),
        setIsEmbedInfoBelow: (isEmbedInfoBelow: boolean) => this.setState({ isEmbedInfoBelow }),
        onWaiting: (count: number) => {
            this.isWaiting = true
            this.whenStalledTimeStamp = Date.now()
            this.props.events.onWaiting?.(count)
        },
        getVideoData: () => this.props.videoData,
    }

    render() {
        const {
            videoData,
            children,
            dimensions = {} as IRendition,
            playerElement,
            noShare,
            noCutout,
            showAttribution,
            // events,
            // persisted state and their defaults
            session: { isMuted = false, volume = 0.7 },
        } = this.props
        const { isFullscreen, showPoster } = this.state
        const { width, height } = dimensions
        const isSmall = !isFullscreen && (height <= 200 || width <= 300)

        const value: VideoContextProps = {
            ...this.state,
            ...this.api,
            noCutout: showPoster || isFullscreen || isSmall || noCutout, // hide cutout when player is small or fullscreen
            videoData,
            playerElement,
            dimensions,
            isMuted,
            volume,
            noShare,
            showAttribution,
            isSmall,
        }

        return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
    }
}

export default withStorageContext(VideoContextManager)
