import { giphyGreen } from '@giphy/colors'
import { useState } from 'react'
import HeaderAnimationComponent from 'shared/components/upload-clips-cta/animation'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import ReportTwentyOne from './report-2021'
import ReportTwentyTwo from './report-2022'
import * as s from './style'

export const HeaderAnimation = styled(HeaderAnimationComponent)`
    left: 0;
    position: absolute;
    top: 0;

    ${mobile.css`
        display: none;
    `}
`

const values: string[] = ['2023', '2022', '2021']

const TransparencyReport = ({ match }: any) => {
    const year = match?.params?.year
    const [currentYear, setCurrentYear] = useState(year)

    const selectNewYear = (newYear: string) => {
        setCurrentYear(newYear)
        history.pushState('GIPHY Transparency Report: ' + newYear, 'GIPHY Transparency Report: ' + newYear, newYear)
    }

    const displayReport = (year: string) => {
        switch (year) {
            case '2021':
                return <ReportTwentyOne />
            case '2022':
                return <ReportTwentyTwo />
            case '2023':
                return (
                    <s.PDFObject data="/transparency/files/2023" type="application/pdf" width="100%" height="100%">
                        <embed src="/transparency/files/2023" type="application/pdf" />
                    </s.PDFObject>
                )
            default:
                return (
                    <>
                        <s.HeroSection>
                            <h1>GIPHY Transparency Report</h1>
                            <s.Disclaimer>An annual Transparency Report for {year} does not yet exist.</s.Disclaimer>
                        </s.HeroSection>
                    </>
                )
        }
    }

    return (
        <div>
            {currentYear && (
                <s.TransparencyReportContainer>
                    <s.TransparencyReportGlobalStyles />
                    <s.Header>
                        <s.InnerHeader>
                            <img src="/static/img/transparency-report/logo.png" />
                            <s.YearDropdownButton
                                gradient="purple-indigo"
                                label={currentYear}
                                labelStyle={{ fontSize: 20 }}
                            >
                                <s.YearDropDownList
                                    defaultIndex={values.findIndex((r) => r === currentYear)}
                                    options={values}
                                    onChange={(index) => {
                                        selectNewYear(values[index])
                                    }}
                                    iconColor={giphyGreen}
                                />
                            </s.YearDropdownButton>
                        </s.InnerHeader>
                    </s.Header>
                    {displayReport(currentYear)}
                </s.TransparencyReportContainer>
            )}
        </div>
    )
}

export default TransparencyReport
