import { giphyRed } from '@giphy/colors'
import IconTooltip from 'shared/components/ui/icon-tooltip'
import styled from 'styled-components'

export const DESCRIPTION_MAX_LENGTH = 200

const Description = styled.textarea<{ hasError: boolean }>`
    display: block;
    font-size: 14px;
    height: 85px;
    line-height: 20px;
    margin-bottom: 15px;
    padding: 10px;
    resize: vertical;
    border: 3px solid ${(props) => (props.hasError ? giphyRed : 'transparent')};
`

const Container = styled.div`
    position: relative;
`

const ToolTip = styled.div`
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    bottom: 0;
    right: 6px;
`

type Props = {
    value: string
    onChange?: Function
    className?: string
}

const DescriptionInput = ({ value = '', onChange = () => {}, className = '' }: Props) => {
    // TODO, saftey net to avoid null as a description value, this should be fixed
    // on the backend to be a an empty string
    const charCount = (value || '').length
    const isTooLong = charCount > DESCRIPTION_MAX_LENGTH
    return (
        <Container className={className}>
            <Description
                hasError={isTooLong}
                value={value}
                placeholder="Add a brief description about your video."
                onChange={(evt) => onChange(evt.target.value)}
            />
            {isTooLong && (
                <ToolTip>
                    <IconTooltip
                        icon={'ss-alert'}
                        color={giphyRed}
                    >{`(${charCount}/${DESCRIPTION_MAX_LENGTH}) characters`}</IconTooltip>
                </ToolTip>
            )}
        </Container>
    )
}

export default DescriptionInput
