import { giphyPurple, giphyRed } from '@giphy/colors'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'

export const ListWrapper = styled.div<{ height: number; marginBottom: number }>`
    height: ${(props) => props.height}px;
    margin: 0 0 ${({ marginBottom }) => marginBottom}px 0;
    overflow: hidden;
    width: 100%;
    ${({ theme: { isInDropdown } }) =>
        !isInDropdown &&
        mobile.css`
    height: 100%;
    margin-bottom: 20px;
`}
`

export const ListsScrollContainer = styled.div`
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
    padding-bottom: 5px;
`

export const List = styled.ul<{ height: number }>`
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    margin: 0;
    padding: 0 6px 0 0;
    white-space: nowrap;
    text-align: left;
    height: ${(props) => props.height + 20}px;
    ${({ theme: { isInDropdown } }) =>
        !isInDropdown &&
        mobile.css`
    -webkit-overflow-scrolling: unset;
    overflow: unset;
    height: 100%;
    margin-bottom: 5px;
`}
`

export const Item = styled.li<{ cover?: boolean }>`
    cursor: pointer;
    display: inline-block;
    margin-left: 6px;
    list-style: none;
    position: relative;
    vertical-align: top;
    z-index: 1;
    &:first-of-type {
        margin-left: 0px;
    }

    img {
        object-fit: ${({ cover }) => (cover ? 'cover' : 'unset')};
    }
`

export const EmptyItem = styled.li<{ width: number }>`
    cursor: pointer;
    display: inline-block;
    margin-left: 6px;
    list-style: none;
    position: relative;
    vertical-align: top;
    z-index: 1;
    width: ${({ width }) => width}px;
`

export const SeeAll = styled(Item)<{ height: number }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    background: linear-gradient(45deg, ${giphyPurple}, ${giphyRed});
    height: ${({ height }) => height}px;
    width: 150px;
    a {
        text-decoration: none;
        border-bottom: none;
    }
`
