import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import OpenInAppCTA from 'mobile/components/open-in-app'
import locationState from 'shared/components/with-location-state'
import Video from '../../shared/components/background-video'
import styled from 'styled-components'
import Header from '../components/header/header'
import Page from './page'

const BackgroundVideo = styled(Video)`
    height: 700px;
    opacity: 0.23;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 50px;
    color: ${giphyWhite};
    font-family: interface;
    font-weight: bold;
    font-size: 26px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    span {
        width: 283px;
        line-height: 30px;
    }
`

const Thumbnail = styled.img`
    height: 128px;
    width: 128px;
    margin: 25px 0 27px 0;
    border-radius: 50%;
    border: 5px solid ${giphyWhite};
    background: rgba(0, 0, 0, 0.27);
`

const Subtitle = styled.div`
    width: 153px;
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${giphyLightGrey};
    font-size: 16px;
    font-weight: normal;
    margin: 5px 0 30px 0;
    div {
        padding-top: 4px;
    }
`

const Footer = styled.div`
    height: 80px;
    margin-top: 35px;
`

const Button = styled.div`
    pointer-events: auto;
`

const MaybeLater = styled.div`
    margin-top: 18px;
    a {
        font-size: 16px;
        color: ${giphyLightGrey};
    }
`

type Props = {
    title: string
    thumbnail: string
    sceneId: string
}

const ArSceneLandingPage = ({ title, thumbnail, sceneId }: Props) => {
    return (
        <Page>
            <BackgroundVideo />
            <Header />
            <Container>
                <Thumbnail src={thumbnail} />
                {title}
                <Subtitle>
                    <div className="ss-camera" /> GIPHY Camera Filter
                </Subtitle>
                <span>Download the GIPHY app to try this camera filter</span>
                <Footer>
                    <Button>
                        <OpenInAppCTA category={'AR Scene'} text="Use our app" slug={sceneId} />
                    </Button>
                    <MaybeLater>
                        <a href={'/'}>Maybe Later</a>
                    </MaybeLater>
                </Footer>
            </Container>
        </Page>
    )
}

export default locationState(['title', 'thumbnail', 'scene_id'])(ArSceneLandingPage)
