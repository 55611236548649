import { pull } from 'lodash'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import styled from 'styled-components'
import Dropdown from './dropdown'
import onHideCallbacks from './tooltip/hide-tooltip-callbacks'

const Container = styled.div`
    display: inline-block;
    flex-shrink: 0;
    font-size: 16px;
    position: relative;
    vertical-align: middle;
`

const Icon = styled.div`
    cursor: pointer;
    line-height: 1;
    padding: 10px;
`

const DropdownWrapper = styled.div`
    bottom: 100%;
    margin-bottom: 2px;
    position: absolute;
    right: 0;
`

export default class IconTooltip extends PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        color: PropTypes.string,
    }

    static hideToolTips = () => onHideCallbacks.forEach((cb) => cb())
    static defaultProps = {
        icon: 'ss-info',
        color: 'rgba(255, 255, 255, 0.7)',
    }

    state = {
        isHover: false,
    }

    componentDidMount() {
        const { show } = this.props
        if (show) {
            this.showTooltip()
        }
        onHideCallbacks.push(this.hideTooltip)
    }

    componentWillUnmount() {
        pull(onHideCallbacks, this.hideTooltip)
    }

    hideTooltip = () => this.setState({ isHover: false })
    showTooltip = () => {
        IconTooltip.hideToolTips()
        // let hide callback propogate
        setTimeout(() => this.setState({ isHover: true }), 0)
    }

    toggleTooltip = () => {
        const isHover = !this.state.isHover
        if (isHover) {
            this.showTooltip()
        } else {
            this.hideTooltip()
        }
    }

    render() {
        const { children, icon, className = ``, color } = this.props
        const { isHover } = this.state
        return (
            <Container onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
                <Icon className={icon + ' ' + className} onTouchStart={this.toggleTooltip} style={{ color }} />
                {isHover && (
                    <DropdownWrapper>
                        <Dropdown isExtendUp nipX="calc(100% - 18px)">
                            {children}
                        </Dropdown>
                    </DropdownWrapper>
                )}
            </Container>
        )
    }
}
