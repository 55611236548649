const PlusCircleIcon = () => (
    <svg
        width="122"
        height="122"
        viewBox="0 0 122 122"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient x1="12.998%" y1="81.019%" x2="100%" y2="-4.517%" id="a">
                <stop stopColor="#0F9" offset="0%" />
                <stop stopColor="#0CF" offset="100%" />
            </linearGradient>
            <linearGradient x1="-14.005%" y1="112.13%" x2="100%" y2="0%" id="c">
                <stop stopColor="#0CF" offset="0%" />
                <stop stopColor="#0F9" offset="100%" />
            </linearGradient>
            <path
                d="M21.25 14.75V2.4h-6.5v12.35H2.4v6.5h12.35V33.6h6.5V21.25H33.6v-6.5H21.25ZM36 12.35v11.3H23.65V36h-11.3V23.65H0v-11.3h12.35V0h11.3v12.35H36Z"
                id="b"
            />
        </defs>
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="url(#a)" strokeWidth="2" cx="60" cy="60" r="60" />
            <use fill="url(#c)" fillRule="nonzero" xlinkHref="#b" transform="translate(42 42)" />
        </g>
    </svg>
)

export default PlusCircleIcon
