import { useContext, useEffect, useRef, useState } from 'react'
import { IAnimationElement } from 'shared/types/animation'
import styled from 'styled-components'
import { ANIMATION_DRIFT_SPEED, ANIMATION_MIN_SCALE, ANIMATION_SCALE_DIFF } from '../constants'
import { AboutPageContext } from './../context'
import { bloop } from '../style'

const Container = styled.div`
    left: 0;
    position: fixed;
    top: 0;
    z-index: 10;
`

const ImageWrapper = styled.div`
    height: 200px;
    left: 0;
    margin: -150px 0 0 -100px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 300px;
`

const Image = styled.img`
    animation: ${bloop} 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1;
    display: block;
    height: 100%;
    object-fit: contain;
    width: 100%;
`

const AboutPageInfoAnimation = () => {
    const { hoverImages, clearHoverImages } = useContext(AboutPageContext)
    const [, setDrawTime] = useState<number>(0)
    const positionRef = useRef<IAnimationElement[]>([])
    const timeRef = useRef<number>(0)
    const requestRef = useRef<number | null>(null)

    const draw = () => {
        let offscreenCount: number = 0
        positionRef.current.forEach((position, i) => {
            if (position.y < -500) {
                offscreenCount++
                return
            }
            positionRef.current[i] = {
                ...position,
                x: position.x + position.drift * position.scale,
                y: position.y - 3 * position.scale,
                rotation: position.rotation + position.drift * position.scale,
            }
        })
        if (offscreenCount === positionRef.current.length) {
            clearHoverImages()
        } else {
            timeRef.current = (timeRef.current || 0) + 1
            setDrawTime(timeRef.current)
            requestRef.current = requestAnimationFrame(draw)
        }
    }

    useEffect(() => {
        requestRef.current = requestAnimationFrame(draw)
        return () => {
            requestRef.current && cancelAnimationFrame(requestRef.current)
        }
    }, [])

    useEffect(() => {
        const positions: IAnimationElement[] = positionRef.current || []
        hoverImages.forEach(({ x, y }, i) => {
            if (!positions[i]) {
                positions.push({
                    x,
                    y,
                    rotation: (Math.random() - Math.random()) * 15,
                    drift: (Math.random() - Math.random()) * ANIMATION_DRIFT_SPEED,
                    scale: ANIMATION_MIN_SCALE + ANIMATION_SCALE_DIFF * Math.random(),
                })
            }
        })
    }, [hoverImages])

    return (
        <Container>
            {hoverImages.map(({ url }, i) => {
                const {
                    x = 0,
                    y = 0,
                    rotation = 0,
                    scale = 0,
                } = positionRef.current ? positionRef.current[i] || {} : {}
                return (
                    <ImageWrapper
                        key={`hoverimage-${i}`}
                        style={{ transform: `translate3d(${x}px, ${y}px, 0) rotate(${rotation}deg) scale(${scale})` }}
                    >
                        <Image src={url} alt="" />
                    </ImageWrapper>
                )
            })}
        </Container>
    )
}

export default AboutPageInfoAnimation
