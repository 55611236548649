import { giphyBlack } from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import { createContext, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import styled, { css } from 'styled-components'
import { headerZIndex, margin } from '../constants'
import { desktop } from '../css'
import Logo from './logo'

// const Menu = lazy(() => import('./menu'))
import Menu from './menu'
// const DesktopMenu = lazy(() => import('./nav/desktop/dropdown'))
import DesktopMenu from './nav/desktop/dropdown'
// const Nav = lazy(() => import('./nav'))
import Nav from './nav'
// const DesktopNav = lazy(() => import('./nav/desktop'))
import DesktopNav from './nav/desktop'

const containerPadding = `${margin * 2}px 0 1px ${margin}px;`

const Container = styled.div`
    position: relative;
    padding: ${containerPadding};
    background: ${giphyBlack};
    z-index: ${headerZIndex};
    ${desktop(css`
        padding-left: 0;
        padding-right: 0;
    `)}
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`
const Giphy = styled(Logo)`
    ${desktop(css`
        visibility: hidden;
    `)}
`

type MenuState = undefined | 'user' | 'logged out user' | 'category' | 'desktop'

type HeaderContextProps = {
    menu: MenuState
    isSearchVisible: boolean
    setIsSearchVisible: (isVisible: boolean) => void
    setMenuState: (state: MenuState) => void
    user: IUser | undefined | null
    hasFavorites?: boolean
}

export const HeaderContext = createContext({} as HeaderContextProps)
// the desktop ui search bar has to scoot aside but the header is fixed so we need to guess at this
export const desktopHeaderWidth = 190
const Header = ({
    user,
    onLogoClick,
    hasFavorites,
}: {
    user?: IUser | null
    onLogoClick?: () => void
    hasFavorites?: boolean
}) => {
    const [menu, setMenuState] = useState<MenuState>(undefined)
    const [delayedMenu, delayedSetMenuState] = useState<MenuState>(menu)
    useDebounce(
        () => {
            setMenuState(delayedMenu)
        },
        50,
        [delayedMenu]
    )
    const [isSearchVisible, setIsSearchVisible] = useState(false)
    return (
        <Container>
            <HeaderContext.Provider
                value={{
                    user,
                    menu,
                    hasFavorites,
                    setMenuState: (state) => {
                        if (state === 'desktop') {
                            setMenuState(state)
                            delayedSetMenuState(state)
                        } else {
                            delayedSetMenuState(state)
                        }
                    },
                    isSearchVisible,
                    setIsSearchVisible,
                }}
            >
                <HeaderContainer>
                    <Giphy onClick={onLogoClick} />
                    <Nav />
                    <DesktopNav />
                </HeaderContainer>
                {menu === 'desktop' ? (
                    <div style={{ position: 'relative' }}>
                        <DesktopMenu />
                    </div>
                ) : menu !== undefined ? (
                    // mobile menu
                    <Menu
                        onItemClick={(path) => {
                            location.href = path
                        }}
                    />
                ) : null}
            </HeaderContext.Provider>
        </Container>
    )
}

export default Header
