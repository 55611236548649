import { Logger } from '@giphy/js-util'
import { useLocation } from 'react-router'
import { usePrevious } from 'react-use'
import { trackPageView } from 'shared/util/analytics'

const ReportOnSPA = () => {
    const { pathname } = useLocation()
    const prevPathname = usePrevious(pathname)
    if (prevPathname && pathname !== prevPathname) {
        Logger.info('route change, report:', pathname)
        trackPageView(pathname)
    }
    return null
}

export default ReportOnSPA
