import { CSSProperties, ForwardedRef, forwardRef, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { ControlSize, Gradient } from 'types'
import { heightFromControlSize } from 'utils'
import Icon, { iconComponent } from '../../../types/icons'
import { ButtonProps } from '../button'
import { Button, Inverted } from './style'

export interface IconButtonProps extends Omit<ButtonProps, 'iconLocation' | 'textColor'> {
    icon: Icon
    iconColor?: string
    iconScale?: number
    inverted?: boolean
    size?: ControlSize
    style?: CSSProperties
    gradient?: Gradient
}

const IconButton = (
    {
        color,
        icon,
        iconColor,
        iconScale = 1,
        gradient,
        inverted = false,
        size = 'small',
        style,
        ...rest
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
) => {
    const IconComponent = useMemo(() => iconComponent(icon), [icon])
    const iconHtml = useMemo(() => {
        return inverted ? renderToString(<IconComponent />) : ''
    }, [IconComponent, inverted])
    const iconSize = useMemo(() => Math.round(heightFromControlSize(size) * 0.5 * iconScale), [iconScale, size])

    const maskCss = `url(data:image/svg+xml,${encodeURIComponent(
        iconHtml
    )}) center center/${iconSize}px ${iconSize}px no-repeat`

    const maskStyle = {
        WebkitMask: maskCss,
        Mask: maskCss,
    }

    return (
        <Button
            {...rest}
            $size={size}
            color={inverted ? 'transparent' : color}
            gradient={inverted ? undefined : gradient}
            ref={ref}
            size={size}
            style={style}
        >
            {inverted ? (
                <Inverted $color={color} $gradient={gradient} $iconColor={iconColor} style={maskStyle} />
            ) : (
                <IconComponent color={iconColor || 'currentColor'} style={{ height: iconSize, width: iconSize }} />
            )}
        </Button>
    )
}

export default forwardRef(IconButton)
