import { FC, useContext } from 'react'
import * as React from 'react'
import { addGifs, fetchChannel } from 'shared/api/channels'
import Logger from 'shared/util/log'
import EditAPIContext from './edit-api'
import MessageContext from './message'
import RefreshDataContext from './refresh-data'
import { IGif } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'

export const useSaveGifToCollection = () => {
    const { refreshChannel } = useContext(RefreshDataContext)
    const { sendMessage } = useContext(MessageContext)
    const saveGif = async (gifs: IGif[], channelId: number) => {
        const suffix = gifs.length > 1 ? 's' : ''
        try {
            const items = gifs.map((gif) => {
                setGADataLayer({
                    event: 'add_to_collection',
                    options: {
                        content: getContentOfGif(gif),
                        creator: getCreatorOfGif(gif),
                    },
                })
                return {
                    gif: gif.id,
                    channel: channelId,
                }
            })

            await addGifs(items)
            Logger.info(`Channel ${channelId} updated, fire refresh`)
            // we probably don't need to fetch the channel here
            // since the added gif isn't reflected in a channel's data
            // but all we have is channel id and refreshChannel needs a channel
            refreshChannel(await fetchChannel(channelId), { deepRefresh: true })
            sendMessage(`GIF${suffix} added to collection`, 'success')
        } catch (e) {
            console.error(`Add gifs to channel error:`, e)
            sendMessage(`There was an error adding the GIF${suffix} to the collection`, 'error')
        }
    }
    return saveGif
}
type Props = {
    children: React.ReactNode
}

const EditAPIContextManager: FC<Props> = ({ children }) => {
    const saveGifsToCollection = useSaveGifToCollection()
    return <EditAPIContext.Provider value={{ saveGifsToCollection }}>{children}</EditAPIContext.Provider>
}

export default EditAPIContextManager
