import { giphyBlack, giphyBlue, giphyWhite } from '@giphy/colors'
import { useContext } from 'react'
import { removeGif } from 'shared/api/channels'
import Test from 'shared/components/test'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import MessageContext from 'shared/contexts/message'
import ModalContext from 'shared/contexts/modal'
import { getWebpOrUrl } from 'shared/util/gif-extract'
import styled from 'styled-components'
import { CY_COLLECTION__SUCCESS_MESSAGE } from 'ui'

const GIF_BOTTOM = 12 // Margin from the bottom of the GIF on GIF Detail Page
const GIF_RIGHT = 12 // Margin from the right of the GIF on GIF Detail Page

const Container = styled.div<{ width?: number; height?: number; top?: number }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    position: absolute;
    z-index: 2;
    top: ${(props) => props.top && `${props.top}px`};
`

const IconCheck = styled.span`
    color: rgb(0, 230, 204);
    background: black;
    height: 20px;
    width: 20px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 4px;

    &:before {
        margin-top: 2px;
    }
`

const IconUndo = styled.span`
    margin-left: 10px;
    margin-bottom: -3px;
`

const SuccessMessage = styled.div`
    display: flex;
    align-items: center;
    height: 52px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    background: linear-gradient(-135deg, rgb(0, 230, 204) 0%, rgb(0, 255, 153) 100%);
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
    color: ${giphyBlack};
    font-size: 15px;
    b {
        font-weight: bold;
    }
`

const UndoButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    padding: 15px;
    font-weight: bold;
    cursor: pointer;
`

const GifContainer = styled.div<{ bottom?: number; right?: number }>`
    position: absolute;
    box-shadow: 0 2px 14px rgb(0 0 0 / 33%);
    bottom: ${({ bottom }) => bottom || GIF_BOTTOM}px;
    right: ${({ right }) => right || GIF_RIGHT}px;
`

const Item = styled.div<{ gutter?: boolean }>`
    cursor: pointer;
    width: 153px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
`

const ItemGif = styled.img`
    height: 88px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;
`

const ItemText = styled.div`
    position: relative;
    border-radius: 0px 0px 6px 6px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: ${giphyWhite};
    padding: 5px 10px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${giphyBlue};
`

const CollectionsLink = styled.a`
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: underline;
    }
`

const MultipleAddOverflow = styled.div`
    text-overflow: ellipsis;
    width: 370px;
    overflow: hidden;
    white-space: nowrap;
`

type CollectionsSuccessMessageProps = {
    width?: number
    height?: number
    top?: number
    undoAction?: () => void
    className?: any
    showGif?: boolean
}

const CollectionsSuccessMessage = ({
    width,
    height,
    top,
    undoAction,
    className = '',
    showGif,
}: CollectionsSuccessMessageProps) => {
    const {
        addGifToCollectionSuccessMessage,
        addGifsToCollectionSuccessMessage,
        addGifToMultipleCollectionsSuccessMessage,
        collectionCreatedSuccessMessage,
        setAddGifToCollectionSuccessMessage,
        combineCollectionsSuccessMessage,
    } = useContext(CollectionContext)
    const { sendMessage } = useContext(MessageContext)
    const { openCollectionCabinet } = useContext(ModalContext)
    const { setGif } = useContext(CollectionContext)

    const removeGifFromCollection = async () => {
        if (addGifToCollectionSuccessMessage) {
            removeGif({
                gif: addGifToCollectionSuccessMessage.featuredGif.id,
                channel: addGifToCollectionSuccessMessage?.channel.id,
            })
            setAddGifToCollectionSuccessMessage(undefined)
            openCollectionCabinet()
            setGif(addGifToCollectionSuccessMessage.featuredGif)
            sendMessage('GIF removed from collection', 'info')
        }

        if (addGifToMultipleCollectionsSuccessMessage) {
            for (const selectedChannel of addGifToMultipleCollectionsSuccessMessage.channels) {
                removeGif({
                    gif: addGifToMultipleCollectionsSuccessMessage.gif.id,
                    channel: selectedChannel.id,
                })
            }
            setAddGifToCollectionSuccessMessage(undefined)
            openCollectionCabinet()
            setGif(addGifToMultipleCollectionsSuccessMessage.gif)
            sendMessage('GIF removed from collections', 'info')
        }
    }

    const getSuccessMessage = () => {
        if (combineCollectionsSuccessMessage?.targetChannelName) {
            return combineCollectionsSuccessMessage?.targetChannelName
        }

        if (addGifToCollectionSuccessMessage) {
            return (
                <CollectionsLink href={addGifToCollectionSuccessMessage?.channel.url}>
                    {addGifToCollectionSuccessMessage?.channel.display_name}
                </CollectionsLink>
            )
        }

        if (addGifsToCollectionSuccessMessage) {
            return addGifsToCollectionSuccessMessage?.channel.display_name
        }

        return <></>
    }

    if (
        !addGifToCollectionSuccessMessage &&
        !combineCollectionsSuccessMessage &&
        !addGifsToCollectionSuccessMessage &&
        !collectionCreatedSuccessMessage &&
        !addGifToMultipleCollectionsSuccessMessage
    ) {
        return <></>
    }

    const DefaultMessage = () => {
        if (combineCollectionsSuccessMessage || addGifToCollectionSuccessMessage || addGifsToCollectionSuccessMessage) {
            return (
                <span>
                    <b></b>
                    <b>{combineCollectionsSuccessMessage?.draggableName || null}</b> Added to{' '}
                    <b>{getSuccessMessage()}</b>
                </span>
            )
        }
        return <></>
    }

    const MultipleAddedMessage = () => {
        if (addGifToMultipleCollectionsSuccessMessage) {
            return (
                <MultipleAddOverflow>
                    Added to{' '}
                    <b>
                        {addGifToMultipleCollectionsSuccessMessage.channels.map((channel, key) => (
                            <CollectionsLink key={key} href={addGifToCollectionSuccessMessage?.channel.url}>
                                {channel.display_name}
                                {addGifToMultipleCollectionsSuccessMessage.channels.length !== key + 1 && ', '}
                            </CollectionsLink>
                        ))}
                    </b>
                </MultipleAddOverflow>
            )
        }

        return <></>
    }

    const CreatedCollectionsMessage = () => {
        if (collectionCreatedSuccessMessage) {
            return (
                <span>
                    <b>
                        <CollectionsLink href="{collectionCreatedSuccessMessage.channel.url}">
                            {collectionCreatedSuccessMessage?.channel?.display_name}
                        </CollectionsLink>{' '}
                        successfully created
                    </b>
                </span>
            )
        }
        return <></>
    }

    const gif = addGifToCollectionSuccessMessage && getWebpOrUrl(addGifToCollectionSuccessMessage.featuredGif)

    return (
        <Container width={width} height={height} top={top} className={className}>
            <Test id={CY_COLLECTION__SUCCESS_MESSAGE}>
                <SuccessMessage>
                    <IconCheck className="ss-check" />
                    <DefaultMessage />
                    <MultipleAddedMessage />
                    <CreatedCollectionsMessage />
                    <UndoButton onClick={undoAction || removeGifFromCollection}>
                        Undo
                        <IconUndo className="ss-replay" />
                    </UndoButton>
                </SuccessMessage>
            </Test>
            {!!addGifToCollectionSuccessMessage && gif && showGif && (
                <GifContainer>
                    <Item>
                        <a href={addGifToCollectionSuccessMessage.channel.url} target="_blank" rel="noreferrer">
                            <ItemGif src={gif} alt={addGifToCollectionSuccessMessage.channel.display_name} />
                        </a>
                        <ItemText>{addGifToCollectionSuccessMessage.channel.display_name}</ItemText>
                    </Item>
                </GifContainer>
            )}
            {!!addGifToMultipleCollectionsSuccessMessage &&
                addGifToMultipleCollectionsSuccessMessage.channels.slice(0, 3).map((channel, key) => {
                    return (
                        <GifContainer key={key} bottom={(key + 1) * GIF_BOTTOM} right={(key + 1) * GIF_RIGHT}>
                            <Item>
                                <ItemGif src={getWebpOrUrl(channel.featured_gif)} alt={channel.display_name} />
                                <ItemText>{channel.display_name}</ItemText>
                            </Item>
                        </GifContainer>
                    )
                })}
        </Container>
    )
}

export default CollectionsSuccessMessage
