import { giphyDarkestGrey } from '@giphy/colors'
import { ReactNode, useEffect, useState } from 'react'
import Portal from '../portal'
import { Container } from './style'

type Props = {
    color?: string
    children?: ReactNode
    className?: string
    open: boolean
    side?: 'left' | 'right'
}

const Sidebar = ({ color = giphyDarkestGrey, children, className, open, side = 'right' }: Props) => {
    const [hidden, setHidden] = useState<boolean>(!open)

    function hide() {
        if (!open) {
            setHidden(true)
        }
    }

    useEffect(() => {
        if (open) {
            setHidden(false)
        }
    }, [open])

    if (hidden) {
        return null
    }

    return (
        <Portal>
            <Container $color={color} $open={open} $side={side} className={className} onTransitionEnd={() => hide()}>
                {children}
            </Container>
        </Portal>
    )
}

export default Sidebar
