import { Video } from '@giphy/react-components'
import { useEffect, useRef } from 'react'
import * as React from 'react'
import VideoPlayerAnalytics, { GAPlayerEventsAPI, quartileOptions } from './analytics'
import { useVideoAnalytics } from './analytics-hook'

type GAProps = {
    gridPosition?: number
    copied?: boolean
    category?: string
    setEvents?: (events: VideoPlayerAnalytics) => void
    setVideoEl?: (api: HTMLVideoElement) => void
}

type VideoProps = React.ComponentProps<typeof Video>

/**
 * Wrap the SDK video element and give it an api so GA can get its data
 */
const GAVideo = (props: VideoProps & GAProps) => {
    const el = useRef<HTMLVideoElement | undefined>()
    const count = useRef(0)
    const isWaiting = useRef(false)
    const whenStalledTimeStamp = useRef(0)
    const events = useVideoAnalytics(props.gif.id, props.category || '', props.gridPosition)

    useEffect(() => {
        if (props.setEvents && events) {
            props.setEvents(events)
        }
    }, [events])

    // this api is passed to the events
    const api = useRef<GAPlayerEventsAPI>({
        getDuration: () => el.current?.duration || 0,
        getVideoData: () => props.gif,
        getLoopCount: () => count.current,
        getIsMutedState: () => !!el?.current?.muted,
        getCurrentTime: () => el?.current?.currentTime || 0,
        getVideoURL: () => el.current?.src || '',
        getCCEnabled: () => !!props.ccEnabled,
    })

    useEffect(() => {
        if (api?.current) events?.onMute(!!props?.muted)
    }, [props.muted])

    useEffect(() => {
        if (api?.current && props.copied) {
            events?.onCopyLink()
        }
    }, [props.copied])

    if (!events) {
        return null
    }

    return (
        <Video
            {...props}
            setVideoEl={(videoEl) => {
                el.current = videoEl
                props.setVideoEl?.(videoEl)
                events.onPlayerReady?.(api.current)
            }}
            onFirstPlay={(time: number) => {
                props.onFirstPlay?.(time)
                events.onFirstPlay?.(time)
                events.onPlay?.()
            }}
            onLoop={(c) => {
                props.onLoop?.(c)
                events.onLoop?.()
                count.current = c
            }}
            onQuartile={(qt: quartileOptions) => {
                props.onQuartile?.(qt)
                events.onQuartile?.(qt)
            }}
            onCanPlay={() => {
                if (isWaiting.current) {
                    isWaiting.current = false
                    events?.onStalledResume?.(Date.now() - whenStalledTimeStamp.current)
                }
                props.onCanPlay?.()
            }}
            onWaiting={(count: number) => {
                isWaiting.current = true
                whenStalledTimeStamp.current = Date.now()
                props.onWaiting?.(count)
            }}
            onEnded={() => {
                props.onEnded?.()
                events?.onEnded?.()
            }}
        />
    )
}

export default GAVideo
