import { LoaderSize } from '../types'
import { Block, Container } from './style'

type Props = {
    className?: string
    color?: string
    size: LoaderSize
}

const LoaderBlocks = ({ className, color, size }: Props) => {
    const blockStyle = color ? { backgroundColor: color } : undefined

    return (
        <Container className={className} loaderSize={size}>
            <Block style={blockStyle} />
            <Block style={blockStyle} />
            <Block style={blockStyle} />
            <Block style={blockStyle} />
            <Block style={blockStyle} />
        </Container>
    )
}

export default LoaderBlocks
