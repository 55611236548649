import { Gradient } from 'types'
import { MessageProps, PortalMessageOptions, PortalMessageStyle } from './types'

export function gradientFromMessageStyle(style: PortalMessageStyle): Gradient {
    switch (style) {
        case 'default':
            return 'purple-indigo'
        case 'error':
            return 'pink-red'
        case 'success':
            return 'blue-green'
    }
}

export function messagePropsFromMessageOptions(data: PortalMessageOptions): MessageProps {
    const { style = 'default' } = data

    return {
        gradient: gradientFromMessageStyle(style),
    }
}
