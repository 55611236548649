exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2JRurIdmDIaQTyDGN2GCpY {\n    background: rgba(0, 0, 0, 0.7);\n    padding-bottom: 10px;\n\n}\n\n    ._2JRurIdmDIaQTyDGN2GCpY:after {\n    \tcontent: '';\n    \tclear: both;\n    \tdisplay: block\n    }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .info-item {\n            box-sizing: border-box;\n            float: left;\n            padding: 40px;\n            width: 50%;\n        }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .info-item:nth-child(2n + 1) {\n                clear: left;\n            }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-title {\n            font-size: 16px;\n            line-height: 1.2;\n            margin-bottom: 18px;\n            font-weight: 600;\n        }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon {\n            background: url(\"/static/img/app_landing/app_icons.svg\") no-repeat;\n            background-size: 233px 108px;\n            clear: both;\n            display: inline-block;\n            height: 60px;\n            margin: 0 auto 15px;\n            width: 60px;\n        }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.search-icon { background-position: 0 -55px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.smiley-icon { background-position: -60px -55px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.heart-icon { background-position: -120px -55px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.lucky-icon { background-position: -180px -55px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.capture-icon { background-position: 6px 5px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.easy-icon { background-position: -61px 5px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.edit-icon { background-position: -120px 5px; }\n\n    ._2JRurIdmDIaQTyDGN2GCpY .item-icon.send-icon { background-position: -180px 5px; }\n", ""]);

// exports
exports.locals = {
	"appFeatures": "_2JRurIdmDIaQTyDGN2GCpY"
};