import { filter, without, includes } from 'lodash'
import { updateGif, updateChannelGif } from '../api'
import log from '../util/log'
import { receivedGifs, removeGifs, clearGetGifsMemo } from './gifs'
import { updateSaveProgress, isSavingGifs } from './edit-mode'
import { updateCTA } from 'shared/api/index'

const mapGifPropsUpdate = (gif, prop, value) => {
    switch (prop) {
        case 'create_tag': {
            const tags = gif.tags ? gif.tags.slice(0) : []
            tags.unshift(value)
            return { ...gif, tags }
        }
        case 'delete_tag':
            return { ...gif, tags: without(gif.tags || [], value) }
        case 'delete_all_tags':
            return { ...gif, tags: [] }
        case 'feature_tag':
            const { featured_tags = [] } = gif
            return { ...gif, featured_tags: [...featured_tags, value] }
        case 'unfeature_tag':
            return { ...gif, featured_tags: without(gif.featured_tags || [], value) }
        case 'delete_channel':
            return { ...gif, channels: filter(gif.channels, (channel) => channel.id !== value.id) }
        case 'add_channel': {
            const newGif = { ...gif }
            newGif.channels = (newGif.channels || []).concat([value])
            return newGif
        }
        case 'description': {
            const newGif = { ...gif }
            const newVideo = { ...newGif.video }
            newVideo['description'] = value
            newGif['video'] = newVideo
            return newGif
        }
        default: {
            const newGif = { ...gif }
            newGif[prop] = value
            return newGif
        }
    }
}

const getErrorMessage = (ids, prop, value) => {
    const id = ids.join(', ')
    switch (prop) {
        case 'create_tag':
            return `Error creating tag "${value}" on ${id}`
        case 'delete_tag':
            return `Error deleting tag "${value}" on ${id}`
        case 'delete_all_tags':
            return `Error deleting all tags on ${id}`
        case 'feature_tag':
            return `Error featuring tag "${value}" on ${id}`
        case 'unfeature_tag':
            return `Error unfeaturing tag "${value}" on ${id}`
        case 'delete_channel':
            return `Error deleting channel ${id}`
        case 'add_channel':
            return `Error adding channel ${id}`
        case 'rating':
            return `Error rating ${id}`
        case 'mark_explicit':
            return `Error marking ${id} explicit`
        case 'content_takedown':
            return `Error taking down ${id}`
        case 'repair_media':
            return `Error repairing ${id}`
        default:
            return `Error changing ${prop} for ${id}`
    }
}

export default function (gifs = [], updateData = {}) {
    return async function (dispatch) {
        const gifIds = gifs.map((gif) => gif.id)
        if (!gifIds.length) {
            return Promise.reject()
        }
        const props = Object.keys(updateData)
        const values = Object.values(updateData)
        let finishedGifs = 0

        dispatch(isSavingGifs(true))

        // remove gif
        if (updateData.delete_gif || updateData.mark_explicit || updateData.content_takedown) {
            dispatch(removeGifs({ gifIds }))
        }

        const erroredGifIds = []
        const promises = []
        let total = gifs.length * props.length

        gifs.forEach(({ id }) => {
            props.forEach((prop, i) => {
                const propValue = values[i]
                const isChannel = prop === 'delete_channel' || prop === 'add_channel'
                const isRemoveChannel = prop === 'delete_channel'
                const isCTA = prop === 'cta'
                const isNoOp = ['content_takedown'].includes(prop)
                promises.push(
                    new Promise(async (resolve, reject) => {
                        try {
                            const data = isNoOp
                                ? {}
                                : await (isChannel
                                      ? updateChannelGif(id, propValue.id, isRemoveChannel)
                                      : isCTA
                                      ? updateCTA(id, propValue.link, propValue.text)
                                      : updateGif(id, prop, propValue))
                            finishedGifs++
                            dispatch(updateSaveProgress(finishedGifs / total))
                            resolve(data)
                        } catch (e) {
                            // 400 is okay here, we may have deleted a tag
                            // or removed a channel that didn't exist for this id
                            const ignore_400_error = includes(['delete_channel', 'delete_tag'], prop)
                            if (ignore_400_error && e.response && e.response.status === 400) {
                                finishedGifs++
                                dispatch(updateSaveProgress(finishedGifs / total))
                                log.warn(`Handled 400 response: ${getErrorMessage([id], prop, propValue)}`)
                                resolve()
                            } else {
                                log.warn(`Error ${e}`)
                                erroredGifIds.push(id)
                                isCTA ? reject() : resolve()
                            }
                        }
                    })
                )
            })
        })

        return new Promise(async (resolve, reject) => {
            try {
                const data = (await Promise.all(promises)) || []
                const { message } = Array.isArray(data) ? data[0] || {} : {}
                const successfulGifs = gifs.filter(({ id }) => erroredGifIds.indexOf(id) < 0)
                props.forEach((prop, i) => {
                    const propValue = values[i]
                    const isChannel = prop === 'delete_channel' || prop === 'add_channel'
                    const isRemoveChannel = prop === 'delete_channel'

                    if (erroredGifIds.length) {
                        const errorMessage = getErrorMessage(erroredGifIds, prop, propValue)
                        global.showError && global.showError(errorMessage)
                    }
                    if (finishedGifs > 0) {
                        const mediaType = successfulGifs.every((item) => item.is_video) ? 'Clip' : 'GIF'
                        const successMessage =
                            message ||
                            `${finishedGifs > 1 ? `These ${mediaType}s were` : `This ${mediaType} was`} ${
                                isChannel
                                    ? `${isRemoveChannel ? 'removed from' : 'added to'} ${
                                          propValue.short_display_name || propValue.display_name || 'channel'
                                      }`
                                    : 'updated'
                            }!`
                        global.showSuccess && global.showSuccess(successMessage)
                    }
                    // reset the getGifs selector
                    clearGetGifsMemo()
                    dispatch(
                        receivedGifs({
                            gifs: successfulGifs.map((gif) => mapGifPropsUpdate(gif, prop, propValue)),
                        })
                    )
                })

                dispatch(isSavingGifs(false))
                resolve()
            } catch (e) {
                dispatch(isSavingGifs(false))
                reject()
            }
        })
    }
}
