import styled, { keyframes, css } from 'styled-components'
import { AnimatedButton } from 'shared/css/buttons'
import Favorite from 'shared/components/favorite/favorite'

const Embed = keyframes`
    0% {
        background-position: -26px -178px;
    }
    20% {
        background-position: -56px -178px;
    }
    40% {
        background-position: -86px -178px;
    }
    60% {
        background-position: -116px -178px;
    }
    100% {
        background-position: -146px -178px;
    }
}
`

const Share = keyframes`
    0% {
        background-position: 3px -147px;
    }
    18% {
        background-position: -27px -147px;
    }
    36% {
        background-position: -57px -147px;
    }
    54% {
        background-position: -87px -147px;
    }
    72% {
        background-position: -117px -147px;
    }
    90% {
        background-position: -147px -147px;
    }
    100% {
        background-position: -147px -147px;
    }
}
`

export const Container = styled.div`
    margin-bottom: 16px;
`

export const Icon = styled.div`
    width: 40px;
    display: inline-block;
    padding: 0px 9px 0 9px;
    font-size: 20px;
`

export const ShareIcon = styled(AnimatedButton)<{ active?: boolean }>`
    padding: 0;
    width: 36px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    margin-right: 6px;
    background-position: 3px -147px;
    transform: scale(1.6);
    :hover {
        animation: ${Share} 1s steps(1) infinite;
    }

    ${(props) =>
        props.active &&
        css`
            animation: ${Share} 1s steps(1) infinite;
        `}
`

export const EmbedIcon = styled(AnimatedButton)<{ active?: boolean }>`
    padding: 0;
    width: 36px;
    height: 35px;
    display: inline-block;
    line-height: 35px;
    margin-right: 6px;
    background-position: 4px -178px;
    transform: scale(1.6);
    :hover {
        animation: ${Embed} 0.5s steps(1) infinite alternate;
    }
    ${(props) =>
        props.active &&
        css`
            animation: ${Embed} 0.5s steps(1) infinite alternate;
        `}
`

export const Button = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    height: 45px;
    line-height: 36px;
    i {
        margin-top: -1px;
    }
    span {
        display: inline-block;
        vertical-align: top;
        margin-left: 8px;
    }
    &:hover > ${ShareIcon} {
        animation: ${Share} 1s steps(1) infinite;
    }
    &:hover > ${EmbedIcon} {
        animation: ${Embed} 1s steps(1) infinite;
    }
`

export const ButtonDiv = styled.div`
    &:hover > ${Button} {
        opacity: 0.65;
    }
    &:hover > ${Button}:hover {
        opacity: 1;
    }
`

export const FavoriteStyled = styled(Favorite)`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    float: none;

    div {
        margin-right: 8px;
        display: inline-block;
        transform: scale(1.5);
    }
`

export const LeftIconContainer = styled.div`
    width: 43px;
    margin-right: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const LeftShare = styled(LeftIconContainer)`
    padding-left: 5px;
`

export const LeftEmbed = styled(LeftIconContainer)`
    padding-left: 2px;
`
