import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import { useContext } from 'react'
import ModalContext from 'shared/contexts/modal'
import UserContext from 'shared/contexts/user'
import { IChannel } from 'shared/types/channel'
import styled from 'styled-components'
import PlusCircleIcon from './plus-circle-icon'

const Container = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
`

const PlusButton = styled.button`
    height: 122px;
    background: transparent;
`

const Copy = styled.div`
    padding: 20px;
    font-size: 17px;
    font-weight: bold;
    color: ${giphyLightGrey};
    display: block;
    span {
        color: ${giphyWhite};
        font-size: 20px;
        font-family: Nexablack;
    }
`
const EmptyCollection = ({ channel }: { channel: IChannel }) => {
    const { openNewCollectionPanel } = useContext(ModalContext)
    const { user } = useContext(UserContext)
    return (
        <Container>
            <PlusButton
                onClick={() => {
                    openNewCollectionPanel({ parentChannel: channel, userId: user.id, redirectOnCreate: true })
                }}
            >
                <PlusCircleIcon />
            </PlusButton>
            <Copy>
                <span>No Collections Yet</span>
                <p>Create your first collection!</p>
            </Copy>
        </Container>
    )
}

export default EmptyCollection
