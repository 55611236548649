import styled from 'styled-components'
import { GifOverlayProps } from '@giphy/react-components'
import { getExplorePath } from 'utils/src/url'

const TagOverlayContainer = styled.div<{ isHovered: boolean }>`
    position: absolute;
    bottom: 0;
    color: white;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 12px;
    white-space: nowrap;
    padding: 4px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    font-weight: bold;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
    transition: opacity 300ms;
    box-sizing: border-box;
`

const Tag = ({ tag }: { tag: string }) => {
    return (
        <span
            onClick={(e) => {
                // TODO move this to a prop once desktop explore and search are converted to nextjs
                e.preventDefault()
                e.stopPropagation()
                const path = getExplorePath(tag)
                if (e.metaKey) {
                    window.open(`${location.origin}${path}`)
                } else {
                    location.href = path
                }
            }}
        >
            #{tag}
            &nbsp;
        </span>
    )
}

const mapTags = (tags: string[] = []) => tags.map((tag) => (tag ? <Tag tag={tag} key={tag} /> : ''))
const TagOverlay = ({ gif, isHovered }: GifOverlayProps) => (
    <TagOverlayContainer isHovered={isHovered}>{mapTags(gif.tags)}</TagOverlayContainer>
)

export default TagOverlay
