import { ForwardedRef, forwardRef, useState } from 'react'
import { ControlSize, Gradient } from 'types'
import { useHighlightStyle } from '../hooks/use-highlight-style'
import Button from './button'
import { Highlight, Options } from './style'

type Props = {
    activeIndex?: number
    backgroundColor: string
    className?: string
    color: string
    controlId: string
    disabled?: boolean
    gradient?: Gradient
    options: string[]
    optionDescriptions?: string[]
    size: ControlSize
    onChange: (index: number) => void
}

const SegmentedControlOptions = (
    {
        activeIndex,
        backgroundColor,
        className,
        color,
        controlId,
        disabled,
        gradient,
        options,
        optionDescriptions = [],
        size,
        onChange,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const [buttonWidths, setButtonWidths] = useState<number[]>([])
    const highlightStyle = useHighlightStyle(buttonWidths, color, activeIndex, gradient)

    function onWidthChange(index: number, width: number) {
        if (width) {
            setButtonWidths((state) => {
                const newState = [...state]
                newState[index] = width
                return newState
            })
        }
    }

    return (
        <Options className={className} ref={ref}>
            {buttonWidths.length === options.length && <Highlight style={highlightStyle} />}
            {options.map((label, i) => {
                const isActive = i === activeIndex

                return (
                    <Button
                        active={isActive}
                        color={isActive ? color : backgroundColor}
                        disabled={disabled}
                        gradient={isActive ? gradient : undefined}
                        key={`${controlId}-option-${i}`}
                        size={size}
                        title={optionDescriptions[i]}
                        onClick={() => onChange(i)}
                        onWidthChange={(w) => onWidthChange(i, w)}
                    >
                        {label}
                    </Button>
                )
            })}
        </Options>
    )
}

export default forwardRef(SegmentedControlOptions)
