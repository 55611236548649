import { useContext } from 'react'
import styled from 'styled-components'
import { CONTENT_WIDTH } from '../constants'
import HoverItem from './hover-item'
import Animation from './animation'
import { AboutPageContext } from './../context'

const Container = styled.div`
    color: #fff;
    overflow: visible;
    margin: 0 auto;
    max-width: ${CONTENT_WIDTH}px;
`

const AboutPageInfo = () => {
    const { hoverImages } = useContext(AboutPageContext)
    return (
        <Container id="about-giphy" data-padded>
            <p>
                GIPHY is also everywhere your conversations are happening. From{' '}
                <HoverItem hoverId="imessage">iMessage</HoverItem>, to{' '}
                <HoverItem hoverId="facebook">Facebook</HoverItem>, <HoverItem hoverId="instagram">Instagram</HoverItem>
                , <HoverItem hoverId="snapchat">Snapchat</HoverItem>, <HoverItem hoverId="tiktok">TikTok</HoverItem>…
                and everywhere in-between.
            </p>
            <p>
                We are the platform where you can find the content you are passionate about — whether it be{' '}
                <HoverItem hoverId="reactions">reaction GIFs</HoverItem> for your convos,{' '}
                <HoverItem hoverId="realtime">real-time GIFs</HoverItem> of culture in action,{' '}
                <HoverItem hoverId="stickers">animated stickers</HoverItem> to layer your Stories, or
                conversation-starting <HoverItem hoverId="video">Clips</HoverItem> content.
            </p>
            <p>
                Whether you&lsquo;re a user, <HoverItem hoverId="brand">brand</HoverItem>,{' '}
                <HoverItem hoverId="partner">content partner</HoverItem>, <HoverItem hoverId="artist">artist</HoverItem>{' '}
                — you name it — GIPHY is home to the content making your conversations and stories more positive, more
                fun, and more <HoverItem hoverId="you">you</HoverItem>.
            </p>
            {hoverImages.length > 0 && <Animation />}
        </Container>
    )
}

export default AboutPageInfo
