exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FrSclPkBso5Y667lfumnp {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: rgba(0, 0, 0, 0.8);\n    width: 100%;\n    height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1FrSclPkBso5Y667lfumnp"
};