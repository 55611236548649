import { giphyWhite } from '@giphy/colors'
import { IChannel } from '@giphy/js-types'
import { useContext } from 'react'
import Carousel from 'shared/components/carousel/carousel'
import { AvatarBadge } from 'shared/components/users'
import UserContext from 'shared/contexts/user'
import styled from 'styled-components'

const ScrollGuy = styled.div`
    display: flex;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    margin-right: 6px;
`

const MiddleHeader = styled.div`
    display: flex;
    padding-bottom: 32px;
    flex-direction: column;
    align-items: flex-start;
`

const ChannelResults = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const StretchCarousel = styled(Carousel)`
    width: 100%;
    & h2 {
        color: ${giphyWhite};
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.3px;
        font-family: InterFace;
    }
`
// @ts-ignore
const Container = ({ paginates, ...props }) => (paginates ? PaginatedCarousel(props) : ScrollCarousel(props))

const PaginatedCarousel = ({ children, items }) => (
    <MiddleHeader>
        <ChannelResults>
            <StretchCarousel maxBlocks={4} title={`${items.length} channel${items.length > 1 ? `s` : ``}`}>
                {children}
            </StretchCarousel>
        </ChannelResults>
    </MiddleHeader>
)

const ScrollCarousel = ({ children }) => <ScrollGuy>{children}</ScrollGuy>

type Props = {
    items?: (IChannel & { response_id?: string })[]
    paginates?: boolean
    css: any
    responseId: string
}
const AvatarBadgeCarousel = ({ items, css, responseId, paginates = false }: Props) => {
    const { user: loggedInUser } = useContext(UserContext)
    if (!items) {
        return null
    }
    return (
        <Container paginates={paginates} items={items}>
            {items.map((channel, index) => {
                const { display_name, user, id, analytics_response_payload } = channel
                if (!user) {
                    console.error(`No user for ${display_name}, channel id: ${id}`)
                    return null
                }
                return (
                    <AvatarBadge
                        user={user}
                        title={display_name}
                        key={index}
                        height={50}
                        css={{
                            containerStyle: css.channelContainer,
                            profileStyle: css.channelProfile,
                            titleStyle: css.channelsTitle,
                        }}
                        pingbackAnalytics={{
                            data: {
                                search_response_id: responseId,
                            },
                            user: loggedInUser,
                            analyticsResponsePayload: analytics_response_payload,
                            eventType: 'CHANNEL_SEARCH',
                        }}
                    />
                )
            })}
        </Container>
    )
}

export default AvatarBadgeCarousel
