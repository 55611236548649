import { giphyBlue, giphyGreen, giphyPurple, giphyRed, giphyYellow } from '@giphy/colors'
import { IAnimationShape } from './index'

const ANIMATION_SHAPES = ['cutout', 'long', 'short', 'long', 'short']

const getCutoutShape = (size: number) => ({
    type: 'cutout',
    color: giphyRed,
    width: size * 3,
    height: size * 3,
    path: [
        [0, 0],
        [size, 0],
        [size, size],
        [size * 2, size],
        [size * 2, size * 2],
        [size * 3, size * 2],
        [size * 3, size * 3],
        [0, size * 3],
        [0, 0],
    ],
})

const getLongBlockShape = (size: number) => ({
    type: 'long',
    color: Math.random() > 0.5 ? giphyBlue : giphyGreen,
    width: size * 7,
    height: size,
    path: [
        [0, 0],
        [size * 7, 0],
        [size * 7, size],
        [0, size],
        [0, 0],
    ],
})

const getBlockShape = (size: number) => ({
    type: 'short',
    color: Math.random() > 0.5 ? giphyYellow : giphyPurple,
    width: size * 5,
    height: size,
    path: [
        [0, 0],
        [size * 5, 0],
        [size * 5, size],
        [0, size],
        [0, 0],
    ],
})

export const getAnimationShape = (size: number) => {
    const type = ANIMATION_SHAPES[Math.floor(ANIMATION_SHAPES.length * Math.random())]
    switch (type) {
        case 'cutout':
            return getCutoutShape(size) as IAnimationShape
        case 'long':
            return getLongBlockShape(size) as IAnimationShape
        default:
            return getBlockShape(size) as IAnimationShape
    }
}
