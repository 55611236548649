exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css"), undefined);
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/breakpoints.css"), undefined);
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!./username-badge.css"), undefined);

// module
exports.push([module.id, "._2i4LUydBEBkjQXHJpbzkg8 {\n    font-size: 16px;\n    display: flex;\n    align-items: stretch;\n    width: 100%;\n}\n\n.Ae5Gw5X2QjfrxxGeMYT2H {\n    flex: 1;\n    display: flex;\n    flex-flow: column nowrap;\n    justify-content: space-between;\n    align-items: stretch;\n    text-align: left;\n    overflow: hidden;\n}\n\n._3DiZ8MrB6UjtRTb29k-zhM {\n    font-size: 1.125em;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    text-align: left;\n}\n\n._3X2KSzdvKhAJqFwx32b8jg {\n    position: relative;\n    padding-right: 20px;\n}\n\n._3d-sAXwzxxGiCIp-2MJly6 {\n    display: inline;\n    max-width: 109px;\n    font-size: 14px;\n}\n\n@media " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/breakpoints.css").locals["desktop"] + " {\n\n._3d-sAXwzxxGiCIp-2MJly6 {\n        display: inline-block\n}\n    }\n\n._3q3_VjSjjdu5nqo0XqK0E {\n    display: inline-block;\n    position: relative;\n    left: 4px;\n    top: 4px;\n}\n\n@media " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/breakpoints.css").locals["desktop"] + " {\n\n._3q3_VjSjjdu5nqo0XqK0E {\n        display: inline-block;\n        top: 0px\n}\n    }\n", ""]);

// exports
exports.locals = {
	"typography": "'shared/css/typography.css'",
	"gifTitleMobile": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["gifTitleMobile"] + "",
	"bodyBold": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["bodyBold"] + "",
	"breakpoints": "'shared/css/breakpoints.css'",
	"desktop": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/breakpoints.css").locals["desktop"] + "",
	"container": "_2i4LUydBEBkjQXHJpbzkg8",
	"profileInfo": "Ae5Gw5X2QjfrxxGeMYT2H",
	"title": "_3DiZ8MrB6UjtRTb29k-zhM " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["bodyBold"] + "",
	"usernameWrapper": "_3X2KSzdvKhAJqFwx32b8jg",
	"usernameStyle": "_3d-sAXwzxxGiCIp-2MJly6 " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!./username-badge.css").locals["username"] + "",
	"verifiedBadgeWrapper": "_3q3_VjSjjdu5nqo0XqK0E"
};