import { IGif, IProfileUser } from '@giphy/js-types'
import { AttributionOverlay, GifOverlayProps } from '@giphy/react-components'
import checkIsMobile from 'is-mobile'
import { createContext, ElementType, SyntheticEvent, useContext, useRef } from 'react'
import Media from 'react-media'
import { useKeyPress } from 'react-use'
import VideoInlinePlay from 'shared/components/video-inline-play/video'
import ChannelPageContext from 'shared/contexts/channel-page'
import ModalContext from 'shared/contexts/modal'
import { desktop } from 'shared/util/media-query'
import styled from 'styled-components'
import { VideoInlinePlayContext } from '../contexts/video-inline-play-manager'
import GifMenuOverlay, { LockOverlay } from './gif-menu-overlay'
import GifSelectionOverlay from './gif-selector-overaly'
import TagOverlay from 'ui/src/components/overlay/tag-overlay'

const isMobile = checkIsMobile()

const Video = styled.video`
    pointer-events: none;
`

const VideoHoverContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    video {
        width: 100% !important;
        width: 100% !important;
    }
`

const LongPressCapture = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    -webkit-touch-callout: none;
`

type GridOverlayContextProps = {
    onGifClick: (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => void
    onAttributionClick?: (gif: IGif) => void
    selectedGifs: (number | string)[]
    iSelectionMode: boolean
    Overlay?: ElementType<GifOverlayProps>
}
export const GridOverlayContext = createContext({} as GridOverlayContextProps)

const DefaultOverlays = ({ gif, isHovered }: GifOverlayProps) => {
    const { channel } = useContext(ChannelPageContext)
    const { muted } = useContext(VideoInlinePlayContext)
    const { onAttributionClick: customOnAttributionClick } = useContext(GridOverlayContext)
    // if we're on a channel page, hide attribution if the gif user matches
    // the channel page user
    // hide if the user isn't public
    const hideAttribution = !gif.user?.is_public || channel?.user?.id === gif.user?.id

    const openAttribution = (gif: IGif) => {
        if (customOnAttributionClick) {
            customOnAttributionClick(gif)
        }
        // open user channel
        const user = gif.user
        const url = (user as IProfileUser).profile_url
        // check for and remove "/" at end of url
        if (url && url.slice(-1) === '/') location.href = url.slice(0, -1)
        else location.href = url
    }

    return (
        <Media
            query={desktop.query}
            render={() => (
                <>
                    <GifMenuOverlay gif={gif} isHovered={isHovered} />
                    {hideAttribution ? (
                        <TagOverlay gif={gif} isHovered={isHovered} />
                    ) : (
                        <AttributionOverlay
                            gif={gif}
                            isHovered={isHovered}
                            onClick={() => {
                                openAttribution(gif)
                            }}
                        />
                    )}
                    {gif.type === 'video' && !muted && isHovered && (
                        <Video src={gif.video!.assets['360p'].url} autoPlay={true} loop={true} />
                    )}
                </>
            )}
        />
    )
}
const GridOverlay = ({ gif, isHovered, mixedGrid = false }: GifOverlayProps & { mixedGrid?: boolean }) => {
    const { onGifClick, selectedGifs, iSelectionMode, Overlay } = useContext(GridOverlayContext)
    const [isDetailMode] = useKeyPress((event: KeyboardEvent) => event.keyCode === 18)
    const { openMobileActionsPanel } = useContext(ModalContext)
    const longPressRef = useRef<any | null>(null)

    const onTouchStart = () => {
        // e.preventDefault()
        longPressRef.current = setTimeout(() => {
            openMobileActionsPanel(gif)
        }, 500)
    }
    const onTouchMove = () => {
        // e.preventDefault()
        clearTimeout(longPressRef.current)
    }
    const onTouchEnd = () => {
        clearTimeout(longPressRef.current)
    }

    if (gif.type === 'video' && mixedGrid) {
        return (
            <VideoHoverContainer>
                <VideoInlinePlay
                    gif={gif}
                    isHovered={isMobile ? false : isHovered}
                    volumeSize={!isMobile ? 42 : undefined}
                    clipboardSize={!isMobile ? 19 : undefined}
                />
                {iSelectionMode && (
                    <GifSelectionOverlay
                        gif={gif}
                        index={selectedGifs.indexOf(gif.id)}
                        onClick={onGifClick}
                        showDetails={isDetailMode}
                    />
                )}
                {Overlay && <Overlay gif={gif} isHovered={isHovered} />}
            </VideoHoverContainer>
        )
    }
    // these defaults will only render on desktop
    return iSelectionMode ? (
        <>
            {Overlay && <Overlay gif={gif} isHovered={isHovered} />}
            <LockOverlay gif={gif} isHovered={isHovered} />
            <GifSelectionOverlay
                gif={gif}
                index={selectedGifs.indexOf(gif.id)}
                onClick={onGifClick}
                showDetails={isDetailMode}
            />
        </>
    ) : Overlay ? (
        <>
            <Overlay gif={gif} isHovered={isHovered} />
            <DefaultOverlays gif={gif} isHovered={isHovered} />
        </>
    ) : (
        <>
            <DefaultOverlays gif={gif} isHovered={isHovered} />
            {isMobile && (
                <LongPressCapture onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} />
            )}
        </>
    )
}

export default GridOverlay
