import { giphyDarkestGrey, giphyGreen, giphyLightGrey, giphyRed } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { useEffect, useRef } from 'react'
import { useGifDetailPermissions } from 'shared/hooks/use-permissions'
import styled from 'styled-components'
import StickerStatus, { Status } from './types'

const Container = styled.div`
    background: ${giphyDarkestGrey};
    width: auto;
    margin-top: 30px;
    max-width: 500px;
`

const Block = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0px;
`

const Emoji = styled.img`
    height: 40px;
    margin: 0px 10px;
    flex-shrink: 0;
`

const RightArrow = styled.div`
    display: inline-block;
    vertical-align: middle;
    color: white;
    font-size: 12px;
    margin-left: 5px;
    margin-top: -1px;
`

const Description = styled.span`
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: ${giphyLightGrey};
    display: inline-block;
`

const Title = styled.h2`
    font-weight: bold;
`

const TextContainer = styled.div`
    margin: 0 5px;
    width: 100%;
`

const LinkContainer = styled.div`
    margin: 0 10px;
    color: ${giphyLightGrey};
    flex-shrink: 0;
    font-size: 14px;
    font-weight: bold;

    a {
        color: inherit;
    }
`

const Topline = styled.div<{ status: Status }>`
    height: 2px;
    background-image: linear-gradient(to right, ${(props) => StickerStatus.gradient(props.status)});
`

type Props = {
    gif: IGif
}

const getStatus = ({ rating, username, is_hidden, user }): Status | null => {
    if (!username || is_hidden) return null
    if (!user) return Status.Private
    if (!rating) return Status.Pending
    if (rating.toLowerCase() === 'r') return Status.Rejected
    return Status.Approved
}

const StickerStatusContainer = ({ gif }: Props) => {
    const permissions = useGifDetailPermissions(gif.id)
    const textRef = useRef<HTMLDivElement>(null)
    const status = permissions?.edit ? getStatus(gif) : null
    // Need this to set the Public and Private text to green and red.
    useEffect(() => {
        if (!textRef.current) return
        const children = textRef.current.children
        for (let i = 0; i < children.length; i++) {
            const el = children[i]
            el.innerHTML = el.innerHTML
                .replace(/Private/g, `<span style='color: ${giphyRed}'>Private</span>`)
                .replace(/Public/g, `<span style='color: ${giphyGreen}'>Public</span>`)
        }
    }, [textRef])
    if (!status) return null
    return (
        <Container>
            <Topline status={status} />
            <Block>
                <Emoji src={StickerStatus.emoji(status)}></Emoji>
                <TextContainer ref={textRef}>
                    <Title>{StickerStatus.title(status)}</Title>
                    <Description>{StickerStatus.description(status, gif.username)}</Description>
                </TextContainer>
                <LinkContainer>
                    <a href={StickerStatus.ctaURL(status)}>
                        {StickerStatus.cta(status)}
                        <RightArrow className="ss-icon ss-navigateright" />
                    </a>
                </LinkContainer>
            </Block>
        </Container>
    )
}

export default StickerStatusContainer
