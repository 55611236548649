import { giphyDarkGrey } from '@giphy/colors'
import { uniqueId } from 'lodash'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { ControlSize, Gradient } from 'types'
import { useRefDimensions } from 'utils'
import { SelectListProps } from '../select-list'
import { PortalTooltipContext } from '../tooltip'
import { TooltipProps } from '../tooltip/hooks/use-tooltip'
import { Container, Dim, Menu, Options } from './style'

type Props = {
    backgroundColor?: string
    className?: string
    color?: string
    defaultIndex?: number
    disabled?: boolean
    gradient?: Gradient
    index?: number
    options: string[]
    optionDescriptions?: string[]
    selectListOptions?: Partial<SelectListProps>
    size?: ControlSize
    style?: CSSProperties
    tooltipOptions?: Partial<TooltipProps>
    onChange?: (index: number) => void
}

const SegmentedControl = ({
    backgroundColor = giphyDarkGrey,
    className,
    color = '#fff',
    defaultIndex,
    disabled = false,
    gradient,
    index: indexProp,
    options,
    optionDescriptions,
    selectListOptions,
    size = 'small',
    style,
    tooltipOptions,
    onChange,
}: Props) => {
    const initialValueRef = useRef<number | undefined>(indexProp || defaultIndex)
    const optionsRef = useRef<HTMLDivElement>(null)
    const idRef = useRef<string>(uniqueId('segmented-control'))
    const [activeIndex, setActiveIndex] = useState<number | undefined>(indexProp || defaultIndex)
    const [isTruncated, setIsTruncated] = useState<boolean>(false)
    const optionsDimensions = useRefDimensions(optionsRef)

    useEffect(() => {
        if (!optionsRef.current || !optionsDimensions) return

        setIsTruncated(Math.round(optionsRef.current.scrollWidth) > Math.round(optionsDimensions.width))
    }, [optionsDimensions, optionsRef])

    useEffect(() => {
        if (typeof indexProp === 'number' && indexProp !== activeIndex) {
            setActiveIndex(indexProp)
        }
    }, [indexProp])

    useEffect(() => {
        if (typeof activeIndex === 'number' && activeIndex !== initialValueRef.current) {
            initialValueRef.current = undefined
            onChange?.(activeIndex)
        }
    }, [activeIndex])

    return (
        <Container $background={backgroundColor} $disabled={disabled} $size={size} className={className} style={style}>
            <Options
                $size={size}
                $truncated={isTruncated}
                activeIndex={activeIndex}
                backgroundColor={backgroundColor}
                color={color}
                controlId={idRef.current}
                disabled={disabled}
                gradient={gradient}
                options={options}
                optionDescriptions={optionDescriptions}
                ref={optionsRef}
                size={size}
                onChange={setActiveIndex}
            />
            {isTruncated && (
                <PortalTooltipContext>
                    <Dim $background={backgroundColor} $size={size} />
                    <Menu
                        activeIndex={activeIndex}
                        backgroundColor={backgroundColor}
                        controlColor={color}
                        controlId={idRef.current}
                        controlSize={size}
                        options={options}
                        selectListOptions={selectListOptions}
                        tooltipOptions={tooltipOptions}
                        onChange={setActiveIndex}
                    />
                </PortalTooltipContext>
            )}
        </Container>
    )
}

export default SegmentedControl
