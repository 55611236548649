import { useContext } from 'react'
import { useWindowSize } from 'react-use'
import { getAssociatedStickers, webFetch } from 'shared/api'
import GifContext from 'shared/contexts/gif'
import Grid from 'shared/components/grid'
import StickerAttributionGridOverlay from 'shared/components/sticker-attribution/grid-overlay-attribution'

const AttributionGrid = ({ gif, columns, overrideWidth }) => {
    const { receivedGifs } = useContext(GifContext)
    const { width } = useWindowSize()

    const handleRelatedGrid = async (offset) => {
        const relatedResults = await webFetch.related(gif.id, { offset, rating: 'pg-13' })
        const { data: gifs = [] } = relatedResults
        receivedGifs({ gifs })
    }

    const fetchUsedStickers = async (offset) => {
        handleRelatedGrid(offset)
        const result = await getAssociatedStickers(gif.id)
        result.pagination = {
            count: result.data.length,
            offset: 0,
            total_count: result.data.length,
        }
        result.data = result.data.filter((gif) => gif.is_sticker)

        return result
    }

    return (
        <Grid
            noRedux={true}
            fetchGifs={(offset) => fetchUsedStickers(offset)}
            width={overrideWidth || width}
            Overlay={StickerAttributionGridOverlay}
            columns={columns}
        />
    )
}

export default AttributionGrid
