import { giphyDarkGrey } from '@giphy/colors'
import React, { CSSProperties, DOMAttributes, ReactNode, useRef } from 'react'
import { ControlSize, Gradient } from 'types'
import { colorFromGradientPosition } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import { IconButtonProps } from '../icon-button'
import { Container, Label, Remove, Thumbnail } from './style'

type DefaultProps = DOMAttributes<HTMLDivElement>

export interface PillProps extends DefaultProps {
    children?: ReactNode
    className?: string
    color?: string
    gradient?: Gradient
    removeable?: boolean
    removeOptions?: Partial<IconButtonProps>
    round?: boolean
    size?: ControlSize
    style?: CSSProperties
    thumbnail?: string
    title?: string
    textColor?: string
    onRemove?: () => void
}

const Pill = ({
    children,
    className,
    color = giphyDarkGrey,
    gradient,
    removeable = false,
    removeOptions = {},
    round = false,
    size = 'small',
    style,
    thumbnail,
    title,
    textColor,
    onClick,
    onRemove,
    ...rest
}: PillProps) => {
    const removeButtonRef = useRef<HTMLButtonElement>(null)
    const baseColor = gradient ? colorFromGradientPosition(gradient, 0.5) : color
    const contentColor = textColor || textColorFromBackgroundColor(baseColor, gradient ? '#fff' : 'inherit')

    function onContainerClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (onClick && e.target !== removeButtonRef.current) {
            onClick?.(e)
        }
    }

    return (
        <Container
            {...rest}
            $clickable={!!onClick}
            $color={color}
            $gradient={gradient}
            $hasThumbnail={!!thumbnail}
            $removeable={removeable}
            $round={round}
            $size={size}
            $textColor={contentColor}
            className={className}
            style={style}
            title={title}
            onClick={onContainerClick}
        >
            {thumbnail && <Thumbnail $size={size} alt="" src={thumbnail} />}
            <Label>{children}</Label>
            {removeable && (
                <Remove
                    color="transparent"
                    icon="close"
                    iconColor="currentColor"
                    iconScale={0.75}
                    ref={removeButtonRef}
                    {...removeOptions}
                    size={size}
                    onClick={() => onRemove?.()}
                />
            )}
        </Container>
    )
}

export default Pill
