import { giphyBlack, giphyLightestGrey, primaryCTA } from '@giphy/colors'
import styled from 'styled-components'

const OptionButton = styled.button<{ highlighted: boolean }>`
    position: relative;
    height: 100%;
    width: 100%;
    background: ${(props) => (props.highlighted ? giphyLightestGrey : '#fff')};
    pointer-events: auto;
    padding: unset;

    &:hover {
        background: ${giphyLightestGrey};
    }
`

const OptionText = styled.div`
    width: auto;
    color: ${giphyBlack};
    text-align: left;
    font-weight: normal;
    line-height: 32px;
    float: left;
    margin: 0 0 0 18px;
`

const OptionIcon = styled.div`
    width: auto;
    color: ${primaryCTA};
    height: 23px;
    line-height: 23px;
    margin: 6px 0 0 8px;
    overflow: hidden;
    pointer-events: none;
    vertical-align: middle;
    float: left;
`

const SelectedIcon = styled(OptionIcon)`
    font-size: 13px;
`

type OptionData = {
    value: string
    name: string
    index: number
}

type OptionProps = {
    onBlur: () => void
    onKeyDown: () => void
    onKeyPress: () => void
    onMouseDown: () => void
    tabIndex: number
    value: string
}

type Props = {
    className: string
    highlighted: boolean
    selected: boolean
    optionData: OptionData
    optionProps: OptionProps
}

const LanguageListItem = ({ className, highlighted, selected, optionData, optionProps }: Props) => {
    const icon = selected ? (
        <SelectedIcon className="ss-icon ss-check"></SelectedIcon>
    ) : (
        <OptionIcon className="ss-icon"></OptionIcon>
    )
    return (
        <OptionButton className={className} highlighted={highlighted} {...optionProps}>
            <OptionText>{optionData.name}</OptionText>
            {icon}
        </OptionButton>
    )
}

export default LanguageListItem
