const RULES = [
    { regex: /<[^>]+>/g, replacement: '' }, // links
    { regex: /\[([^[]+)\]\(([^)]+)\)/g, replacement: "<a href='$2' target='_blank' rel='noopener noreferrer'>$1</a>" }, // links
    { regex: /(\*\*|__)(.*?)\1/g, replacement: '<strong>$2</strong>' }, // bold
    { regex: /(\*|_)(.*?)\1/g, replacement: '<em>$2</em>' }, // emphasis
]

const div = document.createElement('div')

export default function (text) {
    let html = text
    RULES.forEach(({ regex, replacement }) => {
        html = html.replace(regex, replacement)
    })

    div.innerHTML = html.trim()

    return {
        html: html.trim(),
        length: div.innerText.trim().length,
    }
}
