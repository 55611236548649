export class ResponseError extends Error {
    constructor(response, details) {
        super(response.statusText)
        this.name = 'ResponseError'
        this.response = response
        this.details = details
    }
}

export class PublicAPIError extends Error {
    constructor(message = 'Error fetching data from a Public API') {
        super(message)
        this.name = 'PublicAPIError'
    }
}
