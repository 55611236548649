import styled from 'styled-components'

export const FeatureIcon = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 15px;
    background-position: center;
`

export const InsightsIcon = styled(FeatureIcon)`
    background: url(/static/img/apply/insights.svg) center no-repeat;
`

export const AmplifyIcon = styled(FeatureIcon)`
    background: url(/static/img/apply/amplify.svg) center no-repeat;
`

export const CollectionsIcon = styled(FeatureIcon)`
    background: url(/static/img/apply/collections.svg) center no-repeat;
`

export const DistributionIcon = styled(FeatureIcon)`
    background: url(/static/img/apply/distribution.svg) center no-repeat;
`

export const VideoIcon = styled(FeatureIcon)`
    background: url(/static/img/apply/video.svg) center no-repeat;
`
