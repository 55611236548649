import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, heightFromControlSize } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import HeaderComponent from './header'

export const Header = styled(HeaderComponent)`
    border-radius: inherit;
    position: relative;
    user-select: none;
`

export const Container = styled.div<{ $backgroundColor: string; $size: ControlSize }>`
    ${({ $backgroundColor, $size }) => css`
        background: ${$backgroundColor};
        border-radius: ${borderRadiusFromControlSize($size)}px;
        color: ${textColorFromBackgroundColor($backgroundColor)};

        ${Header} {
            min-height: ${heightFromControlSize($size)}px;
        }
    `}
`
