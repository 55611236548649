import { IProfileUser } from '@giphy/js-types'
import { CSSProperties, useRef } from 'react'
import { AvatarImg } from './style'
import { getResizedUrl } from './utils'

type AvatarProps = {
    user: Partial<IProfileUser>
    width?: number
    height?: number
    style?: CSSProperties
    className?: string
}

const Avatar = ({ user, width = 45, height = 45, className, style }: AvatarProps) => {
    const defaultAvatarId = useRef<number>(Math.floor(Math.random() * 5) + 1)
    const avatarUrl = user.avatar_url
        ? getResizedUrl(user.avatar_url, height)
        : `https://media.giphy.com/avatars/default${defaultAvatarId.current}.gif`

    return (
        <AvatarImg
            width={width}
            height={height}
            suppressHydrationWarning
            src={avatarUrl}
            className={className}
            style={style}
            alt={`${user.display_name || user.username} Avatar`}
        />
    )
}

export default Avatar
