import { getResults } from 'shared/api'
import { POST } from 'shared/util/fetch-methods'
import { ICapture, ICustomFont, INewCaptureInfo, IPending, ISegment } from '../types'

const API_BASE_URL = '/api/v1/dvr-service'

export default class API {
    getCaptureStatus = async (captureId: string): Promise<{ status: string; capture: ICapture }> => {
        const url = `${API_BASE_URL}/capture_status/?id=${captureId}`

        const response = await getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const capture = response.data.capture as ICapture

        return {
            status: capture.status,
            capture: capture as ICapture,
        }
    }

    getCaptures = async (offset: number): Promise<{ captures: ICapture[]; pending: IPending[] }> => {
        const url = `${API_BASE_URL}/list_captures/?offset=${offset}`

        const response = await getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        return {
            // TODO: Convert these to more friendly types with normalization of string fields.
            captures: response.data.captures as ICapture[],
            pending: response.data.pending as IPending[],
        }
    }

    getAllCaptures = async (offset: number): Promise<{ captures: ICapture[]; pending: IPending[] }> => {
        const url = `${API_BASE_URL}/list_all_captures/?offset=${offset}`

        const response = await getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        return {
            captures: response.data.captures as ICapture[],
            pending: response.data.pending as IPending[],
        }
    }

    getCaptureDetails = async (captureId: string): Promise<{ capture: ICapture; segments: ISegment[] }> => {
        const url = `${API_BASE_URL}/capture_details/?id=${captureId}`

        const response = await getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const capture = response.data.capture as ICapture
        const segments = response.data.segments.map((segment: ISegment) => {
            // strip old editParam data
            if (segment.editParams && 'aspectRatio' in (segment.editParams as any)) {
                return { ...segment, editParams: undefined }
            }
            return segment
        }) as ISegment[]

        return {
            capture: capture,
            segments: segments,
        }
    }

    newCaptureFromUrl = async (info: INewCaptureInfo, videoUrl: string): Promise<{ uuid: string }> => {
        let url = `${API_BASE_URL}/new_capture/`

        const params = {
            data: JSON.stringify({
                username: info.username,
                // TODO: handle featured tags when api supports it
                tags: info.tags?.map(({text}) => text).join(','),
                watermark: info.watermark?.id,
                customFont: info.font?.id,
                title: info.title,
                url: videoUrl,
                rating: info.rating,
                description: info.description,
                sourceUrl: info.sourceUrl,
                watermarkPosition: info.watermarkPosition,
                watermarkWidth: info.watermarkWidth,
                segmentationMaxLength: info.segmentationMaxLength,
                segmentationMinLength: info.segmentationMinLength,
                segmentationType: info.segmentationType,
                isPrivate: !info.isPublic,
                captionPosition: info.captionPosition,
                captionStyle: info.captionStyle,
                cropType: info.cropType,
                autoCrop: info.autoCrop,
                captionFile: info.caption?.url,
                autoCaption: info.autoCaption,
                language: info.language,
                captionFilename: info.captionFilename,
            }),
        }

        const response = await getResults(url, POST(params, true))

        return {
            uuid: response.data.uuid,
        }
    }

    newCaptureFromFile = async (
        info: INewCaptureInfo,
        file: File,
        onProgress: (p: number) => void
    ): Promise<{ uuid: string }> => {
        const fileExtension = file.name.split('.').pop()!
        const { fullUrl } = await this.uploadCaptureAsset(file, fileExtension, 'uploads', onProgress)
        return this.newCaptureFromUrl(info, fullUrl)
    }

    deleteCapture = (captureId: string) => {
        const url = `${API_BASE_URL}/delete_capture/?id=${captureId}`

        return getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
    }

    getPresignedUpload = (fileExtension: string = 'mp4', bucketPrefix: string = 'uploads') => {
        const url = `${API_BASE_URL}/presigned_upload/?extension=${fileExtension}&bucket_prefix=${bucketPrefix}`
        return getResults(url, {
            method: 'GET',
        })
    }

    getVideoEmbedInfo = (videoUrl: string, withCaption: boolean, quality = 'hd') => {
        const url = `${API_BASE_URL}/video_info/?url=${encodeURIComponent(
            videoUrl
        )}&withCaption=${withCaption}&quality=${quality}`

        return getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
    }

    downloadYoutubeCaption = (videoUrl: string, language: string, quality = 'dvr') => {
        const url = `${API_BASE_URL}/download_caption/?url=${encodeURIComponent(
            videoUrl
        )}&language=${language}&quality=${quality}`

        return getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
    }

    getUserEdits = async (captureId: string): Promise<ISegment[]> => {
        const url = `${API_BASE_URL}/user_edits/?id=${captureId}`
        const response = await getResults(url, {
            method: 'GET',
        })
        const segments: ISegment[] = []

        // TODO: Remove the try/catch once DVR is deployed. We won't need to account for both types of responses.
        try {
            response.data.forEach((data: ISegment[]) => {
                for (let i = 0; i < data.length; i++) {
                    segments[i] = data[i] || segments[i]
                }
            })
        } catch (error) {
            return response.data
        }

        return segments
    }

    saveUserEdits = (captureId: string, data: ISegment[]) => {
        const url = `${API_BASE_URL}/save_user_edits/?id=${captureId}`
        const params = {
            data: JSON.stringify(data),
        }
        getResults(url, POST(params, true))
    }

    saveCaptureEdits = async (capture: INewCaptureInfo, captureID: string) => {
        const url = `${API_BASE_URL}/capture_edit/?id=${captureID}`
        const params = {
            data: JSON.stringify({
                title: capture.title,
                tags: (capture.tags || []).map((t) => t.text),
                username: capture.username,
                watermark: capture.watermark?.id,
                custom_font: capture.font?.id,
                source_url: capture.sourceUrl,
                rating: capture.rating,
                watermark_position: capture.watermarkPosition,
                watermark_width: `${capture.watermarkWidth}`,
                is_private: capture.isPublic,
                description: capture.description,
            }),
        }
        const response = await getResults(url, POST(params, true))
        return response.data
    }

    getWatermarks = async (username: string) => {
        const url = `${API_BASE_URL}/get_watermarks/?username=${username}`

        const response = await getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        return response.data.watermarks
    }

    uploadWatermark = async (username: string, watermark_url: string, filename: string) => {
        const url = `${API_BASE_URL}/new_watermark/`
        const params = {
            data: JSON.stringify({
                username: username,
                image_url: watermark_url,
                filename: filename,
            }),
        }
        await getResults(url, POST(params, true))
    }

    deleteWatermark = async (username: string, watermark_id: string) => {
        const url = `${API_BASE_URL}/delete_watermark/`
        const params = {
            data: JSON.stringify({
                username: username,
                watermark_id: watermark_id,
            }),
        }
        await getResults(url, POST(params, true))
    }

    getFonts = async (username: string): Promise<ICustomFont[]> => {
        const url = `${API_BASE_URL}/get_fonts/?username=${username}`

        const response = await getResults(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        return response.data.fonts
    }

    uploadFont = async (username: string, font_url: string, filename: string) => {
        const url = `${API_BASE_URL}/new_font/`
        const params = {
            data: JSON.stringify({
                username: username,
                font_url: font_url,
                filename: filename,
            }),
        }
        await getResults(url, POST(params, true))
    }

    deleteFont = async (username: string, font_id: string) => {
        const url = `${API_BASE_URL}/delete_font/`
        const params = {
            data: JSON.stringify({
                username: username,
                font_id: font_id,
            }),
        }
        await getResults(url, POST(params, true))
    }

    uploadCaptureAsset = async (
        file: File | Blob,
        extension: string,
        bucketPrefix: string,
        onProgress?: (p: number) => void
    ) => {
        // TODO: Check if asset being uploaded already exists in S3.
        const presignResponse = await this.getPresignedUpload(extension, bucketPrefix)
        const data = presignResponse.data.response || presignResponse.data
        const presignedUrl = data.url
        const fields = data.fields

        const form = new FormData()
        form.append('policy', fields['policy'])
        form.append('AWSAccessKeyId', fields['AWSAccessKeyId'])
        form.append('key', fields['key'])
        form.append('signature', fields['signature'])
        form.append('file', file)

        await new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            if (onProgress) {
                xhr.upload.addEventListener('progress', (evt) => {
                    if (evt.lengthComputable) {
                        onProgress(evt.loaded / evt.total)
                    }
                })
            }
            xhr.addEventListener('load', () => {
                resolve()
            })
            xhr.addEventListener('error', () => {
                reject()
            })
            xhr.open('POST', presignedUrl)
            xhr.send(form)
        })

        return {
            fullUrl: presignedUrl + fields.key,
            key: presignResponse.data.key || fields.key,
        }
    }
}
