import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { Coordinates } from 'types'
import { TooltipDirection } from '../types'
import { Arrow, Container, Wrapper } from './style'

type Props = {
    arrowColor?: string
    children?: ReactNode
    className?: string
    color?: string
    direction?: TooltipDirection
    offset?: Partial<Coordinates>
    style?: CSSProperties
    textAlign?: 'left' | 'center' | 'right'
    width?: number
    ref: ForwardedRef<ReactNode>
}

const Tooltip = (
    {
        arrowColor,
        children,
        className,
        color = '#fff',
        direction = 'up',
        offset,
        style = {},
        textAlign = 'center',
        width,
    }: Props,
    ref: ForwardedRef<any>
) => {
    const [containerStyle, arrowStyle] = useMemo(() => {
        if (!offset) {
            return []
        }

        return [
            {
                marginLeft: offset?.x || 0,
                marginTop: offset?.y || 0,
            },
            {
                marginLeft: -(offset?.x || 0),
                marginTop: -(offset?.y || 0),
            },
        ]
    }, [offset])

    return (
        <Container
            $arrowColor={arrowColor}
            $color={color}
            $textAlign={textAlign}
            $tooltipDirection={direction}
            $tooltipWidth={width}
            className={className}
            ref={ref}
            style={{ ...containerStyle, ...style }}
        >
            <Arrow style={arrowStyle} />
            <Wrapper>{children}</Wrapper>
        </Container>
    )
}

export default forwardRef(Tooltip)
