import { giphyLightCharcoal, giphyLightGrey, giphyWhite } from '@giphy/colors'
import { titleFont } from 'shared/components/text-elements'
import styled, { keyframes } from 'styled-components'

const bloop = keyframes`
    0% { transform: scale(0); }
    100% { transform: scale(1); }
`

export default styled.div`
    margin: ${(props) => (props.theme.margin ? props.theme.margin : '0 auto')};
    color: ${giphyWhite};
    display: block;
    overflow: hidden;
    padding-bottom: 5px;
    position: relative;
    width: 280px;

    button {
        background: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        font: inherit;
        margin: 0;
        outline: none;
        padding: 0;
        text-align: center;
        -webkit-appearance: none;
    }

    div[class='pika-title'] {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 10px;
        padding: 0 10px;
        width: 100%;

        button[class*='pika-'] {
            background: rgba(255, 255, 255, 0.1);
            display: inline-block;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 11px;
            height: 20px;
            line-height: 22px;
            width: 30px;

            i {
                pointer-events: none;
            }
        }

        button[class*='is-disabled'] {
            opacity: 0.5;
            pointer-events: none;
        }

        button[class*='pika-prev'] {
            margin-left: auto;
            margin-right: 2px;
        }
    }

    div[class='pika-label'] {
        color: ${(props) => props.theme.color};
        display: inline-block;
        font-family: ${titleFont};
        font-size: 26px;
        margin-right: 5px;
        position: relative;

        select {
            cursor: pointer;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    table[class='pika-table'] {
        color: ${giphyLightGrey};
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        text-align: center;
        width: 100%;

        abbr {
            color: ${giphyWhite};
            text-decoration: none;
        }

        button {
            display: block;
            height: 30px;
            position: relative;
            width: 100%;
            z-index: 2;
        }

        td[class*='is-disabled'] {
            color: ${giphyLightCharcoal};
            pointer-events: none;
        }

        td[class*='is-today'] {
            color: ${giphyWhite};
        }

        td[class*='is-inrange'] {
            color: ${giphyWhite};
            position: relative;

            &:before {
                background: ${(props) => props.theme.altColor};
                box-sizing: content-box;
                content: '';
                height: 100%;
                left: -7px;
                padding: 0 7px;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        td[class*='is-selected'],
        td[class*='is-startrange'],
        td[class*='is-endrange'] {
            color: ${giphyWhite};
            position: relative;

            &:before {
                animation: ${bloop} 0.1s ease-out;
                background: ${(props) => props.theme.color};
                border-radius: 50%;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                content: '';
                height: 40px;
                left: 50%;
                margin: -21px 0 0 -20px;
                pointer-events: none;
                position: absolute;
                top: 50%;
                width: 40px;
                z-index: 1;
            }
        }
    }
`
