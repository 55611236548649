exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"small": "(max-width: 599px)",
	"medium": "(min-width: 600px) and (max-width: 959px)",
	"large": "(min-width: 960px)",
	"desktop": "(min-width: 768px)"
};