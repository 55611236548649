import checkMobile from 'is-mobile'
import styled, { css } from 'styled-components'
import Attribution from '../attribution'

const IS_MOBILE = checkMobile()

const Container = styled.div<{ width: number }>`
    box-sizing: border-box;
    padding-top: 5px;
    width: ${({ width }) => width}px;
`

const VideoTitle = styled.h4<{ isSmall: boolean }>`
    line-height: 1.5;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;

    ${(props) =>
        props.isSmall &&
        css`
            font-size: 14px;
        `};
`

const VideoPreviewLabel = ({
    title,
    user = {},
    showAttribution = true,
    target = '_self',
    width = 0,
    onClick,
    href,
    className = '',
    noAvatar = IS_MOBILE,
}) => {
    const defaultOnClick = () => (location.href = href)
    const isSmall = width <= 300
    return (
        <Container className={className} width={width}>
            <VideoTitle isSmall={isSmall} onClick={onClick || defaultOnClick} title={title}>
                {title}
            </VideoTitle>
            {showAttribution && <Attribution compact noAvatar={noAvatar} target={target} user={user} />}
        </Container>
    )
}

export default VideoPreviewLabel
