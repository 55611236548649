import { parseUrl } from 'shared/util/gif-parse'
import { FPS } from '../constants'
import { CreationToolFrame, CreationToolSourceFile } from '../types'
import CreationToolFileType from '../types/file-type'
import getVideoFrames from './get-video-frames'

const CHILL_ZONE = {}

const preloadImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const img = document.createElement('img')
        img.onload = () => {
            resolve(img)
        }
        img.onerror = (error) => {
            reject(error)
        }
        img.src = url
    })

const drawToCanvas = (img: HTMLImageElement | HTMLCanvasElement, maxWidth?: number): HTMLCanvasElement => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const aspectRatio = img.width / img.height
    canvas.width = maxWidth ? Math.min(img.width, maxWidth) : img.width
    canvas.height = Math.round(canvas.width / aspectRatio)
    context?.drawImage(img, 0, 0, canvas.width, canvas.height)
    return canvas
}

const trimFramesArray = (frames: CreationToolFrame[], length?: number) => {
    if (length) {
        const totalFrames = length
        const pickedFrames: CreationToolFrame[] = []
        for (let i = 0; i < totalFrames; i++) {
            const frame = Math.round((i / totalFrames) * frames.length)
            pickedFrames.push(frames[frame])
        }
        return pickedFrames
    }

    return frames
}

type SourceFramesProps = {
    sourceFile: CreationToolSourceFile
    length?: number
    maxWidth?: number
    ignoreCache?: boolean
}

/**
 * Processes any file or url and returns the file's canvas frames
 * @param sourceFile
 * @param length
 * @param maxWidth - adding this field will resize the frames relative to the width
 */
const getSourceFrames = async (props: SourceFramesProps): Promise<CreationToolFrame[]> => {
    const { sourceFile, length = 0, maxWidth, ignoreCache = false } = props
    const { id, source, type } = sourceFile
    const frames = CHILL_ZONE[id] || sourceFile.frames
    let outputFrames: CreationToolFrame[] = []

    // already has frames
    if (frames.length > 0) {
        const pickedFrames = trimFramesArray(frames, length)
        return maxWidth ? pickedFrames.map((f) => ({ ...f, canvas: drawToCanvas(f.canvas, maxWidth) })) : pickedFrames
    }

    // gif
    if (type === CreationToolFileType.ANIMATED_IMAGE) {
        const fileUrl = typeof source === 'string' ? source : URL.createObjectURL(source)
        const canvases: HTMLCanvasElement[] = await parseUrl(fileUrl, length, FPS, {
            ignoreCache,
        })
        if (typeof source !== 'string') {
            URL.revokeObjectURL(fileUrl)
        }
        outputFrames = canvases.map((canvas) => ({ canvas: maxWidth ? drawToCanvas(canvas, maxWidth) : canvas }))
    }

    // video
    if (type === CreationToolFileType.VIDEO) {
        outputFrames = await getVideoFrames(source, length, FPS, maxWidth)
    }

    // still
    if (type === CreationToolFileType.STILL_IMAGE) {
        const fileUrl = typeof source === 'string' ? source : URL.createObjectURL(source)
        const img = await preloadImage(fileUrl || (source as string))
        const canvas = drawToCanvas(img, maxWidth)
        if (typeof source !== 'string') {
            URL.revokeObjectURL(fileUrl)
        }
        outputFrames = [{ canvas }]
    }

    // only store frames if fetching ALL
    if (!length) {
        CHILL_ZONE[id] = outputFrames
    }

    outputFrames = outputFrames.map((frames) => {
        const ctx = frames.canvas.getContext('2d')
        if (ctx) {
            ctx.imageSmoothingEnabled = false
        }
        return frames
    })

    return trimFramesArray(outputFrames, length)
}

export default getSourceFrames
