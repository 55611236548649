import { useContext, useEffect, useRef } from 'react'
import VideoContext from '../video-context'

const ShareManager = () => {
    const { setLoop, setMuted, scrub, isMuted, isLoop, mediaState, isShare } = useContext(VideoContext)
    const savedPlayerState = useRef({ isLoop, isMuted })
    const hasSeenShare = useRef(false)

    useEffect(() => {
        // when the overlay is opened, we want to loop the video
        // dimmed in the background with no sound, so it doesn't
        // get annoying. we also want to store the isLoop and isMuted
        // state and replace them back to what they were when the
        // overlay is exited.
        if (isShare) {
            savedPlayerState.current = { isLoop, isMuted }
            hasSeenShare.current = true
            setLoop(true)
            setMuted(true)
        } else if (hasSeenShare.current) {
            const { isMuted, isLoop } = savedPlayerState.current
            setLoop(isLoop)
            setMuted(!!isMuted)
            mediaState === 'playing' && scrub(0)
        }
    }, [isShare])

    return null
}

export default ShareManager
