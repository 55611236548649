import styled from 'styled-components'

export const Background = styled.div`
    border-radius: inherit;
    display: flex;
    flex-direction: row;
    gap: 1px;
    height: 100%;
    pointer-events: none;
    width: 100%;
`

export const Segment = styled.div`
    border-radius: inherit;
    height: 100%;
`
