import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { Gradient } from 'types'
import { gradientToCss } from 'utils'
import IconType, { iconComponent } from '../../types/icons'
import { Icon } from './style'

type Props = {
    className?: string
    color?: string
    gradient?: Gradient
    height: number
    icon: IconType
    style?: CSSProperties
    width: number
}

const IconComponent = ({ className, color = '#fff', gradient, height, icon, style, width }: Props) => {
    const [iconCSS, setIconCSS] = useState<CSSProperties>({})
    const Component = useMemo(() => iconComponent(icon), [icon])
    const iconHTML = useMemo(() => renderToString(<Component />), [icon])
    const iconStyle = { height, width, ...style, ...iconCSS }

    useEffect(() => {
        if (!gradient) {
            setIconCSS({})
            return
        }

        const maskCSS = `url(data:image/svg+xml,${encodeURIComponent(
            iconHTML
        )}) center center/${width}px ${height}px no-repeat`

        setIconCSS({
            background: gradientToCss(gradient),
            display: 'inline-block',
            WebkitMask: maskCSS,
            mask: maskCSS,
        })
    }, [gradient, height, iconHTML, width])

    return gradient ? (
        <Icon className={className} style={iconStyle} />
    ) : (
        <Component className={className} color={color} style={iconStyle} />
    )
}

export default IconComponent
