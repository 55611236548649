import styled, { css, keyframes } from 'styled-components'
import { modalZIndex } from '../../../constants'

const slideIn = keyframes`
    0% { translate: -50% 0; }
    100% { translate: -50% 100%; }
`

export const Container = styled.div<{ $open: boolean }>`
    bottom: 100%;
    left: 50%;
    position: fixed;
    transition: translate 0.3s ease-in-out;
    translate: -50% 0;
    width: 100%;
    z-index: ${modalZIndex + 1};

    ${({ $open }) =>
        $open &&
        css`
            animation: ${slideIn} 0.3s ease-in-out;
            translate: -50% 100%;
            transition-delay: 0.15s;
        `}
`
