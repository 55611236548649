import { uniqueId } from 'lodash'
import React, { CSSProperties, isValidElement, useEffect, useRef, useState } from 'react'
import { iconComponent } from '../../../types/icons'
import useIconColor from './hooks/use-icon-color'
import { List, Option } from './style'

const CheckmarkIcon = iconComponent('checkmark-circle')

export type SelectListProps = {
    activeColor?: string
    iconColor?: string
    style?: CSSProperties
    textColor?: string
}

interface Props extends SelectListProps {
    className?: string
    defaultIndex?: number
    index?: number
    options: string[] | React.ReactNode[]
    onChange?: (index: number) => void
}

const SelectList = ({
    activeColor,
    className,
    defaultIndex,
    iconColor: iconColorProp,
    index: indexProp,
    options,
    style,
    textColor,
    onChange,
}: Props) => {
    const initialValueRef = useRef<number | undefined>(indexProp || defaultIndex)
    const listRef = useRef<HTMLUListElement>(null)
    const idRef = useRef<string>(uniqueId('select-list'))
    const [activeIndex, setActiveIndex] = useState<number | undefined>(defaultIndex)
    const iconColor = useIconColor(listRef, iconColorProp)

    useEffect(() => {
        if (typeof indexProp === 'number' && indexProp !== activeIndex) {
            setActiveIndex(indexProp)
        }
    }, [indexProp])

    useEffect(() => {
        if (typeof activeIndex === 'number' && activeIndex !== initialValueRef.current) {
            initialValueRef.current = undefined
            onChange?.(activeIndex)
        }
    }, [activeIndex])

    return (
        <List $textColor={textColor} className={className} ref={listRef} style={style}>
            {options.map((label, i) => (
                <Option
                    $active={i === activeIndex}
                    $color={i === activeIndex ? activeColor : undefined}
                    key={`${idRef.current}-option${i}`}
                    onClick={() => setActiveIndex(i)}
                >
                    {isValidElement(label) ? label : <span>{label}</span>}
                    <CheckmarkIcon color={iconColor} />
                </Option>
            ))}
        </List>
    )
}

export default SelectList
