import { useContext, useCallback, useRef, useEffect } from 'react'
import { IChannel } from 'shared/types/channel'
import AddToButton from './add-to-button'
import ChannelList from './channel-list'
import EmptyCollectionCTA from './empty-collection-cta'
import ModalContext from 'shared/contexts/modal'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import UserContext from 'shared/contexts/user'
import {
    AddToCollectionPlus,
    AddToSection,
    AddToCollectionContainer,
    AddToCollectionText,
    ToolTipContainer,
} from './sc'
import { Grid } from '@giphy/react-components'
import { useChannelWithNormalGifFetch } from 'shared/api/channels'
import { isEmpty } from 'lodash'
import { CollectionStyleContext } from './../context/collection-style-context'
import Tooltip from 'shared/components/tool-tip'
import { useClickAway, useLocalStorage } from 'react-use'

export function flatten(children: IChannel[]) {
    const flat: IChannel[] = [...children]
    children.forEach((channel) => {
        if (channel.children) {
            flat.push(...flatten(channel.children))
        }
    })
    return flat
}

const empty = {} as IChannel
type Props = {
    onClickChannel: (channelId: number, searchChannelId: number) => void
    onClickAddTo?: (channelId: number, searchChannelId: number) => void
}

const ChannelsGif = ({ channelId }: { channelId: number }) => {
    const { gif } = useContext(CollectionContext)
    const fetchGifs = useChannelWithNormalGifFetch(channelId)

    return <Grid fetchGifs={fetchGifs} gutter={10} width={gif ? 480 : 968} columns={3} />
}

const AddToCollection = ({ onClickChannel, onClickAddTo }: Props) => {
    const { activeChannelId, addGifsToCollectionSuccessMessage, activeChannelColor, gif, channels, rootChannelId } =
        useContext(CollectionContext)
    const { user } = useContext(UserContext)
    const { selectedChannelIds, showGifsBelow } = useContext(CollectionStyleContext)
    const rootChannel = channels[rootChannelId]
    const { openNewCollectionPanel } = useContext(ModalContext)
    const findChannel = (id: number | undefined) => (id ? channels[id] || empty : empty)
    const activeChannel = findChannel(activeChannelId)
    const [hasSeenTooltip, setHasSeenTooltip] = useLocalStorage<boolean | undefined>(
        'has-seen-collections-tooltip',
        undefined
    )
    const tooltipRef = useRef(null)
    useClickAway(tooltipRef, () => {
        setHasSeenTooltip(true)
    })

    useEffect(() => {
        if (rootChannel.children.length > 1 && hasSeenTooltip === undefined) {
            setHasSeenTooltip(false)
        }
    }, [rootChannel.children, hasSeenTooltip])

    const CallbackChannelsGif = useCallback(() => {
        return <ChannelsGif channelId={activeChannel.id} />
    }, [activeChannelId, addGifsToCollectionSuccessMessage])

    const showEmptyCta = () => {
        if (!!activeChannel && !isEmpty(activeChannel)) {
            const { has_children, parent } = activeChannel
            if (!has_children && !parent) {
                return true
            } else {
                return false
            }
        }
    }

    const isRoot = activeChannelId === user?.channelId
    const showTooltip = !hasSeenTooltip && hasSeenTooltip !== undefined

    return (
        <>
            {!showEmptyCta() ? (
                <>
                    {showTooltip && (
                        <ToolTipContainer ref={tooltipRef}>
                            <Tooltip position={'bottom'}>
                                <p>
                                    Tap and Hold on a Collection card to pick it up, then drag to reorder or nest
                                    collections!
                                </p>
                            </Tooltip>
                        </ToolTipContainer>
                    )}
                    <ChannelList
                        addButton={
                            <AddToButton
                                displayName={isRoot ? 'New Collection' : 'New Sub-Collection'}
                                onClick={() =>
                                    openNewCollectionPanel({
                                        parentChannel: activeChannel,
                                        userId: rootChannel.user?.id,
                                        gif,
                                    })
                                }
                                backgroundText={isRoot ? '#3e3e3e' : activeChannelColor}
                            />
                        }
                        onChannelClick={(collectionId: number) => {
                            onClickChannel(collectionId, rootChannelId)
                        }}
                        channel={activeChannel}
                        isRoot={isRoot}
                        showTooltip={showTooltip}
                    />
                    {activeChannelId && !!activeChannel.parent && (
                        <>
                            <AddToSection>
                                <AddToCollectionContainer
                                    onClick={() => onClickAddTo?.(activeChannelId, rootChannelId)}
                                    selected={selectedChannelIds?.includes(activeChannelId)}
                                >
                                    <AddToCollectionPlus className="ss-plus" />
                                    <AddToCollectionText>
                                        Add to <b>{activeChannel.display_name}</b>
                                    </AddToCollectionText>
                                </AddToCollectionContainer>
                            </AddToSection>
                            {showGifsBelow && <CallbackChannelsGif />}
                        </>
                    )}
                </>
            ) : (
                <EmptyCollectionCTA channel={activeChannel} />
            )}
        </>
    )
}
export default AddToCollection
