import { giphyGreen } from '@giphy/colors'
import styled from 'styled-components'

const Button = styled.div`
    cursor: pointer;
    position: relative;
    &:hover svg > g > g {
        fill: ${giphyGreen};
    }
    height: 40px;
    width: 40px;
    padding: 12px;
`

const SvgContainer = styled.svg`
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
`

const BulkEditIcon = () => (
    <SvgContainer>
        <svg viewBox="0 0 64 64">
            <defs>
                <rect id="path-1" x="8" y="8" width="42" height="42" rx="2" />
                <filter
                    x="-24.4%"
                    y="-22.0%"
                    width="148.8%"
                    height="148.8%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                >
                    <feMorphology radius="0.75" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                    <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <rect id="path-3" x="4" y="4" width="42" height="42" rx="2" />
                <filter
                    x="-24.4%"
                    y="-22.0%"
                    width="148.8%"
                    height="148.8%"
                    filterUnits="objectBoundingBox"
                    id="filter-4"
                >
                    <feMorphology radius="0.75" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                    <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <rect id="path-5" x="0" y="0" width="42" height="42" rx="2" />
                <filter
                    x="-24.4%"
                    y="-22.0%"
                    width="148.8%"
                    height="148.8%"
                    filterUnits="objectBoundingBox"
                    id="filter-6"
                >
                    <feMorphology radius="0.75" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
                    <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <path
                    d="M17.9475517,28.8027344 C17.9084898,28.8417971 17.8629182,28.8710937 17.8108357,28.890625 L12.5765672,30.9804687 C12.530995,30.9934896 12.4789133,31 12.4203204,31 C12.3161554,31 12.215247,30.9609379 12.1175922,30.8828125 C12.0069168,30.7721349 11.9743657,30.6191416 12.0199379,30.4238281 L14.1097392,25.1894531 C14.1292701,25.1373695 14.1585661,25.0917971 14.197628,25.0527344 L24.5001527,14.75 L28.2500763,18.5 L17.9475517,28.8027344 Z M31.6289138,13.3339844 C31.8763058,13.5944023 32,13.8955061 32,14.2373047 C32,14.5791033 31.8763058,14.8736967 31.6289138,15.1210938 L29.5000509,17.25 L25.7501272,13.5 L27.8789901,11.3710938 C28.1263822,11.1236967 28.4209696,11 28.7627612,11 C29.1045528,11 29.4056505,11.1236967 29.6660631,11.3710938 L31.6289138,13.3339844 Z"
                    id="path-7"
                />
                <filter
                    x="-35.0%"
                    y="-25.0%"
                    width="170.0%"
                    height="170.0%"
                    filterUnits="objectBoundingBox"
                    id="filter-8"
                >
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.301682692 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
            </defs>
            <g id="sidebar-editor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="responsive-+-sidebar-copy-4" transform="translate(-1594.000000, -808.000000)">
                    <g id="Group-6" transform="translate(1601.000000, 814.000000)">
                        <g id="Rectangle-Copy-7">
                            <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
                            <use
                                stroke="#00CCFF"
                                strokeWidth="1.5"
                                fill="#212121"
                                fillRule="evenodd"
                                xlinkHref="#path-1"
                            />
                        </g>
                        <g id="Rectangle-Copy-4">
                            <use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-3" />
                            <use
                                stroke="#00CCFF"
                                strokeWidth="1.5"
                                fill="#212121"
                                fillRule="evenodd"
                                xlinkHref="#path-3"
                            />
                        </g>
                        <g id="Rectangle-Copy-3">
                            <use fill="black" fillOpacity="1" filter="url(#filter-6)" xlinkHref="#path-5" />
                            <use
                                stroke="#00CCFF"
                                strokeWidth="1.5"
                                fill="#121212"
                                fillRule="evenodd"
                                xlinkHref="#path-5"
                            />
                        </g>
                        <g id="e-copy" fillRule="nonzero">
                            <use fill="black" fillOpacity="1" filter="url(#filter-8)" xlinkHref="#path-7" />
                            <use fill="#FFF35C" xlinkHref="#path-7" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SvgContainer>
)

const CloseSidebarIcon = () => (
    <SvgContainer>
        <svg viewBox="0 0 31 46">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Custom-Preset" transform="translate(-19.000000, -27.000000)" fill="#FFFFFF">
                    <path
                        d="M26.6778917,27 C26.9023925,27 27.12689,27.089799 27.3513909,27.2693997 L49.397267,49.2928258 C49.5768677,49.4873932 49.6666667,49.719374 49.6666667,49.988775 C49.6666667,50.2581761 49.5768677,50.4901568 49.397267,50.6847243 L27.3513909,72.7081503 C27.1717902,72.9027177 26.9435511,73 26.6666667,73 C26.3897822,73 26.1615431,72.9027177 25.9819424,72.7081503 L19.2693997,65.9956076 C19.089799,65.8160069 19,65.5877678 19,65.3108834 C19,65.0339989 19.089799,64.8057598 19.2693997,64.6261591 L33.9067838,49.988775 L19.2693997,35.3513909 C19.089799,35.1717902 19,34.9435511 19,34.6666667 C19,34.3897822 19.089799,34.1615431 19.2693997,33.9819424 L25.9819424,27.2693997 C26.2064433,27.089799 26.4384241,27 26.6778917,27 Z"
                        id="next"
                    />
                </g>
            </g>
        </svg>
    </SvgContainer>
)

const SidebarIcon = () => (
    <SvgContainer>
        <svg viewBox="0 0 42 35">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="#FFFFFF" fillRule="nonzero">
                    <g>
                        <path d="M26.6,31.3157895 L26.6,3.68421053 L3.73333333,3.68421053 L3.73333333,11.0526316 L3.73333333,31.3157895 L26.6,31.3157895 Z M42,0 L42,35 L0,35 L0,0 L42,0 Z" />
                    </g>
                </g>
            </g>
        </svg>
    </SvgContainer>
)

const BulkEditButton = ({ onClick, className = '' }) => {
    return (
        <Button onClick={onClick} className={className}>
            <BulkEditIcon />
        </Button>
    )
}

const CloseSidebarButton = ({ onClick, className }) => {
    return (
        <Button onClick={onClick} className={className}>
            <CloseSidebarIcon />
        </Button>
    )
}

const SidebarButton = ({ onClick, className }) => {
    return (
        <Button onClick={onClick} className={className}>
            <SidebarIcon />
        </Button>
    )
}

export { BulkEditButton, CloseSidebarButton, SidebarButton }
