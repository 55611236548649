// Action Type
export const ADS_ENABLED = 'ADS_ENABLED'

// Action
export const setAdsEnabled = (adsEnabled) => ({ type: ADS_ENABLED, adsEnabled })
// Selector
export const getAdsEnabled = (state) => state

// Reducer
export default (state = [], action = {}) => {
    switch (action.type) {
        case ADS_ENABLED:
            return action.adsEnabled
        default:
            return state
    }
}
