import { createContext } from 'react'

export type GridType = 'feed' | 'grid'
export type GridTypeContextProps = {
    setGridType: (gridType: GridType) => void
    gridType: GridType
}

const GridTypeContext = createContext({
    gridType: 'grid',
} as GridTypeContextProps)

export default GridTypeContext
