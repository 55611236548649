import { giphyDarkCharcoal, giphyLightCharcoal } from '@giphy/colors'
import { uniqueId } from 'lodash'
import { useRef } from 'react'
import { Gradient } from 'types'
import { gradientToCss } from 'utils'
import useBackgroundSegmentStyles from './hooks/use-background-segments-styles'
import { Background, Segment } from './style'

type Props = {
    className?: string
    color?: string
    gradient?: Gradient
    snapTo?: number[]
}

const RangeSliderBackground = ({ color, className, gradient, snapTo = [] }: Props) => {
    const idRef = useRef<string>(uniqueId('range-slider-background'))
    const segmentStyles = useBackgroundSegmentStyles(snapTo, gradient)
    const fallbackBackground = color || `linear-gradient(0deg, ${giphyDarkCharcoal}, ${giphyLightCharcoal})`

    if (!segmentStyles.length) {
        return (
            <Background
                className={className}
                style={{
                    background: gradient ? gradientToCss(gradient) : fallbackBackground,
                }}
            />
        )
    }

    return (
        <Background className={className}>
            {segmentStyles.map((style, i) => (
                <Segment key={`${idRef.current}${i}`} style={{ background: fallbackBackground, ...style }} />
            ))}
        </Background>
    )
}

export default RangeSliderBackground
