import { giphyBlack, giphyLightCharcoal, giphyLightestGrey } from '@giphy/colors'
import { compact, flatten, groupBy, isEqual, orderBy, unionWith, uniqBy, zip } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { fetchAutoTags, fetchAutoTagsWithFrames, fetchRelatedKeywords } from 'shared/api'
import log from 'shared/util/log'
import styled from 'styled-components'
import { CreationToolSourceFile } from '../../../site/creation-tools/types'
import getSourceFrames from '../../../site/creation-tools/utils/get-source-frames'
import Empty from './empty'
import Placeholder from './placeholder'
import { TagSource, TagSuggestion } from './suggested-tag'
import SuggestedTagsList from './suggested-tags-list'
import WhatsThis from './whats-this'

const MAX_TAGS_DISPLAYED = 10

const Container = styled.div`
    width: 100%;
    background: ${giphyBlack};
    border: 1px dashed ${giphyLightCharcoal};
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 15px;
`

const TopRow = styled.div`
    width: 100%;
    height: 25%;
    position: relative;
    margin-bottom: 5px;
`

const Headline = styled.span`
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    margin: 10px;
    color: ${giphyLightestGrey};
`

const BetaPill = styled.img`
    position: relative;
    display: inline-block;
    margin-bottom: -5px;
    height: 20px;
`

enum TagEventType {
    Accepted = 'Tag Accepted',
    Rejected = 'Tag Rejected',
    Seen = 'Tag Seen',
}

export interface SuggestedTagEvent {
    category: string
    action: TagEventType
    tag: TagSuggestion
}

interface TagMeta {
    tag: string
    isCommon: boolean
    isFeatured: boolean
}

type Props = {
    currentTags: (TagMeta | string)[]
    endpoint: string
    lastEnteredTags: string[]
    gifIds?: string[]
    addSugTag: (tag: string, originator?: string) => void
    uploadStatus?: string
    isVertical: boolean
    source?: CreationToolSourceFile | undefined
    showRelated?: boolean
    onBufferedEventsChange?: (events: SuggestedTagEvent[]) => void
}

const SuggestedTagWrapper = ({
    currentTags,
    endpoint,
    lastEnteredTags,
    gifIds,
    addSugTag,
    uploadStatus,
    isVertical,
    source,
    showRelated = false,
    onBufferedEventsChange,
}: Props) => {
    const gifIdsArr: string[] = gifIds || []
    const [suggestedTagResults, setSuggestedTagResults] = useState<TagSuggestion[]>([])
    const [removedTags, setRemovedTags] = useState<string[]>([])
    const [isFetchingRelated, setIsFetchingRelated] = useState<boolean>(false)
    const [isFetchingAuto, setIsFetchingAuto] = useState<boolean>(false)

    // buffered tag events won't be fired until one or more gifIds are present
    const [bufferedTagEvents, setBufferedTagEvents] = useState<SuggestedTagEvent[]>([])
    // create a ref to be used in the interval function
    const bufferedTagEventsRef = useRef(bufferedTagEvents)
    bufferedTagEventsRef.current = bufferedTagEvents

    const fireSuggestedTagEvents = () => {
        // don't fire in the middle of upload
        if (uploadStatus !== 'uploading' && gifIdsArr.length > 0) {
            setBufferedTagEvents([])
        }
    }

    const bufferTagEvents = (events: SuggestedTagEvent[]) => {
        setBufferedTagEvents((currEvents) => unionWith(currEvents, events, isEqual))
    }

    const addSuggestedTags = (suggestedTags: TagSuggestion[]) => {
        setSuggestedTagResults((curr) => sortAndFilterSuggestedTags(unionWith(curr, suggestedTags, isEqual)))
    }

    useEffect(() => {
        callingAllAutoTags(gifIdsArr, source)
        // fire any events for good measure. this seems to work for upload
        fireSuggestedTagEvents()
    }, [gifIdsArr.join(','), status]) // only run if the gifIds actually changed

    // only fetch related tags for user tag changes
    useEffect(() => {
        if (showRelated) {
            fetchRelatedTags(lastEnteredTags)
        }
    }, [showRelated, lastEnteredTags])

    // another hook for managing event firing
    useEffect(() => {
        const interval = setInterval(() => {
            fireSuggestedTagEvents()
        }, 250)

        // fire off remaining buffered events on teardown. this doesn't seem to work on upload
        return () => {
            clearInterval(interval)
            fireSuggestedTagEvents()
        }
    }, [])

    useEffect(() => {
        onBufferedEventsChange && onBufferedEventsChange(bufferedTagEvents)
    }, [bufferTagEvents, onBufferedEventsChange])

    const seenSuggestedTag = (tag: TagSuggestion) => {
        bufferTagEvents([
            {
                category: endpoint,
                action: TagEventType.Seen,
                tag: tag,
            },
        ])
    }

    const addSuggestedTag = (tag: TagSuggestion) => {
        addSugTag(tag.name, TagSource.Auto)
        bufferTagEvents([
            {
                category: endpoint,
                action: TagEventType.Accepted,
                tag: tag,
            },
        ])
        removeSuggestedTag(tag, true)
    }

    const removeSuggestedTag = (tag: TagSuggestion, wasAdded = false) => {
        if (!wasAdded) {
            bufferTagEvents([
                {
                    category: endpoint,
                    action: TagEventType.Rejected,
                    tag: tag,
                },
            ])
        }
        setRemovedTags((curr) => curr.concat(tag.name))
        setSuggestedTagResults((currResults) => currResults.filter((tagSuggestion) => tagSuggestion !== tag))
    }

    const fetchRelatedTag = async (tag: string) => {
        try {
            const {
                data = [],
                meta: { response_id = '' },
            } = await fetchRelatedKeywords(tag)

            return {
                suggestedTags: data.map((tag) => {
                    return {
                        name: tag.name,
                        source: TagSource.Related,
                        response_id: response_id,
                        confidence: tag.score,
                    } as TagSuggestion
                }),
            }
        } catch (error) {
            log.debug(error)
            return {}
        }
    }

    const fetchRelatedTags = async (newTags: string[]) => {
        if (newTags.length === 0) return
        setIsFetchingRelated(true)
        let results: TagSuggestion[] = []
        const promises: Promise<any>[] = []
        newTags.forEach((tag) => {
            if (tag.length > 0) {
                promises.push(
                    fetchRelatedTag(tag).then((data) => {
                        if (data.suggestedTags) {
                            results = results.concat(data.suggestedTags)
                        }
                    })
                )
            }
        })
        await Promise.all(promises)
        setIsFetchingRelated(false)
        addSuggestedTags(results)
    }

    const _fetchAutoTags = async (gifId: string) => {
        if (!gifId) return {}
        try {
            const { data = [] } = await fetchAutoTags(gifId)

            return {
                suggestedTags: data.map((tag) => {
                    return {
                        name: tag.name,
                        source: tag.source,
                        response_id: '',
                        confidence: tag.score,
                    } as TagSuggestion
                }),
            }
        } catch (error) {
            log.debug(error)
            return {}
        }
    }

    const _fetchAutoTagsWithFrames = async (sourceFile: CreationToolSourceFile) => {
        if (!sourceFile.source) return {}

        try {
            let frames = await getSourceFrames({ sourceFile, length: 4 })
            let encodedFrames = frames.map((frame) => frame.canvas.toDataURL('image/jpeg').split(';base64,')[1])
            const { data = [] } = await fetchAutoTagsWithFrames(encodedFrames)

            return {
                suggestedTags: data.map((tag) => {
                    return {
                        name: tag.name,
                        source: tag.source,
                        response_id: '',
                        confidence: tag.score,
                    } as TagSuggestion
                }),
            }
        } catch (error) {
            log.debug(error)
            return {}
        }
    }

    const callingAllAutoTags = async (gifs: string[], sourceFile: CreationToolSourceFile | undefined) => {
        setIsFetchingAuto(true)
        let results: TagSuggestion[] = []
        const promises: Promise<any>[] = []
        if (gifs.length) {
            gifs.forEach((gifId) => {
                promises.push(
                    _fetchAutoTags(gifId).then((data) => {
                        if (data.suggestedTags) {
                            results = results.concat(data.suggestedTags)
                        }
                    })
                )
            })
        } else if (sourceFile && sourceFile.source) {
            promises.push(
                _fetchAutoTagsWithFrames(sourceFile).then((data) => {
                    if (data.suggestedTags) {
                        results = results.concat(data.suggestedTags)
                    }
                })
            )
        }

        await Promise.all(promises)
        setIsFetchingAuto(false)
        addSuggestedTags(results)
    }

    const sortAndFilterSuggestedTags = (suggestedTags: TagSuggestion[]) => {
        const groupedTags = Object.values(groupBy(suggestedTags, 'source')).map((source) =>
            orderBy(source, 'confidence').reverse()
        )
        const zippedTags = compact(flatten(zip<TagSuggestion>(...groupedTags)))
        const uniqueTags = uniqBy(zippedTags, 'name')
        const filteredTags = uniqueTags
            .filter((tag) => {
                return removedTags.every((r) => !isEqual(r, tag.name))
            })
            .filter((tagSug) => {
                return !currentTags.map((t) => (typeof t === 'string' ? t : t.tag)).includes(tagSug.name)
            })
        return filteredTags
    }

    const isFetching = isFetchingRelated || isFetchingAuto

    return (
        <Container>
            <TopRow>
                <Headline>Suggested Tags</Headline>
                {!isVertical && <BetaPill src="/static/img/svg/pill-beta-gradient.svg" alt="" />}
                <WhatsThis isVertical={isVertical} />
            </TopRow>
            {suggestedTagResults.length === 0 || isFetching ? (
                !showRelated && !isFetching ? (
                    <Empty noRelated />
                ) : (
                    <Placeholder isFetching={isFetching} />
                )
            ) : (
                <SuggestedTagsList
                    suggestedTags={suggestedTagResults}
                    onAdd={addSuggestedTag}
                    onDelete={removeSuggestedTag}
                    onSeen={seenSuggestedTag}
                    isVertical={isVertical}
                    lastEnteredTags={lastEnteredTags}
                    maxTagsDisplayed={MAX_TAGS_DISPLAYED}
                />
            )}
        </Container>
    )
}

export default SuggestedTagWrapper
