import Blocks from './blocks'
import Snake from './snake'
import { LoaderSize, LoaderType } from './types'

type Props = {
    className?: string
    size?: LoaderSize
    type?: LoaderType
}

const Loader = ({ className, size = 'medium', type = 'blocks' }: Props) => {
    switch (type) {
        case 'blocks':
            return <Blocks className={className} size={size} />
        case 'snake':
            return <Snake className={className} size={size} />
    }
}

export default Loader
