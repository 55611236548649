import { IGif } from '@giphy/js-types'
import { createContext, useContext, useEffect, useState, useRef } from 'react'
import * as React from 'react'
import { IChannel } from 'shared/types/channel'
import RefreshDataContext from './refresh-data'
import MessageContext from './message'
import UserContext from './user'
import { fetchChannel, deleteChannel, moveChannel, removeGif } from 'shared/api/channels'

export type ChannelMap = {
    [id: number]: IChannel
}

type CollectionContextProps = {
    rootChannelId: number
    channels: ChannelMap
    getChannelChildren(channel: IChannel, update?: boolean): Promise<IChannel[]>
    loadChannels: (channelId?: number) => Promise<IChannel | null>
}

type SuccessMessageAddGifToCollection = {
    featuredGif: IGif
    channel: IChannel
}

type SuccessMessageAddGifToMultipleCollections = {
    gif: IGif
    channels: IChannel[]
}

type SuccessMessageAddGifsToCollection = {
    gifs: number[] | string[]
    channel: IChannel
}

type SuccessMessageCreatedCollection = {
    channel: IChannel
    channelParent: number
}

export type SuccessMessageCombineCollections = {
    draggableName: string
    targetChannelName: string
    draggableId: string
    sourceId: string
    originalIndex: number
}

type AddToCollectionContextProps = {
    gif?: IGif | undefined
    setGif: (gif: IGif | undefined) => void
    activeChannelId: number
    setActiveChannelId: (index: number) => void
    activeChannelColor: string | undefined
    setActiveChannelColor: (string: string | undefined) => void
    isReordering?: boolean
    setReordering: (isReordering: boolean) => void
    activeChannel: IChannel | undefined
    setActiveChannel: (channel: IChannel) => void

    isDragging: boolean
    setIsDragging: (isDragging: boolean) => void

    // Undo move collections
    combineCollectionsSuccessMessage: SuccessMessageCombineCollections | undefined
    setCombineCollectionsSuccessMessage: (message: SuccessMessageCombineCollections | undefined) => void
    undoCombineCollections: () => void

    // Undo add gifs to collection via gif detail page
    addGifToCollectionSuccessMessage: SuccessMessageAddGifToCollection | undefined
    setAddGifToCollectionSuccessMessage: (message: SuccessMessageAddGifToCollection | undefined) => void

    // Undo add gifs to MULTIPLE collection via gif detail page
    addGifToMultipleCollectionsSuccessMessage: SuccessMessageAddGifToMultipleCollections | undefined
    setAddGifToMultipleCollectionsSuccessMessage: (
        message: SuccessMessageAddGifToMultipleCollections | undefined
    ) => void

    // Undo add gifs to collection
    addGifsToCollectionSuccessMessage: SuccessMessageAddGifsToCollection | undefined
    setAddGifsToCollectionSuccessMessage: (message: SuccessMessageAddGifsToCollection | undefined) => void
    undoAddGifsToCollection: () => void

    // Undo creating collections
    collectionCreatedSuccessMessage: SuccessMessageCreatedCollection | undefined
    setCollectionCreatedSuccessMessage: (message: SuccessMessageCreatedCollection | undefined) => void
    undoCreatingCollections: () => void
}

const UNDO_TIMER = 7000

export const CollectionContext = createContext({} as AddToCollectionContextProps & CollectionContextProps)

export function flatten(children: IChannel[]) {
    let map: ChannelMap = {}
    children.forEach((channel) => {
        map[channel.id] = channel
        if (channel.children) {
            map = { ...map, ...flatten(channel.children) }
        }
    })
    return map
}

export const fakeRootChannelId = 0
const makeRootChannel = (rootChildren: IChannel[]) => {
    const children = rootChildren.map((channel) => ({ ...channel, parent: fakeRootChannelId }))
    return {
        children,
        id: fakeRootChannelId,
        has_children: children.length > 0,
        display_name: 'Root',
    } as IChannel
}

type CollectionContextManager = {
    children: React.ReactNode
}

const CollectionContextManager = ({ children }: CollectionContextManager) => {
    // Message context
    const { sendMessage } = useContext(MessageContext)

    // Refresh context
    const { onRefreshChannel, refreshChannel } = useContext(RefreshDataContext)

    // Add to collection states
    const { user } = useContext(UserContext)
    const [isReordering, setReordering] = useState<boolean>(false)
    const [activeChannelId, setActiveChannelId] = useState<number>(user.channelId)
    const [gif, setGif] = useState<IGif | undefined>()
    const [activeChannel, setActiveChannel] = useState<IChannel | undefined>(undefined)
    const [activeChannelColor, setActiveChannelColor] = useState<string | undefined>(undefined)
    const [isDragging, setIsDragging] = useState<boolean>(false)

    // Success Message after Add a GIF via Git Detail Page, we also use this variable to handle the Undo Actions
    const [addGifToCollectionSuccessMessage, setAddGifToCollectionSuccessMessage] = useState<
        SuccessMessageAddGifToCollection | undefined
    >(undefined)

    useEffect(() => {
        if (addGifToCollectionSuccessMessage !== undefined) {
            setTimeout(() => setAddGifToCollectionSuccessMessage(undefined), UNDO_TIMER)
        }
    }, [addGifToCollectionSuccessMessage])

    // Success Message after Add a GIF to MULTIPLE Collections via Git Detail Page, we also use this variable to handle the Undo Actions
    const [addGifToMultipleCollectionsSuccessMessage, setAddGifToMultipleCollectionsSuccessMessage] = useState<
        SuccessMessageAddGifToMultipleCollections | undefined
    >(undefined)

    useEffect(() => {
        if (addGifToMultipleCollectionsSuccessMessage !== undefined) {
            setTimeout(() => setAddGifToMultipleCollectionsSuccessMessage(undefined), UNDO_TIMER)
        }
    }, [addGifToMultipleCollectionsSuccessMessage])

    // Success message after move collections
    const [combineCollectionsSuccessMessage, setCombineCollectionsSuccessMessage] = useState<
        SuccessMessageCombineCollections | undefined
    >(undefined)

    useEffect(() => {
        if (combineCollectionsSuccessMessage !== undefined) {
            setTimeout(() => setCombineCollectionsSuccessMessage(undefined), UNDO_TIMER)
        }
    }, [combineCollectionsSuccessMessage])

    const undoCombineCollections = async () => {
        if (combineCollectionsSuccessMessage) {
            const { draggableId, sourceId, originalIndex } = combineCollectionsSuccessMessage
            if (originalIndex > 0) {
                await moveChannel(
                    parseInt(draggableId),
                    channels.current[sourceId].children[originalIndex - 1].id,
                    'right'
                )
            } else {
                await moveChannel(parseInt(draggableId), parseInt(sourceId), 'first-child')
            }
            const newChildren = await getChannelChildren(channels.current[sourceId])
            refreshChannel(
                {
                    ...channels.current[sourceId],
                    children: newChildren,
                    has_children: !!channels.current[sourceId].children.length,
                },
                { isReordering: true }
            )
            setCombineCollectionsSuccessMessage(undefined)
        }
    }

    // Success message after add gifs to collections
    const [addGifsToCollectionSuccessMessage, setAddGifsToCollectionSuccessMessage] = useState<
        SuccessMessageAddGifsToCollection | undefined
    >(undefined)

    useEffect(() => {
        if (addGifsToCollectionSuccessMessage !== undefined) {
            setTimeout(() => setAddGifsToCollectionSuccessMessage(undefined), UNDO_TIMER)
        }
    }, [addGifsToCollectionSuccessMessage])

    const undoAddGifsToCollection = async () => {
        if (addGifsToCollectionSuccessMessage) {
            for (const gif of addGifsToCollectionSuccessMessage.gifs) {
                await removeGif({
                    gif,
                    channel: addGifsToCollectionSuccessMessage.channel.id,
                })
            }

            setAddGifsToCollectionSuccessMessage(undefined)
        }
    }

    // Success message after create collection
    const [collectionCreatedSuccessMessage, setCollectionCreatedSuccessMessage] = useState<
        SuccessMessageCreatedCollection | undefined
    >(undefined)

    useEffect(() => {
        if (collectionCreatedSuccessMessage !== undefined) {
            setTimeout(() => setCollectionCreatedSuccessMessage(undefined), UNDO_TIMER)
        }
    }, [collectionCreatedSuccessMessage])

    const undoCreatingCollections = async () => {
        if (collectionCreatedSuccessMessage) {
            await deleteChannel(collectionCreatedSuccessMessage.channel.id!)
            const parentChannel = await fetchChannel(collectionCreatedSuccessMessage.channelParent, {
                fetch_children: true,
            })
            refreshChannel(parentChannel)
            sendMessage(`Collection deleted!`, 'success')
            setAddGifsToCollectionSuccessMessage(undefined)
        }
    }

    // Collection states
    const [, setUpdate] = useState<object>()
    const channels = useRef<ChannelMap>({})
    const setChannels = (c: ChannelMap) => {
        channels.current = c
        setUpdate({})
    }
    const getChannelChildren = async (channel: IChannel) => {
        if (channel.has_children) {
            // fetching a channel will give you its children
            // if we have different Collection Managers,
            // they won't make the same request because fetching is cached
            const fetchedChannel = await fetchChannel(channel.id, { fetch_children: true })
            const newChildren = fetchedChannel.children || []
            // put it in context
            setChannels({
                ...channels.current,
                ...flatten(newChildren),
                [fetchedChannel.id]: fetchedChannel,
            })
            // but also return them to use them inline
            return newChildren
        }
        return channel.children || []
    }

    useEffect(() => {
        const offRefresh = onRefreshChannel((channel: IChannel) => {
            setChannels({
                ...channels.current,
                [channel.id]: channel,
            })
        })
        return offRefresh
    }, [])

    const loadChannels = async (channelId?: number) => {
        if (channelId) {
            const fetchedChannel = await fetchChannel(channelId, { fetch_children: true })
            setChannels({
                ...channels.current,
                [fetchedChannel.id]: fetchedChannel,
            })
        } else if (user.channelId) {
            const channel = await fetchChannel(user.channelId, { fetch_children: true })
            setChannels(flatten([makeRootChannel([channel])]))
            return channel
        }
        return null
    }

    return (
        <CollectionContext.Provider
            value={{
                activeChannelId,
                setActiveChannelId,
                activeChannelColor,
                setActiveChannelColor,
                isReordering,
                setReordering,
                activeChannel,
                setActiveChannel,
                gif,
                setGif,
                addGifToCollectionSuccessMessage,
                setAddGifToCollectionSuccessMessage,
                channels: channels.current,
                getChannelChildren,
                rootChannelId: user.channelId,
                loadChannels,
                isDragging,
                setIsDragging,
                combineCollectionsSuccessMessage,
                setCombineCollectionsSuccessMessage,
                undoCombineCollections,
                addGifsToCollectionSuccessMessage,
                setAddGifsToCollectionSuccessMessage,
                undoAddGifsToCollection,
                collectionCreatedSuccessMessage,
                setCollectionCreatedSuccessMessage,
                undoCreatingCollections,
                addGifToMultipleCollectionsSuccessMessage,
                setAddGifToMultipleCollectionsSuccessMessage,
            }}
        >
            {children}
        </CollectionContext.Provider>
    )
}

export default CollectionContextManager
