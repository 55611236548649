import { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import AnimatedButton from '../../animated-button'
import VideoContext from '../video-context'
import { PlayPauseSize } from './icons/play-pause'
import { VolumeOffIcon, VolumeOnIcon } from './icons/volume'

const bloop = keyframes`
    0% { transform: scale(1) translateY(0); }
    50% { transform: scale(1.5) translateY(-30%); }
    100% { transform: scale(1) translateY(0); }
`

const Container = styled.div<{ isMuted: boolean; hasAudio: boolean }>`
    animation: ${bloop} ${(props) => (props.isMuted ? 0.5 : 0)}s ease-in-out 0.8s 1 normal backwards;
    flex-grow: 0;
    flex-shrink: 0;
    height: 38px;
    margin-right: 5px;
    opacity: ${(props) => (props.hasAudio ? 1 : 0.3)};
    position: relative;
    pointer-events: ${(props) => (props.hasAudio ? 'auto' : 'none')};
    width: 38px;
    margin-top: ${PlayPauseSize / 2}px;
`

const Volume = () => {
    const { isMuted, isReady, hasAudio, setMuted } = useContext(VideoContext)
    const toggleMute = () => setMuted(!isMuted)
    return (
        <Container hasAudio={hasAudio} isMuted={!!isMuted}>
            <AnimatedButton
                disabled={!isReady}
                isOn={!isMuted && hasAudio}
                offIcon={<VolumeOffIcon />}
                onClick={toggleMute}
                onIcon={<VolumeOnIcon />}
            />
        </Container>
    )
}

export default Volume
