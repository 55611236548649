import { IRendition, IGif } from '@giphy/js-types'

export const filmfestToGif = (videoData) => {
    videoData.video = { ...videoData.media } // get all the media props (not sure if we'll use any)
    // gif.video props
    videoData.video.assets = {}
    videoData.video.previews = {}
    // add assets
    videoData.media.assets.forEach((a: IRendition) => {
        // @ts-ignore
        if (!a.is_source) {
            videoData.video.assets[a.width] = a
        } else {
            videoData.video.assets.source = a
        }
    })
    // add assets
    videoData.media.previews.forEach((a: IRendition) => {
        videoData.video.previews[a.width] = a
    })
    // result is an IGif (I hope)
    return videoData as IGif
}
