import { uniqueId } from 'lodash'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import useKeyPress from 'react-use/lib/useKeyPress'
import { Range } from 'types'
import Fill from './fill'
import { HANDLE_WIDTH, Handle, Slider } from './style'

const SNAPPING_THRESHOLD_IN_PX = 5

type Props = {
    children?: ReactNode
    className?: string
    disabled?: boolean
    dndId?: string
    handleColor: string
    handlesOnly?: boolean
    maxWidth: number
    range: Range
    snapTo?: number[]
    onChange?: (range: Range) => void
    onUpdate?: (range: Range) => void
}

const RangeSliderDnd = ({
    children,
    className,
    disabled,
    dndId,
    handleColor,
    handlesOnly = false,
    maxWidth: maxWidthProp,
    range,
    snapTo = [],
    onChange,
    onUpdate,
}: Props) => {
    const idRef = useRef<string>(dndId || uniqueId('range-input-slider'))
    const [startOffset, setStartOffset] = useState<number>(0)
    const [endOffset, setEndOffset] = useState<number>(0)
    const [isCtrlPressed] = useKeyPress('Control')

    const maxWidth = maxWidthProp - HANDLE_WIDTH
    const startInPixels = useMemo(() => Math.round(range.start * maxWidth), [maxWidth, range.start])
    const endInPixels = useMemo(() => Math.round(range.end * maxWidth), [maxWidth, range.end])
    const snapToInPixels = useMemo(
        () => snapTo.map((value) => Math.round(value * maxWidthProp)),
        [maxWidthProp, snapTo]
    )

    const controlValue: Range = useMemo(
        () => ({
            end: (endInPixels + endOffset) / maxWidth,
            start: (startInPixels + startOffset) / maxWidth,
        }),
        [endOffset, endInPixels, maxWidth, startOffset, startInPixels]
    )

    const onStartHandleDrag = (x: number) => {
        if (!snapTo.length || isCtrlPressed) {
            setStartOffset(x)
            return
        }

        const startValue = startInPixels + x
        const snapPoint = snapToInPixels.find((value) => Math.abs(value - startValue) <= SNAPPING_THRESHOLD_IN_PX)

        setStartOffset(snapPoint ? snapPoint - startInPixels : x)
    }

    const onEndHandleDrag = (x: number) => {
        if (!snapTo.length || isCtrlPressed) {
            setEndOffset(x)
            return
        }

        const endValue = endInPixels + x + HANDLE_WIDTH
        const snapPoint = snapToInPixels.find((value) => Math.abs(value - endValue) <= SNAPPING_THRESHOLD_IN_PX)

        setEndOffset(snapPoint ? snapPoint - endInPixels - HANDLE_WIDTH : x)
    }

    const onFillDrag = (x: number) => {
        if (!snapTo.length || isCtrlPressed) {
            setStartOffset(x)
            setEndOffset(x)
            return
        }

        const startValue = startInPixels + x
        const endValue = endInPixels + x + HANDLE_WIDTH
        let isSnapToEnd = false

        const snapPoint = snapToInPixels.find((value) => {
            if (Math.abs(value - startValue) <= SNAPPING_THRESHOLD_IN_PX) {
                return true
            }

            if (Math.abs(value - endValue) <= SNAPPING_THRESHOLD_IN_PX) {
                isSnapToEnd = true
                return true
            }

            return false
        })

        if (snapPoint) {
            const snapOffset = isSnapToEnd ? snapPoint - endInPixels - HANDLE_WIDTH : snapPoint - startInPixels

            setStartOffset(snapOffset)
            setEndOffset(snapOffset)
        } else {
            setStartOffset(x)
            setEndOffset(x)
        }
    }

    const updateRange = () => {
        onChange?.(controlValue)
        setEndOffset(0)
        setStartOffset(0)
    }

    useEffect(() => {
        onUpdate?.(controlValue)
    }, [controlValue])

    return (
        <Slider
            className={className}
            style={{
                left: startInPixels + startOffset,
                right: maxWidth - (endInPixels + endOffset),
            }}
        >
            <Handle
                controlColor={handleColor}
                controlId={idRef.current}
                controlType="range-start"
                controlValue={controlValue}
                disabled={disabled}
                max={endInPixels - startInPixels}
                min={-startInPixels}
                onDrag={onStartHandleDrag}
                onDragEnd={updateRange}
            />
            <Fill
                controlId={idRef.current}
                controlType="range"
                controlValue={controlValue}
                disabled={handlesOnly || disabled}
                max={maxWidth - endInPixels}
                min={-startInPixels}
                onDrag={onFillDrag}
                onDragEnd={updateRange}
            >
                {children}
            </Fill>
            <Handle
                controlColor={handleColor}
                controlId={idRef.current}
                controlType="range-end"
                controlValue={controlValue}
                disabled={disabled}
                max={maxWidth - endInPixels}
                min={startInPixels - endInPixels}
                onDrag={onEndHandleDrag}
                onDragEnd={updateRange}
            />
        </Slider>
    )
}

export default RangeSliderDnd
