import { Children, cloneElement, PureComponent } from 'react'
import { connect } from 'react-redux'
import * as selectors from 'shared/redux/reducers'
import styled, { css } from 'styled-components'
import { omit } from 'lodash'
import { Container as HeaderContainer } from '../modules/header/components/header'

const shiftLeft = 625
const shiftRight = 431

const Container = styled.div`
    ${({ style: { translateDirection } }) =>
        translateDirection === 'left'
            ? css`
                  width: 1040px;
                  position: absolute;
                  left: calc(50% - ${shiftLeft}px);
                  top: 0;
              `
            : translateDirection === 'right'
            ? css`
                  width: 1040px;
                  position: absolute;
                  left: calc(50% - ${shiftRight}px);
                  top: 0;
              `
            : translateDirection === 'shrink' &&
              css`
                  position: absolute;
                  left: calc(50% - ${shiftRight}px);
                  top: 0;
                  ${HeaderContainer} {
                      width: 864px;
                  }
              `};
`

@connect((state) => ({
    siteContainerStyle: selectors.getSiteContainerStyle(state),
}))
export default class SiteContainer extends PureComponent {
    render() {
        const { children, siteContainerStyle } = this.props
        const childrenWithProps = Children.map(children, (child) =>
            cloneElement(child, omit(this.props, ['children', 'siteContainerStyle']))
        )

        return <Container style={siteContainerStyle}>{childrenWithProps}</Container>
    }
}
