import { FC, HTMLProps } from 'react'
import styled from 'styled-components'
import VisibilityOffIcon from '../icons/svg/visibility-off'
import VisibilityOnIcon from '../icons/svg/visibility-on'

const ShowHidePasswordButton = styled.button`
    position: absolute;
    bottom: 17px;
    right: 4px;
    padding: 0;
    padding-left: 5px;
    background: none;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    text-align: center;
    color: #828282;

    &:focus {
        border: 1px #aaaaaa solid;
    }
`

export type ShowHidePasswordIconProps = {
    show?: boolean
    error?: boolean
}

const ShowHidePasswordIcon: FC<ShowHidePasswordIconProps & HTMLProps<HTMLButtonElement>> = ({ show, onClick }) => {
    const iconProps = {
        width: 20,
        height: 20,
    }

    return (
        <ShowHidePasswordButton onClick={onClick} type="button">
            {show ? <VisibilityOnIcon {...iconProps} /> : <VisibilityOffIcon {...iconProps} />}
        </ShowHidePasswordButton>
    )
}

export default ShowHidePasswordIcon
