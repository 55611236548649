import styled from 'styled-components'

type IEditableTextarea = {
    isHidden: boolean
}

export default styled.textarea<IEditableTextarea>`
    background: transparent;
    border: 0;
    outline: none;
    cursor: text;
    position: relative;
    font: inherit;
    color: inherit;
    opacity: ${(props) => (props.isHidden ? 0 : 1)};
    line-height: inherit;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    resize: none;
    -webkit-appearance: none;
`
