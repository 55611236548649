import { BackgroundVideo, Buttons, Container, DownloadButton } from './style'

const GifMaker = () => {
    return (
        <Container>
            <BackgroundVideo />
            <Buttons>
                <DownloadButton href="https://giphy.page.link/home" target="_blank">
                    Use Our App
                </DownloadButton>
            </Buttons>
        </Container>
    )
}

export default GifMaker
