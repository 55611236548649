import { giphyDarkestGrey, giphyDarkGrey, giphyWhite } from '@giphy/colors'
import { IVideo } from 'shared/types/video'
import { getFavicon } from 'shared/util/url'
import styled from 'styled-components'

const CTAContainer = styled.div<{ width: number }>`
    width: ${(props) => props.width}px;
    height: 42px;
    background: linear-gradient(90deg, ${giphyDarkestGrey} 0%, ${giphyDarkGrey} 100%);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: ${giphyWhite};
    margin: 10px 0px 15px 0px;
`

const FaviconContainer = styled.div`
    text-align: center;
    margin: 0px 10px 0px 0px;
    display: inline;
`

const TextWrapper = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
    vertical-align: middle;
    display: inline-block;
`

const Favicon = styled.img`
    width: 16px;
    height: 16px;
    vertical-align: middle;
`
const Icon = styled.div`
    color: white;
    margin: 5px 0px 2px 5px;
`

type Props = {
    gif: IVideo
    width?: number
}
const CTA = ({ gif, width = 400 }: Props) => {
    const { text, link } = gif.cta || { text: '', link: '' }
    const favicon = getFavicon(link)

    return (
        <CTAContainer width={width}>
            <a href={link} target="_blank" rel="noreferrer noopener" style={{ width: '100%', maxWidth: '440px' }}>
                <FaviconContainer>
                    {favicon ? <Favicon src={favicon} /> : <Icon className="ss-icon ss-globe" />}
                </FaviconContainer>
                <TextWrapper>{text}</TextWrapper>
            </a>
        </CTAContainer>
    )
}

export default CTA
