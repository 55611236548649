import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, heightFromControlSize } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import LabelComponent from './label'
import StepperComponent from './stepper'

export const Container = styled.div<{ $arrowColor: string; $disabled: boolean; $size: ControlSize }>`
    color: #fff;
    display: inline-flex;

    ${({ $arrowColor, $size }) => css`
        background: ${$arrowColor};
        border-radius: ${borderRadiusFromControlSize($size)}px;
        height: ${heightFromControlSize($size)}px;
    `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
        `}
`

export const Label = styled(LabelComponent)<{ $color: string }>`
    border-radius: inherit;
    height: 100%;
    width: 100%;

    ${({ $color }) => css`
        background: ${$color};
        color: ${textColorFromBackgroundColor($color)};
    `}
`

export const Stepper = styled(StepperComponent)`
    color: inherit;
    height: 100%;
    flex-shrink: 0;
`
