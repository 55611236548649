exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".B7Dk7PSEA2TXsbomGmsHV {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100%;\n}", ""]);

// exports
exports.locals = {
	"container": "B7Dk7PSEA2TXsbomGmsHV"
};