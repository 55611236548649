import { createContext } from 'react'
import { IChannel } from 'shared/types/channel'

export type EditPanelContextProps = {
    allowBulkEditMode: boolean
    bulkEditMode: boolean
    onBulkEditModeChange: () => void
    setChannels: (channels: IChannel[]) => void
    editModeChannels: IChannel[]
}

const EditPanelContext = createContext({
    allowBulkEditMode: false,
    bulkEditMode: false,
    onBulkEditModeChange: () => {},
} as EditPanelContextProps)

export default EditPanelContext
