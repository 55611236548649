import { giphyDarkestGrey } from '@giphy/colors'
import { ReactNode, useRef } from 'react'
import { useRefDimensions } from 'utils'
import Portal from '../../../portal'
import { DropdownOptions } from '../types'
import { useDropdownCoordinates } from './hooks/use-coordinates'
import { useDropdownDirection } from './hooks/use-direction'
import { useDropdownOffset } from './hooks/use-offset'
import { HitZone, Tooltip, Wrapper } from './style'

type Props = {
    children?: ReactNode
    className?: string
    minWidth?: number
    offset?: number
    options?: Partial<DropdownOptions>
    triggerRect: DOMRect | null
    onClose: () => void
}

const Dropdown = ({
    children,
    className,
    minWidth,
    offset: offsetProp = 0,
    triggerRect = null,
    options = {},
    onClose,
}: Props) => {
    const tooltipRef = useRef<HTMLDivElement>(null)
    const dimensions = useRefDimensions(tooltipRef)
    const direction = useDropdownDirection(triggerRect, dimensions)
    const coordinates = useDropdownCoordinates(triggerRect, direction)
    const offset = useDropdownOffset(coordinates, dimensions, offsetProp)
    const isReady = !!direction && !!coordinates && !!offset
    const style = { ...(options.style || {}) }

    return (
        <Portal>
            <HitZone onClick={() => onClose()} />
            <Wrapper style={isReady ? { left: coordinates.x, top: coordinates.y } : { opacity: 0 }}>
                <Tooltip
                    $up={direction === 'up'}
                    className={className}
                    color={options.color || giphyDarkestGrey}
                    direction={direction}
                    offset={offset}
                    ref={tooltipRef}
                    style={minWidth ? { ...style, minWidth } : style}
                    width={options.width}
                >
                    {children}
                </Tooltip>
            </Wrapper>
        </Portal>
    )
}

export default Dropdown
