import { IGif, IRendition } from '@giphy/js-types'
import { PureComponent } from 'react'
import withStorageContextManager from 'react-storage-context'
import Components from './components'
import Container from './container'
import VideoContextManager, { ShareActions, VideoContextAPI } from './context'
import getAspectRatio from './util/get-media-aspect-ratio'
import { filmfestToGif } from './util/normalize'

export type PlayerEvents = {
    onPlay?: () => void
    onPause?: () => void
    onEnded?: () => void
    onLoop?: (count: number) => void
    onMute?: (isMuted: boolean) => void
    onShare?: (isShare: boolean) => void
    onShareAction?: (action?: ShareActions) => void
    onCopyLink?: (showCopy: boolean) => void
    onFirstPlay?: (ms: number) => void
    onQuartile?: (q: any) => void
    onFullscreen?: (isFullscreen: boolean) => void
    onWaiting?: (count: number) => void
    onStalledResume?: (stalledDuration: number) => void
    onPlayerReady?: (api: VideoContextAPI) => void
}
type Props = {
    gif?: IGif
    width: number
    height?: number
    autoPlay?: boolean
    events: PlayerEvents
    forceMute?: boolean
    toggleShare?: boolean
    toggleEmbed?: boolean
    noShare?: boolean
    noCutout?: boolean
    showAttribution?: boolean
    className?: string
    videoData?: any
}
// TODO this seemed to have disappeared., mock it for now
const saveSession = () => {}
class VideoPlayer extends PureComponent<Props> {
    playerElement: HTMLDivElement | null

    static defaultProps = {
        autoPlay: false,
        events: {
            onPlay: () => {
                console.log('on play')
            },
            onPause: () => {
                console.log('on pause')
            },
        },
        toggleShare: false,
        toggleEmbed: false,
    }

    componentDidMount() {
        // force update after mount to pass in the ref playerElement
        this.forceUpdate()
    }

    render() {
        let { gif } = this.props
        if (!gif) {
            const { videoData } = this.props
            gif = filmfestToGif(videoData)
        }

        if (!gif.video) {
            throw new Error(`@giphy/react-giphy-brand Video configration error`)
        }

        const {
            width,
            height,
            className = '',
            autoPlay,
            events,
            forceMute,
            toggleShare,
            toggleEmbed,
            noShare,
            noCutout,
            showAttribution,
        } = this.props

        const dimensions = { width, height }

        if (!width && height) {
            // set width based on height
            dimensions.width = height * getAspectRatio(gif)
        }
        if (!height && width) {
            // set height based on width
            dimensions.height = width / getAspectRatio(gif)
        }

        return (
            <Container
                className={className}
                height={dimensions.height!}
                ref={(c) => (this.playerElement = c)}
                width={dimensions.width}
            >
                {this.playerElement && (
                    <VideoContextManager
                        autoPlay={autoPlay}
                        dimensions={dimensions as IRendition}
                        events={events}
                        forceMute={forceMute}
                        noCutout={noCutout}
                        noShare={!!noShare}
                        playerElement={this.playerElement}
                        saveSession={saveSession}
                        session={{}}
                        showAttribution={!!showAttribution}
                        toggleEmbed={!!toggleEmbed}
                        toggleShare={!!toggleShare}
                        videoData={gif}
                    >
                        <Components />
                    </VideoContextManager>
                )}
            </Container>
        )
    }
}

export default withStorageContextManager('react-giphy-brand-video')(VideoPlayer)
