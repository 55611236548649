import { giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { colorToRgba } from 'utils/src/color'
import { fontSizeFromControlSize, heightFromControlSize } from 'utils/src/control-size'

export const Container = styled.div<{ $disabled: boolean; $dividerColor: string; $size: ControlSize }>`
    align-items: flex-start;
    display: flex;
    height: ${({ $size }) => heightFromControlSize($size)}px;
    font-size: ${({ $size }) => fontSizeFromControlSize($size)}px;
    position: relative;

    &:before {
        background: ${({ $dividerColor }) => $dividerColor};
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
        `}
`

export const Item = styled.button<{
    $active: boolean
    $activeColor: string
    $activeBorderColor: string
    $color: string
}>`
    all: unset;
    align-items: center;
    border-bottom: 3px solid ${({ $activeBorderColor }) => colorToRgba($activeBorderColor, 0)};
    box-sizing: border-box;
    color: ${({ $color }) => $color};
    cursor: pointer;
    display: flex;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    padding: 0 0.8em;
    position: relative;
    text-align: center;
    transition:
        border-bottom-color 0.1s ease-out,
        color 0.1s ease-out;
    user-select: none;

    span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:active {
        opacity: 0.9;
    }

    &:focus-visible {
        border-radius: 4px;
        outline: 2px solid ${giphyYellow};
    }

    ${({ $active, $activeColor, $activeBorderColor }) =>
        $active &&
        css`
            border-bottom-color: ${$activeBorderColor};
            color: ${$activeColor};
            overflow: visible;
        `}
`
