import styled from 'styled-components'
import { WhenInView } from 'shared/util/in-view'
import Social from './social'
import Blocks from './blocks'
import { IMAGE_PATH } from '../constants'
import { AboutPageSticker } from '../style'

const STICKER_HEIGHT: number = 90

const StickerWrapper = styled.div`
    margin: -2.3em 0 -0.5em;
`

const Sticker = styled(AboutPageSticker)`
    height: ${STICKER_HEIGHT}px;
`

const AboutPageContact = () => (
    <section id="contact">
        <StickerWrapper>
            <WhenInView height={STICKER_HEIGHT}>
                <Sticker src={`${IMAGE_PATH}/stickers/eyes.gif`} alt="" />
            </WhenInView>
        </StickerWrapper>
        <h1>Contact Us</h1>
        <Social />
        <Blocks />
    </section>
)

export default AboutPageContact
