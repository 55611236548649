import styled from 'styled-components'
import { giphyDarkCharcoal, giphyGreen, giphyRed } from '../css/colors'

const Container = styled.div`
    cursor: pointer;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    overflow: hidden;
    position: relative;
    user-select: none;
    width: 100%;
`

const Option = styled.div<{ isActive: boolean }>`
    color: ${(props) => (props.isActive ? `#000` : '#fff')};
    float: left;
    height: 100%;
    position: relative;
    text-align: center;
    transition: color 0.2s ease-in-out 0.2s;
    width: 50%;
`

const Fill = styled.div<{ isOn: boolean }>`
    background: ${(props) => (props.isOn ? giphyGreen : giphyRed)};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(${(props) => (props.isOn ? '0' : '100%')}, 0, 0);
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width: 50%;
`

type Props = {
    activeColor?: string
    backgroundColor?: string
    isOn: boolean
    onLabel?: string
    offLabel?: string
    onToggle: (isOn: boolean) => void
}

const ToggleButton = ({
    backgroundColor = giphyDarkCharcoal,
    isOn,
    onToggle,
    onLabel = 'On',
    offLabel = 'Off',
}: Props) => (
    <Container onClick={() => onToggle(!isOn)} style={{ backgroundColor }}>
        <Fill isOn={isOn} />
        <Option isActive={isOn}>{onLabel}</Option>
        <Option isActive={!isOn}>{offLabel}</Option>
    </Container>
)

export default ToggleButton
