import GifMaker from '../components/gif-maker'
import Header from '../components/header/header'
import Page from './page'

const GifMakerPage = ({}) => {
    return (
        <Page>
            <Header />
            <GifMaker />
        </Page>
    )
}

export default GifMakerPage
