import { giphyDarkGrey, giphyDarkestGrey } from '@giphy/colors'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { setGADataLayer } from 'analytics'
import './top-leaderboard-ad.css'

export const CONTAINER_HEIGHT = 120
export const AD_DIMENSIONS = [728, 90]
export const UNIT_NAME = 'top_leaderboard'

const AdContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: ${giphyDarkestGrey};
    height: ${CONTAINER_HEIGHT}px;
    width: 100%;
`

const AdPlaceholder = styled.div`
    background-color: ${giphyDarkGrey};
    width: ${AD_DIMENSIONS[0]}px;
    height: ${AD_DIMENSIONS[1]}px;
`
type Props = { enabled: boolean; pageName: string }
const TopLeaderboardAd = ({ enabled, pageName }: Props) => {
    const anchorDivId = 'top-leaderboard-ad-anchor'
    const className = `htlad-desktop_${UNIT_NAME}_${pageName}`
    const [anchorDiv, setAnchorDiv] = useState(document.getElementById(anchorDivId))
    const [adsLoaded, setAdsLoaded] = useState(false)

    useEffect(() => {
        if (enabled === true && !anchorDiv) {
            let anchorDiv = document.createElement('div')
            anchorDiv.id = anchorDivId
            document.body.prepend(anchorDiv)
            setAnchorDiv(anchorDiv)
        } else if (!enabled && anchorDiv) {
            anchorDiv.remove()
            setAnchorDiv(null)
        }
    }, [])

    useEffect(() => {
        if (anchorDiv && !adsLoaded) {
            setGADataLayer({ event: 'activate_ads' })
            setAdsLoaded(true)
        }
    }, [anchorDiv])

    return (
        <>
            {enabled === true &&
                anchorDiv &&
                createPortal(
                    <AdContainer>
                        <AdPlaceholder>
                            <div className={className}></div>
                        </AdPlaceholder>
                    </AdContainer>,
                    anchorDiv,
                    anchorDivId
                )}
        </>
    )
}

export default TopLeaderboardAd
