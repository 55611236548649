import { giphyDarkestGrey, giphyLightGrey } from '@giphy/colors'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { GradientButton } from '../../site/creation-tools/components/buttons'
import CreationToolGradient from '../../site/creation-tools/types/gradient'
import { mobile } from '../util/media-query'

const Container = styled.div`
    width: 100%;
    margin: auto;
    background-image: url('/static/img/creation_tools/supercut-wave.svg');
    background-size: cover;
    background-color: ${giphyDarkestGrey};
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    box-sizing: border-box;
    height: 240px;
    border-radius: 8px;
`

const Info = styled.div`
    margin-left: 50px;
    width: 50%;
    ${mobile.css`
        margin-left: 0px;
        width: auto;
    `}
`

const Description = styled.div`
    font-size: 15px;
    color: ${giphyLightGrey};
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 22px;
    margin-top: 8px;
`
const Title = styled.div`
    font-weight: bold;
    margin-left: auto;
    font-size: 26px;
    font-family: NexaBlack;
    letter-spacing: 0px;
`

const HelpButton = styled(GradientButton)`
    border-radius: 2px;
    height: 36px;
    width: 220px;
    margin-top: 28px;
    ${mobile.css`
        margin-left: auto;
        margin-right: auto;
    `}
`

const Peeker = styled.img`
    top: -84px;
    position: absolute;
    width: 356px;
    right: 4px;
`

type MoreQuestionProps = {
    title: string
    description: any
    helpLink: string
    className?: string
}

const MoreQuestions = ({ title, description, helpLink, className }: MoreQuestionProps) => {
    const isMobile = useMedia(mobile.query)

    return (
        <Container className={className}>
            <Info>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <HelpButton
                    {...{
                        as: 'a',
                        href: helpLink,
                        colors: CreationToolGradient.colors(CreationToolGradient.IndigoPurple),
                        target: '_blank',
                        rel: 'noopener noreferrer',
                    }}
                >
                    Visit Help Center
                </HelpButton>
            </Info>
            {!isMobile && <Peeker src="/static/img/creation_tools/supercut-leaner.gif" />}
        </Container>
    )
}

export default MoreQuestions
