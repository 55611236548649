import { useContext } from 'react'
import mobileCheck from 'is-mobile'
import styled from 'styled-components'
import { getBestRenditionUrl } from '@giphy/js-util'
import VideoContext from '../video-context'
import getPosterUrl from '../util/poster-url'
import { PlayIcon } from './icons/play-pause'

const IS_MOBILE = mobileCheck()

const Container = styled.div<{ isMobile: boolean }>`
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    padding: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
    align-items: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
    transition: transform 0.2s ease-out;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
    }
`

const Poster = () => {
    const {
        setPoster,
        videoData,
        dimensions: { width, height },
    } = useContext(VideoContext)
    const { video } = videoData
    if (!video) {
        return null
    }
    const play = () => {
        setPoster(false)
    }

    const posterUrl = videoData.images
        ? getBestRenditionUrl(videoData, width, height, { isStill: false, useVideo: false })
        : getPosterUrl(Object.values(video.previews), width, height)
    return (
        <Container isMobile={IS_MOBILE} onClick={play}>
            <img src={posterUrl} />
            <PlayIcon size={45} />
        </Container>
    )
}

export default Poster
