import { giphyDarkestGrey, giphyLightestGrey, giphyWhite } from '@giphy/colors'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useLocalStorage } from 'react-use'
import { AccountsManagerContext } from 'shared/contexts/accounts-manager'
import { channelAccessBottomBannerIndex } from 'shared/css/z-indexes'
import styled from 'styled-components'

const Container = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 54px;
    background: ${giphyDarkestGrey};
    border-radius: 0px 0px 6px 6px;
    z-index: ${channelAccessBottomBannerIndex};
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1040px;
    height: 100%;
`

const ChannelAvatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
`

const UserAvatar = styled.img`
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-left: 8px;
    margin-right: 4px;
`

const LeftColumn = styled.div`
    display: flex;
    align-items: center;
    font-family: 'interface';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: ${giphyLightestGrey};

    span {
        font-weight: bold;
    }
`

const RightColumn = styled.div`
    display: flex;
    align-items: center;

    a {
        display: flex;
        align-items: center;
        color: ${giphyLightestGrey};
        font-family: 'interface';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;

        span {
            font-weight: bold;
        }

        i {
            margin-left: 10px;
            height: 13px;
        }
    }
`

const CloseIcon = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    mask-image: url(/static/img/close.svg);
    right: 16px;
    cursor: pointer;
    background: ${giphyWhite};
`

const ChannelAccessBottomBanner = () => {
    const [confirmedChannelAccessId, setConfirmedChannelAccessId] = useLocalStorage<number>(
        'banner-confirmed-channel-access-id',
        undefined
    )
    const { channel_access } = useContext(AccountsManagerContext)
    const { location } = useHistory()

    useEffect(() => {
        if (!channel_access?.emulated_user || channel_access?.emulated_user.id !== confirmedChannelAccessId) {
            setConfirmedChannelAccessId(0)
        }
    }, [channel_access])

    if (channel_access?.emulated_user && confirmedChannelAccessId !== channel_access.emulated_user.id) {
        return (
            <Container>
                <Content>
                    <LeftColumn>
                        <ChannelAvatar alt={'Channel Avatar'} src={channel_access.emulated_user.avatar_url} />
                        Browsing as &nbsp;
                        <span>
                            {channel_access.emulated_user?.display_name || channel_access.emulated_user?.username}
                        </span>
                    </LeftColumn>
                    <RightColumn>
                        <a href={`/api/v4/channels/access/logout?next=${location.pathname}`}>
                            Switch back to{' '}
                            <UserAvatar alt={'User Channel Avatar'} src={channel_access.user.avatar_url} />
                            &nbsp;
                            <span>{channel_access.user?.display_name || channel_access.user?.username}</span>
                            <i className="ss-navigateright" />
                        </a>
                    </RightColumn>
                </Content>
                <CloseIcon
                    onClick={() => {
                        setConfirmedChannelAccessId(channel_access.emulated_user?.id)
                    }}
                />
            </Container>
        )
    } else {
        return <></>
    }
}

export default ChannelAccessBottomBanner
