import { titleFont } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled, { createGlobalStyle, css } from 'styled-components'
import { DropdownButton, SelectList } from 'ui'

export const TransparencyReportGlobalStyles = createGlobalStyle`
    body {
        background-color: #000 !important;
        min-height: 100vh !important;
        min-width: 0 !important;
        width: 100% !important;
    }
`

export const TransparencyReportContainer = styled.div`
    font-size: 22px;
    text-align: left;
    overflow: hidden;
    a {
        text-decoration: none;
        font-weight: bold;
        color: #00ccff;
    }
    h1,
    h2,
    h3 {
        color: #fff;
        font-family: ${titleFont};
        font-size: 1.65em;
        line-height: 1.2;
        // margin: 0.2em auto 0.5em;

        -webkit-font-smoothing: antialiased;
    }

    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 1.3em;
        margin-bottom: 15px;
    }

    h3 {
        font-family: inherit;
        font-size: 1em;
    }

    p,
    small {
        font-size: 17px;
        display: block;
        line-height: 20px;
        text-align: inherit;

        ${mobile.css`
            margin: 10px 0
        `}
    }

    small {
        font-size: 0.8em;
    }

    strong {
        font-weight: bold;
    }

    ${mobile.css`
        font-size: 18px;
    `}
`

export const Header = styled.div`
    background: #121212;
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const InnerHeader = styled.div`
    display: flex;
    width: 1040px;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;

    ${mobile.css`
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    `}
`
export const FullWidthContainer = styled.div<{ short?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 140px 0px 140px 0px;
    &:nth-child(even) {
        background-color: black;
    }

    &:nth-child(odd) {
        background-color: #121212;
    }

    ${mobile.css`
        padding: 50px 0px 50px 0px;
    `}

    ${(props) =>
        props.short &&
        css`
            padding: 60px 0px 60px 0px;
        `}
`
export const CenteredSection = styled.div`
    display: flex;
    width: 1040px;
    flex-direction: row;
    margin: auto;
    align-items; center;

    ${mobile.css`
        flex-direction: column;
        width: 100%;
    `}
`

export const Pane = styled.div<{ horizontal?: boolean; top?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 50%;
    justify-content: center;

    &:nth-child(odd) {
        margin: 0px 50px 0px 0px;
        ${mobile.css`
            margin: 0;
        `}
    }

    &:nth-child(even) {
        margin: 0px 0px 0px 50px;
        ${mobile.css`
            margin: 0;
        `}
    }
    ${mobile.css`
        padding: 10px;
        margin: 0;
        box-sizing:border-box;
    `}

    ${(props) =>
        props.horizontal &&
        css`
            flex-direction: row;
        `}

       

    ${(props) =>
        props.top &&
        css`
            justify-content: flex-start;
        `}

    ${mobile.css`
        flex-direction: column !important;
        width: 100%;
    `}
`

export const SlideshowPane = styled(Pane)`
    ${mobile.css`
        order: 1;
    `}
`

export const HeroSection = styled.div`
    text-align: center;
    padding: 100px 0px 1px 0px;
    position: relative;
    background: linear-gradient(232.84deg, #9933ff 0%, #6157ff 100%);

    ${mobile.css`
        padding: 75px 0px 1px 0px;
        width: 100%;
        box-sizing: border-box;
        background-image: url('/static/img/transparency-report/background-fallback.png');
    `}

    h1 {
        position: relative;
    }
    p {
        width: 910px;
        text-align: center;
        margin: auto;
        font-size: 22px;
        margin-top: 45px;
        line-height: 25.88px;
        position: relative;

        ${mobile.css`
            padding: 10px;
            width: 100%;
            box-sizing: border-box;
        `}
    }
`

export const ReportImage = styled.img`
    ${mobile.css`
        width: 100%;
    `}
`

export const LockImage = styled(ReportImage)`
    ${mobile.css`
        margin-top: -15px;
    `}
`

export const DogImg = styled(ReportImage)`
    z-index: 2;
    top: 28px;
    position: relative;

    ${mobile.css`
        width: 100%;
        position: absolute;
        top: -2px;
    `}
`

export const Dot = styled.div`
    width: 238px;
    height: 238px;
    background-image: url('/static/img/transparency-report/purple-dot.png');
    position: relative;
    text-align: center;
    margin: 0px 7.5px;
    ${mobile.css`
        text-align: center;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin: 10px 0;
    `}
`

export const RedDot = styled(Dot)`
    background-image: url('/static/img/transparency-report/red-dot.png');
`

export const BlueDot = styled(Dot)`
    background-image: url('/static/img/transparency-report/blue-dot.png');
`

export const LargeInnerText = styled.span`
    opacity: 0.6;
    font-family: NexaBlack;
    position: absolute;
    top: 91px;
    transform: translateX(-50%);
    font-size: 60px;

    ${mobile.css`
        top: 120px;
    `}
`

export const SmallInnerText = styled.span`
    position: absolute;
    font-family: 'interface';
    font-weight: 700;
    font-size: 17px;
    top: 73px;
    transform: translateX(-50%);
    width: 100%;
`

export const BackgroundBox = styled.img`
    position: absolute;
    top: 20px;

    ${mobile.css`
       position: relative;
       width: 100%;
       top: 0;
    `}
`
export const AnchorLinkContainer = styled.div<{ wide?: boolean }>`
    background: rgba(0, 0, 0, 0.1);
    border-radius: 56px;
    padding: 25px 50px;
    font-family: 'interface';
    position: relative;

    ul {
        display: block;
        position: relative;
    }
    li {
        position: relative;
        font-size: 17px;
        font-weight: 700;
        display: inline;

        ${mobile.css`
            display: block;
            font-size: 18px;
            margin: 10px 0px;
        `}

        margin: 0px 15px;

        a {
            text-decoration: none;
            color: inherit;
        }

        &:after {
            position: absolute;
            right: -18px;
            top: 0px;
            content: '|';
            ${mobile.css`
                content: '';
            `}

            );
        }
        &:last-child {
            &:after {
                content: '';
            }
        }
    }
    width: 768px;

    ${(props) =>
        props.wide &&
        css`
            width: 910px;
        `}

    margin: 50px auto;

    ${mobile.css`
        width: 100%;
        box-sizing: border-box;
    `}
`

export const GlassPeeker = styled.img`
    position: absolute;
    width: 382px;
    bottom: -175px;
    right: -275px;

    ${mobile.css`
        display: none;
    `}
`
export const Slideshow = styled.div`
    flex-direction: row;
    display: flex;
    overflow: hidden;
    width: 100%;
    width: 520px;
    height: 520px;
    position: relative;
    ${mobile.css`
        width: 100%;
        height: auto;
    `}
`

export const Slide = styled.div`
    width: 520px;
    flex-direction: row;
    justify-content: center;
    display: inline-block;
    text-align: center;

    ${mobile.css`
        width: 100%;
        margin: 20px 0px 20px 0px;
        img{
            width: 100%;
        }
    `}
`

export const SlideContainer = styled.div<{ whichSlide?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 1040px;
    transition: 0.2s linear all;

    ${(props) =>
        props.whichSlide &&
        css`
            left: -520px;
        `}

    ${mobile.css`
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        left: 0;
    `}
`

export const LeftControl = styled.div`
    position: absolute;
    width: 15px;
    height: 30px;
    background-image: url('/static/img/transparency-report/arrow-back.png');
    top: 190px;
    left: -25px;
    cursor: pointer;

    ${mobile.css`
        display: none;
    `}
`

export const RightControl = styled(LeftControl)`
    background-image: url('/static/img/transparency-report/arrow-forward.png');
    top: 190px;
    left: 530px;
`
export const SlideIndicatorContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 445px;
    flex-direction: row;
    display: flex;
    transform: translateX(-50%);
    ${mobile.css`
        display: none;
    `}
`
export const SlideIndicator = styled.div<{ active: boolean }>`
    width: 14px;
    height: 14px;
    margin: 0px 4px;
    background-image: url('/static/img/transparency-report/dot-active.png');
    ${(props) =>
        !props.active &&
        css`
            opacity: 0.2;
        `}
`

export const PieChart = styled.img`
    width: 475px;
    ${mobile.css`
        width: 100%;
    `}
`

export const YearModule = styled.div`
    background: linear-gradient(88.24deg, #9933ff -20.09%, #6157ff 98.56%);
    border-radius: 2px;
    width: 123px;
    height: 36px;
    font-family: 'interface';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    position: relative;
    span {
        position: absolute;
        top: 6px;
        left: 26px;
    }
    div {
        background-image: url('/static/img/transparency-report/down-arrow.png');
        width: 17px;
        height: 17px;
        position: absolute;
        right: 10px;
        top: 10px;
    }
`

export const Disclaimer = styled.p`
    padding-bottom: 50px;
`

export const CivilRequestsHeader = styled.h2`
    ${mobile.css`
        margin-top:20px;
    `}
`

export const YearDropdownButton = styled(DropdownButton)`
    width: 123px;
    font-family: 'interface';
`

export const YearDropDownList = styled(SelectList)`
    width: 123px;
    font-size: 20px;
    padding: 20px !important;
}
`

export const PDFObject = styled.object`
    min-height: 100vh;
`
