import { giphyBlack, giphyDarkCharcoal, giphyLightGrey, giphyWhite, giphyWhiteSmoke } from '@giphy/colors'
import { pingback, Pingback, PingbackActionType } from '@giphy/js-analytics'
import { PingbackEventType } from '@giphy/js-types'
import { get, isEmpty } from 'lodash'
import { useCallback, useContext, useEffect } from 'react'
import Media from 'react-media'
import TapLink from 'shared/components/tap-link'
import VerifiedBadge from 'shared/components/users/verified-badge'
import UserContext from 'shared/contexts/user'
import { desktop, mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { ChannelItem, Item } from '../types'
import TrendingIcon from './icons/trending'

const Avatar = styled.div<{ imgSrc: string }>`
    background-image: url('${({ imgSrc }) => imgSrc}');
    width: 40px;
    height: 40px;
    margin-right: calc(6px * 2);
    background-size: cover;
    background-position: center;
`

const DropdownItem = styled.div<{ isChannel: boolean | string; isFocusedIndexLink: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ isChannel }) => (isChannel ? 60 : 40)}px;

    ${desktop.css`
          border-bottom: 1px dashed ${giphyWhiteSmoke};
          height: 60px;
          background-color: ${({ isFocusedIndexLink }) => (isFocusedIndexLink ? giphyWhiteSmoke : giphyWhite)};

          &:last-child {
              border: none;
          }
      `}
`

const DropdownItemInfo = styled.div`
    padding-left: 12px;
    display: flex;
    align-items: center;
    color: ${giphyBlack};
    font-weight: normal;

    ${mobile.css`
          color: ${giphyWhite};
          font-style: italic;
          font-weight: 600;
          font-size: 16px;
      `}
`

const ResultInfo = styled.div<{ isChannel: string }>`
    display: flex;
    flex-direction: column;
    line-height: 20px;

    ${desktop.css`
          flex-direction: row;
          span {
              margin-left: 5px;
          }
      `}

    ${({ isChannel }) =>
        isChannel &&
        mobile.css`
          font-style: normal;
      `}
`

const ResultTitle = styled.div<{ isChannelSearch: boolean }>`
    font-weight: ${({ isChannelSearch }) => (isChannelSearch ? 600 : 400)};

    ${mobile.css`
          white-space: pre;
          display: flex;
          align-items: flex-end;
          font-weight: 600;

          img, svg {
              margin-right: 10px;
          }
      `}
`

const ChannelSlug = styled.div`
    color: ${giphyDarkCharcoal};
    font-weight: 500;
    margin-left: 2px;

    ${mobile.css`
          color: ${giphyLightGrey};
          display: flex;
          align-items: center;
          margin-left: 0;

          div {
              margin-left: 2px;
          }
      `}
`

const FocusedIndexLink = styled.a`
    width: 100%;
`

const MobileTapLink = styled(TapLink)`
    width: 100%;
`

const Highlight = styled.span`
    margin-left: 0;
`

const NonHighlight = styled.span`
    color: ${giphyLightGrey};
`

type SearchDropdownItemProps = {
    isChannelSearch: boolean
    item: Item
    callback?: (slug: string, img: string) => void
    isTrending?: boolean
    searchTerm?: string
    isFocusedIndexLink: boolean
}

const SearchDropdownItem = ({
    isChannelSearch,
    item: { name, link, img, slug, response_id, analytics_response_payload },
    item,
    callback,
    isTrending = false,
    searchTerm = '',
    isFocusedIndexLink,
}: SearchDropdownItemProps) => {
    const match = name.match(searchTerm) || {}
    let nonMatchTerm = ''
    let matchTerm = ''

    if (!isEmpty(match)) {
        const inputMatch = get(match, 'input') || ''
        const indexMatch = get(match, 'index') || 0

        matchTerm = match[0]
        nonMatchTerm = inputMatch.slice(indexMatch + searchTerm.length)
    }

    const showChannelSlug = (matches) => isChannelSearch || (slug && matches)
    const showHighlight = !isTrending && !img && match
    const { user } = useContext(UserContext)
    const firePingback = useCallback(
        (actionType: PingbackActionType) => {
            if (!response_id) {
                console.error(`No response id for dropdown ${name}`)
                return null
            }

            let eventType: PingbackEventType = 'DROPDOWN_AUTOCOMPLETE' as PingbackEventType
            const action: Pingback = {
                userId: user?.id?.toString(),
                actionType,
                attributes: { search_response_id: response_id },
                eventType,
                analyticsResponsePayload: analytics_response_payload,
            }
            const channelId = (item as ChannelItem).channelId
            if (channelId) {
                action.eventType = 'DROPDOWN_CHANNEL_SUGGEST' as PingbackEventType
            }
            if (isTrending) {
                action.eventType = 'DROPDOWN_TRENDING_SUGGEST' as PingbackEventType
            }
            pingback(action)
        },
        [response_id, item, isTrending, name]
    )
    useEffect(() => {
        firePingback('SEEN')
    }, [name])

    const Content = useCallback(() => {
        return (
            <DropdownItemInfo>
                {img && <Avatar imgSrc={img} />}
                <ResultInfo isChannel={img}>
                    <Media query={mobile.query}>
                        {(matches: any) => (
                            <>
                                <ResultTitle isChannelSearch={showChannelSlug(matches)}>
                                    {matches ? (
                                        <>
                                            {isTrending ? (
                                                <TrendingIcon />
                                            ) : (
                                                !img && <img src="/static/img/search-icon.svg" width="17" />
                                            )}
                                            {showHighlight ? (
                                                <>
                                                    <Highlight>{matchTerm}</Highlight>
                                                    <NonHighlight>{nonMatchTerm}</NonHighlight>
                                                </>
                                            ) : (
                                                <>{name}</>
                                            )}
                                        </>
                                    ) : (
                                        <span>{name}</span>
                                    )}
                                </ResultTitle>
                                {(isChannelSearch || (slug && matches)) && (
                                    <ChannelSlug>
                                        {`@${slug}`}
                                        {matches && <VerifiedBadge is_verified={true} tooltip={false} />}
                                    </ChannelSlug>
                                )}
                            </>
                        )}
                    </Media>
                </ResultInfo>
            </DropdownItemInfo>
        )
    }, [img, isChannelSearch, slug])

    return (
        <DropdownItem isChannel={isChannelSearch || img} isFocusedIndexLink={isFocusedIndexLink}>
            <Media query={desktop.query}>
                {(matches: any) =>
                    matches ? (
                        <>
                            <FocusedIndexLink
                                href={link}
                                onMouseDown={(e) => {
                                    e.preventDefault()
                                    firePingback('CLICK')
                                    if (isChannelSearch) {
                                        callback && callback(slug, img)
                                    } else {
                                        location.href = link
                                    }
                                }}
                            >
                                <Content />
                            </FocusedIndexLink>
                        </>
                    ) : (
                        <>
                            <MobileTapLink
                                link={!isChannelSearch ? link : null}
                                onTap={() => {
                                    firePingback('CLICK')
                                    if (isChannelSearch) {
                                        callback && callback(slug, img)
                                    }
                                }}
                            >
                                <Content />
                            </MobileTapLink>
                        </>
                    )
                }
            </Media>
        </DropdownItem>
    )
}

export default SearchDropdownItem
