import { IconProps } from '../types/icons'

export const ClipIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7333 0H32.2667C38.7468 0 44 5.25319 44 11.7333V32.2667C44 38.7468 38.7468 44 32.2667 44H11.7333C5.25319 44 0 38.7468 0 32.2667V11.7333C0 5.25319 5.25319 0 11.7333 0ZM23.5559 13.8623C23.5559 13.6882 23.4871 13.5211 23.3644 13.3975C23.1077 13.1387 22.6898 13.1371 22.431 13.3938L18.2248 17.5652L12.3933 17.5659C12.0288 17.5659 11.7333 17.8614 11.7333 18.2259V25.9995C11.7333 26.364 12.0288 26.6595 12.3933 26.6595L17.0094 26.6595L22.4295 32.0713C22.5533 32.1949 22.721 32.2643 22.8959 32.2643C23.2604 32.2643 23.5559 31.9688 23.5559 31.6043V13.8623ZM33.7333 23.0168C33.7333 19.8519 32.0228 16.9806 29.304 15.4237C28.8332 15.154 28.2304 15.3129 27.9577 15.7786C27.6851 16.2442 27.8458 16.8403 28.3166 17.11C30.433 18.3219 31.7629 20.5543 31.7629 23.0168C31.7629 25.4743 30.4385 27.7027 28.3291 28.9166C27.8588 29.1872 27.6993 29.7836 27.973 30.2487C28.2466 30.7138 28.8497 30.8714 29.32 30.6008C32.0298 29.0415 33.7333 26.1753 33.7333 23.0168ZM29.4196 22.3869C29.4196 20.4876 28.31 18.7859 26.606 17.985C26.1146 17.754 25.5268 17.9608 25.2933 18.4468C25.0597 18.9328 25.2688 19.514 25.7602 19.745C26.7836 20.226 27.4492 21.2468 27.4492 22.3869C27.4492 23.4486 26.8728 24.4097 25.957 24.9272C25.4845 25.1942 25.3203 25.7893 25.5903 26.2565C25.8602 26.7237 26.462 26.8861 26.9345 26.6192C28.4582 25.7582 29.4196 24.1551 29.4196 22.3869Z"
            fill={color}
        />
    </svg>
)

export const ClipOutlineIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 0.5H33.5C40.1274 0.5 45.5 5.87258 45.5 12.5V33.5C45.5 40.1274 40.1274 45.5 33.5 45.5H12.5C5.87258 45.5 0.5 40.1274 0.5 33.5V12.5C0.5 5.87258 5.87258 0.5 12.5 0.5ZM42.5 12.5C42.5 7.52944 38.4706 3.5 33.5 3.5H12.5C7.52944 3.5 3.5 7.52944 3.5 12.5V33.5C3.5 38.4706 7.52944 42.5 12.5 42.5H33.5C38.4706 42.5 42.5 38.4706 42.5 33.5V12.5ZM24.5912 14.3229C24.5912 14.1838 24.5362 14.0503 24.4383 13.9515C24.2331 13.7448 23.8992 13.7434 23.6925 13.9486L19.14 18.4644L13.0273 18.4652C12.7361 18.4652 12.5 18.7013 12.5 18.9925V27.2381C12.5 27.5293 12.7361 27.7654 13.0273 27.7654L17.896 27.7654L23.6913 33.5518C23.7902 33.6506 23.9242 33.706 24.0639 33.706C24.3551 33.706 24.5912 33.4699 24.5912 33.1787V14.3229ZM35 24.04C35 20.8031 33.2505 17.8665 30.47 16.2742C29.9885 15.9984 29.372 16.161 29.0931 16.6372C28.8143 17.1134 28.9786 17.7231 29.4602 17.9989C31.6246 19.2384 32.9848 21.5215 32.9848 24.04C32.9848 26.5533 31.6303 28.8324 29.4729 30.0738C28.9919 30.3505 28.8289 30.9605 29.1087 31.4362C29.3886 31.9118 30.0054 32.0731 30.4864 31.7963C33.2578 30.2016 35 27.2702 35 24.04ZM30.5882 23.3957C30.5882 21.4532 29.4534 19.7129 27.7107 18.8938C27.2081 18.6575 26.607 18.869 26.3681 19.366C26.1292 19.8631 26.343 20.4575 26.8457 20.6938C27.8923 21.1857 28.573 22.2297 28.573 23.3957C28.573 24.4816 27.9835 25.4645 27.0469 25.9938C26.5637 26.2668 26.3958 26.8755 26.6719 27.3533C26.9479 27.8311 27.5634 27.9972 28.0466 27.7242C29.605 26.8436 30.5882 25.2041 30.5882 23.3957Z"
            fill={color}
        />
    </svg>
)

export const EmojiIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0ZM29.7994 24.5546C29.0037 24.2596 28.1179 24.6613 27.821 25.4518C26.8607 28.0086 25.0235 29.2112 22 29.2112C18.9765 29.2112 17.1393 28.0086 16.179 25.4518C15.8821 24.6613 14.9963 24.2596 14.2006 24.5546C13.4049 24.8495 13.0005 25.7295 13.2974 26.52C14.7173 30.3005 17.7213 32.2667 22 32.2667C26.2787 32.2667 29.2826 30.3005 30.7026 26.52C30.9995 25.7295 30.5951 24.8495 29.7994 24.5546ZM27.7957 14.6667C26.2462 14.6667 24.9902 15.9145 24.9902 17.4539C24.9902 18.9932 26.2462 20.2411 27.7957 20.2411C29.3451 20.2411 30.6012 18.9932 30.6012 17.4539C30.6012 15.9145 29.3451 14.6667 27.7957 14.6667ZM17.1745 14.6667C15.6251 14.6667 14.369 15.9145 14.369 17.4539C14.369 18.9932 15.6251 20.2411 17.1745 20.2411C18.724 20.2411 19.9801 18.9932 19.9801 17.4539C19.9801 15.9145 18.724 14.6667 17.1745 14.6667Z"
            fill={color}
        />
    </svg>
)

export const EmojiOutlineIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 0.5C35.4264 0.5 45.5 10.5736 45.5 23C45.5 35.4264 35.4264 45.5 23 45.5C10.5736 45.5 0.5 35.4264 0.5 23C0.5 10.5736 10.5736 0.5 23 0.5ZM23 3.5C33.7696 3.5 42.5 12.2304 42.5 23C42.5 33.7696 33.7696 42.5 23 42.5C12.2304 42.5 3.5 33.7696 3.5 23C3.5 12.2304 12.2304 3.5 23 3.5ZM30.9767 25.6126C30.1629 25.3109 29.257 25.7218 28.9533 26.5303C27.9712 29.1452 26.0922 30.3751 23 30.3751C19.9078 30.3751 18.0288 29.1452 17.0467 26.5303C16.743 25.7218 15.8372 25.3109 15.0234 25.6126C14.2096 25.9143 13.796 26.8142 14.0997 27.6227C15.5518 31.4892 18.624 33.5 23 33.5C27.3759 33.5 30.4481 31.4892 31.9003 27.6227C32.204 26.8142 31.7904 25.9143 30.9767 25.6126ZM28.9274 15.5C27.3427 15.5 26.0581 16.7762 26.0581 18.3506C26.0581 19.9249 27.3427 21.2011 28.9274 21.2011C30.5121 21.2011 31.7967 19.9249 31.7967 18.3506C31.7967 16.7762 30.5121 15.5 28.9274 15.5ZM18.0648 15.5C16.4802 15.5 15.1955 16.7762 15.1955 18.3506C15.1955 19.9249 16.4802 21.2011 18.0648 21.2011C19.6495 21.2011 20.9341 19.9249 20.9341 18.3506C20.9341 16.7762 19.6495 15.5 18.0648 15.5Z"
            fill={color}
        />
    </svg>
)

export const GifIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36 0C40.9706 0 45 4.02944 45 9V36C45 40.9706 40.9706 45 36 45H9C4.02944 45 0 40.9706 0 36V9C0 4.02944 4.02944 0 9 0H36ZM14.8926 16.5C10.7947 16.5 8.925 19.4001 8.925 22.2231C8.925 25.0461 10.561 27.9 14.877 27.9C16.9805 27.9 18.8814 27.1595 19.567 25.7866V20.989H14.2382V23.3801H16.9961V24.7685C16.3728 25.2158 15.4691 25.3238 14.8926 25.3238C12.6957 25.3238 11.9633 23.6115 11.9633 22.2231C11.9633 20.1097 13.1164 19.0608 14.8926 19.0608C15.6249 19.0608 16.591 19.2613 17.3856 20.0018L19.1775 18.2432C17.744 16.8085 16.404 16.5 14.8926 16.5ZM24.901 16.8085H21.8315V27.6069H24.901V16.8085ZM36 16.8085H27.6329V27.6069H30.6868V23.8892H35.6728V21.3901H30.6868V19.3693H36V16.8085Z"
            fill={color}
        />
    </svg>
)

export const GifOutlineIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.5 0.5C40.1274 0.5 45.5 5.87258 45.5 12.5V33.5C45.5 40.1274 40.1274 45.5 33.5 45.5H12.5C5.87258 45.5 0.5 40.1274 0.5 33.5V12.5C0.5 5.87258 5.87258 0.5 12.5 0.5H33.5ZM33.5 3.5H12.5C7.52944 3.5 3.5 7.52944 3.5 12.5V33.5C3.5 38.4706 7.52944 42.5 12.5 42.5H33.5C38.4706 42.5 42.5 38.4706 42.5 33.5V12.5C42.5 7.52944 38.4706 3.5 33.5 3.5ZM15.3926 17C16.904 17 18.244 17.3085 19.6775 18.7432L17.8856 20.5018C17.091 19.7613 16.1249 19.5608 15.3926 19.5608C13.6164 19.5608 12.4633 20.6097 12.4633 22.7231C12.4633 24.1115 13.1957 25.8238 15.3926 25.8238C15.9691 25.8238 16.8728 25.7158 17.4961 25.2685V23.8801H14.7382V21.489H20.067V26.2866C19.3814 27.6595 17.4805 28.4 15.377 28.4C11.061 28.4 9.425 25.5461 9.425 22.7231C9.425 19.9001 11.2947 17 15.3926 17ZM25.401 17.3085V28.1069H22.3315V17.3085H25.401ZM36.5 17.3085V19.8693H31.1868V21.8901H36.1728V24.3892H31.1868V28.1069H28.1329V17.3085H36.5Z"
            fill={color}
        />
    </svg>
)

export const StickerIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.8083 0C33.4113 0 42.5766 7.60424 44.959 17.94C45.01 18.1609 45.0127 18.3862 44.9712 18.6021C42.5007 17.5073 39.7656 16.8995 36.888 16.8995C25.8759 16.8995 16.9488 25.8006 16.9488 36.7807C16.9488 39.68 17.5712 42.4343 18.6901 44.918C18.3959 45.0159 18.075 45.0277 17.7663 44.945C7.71025 42.2492 0 32.9413 0 22.7419C0 10.1819 10.2116 0 22.8083 0ZM36.888 20.1121C38.963 20.1121 40.9497 20.4891 42.783 21.178L26.1397 37.8078L21.4494 42.4622L21.2418 42.6636C20.5496 40.8342 20.1708 38.8516 20.1708 36.7807C20.1708 27.5749 27.6554 20.1121 36.888 20.1121Z"
            fill={color}
        />
    </svg>
)

export const StickerOutlineIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.8083 0C33.4113 0 42.5766 7.60423 44.959 17.94C45.0833 18.4791 44.9206 19.0441 44.5283 19.4354L36.8529 27.1111L35.0644 28.8899L26.1397 37.8078L21.4494 42.4622L19.2733 44.5778C19.156 44.6938 19.0441 44.7704 18.9371 44.8117C18.583 45.001 18.1646 45.0517 17.7663 44.945C7.71025 42.2492 0 32.9413 0 22.7419C0 10.1819 10.2116 0 22.8083 0ZM22.8083 3.21261C11.9911 3.21261 3.22199 11.9562 3.22199 22.7419C3.22199 31.0036 9.2584 38.7037 17.3152 41.4507L17.263 41.1195L17.1956 40.6016L17.137 40.0803L17.0853 39.5454C17.0583 39.2419 17.0356 38.9433 17.0169 38.6551L16.9728 37.8281C16.9674 37.6974 16.9629 37.5708 16.9594 37.4489L16.9488 36.7807C16.9488 25.8006 25.8759 16.8995 36.888 16.8995C37.9318 16.8995 39.1543 17.1104 40.3131 17.4133L40.9374 17.587C41.1424 17.6474 41.3438 17.7102 41.5403 17.7746L41.5983 17.7942L41.447 17.2739C39.0176 9.36033 31.9034 3.61007 23.6713 3.23239L23.2846 3.21863L22.8083 3.21261ZM36.888 20.1121C27.6554 20.1121 20.1708 27.5749 20.1708 36.7807L20.185 37.5152L20.2262 38.3558L20.2771 39.0548L31.4357 27.969L34.2126 25.1971L39.0072 20.4022L38.7958 20.354C38.2211 20.2308 37.6795 20.1499 37.2182 20.1225L36.888 20.1121Z"
            fill={color}
        />
    </svg>
)

export const TextIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 0C34.1503 0 44 9.84974 44 22V44H22C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0ZM25.6457 14.1429H13.0743V17.2633H17.6831V29.8571H21.0369V17.2633H25.6457V14.1429ZM30.4427 15.7143L27.4653 16.0677V18.7621H25.6457V21.4125H27.4653V25.5205C27.4653 28.9217 29.1401 29.9597 31.3525 29.8493C32.1589 29.8051 32.7585 29.6726 33.5029 29.3634L32.8205 26.7131C32.4897 26.8898 31.9314 26.9781 31.5799 26.9781C31.0217 26.9781 30.422 26.6689 30.422 25.5205V21.4125H33.0273V18.7621H30.4427V15.7143Z"
            fill={color}
        />
    </svg>
)

export const TextOutlineIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.76 0C32.2254 0 41.52 9.29457 41.52 20.76V41.52H20.76C9.29457 41.52 0 32.2254 0 20.76C0 9.29457 9.29457 0 20.76 0ZM20.76 3C10.9514 3 3 10.9514 3 20.76C3 30.5686 10.9514 38.52 20.76 38.52H38.52V20.76C38.52 10.9514 30.5686 3 20.76 3ZM24 13.5V16.4786H19.6007V28.5H16.3993V16.4786H12V13.5H24ZM28.5789 15V17.9093H31.0461V20.4392H28.5592V24.3604C28.5592 25.4567 29.1316 25.7519 29.6645 25.7519C30 25.7519 30.5329 25.6675 30.8487 25.4989L31.5 28.0287C30.7895 28.3239 30.2171 28.4504 29.4474 28.4925C27.3355 28.5979 25.7368 27.6071 25.7368 24.3604V20.4392H24V17.9093H25.7368V15.3373L28.5789 15Z"
            fill={color}
        />
    </svg>
)
