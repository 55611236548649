import styled from 'styled-components'

const getSmallAvatar = (avatar) => {
    if (!avatar) return ''

    const ext = avatar.split('.').pop().toLowerCase()
    return avatar.replace(`.${ext}`, `/80h.${ext}`)
}

const Avatar = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    height: 36px;
    margin-right: 10px;
    width: 36px;
    background-image: url(${(props) => getSmallAvatar(props.avatar)});
`

export default Avatar
