exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!../app.css"), undefined);

// module
exports.push([module.id, "._2T9Y48EMwSh3hc0XOcE6uM {\n    padding: 30px 0;\n}\n\n._2W7zBPyLawC16IjDCmIVwO {\n    margin: 0 auto 30px;\n    max-width: 250px;\n}\n\n.w64WvKGwmao4PCeqPYz2x {\n    height: 180px;\n    margin: 0 auto 10px;\n    width: 320px;\n}\n\n.w64WvKGwmao4PCeqPYz2x iframe {\n    height: 100% !important;\n    width: 100% !important;\n}", ""]);

// exports
exports.locals = {
	"app": "\"../app.css\"",
	"appTitle": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!../app.css").locals["appTitle"] + "",
	"appVideo": "_2T9Y48EMwSh3hc0XOcE6uM",
	"appVideoTitle": "_2W7zBPyLawC16IjDCmIVwO " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!../app.css").locals["appTitle"] + "",
	"appVideoEmbed": "w64WvKGwmao4PCeqPYz2x"
};