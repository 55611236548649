import { useSelect } from 'react-select-search/dist/cjs'

const SelectSearch = ({ options, defaultValue, onChange }) =>
    useSelect({
        options: options,
        value: defaultValue,
        onChange: onChange,
        multiple: false,
        disabled: false,
        search: true,
        fuse: {
            keys: ['name', 'groupName'],
            threshold: 0.3,
        },
        autoComplete: 'off',
    })

export default SelectSearch
