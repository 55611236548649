import { giphyBlue, giphyIndigo } from '@giphy/colors'
import Pikaday from 'pikaday'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { ThemeProvider } from 'styled-components'
import TimeSelector from './time-selector.js'
import Wrapper from './wrapper'
export default class DatePicker extends PureComponent {
    static propTypes = {
        color: PropTypes.string,
        altColor: PropTypes.string,
        minDate: PropTypes.object,
        maxDate: PropTypes.object,
        defaultValue: PropTypes.object,
        defaultRange: PropTypes.array,
        allowMultiple: PropTypes.bool,
        hasTimeSelect: PropTypes.bool,
        disableDayFn: PropTypes.func,
        onUpdate: PropTypes.func,
    }

    static defaultProps = {
        color: giphyBlue,
        altColor: giphyIndigo,
        allowMultiple: true,
    }

    state = {
        startDate: null,
        endDate: null,
    }

    picker = null
    time = { hours: 0, minutes: 0 }
    isShift = false
    onKeyDown = this.keyDown.bind(this)
    onKeyUp = this.keyUp.bind(this)
    componentDidMount() {
        const { minDate, maxDate, defaultValue, defaultRange, allowMultiple, disableDayFn } = this.props
        if (defaultValue) {
            this.time = {
                hours: defaultValue.getHours(),
                minutes: defaultValue.getMinutes(),
            }
            this.setState({ startDate: defaultValue })
        }

        this.picker = new Pikaday({
            minDate,
            maxDate,
            defaultDate: defaultValue,
            setDefaultDate: !!defaultValue,
            showDaysInNextAndPreviousMonths: false,
            enableSelectionDaysInNextAndPreviousMonths: true,
            disableDayFn,
            keyboardInput: false,
            i18n: {
                previousMonth: '<i class="ss-navigateleft"></i>',
                nextMonth: '<i class="ss-navigateright"></i>',
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            },
            onSelect: (date) => this.onDatePick(date),
        })

        if (allowMultiple) {
            window.addEventListener('keydown', this.onKeyDown, false)
            window.addEventListener('keyup', this.onKeyUp, false)

            if (defaultRange && defaultRange.length > 1) {
                this.picker.setStartRange(defaultRange[0])
                this.picker.setEndRange(defaultRange[1])
                this.picker.gotoDate(defaultRange[1])
            }
        }

        this.el.appendChild(this.picker.el)
    }

    componentWillUnmount() {
        this.picker && this.picker.destroy()
        if (this.props.allowMultiple) {
            window.removeEventListener('keydown', this.onKeyDown, false)
            window.removeEventListener('keyup', this.onKeyUp, false)
        }
    }

    updateTime = ({ hours = 0, minutes = 0, isNowClick = false }) => {
        const { startDate, endDate } = this.state
        const targetDate = endDate || startDate
        const date = new Date(isNowClick || !targetDate ? Date.now() : targetDate.getTime())
        isNowClick && this.picker.setDate(date)
        this.time = { hours, minutes }
        this.onDatePick(date)
    }

    onDatePick(date) {
        const { allowMultiple, onUpdate } = this.props
        const { startDate, endDate } = this.state
        const { hours, minutes } = this.time
        let newEndDate = endDate
        let newStartDate = startDate

        if (allowMultiple) {
            if (startDate && !endDate) {
                const isEarlier = date < startDate
                newEndDate = date
                this.picker.setStartRange(isEarlier ? date : startDate)
                this.picker.setEndRange(isEarlier ? startDate : date)
                this.picker.gotoDate(date)
            } else {
                this.picker.setStartRange(null)
                this.picker.setEndRange(null)
                this.picker.gotoDate(date)
                newStartDate = date
                newEndDate = null
            }
        } else {
            this.picker.setStartRange(null)
            this.picker.setEndRange(null)
            this.picker.gotoDate(date)
            newStartDate = date
            newEndDate = null
        }

        // update time
        const targetDate = newEndDate || newStartDate
        if (targetDate) {
            targetDate.setHours(hours)
            targetDate.setMinutes(minutes)
            targetDate.setSeconds(0)
        }
        this.setState({
            startDate: newStartDate,
            endDate: newEndDate,
        })

        onUpdate && onUpdate(newStartDate, allowMultiple ? newEndDate : null)
    }

    keyDown({ which }) {
        this.isShift = which === 16
    }

    keyUp() {
        this.isShift = false
    }

    render() {
        const { color, altColor, margin, hasTimeSelect } = this.props
        const { startDate, endDate } = this.state
        return (
            <ThemeProvider theme={{ color, margin, altColor }}>
                <Wrapper>
                    <div ref={(c) => (this.el = c)} />
                    {hasTimeSelect && <TimeSelector date={endDate || startDate} onChange={this.updateTime} />}
                </Wrapper>
            </ThemeProvider>
        )
    }
}
