import styled from 'styled-components'
import { giphyLightGrey } from '../../../css/colors'
import { Subheader } from '../../typography'

const SectionLabel = styled(Subheader)`
    color: ${giphyLightGrey};
    margin-bottom: 10px;
    text-transform: capitalize;
`

export default SectionLabel
