import { PureComponent } from 'react'
import styled from 'styled-components'
import { pull } from 'lodash'
import TooltipView from './tooltip'
import onHideCallbacks from './hide-tooltip-callbacks'

const MAX_WIDTH = 200

const Container = styled.div`
    position: relative;
`
const TooltipContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`
class Tooltip extends PureComponent {
    state = {
        showToolTip: false,
        align: 'center',
    }

    static defaultProps = {
        label: '',
        themeId: 'light',
        delay: 0,
        showOnMount: false,
        maxWidth: MAX_WIDTH,
        bubbleOffset: 20, // offset from bubble to arrow when not centered
        offsetY: 3, // for tweaking arrow (nip) position Y
        offsetX: 0, // for tweaking arrow (nip) position X
    }

    static hideToolTips = () => onHideCallbacks.forEach((cb) => cb())
    componentDidMount() {
        const { showOnMount } = this.props
        if (showOnMount) {
            const { left, width } = this.container.getBoundingClientRect()
            this.show({ clientX: left + width / 2 })
        }
    }

    componentWillUnmount() {
        this.unmounted = true
        pull(onHideCallbacks, this.hide)
    }

    show = ({ clientX, touches = [] }) => {
        Tooltip.hideToolTips()
        pull(onHideCallbacks, this.hide)
        onHideCallbacks.push(this.hide)
        // use prop
        const { align, label, maxWidth } = this.props
        const isSingleWord = String(label).split(' ').length === 1
        if (isSingleWord) {
            // force align center single word
            this.setState({ showToolTip: true, align: 'center' })
        } else {
            if (!align) {
                let align = 'center'
                // window size
                const middle = innerWidth / 2
                const padding = 10
                const maxHalfBubble = maxWidth / 2 + padding
                // sometimes clientX is undefined on mobile
                if (!clientX && touches.length) {
                    clientX = touches[0].clientX
                }
                if (clientX < middle && clientX < maxHalfBubble) {
                    align = 'right'
                } else if (clientX > middle && innerWidth - clientX < maxHalfBubble) {
                    align = 'left'
                }
                this.setState({ showToolTip: true, align })
            } else {
                this.setState({ showToolTip: true, align })
            }
        }
    }

    handleTouch = (e) => {
        this.show(e)
        // add a touch handler for the document
        // so when a user touches anything else
        // we can hide
        const onDocumentTouch = ({ target: currentNode }) => {
            while (currentNode.parentNode) {
                if (currentNode === this.container) return
                currentNode = currentNode.parentNode
            }
            if (currentNode !== document) return
            this.hide()
            document.removeEventListener('touchstart', onDocumentTouch)
        }
        document.addEventListener('touchstart', onDocumentTouch)
    }

    hide = () => {
        pull(onHideCallbacks, this.hide)
        if (this.unmounted) {
            return
        }
        this.setState({ showToolTip: false })
    }

    render() {
        const { showToolTip, align } = this.state
        const isExtendUp = true // TODO extend down
        const { label, themeId, delay, children, className, maxWidth, bubbleOffset, offsetY, offsetX } = this.props
        return (
            <Container
                className={className}
                onMouseEnter={this.show}
                onMouseLeave={this.hide}
                onTouchStart={this.handleTouch}
                ref={(c) => (this.container = c)}
            >
                {showToolTip && label && (
                    <TooltipContainer>
                        <TooltipView
                            {...{
                                align,
                                themeId,
                                delay,
                                label,
                                isExtendUp,
                                maxWidth,
                                bubbleOffset,
                                offsetY,
                                offsetX,
                            }}
                            isVisible
                        />
                    </TooltipContainer>
                )}
                {children}
            </Container>
        )
    }
}

export default Tooltip
