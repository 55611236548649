import { giphyWhite } from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import styled, { css } from 'styled-components'
import { CheckmarkCircleIcon } from '../../../icons/utility'
import Avatar from '../../avatar'

export type Access = {
    channel: {
        avatar_url: string
        user_id: number
        display_name: string
        id: number
        slug: string
        url: string
    }
    create_datetime: string
    dashboard_url: string
    id: number
    last_login_datetime: string
    login_url: string
    permission_level: number
    update_datetime: string
}

export type AccessUser = {
    avatar: string
    avatar_url: string
    display_name: ''
    id: number
    is_verified: boolean
    profile_url: string
    username: string
    token: string
}

export type ChannelAccess = {
    access: Access[]
    emulated_user?: AccessUser | null
    user: AccessUser
}
export type AccessProps = {
    user: IUser & {
        channel_access?: {
            access: Access[]
            emulated_user?: IUser
            user?: IUser
        }
    }
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`
const Item = styled.a`
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: center;
`
const Text = styled.div<{ $isActive?: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    ${(props) =>
        props.$isActive &&
        css`
            color: ${giphyWhite};
        `}
`

const ListItem = ({ user, href, isActive }: { user: Partial<IUser>; href: string; isActive?: boolean }) => (
    <Item href={href}>
        <Avatar user={user} width={20} height={20} />
        <Text $isActive={isActive}>{user.display_name}</Text>
        {isActive && <CheckmarkCircleIcon width={20} height={20} />}
    </Item>
)

const ChannelAccessMenu = ({ user }: AccessProps) => {
    const { channel_access } = user
    const firstUser = channel_access?.user || user
    return channel_access?.access?.length ? (
        <Container>
            <hr />
            <ListItem
                user={firstUser}
                key={firstUser.id}
                href={`/api/v4/channels/access/logout`}
                isActive={channel_access.user?.id === channel_access.emulated_user?.id}
            />
            {channel_access?.access
                .filter((access) => {
                    return access.channel.id !== channel_access.emulated_user?.id
                })
                .map((access) => {
                    return (
                        <ListItem
                            key={access.id}
                            user={access.channel}
                            href={access.login_url}
                            isActive={channel_access.emulated_user?.id === access.channel.user_id}
                        />
                    )
                })}
        </Container>
    ) : null
}

export default ChannelAccessMenu
