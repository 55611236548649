import * as colors from '@giphy/colors'
import { giphyLightGrey } from '@giphy/colors'
import IconTooltip from 'shared/components/ui/icon-tooltip'
import styled, { css } from 'styled-components'

type Props = {
    isVertical: boolean
}

const WhatsThisContainer = styled.div<{ isVertical: boolean }>`
    display: inline-block;
    right: 0;
    position: absolute;
    color: ${colors.giphyLightGrey};
    padding-top: 3px;

    ${(props) =>
        props.isVertical &&
        css`
            top: 6px;
            font-weight: normal;
        `}
`

const ToolTip = styled.span`
    font-size: 12px;
    position: relative;
    right: 5px;
`

const ToolTipInnerds = styled.div`
    font-weight: normal;
    text-align: center;
    line-height: normal;
    width: 200px;

    p:last-child {
        margin: 0px;
    }
`

const LearnMore = styled.p`
    text-align: center;

    a {
        color: ${colors.giphyIndigo};
        font-weight: bold;
    }
`

const Help = styled.span`
    display: inline-block;
`

const WhatsThis = ({ isVertical }: Props) => (
    <WhatsThisContainer isVertical={isVertical}>
        <Help>What&apos;s This</Help>
        <ToolTip>
            <IconTooltip icon={'ss-help'} color={giphyLightGrey}>
                <ToolTipInnerds>
                    <p>Suggested tags use machine learning to predict keywords related to your content.</p>
                    {
                        <LearnMore>
                            <a
                                href="https://support.giphy.com/hc/en-us/articles/360047849132"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Learn More
                            </a>
                        </LearnMore>
                    }
                </ToolTipInnerds>
            </IconTooltip>
        </ToolTip>
    </WhatsThisContainer>
)

export default WhatsThis
