import styled, { css } from 'styled-components'
import { ControlSize } from 'types/src/controls'
import { borderRadiusFromControlSize, fontSizeFromControlSize, heightFromControlSize } from 'utils/src/control-size'
import { textColorFromBackgroundColor } from '../../../utils/color'

export const Container = styled.div<{
    $color: string
    $size: ControlSize
    $textColor?: string
}>`
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: center;

    ${({ $color, $size, $textColor }) => css`
        background: ${$color};
        border-radius: ${borderRadiusFromControlSize($size)}px;
        color: ${$textColor || textColorFromBackgroundColor($color)};
        font-size: ${fontSizeFromControlSize($size)}px;
        height: ${heightFromControlSize($size)}px;
        width: ${heightFromControlSize($size)}px;
    `}

    > svg path {
        fill: currentColor;
    }
`
