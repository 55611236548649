exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css"), undefined);
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, "._3iT_S1Bu6kxI1LHLf1zf3r {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    max-width: 100%;\n}\n\n._4P63NI68ltZ-kREBhiXwl {\n    transition: color 0.25s ease;\n    display: inline-block;\n    margin-left: -1px;\n    margin-right: 4px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n._4P63NI68ltZ-kREBhiXwl:hover {\n        color: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n    }\n", ""]);

// exports
exports.locals = {
	"typography": "'shared/css/typography.css'",
	"usernameTitle": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["usernameTitle"] + "",
	"colors": "'shared/css/colors.css'",
	"giphyWhite": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + "",
	"usernameWrapper": "_3iT_S1Bu6kxI1LHLf1zf3r",
	"username": "_4P63NI68ltZ-kREBhiXwl " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["usernameTitle"] + ""
};