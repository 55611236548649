import { useEffect, useState } from 'react'
import { Coordinates, Dimensions } from 'types'

const MAX_OFFSET = 30

export function useDropdownOffset(
    coordinates: Coordinates | null,
    dimensions: Dimensions | null,
    defaultOffset: number = 0
) {
    const [state, setState] = useState<Partial<Coordinates>>({ x: defaultOffset })

    useEffect(() => {
        if (!coordinates || !dimensions) {
            return
        }

        const bounds = document.body.getBoundingClientRect()
        const { x } = coordinates
        const { width } = dimensions
        const midX = width / 2
        const left = x - midX + defaultOffset
        const right = left + width

        if (left < bounds.left) {
            setState({
                x: Math.min(midX - MAX_OFFSET, bounds.left - left + defaultOffset),
            })
        } else if (right > bounds.right) {
            setState({
                x: Math.max(-midX + MAX_OFFSET, bounds.right - right + defaultOffset),
            })
        } else {
            setState({ x: Math.max(-midX + MAX_OFFSET, defaultOffset) })
        }
    }, [coordinates, defaultOffset, dimensions])

    return state
}
