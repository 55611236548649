import { getResults } from '../../../api'
import { DELETE, POST, GET } from '../../../util/fetch-methods'

export const cancelEmailChangeValidation = () => {
    return getResults('/api/v1/users/verify/email/', DELETE())
}

export const resendEmailChangeValidation = () => {
    return getResults('/api/v1/users/verify/email/', POST())
}

export const validateLegacy = (source: number) => {
    /*
    `source` values:
        1 = settings page
        2 = jail
    */
    let params = {
        source,
        platform: 'web',
    }
    const requestInit = POST(JSON.stringify(params))
    requestInit.headers!['Content-Type'] = 'application/json'
    return getResults('/api/v3/users/legacy/email', requestInit as RequestInit)
}

export const validateLegacyEmailCode = (code: string) => {
    let params = {
        code,
    }
    return getResults('/api/v3/users/legacy/email/confirm/', POST(params, true))
}

export const validateLegacyStatus = () => {
    return getResults('/api/v3/users/legacy/email/status?platform=web&source=2', GET)
}
