import { FIELDS } from 'shared/components/form/fields'
import { map } from 'lodash'
const create = (placeholderBelow, key) => [key, { placeholderBelow }]
const BelowPlaceholders = {
    artist: map(
        {
            [FIELDS.email]: '',
            [FIELDS.organization]: 'Confirm the name you want displayed on your GIPHY Creator Channel.',
            [FIELDS.location]: 'Helps us best serve you.',
            [FIELDS.displayName]: 'Confirm the name you want displayed on your GIPHY Creator Channel.',
            [FIELDS.username]:
                "Numbers, special characters, swear words, and the words 'GIFs' or 'GIPHY' are discouraged.",

            [FIELDS.primarySiteText]:
                'Enter the full, functioning URL of an online site that best represents your work.',

            [FIELDS.socialURL]:
                'Enter the full URL of the social media account associated with your artwork. (ex: http://instagram.com/giphy).',
        },
        create
    ),
    brand: map(
        {
            [FIELDS.email]: 'Must be a company branded domain name.',
            [FIELDS.organization]: 'Does your brand belong to a larger company?',
            [FIELDS.location]: 'Helps us best serve you.',
            [FIELDS.displayName]: 'Confirm the name you want displayed on your GIPHY Brand Channel.',
            [FIELDS.username]:
                "Your GIPHY username must accurately reflect the name of your business. Numbers, special characters, swear words, and the words 'GIFs' or 'GIPHY' are discouraged.",

            [FIELDS.primarySiteText]:
                "Brand applications require a working link to the business' custom web domain in this field. (ex: ours would be https://giphy.com). For Brands, social accounts do not apply here.",

            [FIELDS.socialURL]:
                'Enter the full URL of the social media account associated with your artwork. (ex: http://instagram.com/giphy).',
        },
        create
    ),
}

export default BelowPlaceholders
