import { IGif } from '@giphy/js-types'
import { checkIfWebP, getGifHeight } from '@giphy/js-util'
import { isEmpty } from 'lodash'
import { Children, cloneElement, PureComponent, ReactElement, ReactNode, SyntheticEvent } from 'react'
import { giphyBlack, giphyBlue, giphyGreen, giphyPurple, giphyRed, giphyYellow } from '../../css/colors'
import Clock from './clock'
import Media from './media'
import PrivateLock from './private'
import RemovedIcon from './removed'
import { AdPill, GifLink, GifWrapper, Tag, Tags } from './sc'

export const GRID_COLORS = [giphyBlue, giphyGreen, giphyPurple, giphyRed, giphyYellow]
export const getColor = () => GRID_COLORS[Math.round(Math.random() * (GRID_COLORS.length - 1))]

const noop = () => {}

let hasCheckedWebP = false

type Props = {
    gif: IGif
    useVideo: boolean
    backgroundColor: string
    rendition: string
    width: number
    height: number
    autoPlay: boolean
    url: string
    children?: ReactNode
    showTags: boolean
    hideLock: boolean
    onHover: Function
    onSeen: Function
    onVisible: Function
    onClick: (event: SyntheticEvent<HTMLElement, Event>, gif: IGif) => void
    onRightClick: (event: SyntheticEvent<HTMLElement, Event>, gif: IGif) => void
    onTagClick: (event: SyntheticEvent<HTMLElement, Event>, tag: string) => void
    onLoop?: () => void
    onEnded?: () => void
    loop: boolean
    hideAdIndicator?: boolean
}

type State = {
    isSeen: boolean
    backgroundColor: string
    isAnimating: boolean
    isHovered: boolean
}
export default class Gif extends PureComponent<Props, State> {
    state: State = {
        isHovered: false,
        isSeen: false,
        isAnimating: false,
        backgroundColor: '',
    }

    static defaultProps = {
        autoPlay: true,
        showTags: true,
        width: 200,
        onHover: noop,
        onSeen: noop,
        onVisible: noop,
        onClick: noop,
        onRightClick: noop,
        onTagClick: noop,
        useVideo: false,
        loop: true,
        hideAdIndicator: false,
    }

    unmounted = false
    constructor(props: Props) {
        super(props)
        this.checkForWebP()
    }

    async checkForWebP() {
        if (!hasCheckedWebP) {
            // this will never fire if we fetch gifs/stories
            // does fire when in brand repo
            await checkIfWebP
            hasCheckedWebP = true
            if (!this.unmounted) {
                // rerender, include the media component
                this.forceUpdate()
            }
        }
    }

    static getDerivedStateFromProps({ autoPlay, gif, backgroundColor }: Props, prevState: State) {
        const res: any = {}
        if (autoPlay !== prevState.isAnimating) {
            res.isAnimating = autoPlay
        }
        const newBackgroundColor =
            backgroundColor || (gif.is_sticker ? giphyBlack : prevState.backgroundColor || getColor())
        if (newBackgroundColor !== prevState.backgroundColor) {
            res.backgroundColor = newBackgroundColor
        }

        return isEmpty(res) ? null : res
    }

    hoverDelay
    componentWillUnmount() {
        this.unmounted = true
        clearTimeout(this.hoverDelay)
    }

    onMouseOver() {
        const { gif, onHover } = this.props
        clearTimeout(this.hoverDelay)
        this.setState({ isHovered: true })
        this.hoverDelay = setTimeout(() => {
            onHover && onHover(gif)
            this.setState({ isAnimating: true })
        }, 200)
    }

    onMouseOut() {
        clearTimeout(this.hoverDelay)
        this.setState({ isHovered: false, isAnimating: this.props.autoPlay })
    }

    onImageLoad = (e) => {
        const { gif, onSeen, onVisible } = this.props
        const { isSeen } = this.state
        // onSeen is called only once per GIF and indicates that
        // the image was loaded. onVisible will be called every time
        // the image loads regardless of if its been loaded before
        // or not.
        if (!isSeen) {
            onSeen(gif)
            this.setState({ isSeen: true })
        }
        onVisible(gif, e.target)
    }

    onClick = (e: SyntheticEvent<HTMLElement, Event>) => {
        const { gif, onClick } = this.props
        onClick(e, gif)
    }

    onRightClick = (e: SyntheticEvent<HTMLElement, Event>) => {
        const { gif, onRightClick } = this.props
        onRightClick(e, gif)
    }

    render() {
        const {
            gif,
            rendition,
            width,
            height,
            url,
            showTags,
            children,
            onTagClick,
            hideLock,
            useVideo,
            onLoop,
            loop,
            onEnded,
        } = this.props
        const { backgroundColor, isHovered, isAnimating, isSeen } = this.state
        const {
            tags,
            is_sticker: isSticker,
            is_hidden: isPrivate,
            is_removed: isRemoved,
            is_scheduled: isScheduled,
        } = gif
        const gifHeight = height || getGifHeight(gif, width)
        const renderAdPill = false
        // we should use context here,
        // but that would require updating each child component that has depended
        // on these props
        // however, if the child is a div, these props below will throw a warning,
        // so we should switch
        const childProps = {
            gif,
            backgroundColor,
            width,
            height: gifHeight,
            isAnimating,
        }
        return (
            <GifWrapper
                isSticker={isSticker}
                onMouseOut={() => this.onMouseOut()}
                onMouseOver={() => this.onMouseOver()}
                style={{
                    width,
                    height: gifHeight,
                    backgroundColor,
                }}
            >
                <GifLink href={url || gif.url} onClick={this.onClick} onContextMenu={this.onRightClick}>
                    {hasCheckedWebP && (
                        <Media
                            gif={gif}
                            height={gifHeight}
                            isAnimating={isAnimating}
                            loop={loop}
                            onEnded={onEnded}
                            onLoad={this.onImageLoad}
                            onLoop={onLoop}
                            rendition={rendition}
                            type={isSticker ? 'sticker' : useVideo ? 'video' : 'gif'}
                            width={width}
                        />
                    )}
                    {!hideLock && isPrivate ? <PrivateLock /> : null}
                    {isScheduled && <Clock />}
                    {isRemoved ? <RemovedIcon /> : null}
                </GifLink>
                {Children.map(children, (child: ReactElement<any>) => (child ? cloneElement(child, childProps) : null))}
                {renderAdPill && <AdPill isVisible={!isHovered && isSeen} />}
                {showTags && tags && (
                    <Tags isVisible={isHovered}>
                        {tags.map((tag) => (
                            <Tag
                                href={`/explore/${tag.replace(/ /g, '-')}`}
                                key={tag}
                                onClick={(e) => onTagClick(e, tag)}
                            >
                                {tag}
                            </Tag>
                        ))}
                    </Tags>
                )}
            </GifWrapper>
        )
    }
}
