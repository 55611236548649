import { FPS } from '../constants'
import { CreationToolFrame } from '../types'

const drawToCanvas = (video: HTMLVideoElement, maxWidth?: number): HTMLCanvasElement => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d', { alpha: false })
    const aspectRatio = video.videoWidth / video.videoHeight
    canvas.width = maxWidth ? Math.min(video.videoWidth, maxWidth) : video.videoWidth
    canvas.height = Math.round(canvas.width / aspectRatio)
    context?.drawImage(video, 0, 0, canvas.width, canvas.height)
    return canvas
}

const getVideoFrames = (
    source: File | string,
    length: number = 0,
    fps: number = FPS,
    maxWidth?: number
): Promise<CreationToolFrame[]> =>
    new Promise((resolve, reject) => {
        const video = document.createElement('video')
        const frames: CreationToolFrame[] = []
        let totalFrames = length
        let currentFrame = 0

        video.onseeked = () => {
            video.pause()
            frames.push({ canvas: drawToCanvas(video, maxWidth) })
            currentFrame++

            if (currentFrame < totalFrames) {
                video.currentTime = (currentFrame / totalFrames) * video.duration
            } else {
                video.onseeked = null
                if (typeof source !== 'string') {
                    URL.revokeObjectURL(video.src)
                }
                resolve(frames)
            }
        }

        video.oncanplaythrough = () => {
            if (!video.videoWidth || !video.videoHeight) {
                reject(`Invalid dimensions`)
            } else {
                totalFrames = totalFrames || Math.floor(video.duration * fps)
                video.oncanplaythrough = null
                video.currentTime = 0.01
            }
        }

        video.onerror = (err) => {
            reject(err)
        }

        video.preload = 'auto'
        video.src = typeof source === 'string' ? source : URL.createObjectURL(source)
    })

export default getVideoFrames
