import styled, { keyframes } from 'styled-components'

const DELAY = 5000

const fadeOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`

const NewBase = styled.div`
    animation: ${fadeOut} 0.3s ease-out 1 reverse, ${fadeOut} 0.5s ease-out ${DELAY}ms 1 normal backwards;
    opacity: 0;
    pointer-events: none;
`

export const Background = styled(NewBase)`
    background: linear-gradient(to right, ${(props) => props.secondaryColor}, ${(props) => props.primaryColor});
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
`

export const Peeker = styled(NewBase)`
    background: no-repeat url('https://media.giphy.com/media/l41K1gfrUz1HDohvG/giphy.gif') left top;
    background-size: contain;
    height: 70px;
    left: -15px;
    position: absolute;
    top: -18px;
    width: 70px;
`
