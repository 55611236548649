import { giphyDarkestGrey, giphyLightGrey } from '@giphy/colors'
import { privacyTermsIndex } from 'shared/css/z-indexes'
import styled from 'styled-components'

const FloatingLinks = () => {
    return (
        <Container>
            <SingleLink href="/privacy">Privacy</SingleLink>
            <SingleLink href="/terms">Terms</SingleLink>
        </Container>
    )
}

const Container = styled.div`
    display: none;
    @media (min-width: 1348px) {
        display: flex;
        position: fixed;
        bottom: 10px;
        left: 10px;
        z-index: ${privacyTermsIndex};
    }
`

const SingleLink = styled.a`
    color: ${giphyLightGrey};
    font-size: 14px;
    margin-right: 10px;
    border-radius: 5px;
    background: ${giphyDarkestGrey};
    padding: 7px 14px;
    &:hover {
        color: white;
    }
`

export default FloatingLinks
