import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Dropdown from '../dropdown'

const Container = styled.div`
    box-sizing: border-box;
    font-weight: normal;
    padding-right: 10px;
    position: relative;
    width: 20px;
    height: 20px;
`

const Ellipsis = styled.span`
    cursor: ${(props) => (props.isHover ? 'default' : 'pointer')};
    display: inline-block;
    font-size: 16px;
    padding-top: 10px;
    transform: rotate(-90deg);
`
const DropdownContainer = styled.div`
    position: absolute;
    bottom: ${(props) => (props.extendUp ? '-3px' : '-163px')};
    right: -31px;
`

const DropdownOverlay = styled.div`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483645;
`

const DropdownHoverArea = styled.div`
    padding: 25px;
`

export default class EditableTextfield extends PureComponent {
    static propTypes = {
        isHorizontal: PropTypes.bool,
        isDark: PropTypes.bool,
        isHover: PropTypes.bool,
        forceExpandDirection: PropTypes.bool,
    }

    static defaultProps = {
        isHorizontal: false,
        isDark: false,
        isHover: true,
    }

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            extendUp: false,
        }
    }

    popUpOrDown(e) {
        return e.pageY > window.innerHeight / 2
    }

    handleHover(e) {
        if (this.popUpOrDown(e)) {
            this.setState({ isOpen: true, extendUp: true })
        } else {
            this.setState({ isOpen: true, extendUp: false })
        }
    }

    handleClick(e) {
        const { isOpen } = this.state
        if (this.popUpOrDown(e)) {
            this.setState({ isOpen: !isOpen, extendUp: true })
        } else {
            this.setState({ isOpen: !isOpen, extendUp: false })
        }
    }

    render() {
        const { children, isDark, isHover, extendDirectionUp } = this.props
        const { isOpen, extendUp } = this.state
        const extendDirection = typeof extendDirectionUp !== 'undefined' ? extendDirectionUp : extendUp
        return isHover ? (
            <Container onMouseEnter={(e) => this.handleHover(e)} onMouseLeave={() => this.setState({ isOpen: false })}>
                <Ellipsis className={'ss-ellipsis'} />
                {isOpen && (
                    <DropdownContainer extendUp={extendDirection}>
                        <DropdownHoverArea>
                            <Dropdown isDark={isDark} isExtendUp={extendDirection} nipX={'calc(100% - 15px)'}>
                                {children}
                            </Dropdown>
                        </DropdownHoverArea>
                    </DropdownContainer>
                )}
            </Container>
        ) : (
            <Container>
                <Ellipsis className={'ss-ellipsis'} onClick={(e) => this.handleClick(e)} />
                {isOpen && (
                    <DropdownContainer extendUp={extendDirection}>
                        <DropdownOverlay onClick={() => this.setState({ isOpen: false })} />
                        <Dropdown isDark={isDark} isExtendUp={extendDirection} nipX={'calc(100% - 15px)'}>
                            {children}
                        </Dropdown>
                    </DropdownContainer>
                )}
            </Container>
        )
    }
}
