import * as colors from '@giphy/colors'
import IconTooltip from 'shared/components/ui/icon-tooltip'
import styled, { css } from 'styled-components'

const TaggingTipsContainer = styled.span`
    font-size: 14px;
    color: ${colors.giphyLightGrey};
    display: inline-block;
    margin-left: auto;
    flex-shrink: 0;
    margin-right: -10px;
`

const ToolTip = styled.span`
    font-size: 14px;
    position: relative;
    color: ${colors.giphyLightGrey};
`

const LearnMore = styled.p`
    text-align: center;
    margin-bottom: 0px !important;

    a {
        color: ${colors.giphyIndigo};
        font-weight: bold;
    }
`

const TipsList = styled.ol`
    list-style: decimal;
    padding: 20px;
    column-count: 2;
`

const TipsLabel = styled.p`
    font-weight: bold;
    text-align: center;
    color: ${colors.giphyDarkCharcoal};
`

const ToolTipInnerds = styled.div<{ isVertical: boolean }>`
    line-height: normal;
    font-weight: normal;
    width: 500px;
    ${(props) =>
        props.isVertical &&
        css`
            width: 260px;
            ${TipsList} {
                column-count: 1;
            }
        `}
    p:first-child {
        margin: 0px;
    }
`

type Props = {
    isVertical: boolean
}

const TaggingTips = ({ isVertical }: Props) => (
    <TaggingTipsContainer>
        Tagging Tips
        <ToolTip>
            <IconTooltip icon={'ss-help'} color={colors.giphyLightGrey}>
                <ToolTipInnerds isVertical={isVertical}>
                    <TipsLabel>Tips For Better Tagging</TipsLabel>
                    <TipsList>
                        <li>Do not include the hashtag</li>
                        <li>Use spaces between words</li>
                        <li>Keep the tags relevant</li>
                        <li>Tag the emotions &#38; reactions</li>
                    </TipsList>
                    <LearnMore>
                        <a
                            href="https://support.giphy.com/hc/en-us/articles/360026405951-Tagging-Best-Practices"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn More
                        </a>
                    </LearnMore>
                </ToolTipInnerds>
            </IconTooltip>
        </ToolTip>
    </TaggingTipsContainer>
)

export default TaggingTips
