import styled, { css } from 'styled-components'
import { textColorFromBackgroundColor } from '../../../../utils/color'
import IconButton from '../../icon-button'

export const Header = styled.div<{ $color: string; $textColor?: string }>`
    align-items: stretch;
    display: flex;

    ${({ $color, $textColor }) => {
        const color = $textColor || textColorFromBackgroundColor($color)

        return css`
            background: ${$color};
            color: ${color};
        `
    }}
`

export const Button = styled(IconButton)<{ $open: boolean }>`
    flex-shrink: 0;
    height: auto;

    svg {
        transform: rotate(${({ $open }) => ($open ? 0 : 180)}deg);
        transition: transform 0.15s ease-out;
    }
`

export const Label = styled.div`
    align-items: center;
    display: flex;
    margin: auto 0;
    width: 100%;

    > div:first-child {
        flex: 1;
    }

    > svg {
        margin-right: 0.6em;
    }
`

export const LabelText = styled.div`
    align-self: center;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 0.8em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`
