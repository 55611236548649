import styled, { css } from 'styled-components'
import { textColorFromBackgroundColor } from '../../../../utils/color'
import { TooltipDirection } from '../types'

export const ARROW_SIZE = 12
export const ARROW_MID = ARROW_SIZE / 2

export const Arrow = styled.div`
    height: 0;
    overflow: visible;
    position: absolute;
    transform: rotate(45deg);
    width: 0;

    &:before {
        background: inherit;
        content: '';
        display: block;
        height: ${ARROW_SIZE}px;
        margin: -${ARROW_MID}px 0 0 -${ARROW_MID}px;
        width: ${ARROW_SIZE}px;
    }
`

export const Wrapper = styled.div`
    border-radius: 4px;
    box-sizing: border-box;
    color: inherit;
    overflow: hidden;
    position: relative;

    > img,
    > video {
        display: block;
        margin-bottom: 0.5em;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > p,
    > span {
        text-align: inherit;
    }

    > ol,
    > p,
    > span,
    > ul {
        display: block;
        margin: 0;
        padding: 0.5em 1.5em;

        &:first-child {
            padding-top: 1em;
        }

        &:last-child {
            padding-bottom: 1em;
        }
    }
`

export const Container = styled.div<{
    $arrowColor?: string
    $color: string
    $textAlign: string
    $tooltipDirection: TooltipDirection
    $tooltipWidth?: number
}>`
    box-sizing: content-box;
    color: ${({ $color }) => textColorFromBackgroundColor($color)};
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.7));
    padding: ${ARROW_SIZE}px;
    position: relative;
    text-align: ${({ $textAlign }) => $textAlign};

    ${Wrapper} {
        background: ${({ $color }) => $color};
    }

    ${Arrow} {
        background: ${({ $arrowColor, $color }) => $arrowColor || $color};

        ${({ $tooltipDirection }) => {
            switch ($tooltipDirection) {
                case 'down':
                    return css`
                        left: 50%;
                        top: ${ARROW_SIZE}px;
                    `
                case 'left':
                    return css`
                        right: ${ARROW_SIZE}px;
                        top: 50%;
                    `
                case 'right':
                    return css`
                        left: ${ARROW_SIZE}px;
                        top: 50%;
                    `
                case 'up':
                    return css`
                        bottom: ${ARROW_SIZE}px;
                        left: 50%;
                    `
            }
        }}
    }

    ${({ $tooltipWidth }) =>
        $tooltipWidth
            ? css`
                  width: ${$tooltipWidth}px;
              `
            : css`
                  max-width: 320px;
              `}
`
