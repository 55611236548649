import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Circle, Corner, Rectangle, Square } from './shapes'

const SHAPES = [Rectangle, Square, Circle, Corner]

type IShape = {
    div: HTMLDivElement
    x: number
    y: number
    rotation: number
    drift: number
    speed: number
}

const Animation = styled.div`
    border-radius: inherit;
    height: 100%;
    overflow: hidden;
    width: 100%;
`

const Shape = styled.div`
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.2));
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
`

type Props = {
    className?: string
}

const UploadClipsCTAAnimation = ({ className }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!containerRef.current) return

        const { width, height } = containerRef.current.getBoundingClientRect()
        const offset = (width - height) / 2
        const elements = containerRef.current.childNodes
        const shapes: IShape[] = []
        let animationId = 0

        const loop = () => {
            shapes.forEach((shape) => {
                shape.x += shape.drift
                shape.y -= shape.speed
                shape.rotation += shape.drift * 0.5

                if (shape.y < -offset) {
                    shape.x = width * Math.random()
                    shape.y = height + offset
                    shape.rotation = (Math.random() - Math.random()) * 180
                    shape.drift = (Math.random() - Math.random()) * 0.5
                    shape.speed = 1 + Math.random()
                }

                shape.div.style.transform = `translate3d(${shape.x}px, ${shape.y}px, 0) rotate(${shape.rotation}deg)`
            })

            animationId = requestAnimationFrame(loop)
        }

        elements.forEach((div: HTMLDivElement) => {
            shapes.push({
                div,
                x: width * Math.random(),
                y: -offset + (height + offset * 2) * Math.random(),
                rotation: (Math.random() - Math.random()) * 180,
                drift: Math.random() - Math.random(),
                speed: 1 + Math.random(),
            })
        })

        loop()

        return () => {
            cancelAnimationFrame(animationId)
        }
    }, [containerRef])

    return (
        <Animation className={className} ref={containerRef}>
            {SHAPES.map((Component, i) => (
                <Shape key={`clipsctaanimationshape${i}`}>
                    <Component />
                </Shape>
            ))}
        </Animation>
    )
}

export default UploadClipsCTAAnimation
