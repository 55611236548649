import { useState } from 'react'
import { createPortal } from 'react-dom'
import ReportModal from 'shared/components/flags/report-form'
import styled from 'styled-components'
import Modal from 'ui/src/components/modal'

const FlagContentContainer = styled.div`
    margin-bottom: 3px;
    color: #a6a6a6;
    font-size: 14px;
    font-weight: regular;
    text-transform: none;
    font-family: InterFace;
    &:before {
        display: inline-block;
        font-size: 16px;
        margin-right: 8px;
        vertical-align: top;
    }
    cursor: pointer;
    -webkit-font-smoothing: antialiased;

    &::before {
        color: #d8d8d8;
        padding-right: 2px;
    }
`

const ReportForm = ({ isActive, gif, isSticker = false }: any) => {
    const [active, setActive] = useState(isActive ?? false)

    const toggleForm = () => {
        setActive(!active)
    }

    return (
        <>
            <FlagContentContainer className={'ss-flag'} onClick={() => setActive(true)}>
                Report this {(isSticker && 'Sticker') || 'GIF'}
            </FlagContentContainer>
            {active &&
                createPortal(
                    <Modal>
                        <ReportModal toggleForm={toggleForm} gif={gif} />
                    </Modal>,
                    document.body,
                    `${gif}-report-modal`
                )}
        </>
    )
}

export default ReportForm
