import { giphyDarkestGrey } from '@giphy/colors'
import styled, { keyframes } from 'styled-components'
import { TEAM_GUTTER_WIDTH } from '../constants'
import { IStaffMember } from '../types'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
`

const Member = styled.div`
    background: ${giphyDarkestGrey};
    margin-bottom: ${TEAM_GUTTER_WIDTH}px;

    img {
        animation: ${fadeIn} 0.2s ease-out;
        display: block;
        height: 100%;
        width: 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }
`

type Props = {
    x: number
    members: IStaffMember[]
    size: number
    isVisible: boolean
}

const AboutPageTeamAnimationColumn = ({ x, members, size, isVisible }: Props) => (
    <Container style={{ transform: `translate3d(${x}px, 0, 0)` }}>
        {members.map(({ id, image, name }) => (
            <Member key={id} style={{ height: size, width: size }}>
                {isVisible && <img src={image} alt={name} />}
            </Member>
        ))}
    </Container>
)

export default AboutPageTeamAnimationColumn
