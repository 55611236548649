import { FIELDS } from 'shared/components/form/fields'
import { map } from 'lodash'
const create = (placeholder, key) => [key, { placeholder }]
const Placeholders = {
    artist: map(
        {
            [FIELDS.organization]: '',
            [FIELDS.location]: '',
        },
        create
    ),
    brand: map(
        {
            [FIELDS.organization]: 'Parent Company, Inc.',
            [FIELDS.location]: '',
        },
        create
    ),
}

export default Placeholders
