import { giphyDarkGrey, giphyLightGrey, giphyWhite } from '@giphy/colors'
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import styled from 'styled-components'
import { desktopWidth } from '../../../constants'
import { CloseIcon } from '../../../icons/utility'
import Avatar from '../../avatar'
import { AccessProps } from './channel-access-menu'

const Footer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${giphyDarkGrey};
    display: grid;
    grid-template-columns: 1fr auto 1fr;
`

const FooterInner = styled.div`
    padding: 10px;
    width: ${desktopWidth}px;
    display: flex;
    justify-content: space-between;
    div,
    a {
        color: ${giphyLightGrey};
        display: flex;
        gap: 4px;
        align-items: center;
    }
    a:hover {
        color: ${giphyWhite};
    }
`
const Close = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    justify-self: flex-end;
`

const ChannelAccessFooter = ({ user }: AccessProps) => {
    const { channel_access } = user
    const firstUser = channel_access?.user || user
    const [hasClosedBanner, setHasClosedBanner] = useLocalStorage(`has-closed-access-banner ${user.id}`, false)
    const [documentMounted, setDocumentMounted] = useState<Boolean>(false)

    useEffect(() => {
        setDocumentMounted(true)
    }, [])

    return (
        (documentMounted &&
            channel_access?.emulated_user?.id &&
            !hasClosedBanner &&
            createPortal(
                <Footer>
                    <div></div>
                    <FooterInner>
                        <div>
                            <Avatar user={channel_access.emulated_user} width={16} height={16} />
                            <div>
                                Browsing as <strong>{channel_access.emulated_user.display_name}</strong>
                            </div>
                        </div>
                        <a href={`/api/v4/channels/access/logout`}>
                            Switch back to
                            <Avatar user={firstUser} width={16} height={16} />
                            {firstUser.display_name || firstUser.username}
                        </a>
                    </FooterInner>
                    <Close onClick={() => setHasClosedBanner(true)}>
                        <CloseIcon width={20} height={20} />
                    </Close>
                </Footer>,
                document.body,
                'channel-access-footer'
            )) ||
        null
    )
}
export default ChannelAccessFooter
