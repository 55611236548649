import { IGif } from '@giphy/js-types'
import download from 'downloadjs'
import { useState } from 'react'
import sanitize from 'sanitize-filename'
import { Button } from 'shared/components/buttons'
import { getShareUrl } from 'shared/util/gif-extract'
import styled from 'styled-components'
import { ShareUrlRenditions, ShareUrlRenditionType } from 'utils/src/media/sharing'
import { RenditionTray } from './rendition-tray'

type Props = {
    gif: IGif
}

const DownloadButton = styled(Button)<{ isMP4?: boolean }>`
    width: 352px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
    background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
    font-size: 17px;
    line-height: 42px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    *:first-child {
        // 'ss-download' icon
        height: 36px;
    }
    &:hover {
        filter: brightness(1.1);
    }
    p {
        margin: 0px 0px 0px 10px;
        line-height: 36px;
    }
    ${({ isMP4 }) =>
        isMP4 &&
        `
        background: linear-gradient(45deg, rgb(97, 87, 255) 0%, rgb(0, 204, 255) 100%);
    `}
`

const DownloadPane = ({ gif }: Props) => {
    const [currentRendition, setCurrentRendition] = useState<ShareUrlRenditionType>(ShareUrlRenditions.Social)

    const downloadGif = async (gif: any, rendition: ShareUrlRenditionType, isMP4?: boolean) => {
        const gifData = gif.images[rendition]
        const url = isMP4 ? getShareUrl(gifData['mp4']) : getShareUrl(gifData['url'])
        const imageUrl = url.replace('media.giphy.com', 'i.giphy.com')
        const result = await fetch(imageUrl)
        const blob = await result.blob()
        const extension = isMP4 ? 'mp4' : 'gif'
        // http://danml.com/download.html
        download(blob, sanitize(`${gif.title}-${rendition}.${extension}`))
    }

    return (
        <div>
            <DownloadButton onClick={() => downloadGif(gif, currentRendition)}>
                {' '}
                <div className="ss-download" />
                <p>Download GIF</p>
            </DownloadButton>
            <DownloadButton isMP4 onClick={() => downloadGif(gif, ShareUrlRenditions.MP4, true)}>
                <div className="ss-download" />
                <p>Download MP4</p>
            </DownloadButton>
            <RenditionTray currentRendition={currentRendition} setRenditionType={setCurrentRendition} gif={gif} />
        </div>
    )
}

export default DownloadPane
