import { FC, SVGProps } from 'react'

const CaretRightIcon: FC<SVGProps<SVGSVGElement>> = ({ width, height }) => {
    return (
        <svg viewBox="0 0 12 18" width={width} height={height}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M3.004 0c.088 0 .176.035.264.105l8.627 8.618c.07.076.105.167.105.273a.388.388 0 01-.105.272l-8.627 8.618A.349.349 0 013 18a.349.349 0 01-.268-.114L.105 15.259A.363.363 0 010 14.991c0-.108.035-.197.105-.268l5.728-5.727L.105 3.268A.363.363 0 010 3c0-.108.035-.198.105-.268L2.732.105A.432.432 0 013.004 0z"
            />
        </svg>
    )
}

export default CaretRightIcon
