const DEBUG = 0
const INFO = 1
const WARN = 2
const ERROR = 3

const gl = (typeof window !== 'undefined' ? window : global) || {}

const Logger = {
    ENABLED:
        gl.DEBUG ||
        (gl.location &&
            (location.href.indexOf('http://local.giphy.com') === 0 || location.search.indexOf('debug=true') !== -1)),
    LEVEL: 0,
    debug: function (...msg) {
        if (Logger.ENABLED && Logger.LEVEL <= DEBUG) {
            console.debug(...msg) // eslint-disable-line no-console
        }
    },

    info: function (...msg) {
        if (Logger.ENABLED && Logger.LEVEL <= INFO) {
            console.info(...msg) // eslint-disable-line no-console
        }
    },

    warn: function (...msg) {
        if (Logger.ENABLED && Logger.LEVEL <= WARN) {
            console.warn(...msg) // eslint-disable-line no-console
        }
    },
    error: function (...msg) {
        if (Logger.ENABLED && Logger.LEVEL <= ERROR) {
            console.error(...msg) // eslint-disable-line no-console
        }
    },
}
export default Logger
