import { giphyBlack } from '@giphy/colors'
import { grid12 } from 'shared/util/grid'
import styled, { css, keyframes } from 'styled-components'
import { GiphyContainer } from 'ui/src/components/logo'

const EASE_OUT_QUART = 'cubic-bezier(0.165, 0.84, 0.44, 1)'

const LogoAnimation = keyframes`
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -1280px 0;
    }
`

export const HeaderContainer = styled.div`
    height: 61px;
    padding: 2px 0 0;
    position: relative;
    z-index: 499;
`

export const LoginPlaceholder = styled.div`
    background-color: #3e3e3e;
    width: 170px;
    height: 36px;
    float: right;
    margin-top: 10px;
`

export const LogoContainer = styled.a`
    float: left;
    margin-top: 12px;
`

export const LogoSpan = styled.span`
    display: block;
    height: 35px;
    position: relative;
    width: 165px;
`

export const Svg = styled.svg`
    height: 35px;
    width: 164px;
`

export const Animation = styled.div`
    height: 80px;
    left: -26px;
    top: -23px;
    width: 80px;
    position: absolute;
`

export const AnimationOff = styled(Animation)`
    background-position: -1280px 0;
`

export const AnimationOn = styled(Animation)`
    background: url('/static/img/animations/logo_laser.svg') no-repeat 0 0;
    background-size: 1280px 80px;
    background-position: -1280px 0;
    animation: ${LogoAnimation} 1.5s steps(16);
`

/* SEARCH BAR */
export const SearchbarWrapper = styled.div<{ isSticky: boolean }>`
    height: 88px;
    width: ${grid12}px;

    &:after {
        content: '';
        width: 100%;
        display: block;
        clear: both;
    }
    ${({ isSticky }) =>
        isSticky &&
        css`
            height: 88px;
            padding: 8px 0;
            background-color: ${giphyBlack};
        `}
`

export const Searchbar = styled.div`
    position: relative;
    float: right;
`

/* STICKY NAV */
export const NavPlaceholderSearch = styled.div<{ showSearch?: boolean }>`
    clear: both;
    height: 127px;
`

export const NavPlaceholder = styled.div`
    clear: both;
    height: 70px;
`

export const HeaderWrapper = styled.div<{ isSticky: boolean; adsEnabled: boolean }>`
    position: absolute;
    top: 0;
    z-index: 499;
    ${({ adsEnabled }) =>
        adsEnabled &&
        css`
            top: 118px;
        `}

    ${Searchbar} {
        transition: width 0.3s ${EASE_OUT_QUART};
    }

    ${LogoContainer}, ${GiphyContainer} {
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ${EASE_OUT_QUART};
    }

    ${({ isSticky }) =>
        isSticky &&
        css`
            position: fixed;
            top: -70px;
            ${LogoContainer}, ${GiphyContainer} {
                transform: translate3d(0, 74px, 0);
                transition: transform 0.5s ${EASE_OUT_QUART} 0.3s;
            }
            ${Searchbar} {
                width: 846px;
            }
        `}
    ${({ isSticky, adsEnabled }) =>
        isSticky &&
        adsEnabled &&
        css`
            top: 56px;
        `}
`
