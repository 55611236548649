import { FIELDS } from 'shared/components/form/fields'
import { map } from 'lodash'
const create = (beforeElement, key) => [key, { beforeElement }]
const BeforeElements = {
    usernameOnly: map(
        {
            [FIELDS.socialHandle]: '@',
        },
        create
    ),
    needFullLink: map(
        {
            [FIELDS.socialHandle]: 'http://',
        },
        create
    ),
    artist: map(
        {
            [FIELDS.primarySiteText]: 'http://',
            [FIELDS.username]: '@',
        },
        create
    ),
    brand: map(
        {
            [FIELDS.primarySiteText]: 'http://',
            [FIELDS.username]: '@',
        },
        create
    ),
}

export default BeforeElements
