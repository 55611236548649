import { createContext } from 'react'

const noop = (...args) => console.log(...args)

export type GAEVentContextProps = {
    onPagination: (page: number) => void
}

const GAEventContext = createContext({
    onPagination: noop,
} as GAEVentContextProps)

export default GAEventContext
