import { CSSProperties, useEffect, useState } from 'react'
import { Transform } from 'types'

const useDragCss = (position: Transform, width: number, height: number): CSSProperties => {
    const [css, setCss] = useState<CSSProperties>({})

    useEffect(() => {
        const { scaleX, scaleY, rotation, x, y } = position
        const scaledWidth = Math.round(width * scaleX)
        const scaledHeight = Math.round(height * scaleY)
        const shiftX = Math.round((scaledWidth - width) / 2)
        const shiftY = Math.round((scaledHeight - height) / 2)
        const translateCss = `translate3d(${x - shiftX}px, ${y - shiftY}px, 0)`
        const rotateCss = `rotate(${rotation}deg)`

        setCss({
            height: scaledHeight,
            transform: `${translateCss} ${rotateCss}`,
            width: scaledWidth,
        })
    }, [position, width, height])

    return css
}

export default useDragCss
