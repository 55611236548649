exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sSWbYD-5TBtn10K5jLefv {\n    height: 40px;\n    margin-bottom: 60px;\n}\n\n.nx2VkIz2cjLYLeeUd2lKh {\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: contain;\n    display: inline-block;\n    height: 100%;\n    margin: 0 5px;\n}\n\n._11uopH1a79slTTXQEnct1Y {\n    background-image: url('/static/img/app_landing/appstore_2x.png');\n    width: 130px;\n}\n\n._2iC9cM2e3oVwPD9BvbMN58 {\n    background-image: url('/static/img/app_landing/googleplay_2x.png');\n    width: 124px;\n}\n\n._1q7zhHxFLG_TzTviPSubZa {\n    background-image: url('/static/img/app_landing/vive.png');\n    width: 103px;\n}\n\n._3zkM4b9Eg1EFnU9CJfGoFK {\n    background-image: url('/static/img/app_landing/oculus.png');\n    width: 103px;\n}\n", ""]);

// exports
exports.locals = {
	"appLinks": "sSWbYD-5TBtn10K5jLefv",
	"appLink": "nx2VkIz2cjLYLeeUd2lKh",
	"iosLink": "_11uopH1a79slTTXQEnct1Y nx2VkIz2cjLYLeeUd2lKh",
	"androidLink": "_2iC9cM2e3oVwPD9BvbMN58 nx2VkIz2cjLYLeeUd2lKh",
	"viveLink": "_1q7zhHxFLG_TzTviPSubZa nx2VkIz2cjLYLeeUd2lKh",
	"oculusLink": "_3zkM4b9Eg1EFnU9CJfGoFK nx2VkIz2cjLYLeeUd2lKh"
};