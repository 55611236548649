import * as colors from '@giphy/colors'
import { connect } from 'react-redux'
import IconTooltip from 'shared/components/ui/icon-tooltip'
import { message, SendMessageAction } from 'shared/redux/message'
import styled, { css } from 'styled-components'
import CTAToggle from '../../../../site/creation-tools/components/toggle'

const ToggleContainer = styled.div<{ bulkEditMode: boolean }>`
    position: absolute;
    top: -5px;
    left: 100%;
    margin-left: 25px;
    z-index: 1;

    ${(props) =>
        props.bulkEditMode &&
        css`
            position: relative;
            top: auto;
            left: 0;
            margin-left: 0;
            margin-bottom: 25px;
        `}
`

const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`
const ToggleText = styled.div`
    margin-right: auto;
`
const TooltipWrap = styled.div`
    margin-bottom: -5px;
`

const ToolTipInnerds = styled.div`
    font-weight: normal;
    text-align: center;
    line-height: normal;
    width: 200px;

    p:last-child {
        margin: 0px;
    }
`
type Props = {
    bulkEditMode: boolean
    previewWidth: number
    isCTAOn: boolean
    onToggle: Function
}
type ReduxProps = {
    message: SendMessageAction
}

const AdvancedButtonToggle = ({ message, bulkEditMode, previewWidth, isCTAOn, onToggle }: Props & ReduxProps) => {
    const toggleButton = () => {
        try {
            onToggle()
        } catch (e) {
            message(`Failed to updated Advanced Button.`, 'error')
        }
    }

    return (
        <ToggleContainer style={{ width: previewWidth }} bulkEditMode={bulkEditMode}>
            <Row>
                <ToggleText>Display as Button</ToggleText>
                <TooltipWrap>
                    <IconTooltip icon={'ss-help'} color={colors.giphyLightGrey}>
                        <ToolTipInnerds>
                            <p>Display a larger button link with customizable text below your video.</p>
                        </ToolTipInnerds>
                    </IconTooltip>
                </TooltipWrap>
            </Row>
            <CTAToggle onLabel="On" offLabel="Off" isOn={isCTAOn} onToggle={toggleButton} />
        </ToggleContainer>
    )
}

export default connect(undefined, { message })(AdvancedButtonToggle)
