import { setGADataLayer } from 'analytics'

export function trackGA4NavigationEvent(gifId, url, index, gridType) {
    const data = {
        event: 'related_content_click',
        options: {
            eventInfo: {
                navElement: 'related_content',
                navSubelement: 'related_' + gridType,
                link: url,
            },
            content: {
                contentId: gifId,
                contentType: gridType.substring(0, gridType.length - 1),
                contentIndex: index,
            },
        },
    }
    setGADataLayer(data)
}
