import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Gif from 'shared/components/gif/gif'
import Privacy from 'shared/components/privacy/privacy'
import Test from 'shared/components/test'
import gifProps from 'shared/prop-types/gif'
import { getHighestAnimatedRes } from 'shared/util/gif-extract'
import { addProtocol } from 'shared/util/url'
import { CY_GIF_DETAIL__GIF } from 'ui'
import css from './gif.css'
import Info from './meta/info'
import ViewOptions from './overlays/hover-overlay'

const GifWithHD = getHighestAnimatedRes()(Gif)
const preloader = <div className={css.preload} />
const fullscreenStyle = { width: '100%', height: '100%' }

const GifWrapper = ({ gif: { source_post_url, source_domain }, isFullScreen, children }) => {
    const hasSource = source_post_url || source_domain
    return (
        <div>
            {hasSource ? (
                <a
                    className={isFullScreen ? css.sourceDisabled : ''}
                    href={addProtocol(source_post_url || source_domain)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {children}
                </a>
            ) : (
                children
            )}
        </div>
    )
}

/**
 * GIF Detail - GIF Image: Controls the displaying of
 * the GIF along with the tile/fullscreen
 * hover state.
 *
 * @class GifAndInfo
 */
const GifAndInfo = ({
    gif,
    gifSize,
    isHidden,
    history: { replace },
    match: {
        params: { mode },
    },
}) => {
    const { is_sticker, is_hidden, path } = gif
    const isFullScreen = mode === 'fullscreen'
    const style = isFullScreen ? fullscreenStyle : gifSize
    return (
        <div style={{ width: gifSize.width, height: gifSize.height }}>
            <Info gif={gif} />
            <Test id={CY_GIF_DETAIL__GIF}>
                <div className={is_sticker ? css.sticker : css.gif} style={gifSize}>
                    {!isHidden && (
                        <div
                            className={isFullScreen ? css.fullscreen : css.image}
                            data-sticker={is_sticker}
                            onClick={() => {
                                if (isFullScreen) {
                                    replace(path)
                                }
                            }}
                        >
                            <GifWrapper gif={gif} isFullScreen={isFullScreen}>
                                <GifWithHD {...{ gif, preloader, style }} />
                            </GifWrapper>
                        </div>
                    )}
                    <ViewOptions className={css.overlay} gif={gif} />
                </div>
            </Test>
            {is_hidden && <Privacy title={'This GIF is private and can only be seen by you.'} />}
        </div>
    )
}

GifAndInfo.propTypes = {
    gif: gifProps.isRequired,
    gifSize: PropTypes.object.isRequired,
    isHidden: PropTypes.bool,
    isFlipped: PropTypes.bool,
    viewCount: PropTypes.number,
}

export default withRouter(GifAndInfo)
