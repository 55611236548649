import { useEffect, useState } from 'react'
import Loader from 'shared/components/loaders/dots'
import { IMediumPost } from '../types'
import { MEDIUM_API_URL } from '../constants'
import Carousel from '../carousel'
import Post from './post'

const AboutPageNewsPosts = () => {
    const [posts, setPosts] = useState<IMediumPost[]>([])
    const [tallestPostHeight, setTallestPostHeight] = useState<number>(0)
    const [isFetching, setIsFetching] = useState<boolean>(false)

    useEffect(() => {
        const fetchMediumPosts = async () => {
            try {
                setIsFetching(true)
                const { items = [] } = await fetch(MEDIUM_API_URL).then((response) => response.json())
                setPosts(items)
                setIsFetching(false)
            } catch (error) {
                setIsFetching(false)
            }
        }
        fetchMediumPosts()
    }, [])

    return isFetching ? (
        <Loader isAbsolute={false} />
    ) : (
        <Carousel onMeasured={(height) => setTallestPostHeight(height)}>
            {posts.map((post, i) => (
                <Post key={post.guid} index={i} post={post} minHeight={tallestPostHeight} />
            ))}
        </Carousel>
    )
}

export default AboutPageNewsPosts
