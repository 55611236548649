import { IGif } from '@giphy/js-types'
import { createContext, FC, useEffect, useState } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { VideoContextAPI } from 'shared/types/video'
import { parseUrl } from 'shared/util/url'

type VideoOverlayContextProps = {
    // Deal with loop through videos
    setMainVideoApi: (videoApi: VideoContextAPI) => void
    startNextVideo?: () => void
    setNextVideoToPlay?: (video: IGif | undefined) => void
    nextVideoToPlay?: IGif
    changeAutoplay: () => void
    autoplay: boolean | undefined
    loopCounter: number
    setLoopCounter: (counter: number) => void
    timeToNextVideo: number
    resetLoopToNextVideo: () => void
    setPreventLoopCounter: (prevent: boolean) => void

    // Handle up Next
    continueSameUpNext: boolean
    setContinueSameUpNext: (same: boolean) => void
}

type Props = {
    children?: React.ReactNode
}

export const VideoLoopContext = createContext({} as VideoOverlayContextProps)

const MINIMUM_TIME_UNTIL_GO_TO_NEXT_VIDEO = 15
const MINIMUM_OF_LOOPS = 3
const MAXIMUM_OF_LOOPS = 5

/*
 This context stores infos related to clips and handle behaviors such as the autoplay loop
 To read more info about it, you can check the video-loop-manager.md
 */
const VideoLoopContextManager: FC<Props> = ({ children }) => {
    // Deal with loop through videos
    const history = useHistory()
    const [nextVideoToPlay, setNextVideoToPlay] = useState<IGif>()
    const [autoplay, setAutoplay] = useLocalStorage<boolean>('loop-autoplay', true)
    const [loopCounter, setLoopCounter] = useState<number>(0)
    const [continueSameUpNext, setContinueSameUpNext] = useState<boolean>(false)
    const [preventLoopCounter, setPreventLoopCounter] = useState<boolean>(false)
    const [mainVideoApi, setMainVideoApi] = useState<VideoContextAPI>()
    const [timeToNextVideo, setTimeToNextVideo] = useState<number>(-1)
    const startNextVideo = () => {
        setLoopCounter(0)

        if (nextVideoToPlay) {
            setContinueSameUpNext(true)
        }
    }
    useEffect(() => {
        if (continueSameUpNext && nextVideoToPlay) {
            const { pathname } = parseUrl(nextVideoToPlay.url)
            history.push(String(pathname))
        }
    }, [continueSameUpNext])
    const handleLoopCounter = (counter: number) => {
        if (autoplay && !preventLoopCounter) {
            setLoopCounter(counter)
        }
    }
    const handlePreventLoopCounter = (preventLoopCounter) => {
        if (preventLoopCounter) {
            setLoopCounter(Math.round(loopCounter / 2))
        }
        setPreventLoopCounter?.(preventLoopCounter)
    }
    useEffect(() => {
        if (!autoplay) {
            setTimeToNextVideo(-1)
            setLoopCounter(0)
        }
    }, [autoplay])
    useEffect(() => {
        setTimeToNextVideo(-1)
        setLoopCounter(0)
    }, [mainVideoApi])
    useEffect(() => {
        if (mainVideoApi?.getDuration() && loopCounter > 0) {
            let loopToGoNext = Math.round(MINIMUM_TIME_UNTIL_GO_TO_NEXT_VIDEO / mainVideoApi?.getDuration())

            if (loopToGoNext < MINIMUM_OF_LOOPS) {
                loopToGoNext = MINIMUM_OF_LOOPS
            }

            if (loopToGoNext > MAXIMUM_OF_LOOPS) {
                loopToGoNext = MAXIMUM_OF_LOOPS
            }

            if (loopCounter < loopToGoNext - 2) {
                setTimeToNextVideo(-1)
            }
            if (loopCounter === loopToGoNext - 1) {
                const duration = mainVideoApi?.getDuration() || -1
                duration && setTimeToNextVideo(Math.round(duration))
            }
        }
    }, [loopCounter])
    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>

        if (timeToNextVideo === 0) {
            setTimeToNextVideo(-1)
            startNextVideo?.()
        } else {
            timer = setInterval(() => {
                if (timeToNextVideo > -1) {
                    setTimeToNextVideo(timeToNextVideo - 1)
                }
            }, 1000)
        }

        return () => clearInterval(timer)
    }, [timeToNextVideo])

    return (
        <VideoLoopContext.Provider
            value={{
                // Video Loop to next
                setMainVideoApi: (mainVideoApi) => setMainVideoApi(mainVideoApi),
                setLoopCounter: (counter) => handleLoopCounter(counter),
                startNextVideo: () => startNextVideo(),
                setNextVideoToPlay: (video: IGif) => setNextVideoToPlay(video),
                resetLoopToNextVideo: () => handleLoopCounter(Math.round(loopCounter / 2)),
                nextVideoToPlay,
                changeAutoplay: () => setAutoplay(!autoplay),
                setPreventLoopCounter: (preventLoopCounter) => handlePreventLoopCounter(preventLoopCounter),
                autoplay,
                loopCounter,
                timeToNextVideo,

                // Handle Up Next
                continueSameUpNext,
                setContinueSameUpNext: (same) => setContinueSameUpNext(same),
            }}
        >
            {children}
        </VideoLoopContext.Provider>
    )
}

export default VideoLoopContextManager
