import { connect } from 'react-redux'
import { compose } from 'recompose'
import CopyButton from '../../../../shared/buttons/copy-button'
import Social from '../../../../shared/buttons/social'
import Footer from '../../../../shared/modal/footer-container'
import * as selectors from '../../../../shared/reducers'
import { withGifDetailContext } from '../../gif-detail-context'
import TapAndHoldButton from './tap-and-hold-button'
import InfoOverlay from '../../info/index'
import styled from 'styled-components'
import { getContentOfGif, getCreatorOfGif, setGADataLayer, getUrlFileType } from 'analytics'
import { IGif } from '@giphy/js-types'
import { getUrl } from 'shared/util/gif-extract'

// fill the remaining area in the modal
const CopyGif = styled(InfoOverlay)`
    flex: 1;
    width: 100%;
    position: relative;
`

const trackShareSuccessEvent = (gif, contentFormat?) => {
    setGADataLayer({
        event: 'share_success',
        options: {
            social: {
                socialChannel: 'copy',
            },
            content: getContentOfGif(gif, contentFormat),
            creator: getCreatorOfGif(gif),
        },
    })
}

type ShareModalProps = {
    gif: IGif
    slug: string
    url: string
    rendition: string
    isGif: boolean
    setInstagramVisibleAction: (value: boolean) => void
}

const ShareModal = ({ gif, slug, url, rendition, isGif = false, setInstagramVisibleAction }: ShareModalProps) => {
    return (
        <>
            <CopyGif gif={gif} rendition={rendition} />
            <Footer>
                <TapAndHoldButton
                    CTA={`Share ${gif.is_sticker ? 'Sticker' : 'GIF'}`}
                    {...{ gif, rendition }}
                    onShareHappen={() =>
                        trackShareSuccessEvent(gif, { rendition, format: getUrlFileType(getUrl(gif, rendition)) })
                    }
                />
                <CopyButton
                    CTA={`Copy ${gif.is_sticker ? 'Sticker' : 'GIF'} Link`}
                    url={url}
                    label={slug}
                    onClick={() => trackShareSuccessEvent(gif)}
                />
                <Social
                    isGif={isGif}
                    gif={gif}
                    types={[`sms`, `facebook`, `twitter`, `instagram`, `pinterest`, `reddit`]}
                    url={''}
                    setInstagramVisibleAction={setInstagramVisibleAction}
                />
            </Footer>
        </>
    )
}

export default compose(
    withGifDetailContext,
    connect((state) => ({
        size: selectors.getAppSize(state),
    }))
)(ShareModal)
