import { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Tooltip from './tooltip'

const Container = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    height: ${(props) => props.size}px;
    position: relative;
    width: ${(props) => props.size}px;
    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
        `};
`

const Button = styled.div`
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.2s ease-out;
    width: 100%;

    &:before {
        background: ${(props) => props.backgroundColor};
        content: '';
        display: block;
        height: 100%;
        opacity: 0;
        transition: opacity 0.2s ease-out;
        width: 100%;
    }

    &:hover {
        transform: scale(1.1);

        &:before {
            opacity: 1;
        }
    }
`

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    width: 100%;
`

const getIconStyles = (index, activeIndex) => {
    return {
        opacity: activeIndex % 2 === index ? 1 : 0,
        transform: activeIndex % 2 === index ? 'scale(1.0)' : 'scale(0.6)',
    }
}

class AnimatedButton extends PureComponent {
    static defaultProps = {
        backgroundColor: 'transparent',
        size: 38,
        onIcon: null,
        offIcon: null,
        onClick: null,
        isOn: false,
        tooltip: undefined,
    }

    constructor(props) {
        super(props)

        this.state = {
            iconPosition: props.isOn ? 0 : 1,
        }
    }

    componentDidUpdate({ isOn: prevIsOn }) {
        if (this.props.isOn !== prevIsOn) {
            this.animateIcon()
        }
    }

    animateIcon = () => {
        this.setState({
            iconPosition: this.state.iconPosition + 1,
        })
    }

    render() {
        const { backgroundColor, className, tooltip = {}, size, onIcon, offIcon, onClick } = this.props
        const { iconPosition } = this.state
        return (
            <Tooltip {...tooltip} maxWidth={130} offsetY={6}>
                <Container className={className} size={size}>
                    <Button backgroundColor={backgroundColor} onClick={onClick}>
                        <IconWrapper style={getIconStyles(0, iconPosition)}>{onIcon}</IconWrapper>
                        <IconWrapper style={getIconStyles(1, iconPosition)}>{offIcon}</IconWrapper>
                    </Button>
                </Container>
            </Tooltip>
        )
    }
}

export default AnimatedButton
