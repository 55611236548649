import styled, { css, keyframes } from 'styled-components'
import { modalZIndex } from 'ui/src/constants'
import TooltipComponent from '../tooltip'
import { TooltipDirection } from '../types'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

export const HitZone = styled.div<{ $dimmed: boolean }>`
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${modalZIndex};

    ${({ $dimmed }) =>
        $dimmed &&
        css`
            animation: ${fadeIn} 0.15s ease-out;
            background: rgba(0, 0, 0, 0.7);
        `}
`

export const Tooltip = styled(TooltipComponent)<{ $direction: TooltipDirection }>`
    position: absolute;

    ${({ $direction }) => {
        switch ($direction) {
            case 'down':
                return css`
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%);
                `
            case 'left':
                return css`
                    right: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                `
            case 'right':
                return css`
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                `
            case 'up':
                return css`
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                `
        }
    }}
`

export const TriggerCoordinatesPlaceholder = styled.div`
    height: 20px;
    margin: -10px 0 0 -10px;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    width: 20px;
`

export const Wrapper = styled.div`
    color: inherit;
    overflow: visible;
    position: absolute;
    transition: opacity 0.15s ease-out;
    z-index: ${modalZIndex + 1};
`
