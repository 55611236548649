import * as colors from '@giphy/colors'
import { omit } from 'lodash'
import { createRef, PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AvatarInput from 'shared/components/form/avatar'
import { ApplicationSubmitWarning, DesktopTitle, NeedHelp, TermsOfServiceApply } from 'shared/components/form/copy'
import CSRFToken from 'shared/components/form/csrftoken-hoc'
import Fields, { FieldContainer, FIELDS, flattenResponse, Label } from 'shared/components/form/fields'
import { FormInput } from 'shared/components/form/form-components'
import appendNextUrl from 'shared/components/form/get-next-url'
import { Optional } from 'shared/components/form/inputs/label'
import SelectList from 'shared/components/form/ui/select-list'
import { STATUS_MESSAGES, STATUS_STYLE } from 'shared/components/message/flash-message'
import withLocationState from 'shared/components/with-location-state'
import { message } from 'shared/redux/message'
import { getUser } from 'shared/redux/reducers'
import isMobile from 'shared/util/is-mobile'
import { desktop, mobile } from 'shared/util/media-query'
import styled, { createGlobalStyle } from 'styled-components'
import Video from '../background-video'
import ArtistOrBrand, { TypeButton, TypeTitle } from './apply/artist-or-brand'
import GiphyArtist from './apply/giphy-artist'
import BeforeElements from './before-elements'
import BelowPlaceholders from './below-placeholders'
import Labels from './labels'
import Placeholders from './placeholders'
import SocialHandlePlaceholders from './social-handle-placeholders'
import { getIndustriesByGroup } from 'shared/api/industries'

const ERRORS = {
    CRITICAL_ERROR: 'CRITICAL_ERROR',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
}

const ERROR_MESSAGES = {
    CRITICAL_ERROR: STATUS_MESSAGES.SOMETHING_WENT_WRONG,
    VALIDATION_ERROR: 'Whoops! There were some issues with your application.',
}

const formWidth = 620

// These are the social network options that appear in the dropdown
const SOCIAL_OPTIONS = ['Instagram', 'Twitter', 'Facebook', 'Behance', 'YouTube', 'Dribbble', 'Other']
// These selections switch the input field to expect a username instead of a url
const USERNAME_SOCIAL_OPTIONS = ['Instagram', 'Twitter']

const Form = styled.form`
    display: flex;
    justify-content: center;
`

const TrueBlackBackground = createGlobalStyle`
    body {
        background-color: black;
    }
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
    ${FormInput} {
        font-weight: normal;
        margin: 0;
        &::-webkit-input-placeholder {
            opacity: 0;
        }
        ${mobile.css`
            font-size: 15px;
        `};
    }
    ${desktop.css`
        align-items: center;
    `};
    ${mobile.css`
        width: 100%;
        max-width: 350px;
    `};
`

const ApplicationDetails = styled.div`
    display: ${(props) => (props.shown ? 'block' : 'none')};

    ${mobile.css`
        width: 100%;
    `}
`

const DarkAvatarInput = styled(AvatarInput)`
    flex-direction: row;
    align-items: flex-end;
    width: 100%;

    & > div {
        width: 484px;
        margin-left: 16px;
        ${mobile.css`
           width: calc(100% - 136px);
        `}
    }
    img {
        width: 120px;
        height: 120px;
    }

    & > div > button {
        margin: 0;
        font-size: 16px;
        background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
    }

    & > div > input {
        cursor: pointer;
    }
`

const Peeker = styled.div`
    background: url(/static/img/apply/avatar-peeker-sticker.gif) center no-repeat;
    width: 108px;
    height: 138px;
    position: absolute;
    background-size: contain;
    right: 5px;
    top: 5px;
    z-index: 2;

    ${mobile.css`
        display: none;
    `}
`

const AvatarInstructions = styled.p`
    width: 300px;
    /* NOTE: Instructions are positioned absolutely to avoid altering the AvatarInput component */
    position: absolute;
    top: 23px;
    left: 138px;
    font-size: 16px;
    font-weight: 200;
    color: ${colors.giphyLightGrey};

    ${mobile.css`
        text-align: left;
        width: 200px;
        font-size: 15px;
        top: 30px;
    `}
`

export const DarkFields = styled(Fields)`
    ${(props) =>
        props.isDisabled &&
        `
    opacity: 0.5;
    pointer-events: none;
    `}

    ${Label} {
        margin: 8px 0px;
        font-size: 18px;

        div {
            flex-direction: row;
            color: white;
            margin-right: 10px;
        }

        ${Optional} {
            color: ${colors.giphyLightCharcoal};
        }

        ${mobile.css`
            font-size: 16px;
        `}
    }

    ${FieldContainer} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 20px 0px 0px;

        input {
            margin: 0;
        }
    }

    input {
        background: rgb(33, 33, 33);
        font-size: 16px;
        color: white;
        height: 42px;
        ${mobile.css`
            font-size: 15px;
        `};
    }

    ${mobile.css`
        width: 100%;
    `}

    ${desktop.css`
    ${(props) =>
        props.isHalf &&
        `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${FieldContainer} {
        width: 300px;

        input {
            margin: 0;
        }
    }
    `}
    `}
`

const FormFields = styled.div`
    width: 620px;
    margin: auto;
    display: flex;
    flex-direction: column;

    ${mobile.css`
        width: 100%;
    `}
`

const AvatarRow = styled.div`
    position: relative;
    display: flex;
    margin: 30px 0;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 100px;
    ${mobile.css`
        flex-direction: column;
    `};
`

const CenterFixedWidth = styled.div`
    max-width: ${formWidth}px;
    width: 100%;
    text-align: center;
`

const TermsOfService = styled(TermsOfServiceApply)`
    margin-bottom: 20px;
    font-size: 14px;

    ${mobile.css`
        max-width: 100%;
    `}
`

const HeaderImage = styled.img`
    width: 75px;
    height: 75px;
    margin: 0px auto;
    display: block;
    object-fit: contain;

    ${mobile.css`
        width: 62px;
        height: 62px;
    `}
`

const MobileTitle = styled.h1`
    font-size: 26px;
    text-align: center;
    width: 100%;
    margin: 8px auto;
    font-family: 'nexablack', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
`

const IntroText = styled.p`
    width: 788px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;

    ${mobile.css`
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 15px;
    `}
`

const FormTitle = styled(TypeTitle)`
    margin-bottom: 25px;
`

const CTAButton = styled(TypeButton)`
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    height: auto;
    padding: 7px;
    ${(props) => !props.submitEnabled && `background: ${colors.giphyDarkGrey};`}

    ${mobile.css`
        margin: 20px auto;
        background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);
        height: auto;
        padding: 15px
        line-height: 16px;
        color: white;
`}
`

const LearnMoreLink = styled.div`
    font-size: 18px;
    text-align: center;
    color: ${colors.giphyLightGrey};
    margin-bottom: 120px;
    a {
        color: ${colors.giphyBlue};

        &:hover {
            color: white;
        }
    }

    ${mobile.css`
        font-size: 14px;
        max-width: 100%;
        margin-bottom: 60px;
    `};
`

const NeedHelpLink = styled(NeedHelp)`
    margin-top: 10px;
    margin-bottom: 20px;
`

const BackgroundVideo = styled(Video)`
    height: 1015px;
    opacity: 0.23;
    ${mobile.css`
        height: 800px;
    `}
`

const SocialRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0px 0px;

    ${mobile.css`
        flex-direction: column;
    `}

    ${DarkFields} {
        margin: 0;
        ${Label} {
            opacity: 0;
        }
    }

    ${FieldContainer} {
        margin: 0;
    }
`

const RowLabel = styled.div`
    color: white;
    font-size: 18px;
    margin: 8px 0px;
    justify-content: flex-start;

    ${mobile.css`
        font-size: 16px;
    `}
`
const SocialPlatformChooser = styled.div`
    display: flex;
    flex-direction: column;

    & > div > div:first-child {
        background-color: ${colors.giphyDarkestGrey};
    }

    & > div > div:first-child > span {
        color: ${colors.giphyLightGrey};
        color: #8e8e8e;
        line-height: 42px;
        font-size: 16px;
    }

    ${mobile.css`
        text-align: left;
        width: 100%;
    `}
`

const SocialMediaInstructions = styled(Optional)`
    color: ${colors.giphyLightCharcoal};
    font-size: 16px;
    margin-top: 10px;

    ${mobile.css`
        font-size: 14px;
        text-align: left;
    `}
`

const EmailRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`

const EmailAddress = styled.div`
    color: ${colors.giphyLightGrey};
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mobile.css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    `}
`

const EmailInstructions = styled(Optional)`
    color: ${colors.giphyLightCharcoal};
    font-size: 16px;
    margin-left: 15px;
    a {
        color: #00ccff;
    }

    ${mobile.css`
        margin-left: 0;
        margin-top: 5px;
        font-size: 14px;
        text-align: left;
    `}
`

const WarningContainer = styled.div`
    ${mobile.css`
        width: 75%;
        margin: auto;
        line-height: 16px;
    `}
`

const IndustryRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0px 10px;

    ${mobile.css`
        flex-direction: column;
    `}

    ${DarkFields} {
        margin: 0;
        ${Label} {
            opacity: 0;
        }
    }

    ${FieldContainer} {
        margin: 0;
    }
`

const IndustrySelect = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div > div:first-child {
        background-color: ${colors.giphyDarkestGrey};
    }

    & > div > div:first-child > span {
        color: ${colors.giphyLightGrey};
        color: #8e8e8e;
        line-height: 42px;
        font-size: 16px;
    }

    ${mobile.css`
    text-align: left;
    width: 100%;
`}
`

const IndustryRowInstructions = styled(Optional)`
    color: ${colors.giphyLightCharcoal};
    font-size: 16px;
    margin-top: 0px;

    ${mobile.css`
        font-size: 14px;
        text-align: left;
    `}
`

@CSRFToken
@withRouter
@connect(
    (state) => ({
        user: getUser(state),
    }),
    { message }
)
@withLocationState(['needsToChangeAvatar'])
class ArtistForm extends PureComponent {
    constructor(props) {
        super(props)
        this.isAvatarValid = !props.needsToChangeAvatar
        this.formRef = createRef()
    }
    state = {
        submitEnabled: false,
        responseErrors: {},
        formShown: false,
        applyType: null,
        termsChecked: false,
        socialJustNeedsUsername: false,
        socialPlatformSelected: null,
        selectedIndustry: null,
        isIndustrySelected: false,
        otherIndustry: false,
        otherIndustryLabel: '',
        industryOptions: [],
    }
    onError = (errorMessage) => {
        const { message } = this.props
        window.scrollTo(0, 0)
        message(errorMessage, STATUS_STYLE.ERROR)
    }
    areFieldsOkay = () => {
        // Check all parts of the form.
        this.isFormValid = this.isContactValid && this.isIdentityValid && this.isLocationValid && this.isSocialValid
        // Also check the terms of service checkbox, avatar, and social platform
        if (
            this.isFormValid &&
            this.hasSelectedType &&
            this.state.termsChecked &&
            this.isAvatarValid &&
            this.state.isIndustrySelected &&
            this.state.socialPlatformSelected
        ) {
            return true
        }
    }

    onSocialPlatformChange = (platformSelected) => {
        this.setState(
            {
                socialPlatform: platformSelected,
                socialJustNeedsUsername: USERNAME_SOCIAL_OPTIONS.includes(platformSelected),
                socialPlatformSelected: true,
            },
            this.checkFormValidity
        )
    }

    onIndustryChange = (selectedIndustry) => {
        const otherIndustry = selectedIndustry === this.state.otherIndustryLabel
        const isIndustrySelected = selectedIndustry !== null && !otherIndustry
        this.setState(
            {
                selectedIndustry,
                otherIndustry,
                isIndustrySelected,
            },
            this.checkFormValidity
        )
    }

    composeSocialLink = (platform, entry) => {
        // if platform is in the list where we accept usernames then strip the entry of any preceding website protocols and compose a url
        if (USERNAME_SOCIAL_OPTIONS.includes(platform)) {
            let processedEntry = entry.substring(entry.lastIndexOf('/') + 1)
            return 'http://www.' + platform.toLowerCase() + '.com/' + processedEntry
        } else {
            // otherwise, strip all http protocals and add back our own
            let processedEntry = entry.replace(/^https?:\/\//, '')
            return 'http://' + processedEntry
        }
    }

    checkFormValidity = () => {
        this.setState({ submitEnabled: this.areFieldsOkay() })
    }

    onValidContactChange = (isValid) => {
        this.isContactValid = isValid
        this.checkFormValidity()
    }
    onValidIdentityChange = (isValid) => {
        this.isIdentityValid = isValid
        this.checkFormValidity()
    }
    onValidLocationChange = (isValid) => {
        this.isLocationValid = isValid
        this.checkFormValidity()
    }
    onValidSocialChange = (isValid) => {
        this.isSocialValid = isValid
        this.checkFormValidity()
    }
    onValidIndustryChange = (isValid) => {
        this.setState({ isIndustrySelected: isValid })
        this.checkFormValidity()
    }
    onAvatarValid = (isValid) => {
        this.isAvatarValid = isValid
        this.checkFormValidity()
    }
    onAvatarError = (error, errorMessage) => {
        this.onError(errorMessage)
    }

    ackResponseError = (field) => {
        // Callback for `Fields` to notify this Component to remove a server-side field error,
        // once a user has seen it and changed the field value
        const { responseErrors } = this.state
        if (responseErrors[field]) {
            this.setState({ responseErrors: omit(responseErrors, field) })
        }
    }
    submit = (event) => {
        event.stopPropagation()
        event.preventDefault()
        if (this.areFieldsOkay()) {
            const { csrftoken, user } = this.props
            const formData = new FormData(this.form)

            // Prefix some fields with `application_` to differentiate between `OnboardingRequest` and `User` fields
            if (this.state.applyType === 'creator') {
                // If creator, send a blank string for organization so the backend doesn't reject the application
                formData.set(`application_organization`, '')
                formData.delete(FIELDS.organization)
            } else {
                formData.set(`application_organization`, formData.get(FIELDS.organization))
                formData.delete(FIELDS.organization)
            }
            // process the user's input website to make sure it fits url
            let rawURL = formData.get(FIELDS.primarySiteText)
            let processedURL = rawURL.replace(/^https?:\/\//, '')
            // backend expects a url beginning with http:// url so let's create that for them
            processedURL = 'http://' + processedURL
            formData.set(`primary_site`, processedURL)
            formData.set(`application_request_type`, this.state.applyType === 'brand' ? 'partner' : 'creator') // NOTE: 'brand' is known as 'partner' on the backend

            // process the user's social media handle and platform
            let processedSocialURL = this.composeSocialLink(
                this.state.socialPlatform,
                formData.get(FIELDS.socialHandle)
            )
            formData.set(`application_social_url`, processedSocialURL)

            formData.set(`industry_label`, this.state.selectedIndustry)

            const request = new XMLHttpRequest()
            request.open('PUT', appendNextUrl(`/api/v1/users/${user.id}/apply/`))
            request.setRequestHeader('X-CSRFToken', csrftoken)
            request.onload = () => {
                let data
                try {
                    data = JSON.parse(request.responseText)
                } catch (err) {
                    this.handleResponse(new Error(ERRORS.CRITICAL_ERROR))
                    return
                }
                if (request.status >= 200 && request.status < 300) {
                    this.handleResponse(null, data)
                } else {
                    this.handleResponse(new Error(ERRORS.VALIDATION_ERROR), data)
                }
            }
            request.onerror = () => {
                this.handleResponse(new Error(ERRORS.CRITICAL_ERROR))
            }
            request.send(formData)
        }
    }
    handleResponse = (error, data) => {
        const { history } = this.props
        if (!error) {
            history.push('/apply/done')
            window.scrollTo(0, 0)
            return
        }
        if (data && data.application && data.application.user) {
            // Errors in a nested `application.user` array indicate an eligibility issue with the user's account,
            // not field validation issues. Wanted to use a 409 status code for this, but DRF forces a 400.
            this.onError(data.application.user.join(' '))
            return
        } else if (data) {
            // Field-level validation errors are passed as props back to `Fields`
            this.setState({ responseErrors: flattenResponse(data) })
        }
        this.onError(ERROR_MESSAGES[error.message])
    }

    chooseType = (whichType) => {
        this.hasSelectedType = true
        this.setState({ formShown: true }, function () {
            // Scroll to form
            this.formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        })
        this.setState({ applyType: whichType })
        this.setState({ submitEnabled: this.areFieldsOkay(), isArtist: whichType === 'creator' })

        getIndustriesByGroup(whichType).then(({ data: { industries, other_industry_id } }) => {
            const otherIndustryLabel = industries.find((i) => i.id === other_industry_id).label || ''
            const industryOptions = industries.map((i) => i.label)
            this.setState({ industryOptions, otherIndustryLabel })
        })
    }

    render() {
        const {
            responseErrors,
            submitEnabled,
            isArtist,
            formShown,
            applyType,
            termsChecked,
            socialJustNeedsUsername,
            socialPlatformSelected,
            otherIndustry,
            industryOptions,
        } = this.state
        const { className, user, needsToChangeAvatar } = this.props
        const industryLabel = applyType == 'creator' ? 'Category' : 'Industry'
        if (!user.username) {
            return null
        }
        return (
            <>
                <BackgroundVideo />
                <TrueBlackBackground />
                <DesktopTitle>Upgrade Your Account!</DesktopTitle>
                <Form className={className} method="POST" onSubmit={this.submit} ref={(c) => (this.form = c)}>
                    <FormContainer>
                        {isMobile() && <MobileTitle>Upgrade Your Account!</MobileTitle>}
                        <IntroText>
                            {isMobile()
                                ? 'Choose which describes you best:'
                                : 'Applying for a Creator or Brand account on GIPHY allows you to make, distribute, and track your content across the web. To start, select which applies to you:'}
                        </IntroText>
                        <ArtistOrBrand applyType={applyType} formShown={formShown} chooseType={this.chooseType} />
                        {!isMobile() && <GiphyArtist />}
                        <LearnMoreLink>
                            Not sure where you fit? Learn more {!isMobile() && 'about Creators & Brands on GIPHY '}
                            <a
                                href="https://support.giphy.com/hc/en-us/sections/360003006231-GIPHY-For-Brands-and-Artists-"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>
                            .
                        </LearnMoreLink>
                        <ApplicationDetails shown={formShown} ref={this.formRef}>
                            <HeaderImage src="/static/img/apply/peace.gif" />
                            <FormTitle>Confirm Account Details</FormTitle>
                            <FormFields>
                                <EmailRow>
                                    <RowLabel>Contact Email</RowLabel>
                                    <EmailAddress>
                                        {user.email}
                                        <EmailInstructions>
                                            Visit <a href="/settings">Settings</a> to update your email address
                                        </EmailInstructions>
                                    </EmailAddress>
                                </EmailRow>
                                <DarkFields
                                    isHalf={true}
                                    noTooltip
                                    showLabel
                                    onValidChange={this.onValidContactChange}
                                    showFields={[FIELDS.contact]}
                                    customFieldProps={isArtist ? BelowPlaceholders.artist : BelowPlaceholders.brand}
                                    user={user}
                                    responseErrors={responseErrors}
                                    ackResponseError={this.ackResponseError}
                                    aggroErrorMessages={true}
                                />
                                <DarkFields
                                    noTooltip
                                    showLabel
                                    onValidChange={this.onValidIdentityChange}
                                    showFields={[FIELDS.displayName, FIELDS.username, FIELDS.primarySiteText]}
                                    customFieldProps={
                                        isArtist
                                            ? [
                                                  ...BelowPlaceholders.artist,
                                                  ...Placeholders.artist,
                                                  ...BeforeElements.artist,
                                                  ...Labels.artist,
                                              ]
                                            : [
                                                  ...BelowPlaceholders.brand,
                                                  ...Placeholders.brand,
                                                  ...BeforeElements.brand,
                                                  ...Labels.brand,
                                              ]
                                    }
                                    user={user}
                                    stripHttp={true}
                                    responseErrors={responseErrors}
                                    ackResponseError={this.ackResponseError}
                                    aggroErrorMessages={true}
                                />
                                <DarkFields
                                    isHalf={!isArtist}
                                    noTooltip
                                    showLabel
                                    onValidChange={this.onValidLocationChange}
                                    showFields={isArtist ? [FIELDS.location] : [FIELDS.location, FIELDS.organization]}
                                    customFieldProps={
                                        isArtist
                                            ? BelowPlaceholders.artist
                                            : [...Placeholders.brand, ...BelowPlaceholders.brand]
                                    }
                                    user={user}
                                    responseErrors={responseErrors}
                                    ackResponseError={this.ackResponseError}
                                    aggroErrorMessages={true}
                                />
                                <SocialRow>
                                    <SocialPlatformChooser>
                                        <RowLabel>Social</RowLabel>
                                        <SelectList
                                            onChange={(option) => this.onSocialPlatformChange(option)}
                                            options={SOCIAL_OPTIONS}
                                            placeholder="Choose a Social Platform"
                                            themeId={'dark'}
                                            width={isMobile() ? '100%' : '300px'}
                                            allowMultiple={false}
                                            height={'42px'}
                                        />
                                    </SocialPlatformChooser>
                                    <DarkFields
                                        isHalf={true}
                                        isDisabled={!socialPlatformSelected}
                                        noTooltip
                                        showLabel
                                        onValidChange={this.onValidSocialChange}
                                        showFields={[FIELDS.socialHandle]}
                                        customFieldProps={
                                            socialJustNeedsUsername
                                                ? [
                                                      ...BeforeElements.usernameOnly,
                                                      ...SocialHandlePlaceholders.usernameOnly,
                                                  ]
                                                : [
                                                      ...BeforeElements.needFullLink,
                                                      ...SocialHandlePlaceholders.needFullLink,
                                                  ]
                                        }
                                        user={user}
                                        responseErrors={responseErrors}
                                        ackResponseError={this.ackResponseError}
                                        aggroErrorMessages={true}
                                        label={''}
                                        stripUrl={true}
                                    />
                                </SocialRow>
                                <SocialMediaInstructions>
                                    Enter the social media account best associated with you or your brand.
                                </SocialMediaInstructions>
                                <IndustryRow>
                                    <IndustrySelect>
                                        <RowLabel>{industryLabel}</RowLabel>
                                        <SelectList
                                            onChange={this.onIndustryChange}
                                            options={industryOptions}
                                            placeholder={`Choose the ${industryLabel}`}
                                            theme={'dark'}
                                            width={!otherIndustry ? '100%' : '300px'}
                                            allowMultiple={false}
                                            height={'42px'}
                                        />
                                    </IndustrySelect>
                                    {otherIndustry && (
                                        <DarkFields
                                            isHalf
                                            noTooltip
                                            showLabel
                                            noOptional
                                            onValidChange={this.onValidIndustryChange}
                                            showFields={[FIELDS.otherIndustry]}
                                            customFieldProps={[
                                                ...BelowPlaceholders.artist,
                                                [
                                                    FIELDS.otherIndustry,
                                                    {
                                                        placeholder: `Please specify your work ${industryLabel.toLowerCase()}`,
                                                        label: `Other ${industryLabel.toLowerCase()}`,
                                                    },
                                                ],
                                            ]}
                                            user={user}
                                            responseErrors={responseErrors}
                                            ackResponseError={this.ackResponseError}
                                            aggroErrorMessages={true}
                                        />
                                    )}
                                </IndustryRow>
                                <IndustryRowInstructions>
                                    Please specify your work {industryLabel.toLowerCase()}
                                </IndustryRowInstructions>
                                <AvatarRow>
                                    <Peeker />
                                    <AvatarInstructions>
                                        Channel Avatars can be a GIF, JPG, or PNG file and at least 250x250px.
                                    </AvatarInstructions>
                                    <RowLabel>Avatar</RowLabel>
                                    <DarkAvatarInput
                                        onError={this.onAvatarError}
                                        defaultAvatar={isMobile() ? user.avatarURL : user.avatar}
                                        needsToChangeAvatar={needsToChangeAvatar}
                                        onValid={this.onAvatarValid}
                                    />
                                </AvatarRow>
                            </FormFields>
                            <CenterFixedWidth>
                                <TermsOfService
                                    isChecked={termsChecked}
                                    onChange={(value) =>
                                        this.setState(
                                            {
                                                termsChecked: value,
                                            },
                                            this.checkFormValidity
                                        )
                                    }
                                />
                                <CTAButton submitEnabled={submitEnabled} disabled={!submitEnabled}>
                                    Submit Application
                                </CTAButton>
                                <WarningContainer>
                                    <ApplicationSubmitWarning />
                                </WarningContainer>
                                <NeedHelpLink />
                            </CenterFixedWidth>
                        </ApplicationDetails>
                    </FormContainer>
                </Form>
            </>
        )
    }
}

export default ArtistForm
