import { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { giphyDarkestGrey, giphyLightGrey } from '../../css/colors'
import CoverGif from './gif'
import Title from './title'
import Attribution from './attribution'
import Menu from './menu'
import { Background, Peeker } from './new'

const ITEM_SIZE = 50

const Container = styled.div`
    align-items: center;
    background: ${giphyDarkestGrey};
    border-right: 5px solid ${(props) => props.color};
    box-sizing: border-box;
    color: ${giphyLightGrey};
    font-size: 16px;
    height: ${ITEM_SIZE}px;
    line-height: ${ITEM_SIZE}px;
    margin-bottom: 6px;
    position: relative;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
`

export default class StoryListItem extends PureComponent {
    static propTypes = {
        story: PropTypes.object.isRequired,
        showMenu: PropTypes.bool,
        isNew: PropTypes.bool,
        onEditStory: PropTypes.func,
        onRemoveStory: PropTypes.func,
        onPrivacyClick: PropTypes.func,
    }

    static defaultProps = {
        isNew: false,
        showMenu: false,
    }

    state = {
        showNewStuff: false,
        isCoverGifLoaded: false,
    }

    componentDidUpdate({ isNew: prevIsNew }, { isCoverGifLoaded: prevIsCoverGifLoaded }) {
        const { isNew } = this.props
        const { isCoverGifLoaded, showNewStuff } = this.state
        if (isNew !== prevIsNew || isCoverGifLoaded !== prevIsCoverGifLoaded) {
            this.setState({
                showNewStuff: showNewStuff || (isNew && isCoverGifLoaded),
            })
        }
    }

    onCoverGifLoad = () => {
        this.setState({ isCoverGifLoaded: true })
    }

    onCoverGifUnload = () => {
        this.setState({ isCoverGifLoaded: false })
    }

    render() {
        const { story, showMenu, isNew, onEditStory, onRemoveStory, onPrivacyClick } = this.props
        const { showNewStuff } = this.state
        const { color, secondary_color: secondaryColor } = story
        return (
            <Container color={color} isNew={isNew}>
                {showNewStuff && <Background primaryColor={color} secondaryColor={secondaryColor} />}
                <Wrapper>
                    <CoverGif
                        onLoad={this.onCoverGifLoad}
                        onUnload={this.onCoverGifUnload}
                        size={ITEM_SIZE}
                        story={story}
                    />
                    <Title story={story} />
                    <Attribution hasMenu={showMenu} story={story} />
                    {showMenu && (
                        <Menu
                            onEditStory={onEditStory}
                            onPrivacyClick={onPrivacyClick}
                            onRemoveStory={onRemoveStory}
                            story={story}
                        />
                    )}
                </Wrapper>
                {showNewStuff && <Peeker />}
            </Container>
        )
    }
}
