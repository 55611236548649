import { ReactNode, useContext, useRef } from 'react'
import { useRefDimensions, useRefRect } from 'utils'
import { TooltipData } from '../types'
import { PortalTooltipContext } from './context'
import useTooltipCoordinates from './hooks/use-tooltip-coordinates'
import useTooltipDirection from './hooks/use-tooltip-direction'
import useTooltipOffset from './hooks/use-tooltip-offset'
import { Tooltip, Wrapper } from './style'

type Props = {
    children?: ReactNode
    className?: string
    tooltipData: TooltipData
}

const PortalTooltipContent = ({
    children,
    className,
    tooltipData: { direction: defaultDirection = 'up', offset: defaultOffset, ...tooltipData },
}: Props) => {
    const { activeTriggerRef } = useContext(PortalTooltipContext)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const triggerRect = useRefRect(activeTriggerRef)
    const dimensions = useRefDimensions(tooltipRef)
    const direction = useTooltipDirection(triggerRect, dimensions, defaultDirection)
    const coordinates = useTooltipCoordinates(triggerRect, direction)
    const offset = useTooltipOffset(coordinates, dimensions, direction)
    const isReady = !!direction && !!coordinates && !!offset

    return (
        <Wrapper style={isReady ? { left: coordinates.x, top: coordinates.y } : { opacity: 0 }}>
            <Tooltip
                {...tooltipData}
                $direction={direction}
                className={className}
                direction={direction}
                offset={defaultOffset || offset}
                ref={tooltipRef}
            >
                {children}
            </Tooltip>
        </Wrapper>
    )
}

export default PortalTooltipContent
