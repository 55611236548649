import { FIELDS } from 'shared/components/form/fields'
import { map } from 'lodash'
const create = (label, key) => [key, { label }]
const Labels = {
    artist: map(
        {
            [FIELDS.primarySiteText]: 'Website or Portfolio',
            [FIELDS.displayName]: 'Display Name',
            [FIELDS.username]: 'Username',
        },
        create
    ),
    brand: map(
        {
            [FIELDS.primarySiteText]: 'Website or Portfolio',
            [FIELDS.displayName]: 'Display Name',
            [FIELDS.username]: 'Username',
        },
        create
    ),
}

export default Labels
