import { CSSProperties, useMemo } from 'react'
import { Gradient } from 'types'
import { colorFromGradientPosition } from 'utils'

const getSegmentStyle = (start: number, end: number, gradient?: Gradient): CSSProperties => {
    const width = `${Math.round((end - start) * 100)}%`

    if (gradient) {
        const startColor = colorFromGradientPosition(gradient, start)
        const endColor = colorFromGradientPosition(gradient, end)

        return {
            background: `linear-gradient(45deg, ${startColor}, ${endColor})`,
            width,
        }
    }

    return { width }
}

const useBackgroundSegmentStyles = (snapTo: number[] = [], gradient?: Gradient): CSSProperties[] => {
    const segments = useMemo(() => {
        const mappedSegments = snapTo.map((value, i) => getSegmentStyle(snapTo[i - 1] || 0, value, gradient))

        if (snapTo.length && snapTo[snapTo.length - 1] < 1) {
            return [...mappedSegments, getSegmentStyle(snapTo[snapTo.length - 1], 1, gradient)]
        }

        return mappedSegments
    }, [gradient, snapTo])

    return segments
}

export default useBackgroundSegmentStyles
