import { useState, useContext } from 'react'
import { valuesList } from './valuesList'
import { IMAGE_PATH } from '../constants'
import ValuesCarousel from './values-carousel'
import { AboutPageContext } from './../context'
import {
    Container,
    SectionImage,
    TitleContainer,
    Title,
    SubTitle,
    ValuesBox,
    LeftColumn,
    ValueImageBox,
    ValueImage,
    ValueContent,
    ValueAnimation,
    ValueContentTitle,
    ValueSeeAllButton,
    Nav,
    NavText,
    NavLeft,
    NavRight,
    NavTitle,
    RightColumn,
    List,
    ListItem,
    ListItemButton,
    ButtonText,
    BoxImage,
} from './sc'

export default function Values() {
    const [activeValueIndex, setActiveValueIndex] = useState<number>(0)
    const [activeValueHoverIndex, setActiveValueHoverIndex] = useState<number | undefined>(undefined)
    const { windowWidth } = useContext(AboutPageContext)
    const isMobile = windowWidth < 768
    const selectedValue = valuesList[activeValueIndex]

    const goToNextValue = () => {
        const newValueIndex = activeValueIndex + 1

        if (newValueIndex === valuesList.length) {
            setActiveValueIndex(0)
        } else {
            setActiveValueIndex(newValueIndex)
        }
    }

    const goToPrevValue = () => {
        const newValueIndex = activeValueIndex - 1

        if (newValueIndex === -1) {
            setActiveValueIndex(valuesList.length - 1)
        } else {
            setActiveValueIndex(newValueIndex)
        }
    }

    const TitleWithNav = ({ valueName }) => {
        return (
            <NavTitle>
                <Nav onClick={() => goToPrevValue()}>
                    <i className="ss-icon ss-navigateleft" />
                </Nav>
                <ValueContentTitle>{`${valueName}`}</ValueContentTitle>
                <Nav onClick={() => goToNextValue()}>
                    <i className="ss-icon ss-navigateright" />
                </Nav>
            </NavTitle>
        )
    }
    return (
        <Container>
            <TitleContainer>
                <SectionImage src={`${IMAGE_PATH}/hands-up.gif`} />
                <Title>Our Values</Title>
                <SubTitle>What we care about.</SubTitle>
            </TitleContainer>
            {isMobile && <ValuesCarousel currentValue={selectedValue.name} setValue={setActiveValueIndex} />}
            <ValuesBox>
                <LeftColumn gradient={selectedValue.gradient}>
                    <ValueImageBox>
                        {valuesList.map((value, key) => (
                            <ValueImage
                                key={key}
                                active={key === activeValueIndex}
                                src={`${IMAGE_PATH}/values/${value.image}`}
                                alt={value.name}
                            />
                        ))}
                    </ValueImageBox>
                    <ValueContent>
                        {valuesList.map((value, key) => (
                            <ValueAnimation key={key} active={key === activeValueIndex}>
                                {isMobile ? (
                                    <TitleWithNav valueName={value.name} />
                                ) : (
                                    <ValueContentTitle>{value.name}</ValueContentTitle>
                                )}
                                {value.text}
                                <ValueSeeAllButton href={value.link || `search/${value.name}`}>
                                    <span>See all the {value.linkText || value.name} GIFs</span>
                                    <i className="ss-icon ss-navigateright" />
                                </ValueSeeAllButton>
                            </ValueAnimation>
                        ))}
                        {!isMobile && (
                            <>
                                <NavLeft onClick={() => goToPrevValue()}>
                                    <i className="ss-icon ss-navigateleft" />
                                    <NavText>Prev</NavText>
                                </NavLeft>
                                <NavRight onClick={() => goToNextValue()}>
                                    <NavText>Next</NavText>
                                    <i className="ss-icon ss-navigateright" />
                                </NavRight>
                            </>
                        )}
                    </ValueContent>
                </LeftColumn>
                {!isMobile && (
                    <RightColumn>
                        <List>
                            {valuesList.map((value, key) => (
                                <ListItem key={key}>
                                    <ListItemButton
                                        active={key === activeValueHoverIndex || key === activeValueIndex}
                                        gradient={value.gradient}
                                    />
                                    <ButtonText
                                        onClick={() => setActiveValueIndex(key)}
                                        onMouseEnter={() => setActiveValueHoverIndex(key)}
                                        onMouseLeave={() => setActiveValueHoverIndex(undefined)}
                                    >
                                        {value.name}
                                    </ButtonText>
                                </ListItem>
                            ))}
                        </List>
                        <BoxImage src={`${IMAGE_PATH}/values-bottom.gif`} />
                    </RightColumn>
                )}
            </ValuesBox>
        </Container>
    )
}
