import { giphyBlack, giphyBlue, giphyGreen, giphyPink, giphyPurple, giphyRed, giphyYellow } from '@giphy/colors'
import { Size } from 'modules/search-bar/types'
import styled, { css, keyframes } from 'styled-components'

const jazzy = keyframes`
    0% { fill: ${giphyPurple}; }
    16% { fill: ${giphyBlue}; }
    32% { fill: ${giphyGreen}; }
    48% { fill: ${giphyYellow}; }
    64% { fill: ${giphyRed}; }
    80% { fill: ${giphyPink};}
    100% { fill: ${giphyPurple}; }
`

const PlayButton = styled.svg<{ isAnimated: boolean }>`
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.33);
    display: block;
    position: relative;
    transition: box-shadow 0.2s ease-out;
    ${(props) =>
        props.isAnimated &&
        css`
            path {
                transition: all 0.2s ease-out;
            }

            &:hover {
                box-shadow: 0 0 0 4px ${giphyBlack};

                path[data-playbackground] {
                    fill: ${giphyBlack};
                }

                path[data-playicon] {
                    animation: ${jazzy} 1s linear 0s infinite;
                    opacity: 1;
                }
            }
        `}
`

type Props = {
    size?: Size
    className?: string
    onClick?: () => void
    isAnimated?: boolean
}

const PlayBoxIcon = ({
    size = { width: 30, height: 30 },
    className = '',
    onClick = () => {},
    isAnimated = false,
}: Props) => (
    <PlayButton
        className={className}
        width={size.width}
        height={size.height}
        viewBox="0 0 45 45"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        isAnimated={isAnimated}
    >
        <defs>
            <filter x="-27.8%" y="-25.6%" width="155.6%" height="155.6%" filterUnits="objectBoundingBox" id="a">
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="M0 0h45v45H0V0zm15.836 10.842a.804.804 0 0 0-.417.12l-.024 23.75c.107.087.244.13.41.13.11 0 5.99-3.787 17.639-11.361a.389.389 0 0 0 .161-.311.389.389 0 0 0-.161-.31L16.24 10.961a.782.782 0 0 0-.404-.12z"
                data-playbackground
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                d="M15.836 10.842c.135 0 .27.04.404.12L33.444 22.86c.107.087.161.19.161.311 0 .12-.054.224-.161.31-11.649 7.575-17.528 11.362-17.64 11.362a.632.632 0 0 1-.41-.13l.025-23.75a.804.804 0 0 1 .417-.12z"
                data-playicon
                fill="#121212"
                opacity=".5"
            />
        </g>
    </PlayButton>
)

export default PlayBoxIcon
