import { IUser } from '@giphy/js-types'
import { useCallback, useEffect } from 'react'
import Avatar from './avatar'
// @ts-ignore
import styles from './avatar-badge.css'
import UsernameBadge from './username-badge'
import { useInView } from 'react-intersection-observer'
import { pingback, PingbackActionType, Pingback, PingbackAttributes } from '@giphy/js-analytics'

type PingbackAnalytics = {
    eventType: string
    data: PingbackAttributes
    user: IUser
    analyticsResponsePayload: string
}
type Props = {
    user?: IUser
    title: string
    height: number
    onClick?: () => void
    css: any
    pingbackAnalytics: PingbackAnalytics
}
const AvatarBadge = (props: Props) => {
    const { user, title, height, onClick, css = {}, pingbackAnalytics } = props
    const { titleStyle = styles.title, containerStyle = styles.container, profileStyle = styles.profileInfo } = css

    const createPingbackAction = useCallback(
        (eventType, actionType: PingbackActionType, attributes, analyticsResponsePayload: string): Pingback => {
            return {
                actionType,
                eventType,
                attributes,
                analyticsResponsePayload,
            }
        },
        []
    )

    const [ref, inView] = useInView({ threshold: 0, triggerOnce: true })

    const onSeen = useCallback(() => {
        const { eventType, data, user, analyticsResponsePayload } = pingbackAnalytics

        const action = createPingbackAction(eventType, 'SEEN', data, analyticsResponsePayload)
        firePingback(action, user)
    }, [pingbackAnalytics])

    useEffect(() => {
        if (inView) {
            onSeen()
        }
    }, [inView, onSeen])

    const handleClick = useCallback(() => {
        const { eventType, data, user, analyticsResponsePayload } = pingbackAnalytics
        const action = createPingbackAction(eventType, 'CLICK', data, analyticsResponsePayload)
        firePingback(action, user)
        onClick?.()
    }, [pingbackAnalytics])

    const handleHover = useCallback(() => {
        const { eventType, data, user, analyticsResponsePayload } = pingbackAnalytics
        const action = createPingbackAction(eventType, 'HOVER', data, analyticsResponsePayload)
        firePingback(action, user)
    }, [pingbackAnalytics])

    const firePingback = useCallback((action, user) => {
        pingback({ ...action, userId: user?.id })
    }, [])

    return (
        <div ref={ref} onMouseEnter={handleHover} className={containerStyle} onClick={handleClick}>
            <Avatar user={user} height={height} />
            <div className={profileStyle}>
                <a
                    // @ts-ignore TODO: Update after new @giphy/types version
                    href={user?.profile_url?.slice(0, -1)}
                >
                    <h1 className={titleStyle}>{title}</h1>
                </a>
                <div className={styles.usernameClass}>
                    <UsernameBadge user={user} tooltip={false} />
                </div>
            </div>
        </div>
    )
}

export default AvatarBadge
