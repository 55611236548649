import { giphyDarkestGrey, giphyWhite } from '@giphy/colors'
import { ReactNode } from 'react'
import Toggle from 'shared/components/form/ui/toggle'
import styled from 'styled-components'

const OFF_GRADIENT = `linear-gradient(-135deg, rgb(74, 144, 226) 0%, rgb(148, 56, 255) 100%)`
const ON_GRADIENT = `linear-gradient(-135deg, rgb(0, 231, 149) 0%, rgb(0, 187, 221) 100%)`

const Text = styled.div`
    font-size: 17px;
    text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
`

const ToggleContainer = styled(Toggle)`
    margin-bottom: 16px;
    width: 100%;
    border-radius: 22px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    flex-shrink: 0;
`

type Props = {
    toggle?: () => void
    clickOn?: () => void
    clickOff?: () => void
    offText?: string | ReactNode
    onText?: string | ReactNode
    width?: number
    height?: number
    isOn: boolean | undefined
    background?: string
}

const ToggleButton = ({
    toggle = () => {},
    clickOn,
    clickOff,
    onText,
    offText,
    isOn,
    width = 206,
    height = 28,
    background = giphyDarkestGrey,
}: Props) => {
    return (
        <ToggleContainer
            toggle={toggle}
            isOn={isOn}
            offColor={OFF_GRADIENT}
            onColor={ON_GRADIENT}
            height={height}
            width={width}
            onElement={typeof onText === 'string' ? <Text>{onText}</Text> : onText}
            offElement={typeof offText === 'string' ? <Text>{offText}</Text> : offText}
            borderRadius={22}
            iconOn={giphyWhite}
            backgroundColor={background}
            iconOff="rgb(216, 216, 216)"
            clickOn={clickOn}
            clickOff={clickOff}
        />
    )
}

export default ToggleButton
