import { shuffle } from 'lodash'
import { TEAM_COLUMNS, TEAM_COLUMN_WIDTHS, TEAM_GUTTER_WIDTH } from '../constants'
import { IStaffMember } from '../types'

export const chunkStaffMembers = (staff: IStaffMember[] = []) => {
    let shuffled: IStaffMember[] = shuffle(staff)
    const chunks: any[] = []
    const chunkItemCount: number = TEAM_COLUMNS.reduce((a, b) => a + b, 0)
    const chunkCount: number = Math.floor(shuffled.length / chunkItemCount)
    let currentX: number = 0

    // chunk array of staff members into rows and columns
    for (let i = 0; i < chunkCount; i++) {
        TEAM_COLUMNS.forEach((itemCount: number, colIndex: number) => {
            chunks.push({
                members: shuffled.splice(0, itemCount),
                size: TEAM_COLUMN_WIDTHS[colIndex],
                x: currentX,
            })
            currentX += TEAM_COLUMN_WIDTHS[colIndex] + TEAM_GUTTER_WIDTH
        })
    }

    return chunks
}
