exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._33n4j4MBAyFTldds13l8JL {\n    height: 330px;\n    margin: 0 auto 40px;\n    position: relative;\n    width: 365px;\n    perspective: 1000px;\n}\n._2jiBuDtydQqHgQGVgZ2LZQ {\n    background: #000;\n    border-radius: 2px;\n    overflow: hidden;\n    position: absolute;\n}\n.nC7noVP9e-qzMwqHKg3DY {\n    background-position: center center;\n    background-repeat: no-repeat;\n    height: 100%;\n    width: 100%;\n}\n\n._30ccKNxjRwD9T0qwKi8gSO {\n    background-size: contain;\n}\n\n._2O15SPTweL8Q4WX-SE4XUA {\n    background-size: cover;\n}\n\n._28tMEXnSx2ZKiC95dHCSQ0 {\n    background: url('/static/img/app_landing/phone.svg') no-repeat right center;\n    background-size: auto 100%;\n}\n\n._3nyCAdRyPHHLjW945xlYAn {\n    background: url('/static/img/app_landing/computer.svg') no-repeat right center;\n    background-size: auto 100%;\n}\n\n._15Tylxtq_mgZ8s3mBhF5w_ {\n    height: 278px;\n    right: 163px;\n    top: 55px;\n    width: 158px;\n    transform-origin: 0 0;\n    transform: rotateX(33deg) rotateY(3deg) rotateZ(-24deg) skewX(5deg) skewY(3deg);\n}\n\n.NxghsKzM6arzNZGdtZ4TQ {\n    height: 218px;\n    right: 81px;\n    top: 28px;\n    width: 322px;\n    transform-origin: 100% 0;\n    transform: rotateX(4deg) rotateY(-43deg) rotateZ(-8deg) skewX(-14deg) skewY(5deg);\n}\n\n.wciEzyndZkQOb8D-uhUFG {\n    transform: scaleX(-1);\n}\n\n.wciEzyndZkQOb8D-uhUFG ._30ccKNxjRwD9T0qwKi8gSO {\n        transform: scaleX(-1);\n    }\n\n.x61FOO4-b1gfn8b87GKZX {\n}\n\n._203F-eKQEHqFApMYoubG7n {\n}\n\n.DFvQYmzFJzbtBwjgQQZ7C {\n}\n\n._3M4tYPEce2PpmbDvnftpYD {\n}", ""]);

// exports
exports.locals = {
	"screenshotWrapper": "_33n4j4MBAyFTldds13l8JL",
	"screenshot": "_2jiBuDtydQqHgQGVgZ2LZQ",
	"screenshotImage": "nC7noVP9e-qzMwqHKg3DY",
	"phoneScreenshotImage": "_30ccKNxjRwD9T0qwKi8gSO nC7noVP9e-qzMwqHKg3DY",
	"desktopScreenshotImage": "_2O15SPTweL8Q4WX-SE4XUA nC7noVP9e-qzMwqHKg3DY",
	"phoneWrapper": "_28tMEXnSx2ZKiC95dHCSQ0 _33n4j4MBAyFTldds13l8JL",
	"desktopWrapper": "_3nyCAdRyPHHLjW945xlYAn _33n4j4MBAyFTldds13l8JL",
	"phoneScreenshot": "_15Tylxtq_mgZ8s3mBhF5w_ _2jiBuDtydQqHgQGVgZ2LZQ",
	"desktopScreenshot": "NxghsKzM6arzNZGdtZ4TQ _2jiBuDtydQqHgQGVgZ2LZQ",
	"odd": "wciEzyndZkQOb8D-uhUFG",
	"phoneWrapperEven": "x61FOO4-b1gfn8b87GKZX _28tMEXnSx2ZKiC95dHCSQ0 _33n4j4MBAyFTldds13l8JL",
	"desktopWrapperOdd": "_203F-eKQEHqFApMYoubG7n _3nyCAdRyPHHLjW945xlYAn _33n4j4MBAyFTldds13l8JL",
	"phoneWrapperOdd": "DFvQYmzFJzbtBwjgQQZ7C _28tMEXnSx2ZKiC95dHCSQ0 _33n4j4MBAyFTldds13l8JL wciEzyndZkQOb8D-uhUFG",
	"desktopWrapperEven": "_3M4tYPEce2PpmbDvnftpYD _3nyCAdRyPHHLjW945xlYAn _33n4j4MBAyFTldds13l8JL wciEzyndZkQOb8D-uhUFG"
};