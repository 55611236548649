import { giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { ControlSize, Gradient } from 'types'
import { useHighlightStyle } from './hooks/use-highlight-style'
import { useLabelStyle } from './hooks/use-label-style'
import { Container, Highlight, Label } from './style'

type Props = {
    backgroundColor?: string
    className?: string
    colorOff?: string
    colorOn?: string
    disabled?: boolean
    gradientOff?: Gradient
    gradientOn?: Gradient
    isOn?: boolean
    labelOff?: ReactNode
    labelOn?: ReactNode
    size?: ControlSize
    style?: CSSProperties
    tabIndex?: number
    dontManageState?: boolean
    onChange?: (isOn: boolean) => void
}

const Toggle = ({
    backgroundColor = giphyDarkGrey,
    className,
    colorOff = '#fff',
    colorOn = '#fff',
    disabled = false,
    gradientOff,
    gradientOn,
    isOn: isOnProp = false,
    labelOff = 'Off',
    labelOn = 'On',
    size = 'small',
    style,
    tabIndex,
    onChange,
    dontManageState = false,
}: Props) => {
    const [isOn, setIsOn] = useState<boolean>(isOnProp)

    const color = isOn ? colorOn : colorOff
    const gradient = isOn ? gradientOn : gradientOff
    const styleHighlight = useHighlightStyle(isOn, color, gradient)
    const styleOff = useLabelStyle(!isOn, backgroundColor, colorOff, gradientOff)
    const styleOn = useLabelStyle(isOn, backgroundColor, colorOn, gradientOn)

    function onToggle() {
        const newIsOn = !isOn
        if (!dontManageState) {
            setIsOn(newIsOn)
        }
        onChange?.(newIsOn)
    }

    useEffect(() => {
        setIsOn(isOnProp)
    }, [isOnProp])
    return (
        <Container
            $backgroundColor={backgroundColor}
            $disabled={disabled}
            $size={size}
            className={className}
            style={style}
            tabIndex={disabled ? -1 : tabIndex}
            onClick={onToggle}
        >
            <Highlight style={styleHighlight} />
            <Label style={styleOff}>{labelOff}</Label>
            <Label style={styleOn}>{labelOn}</Label>
        </Container>
    )
}

export default Toggle
