import { giphyRed } from '@giphy/colors'
import useWindowSize from 'shared/hooks/window-size'
import { grid12 } from 'shared/util/grid'
import styled from 'styled-components'
import EmptyStatePlaceholder from '../empty-state-placeholder'

const Container = styled.div`
    line-height: 1.4;
    margin-top: 20px;
`

const HeartIcon = styled.i`
    display: inline-block;
    margin: 0 1px;
    vertical-align: middle;
`

type Props = {
    isMobile?: boolean
}

const NoFavorites = ({ isMobile }: Props) => {
    const { innerWidth } = useWindowSize()
    return (
        <Container>
            <EmptyStatePlaceholder
                height={200}
                width={isMobile ? innerWidth - 12 : grid12}
                colors={['#1A1320', '#20171B']}
            >
                <div>
                    You don&apos;t have any <span style={{ color: giphyRed }}>favorites</span> yet!
                </div>
                <div>
                    Add to your favs by clicking the <HeartIcon className="ss-heart" /> around GIPHY.
                </div>
            </EmptyStatePlaceholder>
        </Container>
    )
}

export default NoFavorites
