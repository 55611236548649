import { IconProps } from '../types/icons'

export const AlignCenterIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.6914 0C19.3397 0 19.8653 0.544348 19.8653 1.21583C19.8653 1.84535 19.4034 2.36313 18.8115 2.42539L18.6914 2.43167H5.19171C4.54341 2.43167 4.01786 1.88732 4.01786 1.21583C4.01786 0.586316 4.47977 0.0685406 5.07169 0.00627768L5.19171 0H18.6914ZM17.2148 13.5683C17.8631 13.5683 18.3886 14.1127 18.3886 14.7842C18.3886 15.4137 17.9267 15.9315 17.3348 15.9937L17.2148 16H6.80831C6.16002 16 5.63447 15.4557 5.63447 14.7842C5.63447 14.1546 6.09638 13.6369 6.6883 13.5746L6.80831 13.5683H17.2148ZM22.8262 6.80867C23.4744 6.80867 24 7.35302 24 8.02451C24 8.65403 23.5381 9.1718 22.9462 9.23407L22.8262 9.24034H1.17385C0.52555 9.24034 0 8.696 0 8.02451C0 7.39499 0.461909 6.87721 1.05383 6.81495L1.17385 6.80867H22.8262Z"
            fill={color}
        />
    </svg>
)

export const AlignLeftIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6736 0C15.3219 0 15.8474 0.542685 15.8474 1.21212C15.8474 1.83972 15.3855 2.35591 14.7936 2.41799L14.6736 2.42424H1.17385C0.525549 2.42424 0 1.88156 0 1.21212C0 0.584525 0.461909 0.0683312 1.05383 0.00625851L1.17385 0H14.6736ZM11.5803 13.5758C12.2286 13.5758 12.7542 14.1184 12.7542 14.7879C12.7542 15.4155 12.2923 15.9317 11.7004 15.9937L11.5803 16H1.17385C0.525549 16 0 15.4573 0 14.7879C0 14.1603 0.461909 13.6441 1.05383 13.582L1.17385 13.5758H11.5803ZM22.8262 6.78788C23.4745 6.78788 24 7.33056 24 8C24 8.6276 23.5381 9.14379 22.9462 9.20586L22.8262 9.21212H1.17385C0.525549 9.21212 0 8.66944 0 8C0 7.3724 0.461909 6.85621 1.05383 6.79414L1.17385 6.78788H22.8262Z"
            fill={color}
        />
    </svg>
)

export const AlignRightIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.32642 0C8.67813 0 8.15258 0.542685 8.15258 1.21212C8.15258 1.83972 8.61449 2.35591 9.20641 2.41798L9.32642 2.42424H22.8262C23.4745 2.42424 24 1.88156 24 1.21212C24 0.584525 23.5381 0.0683312 22.9462 0.00625851L22.8262 0H9.32642ZM12.4197 13.5758C11.7714 13.5758 11.2458 14.1184 11.2458 14.7879C11.2458 15.4155 11.7077 15.9317 12.2996 15.9937L12.4197 16H22.8262C23.4745 16 24 15.4573 24 14.7879C24 14.1603 23.5381 13.6441 22.9462 13.582L22.8262 13.5758H12.4197ZM1.17385 6.78788C0.525551 6.78788 0 7.33056 0 8C0 8.6276 0.46191 9.14379 1.05383 9.20586L1.17385 9.21212H22.8262C23.4745 9.21212 24 8.66944 24 8C24 7.3724 23.5381 6.85621 22.9462 6.79414L22.8262 6.78788H1.17385Z"
            fill={color}
        />
    </svg>
)

export const AnimatedTextIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3861 18H18.5875V4.19143H24V0H8V4.19143H13.3861V18ZM12 12H6V14H12V12ZM0 18V16H12V18H0Z"
            fill={color}
        />
    </svg>
)

export const CameraIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.0665 0L21.066 4.4663L28 4.46701V24H0V4.46701L6.93242 4.4663L6.93353 0H21.0665ZM19.2001 1.94924H8.79985V6.41624H1.86633V22.0508H26.1337V6.41624H19.2001V1.94924ZM14.3467 7.44501C17.3038 7.44501 19.6798 9.99624 19.6798 13.1168C19.6798 16.2373 17.3038 18.7885 14.3467 18.7885C11.3895 18.7885 9.01358 16.2373 9.01358 13.1168C9.01358 9.99624 11.3895 7.44501 14.3467 7.44501ZM14.3467 9.39425C12.432 9.39425 10.8799 11.0609 10.8799 13.1168C10.8799 15.1726 12.432 16.8393 14.3467 16.8393C16.2613 16.8393 17.8134 15.1726 17.8134 13.1168C17.8134 11.0609 16.2613 9.39425 14.3467 9.39425Z"
            fill={color}
        />
    </svg>
)

export const CameraRollIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9763 3.83779C15.4017 3.83779 16.2527 4.17627 16.2527 5.02246V18.5615C16.2527 19.5769 15.3166 20 14.8061 20H1.27639C0.850924 20 0 19.5769 0 18.5615V4.93784C0 4.09165 0.68074 3.83779 1.1062 3.83779H14.9763ZM10.126 10.0996L7.23286 14.1613L5.36083 11.792L1.44657 16.8691H14.8912L10.126 10.0996ZM18.6242 0C19.3747 0 20 0.621864 20 1.3681V10.5717C20 10.9448 19.6248 11.1936 19.1245 11.1936C18.6242 11.1936 18.249 10.8826 18.249 10.5717V3.10932C18.249 2.36308 17.6237 1.74122 16.8733 1.74122H9.30661C8.99393 1.74122 8.7438 1.3681 8.7438 0.87061C8.7438 0.373119 8.99393 0 9.30661 0H18.6242ZM4.16953 6.88407C3.4037 6.88407 2.89314 7.4764 2.89314 8.15336C2.89314 8.83031 3.48879 9.42264 4.16953 9.42264C4.85027 9.42264 5.44592 8.83031 5.44592 8.15336C5.44592 7.4764 4.85027 6.88407 4.16953 6.88407Z"
            fill={color}
        />
    </svg>
)

export const CaptionIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 17.7147C1.06025 14.7579 3.17267 8.85307 6.33735 0H7.54217C8.61848 2.95679 10.7791 8.86163 14.0241 17.7147H11.8795C11.5422 16.7983 10.8916 14.9741 9.92771 12.2421H3.95181C3.63052 13.1585 3.00402 14.9827 2.07229 17.7147H0ZM4.57831 10.3487H9.27711C8.8755 9.22478 8.08033 6.96832 6.89157 3.57925C6.50602 4.72047 5.73495 6.97693 4.57831 10.3487ZM22.2651 17.7147C22.249 17.5418 22.2169 17.1787 22.1687 16.6254C21.751 17.0749 21.2771 17.4121 20.747 17.6369C20.2169 17.879 19.6305 18 18.988 18C18.4739 18 17.9759 17.9222 17.494 17.7666C17.012 17.5937 16.5783 17.3429 16.1928 17.0144C15.8394 16.6859 15.5422 16.2709 15.3012 15.7695C15.0763 15.2853 14.9639 14.7147 14.9639 14.0576C14.9639 13.1412 15.1888 12.3977 15.6386 11.8271C16.0884 11.2392 16.6586 10.7723 17.3494 10.4265C18.0402 10.0807 18.8032 9.83862 19.6386 9.70029C20.49 9.56196 21.3092 9.49279 22.0964 9.49279V9.31124C22.0964 8.25648 21.8876 7.49568 21.4699 7.02882C21.0522 6.57925 20.4418 6.35447 19.6386 6.35447C19.1084 6.35447 18.6185 6.48415 18.1687 6.74352C17.7028 6.98559 17.3173 7.32276 17.012 7.75504C16.7068 7.53026 16.2651 7.19309 15.6867 6.74352C16.1526 6.05187 16.7149 5.54179 17.3735 5.21326C18.0161 4.88472 18.8353 4.72046 19.8313 4.72046C21.1486 4.72046 22.1687 5.08357 22.8916 5.8098C23.6305 6.53603 24 7.75503 24 9.46686V17.7147H22.2651ZM22.0964 11.1009C21.5502 11.1182 20.9719 11.1614 20.3614 11.2305C19.751 11.2997 19.1888 11.4467 18.6747 11.6715C18.1606 11.8617 17.7349 12.147 17.3976 12.5274C17.0602 12.9251 16.8916 13.4438 16.8916 14.0836C16.8916 14.4813 16.9639 14.8271 17.1084 15.121C17.253 15.3977 17.4377 15.6311 17.6627 15.8213C17.8876 16.0115 18.1365 16.1412 18.4096 16.2104C18.6827 16.2968 18.9478 16.3401 19.2048 16.3401C19.7831 16.3401 20.3132 16.2104 20.7952 15.951C21.2771 15.6916 21.7108 15.3891 22.0964 15.0432V11.1009Z"
            fill={color}
        />
    </svg>
)

export const CropIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.02 2.71997C11.02 1.89154 10.3484 1.21997 9.52 1.21997C8.69157 1.21997 8.02 1.89154 8.02 2.71997V8.69997L2.72 8.69997C1.89157 8.69997 1.22 9.37154 1.22 10.2C1.22 11.0284 1.89157 11.7 2.72 11.7L8.02 11.7V23.12C8.02 23.9484 8.69157 24.62 9.52 24.62H21.62V30.6C21.62 31.4284 22.2916 32.1 23.12 32.1C23.9484 32.1 24.62 31.4284 24.62 30.6V24.62H29.92C30.7484 24.62 31.42 23.9484 31.42 23.12C31.42 22.2915 30.7484 21.62 29.92 21.62H24.62L24.62 10.2C24.62 9.37154 23.9484 8.69997 23.12 8.69997L11.02 8.69997V2.71997ZM11.02 11.7V21.62H21.62L21.62 11.7L11.02 11.7Z"
            fill={color}
        />
    </svg>
)

export const EyeDropperIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.3498 2.35004C20.0777 1.07794 18.0539 1.03927 16.8295 2.26366L12.5704 6.52217C11.3255 5.98413 10.1978 5.9638 9.57542 6.58622C8.87711 7.28452 8.98785 8.61883 9.73103 10.041L5.2092 14.5638C4.27939 15.4936 3.66464 16.7007 3.43368 18.0531L3.42835 18.0879L1.5541 21.0451C1.31896 21.4161 1.38975 21.9303 1.71945 22.2932L1.76026 22.3361C2.12597 22.7018 2.66961 22.7857 3.05376 22.5357L5.97497 20.6346L6.00503 20.6301C7.31736 20.4208 8.50058 19.8433 9.42434 18.9614L9.52591 18.8622L14.1756 14.2127C15.3978 14.727 16.5007 14.7373 17.1136 14.1244C17.736 13.502 17.7157 12.3743 17.1777 11.1294L21.4362 6.87034C22.6606 5.64595 22.6219 3.62214 21.3498 2.35004ZM13.0501 13.615L8.6644 18.0007C7.84337 18.8217 6.76465 19.3482 5.56473 19.5134L5.4508 19.5291L2.89592 21.192L4.53002 18.6127L4.56784 18.3755C4.76263 17.2351 5.28326 16.2128 6.07071 15.4253L10.3942 11.1018C10.6549 11.4593 10.9524 11.8139 11.2838 12.1569L11.4122 12.2876L11.5429 12.4161C12.0303 12.8869 12.5408 13.2891 13.0501 13.615Z"
            fill={color}
        />
    </svg>
)

export const FillIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.8889 0H3.11111C1.39289 0 0 1.39289 0 3.11111V16.8889C0 18.6071 1.39289 20 3.11111 20H16.8889C18.6071 20 20 18.6071 20 16.8889V3.11111C20 1.39289 18.6071 0 16.8889 0Z"
            fill={color}
        />
    </svg>
)

export const FillOffIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 3.11111V16.8889C20 18.6071 18.6071 20 16.8889 20H3.11111C2.64676 20 2.20616 19.8983 1.8104 19.7159C2.07414 19.6564 2.32414 19.5241 2.52905 19.3192L19.3192 2.52905L19.4209 2.41653C19.5683 2.23573 19.6667 2.02824 19.716 1.81183C19.8984 2.20685 20 2.64712 20 3.11111ZM16.8889 0C17.1739 0 17.45 0.0383346 17.7123 0.110129C17.5344 0.181724 17.3681 0.289894 17.224 0.433916L0.433916 17.224L0.332217 17.3366C0.238034 17.452 0.163877 17.5784 0.109745 17.711C0.038246 17.4494 0 17.1736 0 16.8889V3.11111C0 1.39289 1.39289 0 3.11111 0H16.8889Z"
            fill={color}
        />
    </svg>
)

export const FilterIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 0C19.9706 0 24 4.02944 24 9C24 13.0007 21.3896 16.3917 17.7792 17.5627L17.6144 17.6144C16.4943 21.3099 13.0613 24 9 24C4.02944 24 0 19.9706 0 15C0 10.9387 2.69009 7.50568 6.38563 6.3856L6.43729 6.22084C7.60829 2.61039 10.9993 0 15 0ZM6.02329 8.34751L6.01116 8.3536C3.47787 9.49457 1.71429 12.0413 1.71429 15C1.71429 19.0238 4.97621 22.2857 9 22.2857C11.9635 22.2857 14.5138 20.5163 15.652 17.9764C15.437 17.9921 15.2194 18 15 18C11.7291 18 8.86576 16.2551 7.28998 13.6454L6.93067 13.2857L7.03071 13.1861C6.37251 11.9356 6 10.5113 6 9C6 8.78058 6.00785 8.56299 6.02329 8.34751ZM15.9217 12.7194L12.7194 15.9217C13.4368 16.1579 14.2035 16.2857 15 16.2857C15.4049 16.2857 15.802 16.2527 16.189 16.1892C16.2527 15.802 16.2857 15.4049 16.2857 15C16.2857 14.2035 16.1579 13.4368 15.9217 12.7194ZM15 1.71429C12.0365 1.71429 9.48621 3.48367 8.34799 6.02364C8.56299 6.00785 8.78058 6 9 6C13.9706 6 18 10.0294 18 15C18 15.2194 17.9921 15.437 17.9767 15.6525C20.5163 14.5138 22.2857 11.9635 22.2857 9C22.2857 4.97621 19.0238 1.71429 15 1.71429ZM13.4275 9.2134L9.2134 13.4275C9.72938 14.1008 10.3602 14.6815 11.0767 15.1402L15.1402 11.0767C14.6815 10.3602 14.1008 9.72938 13.4275 9.2134ZM9 7.71429C8.59512 7.71429 8.19795 7.74731 7.81105 7.81082C7.74731 8.19795 7.71429 8.59512 7.71429 9C7.71429 10.0313 7.92858 11.0126 8.31507 11.9018L11.9018 8.31507C11.0126 7.92858 10.0313 7.71429 9 7.71429Z"
            fill={color}
        />
    </svg>
)

export const FlashIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7685 0.376045L9.10541 10.1448L13.5642 12.7056L2.76967 23.0758L4.72085 14.3685L0.599591 11.4662L11.7685 0.376045Z"
            fill={color}
        />
    </svg>
)

export const FlipIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.23584 18.6709C0.23584 19.0172 0.35026 19.3172 0.579102 19.5708C0.814128 19.8244 1.16976 19.9512 1.646 19.9512H8.63184C9.17611 19.9512 9.57812 19.8182 9.83789 19.5522C10.0977 19.2925 10.2275 18.8905 10.2275 18.3462V7.92773C10.2275 7.64322 10.1657 7.40201 10.042 7.2041C9.91829 6.99999 9.75439 6.84846 9.55029 6.74951C9.34619 6.64436 9.12354 6.59179 8.88232 6.59179C8.64111 6.59179 8.40609 6.64746 8.17725 6.75878C7.95459 6.87011 7.76286 7.04947 7.60205 7.29687L0.541992 17.6968C0.436849 17.8514 0.359538 18.0122 0.310059 18.1792C0.260579 18.3462 0.23584 18.5101 0.23584 18.6709ZM11.2944 18.3462C11.2944 18.8905 11.4212 19.2925 11.6748 19.5522C11.9346 19.8182 12.3397 19.9512 12.8901 19.9512H19.8667C20.3491 19.9512 20.7048 19.8244 20.9336 19.5708C21.1686 19.3172 21.2861 19.0172 21.2861 18.6709C21.2861 18.5101 21.2614 18.3462 21.2119 18.1792C21.1624 18.0122 21.0882 17.8514 20.9893 17.6968L13.9106 7.29687C13.7498 7.04947 13.5581 6.87011 13.3354 6.75878C13.1128 6.64746 12.8778 6.59179 12.6304 6.59179C12.3953 6.59179 12.1758 6.64436 11.9717 6.74951C11.7676 6.84846 11.6037 6.99999 11.48 7.2041C11.3563 7.40201 11.2944 7.64322 11.2944 7.92773V18.3462ZM1.59961 3.14062L3.98389 4.91259C4.23128 5.10432 4.47559 5.1538 4.7168 5.06103C4.96419 4.96207 5.08789 4.75797 5.08789 4.44873V3.20556H16.4434V4.44873C16.4434 4.75797 16.564 4.96207 16.8052 5.06103C17.0464 5.1538 17.2907 5.10432 17.5381 4.91259L19.9224 3.14062C20.0832 3.01692 20.1636 2.85921 20.1636 2.66748C20.1636 2.46956 20.0832 2.31184 19.9224 2.19433L17.5381 0.403803C17.2969 0.218257 17.0526 0.17187 16.8052 0.264643C16.564 0.351232 16.4434 0.555333 16.4434 0.876948V2.10156H5.08789V0.876948C5.08789 0.561518 4.96419 0.357417 4.7168 0.264643C4.47559 0.165685 4.23128 0.212072 3.98389 0.403803L1.59961 2.19433C1.4388 2.31184 1.3584 2.46956 1.3584 2.66748C1.3584 2.85921 1.4388 3.01692 1.59961 3.14062Z"
            fill={color}
        />
    </svg>
)

export const FlipCameraIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.2531 0L22.9427 10.6598H13.6345L18.0137 5.80644C16.3477 4.24281 14.1087 3.28375 11.6477 3.28375C6.50668 3.28375 2.32485 7.46532 2.32485 12.6061C2.32485 17.7468 6.50668 21.9284 11.6477 21.9284C13.8386 21.9284 15.8513 21.1639 17.4435 19.8951L18.7715 21.4913C16.8188 23.0584 14.3452 24 11.6477 24C5.35479 24 0.253098 18.8986 0.253098 12.6061C0.253098 6.3135 5.35479 1.21212 11.6477 1.21212C14.6464 1.21212 17.372 2.37121 19.4043 4.26554L23.2531 0Z"
            fill={color}
        />
    </svg>
)

export const LayersIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.6158 7.60217C23.9587 7.79597 24.0908 8.20579 23.9354 8.54981L23.8944 8.6279L18.4751 17.6279C18.3518 17.8327 18.1369 17.9674 17.8972 17.9948L17.8064 18H6.96771C6.54013 18 6.19352 17.6642 6.19352 17.25C6.19352 16.8654 6.49238 16.5484 6.87742 16.505L6.96771 16.5H17.3612L22.557 7.8721C22.7724 7.51431 23.2464 7.39346 23.6158 7.60217ZM20.519 3.85217C20.862 4.04597 20.994 4.45579 20.8386 4.79981L20.7977 4.8779L15.3783 13.8779C15.255 14.0827 15.0401 14.2174 14.8005 14.2448L14.7096 14.25H3.87095C3.44337 14.25 3.09676 13.9142 3.09676 13.5C3.09676 13.1154 3.39563 12.7984 3.78066 12.755L3.87095 12.75H14.2644L19.4602 4.1221C19.6756 3.76431 20.1497 3.64346 20.519 3.85217ZM15.6896 0.000193795L15.8556 0.00284934C17.103 0.0434549 17.7733 0.547134 17.7733 1.33495C17.7733 1.68853 17.6292 2.12166 17.3316 2.59899L13.0395 9.44072L12.9576 9.56799C12.3625 10.471 11.8705 10.7136 10.6582 10.7136H2.07403L1.91141 10.711C0.687841 10.6718 0 10.184 0 9.38768C0 9.02527 0.153632 8.59213 0.451293 8.10596L4.73377 1.25539L4.81694 1.12699C5.39526 0.257159 5.90478 -0.00824386 7.10546 0.000193795H15.6896ZM15.6896 1.50019L6.79196 1.50118C6.47489 1.51033 6.37458 1.56176 6.20951 1.79956L6.11136 1.94862L1.77574 8.88298L1.7196 8.97822C1.68509 9.03915 1.65632 9.09482 1.63283 9.14438L1.6173 9.18002L1.749 9.19806C1.84108 9.20768 1.94961 9.2136 2.07403 9.2136L10.8846 9.21308C10.8846 9.21308 10.7781 9.22549 11.0602 9.2077C11.3423 9.18992 11.4233 9.11889 11.6058 8.83634L16.0084 1.81997L16.062 1.73037C16.095 1.67291 16.1224 1.62011 16.1448 1.57283L16.1612 1.53602L16.1064 1.52686C15.9976 1.51089 15.8584 1.50019 15.6896 1.50019Z"
            fill={color}
        />
    </svg>
)

export const MotionIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.50155 1.79102C9.70565 1.79102 9.87882 1.72298 10.0211 1.58691C10.1633 1.44466 10.2345 1.26839 10.2345 1.05811C10.2345 0.847819 10.1633 0.674642 10.0211 0.538574C9.87882 0.396322 9.70565 0.325195 9.50155 0.325195H3.08162L4.53817 1.79102H9.50155ZM11.9879 4.32373C12.192 4.32373 12.3652 4.2526 12.5074 4.11035C12.6558 3.9681 12.7301 3.79183 12.7301 3.58154C12.7301 3.37744 12.6558 3.20736 12.5074 3.07129C12.3652 2.92904 12.192 2.85791 11.9879 2.85791H5.55868L7.00594 4.32373H11.9879ZM14.4278 6.85645C14.6381 6.85645 14.8144 6.78532 14.9566 6.64307C15.0989 6.50081 15.17 6.32454 15.17 6.11426C15.17 5.91016 15.0989 5.74007 14.9566 5.604C14.8144 5.46175 14.6381 5.39062 14.4278 5.39062H8.03573L9.49227 6.85645H14.4278ZM16.9141 7.91406H10.5221C10.788 8.1862 10.9828 8.42741 11.1065 8.6377C11.2302 8.84798 11.3014 9.09538 11.3199 9.37988H16.9141C17.1182 9.37988 17.2914 9.30876 17.4337 9.1665C17.5759 9.02425 17.6471 8.85107 17.6471 8.64697C17.6471 8.43669 17.5759 8.26351 17.4337 8.12744C17.2914 7.98519 17.1182 7.91406 16.9141 7.91406ZM8.73153 16.5791L5.52157 9.10156L5.02059 10.0107L9.48299 10.2612C9.69946 10.2736 9.86955 10.221 9.99325 10.1035C10.1169 9.97982 10.1819 9.82829 10.1881 9.64893C10.2004 9.46956 10.1293 9.30257 9.97469 9.14795L1.21688 0.213867C1.08081 0.0716146 0.929281 0.000488281 0.762288 0.000488281C0.595296 -0.00569661 0.449951 0.046875 0.326253 0.158203C0.20874 0.269531 0.149984 0.427246 0.149984 0.631348L0.00154622 13.063C-0.0108236 13.2856 0.0510254 13.4588 0.187093 13.5825C0.323161 13.7 0.480876 13.7588 0.660238 13.7588C0.845784 13.7526 1.00968 13.666 1.15194 13.499L4.02791 10.3076L3.00741 10.0015L6.11532 17.6646C6.18335 17.8439 6.29159 17.9645 6.44002 18.0264C6.59465 18.0882 6.74927 18.0882 6.90389 18.0264L8.44393 17.3955C8.60474 17.3337 8.70988 17.2254 8.75936 17.0708C8.81502 16.9162 8.80575 16.7523 8.73153 16.5791Z"
            fill={color}
        />
    </svg>
)

export const PinIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 12.3549C0 13.1426 0.567686 13.6733 1.43231 13.6733H6.06114V17.6451C6.06114 18.7148 6.74236 20 7.00437 20C7.26638 20 7.9476 18.7148 7.9476 17.6451V13.6733H12.5677C13.4323 13.6733 14 13.1426 14 12.3549C14 10.539 12.4891 8.71476 10.0175 7.83582L9.72926 3.93864C11.0568 3.22554 12.0786 2.45439 12.524 1.91542C12.7686 1.60862 12.8908 1.29353 12.8908 1.01161C12.8908 0.422886 12.4192 0 11.7293 0H2.27948C1.58952 0 1.10917 0.422886 1.10917 1.01161C1.10917 1.29353 1.23144 1.60862 1.48472 1.91542C1.93013 2.45439 2.94323 3.22554 4.27074 3.93864L3.99127 7.83582C1.51092 8.71476 0 10.539 0 12.3549Z"
            fill={color}
        />
    </svg>
)

export const RotateIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.07692 11.3043L4.11923 14.2122C5.58513 16.6342 8.27363 18.2609 11.3428 18.2609C15.3695 18.2609 18.7404 15.4613 19.5555 11.7391H21.3588C20.5184 16.4265 16.3488 20 11.3428 20C7.78784 20 4.65434 18.1977 2.8358 15.474L0 18.2609V11.3043H7.07692ZM11.3428 0C14.9793 0 18.1748 1.88595 19.9728 4.71484L23 1.73913V8.69565H15.9231L18.6816 5.98431C17.2443 3.45419 14.4942 1.73913 11.3428 1.73913C7.31608 1.73913 3.94525 4.5387 3.13008 8.26087H1.32679C2.16718 3.57348 6.33681 0 11.3428 0Z"
            fill={color}
        />
    </svg>
)

export const ScissorsIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.7291 0.480911L4.73721 0.494353L10.9499 10.9795L17.5892 0.663657C17.8932 0.191283 18.5156 0.0594101 18.9793 0.369112L18.9902 0.376526L19.0625 0.426159C19.5179 0.739098 19.6419 1.36681 19.3407 1.83482L12.1507 13.0059L14.4727 16.9248C15.1794 16.0328 16.2615 15.4623 17.4743 15.4623H18.1448C20.274 15.4623 22 17.2206 22 19.3896V20.0727C22 22.2417 20.274 24 18.1448 24H17.4743C15.3451 24 13.619 22.2417 13.619 20.0727V19.3896C13.619 19.3044 13.6217 19.2198 13.627 19.136C13.408 19.0659 13.2117 18.9184 13.0804 18.7043C13.0777 18.6999 13.075 18.6954 13.0723 18.6909L10.8763 14.9853L8.60125 18.5216C8.52926 18.6334 8.43941 18.7262 8.33804 18.7985C8.36615 18.9915 8.38095 19.1888 8.38095 19.3896V20.0727C8.38095 22.2417 6.6549 24 4.52571 24H3.85524C1.72605 24 0 22.2417 0 20.0727V19.3896C0 17.2206 1.72605 15.4623 3.85524 15.4623H4.52571C5.61302 15.4623 6.59519 15.9208 7.29606 16.6584L9.67558 12.9589L2.94433 1.59841C2.66092 1.1201 2.80673 0.498607 3.27183 0.202544L3.34566 0.155545C3.81589 -0.143778 4.43527 0.00189298 4.7291 0.480911ZM4.52571 17.426H3.85524C2.79064 17.426 1.92762 18.3051 1.92762 19.3896V20.0727C1.92762 21.1572 2.79064 22.0363 3.85524 22.0363H4.52571C5.59031 22.0363 6.45333 21.1572 6.45333 20.0727V19.3896C6.45333 18.3051 5.59031 17.426 4.52571 17.426ZM18.1448 17.426H17.4743C16.4097 17.426 15.5467 18.3051 15.5467 19.3896V20.0727C15.5467 21.1572 16.4097 22.0363 17.4743 22.0363H18.1448C19.2094 22.0363 20.0724 21.1572 20.0724 20.0727V19.3896C20.0724 18.3051 19.2094 17.426 18.1448 17.426Z"
            fill={color}
        />
    </svg>
)

export const SendBackIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 10.8708C17 10.5316 16.8898 10.2528 16.6695 10.0342C16.4492 9.81572 16.1706 9.70646 15.8336 9.70646L12.422 9.70646V9.45205C12.422 8.29093 12.5402 7.2668 12.7767 6.37965C13.0132 5.4925 13.4085 4.71298 13.9625 4.0411C14.5166 3.36921 15.2699 2.7756 16.2224 2.26027C16.5334 2.09067 16.7376 1.89987 16.8348 1.68787C16.932 1.47586 16.9806 1.26223 16.9806 1.04697C16.9806 0.759947 16.8526 0.513698 16.5966 0.308218C16.3407 0.102739 15.9697 4.76837e-07 15.4837 4.76837e-07C13.7989 4.76837e-07 12.2843 0.187541 10.9397 0.562622C9.5951 0.937704 8.44978 1.51011 7.50372 2.27984C6.55765 3.04958 5.83514 4.02968 5.33619 5.22016C4.83724 6.41063 4.58776 7.82127 4.58776 9.45205V9.70646H1.20526C0.874786 9.70646 0.59129 9.81735 0.354774 10.0391C0.118258 10.2609 0 10.5414 0 10.8806C0 11.122 0.0518391 11.3389 0.155517 11.5313C0.259196 11.7237 0.434153 11.9472 0.680389 12.2016L7.46484 19.5205C7.63331 19.6967 7.80665 19.8206 7.98485 19.8924C8.16305 19.9641 8.33314 20 8.49514 20C8.65066 20 8.81913 19.9641 9.00057 19.8924C9.18201 19.8206 9.35697 19.6967 9.52544 19.5205L16.3682 12.2016C16.582 11.9667 16.7408 11.7449 16.8445 11.5362C16.9482 11.3275 17 11.1057 17 10.8708Z"
            fill={color}
        />
    </svg>
)

export const SendForwardIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M-1.45322e-07 9.12916C-1.45322e-07 9.46836 0.110158 9.74723 0.330474 9.96575C0.550791 10.1843 0.829427 10.2935 1.16638 10.2935L4.57804 10.2935V10.5479C4.57804 11.7091 4.45979 12.7332 4.22327 13.6204C3.98675 14.5075 3.59148 15.287 3.03745 15.9589C2.48342 16.6308 1.73013 17.2244 0.777588 17.7397C0.466553 17.9093 0.262436 18.1001 0.165238 18.3121C0.0680394 18.5241 0.0194395 18.7378 0.0194395 18.953C0.0194395 19.2401 0.147418 19.4863 0.403373 19.6918C0.659329 19.8973 1.0303 20 1.5163 20C3.20107 20 4.71574 19.8125 6.06032 19.4374C7.4049 19.0623 8.55022 18.4899 9.49628 17.7202C10.4423 16.9504 11.1649 15.9703 11.6638 14.7798C12.1628 13.5894 12.4122 12.1787 12.4122 10.5479V10.2935H15.7947C16.1252 10.2935 16.4087 10.1826 16.6452 9.96086C16.8817 9.73907 17 9.45858 17 9.11937C17 8.87802 16.9482 8.66112 16.8445 8.46869C16.7408 8.27626 16.5658 8.05284 16.3196 7.79843L9.53516 0.479452C9.36669 0.303327 9.19335 0.179387 9.01515 0.107632C8.83695 0.0358774 8.66686 0 8.50486 0C8.34934 0 8.18087 0.0358774 7.99943 0.107632C7.81799 0.179387 7.64303 0.303327 7.47456 0.479452L0.63179 7.79843C0.417953 8.03327 0.259195 8.25506 0.155517 8.4638C0.0518389 8.67254 -1.45322e-07 8.89433 -1.45322e-07 9.12916Z"
            fill={color}
        />
    </svg>
)

export const StrokeIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 0C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H17ZM17 3H3V17H17V3Z"
            fill={color}
        />
    </svg>
)

export const StrokeOffIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 0C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H17ZM16.9999 5.32954L5.32871 16.9999L17 17L16.9999 5.32954ZM15.558 2.99994L3 3L2.99994 15.558L15.558 2.99994Z"
            fill={color}
        />
    </svg>
)

export const TrashIcon = ({ color = '#fff', ...props }: IconProps) => (
    <svg {...props} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5879 2.5C17.7029 2.5 17.8003 2.45931 17.8802 2.37793C17.9601 2.29655 18 2.19727 18 2.08008V0.419921C18 0.302734 17.9601 0.20345 17.8802 0.12207C17.8003 0.0406898 17.7029 0 17.5879 0H0.412141C0.297125 0 0.199681 0.0406898 0.119808 0.12207C0.0399361 0.20345 0 0.302734 0 0.419921V2.08008C0 2.19727 0.0399361 2.29655 0.119808 2.37793C0.199681 2.45931 0.297125 2.5 0.412141 2.5H17.5879ZM14.722 20C14.8243 20 14.9153 19.9642 14.9952 19.8926C15.0751 19.821 15.1214 19.7331 15.1342 19.6289L16.361 4.16992H1.63898L2.86581 19.6289C2.87859 19.7331 2.92492 19.821 3.00479 19.8926C3.08466 19.9642 3.17572 20 3.27796 20H14.722Z"
            fill={color}
        />
    </svg>
)
