// search bar
export const CY_SEARCH_BAR__BUTTON = 'search-bar__button'
export const CY_SEARCH_BAR__TRENDING_SEARCH = 'search-bar__trending-search'
export const CY_SEARCH_BAR__CHANNELS = 'search-bar__channels'
export const CY_SEARCH_BAR__SUGGESTED_TERMS = 'search-bar__suggested-terms'

// trending feed
export const CY_TRENDING_FEED__CAROUSEL = 'trending-feed__carousel'
export const CY_TRENDING_FEED__GIF_CLASS = 'trending-feed__gif-class'
export const CY_TRENDING_FEED__GIF_DATA_ID = (uniqueID: string | number) => `trending-feed__gif-id--${uniqueID}`

// artist feed
export const CY_ARTIST_FEED__CAROUSEL = 'artist-feed__carousel'
export const CY_ARTIST_FEED__GIF_CLASS = 'artist-feed__gif-class'
export const CY_ARTIST_FEED__GIF_DATA_ID = (uniqueID: string | number) => `artist-feed__gif-id--${uniqueID}`

// trending clips feed
export const CY_TRENDING_CLIPS_FEED__CONTAINER = 'trending-clips-feed__container'
export const CY_TRENDING_CLIPS_FEED__MAIN_CLIP_CLASS = 'trending-clips-feed__main-clip-class'
export const CY_TRENDING_CLIPS_FEED__MAIN_CLIP_ID = 'trending-clips-feed__main-clip-class'
export const CY_TRENDING_CLIPS_FEED__SECONDARY_CLIP_CLASS = 'trending-clips-feed__secondary-clip-class'
export const CY_TRENDING_CLIPS_FEED__SECONDARY_CLIP_ID = 'trending-clips-feed__secondary-clip-class'
export const CY_TRENDING_CLIPS_FEED__TERTIARY_CLIP_CLASS = 'trending-clips-feed__tertiary-clip-class'
export const CY_TRENDING_CLIPS_FEED__TERTIARY_CLIP_ID = 'trending-clips-feed__tertiary-clip-class'

// stories
export const CY_STORIES__CONTAINER = 'stories__container'
export const CY_STORIES__STORY_THUMB_DATA_ID = (uniqueID: string) => `stories__story-thumb-id--${uniqueID}`

// gif detail
export const CY_GIF_DETAIL__GIF = 'gif-detail__gif'
export const CY_GIF_DETAIL__DOWNLOAD_BUTTON = 'gif-detail__download-button'

// curation station
export const CY_CURATION_STATION__CONTAINER = 'curation-station__container'
export const CY_CURATION_STATION__TOGGLE = 'curation-station__toggle'
export const CY_CURATION_STATION__FEED = 'curation-station__feed'
export const CY_CURATION_STATION__EDIT_PANEL = 'curation-station__edit-panel'

// user settings
export const CY_USER_SETTINGS__SAVE_BUTTON = 'user-settings__save'
export const CY_USER_SETTINGS__EMAIL_VALIDATION = 'user-settings__email-validation'

export const attributeName = 'tid'

// collections
export const CY_CREATE_COLLECTION__BUTTON = 'collection-create-collection__button'
export const CY_COLLECTION__SUCCESS_MESSAGE = 'collection-create-collection__message'

export const classes = {
    CY_CLIP_PREVIEW: `cy-clip-preview`,
}
