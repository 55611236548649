import { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

type PortalProps = {
    children?: ReactNode
    className?: string
    element?: string
}

const Portal = ({ children, className = 'root-portal', element = 'div' }: PortalProps) => {
    const [container] = useState(() => {
        const el = document.createElement(element)
        el.classList.add(className)
        return el
    })

    useEffect(() => {
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return ReactDOM.createPortal(children, container)
}

export default Portal
