import styled from 'styled-components'
import { titleFont } from '../../../shared/css/fonts'

const Container = styled.div`
    background: rgba(0, 0, 0, 0.4);
    color: giphyWhite;
    padding: 30px 20px 110px;
    position: relative;
    text-align: center;
    height: 140px;
`

const Copyright = styled.div`
    font-family: ${titleFont};
    margin-bottom: 8px;
`

const ToLinks = styled.p`
    font-size: 14px;
    margin: 0;

    a {
        display: inline-block;
        margin: 0 8px;
        opacity: 0.8;
    }
`

const MenuFooter = () => (
    <Container>
        <Copyright>&copy; {new Date().getFullYear()} GIPHY, Inc.</Copyright>
        <ToLinks>
            <a href="/terms" target="_blank">
                Terms of Service
            </a>
            <a href="/community-guidelines" target="_blank">
                Community Guidelines
            </a>
            <a href="/privacy" target="_blank">
                Privacy Policy
            </a>
            <a href="/dmca" target="_blank">
                Copyright
            </a>
            <span
                onClick={() => {
                    // @ts-ignore unless they can provide ts definitions
                    window.Didomi && window.Didomi.preferences.show()
                }}
            >
                Manage Cookies
            </span>
        </ToLinks>
    </Container>
)

export default MenuFooter
