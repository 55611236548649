import { useState } from 'react'
import { Access, ChannelAccess } from 'shared/contexts/accounts-manager'
import { LoggedInUser } from 'types'
import {
    AccessUserIcon,
    CheckMarkIcon,
    ChannelAccessAccountsNumber,
    Divider,
    NetworkChannelsContainer,
    NetworkChannelsContent,
    CaretDown,
    CaretUp,
} from './style'

type NetworkChannelItemProps = {
    access: Access
    channel_access: ChannelAccess
    user: LoggedInUser
}

const NetworkChannelItem = ({ access, channel_access, user }: NetworkChannelItemProps) => {
    return (
        <li key={access.id}>
            <a href={`${access.login_url}`}>
                <img src={access.channel.avatar_url} />
                <span>{access.channel.display_name}</span>
                {channel_access.emulated_user && user.channelId === access.channel.id && <CheckMarkIcon />}
            </a>
        </li>
    )
}

type NetworkChannelsProps = {
    channel_access: ChannelAccess
    user: LoggedInUser
}

const NetworkChannels = ({ channel_access, user }: NetworkChannelsProps) => {
    const [showAll, setShowAll] = useState<boolean>(false)

    return (
        <>
            <Divider />
            {channel_access.user && (
                <li>
                    <a href={`/api/v4/channels/access/logout`}>
                        <img src={channel_access.user.avatar_url} />
                        <span>{channel_access.user.display_name || channel_access.user.username}</span>
                        {!channel_access.emulated_user ? <CheckMarkIcon /> : <AccessUserIcon />}
                    </a>
                </li>
            )}
            {channel_access.access.length > 10 ? (
                <>
                    {showAll ? (
                        <NetworkChannelsContainer>
                            <NetworkChannelsContent>
                                {channel_access.access.map((access) => (
                                    <NetworkChannelItem access={access} channel_access={channel_access} user={user} />
                                ))}
                            </NetworkChannelsContent>
                        </NetworkChannelsContainer>
                    ) : (
                        <NetworkChannelItem
                            access={channel_access.access[0]}
                            channel_access={channel_access}
                            user={user}
                        />
                    )}
                    {showAll && <Divider />}
                    <li
                        onClick={() => {
                            setShowAll((prev) => !prev)
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <ChannelAccessAccountsNumber>{channel_access.access.length}</ChannelAccessAccountsNumber>
                            <span>{showAll ? 'Hide Accounts' : 'See All Accounts'}</span>
                            {showAll ? <CaretUp /> : <CaretDown />}
                        </a>
                    </li>
                </>
            ) : (
                channel_access.access.map((access) => (
                    <NetworkChannelItem access={access} channel_access={channel_access} user={user} />
                ))
            )}
        </>
    )
}

export default NetworkChannels
