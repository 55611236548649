import { createContext, ReactNode, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { colorRandomization } from '../add-to-collection/utils'

type CollectionStyleContextProps = {
    itemWidth: number | undefined
    showSearch: boolean
    selectedChannelIds: number[] | undefined
    showGifsBelow: boolean
}

type CollectionStyleContextManagerProps = {
    children: ReactNode
    channelsLength: number
}

type CollectionColorProps = {
    channelColors: (string | undefined)[]
    setChannelColors: Dispatch<SetStateAction<(string | undefined)[]>>
}

export const CollectionStyleContext = createContext({} as CollectionStyleContextProps & CollectionColorProps)

const CollectionStyleContextManager = ({
    itemWidth,
    showSearch,
    selectedChannelIds,
    showGifsBelow,
    children,
    channelsLength = 0,
}: CollectionStyleContextProps & CollectionStyleContextManagerProps) => {
    const [channelColors, setChannelColors] = useState<(string | undefined)[]>([])
    useEffect(() => {
        setChannelColors(colorRandomization(channelsLength + 1))
    }, [channelsLength])
    return (
        <CollectionStyleContext.Provider
            value={{
                itemWidth,
                showSearch,
                selectedChannelIds,
                showGifsBelow,
                channelColors,
                setChannelColors,
            }}
        >
            {children}
        </CollectionStyleContext.Provider>
    )
}

export default CollectionStyleContextManager
