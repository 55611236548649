import { useContext } from 'react'
import styled from 'styled-components'
import { WhenInView } from 'shared/util/in-view'
import { IMAGE_PATH } from '../constants'
import { AboutPageSticker } from '../style'
import LogoShapesAnimation from 'ui/src/components/logo-shapes-animation'
import { ANIMATION_SPEED } from '../constants'
import { AboutPageContext } from './../context'
import { mobile } from 'shared/util/media-query'
import HoverItem from '../info/hover-item'

enum GradientStrings {
    Pink = '-webkit-linear-gradient(-135deg, rgb(255, 102, 102) 0%, rgb(230, 70, 182) 100%);',
    Purple = 'linear-gradient(-135deg, rgb(153, 51, 255) 0%, rgb(97, 87, 255) 100%);',
    Orange = 'linear-gradient(45deg, rgb(255, 102, 102) 0%, rgb(255, 243, 92) 100%);',
    Teal = 'linear-gradient(-135deg, rgb(0, 255, 153) 0%, rgb(0, 204, 255) 100%);',
}

const STICKER_HEIGHT: number = 125

const Section = styled.section``

const StickerWrapper = styled.div`
    margin: -3.3em 0 -0.5em;
`

const Sticker = styled(AboutPageSticker)`
    height: ${STICKER_HEIGHT}px;
`

const PhilsophiesContainer = styled.div`
    width: 1224px;
    display: flex;
    flex-direction: column;
    margin: 92px auto 0;

    ${mobile.css`
        width: 100%;
        margin-top: 48px;
    `}
`

const IndividualPhilosophy = styled.div`
    height: 312px;
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    justify-content: space-between;

    img {
        object-fit: cover;
        width: 592px;
        height: 312px;
        border-radius: 8px;
    }

    ${mobile.css`
        flex-direction: column;
        height: auto;
        margin: 10px 0;
        img {
            height: 160px;
            width: 100%;

    `}
`

const ExplanationText = styled.div<{ gradient: GradientStrings }>`
    width: 592px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
        font-family: NexaBlack;
        font-size: 28px;
        font-weight: normal;
        background: ${(props) => props.gradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 0 20px;
    }

    p {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 22px;
        margin: 0;
    }

    ${mobile.css`
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        order: 2;
    `}
`

const Inner = styled.div`
    width: 450px;
    text-align: left;
    margin: auto;

    ${mobile.css`
    width: 100%;
    `}
`

const Header = styled.h1`
    margin-top: 22px;
    font-size: 46px;
`

const Subheader = styled.h3`
    font-size: 22px;
    font-weight: normal;
    height: 30px;
    letter-spacing: 0px;
    line-height: 30px;
    text-align: center;

    ${mobile.css`
        padding: 0 15px;
    `}
`

const AnimationWrapper = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
`

const StyledHoverItem = styled(HoverItem)`
    font-weight: bold;
`

const Philosophies = () => {
    const { windowWidth } = useContext(AboutPageContext)
    const animationHeight = 4300

    return (
        <Section id="philosophies-and-values">
            <AnimationWrapper>
                <WhenInView height={animationHeight}>
                    <LogoShapesAnimation width={windowWidth} height={animationHeight} speed={ANIMATION_SPEED * 1.5} />
                </WhenInView>
            </AnimationWrapper>
            <StickerWrapper>
                <WhenInView height={STICKER_HEIGHT}>
                    <Sticker src={`${IMAGE_PATH}/stickers/third-eye.gif`} alt="" />
                </WhenInView>
            </StickerWrapper>
            <Header>Our Philosophies</Header>
            <Subheader>
                This is what we believe. These philosophies guide our approach to GIPHY&apos;s community, culture, and
                products.
            </Subheader>
            <PhilsophiesContainer>
                <IndividualPhilosophy>
                    <img src={`${IMAGE_PATH}/philosophies/PeopleBeforeProduct.gif`} alt="" />
                    <ExplanationText gradient={GradientStrings.Pink}>
                        <Inner>
                            <h3>People Before Product</h3>
                            <p>
                                The most important product we can ever develop is our{' '}
                                <StyledHoverItem hoverId={'people'}>people</StyledHoverItem>. People make products,
                                people use products, we are a people company.
                            </p>
                        </Inner>
                    </ExplanationText>
                </IndividualPhilosophy>
                <IndividualPhilosophy>
                    <ExplanationText gradient={GradientStrings.Purple}>
                        <Inner>
                            <h3>Community Before Corporation</h3>
                            <p>
                                We win and lose together. We all make it together or we don&apos;t make it at all. Clear{' '}
                                <StyledHoverItem hoverId={'eyes'}>eyes</StyledHoverItem>, full{' '}
                                <StyledHoverItem hoverId={'hearts'}>hearts</StyledHoverItem>...
                            </p>
                        </Inner>
                    </ExplanationText>
                    <img src={`${IMAGE_PATH}/philosophies/Community.gif`} alt="" />
                </IndividualPhilosophy>
                <IndividualPhilosophy>
                    <img src={`${IMAGE_PATH}/philosophies/Relationships.gif`} alt="" />
                    <ExplanationText gradient={GradientStrings.Orange}>
                        <Inner>
                            <h3>Relationships Before Process</h3>
                            <p>
                                <StyledHoverItem hoverId={'relationships'}>Relationships</StyledHoverItem> are the root
                                of all processes. Our relationships will determine if our processes live or die.
                            </p>
                        </Inner>
                    </ExplanationText>
                </IndividualPhilosophy>
                <IndividualPhilosophy>
                    <ExplanationText gradient={GradientStrings.Teal}>
                        <Inner>
                            <h3>It&apos;s not what we can&apos;t do, it&apos;s what we can do.</h3>
                            <p>
                                Problems are easy. <StyledHoverItem hoverId={'solutions'}>Solutions</StyledHoverItem>{' '}
                                are hard. We like to solve problems and we choose to be GIPHYcans and not
                                GIPHYcan&apos;ts.
                            </p>
                        </Inner>
                    </ExplanationText>
                    <img src={`${IMAGE_PATH}/philosophies/CantDoCanDo.gif`} alt="" />
                </IndividualPhilosophy>
                <IndividualPhilosophy>
                    <img src={`${IMAGE_PATH}/philosophies/PostGIPHY.gif`} alt="" />
                    <ExplanationText gradient={GradientStrings.Pink}>
                        <Inner>
                            <h3>Post GIPHY</h3>
                            <p>
                                Our <StyledHoverItem hoverId={'post'}>promise</StyledHoverItem> to our employees as our
                                friends and partners in this journey is to make sure they leave GIPHY professionally
                                better than when they came to GIPHY and that they are left with a lifetime of positive
                                memories.
                            </p>
                            <br />
                            <p>
                                We have unlimited opportunity, creativity, and passion. Let&apos;s make sure we make the
                                time we have at GIPHY the most rewarding of our careers and lives.
                            </p>
                        </Inner>
                    </ExplanationText>
                </IndividualPhilosophy>
                <IndividualPhilosophy>
                    <ExplanationText gradient={GradientStrings.Purple}>
                        <Inner>
                            <h3>GIPHY for Good</h3>
                            <p>
                                GIPHY reaches hundreds of millions of people across the{' '}
                                <StyledHoverItem hoverId={'good'}>world</StyledHoverItem> every day. We have the
                                opportunity to meaningfully change user behavior by exposing our users to new ideas,
                                encouraging positivity, and actively fighting hate and injustice on the internet. We
                                have a notable impact on the world, and we take this responsibility seriously!
                            </p>
                        </Inner>
                    </ExplanationText>
                    <img src={`${IMAGE_PATH}/philosophies/GIPHYForGood.gif`} alt="" />
                </IndividualPhilosophy>
            </PhilsophiesContainer>
        </Section>
    )
}

export default Philosophies
