import { giphyAnalytics } from 'shared/api'
import log from 'shared/util/log'
import { getGADataLayerData } from 'analytics'
import normalizePageViewUrl from 'shared/util/normalize-page-view-urls'

declare global {
    interface Window {
        didomiOnReady: unknown[]
        didomiEventListeners: any[]
        gaCreated: boolean
        ga: Function
    }
}

export function trackPageView(trackingUrl: string, normalize: boolean = true) {
    if (!trackingUrl || !window.dataLayer) return

    if (normalize) {
        trackingUrl = normalizePageViewUrl(trackingUrl)
    }

    if (trackingUrl) {
        log.debug(`trackPageView virtualPageURL: ${trackingUrl}`)

        // Only fire pageview when didomi is ready
        window.didomiOnReady = window.didomiOnReady || []
        window.didomiOnReady?.push(function () {
            window.dataLayer?.push({
                event: 'VirtualPageview',
                virtualPageURL: trackingUrl,
                virtualPageName: trackingUrl,
                ...getGADataLayerData(),
            })
        })
    }
}

export const giphyAnalyticsEvent = async ({ shareType, gifId }) => {
    try {
        await giphyAnalytics(shareType, gifId)
    } catch (e) {
        log.error(`Error sending giphyAnalyticsEvent ${e}`)
    }
}

export default {
    trackPageView,
}
