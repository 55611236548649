import { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { getScrollTop } from 'shared/util/scrolling'
import Tag from './ellipsis-tag'

const NUMBER_OF_LINES = 2

const Container = styled.div<{ isTruncated: boolean }>`
    margin-bottom: 20px;
    text-align: left;
    ${({ isTruncated }) =>
        isTruncated &&
        css`
            overflow-y: hidden;
            max-height: 48px;
        `}
`

type Props = {
    className?: string
    tags: any
    isSticker: boolean
}

const Tags = ({ tags, isSticker }: Props) => {
    const [isTruncated, setIsTruncated] = useState<boolean>(false)
    const [maxHeight, setMaxHeight] = useState<number>(0)
    const [userToggled, setUserToggled] = useState<boolean>(false)
    const truncationContainer = useRef<HTMLDivElement>(null)

    const calculateMaxHeight = () => {
        const scrollTop = getScrollTop()
        const truncatedContainer = truncationContainer.current

        if (truncatedContainer) {
            const tagHeight = truncatedContainer.children[0]['offsetHeight']
            const bottomTruncatedContainer =
                truncatedContainer.getBoundingClientRect().top + tagHeight * NUMBER_OF_LINES // in case this jumps around the page
            if (truncatedContainer.clientHeight > tagHeight * NUMBER_OF_LINES + 10 && !userToggled && !isTruncated) {
                setIsTruncated(true)
                setMaxHeight(bottomTruncatedContainer + scrollTop)
            } else {
                setMaxHeight(bottomTruncatedContainer + scrollTop)
            }
        }
    }
    useEffect(() => {
        calculateMaxHeight()
    }, [userToggled])

    const toggleContainer = () => {
        setUserToggled(true)
        setIsTruncated(false)
    }
    return (
        <Container isTruncated={isTruncated} ref={truncationContainer}>
            {tags.map((tag: any, index: number) => (
                <Tag
                    userToggled={userToggled}
                    name={tag}
                    isSticker={isSticker}
                    key={index}
                    maxHeight={maxHeight}
                    toggleContainer={() => toggleContainer()}
                />
            ))}
        </Container>
    )
}

export default Tags
