import { giphyBlack, giphyGreen, giphyLightestGrey } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { Gif } from '@giphy/react-components'
import { Attribution } from '@giphy/react-giphy-brand'
import { useContext, useEffect, useState } from 'react'
import InstagramModal from 'shared/components/instagram-share/modal'
import ModalContext from 'shared/contexts/modal'
import useWindowSize from 'shared/hooks/window-size'
import styled, { ThemeProvider } from 'styled-components'
import { Clipboard } from 'ts-clipboard'
import Overlay from '../../shared/overlay'
import ShareModal from '../gif-detail/primary/share/share-modal'

type AttributionPanelProps = {
    active?: boolean
}

const Container = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    user-select: none;
`

const GifContainer = styled.div`
    border-radius: 15px;
    position: absolute;
    width: 95%;
    background-color: ${giphyBlack};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;

    img {
        object-fit: contain;
    }
`

const AttributionHeader = styled.div`
    padding: 12px 16px;
`

const ActionsTray = styled.div`
    display: flex;
    flex-direction: row;
    padding: 21px 50px;
    justify-content: space-between;
    font-family: interface;
    font-weight: bold;
    font-size: 18px;
    color: ${giphyLightestGrey};
`

const Action = styled.div<AttributionPanelProps>`
    display: flex;
    flex-direction: row;
    color: ${(props) => (props.active ? giphyGreen : giphyLightestGrey)};
    div {
        margin-right: 10px;
        position: relative;
        top: 1px;
    }

    a {
        display: flex;
        flex-direction: row;
        color: inherit;
    }
`

type Props = {
    gif: IGif
}
const AttributionPanel = ({ gif }: Props) => {
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)
    const [shareModalActive, setShareModalActive] = useState<boolean>(false)
    const [instagramModalActive, setInstagramModalActive] = useState<boolean>(false)
    const { innerWidth } = useWindowSize()
    const { openMobileActionsPanel } = useContext(ModalContext)

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = ''
        }
    }, [])

    const deactivateModal = () => {
        setIsLinkCopied(false)
        openMobileActionsPanel(undefined)
    }

    const copyToClipboard = (url) => {
        Clipboard.copy(url)
        setIsLinkCopied(true)
    }

    const onContextMenu = (e) => {
        e?.preventDefault()
    }

    return (
        <Container
            onClick={() => {
                !shareModalActive && deactivateModal()
            }}
        >
            {!shareModalActive ? (
                <GifContainer onContextMenu={onContextMenu} onClick={(e) => e.stopPropagation()}>
                    <>
                        {/* Only display attribution if a user exists. NOTE: Sometimes users are null and sometimes they are empty objects. */}
                        {Object.keys(gif.user || {}).length > 0 && (
                            <AttributionHeader>
                                <Attribution user={gif.user} />
                            </AttributionHeader>
                        )}
                        <Gif
                            gif={gif}
                            backgroundColor={'transparent'}
                            hideAttribution
                            width={Math.floor(innerWidth * 0.95)}
                            onGifRightClick={onContextMenu}
                        />
                        <ActionsTray>
                            <Action onClick={() => setShareModalActive(true)}>
                                <div className="ss-send" />
                                <span>Share GIF</span>
                            </Action>
                            <Action active={isLinkCopied} onClick={() => copyToClipboard(gif.url)}>
                                <div className="ss-link" />
                                <span>{isLinkCopied ? 'Copied!' : 'Copy Link'}</span>
                            </Action>
                        </ActionsTray>
                    </>
                </GifContainer>
            ) : (
                <ThemeProvider
                    theme={{
                        headerHeight: 60,
                        closeMarginLeft: 12,
                        backgroundOpacity: 0.1,
                    }}
                >
                    <Overlay closeOverlay={() => setShareModalActive(false)} hideCancel={undefined}>
                        <ShareModal
                            gif={gif}
                            slug={gif.slug}
                            url={gif.url}
                            rendition="original"
                            isGif={true}
                            setInstagramVisibleAction={setInstagramModalActive}
                        />
                        {instagramModalActive && (
                            <InstagramModal
                                isMobile
                                gifId={gif.id}
                                appLink={null}
                                onClose={() => setInstagramModalActive(false)}
                            />
                        )}
                    </Overlay>
                </ThemeProvider>
            )}
        </Container>
    )
}

export default AttributionPanel
