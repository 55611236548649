import { giphyDarkCharcoal, giphyLightGrey } from '@giphy/colors'
import { uniqueId } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { ControlSize } from 'types'
import { Container, Item } from './style'

type Props = {
    activeColor?: string
    activeBorderColor?: string
    className?: string
    color?: string
    defaultIndex?: number
    disabled?: boolean
    dividerColor?: string
    index?: number
    labels: string[]
    size?: ControlSize
    onChange?: (index: number) => void
}

const Tabs = ({
    activeColor = '#fff',
    activeBorderColor = activeColor,
    className,
    color = giphyLightGrey,
    defaultIndex = 0,
    disabled = false,
    dividerColor = giphyDarkCharcoal,
    index: indexProp,
    labels,
    size = 'small',
    onChange,
}: Props) => {
    const idRef = useRef<string>(uniqueId('tab-control'))
    const initialIndexRef = useRef<number | undefined>(defaultIndex)
    const [activeIndex, setActiveIndex] = useState<number>(defaultIndex)

    useEffect(() => {
        if (!disabled && activeIndex !== initialIndexRef.current) {
            initialIndexRef.current = undefined
            onChange?.(activeIndex)
        }
    }, [activeIndex, disabled])

    useEffect(() => {
        if (typeof indexProp === 'number' && indexProp !== activeIndex) {
            setActiveIndex(indexProp)
        }
    }, [indexProp])

    return (
        <Container
            $disabled={disabled || labels.length < 2}
            $dividerColor={dividerColor}
            $size={size}
            className={className}
        >
            {labels.map((label, i) => (
                <Item
                    $active={!disabled && i === activeIndex}
                    $activeColor={activeColor}
                    $activeBorderColor={activeBorderColor}
                    $color={color}
                    key={`${idRef.current}-${i}`}
                    tabIndex={disabled || labels.length < 2 ? -1 : undefined}
                    onClick={() => setActiveIndex(i)}
                >
                    <span>{label}</span>
                </Item>
            ))}
        </Container>
    )
}

export default Tabs
