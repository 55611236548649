import styled from 'styled-components'
import Dropdown from '../ellipsis-dropdown'
import { giphyWhite, giphyLightCharcoal } from '../../css/colors'

const Container = styled.div`
    color: ${giphyWhite};
    height: 100%;
    line-height: 42px;
    padding: 0 10px;
`

const linkStyles = `
color: ${giphyLightCharcoal};
display: block;
line-height: 30px;
text-decoration: none;

&:hover {
    color: ${giphyLightCharcoal};
}
`

const Link = styled.a`
    ${linkStyles}
`

const LinkDiv = styled.div`
    cursor: pointer;
    ${linkStyles}
`

const StoryItemMenu = ({ story = {}, onEditStory, onRemoveStory, onPrivacyClick }) => {
    const { slug, story_id, is_private: isPrivate } = story
    const onClickLinkDiv = () => onPrivacyClick(story)
    return (
        <Container>
            <Dropdown extendDirectionUp isDark>
                <>
                    <Link href={`/stories/${slug}`} rel="noopener noreferrer" target="_blank">
                        Open story
                    </Link>
                    <Link
                        href={`/create/story/finalize/${story_id}`}
                        onClick={() => onEditStory(story)}
                        target="_blank"
                    >
                        Edit story
                    </Link>
                    <LinkDiv onClick={onClickLinkDiv}>{isPrivate ? 'Make Story Public' : 'Make Story Private'}</LinkDiv>
                    <Link href="#" onClick={() => onRemoveStory(story)}>
                        Remove story
                    </Link>
                </>
            </Dropdown>
        </Container>
    )
}

export default StoryItemMenu
