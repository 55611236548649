import * as ContentTypesIcons from './content-types'
import * as CreationIcons from './creation'
import * as NavigationIcons from './navigation'
import * as PlayerIcons from './player'
import * as UIIcons from './ui'
import * as UtilityIcons from './utility'

export default {
    ...CreationIcons,
    ...ContentTypesIcons,
    ...NavigationIcons,
    ...PlayerIcons,
    ...UIIcons,
    ...UtilityIcons
}