import { useEffect, useState } from 'react'
import { Coordinates } from 'types'
import { TooltipDirection } from '../../../tooltip'

export function useDropdownCoordinates(triggerRect: DOMRect | null, direction: TooltipDirection) {
    const [state, setState] = useState<Coordinates | null>(null)

    useEffect(() => {
        if (!triggerRect) return

        const { bottom, left, top, width } = triggerRect
        const midX = Math.round(left + width / 2)

        const scroll = {
            x: document.scrollingElement?.scrollLeft || document.body.scrollLeft,
            y: document.scrollingElement?.scrollTop || document.body.scrollTop,
        }

        if (direction === 'up') {
            setState({
                x: midX + scroll.x,
                y: top + scroll.y,
            })
        } else {
            setState({
                x: midX + scroll.x,
                y: bottom + scroll.y,
            })
        }
    }, [direction, triggerRect])

    return state
}
