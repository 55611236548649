import { uniq, without } from 'lodash'
import * as React from 'react'
import { FC, useCallback, useRef, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { addFavoritesByUser } from 'shared/api/favorites'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import useSameObjectRef from 'shared/hooks/use-same-ref'
import { LoggedInUser } from 'types'
import UserContext from './user'

type Props = {
    user: LoggedInUser
    isLoggedIn: boolean
    children?: React.ReactNode
}

const UserManager: FC<Props> = ({ isLoggedIn, user, children }) => {
    const [userFavorites, setUserFavorites] = useState<(string | number)[]>([])
    const [localFavorites, setLocalFavorites] = useLocalStorage<(string | number)[]>('local-favorites', [])
    const hasSyncedLocalFavorites = useRef(false)

    const addFavorite = useCallback(
        (id) => {
            if (!isLoggedIn) {
                if (localFavorites?.indexOf(id) === -1) {
                    setLocalFavorites([id, ...localFavorites])
                }
            }
        },
        [userFavorites, localFavorites]
    )
    const removeFavorite = useCallback(
        (id) => {
            if (!isLoggedIn) {
                setLocalFavorites(without(localFavorites, id))
            }
        },
        [userFavorites]
    )
    const userValue = useSameObjectRef({
        user,
        isLoggedIn,
        favorites: isLoggedIn ? userFavorites : localFavorites,
        localFavorites,
        addFavorite,
        removeFavorite,
    })
    // if a user wasn't logged in,
    // and added favorites, they will have local favorites
    useAsyncEffect(async () => {
        const failedSync: (string | number)[] = []

        if (
            localFavorites &&
            !hasSyncedLocalFavorites.current &&
            localFavorites.length > 0 &&
            isLoggedIn &&
            user?.userToken
        ) {
            hasSyncedLocalFavorites.current = true
            await Promise.all(
                localFavorites.map(async (id) => {
                    // shoot off a bunch of requests for now.
                    // multiple gif ids will be supported eventually
                    // see https://giphypedia.atlassian.net/browse/API-2807
                    try {
                        await addFavoritesByUser(user.userToken, id)
                    } catch (_) {
                        failedSync.push(id)
                    }
                })
            )
            // include in our favorites state
            setUserFavorites(uniq([...userFavorites, ...localFavorites]))
            // remove
            setLocalFavorites(failedSync)
        }
    }, [localFavorites, userFavorites, isLoggedIn, user?.userToken])

    return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
}

export default UserManager
