import styled, { keyframes } from 'styled-components'
import {
    giphyBlack,
    giphyPurple,
    giphyBlue,
    giphyGreen,
    giphyYellow,
    giphyRed,
    giphyPink,
} from '../../../../css/colors'

const jazzy = keyframes`
    0% { fill: ${giphyPurple}; }
    16% { fill: ${giphyBlue}; }
    32% { fill: ${giphyGreen}; }
    48% { fill: ${giphyYellow}; }
    64% { fill: ${giphyRed}; }
    80% { fill: ${giphyPink};}
    100% { fill: ${giphyPurple}; }
`

const PlaySVG = styled.svg`
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.33);
    display: block;
    position: relative;
    transition: box-shadow 0.2s ease-out;

    path {
        transition: all 0.2s ease-out;
    }

    &:hover {
        box-shadow: 0 0 0 4px ${giphyBlack};

        path[data-playbackground] {
            fill: ${giphyBlack};
        }

        path[data-playicon] {
            animation: ${jazzy} 1s linear 0s infinite;
            opacity: 1;
        }
    }
`
export const PlayPauseSize = 38
export const PlayIcon = ({ size = PlayPauseSize }) => (
    <PlaySVG height={size} viewBox="0 0 45 45" width={size} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M0 0h45v45H0V0zm15.836 10.842a.804.804 0 0 0-.417.12l-.024 23.75c.107.087.244.13.41.13.11 0 5.99-3.787 17.639-11.361a.389.389 0 0 0 .161-.311.389.389 0 0 0-.161-.31L16.24 10.961a.782.782 0 0 0-.404-.12z"
                data-playbackground
                fill="#FFF"
                fillRule="nonzero"
            />
            <path
                d="M15.836 10.842c.135 0 .27.04.404.12L33.444 22.86c.107.087.161.19.161.311 0 .12-.054.224-.161.31-11.649 7.575-17.528 11.362-17.64 11.362a.632.632 0 0 1-.41-.13l.025-23.75a.804.804 0 0 1 .417-.12z"
                data-playicon
                fill="#121212"
                opacity=".5"
            />
        </g>
    </PlaySVG>
)

export const PauseIcon = ({ size = PlayPauseSize }) => (
    <svg height={size} viewBox="0 0 50 50" width={size} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path d="m0 0h50v50h-50z" />
            <g fill="#fff" transform="translate(10 10)">
                <path d="m.208696 29.208696v-28c0-.55228475.44771525-1 1-1h9.25c.5522847 0 1 .44771525 1 1v28c0 .5522847-.4477153 1-1 1h-9.25c-.55228475 0-1-.4477153-1-1z" />
                <path d="m18.958696 29.208696v-28c0-.55228475.4477153-1 1-1h9.25c.5522847 0 1 .44771525 1 1v28c0 .5522847-.4477153 1-1 1h-9.25c-.5522847 0-1-.4477153-1-1z" />
            </g>
        </g>
    </svg>
)
