import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Toggle from '../../../../../site/creation-tools/components/toggle'

const Label = styled.span`
    font-weight: bold;
    margin-right: 10px;
    font-size: 15px;
`
const Container = styled.div<{ isEditPanel: boolean }>`
    display: flex;
    align-items: center;
    margin-top: -20px;
    ${(props) =>
        props.isEditPanel &&
        css`
            margin-bottom: 25px;
        `}
`
const ToggleContainer = styled.div`
    width: 25%;
`

type Props = {
    onToggle: () => void
    isOn: boolean
    isEditPanel?: boolean
}

const CTA = ({ isOn, onToggle, isEditPanel = false }: Props) => {
    useEffect(() => {
        setToggleOn(isOn)
    }, [isOn])
    const [toggleOn, setToggleOn] = useState<boolean>(false)

    const toggle = (isOn: boolean) => {
        setToggleOn(!isOn)
        onToggle()
    }

    return (
        <Container isEditPanel={isEditPanel}>
            <Label>Show as Button:</Label>
            <ToggleContainer>
                <Toggle
                    reverseOrder={true}
                    onLabel="On"
                    offLabel="Off"
                    isOn={!toggleOn}
                    onToggle={() => toggle(toggleOn)}
                />
            </ToggleContainer>
        </Container>
    )
}

export default CTA
