import styled, { ThemeProvider, css } from 'styled-components'
import { PureComponent } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import Gif from '../gif/media'
import Footer from './footer'
import ProgressBar from './progress-bar'
import Avatar from '../avatar'
import Icon from './icon'
import Truncate from 'react-truncate'
import { giphyWhite, giphyBlack } from '../../css/colors'
import { Subheader } from '../typography'
import { getIconData } from '../../util/story-utils'
import hexToRgba from 'hex-rgba'

const padding = `10px`
const LARGE_CARD_HEIGHT = 280
const SLIM_CARD_HEIGHT = 112

const iconSize = css`
    width: 36px;
    height: 36px;
`
const iconSizeLarge = css`
    width: 50px;
    height: 50px;
`
const regularIconTheme = css`
    ${iconSize};
    font-size: 17px;
    line-height: 40px;
`

const largeIconTheme = css`
    ${iconSizeLarge};
    font-size: 22px;
    line-height: 54px;
`

const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: ${(props) => (props.hasWatched ? 0.5 : 1)};
    text-shadow: 0 2px ${padding} rgba(0, 0, 0, 0.25);
`

const titleCss = css`
    color: ${giphyWhite};
    line-height: 1.2;
    user-select: none;
`

const SmallTitle = styled(Subheader)`
    ${titleCss};
`
const RegTitle = styled(Subheader)`
    ${titleCss};
    width: 75%;
`

const gradCss = css`
    content: '';
    z-index: -1;
    top: -40px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: -${padding};
    position: absolute;
    transition: opacity 150ms linear;
`

const GradientContainer = styled.div`
    position: relative;
    width: 100%;
    &:after {
        ${gradCss};
        background: linear-gradient(rgba(0, 0, 0, 0), ${(props) => props.theme.color} 100%);
        opacity: ${(props) => (props.theme.isMouseOver ? 1 : 0)};
    }
    &:before {
        background: linear-gradient(rgba(0, 0, 0, 0), ${hexToRgba(giphyBlack, 60)});
        ${gradCss};
        opacity: ${(props) => (props.theme.isMouseOver ? 0 : 1)};
    }
`

const ContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background-color: ${(props) => props.theme.color};
`

const Content = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${padding};
    flex: 1;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 0;
`
const GifContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    ${(props) =>
        props.isSticker &&
        css`
            background-color: ${giphyBlack};
            background-image: linear-gradient(45deg, #222 25%, transparent 25%, transparent 75%, #222 75%, #222 100%),
                linear-gradient(45deg, #222 25%, transparent 25%, transparent 75%, #222 75%, #222 100%);
            background-position: 0 0, 19px 19px;
            background-size: 38px 38px;

            img {
                object-fit: contain;
            }
        `};
`

const GifInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    transition: transform 150ms linear;
    transform: scale(${(props) => (props.theme.isMouseOver ? 1.15 : 1)});
`

const AvatarScaler = styled.div`
    overflow: hidden;
    ${({ theme: { isLargeCard } }) => (isLargeCard ? iconSizeLarge : iconSize)};
    ${Avatar} {
        ${({ theme: { iconTheme } }) => iconTheme};
        transition: transform 150ms linear;
        transform: scale(${(props) => (props.theme.isMouseOver ? 1.2 : 1.0)});
    }
`
const AvatarFlex = styled.div`
    flex: 1;
`

const Cascade = styled.div`
    background: ${(props) => props.theme.color};
`
const Cascade1 = styled(Cascade)`
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);
    height: 3px;
    opacity: 0.8;
`
const Cascade2 = styled(Cascade)`
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);
    height: 6px;
    margin: 0 6px;
    opacity: 0.6;
`
const Cascade3 = styled(Cascade)`
    height: 6px;
    margin: 0 12px;
    opacity: 0.4;
`
class StoryPreview extends PureComponent {
    state = {}
    static propTypes = {
        autoPlay: PropTypes.bool,
        hasWatched: PropTypes.bool,
        rendition: PropTypes.string,
        hideIcons: PropTypes.bool,
        timestampText: PropTypes.string,
        showUsername: PropTypes.bool,
        onClick: PropTypes.func,
        width: PropTypes.number,
        height: PropTypes.number,
        story: PropTypes.object,
        hideCascade: PropTypes.bool,
        hideAvatar: PropTypes.bool,
    }

    onMouseOver = () => this.setState({ isMouseOver: true })
    onMouseOut = () => this.setState({ isMouseOver: false })
    render() {
        const {
            autoPlay: isAnimating,
            hasWatched,
            onClick = () => {},
            width,
            height,
            hideIcons,
            hideCascade,
            hideAvatar,
            rendition,
            timestampText,
            showUsername,
            children,
            numberOfLines = false,
            story: {
                story_id,
                title,
                cover_gif: coverGif,
                tags = [],
                user = {},
                publish_datetime: publishDateTime,
                is_private: isPrivate,
                color,
            },
        } = this.props
        const { gif } = coverGif || {}
        const isAd = tags.indexOf('ad') !== -1
        const { isMouseOver } = this.state
        const isLargeCard = height >= LARGE_CARD_HEIGHT
        const isSlimCard = height <= SLIM_CARD_HEIGHT
        const iconTheme = isLargeCard ? largeIconTheme : regularIconTheme
        const Title = isLargeCard ? RegTitle : SmallTitle
        const iconData = getIconData({ publishDateTime, isPrivate, hasWatched, hideIcons })
        return (
            <ThemeProvider theme={{ color, isMouseOver, iconTheme, isLargeCard, isSlimCard }}>
                <Container
                    onClick={() => onClick(story_id)}
                    onMouseOut={this.onMouseOut}
                    onMouseOver={this.onMouseOver}
                    style={{ width, height }}
                >
                    <ContainerInner>
                        <GifContainer isSticker={gif && gif.is_sticker}>
                            <GifInnerContainer>
                                {gif && <Gif {...{ gif, rendition, width, height, isAnimating }} />}
                            </GifInnerContainer>
                        </GifContainer>
                        <Content>
                            <ProgressBar />
                            {!isSlimCard && !hideAvatar && isEmpty(iconData) && user.is_public && (
                                <AvatarFlex>
                                    <AvatarScaler>
                                        <Avatar avatar={user.avatar || user.avatar_url} />
                                    </AvatarScaler>
                                </AvatarFlex>
                            )}
                            <GradientContainer>
                                <Title>
                                    <Truncate lines={isSlimCard ? 3 : numberOfLines} trimWhitespace>
                                        {title}
                                    </Truncate>
                                </Title>
                                {children || (
                                    <Footer
                                        {...{
                                            timestampText,
                                            isAd,
                                            publishDateTime,
                                            user: showUsername && user.username ? user : null,
                                        }}
                                    />
                                )}
                            </GradientContainer>
                        </Content>
                        {!isSlimCard && <Icon {...iconData} />}
                    </ContainerInner>
                    {!hideCascade && (
                        <>
                            <Cascade1 />
                            <Cascade2 />
                            <Cascade3 />
                        </>
                    )}
                </Container>
            </ThemeProvider>
        )
    }
}

export default StoryPreview
