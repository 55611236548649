import styled from 'styled-components'
import IconButton from '../../icon-button'

export const Stepper = styled.div`
    border-radius: inherit;
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
`

export const Button = styled(IconButton)`
    height: 100%;
    padding: 0 0.75em;
    width: 100%;
`
