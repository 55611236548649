// convert a url to relative
import parser from 'url-parse'

export const parseUrl = (url) => parser(url)

export const relativeUrl = (url) => `/${url.replace(/^(?:\/\/|[^\/]+)*\//, '')}`

export const removeProtocol = (url, pretty = false) => `${pretty ? '' : '//'}${url.replace(/.*?:\/\//g, '')}`

export const addProtocol = (url, type = 'http') => (url.match(/^(http|https):\/\//) ? url : `${type}://${url}`)

export const getFavicon = (url) => {
    return `https://www.google.com/s2/favicons?domain=${url}`
}

export const getSlug = (url) => {
    const trimmed = url.replace(/\/$/, '')
    const bits = trimmed.split('/')
    return bits[bits.length - 1]
}
const alterPaths = ['/channel_assets/', '/avatars/']
export const convertToImagingService = (url: string, size: `80h` | `200h` | `200w` = `200h`): string => {
    // only alter urls with these pathnames
    if (alterPaths.some((path) => url.indexOf(path) !== -1)) {
        const ext = url?.split('.')?.pop()?.toLowerCase()
        let existing
        ;['/80h', '/200h', '200w'].forEach((s) => {
            if (url.indexOf(s) !== -1) {
                existing = url.replace(`${s}.${ext}`, `/${size}.${ext}`)
            }
        })
        const result = existing || url.replace(`.${ext}`, `/${size}.${ext}`)
        return result
    }
    return url
}

export const getGifId = (url) => {
    const isFile = url.split('/').pop().indexOf('.') > -1
    const slashes = url.split('/')
    const gifId = isFile ? slashes[slashes.length - 2] : slashes.pop().split('-').pop()

    return gifId || ''
}

export default {
    relativeUrl,
    removeProtocol,
    addProtocol,
    getGifId,
}
