const UserIcon = () => (
    <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path
                d="M23.143 23.443a9.696 9.696 0 0 1-4.31 1.002 9.686 9.686 0 0 1-4.548-1.124A7.87 7.87 0 0 0 10.8 29h15.65a7.868 7.868 0 0 0-3.307-5.557zM28 29.894v.606H9.25v-.606a9.374 9.374 0 0 1 5.04-8.315 8.152 8.152 0 0 0 4.543 1.366 8.16 8.16 0 0 0 4.354-1.243A9.372 9.372 0 0 1 28 29.894zM19.071 19.33c2.713 0 4.911-2.2 4.911-4.915a4.913 4.913 0 0 0-4.91-4.915 4.912 4.912 0 0 0-4.911 4.915 4.913 4.913 0 0 0 4.91 4.915zm0 1.5a6.413 6.413 0 0 1-6.41-6.415A6.413 6.413 0 0 1 19.07 8a6.413 6.413 0 0 1 6.411 6.415 6.413 6.413 0 0 1-6.41 6.415z"
                id="usericonpath"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h39v39H0z" />
            <use fill="#FFF35C" fillRule="nonzero" xlinkHref="#usericonpath" />
        </g>
    </svg>
)

export default UserIcon
