import styled, { css } from 'styled-components'
import { ControlSize, Gradient } from 'types'
import { borderRadiusFromControlSize, fontSizeFromControlSize, gradientToCss, heightFromControlSize } from 'utils'
import IconButton from '../icon-button'

export const Container = styled.div<{
    $clickable: boolean
    $color: string
    $gradient?: Gradient
    $hasThumbnail: boolean
    $removeable: boolean
    $round: boolean
    $size: ControlSize
    $textColor: string
}>`
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    line-height: normal;

    ${({ $color, $gradient, $hasThumbnail, $removeable, $round, $size, $textColor }) => css`
        background: ${$gradient ? gradientToCss($gradient) : $color};
        border-radius: ${borderRadiusFromControlSize($size, $round)}px;
        color: ${$textColor};
        font-size: ${fontSizeFromControlSize($size)}px;
        height: ${heightFromControlSize($size)}px;
        min-width: ${heightFromControlSize($size)}px;
        padding-left: ${$hasThumbnail ? 0 : '0.8em'};
        padding-right: ${$removeable ? 0 : '0.8em'};
    `}

    ${({ $clickable }) =>
        $clickable &&
        css`
            cursor: pointer;
            user-select: none;
        `}
`

export const Label = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`

export const Remove = styled(IconButton)`
    border-radius: inherit;
    color: inherit;

    svg {
        pointer-events: none;
    }
`

export const Thumbnail = styled.img<{ $size: ControlSize }>`
    border-radius: inherit;
    flex-shrink: 0;
    margin: 0 0.6em 0 0;
    object-fit: contain;

    ${({ $size }) => css`
        height: ${heightFromControlSize($size)}px;
        width: ${heightFromControlSize($size)}px;
    `}
`
