import styled, { css, keyframes } from 'styled-components'

const Z_INDEX = 2147483637

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

export const Container = styled.div`
    align-items: center;
    display: flex;
    inset: 0;
    justify-content: center;
    position: fixed;
    z-index: ${Z_INDEX - 1};

    > div:last-child {
        animation: ${fadeIn} 0.15s ease-out;
    }
`

export const Dim = styled.div<{ $blurred: boolean }>`
    background: rgba(0, 0, 0, 0.5);
    inset: 0;
    position: absolute;

    ${({ $blurred }) =>
        $blurred &&
        css`
            backdrop-filter: blur(4px);
        `}
`
