import { FunctionComponent } from 'react'
import { IconProps } from '.'
import * as icons from '../../icons/utility'

export const utilityIcons = [
    'add',
    'calendar',
    'checkmark',
    'checkmark-circle',
    'clock',
    'close',
    'download',
    'dragger',
    'dropdown',
    'duplicate',
    'edit',
    'effect',
    'ellipsis',
    'embed',
    'favorite',
    'flag',
    'grid',
    'history',
    'info',
    'important',
    'link',
    'list',
    'lock',
    'log-out',
    'navigate-down',
    'navigate-left',
    'navigate-right',
    'navigate-up',
    'thin-navigate-down',
    'question',
    'reset',
    'save',
    'search',
    'share',
    'sidebar',
    'source',
    'stats',
    'tag',
    'tap-and-hold',
    'title',
    'tv',
    'unlock',
    'user',
    'verified',
    'view',
    'watermark',
    'upload',
    'funnel',
] as const

type UtilityIcon = (typeof utilityIcons)[number]

export function utilityIconComponent(icon: UtilityIcon): FunctionComponent<IconProps> {
    switch (icon) {
        case 'add':
            return icons.AddIcon
        case 'calendar':
            return icons.CalendarIcon
        case 'checkmark':
            return icons.CheckmarkIcon
        case 'checkmark-circle':
            return icons.CheckmarkCircleIcon
        case 'clock':
            return icons.ClockIcon
        case 'close':
            return icons.CloseIcon
        case 'download':
            return icons.DownloadIcon
        case 'dragger':
            return icons.DraggerIcon
        case 'dropdown':
            return icons.DropdownIcon
        case 'duplicate':
            return icons.DuplicateIcon
        case 'edit':
            return icons.EditIcon
        case 'effect':
            return icons.EffectIcon
        case 'ellipsis':
            return icons.EllipsisIcon
        case 'embed':
            return icons.EmbedIcon
        case 'favorite':
            return icons.FavoriteIcon
        case 'flag':
            return icons.FlagIcon
        case 'grid':
            return icons.GridIcon
        case 'history':
            return icons.HistoryIcon
        case 'info':
            return icons.InfoIcon
        case 'important':
            return icons.ImportantIcon
        case 'link':
            return icons.LinkIcon
        case 'list':
            return icons.ListIcon
        case 'lock':
            return icons.LockIcon
        case 'log-out':
            return icons.LogOutIcon
        case 'navigate-down':
            return icons.NavigateDownIcon
        case 'navigate-left':
            return icons.NavigateLeftIcon
        case 'navigate-right':
            return icons.NavigateRightIcon
        case 'navigate-up':
            return icons.NavigateUpIcon
        case 'thin-navigate-down':
            return icons.ThinNavigateDown
        case 'question':
            return icons.QuestionIcon
        case 'reset':
            return icons.ResetIcon
        case 'save':
            return icons.SaveIcon
        case 'search':
            return icons.SearchIcon
        case 'share':
            return icons.ShareIcon
        case 'sidebar':
            return icons.SidebarIcon
        case 'source':
            return icons.SourceIcon
        case 'stats':
            return icons.StatsIcon
        case 'tag':
            return icons.TagIcon
        case 'tap-and-hold':
            return icons.TapAndHoldIcon
        case 'title':
            return icons.TitleIcon
        case 'tv':
            return icons.TvIcon
        case 'unlock':
            return icons.UnlockIcon
        case 'user':
            return icons.UserIcon
        case 'view':
            return icons.ViewIcon
        case 'verified':
            return icons.VerifiedIcon
        case 'watermark':
            return icons.WatermarkIcon
        case 'upload':
            return icons.UploadIcon
        case 'funnel':
            return icons.FunnelIcon
    }
}

export default UtilityIcon
