exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, "._3rNfgCe_Sl5Rg3toAd0r4b {\n    height: 26px;\n    margin: 3px 0;\n}\n._3yyTtbm5HyGY1xPNaV27nu {\n    fill: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyYellow"] + ";\n}\n._2oXp7kWGA002RGqitKrA-i {\n    fill: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyBlue"] + ";\n}\n._2e03Y9JU0HJPHjOaxu79wE {\n    fill: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyRed"] + ";\n}\n._1-568Ul7EtBFKWs-LeX3yL {\n    fill: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyGreen"] + ";\n}\n._3EbMoqfL8058lEMen-E9vo {\n    fill: " + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyPurple"] + ";\n}\n.oVqEAn4GfBArOWsm71TLb {\n    fill: #000;\n    enable-background: new;\n    opacity: 0.4;\n}", ""]);

// exports
exports.locals = {
	"colors": "\"shared/css/colors.css\"",
	"giphyYellow": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyYellow"] + "",
	"giphyBlue": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyBlue"] + "",
	"giphyRed": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyRed"] + "",
	"giphyGreen": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyGreen"] + "",
	"giphyPurple": "" + require("-!../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyPurple"] + "",
	"logo": "_3rNfgCe_Sl5Rg3toAd0r4b",
	"yellow": "_3yyTtbm5HyGY1xPNaV27nu",
	"blue": "_2oXp7kWGA002RGqitKrA-i",
	"red": "_2e03Y9JU0HJPHjOaxu79wE",
	"green": "_1-568Ul7EtBFKWs-LeX3yL",
	"purple": "_3EbMoqfL8058lEMen-E9vo",
	"shadow": "oVqEAn4GfBArOWsm71TLb"
};