import { giphyLightestGrey } from '@giphy/colors'
import styled from 'styled-components'

const EmptyEmoji = styled.img`
    height: 44px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
`

const Description = styled.p`
    text-align: center;
    color: ${giphyLightestGrey};
    padding: 0 10px;
`

type Props = {
    noRelated?: boolean
}

const SuggestedTagsEmpty = ({ noRelated }: Props) => (
    <div>
        <EmptyEmoji src="https://media.giphy.com/media/IzcFv6WJ4310bDeGjo/giphy.gif" />
        <Description>{`Sorry, we weren't able to find any suggestions${
            noRelated ? '' : ' yet... keep adding tags!'
        }`}</Description>
    </div>
)

export default SuggestedTagsEmpty
