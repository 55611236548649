import { giphyDarkCharcoal, giphyLightGrey } from '@giphy/colors'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    border-radius: 8px;
    border: 2px solid ${giphyDarkCharcoal};
    height: 130px;
    width: 1040px;
    margin-bottom: 20px;
`

const Description = styled.div`
    color: ${giphyLightGrey};
    font-family: 'InterFace';
    font-size: 18px;
    font-weight: bold;
    height: 57px;
    letter-spacing: 0px;
    line-height: 24px;
    width: 633px;
    margin-top: 10px;
    margin-left: 85px;
`

const Logo = styled.div`
    svg {
        width: 257px;
    }
`

const LogoSvg = () => (
    <svg width="270px" height="70px" viewBox="0 0 270 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>GIPHYARTISTSVG</title>
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                <stop stopColor="#9933FF" offset="0%"></stop>
                <stop stopColor="#6157FF" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="GIPHYARTISTSVG" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(4.500000, 10.900000)" fillRule="nonzero">
                <g id="GIPHYArtist" transform="translate(52.500000, 4.500000)" fill="#FFFFFF" fontSize="32">
                    <text
                        id="GIPHY"
                        style={{
                            fontFamily: 'nexablack',
                        }}
                        fontWeight="800"
                    >
                        <tspan x="0" y="30">
                            GIPHY
                        </tspan>
                    </text>
                    <text
                        id="Artist"
                        style={{
                            fontFamily: 'nexaregular',
                            fontWeight: 'bold',
                        }}
                        fontWeight="700"
                    >
                        <tspan x="109.152" y="31.056">
                            Artist
                        </tspan>
                    </text>
                </g>
                <g id="Arts-Icon">
                    <rect
                        id="Rectangle"
                        stroke="#979797"
                        fill="#D8D8D8"
                        opacity="0"
                        x="0"
                        y="0"
                        width="49"
                        height="49"
                    ></rect>
                    <path
                        d="M14.6194566,42.2678965 C13.6546134,41.57354 13.409195,40.5876504 13.8832013,39.310228 L17.4608043,28.3441866 L11.9841096,28.3441866 C11.2919784,28.3441866 10.6459892,27.9912834 10.2768525,27.4178157 C9.9077158,26.844348 9.9077158,25.9096754 10.2768525,25.3362078 L21.1202424,7.42637087 C21.443237,6.85290319 22.0892262,6.5 22.8274995,6.5 L37.0378827,6.5 C37.730014,6.5 38.3760032,6.85290319 38.7451399,7.42637087 C39.1142766,7.99983856 39.0681345,8.74975783 38.6989978,9.32322552 L31.1316959,20.880805 L35.9849665,20.880805 C36.815524,20.880805 37.5076553,21.3660469 37.8306499,22.0718532 C38.1075024,22.7776596 37.922934,23.5716918 37.369229,24.1010466 L18.1834857,42.001162 C17.7682069,42.3981781 16.0667214,43.3094313 14.6194566,42.2678965 Z M14.4850011,25.6236385 L19.1736764,25.6236385 L19.1736764,25.6236385 C19.810801,25.6236385 20.6794173,25.5447671 21.0958701,26.4117388 C21.4269888,26.8980427 21.4269888,27.523866 21.0958701,28.2892088 L17.8000237,37.8057136 C17.7666563,37.9020594 17.8177103,38.0072127 17.9140561,38.0405801 C17.9809693,38.0637541 18.0552417,38.0466124 18.1052311,37.9964579 L31.8135699,24.2428273 C31.8855477,24.1706117 31.8853549,24.0537199 31.8131393,23.9817421 C31.7785349,23.9472516 31.7316695,23.9278846 31.682812,23.9278846 L28.9965319,23.9278846 L28.9965319,23.9278846 C28.3138984,23.9278846 27.5575624,24.1223881 26.896271,23.2259015 C26.2349797,22.329415 26.5321999,21.4637595 26.896271,20.8942489 L34.6703008,9.57948969 C34.7280396,9.49545329 34.7067213,9.38052172 34.6226849,9.32278284 C34.5919279,9.30165064 34.5554889,9.29033539 34.5181719,9.29032882 L24.0562393,9.2884892 C23.9586723,9.2884892 23.8682954,9.33979955 23.8183324,9.42360307 L14.3264289,25.3444836 C14.2742162,25.4320606 14.3028846,25.5453826 14.3904616,25.5975953 C14.4190509,25.61464 14.4517164,25.6236385 14.4850011,25.6236385 Z"
                        id="Combined-Shape"
                        fill="url(#linearGradient-1)"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
)

const WhiteBold = styled.a`
    color: white;
    text-decoration: underline;
`

const GiphyArtist = () => (
    <Container>
        <Logo>
            <LogoSvg />
        </Logo>
        <Description>
            The GIPHY <WhiteBold href="https://giphy.com/artists">Artist Program</WhiteBold> is by invitation only. If
            you’re interested in being a GIPHY Artist, apply as a{' '}
            <WhiteBold href="https://support.giphy.com/hc/en-us/articles/4631422637082-What-is-a-Creator-on-GIPHY-">
                Creator
            </WhiteBold>{' '}
            above & we’ll be in touch!
        </Description>
    </Container>
)

export default GiphyArtist
