import {
    UserOptions,
    getInitialGADataLayerData,
    setGADataLayer,
    setUser,
    getUser,
    getUserLoginParameters,
} from 'analytics'
import { useContext, useLayoutEffect } from 'react'
import UserContext from '../contexts/user'
import TagManager from 'react-gtm-module'
import { useLocalStorage } from 'react-use'

const getUserType = (user) => {
    if (user.isStaff) {
        return 'staff'
    }

    if (user.isArtist) {
        return 'artist'
    }

    if (user.isPartner) {
        return 'partner'
    }

    return 'user'
}

const GA4 = () => {
    const { user } = useContext(UserContext)
    const loginParams = getUserLoginParameters()
    const [loginMethod, setLoginMethod] = useLocalStorage('login_type', loginParams.platform || 'email')

    useLayoutEffect(() => {
        if (!user.isFetching) {
            let userProps: UserOptions = getUser()
            if (user.loggedIn) {
                userProps = {
                    userID: `${user.id}`,
                    userType: getUserType(user),
                    verifiedStatus: user.isVerified,
                    loggedInStatus: true,
                    loginMethod: loginParams.platform || loginMethod,
                }
            }

            setUser(userProps)
            if (loginParams.action) {
                setLoginMethod(loginParams.platform)
                setGADataLayer({
                    event: `${loginParams.action}_form_success`,
                    options: {
                        form: {
                            formName: loginParams.action,
                        },
                    },
                })
            }

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(getInitialGADataLayerData())

            const tagManagerArgs = {
                gtmId: window.GTM_CONTAINER_ID,
            }

            TagManager.initialize(tagManagerArgs)
        }
    }, [user.isFetching])

    return <></>
}
export default GA4
