import { useContext } from 'react'
import styled from 'styled-components'
import VideoContext from '../video-context'
import Attribution from '../../attribution'
import { TitleSmall } from '../../typography'

const Container = styled.div<{ padded: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding-top: ${(props) => (props.padded ? 10 : 0)}px;
`

const Title = styled(TitleSmall)`
    color: #fff;
    padding: 6px 0;
`

const VideoAttribution = () => {
    const { videoData, isSmall } = useContext(VideoContext)
    const showTitle = !isSmall && !!videoData.title
    const showAvatar = !!videoData.user
    return (
        <Container padded={showTitle && showAvatar}>
            {showTitle && <Title>{videoData.title}</Title>}
            {showAvatar && <Attribution compact user={videoData.user} />}
        </Container>
    )
}

export default VideoAttribution
