import { giphyDarkGrey, giphyDarkestGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { ControlSize } from 'types'
import Icon from '../../../types/icons'
import { IconButtonProps } from '../icon-button'
import Content from './content'
import { Container, Header } from './style'

type Props = {
    backgroundColor?: string
    buttonOptions?: Partial<IconButtonProps>
    children?: ReactNode
    className?: string
    color?: string
    contentStyle?: CSSProperties
    defaultOpen?: boolean
    headerStyle?: CSSProperties
    icon?: Icon
    label?: ReactNode
    open?: boolean
    size?: ControlSize
    style?: CSSProperties
    textColor?: string
    onChange?: (isOpen: boolean) => void
    onTransitionEnd?: (isOpen: boolean) => void
}

const Collapse = ({
    backgroundColor = giphyDarkestGrey,
    buttonOptions = {},
    children,
    className,
    color = giphyDarkGrey,
    contentStyle,
    defaultOpen = false,
    headerStyle,
    icon,
    label,
    open: openProp,
    size = 'small',
    style,
    textColor,
    onChange,
    onTransitionEnd: transitionEndProp,
}: Props) => {
    const initialValueRef = useRef<boolean | undefined>(openProp || defaultOpen)
    const [isOpen, setIsOpen] = useState<boolean>(openProp || defaultOpen)

    const onTransitionEnd = () => {
        transitionEndProp?.(isOpen)
    }

    useEffect(() => {
        if (typeof openProp !== 'undefined' && openProp !== isOpen) {
            setIsOpen(openProp)
        }
    }, [openProp])

    useEffect(() => {
        if (isOpen !== initialValueRef.current) {
            initialValueRef.current = undefined
            onChange?.(isOpen)
        }
    }, [isOpen])

    return (
        <Container $backgroundColor={backgroundColor} $size={size} className={className} style={style}>
            <Header
                buttonOptions={buttonOptions}
                color={color}
                icon={icon}
                isOpen={isOpen}
                label={label}
                size={size}
                style={headerStyle}
                textColor={textColor}
                onToggle={setIsOpen}
            />
            <Content isOpen={isOpen} style={contentStyle} onTransitionEnd={onTransitionEnd}>
                {children}
            </Content>
        </Container>
    )
}

export default Collapse
