export enum Status {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Private = 'PRIVATE',
}

namespace StickerStatus {
    export function emoji(status: Status): string {
        switch (status) {
            case Status.Pending:
                return 'https://media.giphy.com/media/USUIWSteF8DJoc5Snd/giphy.gif'
            case Status.Approved:
                return 'https://media.giphy.com/media/iigp4VDyf5dCLRlGkm/giphy.gif'
            case Status.Rejected:
                return 'https://media.giphy.com/media/iD6QiXTTAYrU5C3c89/giphy.gif'
            case Status.Private:
                return 'https://media.giphy.com/media/VDNDX5BhKKz0YsJkl0/giphy.gif'
        }
    }

    export function title(status: Status): string {
        switch (status) {
            case Status.Pending:
                return 'Your sticker is being reviewed...'
            case Status.Approved:
                return 'Available on GIPHY'
            case Status.Rejected:
                return 'Only visible to you'
            case Status.Private:
                return 'Your channel is Private'
        }
    }

    export function cta(status: Status): string {
        switch (status) {
            case Status.Pending:
                return 'Need More Help?  '
            case Status.Approved:
                return 'Need More Help?  '
            case Status.Rejected:
                return 'Need More Help?  '
            case Status.Private:
                return 'Open Settings  '
        }
    }

    export function ctaURL(status: Status): string {
        switch (status) {
            case Status.Pending:
                return 'https://support.giphy.com/hc/en-us/articles/360051726632'
            case Status.Approved:
                return 'https://support.giphy.com/hc/en-us/articles/360051726632'
            case Status.Rejected:
                return 'https://support.giphy.com/hc/en-us/articles/360051726632'
            case Status.Private:
                return 'https://giphy.com/settings'
        }
    }

    export function description(status: Status, username: string | null): string {
        switch (status) {
            case Status.Pending:
                return '...to ensure it meets GIPHY’s content safety & quality standards. This can take a bit.'
            case Status.Approved:
                return `Searching @${username} lets you find & use this sticker in select integrations.`
            case Status.Rejected:
                return 'Not available in search. This sticker will only be available on your channel page.'
            case Status.Private:
                return 'To search for your sticker, change your channel privacy setting to Public.'
        }
    }

    export function gradient(status: Status): string {
        switch (status) {
            case Status.Pending:
                return '#ff6666, #fff35c'
            case Status.Approved:
                return '#00ccff, #00ff99'
            case Status.Rejected:
                return '#ff6666, #e646b6'
            case Status.Private:
                return '#ff6666, #e646b6'
        }
    }
}

export default StickerStatus
