import styled from 'styled-components'
import { giphyBlack, giphyDarkGrey, giphyGreen } from 'shared/css/colors.css'

const CheckboxContainer = styled.span`
    background: ${giphyBlack};
    border: 3px solid ${giphyDarkGrey};
    box-sizing: border-box;
    display: inline-block;
    height: 24px;
    line-height: 22px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 24px;
    z-index: 1;
    input[type='checkbox'] {
        height: 100%;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        cursor: pointer;
    }
`

const CheckboxIcon = styled.span`
    color: ${giphyGreen};
    font-size: 14px;
    height: 14px;
    left: 50%;
    margin: -9px 0 0 -6px;
    position: absolute;
    top: 50%;
    width: 14px;
    pointer-events: none;
`

const Checkbox = ({ className = '', isChecked, onChange }) => (
    <CheckboxContainer className={className}>
        <input type="checkbox" checked={isChecked} onChange={(e) => onChange(e.target.checked)} />
        {isChecked && <CheckboxIcon className="ss-check" />}
    </CheckboxContainer>
)

export default Checkbox
