import { giphyBlack, giphyPink, giphyPurple, giphyWhite } from '@giphy/colors'
import { trackGA4NavigationEvent } from 'analytics'
import styled, { css, keyframes } from 'styled-components'
import { desktopWidth } from '../../../constants'
import Items from '../items'
import { HeaderContext } from '../../header'
import { useContext } from 'react'
import { desktop } from '../../../css'

const gridSpacing = 16

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    animation: ${keyframes`
                    0% { opacity: 0 }
                    100% { opacity: 1 }
                `} 0.5s ease;
    border-bottom: 8px solid ${giphyBlack};
    border-top: 8px solid ${giphyBlack};
    width: ${desktopWidth}px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, ${giphyPink} 0%, ${giphyPurple} 100%);

    h2 {
        margin-left: 0;
    }
    ul {
        list-style: none;
        margin-left: 0;
    }

    li {
        line-height: 32px;
    }
`

const Columns = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    gap: 36px;
    padding: 0 60px;
    ${desktop(css`
        padding-bottom: 30px;
    `)}
`

const SmallColumn = styled.div`
    flex: 1;
`

const LargeColumn = styled.div`
    flex: 2;
    ul {
        columns: 2;
    }
`

const LinkSection = styled.ul`
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: ${gridSpacing / 2}px;
    padding-left: 0;
    margin: 10px 0;

    a {
        font-size: 16px;
        font-weight: bold;
        color: ${giphyWhite};
        cursor: pointer;
        opacity: 1;
        -webkit-font-smoothing: antialiased;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.25s;
        }
    }
`

const Header = styled.h2`
    font-size: 26px;
    margin: 32px 0px 0px 60px;
    display: flex;
    flex: 1;
    padding-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    a {
        color: ${giphyWhite};
        opacity: 1;
        text-transform: capitalize;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.25s;
        }
    }
`
export const headerClassname = Header.toString()

const Footer = styled.div`
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    font-size: 14px;
    height: 60px;
    line-height: 20px;
    padding: 20px 60px;
    width: 100%;

    a {
        margin-right: 30px;
    }

    span {
        cursor: pointer;
    }
`
const NewPill = styled.img`
    position: relative;
    top: 4px;
    left: 5px;
    display: inline-block;
`

const Copyright = styled.div`
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 30px;
    padding-right: 30px;
    font-weight: bold;
`
// add a new pills here
const newPills: (keyof typeof Items)[] = []
const ColumnChildren = ({ title, items, parent }: { title: string; items: (keyof typeof Items)[]; parent: string }) => {
    return (
        <>
            <Header>
                <a onClick={() => trackGA4NavigationEvent('none', `/${title}`, title)} href={`/${title}`}>
                    {title}
                </a>
            </Header>
            <LinkSection>
                {items.map((label, i) => {
                    const path = Items[label]
                    const newPill = newPills.includes(label)
                    return (
                        <li key={i}>
                            <a onClick={() => trackGA4NavigationEvent(label, path, parent)} href={path}>
                                {label}
                            </a>
                            {newPill && <NewPill src="/static/img/svg/pill-new.svg" alt="" />}
                        </li>
                    )
                })}
            </LinkSection>
        </>
    )
}

const footerLinks = [
    { url: '/dmca', label: 'DMCA' },
    { url: '/community-guidelines', label: 'Community Guidelines' },
    { url: '/privacy', label: 'Privacy Policy' },
    { url: '/dmca', label: 'Copyright' },
]

const DesktopMenu = () => {
    const { setMenuState } = useContext(HeaderContext)
    return (
        <MenuContainer onMouseOver={() => setMenuState('desktop')} onMouseLeave={() => setMenuState(undefined)}>
            <Columns>
                <LargeColumn>
                    <ColumnChildren
                        title="categories"
                        parent="Categories"
                        items={[
                            `GIPHY Studios`,
                            `Animals`,
                            `Actions`,
                            `Anime`,
                            `Cartoons`,
                            `Emotions`,
                            `Food/Drink`,
                            `Gaming`,
                            `Holidays/Greetings`,
                            `Memes`,
                            `Clips`,
                        ]}
                    />
                </LargeColumn>
                <SmallColumn>
                    <ColumnChildren
                        title="stickers"
                        parent="Stickers"
                        items={[`Originals`, `Trending`, `Reactions`, `Packs`]}
                    />
                </SmallColumn>
                <SmallColumn>
                    <ColumnChildren title="apps" parent="Apps" items={[`GIPHY`, `GIPHY Capture`]} />
                </SmallColumn>
                <LargeColumn>
                    <ColumnChildren
                        title="about"
                        parent="About"
                        items={['Team', 'Studios', 'Developers', 'FAQ', 'Support', 'Jobs', 'Ads', 'Transparency']}
                    />
                </LargeColumn>
            </Columns>
            <Footer>
                <Copyright>&copy; {new Date().getFullYear()} GIPHY, Inc.</Copyright>
                <div>
                    {footerLinks.map(({ url, label }) => (
                        <a key={label.toLowerCase().replace(/ /g, '-')} href={url} target="_blank" rel="noreferrer">
                            {label}
                        </a>
                    ))}
                    <span
                        onClick={() => {
                            // @ts-ignore unless they can provide ts definitions
                            window.Didomi && window.Didomi.preferences.show()
                        }}
                    >
                        Manage Cookies
                    </span>{' '}
                </div>
            </Footer>
        </MenuContainer>
    )
}

export default DesktopMenu
