import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import { ReactNode, SyntheticEvent } from 'react'
import { useHistory } from 'react-router'
import { desktop } from 'shared/util/media-query'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    margin-bottom: 6px;
    font-weight: 600;
    font-family: interface;
    -webkit-font-smoothing: antialiased;
    padding: 0 8px;
    ${desktop.css`
        padding: 0 8px 0 0;
        margin-bottom: 0px;
    `}
`

const Title = styled.h3<{ onClick?: any }>`
    ${desktop.css`
        font-size: 24px;
        padding-top: 0px;
    `}
    display: flex;
    color: ${giphyWhite};
    font-size: 22px;
    align-items: center;
    padding-top: 7px;
    // Only make cursor pointer if this section header has a url/link
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`

const SeeAll = styled.h5<{ onClick?: any }>`
    color: ${giphyLightGrey};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    padding-top: 10px;
    ${desktop.css`
        padding-top: 5px;
    `}
`

const Arrow = styled.div`
    font-size: 11px;
    margin-left: 3px;
    height: 13px;
    display: inline-block;
`

const Icon = styled.div`
    width: 22px;
    height: 24px;
    margin-right: 8px;
    position: relative;
    ${desktop.css`
        height: 24px;
        width: 24px;
        margin-right: 10px;
    `}
    & svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`

type Props = {
    label: string
    url?: string
    historyState?: any
    icon: ReactNode
    seeAllLabel?: string
    isMobileTrending?: boolean
}

const HomepageHeader = ({
    label,
    url = '',
    historyState = {},
    icon,
    isMobileTrending = false,
    seeAllLabel = '',
}: Props) => {
    const history = useHistory()
    const onClick = (e: SyntheticEvent) => {
        e.preventDefault()
        // do a page reload so we get the SSR version and can deprecate the old version
        if (url === '/trending-gifs') {
            location.href = url
        } else {
            history.push(url, historyState)
        }
    }

    return (
        <Container>
            <Title onClick={url ? onClick : undefined}>
                <Icon>{icon}</Icon>
                {isMobileTrending ? (
                    // design wants 'rending' closer to the 'T' in 'Trending on mobile-web
                    <>
                        <span style={{ marginRight: `-1px` }}>T</span>rending
                    </>
                ) : (
                    label
                )}
            </Title>
            {seeAllLabel && (
                <SeeAll onClick={onClick}>
                    {seeAllLabel} <Arrow className="ss-icon ss-navigateright" />
                </SeeAll>
            )}
        </Container>
    )
}

export default HomepageHeader
