import { ReactNode, createContext, useState } from 'react'
import { AlertData } from '../types'

type ContextProps = {
    activeId?: string
    alerts: Map<string, AlertData>
    closeAlert: () => void
    openAlert: (id: string) => void
    registerAlert: (id: string, data: AlertData) => void
    unregisterAlert: (id: string) => void
}

type Props = {
    children?: ReactNode
}

export const PortalAlertContext = createContext({} as ContextProps)

const PortalAlertContextManager = ({ children }: Props) => {
    const [alerts, setAlerts] = useState<Map<string, AlertData>>(new Map())
    const [activeId, setActiveId] = useState<string | undefined>()

    function closeAlert() {
        setActiveId(undefined)
    }

    function openAlert(id: string) {
        setActiveId(id)
    }

    function registerAlert(id: string, data: AlertData) {
        const clone = new Map(alerts.entries())
        clone.set(id, data)
        setAlerts(clone)
    }

    function unregisterAlert(id: string) {
        const clone = new Map(alerts.entries())
        clone.delete(id)
        setAlerts(clone)
    }

    return (
        <PortalAlertContext.Provider
            value={{ activeId, alerts, closeAlert, openAlert, registerAlert, unregisterAlert }}
        >
            {children}
        </PortalAlertContext.Provider>
    )
}

export default PortalAlertContextManager
