import { ElementType } from 'react'

export type FlashMessageConfig = {
    gradientColors?: string[]
    icon?: string
    title?: string
    message?: string
    linkText?: string
    messageComponent?: ElementType
    linkURL?: string
    textColor?: string
    createdAt?: number
}

type FlashMessagesConfigType = {
    email_validation_email_change_success: FlashMessageConfig
    legacy_email_confirmation_success: FlashMessageConfig
    registration_confirm_success: FlashMessageConfig
    registration_confirm_already_confirmed: FlashMessageConfig
    registration_confirm_failure_invalid_token: FlashMessageConfig
    registration_confirm_failure_already_logged_in: FlashMessageConfig
    registration_confirm_failure_unknown_error: FlashMessageConfig
    registration_send_email_error: FlashMessageConfig
}

export type FlashMessageNamesType =
    | 'email_validation_email_change_success'
    | 'legacy_email_confirmation_success'
    | 'registration_confirm_success'
    | 'registration_confirm_already_confirmed'
    | 'registration_confirm_failure_invalid_token'
    | 'registration_confirm_failure_already_logged_in'
    | 'registration_confirm_failure_unknown_error'
    | 'registration_send_email_error'

export const FLASH_MESSAGES: FlashMessagesConfigType = {
    legacy_email_confirmation_success: {
        gradientColors: ['#6157ff', '#00ccff'],
        icon: '😎',
        title: 'Success!',
        message: 'Your GIPHY account is now validated.',
        linkText: 'Home',
        linkURL: '/',
        textColor: '#ffffff',
    },
    email_validation_email_change_success: {
        gradientColors: ['#6157ff', '#00ccff'],
        icon: '😎',
        title: 'Success!',
        message: 'Your GIPHY email has been updated.',
        linkText: 'Home',
        linkURL: '/',
        textColor: '#ffffff',
    },
    registration_confirm_success: {
        gradientColors: ['#6157ff', '#00ccff'],
        icon: '😎',
        title: 'Success!',
        message: 'Your email is now validated.',
        linkText: 'Login Now',
        linkURL: '/login',
        textColor: '#ffffff',
    },
    registration_confirm_already_confirmed: {
        gradientColors: ['#6157ff', '#00ccff'],
        icon: '✌️',
        title: 'Confirmed!',
        message: 'Looks like your account was already validated!',
        linkText: 'Login Now',
        linkURL: '/login',
        textColor: '#ffffff',
    },
    registration_confirm_failure_invalid_token: {
        gradientColors: ['#e646b6', '#ff6666'],
        icon: '☠️',
        title: 'Busted!',
        message: 'Your confirmation link is expired or invalid.',
        linkText: '',
        linkURL: '',
        textColor: '#ffffff',
    },
    registration_confirm_failure_already_logged_in: {
        gradientColors: ['#e646b6', '#ff6666'],
        icon: '🧐',
        title: 'You are already signed in!',
        message: 'Sign out first, then try again.',
        linkText: 'Sign Out',
        linkURL: '/logout',
        textColor: '#ffffff',
    },
    registration_confirm_failure_unknown_error: {
        gradientColors: ['#e646b6', '#ff6666'],
        icon: '😭',
        title: 'Oops!',
        message: 'An unknown error occurred. Please try again.',
        linkText: '',
        linkURL: '',
        textColor: '#ffffff',
    },
    registration_send_email_error: {
        gradientColors: ['#e646b6', '#ff6666'],
        icon: '🤬',
        title: 'Error!',
        message: 'There was a problem sending your confirmation email. Please contact GIPHY Support.',
        linkText: 'Contact Support',
        linkURL: 'https://support.giphy.com',
        textColor: '#ffffff',
    },
}

export const FLASH_MESSAGE_TEMPLATES = {
    success: {
        gradientColors: ['#6157ff', '#00ccff'],
        icon: '😎',
        title: 'Success!',
        message: 'It worked great!',
        linkText: 'Home',
        linkURL: '/',
        textColor: '#ffffff',
    },
    failure: {
        gradientColors: ['#e646b6', '#ff6666'],
        icon: '🤬',
        title: 'Failure!',
        message: "It didn't work!",
        linkText: 'Home',
        linkURL: '/',
        textColor: '#ffffff',
    },
}
