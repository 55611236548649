import { giphyLightGrey } from '@giphy/colors'
import { Video } from '@giphy/react-giphy-brand'
import { head, isEqual, isNumber } from 'lodash'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { fetchSpotifyArtist } from 'shared/api'
import { fetchGifDetailViewCount, fetchTagCounts } from 'shared/api/index'
import EditPanelContext from 'shared/components/edit-panel/context'
import { addSpotifyAttribution, isSpotifyDeepLinkUrl } from 'shared/util/gif-maker-util'
import getViewCountDisplay from 'shared/util/view-count-formatter'
import styled, { css } from 'styled-components'
import CTA from '../../../../site/creation-tools/components/form-fields/upload/cta'
import Toggle from '../../../../site/creation-tools/components/toggle'
import { giphyYellow } from '../../../css/colors.css'
import gifProps from '../../../prop-types/gif'
import { getUrl } from '../../../util/gif-extract'
import { fitGifToSize } from '../../../util/sizing'
import Scrubber from '../scrubber'
import { containsPrivateChannel, getPropFromGifs, getTagsFromGifs } from '../utils'
import AdvancedButtonToggle from './advanced-button'
import styles from './info.css'
import PrivacyToggle from './privacy-toggle'
import Rating from './rating'
import Tags from './tags'
import TextFieldAsync from './textfield-async'
import Username from './username'

const StyledScrubber = styled(Scrubber)`
    margin-top: 13px;
`

const Metadata = styled.div`
    flex-grow: 1;
    margin-right: ${(props) => (props.bulkEditMode ? `0px` : `25px`)};
`

const Prev = styled.div`
    position: absolute;
    top: ${(props) => (props.height - 50) / 2}px;
    left: 0;
    color: ${giphyLightGrey};
    font-size: 18px;
    padding: 15px;
    height: 50px;
    z-index: 2;
`

const Next = styled(Prev)`
    left: auto;
    right: 0;
`

const GifIndex = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
`

const GifWrap = styled.div`
    flex-shrink: 0;
    position: relative;
    margin: 0 auto;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
`

const PrivacyViewCountWrap = styled.div`
    padding-top: 10px;
    ${(props) =>
        props.bulkEditMode
            ? css`
                  display: flex;
                  height: 80px;
                  flex-direction: column;
              `
            : css`
                  height: 25px;
              `}
`

const GifCount = styled.div`
    font-family: 'NexaBlack';
    pointer-events: none;
    background-color: ${giphyYellow};
    color: black;
    font-weight: 900;
    font-size: 18px;
    height: 34px;
    width: 34px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 34px;
    text-align: center;
`

const RightSideContainer = styled.div`
    flex-grow: 1;
    position: relative;
`

const SourceContainer = styled.div`
    position: relative;
`

const GPSEligible = styled.div``

const GPSToggleWrap = styled.div`
    display: inline-block;
    width: 160px;
    margin-left: 18px;
`

/**
 * Edit Panel - Info: Controls the info section
 * of the GIF Edit Panel. This allows tagging,
 * editing source, content url, etc.
 *
 * @class EditPanelInfo
 */
class EditPanelInfo extends PureComponent {
    state = {
        gifIndex: 0,
        viewCount: null,
        tagCounts: {},
        videoApi: null,
    }
    static contextType = EditPanelContext
    static propTypes = {
        gifs: PropTypes.arrayOf(gifProps),
        permissions: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        onTogglePrivacy: PropTypes.func.isRequired,
        onEnableGPS: PropTypes.func,
        onDisableGPS: PropTypes.func,
    }
    constructor(props) {
        super(props)
        const firstGif = head(props.gifs)
        if (firstGif && firstGif.cta) {
            this.state.isCTAOn = firstGif.cta.text !== ''
            this.state.ctaText = firstGif.cta.text
        } else {
            this.state.isCTAOn = false
        }
    }
    async updateViewCount() {
        const { gifs } = this.props

        this.setState({ viewCount: null })

        if (gifs.length === 1) {
            const gifId = gifs[0].id
            const data = await fetchGifDetailViewCount(gifId)
            this.setState({ viewCount: data.viewCount })
        }
    }
    async updateTagCounts() {
        const { gifs, permissions } = this.props

        this.setState({ tagCounts: {} })

        if (permissions.tag_counts && gifs.length === 1) {
            const gifId = gifs[0].id
            const data = await fetchTagCounts(gifId)
            this.setState({ tagCounts: data.tag_counts })
        }
    }
    componentDidMount() {
        this.updateViewCount()
        this.updateTagCounts()
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.gifs, prevProps.gifs) || !isEqual(this.props.permissions, prevProps.permissions)) {
            const firstGif = head(this.props.gifs)
            this.setState({ gifIndex: 0 })
            if (firstGif.cta) {
                this.setState({ isCTAOn: Boolean(firstGif.cta.text), ctaText: firstGif.cta.text })
            }
            this.updateViewCount()
            this.updateTagCounts()
        }
    }
    nextGif() {
        const { gifs } = this.props
        const { gifIndex: currentIndex } = this.state
        const gifIndex = currentIndex >= gifs.length - 1 ? 0 : currentIndex + 1
        this.setState({ gifIndex })
    }
    prevGif() {
        const { gifs } = this.props
        const { gifIndex: currentIndex } = this.state
        const gifIndex = currentIndex === 0 ? gifs.length - 1 : currentIndex - 1
        this.setState({ gifIndex })
    }

    toggleCTAOn(ctaText) {
        const { isCTAOn } = this.state
        if (isCTAOn && ctaText) {
            this.validateAndUpdateCTA('', '')
        }
        this.setState({ isCTAOn: !isCTAOn })
    }

    async toggleSpotifyCTA(source_post_url) {
        const { isCTAOn } = this.state

        if (isCTAOn) {
            this.validateAndUpdateCTA('', '')
        } else {
            const artistName = await fetchSpotifyArtist(source_post_url)
            const spotifyLink = addSpotifyAttribution(source_post_url)
            await this.validateAndUpdateCTA(`Listen to ${artistName} on Spotify`, spotifyLink)
        }

        this.setState({ isCTAOn: !isCTAOn })
    }

    async validateAndUpdateSourceURL(source_post_url, showCTA, ctaText, isSpotify) {
        const { onChange } = this.props
        const { isCTAOn } = this.state
        try {
            if (isCTAOn && showCTA) {
                if (source_post_url === '' || isSpotify) {
                    await this.validateAndUpdateCTA('', '')
                } else {
                    await this.validateAndUpdateCTA(ctaText, source_post_url)
                }
            }
            // dont update source url is it doesnt validate with metadata service
            onChange({ source_post_url })
        } catch (error) {
            throw 'Invalid URL.'
        }
    }

    async validateAndUpdateCTA(ctaText, ctaLink) {
        const { onChange } = this.props
        const cta = { text: ctaText, link: ctaLink }
        if (ctaText && !ctaLink) {
            throw 'Source URL must be set in order to edit the button title.'
        }
        await onChange({ cta })
    }

    render() {
        const { gifs, permissions, onChange, user, onTogglePrivacy, onEnableGPS, onDisableGPS } = this.props
        const { gifIndex, viewCount, tagCounts, isCTAOn, videoApi } = this.state
        const { bulkEditMode } = this.context
        const gifUser = getPropFromGifs(gifs, 'user')
        const isPrivate = containsPrivateChannel(gifs)
        const source = getPropFromGifs(gifs, 'source_post_url')
        const cta = getPropFromGifs(gifs, 'cta') || { text: '', link: '' }
        const username = getPropFromGifs(gifs, 'username')
        const rating = getPropFromGifs(gifs, 'rating')
        const gpsNoTrend = getPropFromGifs(gifs, 'gps_no_trend')
        const gif = gifs[gifIndex]
        const isSticker = gif.is_sticker
        const tags = getTagsFromGifs(gifs)
        const previewWidth = bulkEditMode ? 280 : 300
        const previewHeight = bulkEditMode ? 195 : 245
        const gaEventCateogry = bulkEditMode ? 'edit-panel' : 'gif detail'
        // bulk editor does not currently mix gif and video so the below is safe
        const isVideo = gifs.length >= 1 && gif.type === 'video'
        const showCTA = (user.isVerified || user.isStaff) && source
        const videoTitle = getPropFromGifs(gifs, 'title') || ''
        const videoDescription = getPropFromGifs(gifs, 'video.description') || ''
        const isSpotify = isSpotifyDeepLinkUrl(source)
        const fittedSize = fitGifToSize(gif, previewWidth, previewHeight)
        const gifPreview = (
            <RightSideContainer>
                <GifWrap bulkEditMode={bulkEditMode} width={previewWidth}>
                    <div
                        className={isSticker ? styles.sticker : styles.gif}
                        data-isstack={gifs.length > 1}
                        style={{
                            width: fittedSize.width,
                            left: fittedSize.left,
                        }}
                    >
                        {isVideo ? (
                            <div onClick={(evt) => evt.stopPropagation()}>
                                <Video
                                    gif={gif}
                                    width={fittedSize.width}
                                    noCutout
                                    noShare
                                    events={{
                                        onPlayerReady: (videoApi) => {
                                            this.setState({ videoApi })
                                        },
                                    }}
                                />
                                <StyledScrubber gif={gif} videoApi={videoApi} />
                            </div>
                        ) : (
                            <img className={styles.previewImage} src={getUrl(gif, 'fixed_height')} key={gif.id} />
                        )}

                        {bulkEditMode && gifs.length > 1 && (
                            <>
                                <GifCount>{gifs.length}</GifCount>
                                <GifIndex>{`${gifIndex + 1}/${gifs.length}`}</GifIndex>
                                <Prev
                                    className="ss-navigateleft"
                                    height={fittedSize.height}
                                    onClick={() => this.prevGif()}
                                />
                                <Next
                                    className="ss-navigateright"
                                    height={fittedSize.height}
                                    onClick={() => this.nextGif()}
                                />
                            </>
                        )}
                    </div>
                </GifWrap>
                <PrivacyViewCountWrap bulkEditMode={bulkEditMode}>
                    {bulkEditMode && (
                        <PrivacyToggle
                            key={`Title${gif.id}`}
                            user={user}
                            permissions={permissions}
                            gifs={gifs}
                            onTogglePrivacy={onTogglePrivacy}
                            bulkEditMode={bulkEditMode}
                        />
                    )}
                    {isNumber(viewCount) && viewCount > 0 && (
                        <div className={styles.viewCount}>{getViewCountDisplay(viewCount)}</div>
                    )}
                </PrivacyViewCountWrap>
            </RightSideContainer>
        )

        return (
            <div className={styles.container}>
                {bulkEditMode ? gifPreview : null}
                <div className={styles.metadataBlock}>
                    <Metadata bulkEditMode={bulkEditMode}>
                        {user.isStaff && (
                            <Username
                                className={styles.section}
                                gifUser={gifUser}
                                avatar={gifUser ? gifUser.avatar : null}
                                value={username}
                                isPrivate={isPrivate}
                                onChange={(username) => onChange({ username })}
                            />
                        )}
                        {isVideo && gif.video && (
                            <div>
                                <TextFieldAsync
                                    key={`Title${gif.id}`}
                                    className={styles.section}
                                    title="Clip Title"
                                    placeholder="The title of your clip"
                                    value={videoTitle}
                                    onChange={(title) => onChange({ title })}
                                />
                                <TextFieldAsync
                                    key={`Description${gif.id}`}
                                    className={styles.section}
                                    title="Clip Description"
                                    placeholder="Add a brief description about your clip."
                                    maxLength={200}
                                    height={86}
                                    multiline={true}
                                    value={videoDescription}
                                    onChange={(description) => onChange({ description })}
                                />
                            </div>
                        )}
                        <SourceContainer>
                            <TextFieldAsync
                                key={`Source${gif.id}`}
                                className={styles.section}
                                title={showCTA ? 'Source / Link' : 'Source URL'}
                                value={source}
                                isURL={true}
                                placeholder="Add a source / link"
                                customError={true}
                                onChange={(ctaUrl) =>
                                    this.validateAndUpdateSourceURL(ctaUrl, showCTA, cta.text, isSpotify)
                                }
                            />
                            {isSpotify && !isVideo && user.isStaff && (
                                <CTA isOn={isCTAOn} onToggle={() => this.toggleSpotifyCTA(source)} isEditPanel={true} />
                            )}
                            {isVideo && showCTA && (
                                <AdvancedButtonToggle
                                    gifID={gif.id}
                                    bulkEditMode={bulkEditMode}
                                    previewWidth={previewWidth}
                                    isCTAOn={isCTAOn}
                                    onToggle={() => this.toggleCTAOn(cta.text)}
                                />
                            )}
                        </SourceContainer>
                        {isVideo && showCTA && isCTAOn && (
                            <TextFieldAsync
                                key={`CTAText${gif.id}`}
                                className={styles.section}
                                title="Button Title (required)"
                                value={cta.text}
                                maxLength={40}
                                placeholder="Add a button title"
                                customError={true}
                                onChange={(ctaTitle) => this.validateAndUpdateCTA(ctaTitle, source)}
                            />
                        )}
                        {(permissions.rate || user.isStaff) && (
                            <Rating
                                className={styles.section}
                                value={rating}
                                allowUnrated={user.isStaff}
                                onChange={(rating) => onChange({ rating })}
                            />
                        )}

                        {user.isStaff && (
                            <GPSEligible>
                                <span>Eligible for Trending</span>
                                <GPSToggleWrap>
                                    <Toggle
                                        onLabel="Yes"
                                        offLabel="No"
                                        isOn={!gpsNoTrend}
                                        onToggle={() => (gpsNoTrend ? onEnableGPS?.() : onDisableGPS?.())}
                                    />
                                </GPSToggleWrap>
                            </GPSEligible>
                        )}
                    </Metadata>
                    {!bulkEditMode ? gifPreview : null}
                </div>
                {(permissions.tag || user.isStaff) && (
                    <Tags
                        className={styles.contentBlock}
                        user={user}
                        gifs={gifs}
                        tags={tags}
                        tagCounts={tagCounts}
                        gaEventCategory={gaEventCateogry}
                        isMultipleGifs={gifs.length > 1}
                        onAddTags={async (tags) => {
                            for (let tag of tags) {
                                await onChange({ create_tag: tag })
                            }
                        }}
                        onRemoveTag={(tag) => onChange({ delete_tag: tag })}
                        onRemoveAllTags={() => onChange({ delete_all_tags: true }, true)}
                        onFeatureTag={(tag) => onChange({ feature_tag: tag })}
                        onUnfeatureTag={(tag) => onChange({ unfeature_tag: tag })}
                    />
                )}
            </div>
        )
    }
}

export default EditPanelInfo
