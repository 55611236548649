import styled from 'styled-components'

const Icon = styled.a`
    display: inline-block;
    margin: 0 0.5em;
`

const Container = styled.div`
    text-align: center;

    ${Icon} {
        font-weight: normal;
        text-decoration: none;
    }
`

const AboutPageSocialIcons = () => (
    <Container>
        <Icon href="https://twitter.com/giphy" target="_blank">
            <i className="ss-social ss-twitter" />
        </Icon>
        <Icon href="https://www.instagram.com/giphy" target="_blank">
            <i className="ss-social ss-instagram" />
        </Icon>
        <Icon href="https://facebook.com/giphy" target="_blank">
            <i className="ss-social ss-facebook" />
        </Icon>
        <Icon href="https://www.youtube.com/giphy" target="_blank">
            <i className="ss-social ss-youtube" />
        </Icon>
    </Container>
)

export default AboutPageSocialIcons
