import styled from 'styled-components'

export const Container = styled.div`
    overflow: hidden;
    position: relative;

    img,
    video {
        user-select: none;
    }
`

export const Title = styled.div`
    left: -4%;
    position: absolute;
    top: 2%;
    transform: rotate(-5deg);
    width: 64%;
    z-index: 1;
`

export const TitleBackground = styled.img`
    width: 100%;
`

export const TitleSVG = styled.svg`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
`

export const Emoji = styled.div`
    position: absolute;
    right: -8%;
    transform: rotate(5deg);
    top: 44%;
    width: 31%;
    z-index: 1;
`

export const EmojiBackground = styled.img`
    width: 100%;
`

export const EmojiImage = styled.img`
    height: 67%;
    left: 3%;
    object-fit: contain;
    position: absolute;
    top: 17%;
    width: 88%;
`

export const Video = styled.video`
    display: block;
    margin-top: -15%;
    width: 100%;
`
