import { useContext } from 'react'
import styled, { css } from 'styled-components'
import AnimatedButton from '../../animated-button'
import VideoContext from '../video-context'
import { PauseIcon, PlayIcon } from './icons/play-pause'

const Container = styled.div<{ centered: boolean }>`
    flex-grow: 0;
    flex-shrink: 0;
    height: 38px;
    overflow: hidden;
    width: 38px;
    margin: ${(props) => (props.centered ? '-19px 0 0 -19px' : '0 0 5px')};

    ${(props) =>
        props.centered &&
        css`
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
        `}
`

const PlayPause = ({ centered = false }: { centered?: boolean }) => {
    const { play, pause, mediaState } = useContext(VideoContext)
    const togglePlay = () => {
        mediaState === 'playing' ? pause() : play()
    }
    return (
        <Container centered={centered}>
            <AnimatedButton
                isOn={mediaState === 'playing'}
                offIcon={<PlayIcon />}
                onClick={togglePlay}
                onIcon={<PauseIcon />}
            />
        </Container>
    )
}

export default PlayPause
