import { fetchChannel } from 'shared/api/channels'
import { initChannelSelector, isFetchingChannels, showChannelSection, updateChannelSection } from './channel-selector'

export default function fetchChannels(channelId) {
    return function (dispatch) {
        dispatch(isFetchingChannels(true))
        return fetchChannel(channelId).then((data) => {
            dispatch(isFetchingChannels(false))
            if (channelId) {
                dispatch(updateChannelSection(channelId, data))
                dispatch(showChannelSection(channelId))
            } else {
                dispatch(initChannelSelector(data.results))
                dispatch(showChannelSection())
            }
        })
    }
}
