import 'fullscreen-polyfill'
import { useContext, useEffect } from 'react'
import useKey from 'react-use/lib/useKey'
import VideoContext from '../video-context'

const FullscreenManager = () => {
    const { setFullscreen, isFullscreen, playerElement, play, pause, mediaState } = useContext(VideoContext)
    const onFullscreenChange = () => {
        if (document.fullscreenElement === null) {
            setFullscreen(false)
        }
    }

    const onKeyDown = (e: KeyboardEvent) => {
        if (isFullscreen) {
            e.preventDefault()
            mediaState === 'playing' ? pause() : play()
        }
    }

    useKey(' ', onKeyDown, undefined, [isFullscreen, mediaState])

    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullscreenChange)
        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenChange)
        }
    }, [])

    useEffect(() => {
        try {
            // isFullscreen is more like go fullscreen
            !isFullscreen ? document.exitFullscreen().catch(() => {}) : playerElement?.requestFullscreen()
        } catch (error) {}
    }, [isFullscreen])

    return null
}

export default FullscreenManager
