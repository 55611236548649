import { giphyBlack, giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { ControlSize, NumberFormat, TextLength } from 'types'
import { IconButtonProps } from '../icon-button'
import { Container, Label, Stepper } from './style'

export type Props = {
    arrowOptions?: Partial<IconButtonProps>
    className?: string
    color?: string
    defaultValue?: number
    disabled?: boolean
    extension?: ReactNode
    format?: NumberFormat
    max?: number
    min?: number
    length?: TextLength
    placeholder?: string
    size?: ControlSize
    step?: number
    style?: CSSProperties
    value?: number
    onChange?: (value: number) => void
    onChangeValue?: (value?: number) => void
}

const NumberInput = ({
    arrowOptions: arrowOptionsProp = {},
    className,
    color = giphyDarkGrey,
    defaultValue,
    disabled = false,
    extension,
    format,
    length,
    max,
    min,
    placeholder,
    size = 'small',
    step,
    style,
    value: valueProp,
    onChange,
    onChangeValue,
}: Props) => {
    const initialValueRef = useRef<number | undefined>(valueProp || defaultValue)
    const [value, setValue] = useState<number | undefined>(valueProp || defaultValue)
    const showStepper = step && size !== 'mini'
    const arrowOptions = { color: giphyBlack, ...arrowOptionsProp }

    const onInputChange = useCallback(
        (v: number) => {
            let roundedValue = Math.round(v * 100) / 100

            if (typeof max === 'number') {
                roundedValue = Math.min(roundedValue, max)
            }

            if (typeof min === 'number') {
                roundedValue = Math.max(roundedValue, min)
            }

            onChangeValue?.(roundedValue)
            setValue(roundedValue)
        },
        [max, min]
    )

    useEffect(() => {
        if (disabled) return

        if (typeof value === 'number' && value !== initialValueRef.current) {
            initialValueRef.current = undefined
            onChange?.(value)
        }
    }, [value])

    useEffect(() => {
        if (typeof valueProp === 'number' && valueProp !== value) {
            setValue(valueProp)
        }
    }, [valueProp])

    return (
        <Container
            $arrowColor={showStepper ? arrowOptions.color : color}
            $disabled={disabled}
            $size={size}
            className={className}
            style={style}
        >
            <Label
                $color={color}
                disabled={disabled}
                extension={extension}
                format={format}
                length={length}
                placeholder={placeholder}
                size={size}
                step={step}
                value={value}
                onChange={onInputChange}
            />
            {showStepper && (
                <Stepper
                    arrowOptions={arrowOptions}
                    onDecrement={() => onInputChange((value || 0) - step)}
                    onIncrement={() => onInputChange((value || 0) + step)}
                />
            )}
        </Container>
    )
}

export default NumberInput
