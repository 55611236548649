import styled, { css } from 'styled-components'
import { ControlSize, Gradient } from 'types'
import { gradientToCss, heightFromControlSize } from 'utils'
import ButtonComponent from '../button'

export const Button = styled(ButtonComponent)<{ $size: ControlSize }>`
    padding: 0;
    width: ${({ $size }) => heightFromControlSize($size)}px;

    > svg {
        display: block;
        margin: 0 auto;
        pointer-events: none;
    }
`

export const Inverted = styled.span<{ $color?: string; $gradient?: Gradient; $iconColor?: string }>`
    border-radius: inherit;
    height: 100%;
    display: block;
    width: 100%;

    ${({ $color, $gradient, $iconColor }) => {
        const gradientColor = $gradient ? gradientToCss($gradient) : null
        const buttonColor = $iconColor || $color || '#fff'

        return css`
            background: ${gradientColor || buttonColor};
        `
    }}
`
