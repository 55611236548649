import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { PureComponent } from 'react'
import { addProtocol } from 'shared/util/url'
import { debounce } from 'core-decorators'
import css from './text.css'

/**
 * Text Input: Controls a text input with an
 * optional button.
 *
 * @class TextInput
 */
export default class extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
        autoSelect: PropTypes.bool,
        placeholder: PropTypes.string,
        buttonLabel: PropTypes.node,
        buttonColor: PropTypes.string,
        onSubmit: PropTypes.func,
    }
    static defaultProps = {
        autoSelect: false,
        type: 'text',
        value: '',
    }
    state = {
        text: '',
    }
    componentDidMount() {
        const { value } = this.props
        this.setState({ text: value })
    }
    componentWillReceiveProps(nextProps) {
        const { value } = this.props

        if (nextProps.value !== value) {
            this.setState({
                text: nextProps.value,
            })
        }
    }
    onSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        const { text } = this.state
        const { autoSelect, onSubmit } = this.props
        if (this.input && autoSelect) {
            this.input.select()
        }
        onSubmit(text)
    }
    onBlur() {
        const { onBlur = () => {} } = this.props
        const { text } = this.state

        onBlur(text)
    }
    @debounce(500)
    onChangeDebounce() {
        const { onChange } = this.props
        const { text } = this.state
        onChange(text || '')
    }
    render() {
        const {
            className,
            type,
            value,
            placeholder,
            buttonLabel,
            name,
            errorMessage,
            disabled,
            buttonColor,
            onChange,
        } = this.props
        const { text } = this.state
        const isUrl = type === 'url'
        const textValue = text ? (isUrl ? addProtocol(text) : text) : ''

        return (
            <form className={className} onSubmit={(e) => this.onSubmit(e)}>
                {name && (
                    <span className={css.name}>
                        {name} <span className={css.errorLabel}>{errorMessage}</span>
                    </span>
                )}
                <div className={errorMessage ? css.error : css.container}>
                    <input
                        ref={(c) => (this.input = c)}
                        className={disabled ? css.inputDisabled : css.input}
                        type={type}
                        value={textValue || ''}
                        placeholder={placeholder}
                        spellCheck={false}
                        onChange={(e) => {
                            if (disabled) return
                            this.setState({ text: e.target.value || '' })
                            onChange && this.onChangeDebounce()
                        }}
                        onBlur={() => this.onBlur()}
                        onKeyDown={(evt) => evt.stopPropagation()}
                    />
                    {buttonLabel && (
                        <button
                            className={css.button}
                            type="submit"
                            disabled={(value || '') === text}
                            style={{ backgroundColor: buttonColor }}
                        >
                            {buttonLabel}
                        </button>
                    )}
                </div>
            </form>
        )
    }
}
