import { useDraggable } from '@dnd-kit/core'
import { giphyDarkGrey } from '@giphy/colors'
import { uniqueId } from 'lodash'
import { CSSProperties, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Coordinates, Transform } from 'types'
import { iconComponent } from '../../types/icons'
import useDragCss from './hooks/use-drag-css'
import useTransform from './hooks/use-drag-transform'
import useIsOverflow from './hooks/use-is-overflow'
import { Background, Container, Content, Ellipsis, Mover, Rotator, Scaler } from './style'

const DEFAULT_TRANSFORM = { x: 0, y: 0, rotation: 0, scaleX: 1, scaleY: 1 }
const EllipsisIcon = iconComponent('ellipsis')

type Props = {
    children?: ReactNode
    className?: string
    clipPath?: string
    defaultTransform?: Transform
    disabled?: boolean
    dndId?: string
    ellipsisColor?: string
    height: number
    hideBackground?: boolean
    ignoreAspectRatio?: boolean
    outlineColor?: string
    overflowColor?: string
    persistFocus?: boolean
    rotateable?: boolean
    rotatorColor?: string
    scaleable?: boolean
    scalerColor?: string
    style?: CSSProperties
    width: number
    onContextMenu?: (e: SyntheticEvent<HTMLElement, MouseEvent>) => void
    onDoubleClick?: (e: SyntheticEvent<HTMLElement, MouseEvent>) => void
    onDragEnd?: (transform: Transform) => void
    onDragMove?: (transform: Transform) => void
    onDragStart?: (transform: Transform) => void
    onEllipsisMenu?: (e: SyntheticEvent<HTMLElement, MouseEvent>) => void
    onFocusChange?: (focused: boolean) => void
}

const Draggable = ({
    children,
    className,
    clipPath,
    defaultTransform = DEFAULT_TRANSFORM,
    disabled = false,
    dndId,
    ellipsisColor = '#fff',
    height,
    hideBackground = false,
    ignoreAspectRatio = false,
    outlineColor = '#fff',
    overflowColor = giphyDarkGrey,
    persistFocus = false,
    rotateable = false,
    rotatorColor = '#fff',
    scaleable = false,
    scalerColor = '#fff',
    style = {},
    width,
    onContextMenu,
    onDoubleClick,
    onDragEnd,
    onDragMove,
    onDragStart,
    onEllipsisMenu,
    onFocusChange,
}: Props) => {
    const idRef = useRef<string>(dndId || uniqueId('draggable'))
    const moverId = `${idRef.current}-mover`
    const rotatorId = `${idRef.current}-rotator`
    const scalerId = `${idRef.current}-scaler`

    const [{ x, y }, setTranslate] = useState<Coordinates>({ x: 0, y: 0 })
    const [scale, setScale] = useState<Coordinates>({ x: 0, y: 0 })
    const [rotation, setRotation] = useState<number>(0)
    const [isFocused, setIsFocused] = useState<boolean | undefined>()

    const { isDragging: isMoving } = useDraggable({ id: moverId, disabled })
    const { isDragging: isRotating } = useDraggable({ id: rotatorId, disabled })
    const { isDragging: isScaling } = useDraggable({ id: scalerId, disabled })
    const isDragging = isMoving || isRotating || isScaling

    const position = useTransform(defaultTransform, { x, y, rotation, scaleX: scale.x, scaleY: scale.y })
    const center = useMemo(() => ({ x: width / 2, y: height / 2 }), [height, width])
    const dragStyle = useDragCss(position, width, height)
    const scaleAspectRatio = defaultTransform.scaleX / defaultTransform.scaleY
    const pointerEvents = disabled ? 'none' : undefined

    const rotatorIsOverflow = useIsOverflow({ x: Math.round(width / 2), y: 0 }, defaultTransform, clipPath)
    const scalerIsOverflow = useIsOverflow({ x: width, y: height }, defaultTransform, clipPath)
    const ellipsisIsOverflow = useIsOverflow({ x: width, y: 0 }, defaultTransform, clipPath)

    const onBlur = useCallback(() => setIsFocused(false), [])
    const onFocus = useCallback(() => setIsFocused(true), [])

    useEffect(() => {
        if (typeof isFocused === 'boolean') {
            onFocusChange?.(isFocused)
        }
    }, [isFocused])

    useEffect(() => {
        isDragging && onDragMove?.(position)
    }, [position])

    useEffect(() => {
        if (isDragging) {
            onDragStart?.(position)
        } else {
            onDragEnd?.(position)
            setTranslate({ x: 0, y: 0 })
            setScale({ x: 0, y: 0 })
            setRotation(0)
        }
    }, [isDragging])

    return (
        <Container
            className={className}
            focused={isFocused || persistFocus}
            style={{ ...dragStyle, ...style, pointerEvents }}
            tabIndex={0}
            onBlur={onBlur}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            onFocus={onFocus}
        >
            {!hideBackground && !disabled && (
                <Background
                    animated={isFocused || persistFocus}
                    clipPath={clipPath}
                    outlineColor={outlineColor}
                    overflowColor={overflowColor}
                />
            )}
            {children && <Content>{children}</Content>}
            {!disabled && (
                <>
                    <Mover dndId={moverId} onMove={setTranslate} />
                    {rotateable && (
                        <Rotator
                            center={center}
                            color={rotatorIsOverflow ? overflowColor : rotatorColor}
                            dndId={rotatorId}
                            onRotate={setRotation}
                        />
                    )}
                    {scaleable && (
                        <Scaler
                            aspectRatio={ignoreAspectRatio ? undefined : scaleAspectRatio}
                            center={center}
                            color={scalerIsOverflow ? overflowColor : scalerColor}
                            dndId={scalerId}
                            rotation={position.rotation}
                            onScale={setScale}
                        />
                    )}
                    {onEllipsisMenu && (
                        <Ellipsis onClick={onEllipsisMenu}>
                            <EllipsisIcon color={ellipsisIsOverflow ? overflowColor : ellipsisColor} />
                        </Ellipsis>
                    )}
                </>
            )}
        </Container>
    )
}

export default Draggable
