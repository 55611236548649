import { giphyIndigo, giphyPurple, giphyWhite } from '@giphy/colors'
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { desktop } from '../../css'
import { EllipsisIcon } from '../../icons/utility'
import Button from '../controls/button'
import { HeaderContext } from '../header'
import { Ellipsis, MenuItem, MenuLink, MenuList } from './desktop-sc'
import UserMenu from './desktop/user-menu'

const NavContainer = styled.div`
    display: none;
    color: ${giphyWhite};
    ${desktop(css`
        display: flex;
    `)}
    flex: 1;
    gap: 10px;
    margin-left: 10px;
    position: relative;
`

const Buttons = styled.div`
    display: none;
    ${desktop(css`
        display: flex;
        gap: 4px;
    `)}
`

const Links = [
    { title: 'Reactions', href: '/reactions' },
    { title: 'Entertainment', href: '/entertainment' },
    { title: 'Sports', href: '/sports' },
    { title: 'Stickers', href: '/stickers' },
    { title: 'Artists', href: '/artists' },
] as const

const Bstyle = { borderRadius: 2, height: 36, width: 80 }

const DesktopNav = () => {
    const { setMenuState } = useContext(HeaderContext)
    return (
        <>
            <NavContainer>
                <MenuList>
                    {Links.map(({ title, href }) => (
                        <MenuItem key={title}>
                            <MenuLink href={href}>{title}</MenuLink>
                        </MenuItem>
                    ))}
                    <Ellipsis onMouseEnter={() => setMenuState('desktop')} onMouseLeave={() => setMenuState(undefined)}>
                        <MenuLink as="div">
                            <EllipsisIcon height={15} />
                        </MenuLink>
                    </Ellipsis>
                </MenuList>
                <Buttons>
                    <Button
                        gradient="indigo-purple"
                        href="/upload"
                        style={{
                            ...Bstyle,
                            background: giphyIndigo,
                        }}
                    >
                        <div>Upload</div>
                    </Button>
                    <Button
                        gradient="purple-indigo"
                        href="/create"
                        style={{
                            ...Bstyle,
                            background: giphyPurple,
                        }}
                    >
                        <div>Create</div>
                    </Button>
                </Buttons>
                <UserMenu />
            </NavContainer>
        </>
    )
}

export default DesktopNav
