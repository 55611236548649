import { giphyLightGrey, giphyWhite } from '@giphy/colors'
import { getPingbackId } from '@giphy/js-util'
import { Toggle } from '@giphy/react-giphy-brand'
import { without } from 'lodash'
import Mousetrap from 'mousetrap'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { relatedClips, webFetch } from 'shared/api'
import ClipsCarousel from 'shared/components/clips-carousel'
import Grid from 'shared/components/grid'
import AttributionGrid from 'shared/components/sticker-attribution/attribution-grid.tsx'
import gifProps from 'shared/prop-types/gif'
import { toggleEditMode, updateEditMode } from 'shared/redux/edit-mode'
import { receivedGifs } from 'shared/redux/gifs'
import { getEditModeIsEditing, getUser, getAdsEnabled } from 'shared/redux/reducers'
import { getUrl } from 'shared/util/gif-extract'
import { getTitle } from 'shared/util/gif-text'
import { grid9 } from 'shared/util/grid'
import { GIFMAKER_CREATION_LOCATIONS } from '../../gif-maker/components/finalize/upload/constants'
import Attribution from '../containers/attribution'
import ArcadeCTA from './arcade-cta.tsx'
import css from './detail.css'
import GifDetailGif from './gif/index'
import Status from './gif/status'
import { trackGA4NavigationEvent } from './navigation-ga4'
import TopLeaderboardAd from '../../../modules/header/components/top-leaderboard-ad'
import MediumRectangleAd from '../components/medium-rectangle-ad'

const GIFS_GRADIENT = `linear-gradient(-135deg, rgb(74, 144, 226) 0%, rgb(148, 56, 255) 100%)`
const STICKERS_GRADIENT = `linear-gradient(-135deg, rgb(0, 231, 149) 0%, rgb(0, 187, 221) 100%)`

/**
 * GIF Detail: Controls the main GIF Detail component
 * and layout.
 *
 * @class GifDetail
 */
@withRouter
@connect(
    (state) => ({
        isEditing: getEditModeIsEditing(state),
        loggedInUser: getUser(state),
        adsEnabled: getAdsEnabled(state),
    }),
    { updateEditMode, toggleEditMode, receivedGifs }
)
export default class Detail extends PureComponent {
    state = {
        gridType: 'related',
        hasRelated: false,
        relatedClips: [],
        relatedStickers: [],
    }
    static propTypes = {
        gif: gifProps.isRequired,
        prevGif: gifProps,
        nextGif: gifProps,
        permissions: PropTypes.object,
        isEditOn: PropTypes.bool,
        importType: PropTypes.string,
    }
    updateDocumentTitle(gif) {
        this.setState({
            documentTitle: gif
                ? gif.is_sticker
                    ? `${getTitle(gif)} for iOS & Android | GIPHY`
                    : `${getTitle(gif)} - Find & Share on GIPHY`
                : 'Find & Share on GIPHY',
        })
    }
    setCorrectGridType(gif) {
        if (gif && gif.has_attribution) {
            this.setState({ gridType: 'stickerAttribution' })
        }
    }

    async componentWillMount() {
        const {
            gif: { id },
            gif,
        } = this.props
        this.setCorrectGridType(gif)
        this.hasRelatedGifs(gif.id)
        this.updateDocumentTitle(gif)
        const result = await relatedClips(id, getPingbackId())
        this.setState({ relatedClips: result.data })
    }

    hasRelatedGifs = (gif_id) => {
        this.fetchGifs(gif_id).then((result) => {
            if (result.data.length) {
                this.setState({ hasRelated: true })
                let relatedStickers = result.data.filter((gif) => gif.is_sticker)
                this.setState({ relatedStickers })
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.gif.id !== nextProps.gif.id) {
            this.setState({ hasRelated: false })
            if (nextProps.gif.has_attribution) {
                this.setState({ gridType: 'stickerAttribution' })
            } else {
                this.setState({ gridType: 'related' })
            }
            this.hasRelatedGifs(nextProps.gif.id)
            this.updateDocumentTitle(nextProps.gif)
        }
    }

    componentDidMount() {
        // activate fullscreen mode
        Mousetrap.bind('up', () => {
            const {
                gif: { path },
                history: { replace },
            } = this.props
            replace(`${path}/fullscreen`)
            return false
        })

        // activate tile mode
        Mousetrap.bind('shift+up', () => {
            const {
                gif: { path },
                history: { replace },
            } = this.props
            replace(`${path}/tile`)
            return false
        })

        // reset takeover
        Mousetrap.bind(['down', 'shift+down', 'esc'], () => {
            this.resetTakeoverMode()
            return false
        })

        // reset takeover (esc)
        Mousetrap.bind('esc', () => {
            this.resetTakeoverMode()
            return false
        })

        // prev gif
        Mousetrap.bind('left', () => {
            const {
                prevGif,
                history: { push },
                match: {
                    params: { mode = '' },
                },
            } = this.props
            prevGif && push(`${prevGif.path}/${mode}`)
            return false
        })

        // next gif
        Mousetrap.bind('right', () => {
            const {
                nextGif,
                history: { push },
                match: {
                    params: { mode = '' },
                },
            } = this.props
            nextGif && push(`${nextGif.path}/${mode}`)
            return false
        })

        // edit mode
        Mousetrap.bind('command+i', () => {
            const {
                gif: { path },
                permissions: { edit },
                history: { replace },
            } = this.props
            edit && replace(`${path}/edit`)
            return false
        })

        this.props.mode === 'edit' && this.openEditPanel()
    }
    componentDidUpdate({ isEditing: prevIsEditing, mode: prevMode }) {
        const { mode, isEditing } = this.props
        const isEdit = mode === 'edit'

        if ((isEdit && mode !== prevMode) || isEditing) {
            this.openEditPanel()
        }
        if (!isEditing && isEditing !== prevIsEditing) {
            this.resetTakeoverMode()
        }
    }
    componentWillUnmount() {
        this.historyListener && this.historyListener()
        Mousetrap.unbind(['up', 'down', 'left', 'right', 'shift+up', 'shift+down', 'esc'])
    }

    openEditPanel() {
        const { gif, updateEditMode, toggleEditMode } = this.props
        updateEditMode(gif)
        toggleEditMode(true)
    }

    resetTakeoverMode() {
        const {
            gif: { path },
            history: { replace },
            match: {
                params: { mode },
            },
        } = this.props
        mode && replace(path)
    }

    handleGridSwap = () => {
        const { gridType } = this.state
        this.setState({ gridType: gridType === 'related' ? 'stickerAttribution' : 'related' })
    }

    fetchGifs = (gif_id, offset) => webFetch.related(gif_id, { offset, rating: 'pg-13' })

    render() {
        const {
            gif,
            match: {
                params: { mode = '' },
            },
            importType,
            loggedInUser,
            adsEnabled,
        } = this.props
        const { gridType, documentTitle, hasRelated, relatedClips, relatedStickers } = this.state
        const { is_sticker, tags, has_attribution } = gif
        const sanitizedTags = without(tags, 'transparent')
        const showStatus = is_sticker && importType === GIFMAKER_CREATION_LOCATIONS.DESKTOP.STICKER
        const usersMatch = loggedInUser?.id === gif.user?.id
        const showArcadeRemixCTA = showStatus && usersMatch
        const showArcadeCreateCTA = showArcadeRemixCTA === false && is_sticker

        return (
            <div className={css.container}>
                <TopLeaderboardAd enabled={adsEnabled} pageName="detail" />
                <MediumRectangleAd enabled={adsEnabled && !loggedInUser?.loggedIn} pageName="detail" />
                <Helmet>
                    <title>{documentTitle}</title>
                </Helmet>
                <div className={css.sidebar}>
                    <Attribution isCompact={true} gif={gif} />
                    {showArcadeCreateCTA && (
                        <ArcadeCTA key={gif.id} gif={gif} relatedStickers={relatedStickers} remix={false} />
                    )}
                    {showArcadeRemixCTA && (
                        <ArcadeCTA key={gif.id} gif={gif} relatedStickers={relatedStickers} remix={true} />
                    )}
                </div>
                <div className={css.content}>
                    <GifDetailGif gifId={gif.id} isSticker={is_sticker} tags={sanitizedTags}>
                        <>{showStatus && <Status gif={gif} />}</>
                    </GifDetailGif>
                    <div>
                        {!!relatedClips.length && (
                            <div className={css.clipsContainer}>
                                <ClipsCarousel
                                    onClipClick={(gif, index) =>
                                        trackGA4NavigationEvent(gif.id, gif.url, index, 'clips')
                                    }
                                    clips={relatedClips}
                                    title={'Related Clips'}
                                    maxItems={3}
                                />
                            </div>
                        )}
                        {has_attribution && hasRelated ? (
                            <div className={css.toggleContainer}>
                                <Toggle
                                    toggle={() => {
                                        this.handleGridSwap('related')
                                    }}
                                    isOn={gridType === 'related'}
                                    height={35}
                                    width={280}
                                    offColor={STICKERS_GRADIENT}
                                    onColor={GIFS_GRADIENT}
                                    onElement={<div className={css.toggleText}>Related GIFs </div>}
                                    offElement={<div className={css.toggleText}>Stickers Used</div>}
                                    borderRadius={22}
                                    backgroundColor={`transparent`}
                                    iconOn={giphyWhite}
                                    iconOff={giphyLightGrey}
                                    style={{ marginBottom: `10px` }}
                                />
                            </div>
                        ) : (
                            <div className={css.subheader}>
                                {gridType === 'related' && hasRelated
                                    ? 'Related GIFs'
                                    : gridType === 'stickerAttribution'
                                    ? 'Stickers Used'
                                    : ''}{' '}
                            </div>
                        )}
                        {gridType === 'stickerAttribution' && (
                            <AttributionGrid
                                key={`${gif.id}-${gridType}`}
                                overrideWidth={grid9}
                                columns={3}
                                gif={gif}
                            />
                        )}
                        {gridType === 'related' && (
                            <Grid
                                key={`${gif.id}-${gridType}`}
                                fetchGifs={() => this.fetchGifs(gif.id)}
                                width={grid9}
                                onGifGAClick={(gif, index) => trackGA4NavigationEvent(gif.id, gif.url, index, 'gifs')}
                            />
                        )}
                    </div>
                </div>
                {mode === 'tile' && (
                    <div
                        className={css.takeover}
                        data-sticker={is_sticker}
                        title="Back to GIF"
                        style={{ backgroundImage: `url(${getUrl(gif)})` }}
                        onClick={() => this.resetTakeoverMode()}
                    />
                )}
            </div>
        )
    }
}
