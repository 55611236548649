import { giphyLightCharcoal, giphyWhite } from '@giphy/colors'
import { ReactNode, useCallback, useContext, useEffect } from 'react'
import { useKeyPress } from 'react-use'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { IChannel } from 'shared/types/channel'
import styled, { css } from 'styled-components'
import Breadcrumbs from './add-to-collection/breadcumbs'

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${giphyWhite};
    font-family: 'interface', sans-serif;
    font-weight: bold;
    border-bottom: 1px solid ${giphyLightCharcoal};
    padding: 14px 0px 13px 14px;
    margin-bottom: 10px;
    z-index: 1;
`

const TitleDiv = styled.div`
    display: flex;
    cursor: pointer;
`

const BackButtonContainer = styled.div`
    width: 25px;
    display: flex;
    align-items: center;
    margin-bottom: -4px;
`

const Title = styled.div<{ reduceWidth: boolean }>`
    box-sizing: border-box;
    font-size: 16px;
    flex-direction: row;
    display: flex;
    max-height: 23px;
    overflow: hidden;
    white-space: nowrap;
    max-width: ${({ reduceWidth }) => reduceWidth && `400px`};
`

const RightIcon = styled.div<{ isOpen?: boolean }>`
    cursor: pointer;
    position: absolute;
    ${({ isOpen }) =>
        !isOpen &&
        css`
            background: linear-gradient(45deg, rgb(153, 51, 255) 0%, rgb(49, 145, 255) 100%);
        `}
    border-radius: 0px 4px 2px 0px;
    height: 45px;
    width: 45px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    span {
        margin-top: 5px;
    }
`
const CloseButton = styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    cursor: pointer;
    width: 25px;
`
type Props = {
    title?: string | ReactNode
    RightIcon?: ReactNode
    onClose?: () => void
    onBack?: () => void
    channel?: IChannel
    parent?: string
    closeIcon?: string

    // Collapse options
    isOpen?: boolean
    onClickCollapse?: () => void
}
const Header = ({ title, onClose = () => {}, onBack, channel, parent, isOpen, onClickCollapse }: Props) => {
    const [isEscPressed] = useKeyPress((event: KeyboardEvent) => event.key === 'Esc' || event.key === 'Escape')
    const { isDragging, gif } = useContext(CollectionContext)

    useEffect(() => {
        if (isEscPressed) {
            onClose()
        }
    }, [isEscPressed])

    const Crumbs = useCallback(() => {
        if (channel) {
            return <Breadcrumbs showRoot numberOfCrumbs={gif ? 2 : 4} channel={channel} />
        }
        return <></>
    }, [])

    return (
        <Container>
            <TitleDiv>
                {onBack && (
                    <BackButtonContainer onClick={onBack}>
                        <div className={isDragging && !!parent ? `ss-redirect` : 'ss-navigateleft'} />
                    </BackButtonContainer>
                )}
                <Title reduceWidth={!!gif}>
                    {isDragging && !!parent ? `Move to ${parent}` : channel ? <Crumbs /> : title}
                </Title>
            </TitleDiv>
            {onClickCollapse ? (
                <RightIcon isOpen={isOpen} onClick={onClickCollapse}>
                    <span className={isOpen ? 'ss-navigateup' : 'ss-navigatedown'} />
                </RightIcon>
            ) : (
                <CloseButton onClick={onClose}>
                    <div className={'ss-delete'} />
                </CloseButton>
            )}
        </Container>
    )
}

export default Header
