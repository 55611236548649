exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css"), undefined);
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/fonts.css"), undefined);

// module
exports.push([module.id, "._3XxUscFeFe6i-6HWC4XQjd {\n    border-bottom: 2px solid " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyDarkGrey"] + ";\n    padding: 24px 0 4px 0;\n}\n\n._3XxUscFeFe6i-6HWC4XQjd ul {\n        display: flex;\n        flex-direction: column;\n        text-align: left;\n        margin: 0;\n\t}\n\n._2d307HIbw8FhSNGy7vhMvw {\n    text-align: left;\n    margin-bottom: 20px;\n}\n\n.Ny5B9yJbElcPujvYOewUG {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n._1Ae1T1_5TnU-TtmIT2jFvT {\n    width: 40px;\n    height: 40px;\n}\n\n._1bfUfIAMzf5P95XyES2s-H {\n    margin-left: 15px;\n    color: " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n    font-family: " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/fonts.css").locals["bodyFont"] + ";\n    font-size: 16px;\n    font-weight: 900;\n    letter-spacing: 0.01em;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}", ""]);

// exports
exports.locals = {
	"typography": "'shared/css/typography.css'",
	"sectionHeading": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["sectionHeading"] + "",
	"colors": "'shared/css/colors.css'",
	"giphyDarkGrey": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyDarkGrey"] + "",
	"giphyWhite": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + "",
	"fonts": "'shared/css/fonts.css'",
	"bodyFont": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/fonts.css").locals["bodyFont"] + "",
	"container": "_3XxUscFeFe6i-6HWC4XQjd",
	"title": "_2d307HIbw8FhSNGy7vhMvw " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["sectionHeading"] + "",
	"channelItem": "Ny5B9yJbElcPujvYOewUG",
	"avatar": "_1Ae1T1_5TnU-TtmIT2jFvT",
	"name": "_1bfUfIAMzf5P95XyES2s-H"
};