import { giphyLightestGrey } from '@giphy/colors'
import { SelectSearchOption, SelectedOptionValue } from 'react-select-search'
import styled from 'styled-components'
import LanguageInput from './language-input'
import LanguageListItem from './language-list-item'
import SelectSearch from './select-search-wrapper'

const InputWrapper = styled.div``

const Arrow = styled.div`
    background: transparent;
    color: #fff;
    font-size: 12px;
    height: 30px;
    line-height: 32px;
    position: relative;
    text-align: center;
    text-indent: 2px;
    width: 30px;
    position: absolute;
    right: 0;
    top: -5px;
`

const ListWrapper = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
`

const StyledList = styled.ul`
    position: absolute;
    background: #fff;
    max-height: 219px;
    overflow: scroll;
    width: inherit;
    margin-top: 12px;
`

const ListElement = styled.li<{ highlighted: boolean }>`
    height: 32px;
    width: inherit;
    background: ${(props) => (props.highlighted ? giphyLightestGrey : '#fff')};

    &:first-child {
        height: 41px;
    }

    &:first-child button {
        padding-top: 9px;
    }

    &:hover {
        background: ${giphyLightestGrey};
    }
`

type Props = {
    options: SelectSearchOption[]
    defaultValue: string
    onChange: (value: SelectedOptionValue) => void
}

const LanguageSelect = ({ options, defaultValue, onChange }: Props) => {
    const [snapshot, valueProps, optionProps] = SelectSearch({
        options: options,
        defaultValue: defaultValue,
        onChange: onChange,
    })

    const displayedValue = snapshot.displayValue
    const valProps = {
        ...valueProps,
        placeholder: snapshot.focus ? displayedValue.substr(displayedValue.indexOf(' ') + 1).trim() : displayedValue,
        value: snapshot.search,
    }

    return (
        <InputWrapper className="select-search">
            <LanguageInput valueProps={valProps} focused={snapshot.focus} />
            {snapshot.focus && (
                <ListWrapper className="select-search__select">
                    <Arrow className="ss-directup"></Arrow>
                    <StyledList className="select-search__options">
                        {snapshot.options.map((option, index) => {
                            const listProps = {
                                role: 'menuitem',
                                'data-index': index,
                                'data-value': option.value,
                            }
                            var optProps = { ...optionProps }
                            optProps.value = option.value
                            return (
                                <ListElement
                                    className="select-search__row"
                                    key={option.value}
                                    highlighted={snapshot.highlighted === index}
                                    {...listProps}
                                >
                                    <LanguageListItem
                                        className="select-search__option"
                                        highlighted={snapshot.highlighted === index}
                                        selected={snapshot.value.value === option.value}
                                        optionData={option}
                                        optionProps={optProps}
                                    ></LanguageListItem>
                                </ListElement>
                            )
                        })}
                    </StyledList>
                </ListWrapper>
            )}
        </InputWrapper>
    )
}

export default LanguageSelect
