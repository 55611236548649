import { useContext } from 'react'
import styled, { css } from 'styled-components'
import VideoContext from '../video-context'
import Attribution from './attribution'
import Volume from './volume'

const Placeholder = styled.div`
    flex: 1;
`

const Container = styled.div<{ isSmall: boolean; noPadding: boolean; isVisible: boolean; dimSize: number }>`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 60px;
    left: 0;
    padding: 0 ${(props) => (props.noPadding ? 10 : 60)}px 0 20px;
    position: absolute;
    top: 0;
    transform: translateY(${(props) => (props.isVisible ? 0 : -100)}%);
    transition: transform 0.2s ease-out;
    width: 100%;
    z-index: 1;

    &:before {
        background: linear-gradient(rgba(18, 18, 18, 0.6), rgba(0, 0, 0, 0));
        content: '';
        height: ${(props) => props.dimSize}px;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    ${(props) =>
        props.isSmall &&
        css`
            padding-left: 10px;
            padding-right: 0;
        `};
`

type Props = {
    isVisible: boolean
    dimSize: number
    onMouseOver: () => void
}

const Header = ({ dimSize = 0, isVisible = false, onMouseOver }: Props) => {
    const { isSmall, noCutout, showAttribution } = useContext(VideoContext)
    return (
        <Container
            dimSize={dimSize}
            isSmall={isSmall}
            isVisible={isVisible}
            noPadding={noCutout}
            onMouseOver={onMouseOver}
        >
            {showAttribution ? <Attribution /> : <Placeholder />}
            <Volume />
        </Container>
    )
}

export default Header
