import * as colors from '@giphy/colors'
import { GifOverlayProps } from '@giphy/react-components'
import { useState } from 'react'
import { useWindowSize } from 'react-use'
import Filters from 'shared/components/artists/filters'
import Grid from 'shared/components/artists/grid'
import SelectList from 'shared/components/form/ui/select-list'
import { WhenInView } from 'shared/util/in-view'
import styled, { keyframes } from 'styled-components'
import Artist from './attribution'

const GUTTER = 6
const BANNER_RATIO = 0.15

const slideIn = keyframes`
  from {
    overflow: hidden;
    max-height: 0;
  }

  to {
    overflow: visible;
    max-height: 29px;
  }
`

const Container = styled.div`
    padding: 6px 0 20px;
    position: relative;
    text-align: left;
`

const Banner = styled.div`
    background: no-repeat url('/static/img/artistdirectory_750.gif') center top;
    background-size: contain;
    margin-bottom: 12px;
    width: 100%;
`

const FilterWrapper = styled.div<{ isFilterVisible: boolean }>`
    margin-bottom: 15px;
    overflow: hidden;
    animation: ${({ isFilterVisible }) => isFilterVisible && slideIn} 300ms ease-out forwards;
`

const ForWorkToggle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
`

const SortSelectList = styled(SelectList)`
    margin-left: 10px;
    background: transparent;
    & span {
        display: none;
    }
    .ss-dropdown,
    .ss-directup {
        color: ${colors.giphyBlue};
        right: 12px;
        top: 1px;
    }
    > div:nth-child(2) {
        width: 220px;
        position: absolute;
        left: -64px;
    }
    .ss-delete,
    .ss-check,
    .ss-plus {
        position: absolute;
        right: 0px;
    }
`

const ControlsBar = styled.div<{ isFilterVisible: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: ${({ isFilterVisible }) => (isFilterVisible ? `0px` : `10px;`)};
`

const UserElement = ({ gif }: GifOverlayProps) => <Artist user={gif.user} />

const SortOptions = styled(ForWorkToggle)`
    color: ${colors.giphyWhite};
`

const ArtistsDirectory = () => {
    const { width } = useWindowSize()
    const bannerHeight = Math.round(width * BANNER_RATIO)

    const [filter, setFilter] = useState<string>('')
    const [sortType, setSortType] = useState<string>('updatetime')

    const setSortMode = (option: string) => {
        setFilter('')
        setSortType(option === 'Alphabetical' ? 'username' : 'updatetime')
    }
    const setLetter = (letter: string) => {
        setFilter(letter)
    }

    const isFilterVisible = sortType === 'username'
    return (
        <Container>
            <WhenInView height={bannerHeight}>
                <Banner style={{ height: bannerHeight }} />
            </WhenInView>
            <ControlsBar isFilterVisible={isFilterVisible}>
                <SortOptions>
                    Sort By{' '}
                    <SortSelectList
                        onChange={(option) => setSortMode(option)}
                        options={['Recently Updated', 'Alphabetical']}
                        title={'SORT BY'}
                        placeholder="Recently Updated"
                        allowMultiple={false}
                        themeId={'transparentDark'}
                        nipX={`calc(100% - 152px)`}
                    />
                </SortOptions>
            </ControlsBar>
            {isFilterVisible && (
                <FilterWrapper isFilterVisible={isFilterVisible}>
                    <Filters filter={filter} setLetter={setLetter} />
                </FilterWrapper>
            )}
            <Grid filter={filter} sort={sortType} overlay={UserElement} width={width} columns={2} gutter={GUTTER} />
        </Container>
    )
}

export default ArtistsDirectory
