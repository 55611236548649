import { createContext, FC, useState } from 'react'
import CaretRightIcon from '../icons/svg/caret-right-icon'
import CloseIcon from '../icons/svg/close-icon'
import Portal from '../portal'
import { FlashMessageConfig, FlashMessageNamesType, FLASH_MESSAGES, FLASH_MESSAGE_TEMPLATES } from './constants'
import {
    FlashMessageContent,
    FlashMessageContentIcon,
    FlashMessageContentMessage,
    FlashMessageContentTitle,
    FlashMessageContentTitleWrapper,
    FlashMessageDismiss,
    FlashMessageLink,
    FlashMessageSpacer,
    FlashMessageWrapper,
} from './flash-message.styles'

interface FlashMessageProps {
    flashMessage?: FlashMessageNamesType
    flashMessageData?: FlashMessageConfig
    onDismiss?: () => void
    index: number
}

const FlashMessage: FC<FlashMessageProps> = ({ flashMessage, flashMessageData, onDismiss, index }) => {
    const [dismissed, setDismissed] = useState(false)

    const dismissCallback = () => {
        setDismissed(true)
        if (onDismiss) onDismiss()
    }
    if (!flashMessage && !flashMessageData) return null
    const finalFlashMessageData = flashMessage ? FLASH_MESSAGES[flashMessage] : flashMessageData

    if (dismissed || !finalFlashMessageData) return null
    const MessageComponent = finalFlashMessageData?.messageComponent
    return (
        <Portal>
            <FlashMessageWrapper
                gradientColors={finalFlashMessageData.gradientColors}
                textColor={finalFlashMessageData.textColor}
                index={index}
            >
                <FlashMessageSpacer />
                {!MessageComponent ? (
                    <FlashMessageContent>
                        {(finalFlashMessageData.icon || finalFlashMessageData.title) && (
                            <FlashMessageContentTitleWrapper>
                                <FlashMessageContentIcon>{finalFlashMessageData.icon}</FlashMessageContentIcon>
                                <FlashMessageContentTitle>{finalFlashMessageData.title}</FlashMessageContentTitle>
                            </FlashMessageContentTitleWrapper>
                        )}
                        <FlashMessageContentMessage>{finalFlashMessageData.message}</FlashMessageContentMessage>
                        {finalFlashMessageData.linkURL && (
                            <FlashMessageLink href={finalFlashMessageData.linkURL}>
                                {finalFlashMessageData.linkText} <CaretRightIcon width={15} height={15} />
                            </FlashMessageLink>
                        )}
                    </FlashMessageContent>
                ) : (
                    <MessageComponent>{finalFlashMessageData.message}</MessageComponent>
                )}

                <FlashMessageDismiss onClick={dismissCallback}>
                    <CloseIcon width={25} height={25} />
                </FlashMessageDismiss>
            </FlashMessageWrapper>
        </Portal>
    )
}

type ShowMessageType = FlashMessageConfig & { messageType?: 'success' | 'failure'; slug?: FlashMessageNamesType }

export const FlashMessageContext = createContext(
    {} as {
        showMessage: (settings: ShowMessageType) => void
        hideMessage: () => void
        messageQueue: FlashMessageConfig[]
    }
)

export const FlashMessagesProvider = ({ children }) => {
    const [messageQueue, setMessageQueue] = useState<FlashMessageConfig[]>([])

    const showMessage = ({
        gradientColors,
        icon,
        title,
        message,
        linkText,
        linkURL,
        textColor,
        messageType,
        slug,
        ...rest
    }: FlashMessageConfig & { messageType: 'success'; slug: FlashMessageNamesType }) => {
        const createdAt = Math.random()

        if (slug?.length) {
            setMessageQueue((messageQueue) => [...messageQueue, { ...FLASH_MESSAGES[slug], createdAt }])
            return
        }

        // By default, use the Success template
        const flashMessageData = { ...FLASH_MESSAGE_TEMPLATES.success, ...rest } as FlashMessageConfig

        // If a message type is defined, search for a template with the same name and use its settings
        if (messageType?.length) {
            flashMessageData.gradientColors = FLASH_MESSAGE_TEMPLATES[messageType].gradientColors
            flashMessageData.icon = FLASH_MESSAGE_TEMPLATES[messageType].icon
            flashMessageData.title = FLASH_MESSAGE_TEMPLATES[messageType].title
            flashMessageData.message = FLASH_MESSAGE_TEMPLATES[messageType].message
            flashMessageData.linkText = FLASH_MESSAGE_TEMPLATES[messageType].linkText
            flashMessageData.linkURL = FLASH_MESSAGE_TEMPLATES[messageType].linkURL
            flashMessageData.textColor = FLASH_MESSAGE_TEMPLATES[messageType].textColor
        }

        // Override settings with custom values if they are provided (higher priority)

        if (gradientColors !== undefined) {
            flashMessageData.gradientColors = gradientColors
        }
        if (icon !== undefined) {
            flashMessageData.icon = icon
        }
        if (title !== undefined) {
            flashMessageData.title = title
        }
        if (message !== undefined) {
            flashMessageData.message = message
        }
        if (linkText !== undefined) {
            flashMessageData.linkText = linkText
        }
        if (linkURL !== undefined) {
            flashMessageData.linkURL = linkURL
        }
        if (textColor !== undefined) {
            flashMessageData.textColor = textColor
        }

        setMessageQueue((messageQueue) => [...messageQueue, { ...flashMessageData, createdAt }])
    }

    const removeFromQueue = () => {
        setMessageQueue((messageQueue) => {
            messageQueue.shift()
            return [...messageQueue]
        })
    }
    return (
        <FlashMessageContext.Provider value={{ showMessage, hideMessage: removeFromQueue, messageQueue }}>
            {messageQueue.map((currentMessage, index) => {
                return (
                    <FlashMessage
                        key={currentMessage.createdAt}
                        index={index}
                        flashMessageData={currentMessage}
                        onDismiss={index === 0 ? removeFromQueue : undefined}
                    />
                )
            })}
            {children}
        </FlashMessageContext.Provider>
    )
}

export default FlashMessage
