import { mapValues } from 'lodash'
import { matchPath } from 'react-router'

// the goal is for any component to be able to use:
// getRouteFromPathname(location.pathname) and figure out what
// route we're on to determine things like groupId, category, etc.

// we freeze the route objects,
// but for dynamic routes, their paths can change
const routes = Object.freeze(
    // I used `path` here because that's what `matchPath` uses :/
    // react router uses `pathname` so map'em for now (and refactor someday!)
    mapValues(
        {
            CATEGORIES: { path: '/categories/:subcategory?' },
            CREATE: { path: '/create/gifmaker' },
            CLIPS: { path: '/clips', exact: true },
            CLIPS_DETAIL: { path: '/clips/:id/:mode?', exact: true },
            UPLOAD: { path: '/upload' },
            DASHBOARD: { path: '/dashboard' },
            EXPLORE: { path: '/explore/:term' },
            FAVORITES: { path: '/favorites' },
            GIF_DETAIL: { path: '/(gifs|stickers)/:id/:mode?', exact: true },
            VIDEO_CHANNEL: { path: '/:username/videos', exact: true },
            APPLY: { path: '/apply', state: { headerSubtitle: 'Brand and Artist Application' } },
            EMAIL_JAIL: { path: `/email-jail` },
            SEARCH: { path: '/search/:term' },
            STUDIOS: { path: '/studios' },
            TEAM: { path: '/team', exact: true },
            EDIT_GIFS: { path: '/edit/gifs' },
            EDIT_GIF: { path: '/edit/:id' },
            ARTISTS: { path: '/artists/:letter?' },
            APPS: { path: '/apps/:app?' },
            TRANSPARENCY: { path: '/transparency/:year' },
            ABOUT: { path: '/about' },
            AR_SCENE_LANDING_PAGE: { path: '/ar-scenes/:id', exact: true },
            TAKEDOWN_APPEAL: { path: '/takedown-appeal/:id', state: { headerSubtitle: 'Content Takedown Appeal' } },
            // CHANNEL: dynamic route, starts off empty,
            // but at some point will get a path
            CHANNEL: {
                path: '/dontmatchme',
                exact: true,
            },
            CHANNEL_STORIES: {
                path: '/:username/stories',
                exact: true,
            },
            // CHANNE_LEAF: {} // we maybe could use this, not sure
            STORY: { path: '/stories/:slug' },
            POST: { path: '/posts/:slug', exact: true },
            SETTINGS: { path: '/settings', exact: true },
            // HOMEBETA: { path: '/homebeta', exact: true },
            TRENDING: { path: '/trending-gifs', exact: true },
            TRENDING_CLIPS: { path: '/clips', exact: true },
            HOMEBASE: { path: '/edit/home', exact: true },
            // home needs to be last
            HOME: { path: '/(home|page)?/:page?', exact: true },
            ERROR_404: { path: '/404', exact: true },
            ERROR_403: { path: '/403', exact: true },
            ERROR_503: { path: '/maintenance', exact: true },
        },
        (obj) => ({ ...obj, pathname: obj.path })
    )
)

export const getRouteFromPathname = (pathname) =>
    Object.values(routes).find((location) => matchPath(pathname, location))

export default routes
