import { useDraggable } from '@dnd-kit/core'
import { ReactNode, useEffect } from 'react'
import { Range } from 'types'
import { Fill } from './style'

type Props = {
    children?: ReactNode
    className?: string
    controlId: string
    controlType: string
    controlValue: Range
    disabled?: boolean
    max: number
    min: number
    onDrag?: (x: number) => void
    onDragEnd?: () => void
}

const RangeSliderDndFill = ({
    children,
    className,
    controlId,
    controlType,
    controlValue,
    disabled,
    max,
    min,
    onDrag,
    onDragEnd,
}: Props) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
        disabled,
        id: `${controlId}-fill`,
        data: {
            control: 'range-slider',
            controlId,
            controlType,
            controlValue,
        },
    })

    useEffect(() => {
        if (!isDragging) {
            onDragEnd?.()
        }
    }, [isDragging])

    useEffect(() => {
        if (typeof transform?.x === 'number') {
            const newValue = Math.min(max, Math.max(min, transform.x))
            onDrag?.(newValue)
        }
    }, [transform?.x])

    return (
        <Fill {...listeners} {...attributes} $disabled={disabled} className={className} ref={setNodeRef}>
            {children}
        </Fill>
    )
}

export default RangeSliderDndFill
