import styled, { css } from 'styled-components'
import { ControlSize, Gradient } from 'types'
import { borderRadiusFromControlSize, fontSizeFromControlSize, gradientToCss, heightFromControlSize } from 'utils'
import IconButton from '../icon-button'
import { DropdownButtonTriggerMode } from './types'

export const ArrowButton = styled(IconButton)<{ $triggerMode: DropdownButtonTriggerMode }>`
    flex-shrink: 0;

    ${({ $triggerMode }) =>
        $triggerMode === 'full' &&
        css`
            &:active {
                opacity: 1;
            }
        `}
`

export const Button = styled.div<{ $active: boolean; $color: string; $size: ControlSize }>`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-weight: inherit;
    overflow: hidden;
    padding: 0 0.8em;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    width: 100%;

    ${({ $color, $size }) => css`
        color: ${$color};
        font-size: ${fontSizeFromControlSize($size)}px;
        min-width: ${heightFromControlSize($size)}px;
    `}

    ${({ $active }) =>
        $active &&
        css`
            cursor: pointer;
        `}
`

export const Container = styled.div<{
    $color: string
    $disabled: boolean
    $gradient?: Gradient
    $size: ControlSize
    $triggerMode: DropdownButtonTriggerMode
}>`
    display: flex;
    font-weight: ${({ $gradient }) => ($gradient ? 'bold' : 'normal')};

    ${({ $color, $gradient, $size }) => css`
        background: ${$gradient ? gradientToCss($gradient) : $color};
        border-radius: ${borderRadiusFromControlSize($size)}px;
        height: ${heightFromControlSize($size)}px;
    `}

    ${({ $triggerMode }) =>
        $triggerMode === 'full' &&
        css`
            &:active {
                opacity: 0.8;
            }
        `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`
