import { dimColor } from '@giphy/colors'
import { AnimatedButton } from 'shared/css/buttons'
import styled, { css, keyframes } from 'styled-components'

const TileAnimation = keyframes`
    0% {
        background-position: -27px -86px;
    }
    25% {
        background-position: -57px -86px;
    }
    50% {
        background-position: -87px -86px;
    }
    75% {
        background-position: -117px -86px;
    }
    100% {
        background-position: -117px -86px;
    }
`

const FullscreenAnimation = keyframes`
    0% {
        background-position: -27px -116px;
    }
    25% {
        background-position: -57px -116px;
    }
    50% {
        background-position: -87px -116px;
    }
    75% {
        background-position: -117px -116px;
    }
    100% {
        background-position: -117px -116px;
    }
`

const EditAnimation = keyframes`
   0% {
        background-position: -27px -267px;
    }
    25% {
        background-position: -57px -267px;
    }
    50% {
        background-position: -87px -267px;
    }
    75% {
        background-position: -117px -267px;
    }
    100% {
        background-position: -117px -267px;
    }
`

const ButtonCss = css`
    background-color: ${dimColor};
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
`

export const Edit = styled(AnimatedButton)`
    ${ButtonCss}
    background-position: 0 -267px;
    float: right;
    margin: 0;
    vertical-align: top;
    background-size: 186px 300px;

    &:hover {
        background-size: 180px 300px;
        animation: ${EditAnimation} 0.4s steps(1) infinite;
    }
`

export const Tile = styled(AnimatedButton)`
    ${ButtonCss}
    background-position: 3px -86px;
    &:hover {
        animation: ${TileAnimation} 0.4s steps(1) infinite;
    }
`

export const Fullscreen = styled(AnimatedButton)`
    ${ButtonCss}
    background-position: 3px -116px;
    &:hover {
        animation: ${FullscreenAnimation} 0.4s steps(1) infinite alternate;
    }
`
