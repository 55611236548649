import { createContext } from 'react'
import { SendMessageAction } from 'shared/redux/message'

export type MessageContextProps = {
    sendMessage: SendMessageAction
}

const MessageContext = createContext({} as MessageContextProps)

export default MessageContext
