import { giphyBlack } from '@giphy/colors'
import { Attribution } from '@giphy/react-giphy-brand'
import CornerCutout from 'shared/components/ui/corner-cutout'
import styled from 'styled-components'

const Wrapper = styled.a`
    box-sizing: border-box;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease-out;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
`

const CutoutContainer = styled.div`
    position: absolute;
    right: -50px;
    top: -50px;
    transition: all 0.2s ease-out;
    width: 48px;
    height: 48px;
`
const MovingCutout = styled(CornerCutout)`
    background-color: ${giphyBlack};
    transition: all 0.2s ease-out;
`

const Container = styled.div`
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: left;
    top: 0;
    transition: opacity 0.2s ease-out;
    width: 100%;

    &:before {
        content: '';
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
        bottom: 0;
        height: 70px;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
    }

    div:hover > & {
        ${Wrapper} {
            opacity: 1;
        }

        ${CutoutContainer} {
            top: 0;
            right: 0;
        }
    }
`

const User = styled.div`
    bottom: 10px;
    left: 10px;
    position: absolute;
    right: 10px;
`

const MinimalChannelPreview = ({ user }: any) => {
    return (
        <Container>
            <Wrapper href={'/' + user.username}>
                <CutoutContainer>
                    <MovingCutout isVisible />
                </CutoutContainer>
            </Wrapper>
            <User>
                <Attribution canViewForHire={true} user={user} />
            </User>
        </Container>
    )
}

export default MinimalChannelPreview
