import { giphyDarkestGrey, giphyLightestGrey, giphyWhite } from '@giphy/colors'
import * as React from 'react'
import { SetStateAction } from 'react'
import styled, { css } from 'styled-components'
import { valuesList } from './valuesList'

const Container = styled.div`
    width: 100%;
    margin-top: 46px;
`
const ScrollCarousel = styled.div`
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    height: 44px;
    background: ${giphyDarkestGrey};
    ::-webkit-scrollbar {
        display: none;
    }
`

const Pill = styled.div<{ gradient?: string | null }>`
    height: 100%;
    border-radius: 22px;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    padding: 0 15px;
    color: ${giphyLightestGrey};
    background: ${giphyDarkestGrey};
    ${({ gradient }) =>
        !!gradient &&
        css`
            background: ${gradient};
            color: ${giphyWhite};
            span {
                filter: drop-shadow(3px 1px 5px rgba(0, 0, 0, 0.25));
            }
        `};
`

type Props = {
    currentValue: string
    setValue: React.Dispatch<SetStateAction<number>>
}

const ValuesCarousel = ({ currentValue, setValue }: Props) => (
    <Container>
        <ScrollCarousel>
            {valuesList.map((value, key) => (
                <Pill
                    key={key}
                    onClick={() => setValue(key)}
                    gradient={value.name === currentValue ? value.gradient : null}
                >
                    <span>{value.name}</span>
                </Pill>
            ))}
        </ScrollCarousel>
    </Container>
)

export default ValuesCarousel
