import App from 'components/apps/app'
import Header from 'components/header/header'
import locationState from 'shared/components/with-location-state'
import Page from '../page'

const AppDetailPage = ({ app }) => (
    <Page>
        <Header />
        <App {...app} />
    </Page>
)

export default locationState([`app`])(AppDetailPage)
