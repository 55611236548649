import { giphyAqua, giphyDarkGrey } from '@giphy/colors'
import styled, { keyframes } from 'styled-components'

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
`

export const Pill = styled.a`
    display: inline-flex;
    padding: 0 16px;
    background-color: ${giphyDarkGrey};
    border-radius: 20px;
    line-height: 38px;
    justify-content: center;
    align-items: center;
    height: 40px;
    overflow: visible;
    animation-name: ${fadeInUp};
    animation-duration: 1s;
    &:active {
        opacity: 0.8;
    }
    &:hover {
        color: ${giphyAqua};
    }
`

export const TagText = styled.h3`
    margin: 0px;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 400;
    text-transform: lowercase;
    font-style: italic;
    letter-spacing: 0.04em;
    -webkit-font-smoothing: antialiased;
`
