import { giphyIndigo, giphyPurple } from '@giphy/colors'
import styled from 'styled-components'

const Shape = styled.div`
    background: linear-gradient(45deg, ${giphyIndigo}, ${giphyPurple});
    left: 0;
    position: absolute;
    top: 0;
`

export const Circle = styled(Shape)`
    border-radius: 50%;
    height: 270px;
    transform: translate(-50%, -50%) rotate(140deg);
    width: 270px;
`

export const Square = styled(Shape)`
    height: 230px;
    transform: translate(-50%, -50%) rotate(-75deg);
    width: 230px;
`

export const Rectangle = styled(Shape)`
    height: 450px;
    transform: translate(-50%, -50%) rotate(-25deg);
    width: 270px;
`

export const Corner = styled(Shape)`
    clip-path: polygon(0% 0%, 33% 0%, 33% 33%, 66% 33%, 66% 66%, 100% 66%, 100% 100%, 0% 100%);
    height: 320px;
    transform: translate(-50%, -50%) rotate(-7deg);
    width: 320px;
`
