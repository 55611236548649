exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1xGL15MkbsCj_IGKiPYW8I {\n    display: inline-block;\n    margin-right: 12px;\n    font-size: 14px;\n}\n\n    ._1xGL15MkbsCj_IGKiPYW8I:last-child {\n        margin-right: 0;\n    }", ""]);

// exports
exports.locals = {
	"rating": "_1xGL15MkbsCj_IGKiPYW8I"
};