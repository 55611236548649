import { CSSProperties, useMemo } from 'react'
import { Gradient } from 'types'
import { gradientToHexColors } from 'utils'
import { textColorFromBackgroundColor } from '../../../../utils/color'

export function useLabelStyle(
    active: boolean,
    backgroundColor: string,
    color: string,
    gradient?: Gradient
): CSSProperties {
    return useMemo(() => {
        if (!active) {
            if (backgroundColor !== 'transparent') {
                return {
                    color: textColorFromBackgroundColor(backgroundColor),
                }
            } else {
                return {
                    color: 'inherit',
                }
            }
        } else {
            const highlightColor = gradient ? gradientToHexColors(gradient)[0] : color

            return {
                color: textColorFromBackgroundColor(highlightColor),
            }
        }
    }, [active, backgroundColor, color, gradient])
}
