import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    left: 0;
    opacity: 0.3;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
`

const Video = styled.video`
    height: 100%;
    display: block;
    object-fit: cover;
    width: 100%;
`

const Dim = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    height: 300px;
    left: 0;
    position: absolute;
    width: 100%;
`

type Props = {
    className?: any
}

const BackgroundVideo = ({ className }: Props) => (
    <Container className={className}>
        <Video src="/static/img/videos/tron.mp4" muted playsInline autoPlay loop />
        <Dim />
    </Container>
)

export default BackgroundVideo
