import { Coordinates } from 'types'

const CURSORS = ['nwse-resize', 'ns-resize', 'nesw-resize', 'ew-resize']

export function getRotatedCursor(rotation: number): string {
    const index = Math.round(rotation / 45)
    return CURSORS[index % CURSORS.length]
}

export function getRotatedCoordinates(coordinates: Coordinates, rotation: number): Coordinates {
    const { x, y } = coordinates
    const radians = (Math.PI / 180) * rotation
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)

    return {
        x: cos * x + sin * y,
        y: cos * y - sin * x,
    }
}
