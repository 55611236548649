import { giphyBlack, giphyLightestGrey } from '@giphy/colors'
import styled, { css } from 'styled-components'
import IconButton from '../../controls/icon-button'

export const Container = styled.div<{ $hasSticker: boolean }>`
    background: ${giphyBlack};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    color: ${giphyLightestGrey};
    font-size: 17px;
    padding: 50px;
    position: relative;
    text-align: center;
    width: 480px;

    ${({ $hasSticker }) =>
        $hasSticker &&
        css`
            padding-top: 70px;
        `}
`

export const Buttons = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;

    > button {
        flex: 1;
    }
`

export const Description = styled.div`
    line-height: 1.4;
    margin: 0 auto 1em;
    text-align: inherit;
`

export const Title = styled.h2`
    color: #fff;
    font-family: var(--font-nexa-black), 'nexablack', sans-serif;
    font-size: 26px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 0.7em;
`

export const Header = styled.div`
    margin-bottom: 1.8em;

    &:last-child {
        margin-bottom: 0;
    }

    ${Title}:last-child,
    ${Description}:last-child {
        margin-bottom: 0;
    }
`

export const Sticker = styled.img`
    height: 90px;
    left: 50%;
    margin-left: -60px;
    object-fit: contain;
    pointer-events: none;
    position: absolute;
    top: -30px;
    width: 130px;
`

export const CloseButton = styled(IconButton)`
    border-top-right-radius: inherit;
    position: absolute;
    right: 0;
    top: 0;
`
