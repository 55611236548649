import Header from 'components/header/header'
import { omit } from 'lodash'
import locationState from 'shared/components/with-location-state'
import App from '../../components/apps/app'
import Page from '../page'

const omitAppDetails = (app) => {
    return omit(app, ['video_embed', 'info_items'])
}
const AppsPage = ({ apps }) => (
    <Page>
        <Header />
        {apps.map(omitAppDetails).map((app, index) => (
            <App key={index} {...app} {...{ index, is_landing: true }} />
        ))}
    </Page>
)

export default locationState(['apps'])(AppsPage)
