import mobileCheck from 'is-mobile'
import { ShareActions } from '../components/video/context'
import { facebookColor, instagramColor, pinterestColor, redditColor, smsColor, twitterColor } from '../css/colors'
import { isiOS } from './environment'

type Link = {
    id: ShareActions
    backgroundColor: string
    className: string
    getUrl: (url: string, mediaUrl?: string, description?: string) => string
}
const LINKS: Link[] = [
    {
        id: 'facebook',
        backgroundColor: facebookColor,
        className: 'ss-social ss-facebook',
        getUrl: (url) => `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url || location.href)}`,
    },
    {
        id: 'twitter',
        backgroundColor: twitterColor,
        className: 'ss-social ss-twitter',
        getUrl: (url) => `http://twitter.com/share?url=${encodeURIComponent(url || location.href)}?tc=1&via=giphy`,
    },
    {
        id: 'instagram',
        backgroundColor: instagramColor,
        className: 'ss-social ss-instagram',
        getUrl: (url) => `/login/?next=${encodeURIComponent(url || location.href)}&ref=auth_required`,
    },
    {
        id: 'pinterest',
        backgroundColor: pinterestColor,
        className: 'ss-social ss-pinterest',
        getUrl: (shareUrl, mediaUrl, description) =>
            `http://pinterest.com/pin/create/bookmarklet/?media=${mediaUrl}&url=${encodeURIComponent(
                shareUrl || location.href
            )}&description=${description}`,
    },
    {
        id: 'reddit',
        backgroundColor: redditColor,
        className: 'ss-social ss-reddit',
        getUrl: (url) => `//www.reddit.com/submit?url=${encodeURIComponent(url || location.href)}`,
    },
]

// if mobile, add sms link
mobileCheck() &&
    LINKS.unshift({
        id: 'sms',
        backgroundColor: smsColor,
        className: 'ss-chat',
        getUrl: (url) => `sms:${isiOS() ? '&' : '?'}body=${encodeURIComponent(url || location.href)}`,
    })

export default LINKS
