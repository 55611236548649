import PropTypes from 'prop-types'
import TextInput from '../../inputs/text'
import styled from 'styled-components'
import { giphyWhite, giphyDarkCharcoal, giphyRed, giphyLightGrey } from 'shared/css/colors.css'
import Arrow from 'shared/components/giphy-marketers/icons/arrow'

const InputContainer = styled.div`
    display: flex;
`

const AvatarContainer = styled.div`
    background-color: ${giphyWhite};
    width: 36px;
    height: 36px;
    position: relative;
    text-align: center;
`

const AvatarDefault = styled.div`
    color: ${giphyDarkCharcoal};
    padding-top: 9px;
    font-size: 18px;
`

const AvatarImage = styled.img`
    width: 36px;
    height: 36px;
`

const Input = styled(TextInput)`
    flex-grow: 1;
`

const LockIcon = styled.div`
    background-color: rgba(0, 0, 0, 0.66);
    color: ${giphyRed};
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 42px;
    font-size: 16px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`

const HelperText = styled.span`
    float: right;
    font-size: 12px;

    a {
        color: ${giphyLightGrey};
    }
`

/**
 * Edit Panel - Username: Controls the username
 * text input in the GIF Edit Panel.
 *
 * @class EditPanelUsername
 */
const EditPanelUsername = ({ className, gifUser, avatar, value, onChange, isPrivate }) => (
    <div className={className}>
        <span>Username</span>
        {gifUser && (
            <HelperText>
                <a
                    href={`https://admin.giphy.com/admin/users/user/${gifUser.id}/change/`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Open in Admin
                    <Arrow rotate={270} color={giphyLightGrey} height={7} />
                </a>
            </HelperText>
        )}
        <InputContainer>
            <AvatarContainer>
                {avatar ? <AvatarImage src={avatar} /> : <AvatarDefault className="ss-user" />}

                {isPrivate && <LockIcon className="ss-lock" />}
            </AvatarContainer>
            <Input type="text" value={value} placeholder="Add a username" buttonLabel="Save" onSubmit={onChange} />
        </InputContainer>
    </div>
)

EditPanelUsername.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export default EditPanelUsername
