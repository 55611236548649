import { ReactNode } from 'react'
import styled from 'styled-components'

const Inactive = styled.span`
    opacity: 0.5;
    pointer-events: none;
`

type Props = {
    gif: any
    children?: ReactNode
    className?: string
}

const NextPrev = ({ gif, children, className }: Props) => {
    return gif ? (
        <a className={`${className}`} href={gif.path}>
            {children}
        </a>
    ) : (
        <Inactive className={`${className}`}>{children}</Inactive>
    )
}

export default NextPrev
