import styled from 'styled-components'
import { fontFamily } from '../../constants'

const Container = styled.div`
    background: rgba(0, 0, 0, 0.4);
    color: giphyWhite;
    padding: 30px 20px 110px;
    position: relative;
    text-align: center;
    height: 140px;
`

const Copyright = styled.div`
    font-family: ${fontFamily.title};
    margin-bottom: 8px;
`

const ToLinks = styled.p`
    font-size: 14px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    a {
        display: inline-block;
        opacity: 0.8;
        margin: 0 3px;
    }
`

const MenuFooter = () => (
    <Container>
        <Copyright>&copy; {new Date().getFullYear()} GIPHY, Inc.</Copyright>
        <ToLinks>
            <a href="/terms" target="_blank">
                Terms of Service
            </a>
            <a href="/community-guidelines" target="_blank">
                Community Guidelines
            </a>
            <a href="/privacy" target="_blank">
                Privacy Policy
            </a>
            <a href="/dmca" target="_blank">
                Copyright
            </a>
            <span
                onClick={() => {
                    // @ts-ignore unless they can provide ts definitions
                    window.Didomi && window.Didomi.preferences.show()
                }}
            >
                Manage Cookies
            </span>
        </ToLinks>
    </Container>
)

export default MenuFooter
