import { giphyLightestGrey } from '@giphy/colors'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'

export const EmailVerificationMessageWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 135px;

    ${mobile.css`
    margin-left: 0;
  `}
`

export const EmailVerificationMessageButtons = styled.div`
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    svg {
        margin-right: 12px;
    }

    * {
        cursor: pointer;
    }
`

export const EmailVerificationMessageContent = styled.div`
    margin-left: 12px;
    text-align: left;

    h5 {
        color: ${giphyLightestGrey};
        font-size: 14px;
        font-weight: bold;
        margin: 0;
    }

    p {
        color: ${giphyLightestGrey};
        font-size: 12px;
        font-weight: bold;
        margin: 0;
    }
`

export const EmailVerificationMessageIconOnly = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;

    ${mobile.css`
    top: 38px
  `}
`
