import { ReactNode, useContext, useMemo } from 'react'
import Portal from '../../portal'
import Alert from '../alert'
import { PortalAlertContext } from './context'
import { Container, Dim } from './style'

type Props = {
    blurred?: boolean
    children?: ReactNode
    className?: string
    isOpen?: boolean
}

const PortalAlert = ({ blurred = false, children, className, isOpen = false }: Props) => {
    const { activeId, alerts, closeAlert } = useContext(PortalAlertContext)
    const activeData = useMemo(() => (activeId ? alerts.get(activeId) : undefined), [activeId, alerts])

    if (!activeData || !isOpen) {
        return null
    }

    return (
        <Portal>
            <Container>
                <Dim $blurred={blurred} />
                <Alert {...activeData} className={className} onClose={() => closeAlert()}>
                    {children}
                </Alert>
            </Container>
        </Portal>
    )
}

export default PortalAlert
