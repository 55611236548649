import { isEmpty } from 'lodash'
import { PureComponent } from 'react'
import styled from 'styled-components'
import Editable from './editable'
import markdownToHtml from './markdown-to-html'

const MIN_HEIGHT = 20

type IContainerDiv = {
    focused: boolean
    rawText: string
}
const ContainerDiv = styled.div<IContainerDiv>`
    box-sizing: content-box;
    font-size: inherit;
    line-height: inherit;
    position: relative;
`

type IMarkdown = {
    isHidden: boolean
}
const Markdown = styled.div<IMarkdown>`
    height: 100%;
    left: 0;
    line-height: inherit;
    opacity: ${(props) => (props.isHidden ? 0 : 1)};
    margin: 0;
    padding: 0;
    pointer-events: ${(props) => (props.isHidden ? 'none' : 'auto')};
    position: absolute;
    top: 0;
    width: 100%;
`

type IEditableTextfield = {
    maxCharacters: number
    placeholder?: string
    supportMarkdown?: boolean
    text?: string
    fieldType: string
    onCharacterCount: (count: number, isOver: boolean) => void
    onFocus?: () => void
    onBlur?: () => void
    onChange?: (value: string, fieldType: string) => void
}

export default class EditableTextfield extends PureComponent<IEditableTextfield> {
    public static defaultProps = {
        placeholder: '',
        text: '',
        supportMarkdown: false,
    }

    public readonly state = {
        focused: false,
        rawText: '',
        textHeight: MIN_HEIGHT,
    }

    public rawField
    public markdownField

    componentDidMount() {
        this.updateText()
    }

    componentDidUpdate(prevProps, prevState) {
        const { text } = this.props
        const { focused } = this.state
        if (prevState.focused !== focused) {
            this.updateText()
        }
        if (text !== prevProps.text) {
            this.setState(
                {
                    rawText: text,
                },
                () => {
                    this.updateSize()
                }
            )
        }
    }

    updateSize() {
        this.rawField.style.height = '0px'
        this.setState({
            textHeight: this.rawField.scrollHeight,
        })
        this.rawField.style.height = ''
    }

    updateText() {
        const { supportMarkdown } = this.props
        const { rawText } = this.state
        supportMarkdown
            ? (this.markdownField.innerHTML = markdownToHtml(rawText).html)
            : (this.markdownField.innerText = rawText)

        this.updateSize()
        this.updateCharacterCount()
    }

    updateCharacterCount() {
        const { maxCharacters, supportMarkdown, onCharacterCount } = this.props
        const { rawText } = this.state

        if (onCharacterCount) {
            const { length } = supportMarkdown ? markdownToHtml(rawText) : rawText
            onCharacterCount(length, (maxCharacters && length) > maxCharacters)
        }
    }

    onInput = (e) => {
        e.preventDefault()
        this.setState({ rawText: e.target.value }, () => {
            this.updateSize()
            this.updateCharacterCount()
            this.props.onChange && this.props.onChange(this.state.rawText, this.props.fieldType)
        })
    }

    onFocus = () => {
        this.rawField.focus()
        this.setState({ focused: true })
        this.props.onFocus && this.props.onFocus()
    }

    onBlur = () => {
        this.setState({
            focused: false,
        })
        this.props.onBlur && this.props.onBlur()
    }

    render() {
        const { placeholder, text } = this.props
        const { focused, rawText, textHeight } = this.state
        const showMarkdown = !isEmpty(rawText) && !focused
        return (
            <ContainerDiv focused={focused} rawText={rawText} style={{ height: Math.max(MIN_HEIGHT, textHeight) }}>
                <Editable
                    defaultValue={rawText || text}
                    isHidden={showMarkdown}
                    onBlur={this.onBlur}
                    onChange={this.onInput}
                    onFocus={this.onFocus}
                    placeholder={placeholder}
                    ref={(c) => (this.rawField = c)}
                />
                <Markdown isHidden={!showMarkdown} onClick={this.onFocus} ref={(c) => (this.markdownField = c)} />
            </ContainerDiv>
        )
    }
}
