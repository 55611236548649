export default [
    {
        rating: '–',
        value: '',
        description: 'Content that has not been rated.',
    },
    {
        rating: 'g',
        value: 'g',
        description: 'Content that is appropriate for all ages.',
    },
    {
        rating: 'pg',
        value: 'pg',
        description:
            'Content that is generally safe for everyone, but may require parental preview before children can watch.',
    },
    {
        rating: 'pg-13',
        value: 'pg-13',
        description:
            'Mild sexual innuendos, mild substance use, mild profanity, or threatening images. May include images of semi-naked people, but DOES NOT show real human genitalia or nudity.',
    },
    {
        rating: 'r',
        value: 'r',
        description:
            'Strong language, strong sexual innuendo, violence, and illegal drug use; not suitable for teens or younger. NO NUDITY.',
    },
]
