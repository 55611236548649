import { useContext } from 'react'
import styled from 'styled-components'
import { giphyWhite } from '../../../css/colors'
import CopyButton from '../../copy-button'
import { ActiveShareTab } from '../context'
import getVideoEmbedCode from '../embed-code'
import VideoContext from '../video-context'
import Label from './section-label'

const Container = styled.div`
    height: 36px;
    margin-bottom: 20px;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`

const TextInput = styled.input`
    background: ${giphyWhite};
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 14px;
    height: 36px;
    outline: none;
    padding: 6px 12px;
    width: 100%;
    -webkit-appearance: none;
`

const CopyWrapper = styled.div<{ isFullWidth: boolean }>`
    width: ${(props) => (props.isFullWidth ? '100%' : '220px')};
`

const getTitle = (activeShareTab: ActiveShareTab) => {
    if (activeShareTab === 'embed') {
        return 'Embed'
    }
    return 'Copy Link to Clip'
}

const getLabel = (activeShareTab: ActiveShareTab) => {
    if (activeShareTab === 'embed') {
        return 'Copy Embed Code'
    }
    return 'Copy Clip Link'
}

const ShareLink = () => {
    const {
        videoData,
        videoData: { url },
        isSmall,
        activeShareTab,
        isEmbedResponsive,
        isEmbedInfoBelow,
        onCopyLink,
    } = useContext(VideoContext)
    const isEmbed = activeShareTab === 'embed'
    const copyContent = isEmbed ? getVideoEmbedCode(videoData, isEmbedResponsive, isEmbedInfoBelow) : url
    return (
        <Container>
            {!isSmall && <Label>{getTitle(activeShareTab)}</Label>}
            <Wrapper>
                {!isSmall && <TextInput onFocus={(e) => e.target.select()} readOnly type="text" value={copyContent} />}
                <CopyWrapper isFullWidth={isSmall} onClick={() => onCopyLink(true)}>
                    <CopyButton CTA={getLabel(activeShareTab)} primary url={copyContent} />
                </CopyWrapper>
            </Wrapper>
        </Container>
    )
}

export default ShareLink
