import styled, { css } from 'styled-components'
import Button from './button'
import { primaryCTA, secondaryCTA, giphyDarkGrey, giphyGreen, giphyRed } from '../css/colors'

const getButtonColor = ({ primary, secondary, error, success }) => {
    if (success) {
        return giphyGreen
    }
    if (error) {
        return giphyRed
    }
    return primary ? primaryCTA : secondary ? secondaryCTA : giphyDarkGrey
}

export default styled(Button)`
    display: block;
    width: 100%;
    background-color: ${(props) => getButtonColor(props)};

    ${(props) =>
        props.success &&
        css`
            color: black;
            &:hover {
                color: black;
            }
        `};
    transition: background-color 0.1s ease-out;
`
