import { IGif } from '@giphy/js-types'
import { createContext } from 'react'

const noop = (...args) => console.log(...args)

export type EditAPIProps = {
    saveGifsToCollection: (gifs: IGif[], channelId: number) => void
}

const EditAPIContext = createContext({
    saveGifsToCollection: noop,
} as EditAPIProps)

export default EditAPIContext
