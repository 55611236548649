import {
    giphyDarkCharcoal,
    giphyDarkGrey,
    giphyDarkestGrey,
    giphyLightCharcoal,
    giphyLightestGrey,
    giphyWhite,
} from '@giphy/colors'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { ILoggedInUser } from 'types'
import { menuZIndex } from '../../../constants'
import { NavigateDownIcon, NavigateUpIcon, UserIcon } from '../../../icons/utility'
import Avatar from '../../avatar'
import Button_ from '../../controls/button'
import { HeaderContext } from '../../header'
import ChannelAccessFooter from './channel-access-footer'
import ChannelAccess, { AccessProps } from './channel-access-menu'

const menuWidth = 164
const avatarSize = 36
const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const LoginIcon = styled.div`
    position: relative;
    height: ${avatarSize}px;
    width: ${avatarSize}px;
    background: ${giphyLightCharcoal};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0 0 2px;
`
const LoginContainer = styled.div`
    display: flex;
    height: ${avatarSize}px;
    cursor: pointer;
`

const Button = styled(Button_)`
    display: flex;
    justify-content: center;
`
const LoggedIn = styled.a`
    display: flex;
    background-color: ${giphyDarkCharcoal};
    width: ${menuWidth}px;
`
const Username = styled.div`
    flex: 1;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    user-select: none;
    overflow: hidden;
    padding-left: 8px;
`

const Arrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
`

const nibleSize = `5px`
export const Niblet = styled.div`
    border-bottom: ${nibleSize} solid;
    border-left: ${nibleSize} solid transparent;
    border-right: ${nibleSize} solid transparent;
    position: absolute;
    right: 50%;
    margin-right: ${-parseInt(nibleSize) / 2}px;
    color: ${giphyDarkestGrey};
`
const DropdownContainer = styled.div`
    position: absolute;
    z-index: ${menuZIndex};
    width: 100%;
`
const Dropdown = styled.div`
    position: relative;
    top: ${nibleSize};
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    background-color: ${giphyDarkestGrey};
    a {
        font-weight: normal;
        font-size: 15px;
        color: ${giphyLightestGrey};
    }
    a:hover {
        color: ${giphyWhite};
    }
    hr {
        width: 100%;
        height: 1px;
        background: ${giphyDarkGrey};
    }
`
const defaultUser = {
    avatar_url: 'https://media.giphy.com/avatars/default5.gif',
    display_name: 'GIPHY User',
    username: 'GIPHY',
} as ILoggedInUser

const Wrap = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`
const isLoggedInUser = (user: any): user is ILoggedInUser => {
    return user?.is_staff !== undefined
}
const UserMenu = () => {
    const { user, hasFavorites } = useContext(HeaderContext)

    // user is either a user object or null after fetching
    const hasFetchedUser = user !== undefined
    const [dropdown, setDropdown] = useState(false)
    return (
        <>
            <Container onMouseOver={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                <LoginContainer>
                    {!hasFetchedUser ? (
                        <LoggedIn>
                            <LoginIcon />
                        </LoggedIn>
                    ) : isLoggedInUser(user) ? (
                        <LoggedIn href={user.channel}>
                            <Avatar user={user} height={avatarSize} width={avatarSize} />
                            <Username>
                                <Wrap>{user.display_name || user.username}</Wrap>
                            </Username>
                            <Arrow>{dropdown ? <NavigateUpIcon width={12} /> : <NavigateDownIcon width={12} />}</Arrow>
                        </LoggedIn>
                    ) : hasFavorites ? (
                        <LoggedIn>
                            <Avatar user={defaultUser} height={avatarSize} width={avatarSize} />
                            <Username>
                                <Wrap>{defaultUser.display_name}</Wrap>
                            </Username>
                            <Arrow>{dropdown ? <NavigateUpIcon width={12} /> : <NavigateDownIcon width={12} />}</Arrow>
                        </LoggedIn>
                    ) : (
                        <>
                            <LoginIcon>
                                <UserIcon width={14} />
                            </LoginIcon>
                            <Button
                                color={giphyDarkCharcoal}
                                href="/login"
                                style={{
                                    height: avatarSize,
                                    width: menuWidth - avatarSize,
                                    borderRadius: 0,
                                    borderTopRightRadius: 2,
                                    borderBottomRightRadius: 2,
                                }}
                            >
                                <div>Log In</div>
                            </Button>
                        </>
                    )}
                </LoginContainer>
                <div style={{ position: 'relative' }}>
                    {dropdown ? (
                        isLoggedInUser(user) ? (
                            <DropdownContainer>
                                <Niblet />
                                <Dropdown>
                                    {user.permissions.dashboard_analytics && <a href="/dashboard">Dashboard</a>}
                                    <a href={`${user.channel}?openCollectionCabinet=true`}>Collections</a>
                                    {user.is_staff && <a href="/edit/channels">Channels</a>}
                                    <a href="/favorites">Favorites</a>
                                    {(user.permissions.access_dvr || user.is_staff) && (
                                        <a href="/supercut">Super Cut</a>
                                    )}
                                    <ChannelAccess user={user as AccessProps['user']} />
                                    <hr />
                                    <a href="/settings">Settings</a>
                                    <a href="/logout">Log Out</a>
                                </Dropdown>
                            </DropdownContainer>
                        ) : hasFavorites ? (
                            <DropdownContainer>
                                <Niblet />
                                <Dropdown>
                                    <a href="/login">Login</a>
                                    <a href="/join">Join</a>
                                    <a href="/favorites">Favorites</a>
                                </Dropdown>
                            </DropdownContainer>
                        ) : null
                    ) : null}
                </div>
            </Container>
            {user && <ChannelAccessFooter user={user as AccessProps['user']} />}
        </>
    )
}

export default UserMenu
