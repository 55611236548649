export const highestZIndex = 2147483647
export const aboveNavZIndex = 500
export const belowNavZIndex = 498
export const modalZIndex = 2147483646
export const messagesZIndex = 999999
export const belowMessagesZIndex = 999998
export const headerIndex = 10
export const bulkEditorModalIndex = 2147483639
export const privacyTermsIndex = 2147483638
export const bulkEditorButton = 2147483639
export const channelAccessBottomBannerIndex = 2147483640
