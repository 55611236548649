import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
import ModalContext from 'shared/contexts/modal'
import styled from 'styled-components'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'

const Container = styled.div`
    cursor: pointer;
    display: block;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    .ss-plus {
        margin-right: 19px;
        padding-top: 6px;
        font-size: 23px;
    }
`
const GifDetailAddToCollection = ({ gif }: { gif: IGif }) => {
    const { openCollectionCabinet } = useContext(ModalContext)
    const { setGif } = useContext(CollectionContext)

    return (
        <Container
            onClick={() => {
                setGif?.(gif)
                openCollectionCabinet()
            }}
        >
            <div className="ss-plus" />
            Add to Collection
        </Container>
    )
}
export default GifDetailAddToCollection
