import styled from 'styled-components'
import { modalZIndex } from 'ui/src/constants'

export const DESKTOP_CONTAINER_HEIGHT = 300
export const DESKTOP_AD_DIMENSIONS = [300, 250]
export const UNIT_NAME = 'medium_rectangle'

const AdContainer = styled.div`
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: ${modalZIndex - 1};
`
type Props = { enabled: boolean; pageName: string }
const MediumRectangleAd = ({ enabled, pageName }: Props) => {
    const className = `htlad-desktop_${UNIT_NAME}_${pageName}`

    return (
        enabled && (
            <AdContainer>
                <div className={className}></div>
            </AdContainer>
        )
    )
}

export default MediumRectangleAd
