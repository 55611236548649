import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import UserContext from './user'
import { getExperiences } from 'shared/components/user-onboarding/user-onboarding.api'

export type ExperienceRecord = {
    experience_id: string
    status: number
    create_datetime: string
    update_datetime: string
}

export type ExperiencesContextProps = {
    experiences: ExperienceRecord[]
    disabled: Boolean
}

const ExperiencesContext = createContext({
    experiences: [],
    disabled: false,
} as ExperiencesContextProps)

export const ExperiencesContextManager = ({ children }: { children: ReactNode }) => {
    const { user } = useContext(UserContext)
    const [experiences, setExperiences] = useState<ExperienceRecord[]>([])
    const [disabled, setDisabled] = useState<Boolean>(false)

    useEffect(() => {
        if (!user || !user.loggedIn) {
            setDisabled(true)
        } else {
            const timer = setTimeout(() => {
                getExperiences().then((response) => {
                    setExperiences(response)
                })
            }, 2500)

            return () => clearTimeout(timer)
        }
    }, [user])
    return <ExperiencesContext.Provider value={{ experiences, disabled }}>{children}</ExperiencesContext.Provider>
}

export default ExperiencesContext
