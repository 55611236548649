import { GifsResult } from '@giphy/js-fetch-api'
// import { IGif } from '@giphy/js-types'
import { GifOverlayProps, Grid } from '@giphy/react-components'
import { ElementType } from 'react'
import { getArtistChannels } from 'shared/api'
import getGifSkeleton from 'shared/components/gif-skeleton'
import NoResults from './no-results'

type Props = {
    filter: string
    columns?: number
    gutter?: number
    isFreelance?: boolean
    width: number
    sort: string
    overlay?: ElementType<GifOverlayProps>
}

const ArtistsGrid = ({ filter = '', isFreelance = false, sort, overlay, columns, gutter, width }: Props) => {
    const fetchArtists = async (offset: number) => {
        const { results = [], count } = await getArtistChannels({
            filter: filter,
            freelance: isFreelance,
            order: sort === 'updatetime' ? 'desc' : 'asc',
            sort: sort,
            offset,
        })
        // filter out any channels that do not have a featured_gif set, as these will break
        const data = results.map(({ featured_gif, user }) => {
            let gif = featured_gif
            // if there is no featured gif set or the gif is private,
            // fall back to an empty GIF. we need to make sure the
            // artist is not hidden from this page.
            if (!gif || gif.is_hidden || gif.is_removed) {
                gif = getGifSkeleton()
            }
            return { ...gif, url: user.profile_url, user }
        })
        const pagination = {
            count: data.length,
            total_count: count,
            offset,
        }
        return {
            data,
            meta: {
                msg: '',
                response_id: '',
                status: 200,
            },
            pagination,
        } as GifsResult
    }
    return (
        <Grid
            key={`${filter} ${isFreelance} ${sort}`}
            columns={columns || 3}
            width={width}
            gutter={gutter || 16}
            overlay={overlay}
            fetchGifs={fetchArtists}
            noResultsMessage={<NoResults />}
        />
    )
}

export default ArtistsGrid
