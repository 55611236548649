import { FC, SVGProps } from 'react'

const ErrorIcon: FC<SVGProps<SVGSVGElement>> = ({ width, height }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26" width={width} height={height}>
        <defs>
            <linearGradient id="ErrorIcon__gradient" x1="100%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#F66" />
                <stop offset="100%" stopColor="#E646B6" />
            </linearGradient>
        </defs>
        <path
            fill="url(#ErrorIcon__gradient)"
            fillRule="evenodd"
            d="M18.5 6.167c6.812 0 12.333 5.521 12.333 12.333S25.312 30.833 18.5 30.833 6.167 25.312 6.167 18.5 11.688 6.167 18.5 6.167zm0 16.748c-.57 0-1.056.201-1.457.603a1.985 1.985 0 00-.603 1.457c0 .57.201 1.056.603 1.457.401.402.887.603 1.457.603.57 0 1.056-.201 1.457-.603.402-.401.603-.887.603-1.457 0-.57-.201-1.056-.603-1.457a1.985 1.985 0 00-1.457-.603zm1.542-11.297h-3.084v8.732h3.084v-8.732z"
            transform="translate(-6 -5.5)"
        />
    </svg>
)

export default ErrorIcon
