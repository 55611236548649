import { giphyBlack, giphyDarkestGrey, giphyGreen, giphyRed } from '@giphy/colors'
import { useEffect, useState } from 'react'
import Tappable from 'react-tappable'
import styled, { keyframes } from 'styled-components'

const SEEN_DURATION_THRESHOLD = 250

const tagIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const Remove = styled(Tappable)`
    border-radius: inherit;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    height: 40px;
    line-height: 44px;
    margin-left: -20px;
    padding: 0 5px;
    text-align: center;
    vertical-align: top;

    &:before {
        border-radius: 50%;
        line-height: 23px;
        width: 21px;
        display: inline-block;
        height: 21px;
    }
`

const Label = styled.span`
    display: inline-block;
    padding: 0 20px;
    vertical-align: top;

    &:before {
        content: '#';
    }
`

const Container = styled.li<{ isVertical: boolean }>`
    background: ${giphyDarkestGrey};
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-style: italic;
    font-weight: normal;
    height: 40px;
    line-height: 40px;
    margin-right: 6px;
    margin-bottom: 6px;
    position: relative;
    text-transform: lowercase;
    white-space: nowrap;
    animation: ${tagIn} 0.5s ease-out;
    cursor: pointer;

    &:hover {
        color: ${giphyBlack};

        ${Remove}:before {
            color: #fff;
            background-color: #000;
        }
    }
`

export enum TagSource {
    Related = 'related-giphy2vec',
    Auto = 'autotagging-cnn',
}

export interface TagSuggestion {
    name: string
    source: string
    response_id: string
    confidence?: number
}

type Props = {
    tagSuggestion: TagSuggestion
    onAdd: (tag: TagSuggestion) => void
    onDelete: (tag: TagSuggestion) => void
    onSeen: (tag: TagSuggestion) => void
    isVertical: boolean
}

const SuggestedTag = ({ tagSuggestion, onAdd, onDelete, onSeen, isVertical }: Props) => {
    const [hoverColor, setHoverColor] = useState<string | undefined>(undefined)

    useEffect(() => {
        // don't want to trigger "seen" event immediately because of quick render updates
        const seenTimeout = setTimeout(() => {
            onSeen(tagSuggestion)
        }, SEEN_DURATION_THRESHOLD)

        return () => {
            clearTimeout(seenTimeout)
        }
    }, [])

    return (
        <Container isVertical={isVertical} style={{ backgroundColor: hoverColor }}>
            <Label
                onMouseOver={() => setHoverColor(giphyGreen)}
                onMouseOut={() => setHoverColor(undefined)}
                onClick={() => onAdd(tagSuggestion)}
            >
                {tagSuggestion.name}
            </Label>
            <Remove
                className="ss-delete"
                onMouseEnter={() => setHoverColor(giphyRed)}
                onMouseLeave={() => setHoverColor(undefined)}
                onTap={() => onDelete(tagSuggestion)}
            />
        </Container>
    )
}

export default SuggestedTag
