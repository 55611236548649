import { giphyLightGrey } from '@giphy/colors'
import { titleFont } from 'shared/components/text-elements'
import { mobile } from 'shared/util/media-query'
import styled from 'styled-components'
import { CONTENT_WIDTH } from '../constants'

const Container = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: ${CONTENT_WIDTH}px;
    padding: 1.6em 0;
    width: 100%;

    a {
        color: ${giphyLightGrey};
        display: inline-block;
        margin-left: 1em;
        text-decoration: none;
    }

    ${mobile.css`
        flex-direction: column;

        a {
            line-height: 2;
            margin-left: 0;
            margin-top: 0.1em;
        }
    `}
`

const Copyright = styled.div`
    color: #fff;
    font-family: ${titleFont};
`

const AboutPageFooterLinks = () => (
    <Container>
        <Copyright>&copy;{new Date().getFullYear()} GIPHY, Inc.</Copyright>
        <a href="https://giphy.com/terms" target="_blank" rel="noopener noreferrer">
            Terms of Service
        </a>
        <a href="https://giphy.com/community-guidelines" target="_blank" rel="noopener noreferrer">
            Community Guidelines
        </a>
        <a href="https://giphy.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
        </a>
        <a href="https://giphy.com/dmca" target="_blank" rel="noopener noreferrer">
            Copyright
        </a>
    </Container>
)

export default AboutPageFooterLinks
