import styled, { css } from 'styled-components'
import Avatar from '../avatar'
import { desktop } from '../../css'

export const NavContainer = styled.div`
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    align-content: center;
    ${desktop(css`
        display: none;
    `)}
`

export const Button = styled.a`
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    width: 38px;
    height: 40px;
    margin-left: 8px;
`

export const UserButton = styled(Button)`
    overflow: hidden;
    border-radius: 2px;
`

export const StyledAvatar = styled(Avatar)<{ isActive: boolean }>`
    transition: transform 0.3s ease-out;
    cursor: pointer;

    ${(props) =>
        props.isActive &&
        css`
            transform: scale(1.3);
        `};
`

export const CategoryButton = styled(Button)`
    margin-right: 6px;
    vertical-align: top;
    box-sizing: border-box;
    height: 40px;
`
