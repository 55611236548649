import styled from 'styled-components'
import { WhenInView } from 'shared/util/in-view'
import withLocationState from 'shared/components/with-location-state'
import Animation from './animation'
import Links from './links'
import { chunkStaffMembers } from './animation-utils'
import { TEAM_ANIMATION_HEIGHT, IMAGE_PATH } from '../constants'
import { ITeamColumn, IStaffMember } from '../types'
import { AboutPageSticker } from '../style'

const STICKER_HEIGHT: number = 80

const Container = styled.div`
    padding: 0 0 4em;
`

const Sticker = styled(AboutPageSticker)`
    display: inline-block;
    height: ${STICKER_HEIGHT}px;
    margin: 0 0.1em;

    &:nth-child(1) {
        animation-delay: 0.18s;
    }

    &:nth-child(3) {
        animation-delay: 0.26s;
    }
`

type Props = {
    staff: IStaffMember[]
}

const AboutPageTeam = ({ staff }: Props) => {
    const columns: ITeamColumn[] = chunkStaffMembers(staff)
    return (
        <Container id="our-team">
            <WhenInView height={STICKER_HEIGHT}>
                <Sticker src={`${IMAGE_PATH}/stickers/sunglasses.gif`} alt="" />
                <Sticker src={`${IMAGE_PATH}/stickers/heart-eyes.gif`} alt="" />
                <Sticker src={`${IMAGE_PATH}/stickers/smile.gif`} alt="" />
            </WhenInView>
            <h1>Our Team</h1>
            <WhenInView height={TEAM_ANIMATION_HEIGHT}>
                <Animation columns={columns} />
            </WhenInView>
            <Links />
        </Container>
    )
}

export default withLocationState(['staff'])(AboutPageTeam)
