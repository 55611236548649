exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._65D6y2pQ3jngpI3b7b-Lm {\r\n    display: inline-block;\r\n    margin-right: 20px;\r\n    vertical-align: top;\r\n}\r\n\r\n._3fhG9XvaSc7o9Gg60RU2tj {\r\n    display: inline-block;\r\n    vertical-align: top;\r\n}", ""]);

// exports
exports.locals = {
	"label": "_65D6y2pQ3jngpI3b7b-Lm",
	"ratings": "_3fhG9XvaSc7o9Gg60RU2tj"
};