import { useContext, useEffect, useCallback } from 'react'
import { VideoInlinePlayContext } from 'shared/contexts/video-inline-play-manager'
import { VolumeOffIcon, VolumeOnIcon } from 'shared/components/icons/video/volume'
import { VolumeOffAnimation } from 'shared/components/icons/video/volume-animation'
import styled from 'styled-components'
import { ClipboardButton, FavoriteOverlay } from '../gif-menu-overlay'
import { IGif } from '@giphy/js-types'

const Container = styled.div<{ isHovered: boolean }>`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 75px;
    width: ${({ isHovered }) => `${isHovered ? 100 : 82}px`};
    pointer-events: ${({ isHovered }) => `${isHovered ? 'auto' : 'none'}`};
    background: linear-gradient(225deg, hsla(0, 0%, 0%, 0.8) 0%, hsla(0, 0%, 0%, 0.04) 48%, hsla(0, 0%, 100%, 0) 100%);

    svg {
        position: absolute;
        right: 5px;
        top: 5px;
    }
`

const ClipboardDiv = styled.div`
    display: flex;
    position: absolute;
    top: 4px;
    right: 43px;
    justify-content: space-between;
    width: 63px;
`

type Props = {
    toggleMute?: () => void
    muted: boolean
    isHovered: boolean
    volumeSize?: number
    clipboardSize?: number
    gif: IGif
    copyLink?: () => void
    hideLinkAndCopy?: boolean
}

const VolumeButton = ({
    toggleMute = () => {},
    muted,
    isHovered,
    volumeSize = 38,
    clipboardSize,
    gif,
    copyLink,
    hideLinkAndCopy = false,
}: Props) => {
    const { onFinishVolumeIconAnimation, finishVolumeIconAnimation } = useContext(VideoInlinePlayContext)

    const VolumeOffCallback = useCallback(() => {
        return !finishVolumeIconAnimation && isHovered ? (
            <VolumeOffAnimation size={volumeSize} />
        ) : (
            <VolumeOffIcon size={volumeSize} />
        )
    }, [])

    useEffect(() => {
        if (isHovered && !finishVolumeIconAnimation) {
            onFinishVolumeIconAnimation?.()
        }
    }, [isHovered])

    return (
        <Container isHovered={isHovered}>
            {isHovered && !hideLinkAndCopy && (
                <ClipboardDiv>
                    <ClipboardButton gif={gif} isClip={true} copyLink={copyLink} size={clipboardSize} />
                    <FavoriteOverlay gif={gif} isHovered={isHovered} />
                </ClipboardDiv>
            )}
            <div
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    toggleMute()
                }}
            >
                {muted ? <VolumeOffCallback /> : <VolumeOnIcon size={volumeSize} />}
            </div>
        </Container>
    )
}

export default VolumeButton
