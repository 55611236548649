import { IGif } from '@giphy/js-types'
import { PlayerEvents } from 'shared/types/video'
import uuid from 'uuid/v4'

export type quartileOptions = 0.25 | 0.5 | 0.75

export type GAPlayerEventsAPI = {
    getDuration: () => number
    getVideoURL: () => string
    getCurrentTime: () => number
    getIsMutedState: () => boolean
    getCCEnabled: () => boolean
    getLoopCount: () => number
    getVideoData: () => IGif
}

type DefaultDimensions = {
    video_muted: boolean
    video_captions: boolean
    video_current_time: number
    video_duration: number
    video_percent: number
    video_url: string
    video_title: string
    media_type: string
    video_session_id: string
    video_stalled_duration?: number
    grid_position?: number
}

class VideoPlayerAnalytics implements PlayerEvents {
    videoId: number | string
    isPlaying: boolean
    category?: string
    sessionId: string
    api: GAPlayerEventsAPI
    gif: IGif
    gridPosition?: number

    constructor(videoId: number | string, category?: string, gridPosition?: number) {
        this.videoId = videoId
        this.isPlaying = false
        this.category = category
        this.gridPosition = gridPosition
        this.sessionId = uuid()
    }
    getDefaultDimensions = (): DefaultDimensions | null => {
        // If we don't have video data, do not return dimensions.
        if (!this.api) {
            return null
        }

        const video_duration = this.api.getDuration()
        const video_current_time = this.api.getCurrentTime()

        const defaultDimenions: DefaultDimensions = {
            video_url: this.gif.url,
            video_title: this.gif.title,
            video_duration: this.api.getDuration(),
            video_current_time: this.api.getCurrentTime(),
            video_muted: !!this.api.getIsMutedState(),
            video_captions: !!this.api.getCCEnabled(),
            video_percent: video_duration && video_duration > 0 ? video_current_time / video_duration : 0,
            media_type: this.gif.type,
            video_session_id: this.sessionId,
        }

        // If has position on the grid, add to default dimensions
        if (this.gridPosition) {
            defaultDimenions.grid_position = this.gridPosition
        }

        return defaultDimenions
    }
    onPlayerReady = (api: GAPlayerEventsAPI) => {
        this.api = api
        const gif = this.api.getVideoData()
        this.gif = gif
        this.isPlaying = false
    }
    onFirstPlay = (_time?: number) => {
        // if has time, track the time of the loading
        this.onPlay()
    }
    onPlay = () => {
        if (!this.isPlaying) {
            this.isPlaying = true
        }
    }
    onPause = () => {
        this.isPlaying = false
    }
    onEnded = () => {}
    onLoop = () => {}
    onStalledResume = (_stalledDuration: number) => {}
    onMute = (_isMuted: boolean) => {}
    onFullscreen = (_isFullscreen: boolean) => {}
    onShare = (_isOpen: boolean) => {}
    onShareAction = (_shareType) => {}
    onCopyLink = () => {}
    onQuartile = (_quartile: quartileOptions) => {}
}

export default VideoPlayerAnalytics
