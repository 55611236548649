import { useRef, useEffect, useState } from 'react'

declare global {
    interface Window {
        didomiEventListeners: any[]
    }
}

const DidomiUI = () => {
    /* Add-on javascript to achieve collapsed/expanded states on Didomi notice */
    const didomiHacksStatus = useRef(false)
    const [didomiSeeMoreExpanded, setDidomiSeeMoreExpanded] = useState<boolean>(false)
    const [didomiNoticeText, setDidomiNoticeText] = useState<string | null | undefined>()
    const didomiElementIdentifiers = {
        seeMore: 'giphy-didomi-notice-text-see-more',
        collapsed: 'giphy-didomi-collapsed',
    }
    const getDidomiElements = function () {
        const noticeRootEl = document.getElementById('didomi-notice')
        const noticeTextEl = noticeRootEl?.getElementsByClassName('didomi-notice-text')[0]
        const manageCookiesButtonEl = document.getElementById('didomi-notice-learn-more-button')
        return { noticeRootEl, noticeTextEl, manageCookiesButtonEl }
    }

    useEffect(() => {
        const didomiSeeMore = document.createElement('span')
        didomiSeeMore.id = didomiElementIdentifiers.seeMore
        didomiSeeMore.onclick = () => {
            setDidomiSeeMoreExpanded((didomiSeeMoreExpanded) => !didomiSeeMoreExpanded)
        }
        window.didomiEventListeners = window.didomiEventListeners || []
        window.didomiEventListeners.push({
            event: 'notice.shown',
            listener: function () {
                if (didomiHacksStatus.current === false) {
                    const { noticeRootEl, noticeTextEl, manageCookiesButtonEl } = getDidomiElements()
                    if (noticeRootEl) {
                        didomiHacksStatus.current = true
                        noticeTextEl?.classList.add(didomiElementIdentifiers.collapsed)
                        manageCookiesButtonEl?.classList.add(didomiElementIdentifiers.collapsed)
                        const textParagraph = noticeTextEl?.getElementsByTagName('p')[0]
                        const noticeText = textParagraph?.textContent
                        setDidomiNoticeText(noticeText)
                        const snippedText = noticeText?.substring(0, noticeText?.indexOf('By clicking'))
                        if (snippedText) textParagraph!.innerText = snippedText
                        textParagraph?.parentNode?.insertBefore(didomiSeeMore, textParagraph.nextSibling)
                    }
                }
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const { noticeTextEl, manageCookiesButtonEl } = getDidomiElements()
        const textParagraph = noticeTextEl?.getElementsByTagName('p')[0]
        if (didomiNoticeText) textParagraph!.innerText = didomiNoticeText
        noticeTextEl?.classList.remove(didomiElementIdentifiers.collapsed)
        manageCookiesButtonEl?.classList.remove(didomiElementIdentifiers.collapsed)
        document.getElementById(didomiElementIdentifiers.seeMore)?.remove()
    }, [didomiSeeMoreExpanded])

    return <></>
}
export default DidomiUI
