import ReactDOMServer from 'react-dom/server'
import { IGif } from '@giphy/js-types'

const DEFAULT_WIDTH = 480

const getSmallAvatar = (avatar) => {
    if (!avatar) return ''

    const ext = avatar.split('.').pop().toLowerCase()
    return avatar.replace(`.${ext}`, `/80h.${ext}`)
}

type InfoComponentProps = { gif: IGif }
const InfoComponent = ({ gif: { user, title, video } }: InfoComponentProps) => {
    if (!user) return null
    const avatarUrl = getSmallAvatar(user.avatar_url)
    return (
        <>
            <a
                href={`https://giphy.com/channel/${user.username}`}
                rel="noopener noreferrer"
                style={{
                    clear: 'both',
                    display: 'inline-block',
                    height: '36px',
                    lineHeight: '18px',
                    margin: '10px 0',
                    padding: '0 0 0 44px',
                    position: 'relative',
                }}
                target="_blank"
            >
                <img
                    alt=""
                    src={avatarUrl}
                    style={{ height: '36px', left: '0', position: 'absolute', top: '0', width: '36px' }}
                />
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{user.display_name || user.username}</div>
                <div style={{ fontSize: '14px', fontWeight: 'normal' }}>@{user.username}</div>
            </a>
            {title && (
                <p style={{ marginTop: '6px' }}>
                    <strong>{title}</strong>
                    <br />
                    {video ? video.description : ''}
                </p>
            )}
        </>
    )
}

type EmbedComponentProps = {
    gif: IGif
    isResponsive: boolean
    isInfoBelow: boolean
}
const EmbedComponent = ({ gif, isResponsive, isInfoBelow }: EmbedComponentProps) => {
    if (!gif.video) return null

    const { video } = gif
    const embedUrl = `https://giphy.com/embed/${gif.id}/video`
    const aspectRatio = video.assets.source.height / video.assets.source.width

    if (!isResponsive) {
        return (
            <div style={{ width: DEFAULT_WIDTH }}>
                <iframe
                    allow="fullscreen"
                    frameBorder="0"
                    height={Math.round(DEFAULT_WIDTH * aspectRatio)}
                    src={embedUrl}
                    width={DEFAULT_WIDTH}
                />
                {isInfoBelow && <InfoComponent gif={gif} />}
            </div>
        )
    }
    return (
        <div style={{ width: '100%' }}>
            <div style={{ height: 0, paddingBottom: `${aspectRatio * 100}%`, position: 'relative', width: '100%' }}>
                <iframe
                    allowFullScreen
                    frameBorder="0"
                    height="100%"
                    src={embedUrl}
                    style={{
                        left: 0,
                        position: 'absolute',
                        top: 0,
                    }}
                    width="100%"
                />
            </div>
            {isInfoBelow && <InfoComponent gif={gif} />}
        </div>
    )
}

const getVideoEmbedCode = (gif: IGif, isResponsive: boolean = true, isInfoBelow: boolean = false) => {
    if (!gif.video) return ''
    return ReactDOMServer.renderToStaticMarkup(
        <EmbedComponent gif={gif} isInfoBelow={isInfoBelow} isResponsive={isResponsive} />
    )
}

export default getVideoEmbedCode
