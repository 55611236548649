import styled from 'styled-components'
import { WhenInView } from 'shared/util/in-view'
import VisitLink from '../visit-giphy'
import Links from './links'
import { IMAGE_PATH } from '../constants'

const Container = styled.div`
    font-size: 0.8em;
`

const Kitty = styled.img`
    display: block;
    filter: contrast(1.2);
    height: 150px;
    margin: 0 auto;
    width: auto;
`

const AboutPageFooter = () => (
    <Container data-padded>
        <VisitLink />
        <Links />
        <WhenInView height={150}>
            <Kitty src={`${IMAGE_PATH}/kitty.gif`} alt="" />
        </WhenInView>
    </Container>
)

export default AboutPageFooter
