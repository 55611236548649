export default [
    'happy birthday',
    'cats',
    'thank you ',
    'love',
    'happy',
    'excited',
    'money',
    'dancing',
    'dogs',
    'i love you',
    'kiss',
    'sad',
    'coffee',
    'party',
    'yes',
    'no',
    'food',
    'hungry',
    'hug',
    'eye roll',
    'angry',
    'hello',
    'goodbye',
    'applause',
    'congratulations',
    'celebration',
    'sorry',
    'pizza',
    'cheeseburger',
    'sunglasses',
    'deal with it',
    'flirting',
    'weird',
    'mind blown',
    'thumbs up',
    'ok',
    'awkward',
    'shrug',
    'peace out',
    'awesome',
    'whatever',
    'slow clap',
    'tired',
    'idk',
    'popcorn',
    'mic drop',
    'ice cream ',
    '90s',
    'goats',
    'working',
]
