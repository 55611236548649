import styled from 'styled-components'
import { giphyLightGrey } from '../../css/colors'

const getSmallAvatar = (avatar) => {
    if (!avatar) return ''

    const ext = avatar.split('.').pop().toLowerCase()
    return avatar.replace(`.${ext}`, `/80h.${ext}`)
}

const Container = styled.a`
    align-items: center;
    color: ${giphyLightGrey};
    display: flex;
    flex-shrink: 0;
    height: 100%;
    padding: 0 ${(props) => (props.hasMenu ? 0 : `15px`)} 0 15px;
    text-decoration: none;

    &:hover {
        color: ${giphyLightGrey};
    }
`

const Username = styled.span`
    margin-right: 10px;

    &:before {
        content: '@';
    }
`

const Avatar = styled.div`
    background: no-repeat url(${(props) => getSmallAvatar(props.url)}) center center;
    background-size: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
`

const StoryItemAttribution = ({ story = {}, hasMenu = true }) => {
    const { user } = story
    return user ? (
        <Container hasMenu={hasMenu} href={user.profile_url} rel="noopener noreferrer" target="_blank">
            <Username>{user.username}</Username>
            <Avatar url={user.avatar || user.avatar_url} />
        </Container>
    ) : null
}

export default StoryItemAttribution
