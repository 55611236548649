import { FC, SVGProps } from 'react'

const CheckMarkIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <defs>
            <linearGradient id="a" x1="100%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#0F9" />
                <stop offset="100%" stopColor="#0CF" />
            </linearGradient>
        </defs>
        <path
            fill="url(#a)"
            fillRule="evenodd"
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm5.245 6a.257.257 0 00-.201.089l-5.98 6.732-2.608-2.944a.257.257 0 00-.2-.09.257.257 0 00-.201.09l-1.97 2.212a.312.312 0 00-.085.225c0 .092.029.167.086.226l4.774 5.371c.075.06.143.089.204.089.062 0 .13-.03.204-.089l8.146-9.159a.312.312 0 00.086-.225.312.312 0 00-.086-.226l-1.969-2.212a.257.257 0 00-.2-.089z"
        />
    </svg>
)

export default CheckMarkIcon
