import { ReactNode, useContext, useMemo } from 'react'
import Portal from '../../../portal'
import { PortalTooltipContext } from './context'
import { HitZone } from './style'
import Tooltip from './tooltip'

type Props = {
    children?: ReactNode
    className?: string
    isOpen: boolean
}

const PortalTooltip = ({ children, className, isOpen }: Props) => {
    const { activeId, tooltips, closeTooltip } = useContext(PortalTooltipContext)
    const activeData = useMemo(() => (activeId ? tooltips.get(activeId) : undefined), [activeId, tooltips])

    if (!isOpen || !activeData) {
        return null
    }

    return (
        <Portal>
            <HitZone $dimmed={activeData?.dimmed || false} onClick={closeTooltip} />
            <Tooltip className={className} tooltipData={activeData}>
                {children}
            </Tooltip>
        </Portal>
    )
}

export default PortalTooltip
