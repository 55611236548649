import { giphyBlue, giphyIndigo, giphyLightBlue, giphyPink, giphyPurple, giphyRed } from '@giphy/colors'
import { sample } from 'lodash'

export const colors = [giphyPink, giphyIndigo, giphyBlue, giphyRed, giphyLightBlue, giphyPurple]
const ITEMS_PER_LINE = 3

export const hexToRGBA = (hex: string, alpha: number | string): string => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

export const getOpacity = (index: number): string | number => {
    const module = index % ITEMS_PER_LINE

    switch (module) {
        case 0:
            return 1
        case 1:
            return 0.66
        case 2:
            return 0.33
        default:
            return `${100 / module}%`
    }
}

export const colorRandomization = (length: number) => {
    let randomColors = [sample(colors)]
    while (randomColors.length < length) {
        const newColor = sample(colors)
        if (newColor !== randomColors[randomColors.length - 1]) {
            randomColors.push(newColor)
        }
    }
    return randomColors
}
