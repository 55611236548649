import { ReactNode, createContext, useRef, useState } from 'react'
import { PortalMessageOptions } from '../types'
import { messagePropsFromMessageOptions } from '../utilities'
import PortalMessage from './'

const DEFAULT_DELAY = 5000

interface QueueMessage extends PortalMessageOptions {
    content: ReactNode
}

export type PortalMessageContextProps = {
    showMessage: (message: ReactNode, options?: PortalMessageOptions) => void
}

export const PortalMessageContext = createContext({} as PortalMessageContextProps)

export const PortalMessageContextManager = ({ children }: { children: ReactNode }) => {
    const delayRef = useRef<number | null>(null)
    const queueRef = useRef<QueueMessage[]>([])

    const [activeMessage, setActiveMessage] = useState<QueueMessage | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const messageProps = activeMessage ? messagePropsFromMessageOptions(activeMessage) : {}

    function nextMessage() {
        const displayMessage = queueRef.current[0]

        if (delayRef.current) {
            clearTimeout(delayRef.current)
        }

        if (!displayMessage) {
            setActiveMessage(null)
            return
        }

        const { delay = DEFAULT_DELAY, persistent = false } = displayMessage

        setActiveMessage(displayMessage)
        setIsOpen(true)

        if (!persistent) {
            delayRef.current = window?.setTimeout(() => {
                setIsOpen(false)
            }, delay)
        }
    }

    function removeMessage() {
        queueRef.current = queueRef.current.slice(1)
        nextMessage()
    }

    function showMessage(message: ReactNode, options: PortalMessageOptions = {}) {
        const queueMessage = { ...options, content: message }

        if (options.id) {
            const index = queueRef.current.findIndex((m) => m.id === queueMessage.id)

            // same id as active message. replace with message and restart delay.
            if (index === 0) {
                queueRef.current[0] = queueMessage
                nextMessage()
                return
            }

            // id exists in queue. remove instances and move message to back of queue.
            if (index > 0) {
                queueRef.current = queueRef.current.filter((m) => m.id !== queueMessage.id)
            }
        }

        queueRef.current.push(queueMessage)
        !isOpen && nextMessage()
    }

    return (
        <PortalMessageContext.Provider value={{ showMessage }}>
            {children}
            {activeMessage && (
                <PortalMessage
                    {...messageProps}
                    closeable
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onCloseEnd={() => removeMessage()}
                >
                    {activeMessage.content}
                </PortalMessage>
            )}
        </PortalMessageContext.Provider>
    )
}
