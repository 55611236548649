import { giphyDarkestGrey } from '@giphy/colors'
import { MutableRefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ANIMATION_DRIFT_SPEED, ANIMATION_MIN_SCALE, ANIMATION_SCALE_DIFF } from './constants'
import { getAnimationShape } from './shapes'

const Canvas = styled.canvas`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

export interface IAnimationShape {
    type: 'cutout' | 'long' | 'short' | 'circle'
    color: string
    width: number
    height: number
    path: [number, number][]
}

export interface IAnimationElement {
    shape?: IAnimationShape
    scale: number
    rotation: number
    drift: number
    x: number
    y: number
}

type Props = {
    height: number
    width: number
    blockSize?: number
    colors?: string[]
    speed?: number
}

const LogoShapesAnimation = ({
    height: canvasHeight,
    width: canvasWidth,
    blockSize = 30,
    colors = ['#14132D', '#09202A'],
    speed = 1,
}: Props): JSX.Element => {
    const canvasRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null)

    // draw
    useEffect(() => {
        if (!canvasRef.current) return

        const canvas: HTMLCanvasElement = canvasRef.current
        const context = canvas.getContext('2d')
        const height = canvas.offsetHeight
        const width = canvas.offsetWidth
        const elementCount = Math.floor(width / (blockSize * 3))
        const elements: IAnimationElement[] = []
        const gradient = context ? context.createLinearGradient(0, 0, width, 0) : null
        let animationId: number | null = null

        if (gradient) {
            colors.forEach((color, i) => {
                gradient.addColorStop(i / colors.length, color)
            })
        }

        const draw = () => {
            canvas.height = height
            canvas.width = width
            elements.forEach((element) => {
                const { shape, x, y, scale, rotation, drift } = element
                if (!shape) return
                const shiftX = shape.width / 2
                const shiftY = shape.height / 2
                const maxHeight = Math.max(shape.width, shape.height)
                if (context) {
                    context.save()
                    context.fillStyle = '#fff'
                    context.translate(x, y)
                    context.scale(scale, scale)
                    context.rotate(rotation * (Math.PI / 180))
                    context.beginPath()
                    context.moveTo(shape.path[0][0] - shiftX, shape.path[0][1] - shiftY)
                    for (let i = 1; i < shape.path.length; i++) {
                        context.lineTo(shape.path[i][0] - shiftX, shape.path[i][1] - shiftY)
                    }
                    context.fill()
                    context.restore()
                }
                element.y -= speed * scale
                element.rotation += drift * scale
                if (element.y < -maxHeight * scale) {
                    const newScale = ANIMATION_MIN_SCALE + ANIMATION_SCALE_DIFF * Math.random()
                    element.shape = getAnimationShape(blockSize)
                    element.y = height + maxHeight * newScale
                    element.rotation = Math.random() * 360
                    element.drift = (Math.random() - Math.random()) * ANIMATION_DRIFT_SPEED
                    element.scale = newScale
                }
            })

            if (context) {
                context.fillStyle = gradient || giphyDarkestGrey
                context.globalCompositeOperation = 'source-in'
                context.fillRect(0, 0, width, height)
            }

            animationId = requestAnimationFrame(draw)
        }

        for (let i = 0; i < elementCount; i++) {
            const heightOffset = blockSize * 7
            elements.push({
                shape: getAnimationShape(blockSize),
                scale: ANIMATION_MIN_SCALE + ANIMATION_SCALE_DIFF * Math.random(),
                rotation: Math.random() * 360,
                drift: (Math.random() - Math.random()) * ANIMATION_DRIFT_SPEED,
                x: (width / elementCount) * (i + 1),
                y: (height + heightOffset) * Math.random(),
            })
        }

        draw()

        return () => {
            animationId && cancelAnimationFrame(animationId)
        }
    }, [canvasRef, canvasHeight, canvasWidth, blockSize, colors, speed])

    return <Canvas ref={canvasRef} style={{ height: canvasHeight, width: canvasWidth }} suppressHydrationWarning />
}

export default LogoShapesAnimation
