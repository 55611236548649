import { giphyRed } from '../../css/colors'
import { LockContainer } from './sc'

const RemovedGif = () => (
    <LockContainer>
        <svg height="44" width="40" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.084 0a.88.88 0 0 1 .65.269.896.896 0 0 1 .266.655v3.652a.896.896 0 0 1-.266.655.88.88 0 0 1-.65.269H.916a.88.88 0 0 1-.65-.269A.896.896 0 0 1 0 4.576V.924C0 .666.089.448.266.269A.88.88 0 0 1 .916 0h38.168zM3.642 9.174h32.716l-2.726 34.01a.902.902 0 0 1-.31.58.889.889 0 0 1-.606.236H7.284a.889.889 0 0 1-.607-.236.902.902 0 0 1-.309-.58L3.642 9.174z"
                fill={giphyRed}
                fillRule="evenodd"
            />
        </svg>
    </LockContainer>
)

export default RemovedGif
