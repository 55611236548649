import styled from 'styled-components'

export const Content = styled.div`
    padding: 0.75em 1em;
`

export const Wrapper = styled.div`
    overflow: hidden;
    transition: height 0.15s ease-out;
`
