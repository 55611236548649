import { trackGA4NavigationEvent } from 'analytics'
import styled, { keyframes } from 'styled-components'
import { fontFamily, menuZIndex } from '../../constants'
import { MainMenuItem, MainMenuLink } from './'
import MenuFooter from './menu-footer'
import { Background, BackgroundWrapper, Item, Niblet } from './sc'

const menuIn = keyframes`
  0% {
      margin-bottom: -80px;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-80px);
  }
  100% {
      margin-bottom: 6px;
      opacity: 1;
      pointer-events: none;
      transform: translateY(0);
  }
`

const MenuContainer = styled.div`
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    background: black;
`

const NewPill = styled.img`
    position: relative;
    top: 4px;
    left: 5px;
    display: inline-block;
`
const InnerContainer = styled.div`
    position: relative;
    height: 100%;
    animation: ${menuIn} 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-size: 16px;
    margin: 6px -6px;
    padding-top: 8px;
    position: relative;
`
const MenuWrapper = styled.div`
    position: absolute;
    top: 0px;
    height: 100vh;
    width: 100%;
    left: 0;
    z-index: ${menuZIndex};
    overflow: scroll;
    padding-bottom: 70px;
`
const Section = styled.ul`
    columns: 125px 2;
    margin-bottom: 5px;
    padding: 0;
    li {
        list-style: none;
    }
    a {
        font-style: normal;
        font-stretch: normal;
        line-height: 38px;
        font-size: 16px;
        opacity: 0.8;
        font-family: ${fontFamily.body};
    }
`

const Title = styled.span`
    font-family: ${fontFamily.title};
    font-style: normal;
    font-stretch: normal;
    font-weight: 100;
    font-size: 24px;
    display: block;
    margin: 5px 0 15px;
    i {
        font-size: 14px;
    }
`

type Props = { items: MainMenuItem[]; color: string }

const Menu = ({ items, color }: Props) => (
    <MenuContainer>
        <InnerContainer>
            <Niblet style={{ color }} />
            <BackgroundWrapper>
                <Background style={{ backgroundColor: color }} />
            </BackgroundWrapper>
            <MenuWrapper>
                {items.map(({ header, items }, index) => (
                    <Item key={index}>
                        {header.link ? (
                            <Title
                                as="a"
                                href={header.link}
                                onClick={() => trackGA4NavigationEvent('none', header.link, header.title)}
                            >
                                {header.title} <i className="ss-icon ss-navigateright" />
                            </Title>
                        ) : (
                            <Title>{header.title}</Title>
                        )}
                        <Section>
                            {(items as MainMenuLink[]).map(({ title, link, newPill = false }, index) => (
                                <li key={index}>
                                    <a onClick={() => trackGA4NavigationEvent(title, link, header.title)} href={link}>
                                        {title}
                                    </a>
                                    {newPill && <NewPill src="/static/img/svg/pill-new.svg" alt="" />}
                                </li>
                            ))}
                        </Section>
                    </Item>
                ))}
                <MenuFooter />
            </MenuWrapper>
        </InnerContainer>
    </MenuContainer>
)

export default Menu
