import { uniqueId } from 'lodash'
import { InputGroup, Label, RadioButton, RadioInput } from './style'

type Props = {
    className?: string
    value: string
    children?: any
    group?: string
    title?: string
    isChecked?: boolean
    onChange: (arg0: any) => void
}

const Radio = ({ className, value, children, group, title, isChecked = false, onChange = () => {} }: Props) => {
    const id = uniqueId(`${group}_`)
    return (
        <InputGroup className={className} title={title}>
            <RadioInput
                type="radio"
                name={group}
                id={id}
                value={value}
                onChange={onChange}
                checked={isChecked}
                required
            />
            <Label htmlFor={id}>
                <RadioButton $isChecked={isChecked} />
                {children}
            </Label>
        </InputGroup>
    )
}

export default Radio
