import { giphyBlue, giphyGreen, giphyPurple, giphyRed, giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { desktop } from '../css'

const GiphySVG = styled.svg`
    fill: white;
    height: 100%;
`

export const GiphyContainer = styled.div`
    display: flex;
    width: 100%;
    height: 27px;
    ${desktop(css`
        width: unset;
        height: 38px;
        cursor: pointer;
    `)}
`

const IconContainer = styled.svg`
    display: block;
    height: 100%;
    width: 21px;
    margin-right: 6px;
    flex-shrink: 0;

    .shadow {
        fill: #000;
        enable-background: new;
        opacity: 0.4;
    }

    ${desktop(css`
        width: 28px;
    `)}
`

export const Icon = () => (
    <IconContainer xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 35">
        <g fillRule="evenodd" clipRule="evenodd">
            <path fill={giphyGreen} d="M0 3h4v29H0z" />
            <path fill={giphyPurple} d="M24 11h4v21h-4z" />
            <path fill={giphyBlue} d="M0 31h28v4H0z" />
            <path fill={giphyYellow} d="M0 0h16v4H0z" />
            <path fill={giphyRed} d="M24 8V4h-4V0h-4v12h12V8" />
            <path className="shadow" d="M24 16v-4h4M16 0v4h-4" />
        </g>
    </IconContainer>
)

const GIPHY = () => (
    <GiphySVG viewBox="0 0 128 36" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g>
                <path
                    d="m22.6,12c-2,-1.9 -4.4,-2.4 -6.2,-2.4c-4.4,0 -7.3,2.6 -7.3,8c0,3.5 1.8,7.8 7.3,7.8c1.4,0 3.7,-0.3 5.2,-1.4l0,-3.5l-6.9,0l0,-6l13.3,0l0,12.1c-1.7,3.5 -6.4,5.3 -11.7,5.3c-10.7,0 -14.8,-7.2 -14.8,-14.3c0,-7.1 4.7,-14.4 14.9,-14.4c3.8,0 7.1,0.8 10.7,4.4l-4.5,4.4zm9.1,19.2l0,-27.2l7.6,0l0,27.2l-7.6,0zm20.1,-7.4l0,7.3l-7.7,0l0,-27.1l13.2,0c7.3,0 10.9,4.6 10.9,9.9c0,5.6 -3.6,9.9 -10.9,9.9l-5.5,0zm0,-6.5l5.5,0c2.1,0 3.2,-1.6 3.2,-3.3c0,-1.8 -1.1,-3.4 -3.2,-3.4l-5.5,0l0,6.7zm36.7,13.9l0,-10.3l-9.8,0l0,10.3l-7.7,0l0,-27.2l7.7,0l0,10.3l9.8,0l0,-10.3l7.6,0l0,27.2l-7.6,0zm24.2,-17.9l5.9,-9.3l8.7,0l0,0.3l-10.8,16l0,10.8l-7.7,0l0,-10.8l-10.3,-16l0,-0.3l8.7,0l5.5,9.3z"
                    id="svg_2"
                />
            </g>
        </g>
    </GiphySVG>
)
type Props = { onClick?: () => void; className?: string }
const Logo = ({ onClick = () => (location.href = '/'), className }: Props) => (
    <GiphyContainer onClick={onClick} className={className}>
        <Icon />
        <GIPHY />
    </GiphyContainer>
)

export default Logo
