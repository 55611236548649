import * as React from 'react'
import { TestAttributesConfig } from 'react-test-attributes'
import CollectionsManager from 'shared/contexts/add-to-collections-manager'
import EditAPIContextManager from 'shared/contexts/edit-api-manager'
import GAEventContextManager from 'shared/contexts/ga-event-manager'
import ModalContextManager from 'shared/contexts/modal-manager'
import ReduxBridgeManager from 'shared/contexts/redux-bridge-manager'
import RefreshDataContextManager from 'shared/contexts/refresh-data-manager'
import VideoInlinePlayManager from 'shared/contexts/video-inline-play-manager'
import VideoLoopManager from 'shared/contexts/video-loop-manager'
import VideoMainManager from 'shared/contexts/video-main-manager'
import { attributeName } from 'ui'
import { FlashMessagesProvider } from './components/flash-message/flash-message'
import AccountsManagerContextManager, { Access, ChannelAccess, DashboardUser } from './contexts/accounts-manager'
import { ExperiencesContextManager } from './contexts/experiences'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

type ContextManagersProps = {
    children: React.ReactNode
    channel_access?: ChannelAccess
    dashboard_channel_access?: Access[]
    dashboard_user?: DashboardUser
}

const ContextManagers = ({
    children,
    channel_access,
    dashboard_channel_access,
    dashboard_user,
}: ContextManagersProps) => (
    <ReduxBridgeManager>
        <GAEventContextManager>
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <AccountsManagerContextManager
                    channel_access={channel_access}
                    dashboard_channel_access={dashboard_channel_access}
                    dashboard_user={dashboard_user}
                >
                    <TestAttributesConfig value={{ suffix: attributeName, enableInProductionMode: true }}>
                        <VideoLoopManager>
                            <VideoInlinePlayManager>
                                <VideoMainManager>
                                    <RefreshDataContextManager>
                                        <EditAPIContextManager>
                                            <CollectionsManager>
                                                <ModalContextManager>
                                                    <ExperiencesContextManager>
                                                        <FlashMessagesProvider>{children}</FlashMessagesProvider>
                                                    </ExperiencesContextManager>
                                                </ModalContextManager>
                                            </CollectionsManager>
                                        </EditAPIContextManager>
                                    </RefreshDataContextManager>
                                </VideoMainManager>
                            </VideoInlinePlayManager>
                        </VideoLoopManager>
                    </TestAttributesConfig>
                </AccountsManagerContextManager>
            </StyleSheetManager>
        </GAEventContextManager>
    </ReduxBridgeManager>
)

function shouldForwardProp(propName: string, target: any) {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName)
    }
    // For other elements, forward all props
    return true
}
export default ContextManagers
