import { giphyBlack, giphyIndigo } from '@giphy/colors'
import styled from 'styled-components'
import { colorToRgba } from 'utils'
import { PEEKER_URL } from '../open-in-app'
import Video from './video'

export const Container = styled.div`
    background: ${giphyIndigo};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    min-height: 100vh;
    position: relative;
`

export const BackgroundVideo = styled(Video)``

export const Buttons = styled.div`
    background: ${giphyIndigo};
    bottom: 0;
    margin-top: -80px;
    padding: 20px 0;
    position: sticky;
    z-index: 2;

    &:before {
        background: linear-gradient(${colorToRgba(giphyIndigo, 0)}, ${giphyIndigo});
        bottom: 100%;
        content: '';
        height: 100px;
        left: 0;
        position: absolute;
        width: 100%;
    }
`

export const DownloadButton = styled.a`
    background: ${giphyBlack};
    border-radius: 6px;
    display: block;
    font-size: 16px;
    font-weight: bold;
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    position: relative;
    width: 75%;

    &:before {
        background: no-repeat url(${PEEKER_URL}) center center;
        background-size: contain;
        content: '';
        height: 40px;
        left: 0;
        position: absolute;
        top: -8px;
        width: 40px;
    }
`
