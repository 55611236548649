import { IUser } from '@giphy/js-types'
import isURL from 'is-url'
import { each, every, map, mapValues, noop, pick, pickBy, snakeCase } from 'lodash'
import PropTypes from 'prop-types'
import { ComponentProps, PureComponent } from 'react'
import Test from 'shared/components/test'
import IconTooltip from 'shared/components/ui/icon-tooltip'
import log from 'shared/util/log'
import { desktop, mobile } from 'shared/util/media-query'
import styled, { css } from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import EmailVerificationMessage from '../settings/email-verification-message'
import { FormInput } from './form-components'
import SharedLabel from './inputs/label'
import ShowHidePasswordIcon from './show-hide-password-icon'

export const FIELDS = {
    email: 'email',
    username: 'username',
    password: 'password',
    passwordConfirmation: 'password_confirmation',
    contact: 'contact_name',
    displayName: 'display_name',
    primarySite: 'primary_site',
    primarySiteText: 'primary_site',
    socialURL: 'social_url',
    socialHandle: 'social_handle',
    organization: 'organization',
    location: 'location',
    facebook: 'facebook',
    instagram: 'instagram',
    tumblr: 'tumblr_site',
    twitter: 'twitter',
    tiktok: 'tiktok',
    youtube: 'youtube',
    otherIndustry: 'industry_user_provided_industry',
}

export function flattenResponse(response: object) {
    // Brings nested field names in error response to top level; converts the array of error message(s) to a string
    const flattened = {}
    const flatten = (obj: object) => {
        for (const field of Object.keys(obj)) {
            if (Array.isArray(obj[field])) {
                flattened[field] = obj[field].join(' ')
            } else {
                flatten(obj[field])
            }
        }
    }
    flatten(response)
    return flattened
}

const getInput = (type: string, name: string, placeholder: string, label?: string, optional = false) => ({
    type,
    name,
    placeholder,
    autoComplete: 'off',
    label,
    optional,
})

export const FieldContainer = styled.div`
    display: flex;
    position: relative;

    ${mobile.css`
        flex-direction: column;
    `};
`

export const FieldOuterContainer = styled.div`
    position: relative;
`

export const Label = styled(SharedLabel)`
    ${desktop.css`
        margin-right: 10px;
    `};
`

const PlaceholderText = styled.div`
    color: rgb(99, 99, 99); //NOTE: Not a GIPHY color
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
    ${mobile.css`
        text-align:left;
        font-size: 14px;
    `}
`

// Some form elements may have a 'http://' or '@' symbol or other unchangeable prefix.
export const AdaptedFormInput = styled(FormInput)<{ borderRadius?: number; inputProps: ComponentProps<'input'> }>`
    ${(props) =>
        props.borderRadius &&
        css`
            input {
                border-radius: ${props.borderRadius}px !important;
            }
        `};

    ${({ beforeElement }) =>
        beforeElement &&
        css`
            &:before {
                content: '${beforeElement}';
                position: relative;
                left: 0;
                background-color: rgb(54, 54, 54); //NOTE: Not a GIPHY color
                height: 42px;
                display: flex;
                align-items: center;
                padding: 15px;
                font-size: 16px;
                font-family: interface;
                font-weight: 700;
                box-sizing: border-box;
            }
        `}
`

type Validated = {
    okay: boolean
    has: boolean
    error?: string
    showError: boolean
}

type FieldType = {
    // TODO how to use InputHTMLAttributes?
    [key: string]: any
    label?: string
    tooltip?: string
    type?: string
    name?: string
    placeholder?: string
    optional?: boolean
}

export type FieldUpdate = [string, FieldType]

type UserEmailValidationData = {
    is_email_valid?: boolean
    pending_email?: string
    email?: string
    has_social_auth?: boolean
}

type Props = {
    user: IUser & UserEmailValidationData
    showFields: string[]
    responseErrors: any
    customFieldProps?: FieldUpdate[]
    aggroErrorMessages: boolean
    showLabel?: boolean
    noTooltip?: boolean
    noOptional?: boolean
    onValidChange: (isValid: boolean, validated?: Validated) => void
    ackResponseError: (field: string) => void
    className?: string
    borderRadius?: number
    saveCallback?: () => void
    changingEmail?: boolean
    setChangingEmail?: (string) => void
    isSaving?: boolean
    settingsPage?: boolean
    saveResponse?: {
        pending_email?: string
    }
}

type State = {
    validated: { [key: string]: Validated }
    isValid: boolean
    showPassword: boolean
    password: string
    passwordConfirmation: string
}

class Fields extends PureComponent<Props, State> {
    static propTypes = {
        user: PropTypes.object,
    }
    static defaultProps = {
        showFields: [],
        label: false,
        user: {},
        responseErrors: {}, // Server-side errors passed from parent component (e.g. username unavailable)
        ackResponseError: noop, // Callback after a field change to notify parent component to remove a field's server-side error from props
        aggroErrorMessages: false, // If `false`, user must enter a value in a field before seeing an error msg; if `true`, display errors in blank required fields on blur event
    }
    constructor(props) {
        super(props)
        // Create initial `validated` object in state using optional fields
        this.updateCustomProps()
        this.state = {
            isValid: false,
            validated: mapValues(
                pickBy(this.fields, (value) => value.optional),
                (): Validated => {
                    return {
                        okay: true,
                        has: false,
                        error: '',
                        showError: false,
                    }
                }
            ),
            showPassword: false,
            password: '',
            passwordConfirmation: '',
        }
        // Pre-populate fields with user data
        each(props.user, (val, key) => {
            const field = this.fields[snakeCase(key)]
            if (field) {
                let processedVal = val
                if (val) {
                    if (props.stripHttp) {
                        // We don't want the http:// or https:// prefixes
                        processedVal = val.replace(/^https?:\/\//, '')
                    }
                    if (props.stripUrl) {
                        // We just want the social handle, not the rest of the url. Taking from the last preceding slash should isolate the username from any protocols, directories, etc.
                        processedVal = processedVal.substring(0, processedVal.lastIndexOf('/') + 1)
                    }
                    field.defaultValue = processedVal
                }
            }
        })
    }

    componentDidMount() {
        const { user, onValidChange } = this.props
        each(user, (value, key) => {
            const field = this.fields[snakeCase(key)]
            // check values provided by user object
            if (field) field.onChange({ target: { value } })
        })
        onValidChange(this.state.isValid)
    }
    componentDidUpdate(prevProps, prevState) {
        const { validated } = this.state
        const { showFields, responseErrors, customFieldProps } = this.props
        const prevResponseErrors = prevProps ? prevProps.responseErrors : {}
        let validatedWithResponseErrors = { ...validated } // Copy `validated`, then update using `responseErrors`
        let isValid = false
        let newError = false
        if (customFieldProps !== prevProps.customFieldProps) {
            this.updateCustomProps()
            // can't use gdsfp since we update an object on `this`
            this.forceUpdate()
        }

        // Add every new error in `responseErrors` to `validated` object, and mark field as `!okay`, before determining `isValid`
        each(responseErrors, (value, key) => {
            if (value !== prevResponseErrors[key]) {
                validatedWithResponseErrors[key] = {
                    ...validated[key],
                    okay: false,
                    error: value,
                    showError: true,
                }
                newError = true
            }
        })

        isValid = every(pick(this.fields, showFields), (_, key) => {
            const field = validatedWithResponseErrors[key]
            if (field) {
                const { optional } = this.fields[key]
                if (!field.has && optional) {
                    return true
                }
                return field.okay
            }
            return false
        })

        if (newError || isValid !== prevState.isValid) {
            const { onValidChange } = this.props
            this.setState({
                isValid,
                validated: validatedWithResponseErrors,
            })
            onValidChange(isValid)
        }
    }
    updateCustomProps() {
        const { customFieldProps, showFields } = this.props
        each(customFieldProps, ([key, customProps]) => {
            if (showFields.indexOf(key) === -1) {
                log.warn(`Custom field props specified for ${key}, but it's not in showFields: ${showFields}`)
            }
            const field = this.fields[snakeCase(key)]
            if (field) this.fields[snakeCase(key)] = { ...field, ...customProps }
        })
    }
    showErrors = (fields) => {
        const { validated } = this.state

        const { aggroErrorMessages } = this.props

        const validations = {}

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            const checkField = validated[field] || { has: false, okay: !!this.fields[field].optional }

            validations[field] = {
                ...checkField,
                // We do not show errors with `aggroErrorMessages` disabled if the field has no value.
                showError: !checkField.okay && (checkField.has || aggroErrorMessages),
            }
        }

        this.setState({
            validated: {
                ...validated,
                ...validations,
            },
        })
    }

    socialAuthWithoutEmail = (newEmail, oldEmail, socialAuth) => {
        return !newEmail && !oldEmail && socialAuth
    }

    onEmailChange = (val) => {
        const {
            ackResponseError,
            setChangingEmail,
            user: { email, has_social_auth },
        } = this.props
        const okay = (val && isEmail(val)) || this.socialAuthWithoutEmail(val, email, has_social_auth)
        ackResponseError(FIELDS.email)
        IconTooltip.hideToolTips()
        if (setChangingEmail) setChangingEmail(val !== email)
        this.setState(({ validated }) => ({
            validated: {
                ...validated,
                [FIELDS.email]: {
                    okay: okay,
                    has: !!val,
                    error: !okay ? 'Invalid e-mail.' : '',
                    showError: false,
                },
            },
        }))
    }
    onSimpleChange = (field) => (val) => {
        const { ackResponseError } = this.props
        const { optional = false, minLength = 6, maxLength = Infinity } = this.fields[field]
        ackResponseError(field)
        IconTooltip.hideToolTips()
        this.setState(({ validated }) => ({
            validated: {
                ...validated,
                [field]: {
                    ...this.stringValidate(field, val, minLength, maxLength, optional),
                },
            },
        }))
    }
    onPasswordChange = (field) => (val) => {
        const { ackResponseError } = this.props
        const { optional = false, minLength = 6, maxLength = Infinity } = this.fields[field]
        ackResponseError(field)
        IconTooltip.hideToolTips()

        let validationResults = {}
        validationResults[field] = this.stringValidate(field, val, minLength, maxLength, optional)

        this.setState(({ validated }) => ({
            validated: {
                ...validated,
                ...validationResults,
            },
        }))
    }
    onUrlChange = (field) => (val) => {
        const { ackResponseError } = this.props
        const okay = isURL(val) || (!val && this.fields[field].optional)
        ackResponseError(field)
        IconTooltip.hideToolTips()
        this.setState(({ validated }) => ({
            validated: {
                ...validated,
                [field]: {
                    okay: okay,
                    has: !!val,
                    error: !okay
                        ? `Please use a valid ${(
                              this.fields[field].label ||
                              this.fields[field].placeholder ||
                              field
                          ).toLowerCase()} URL.`
                        : null,
                    showError: false,
                },
            },
        }))
    }
    stringValidate = (field, val, minLength = 0, maxLength = Infinity, optional = false) => {
        const blank = !val
        const meetsMin = !blank && val.length >= minLength
        const meetsMax = !blank && val.length <= maxLength
        const okay = (meetsMin && meetsMax) || (blank && optional)
        const label = this.fields[field].label || this.fields[field].placeholder
        const error = okay
            ? null
            : blank
            ? `${label} cannot be left blank.`
            : !meetsMin
            ? `${label} must be at least ${minLength} characters.`
            : `${label} must be less than ${maxLength} characters.`
        return {
            okay: okay,
            has: !!val,
            error: error,
            showError: false, // Flipped to `true` after field loses focus
        }
    }
    fields: { [key: string]: FieldType } = {
        [FIELDS.email]: {
            onChange: (e) => this.onEmailChange(e.target.value),
            onBlur: () => this.showErrors([FIELDS.email]),
            ...getInput('email', FIELDS.email, 'Email Address'),
            isEmailValid: this.props.user.is_email_valid,
            pendingEmail: this.props.user.pending_email,
        },
        [FIELDS.username]: {
            onChange: (e) => this.onSimpleChange(FIELDS.username)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.username]),
            minLength: 1,
            maxLength: 30,
            labelWidth: 'auto',
            ...getInput('text', FIELDS.username, 'Username'),
        },
        [FIELDS.password]: {
            onChange: (e) => this.onPasswordChange(FIELDS.password)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.password]),
            minLength: 6,
            maxLength: 256,
            ...getInput('password', FIELDS.password, 'Password'),
        },
        [FIELDS.contact]: {
            onChange: (e) => this.onSimpleChange(FIELDS.contact)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.contact]),
            minLength: 3,
            maxLength: 120,
            labelWidth: 'auto',
            ...getInput('text', FIELDS.contact, 'Your real name', 'Contact Name'),
            tooltip: 'Use your full name in this field (ex. Jane Smith).',
        },
        [FIELDS.displayName]: {
            onChange: (e) => this.onSimpleChange(FIELDS.displayName)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.displayName]),
            minLength: 1,
            maxLength: 120,
            labelWidth: 'auto',
            ...getInput('text', FIELDS.displayName, 'Display Name'),
            tooltip: 'The name you would like displayed on your Brand or Artist channel.',
            placeholderBelow: true,
        },
        [FIELDS.primarySite]: {
            onChange: (e) => this.onUrlChange(FIELDS.primarySite)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.primarySite]),
            minLength: 12,
            maxLength: 256,
            ...getInput('url', FIELDS.primarySite, 'http://yoursite.com', 'Website'), // was url
            tooltip: `A working URL to the business' custom web domain. For Brands, social accounts do not qualify. Artists, please link to a place online that best represents your work.`,
        },
        [FIELDS.primarySiteText]: {
            onChange: (e) => this.onSimpleChange(FIELDS.primarySiteText)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.primarySiteText]),
            minLength: 6, // was 12
            maxLength: 256,
            labelWidth: 'auto',
            ...getInput('text', FIELDS.primarySiteText, 'www.yoursite.com', 'Website'), // was url
            tooltip: `A working URL to the business' custom web domain. For Brands, social accounts do not qualify. Artists, please link to a place online that best represents your work.`,
            optional: false,
        },
        [FIELDS.socialURL]: {
            onChange: (e) => this.onUrlChange(FIELDS.socialURL)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.socialURL]),
            maxLength: 256,
            labelWidth: 'auto',
            ...getInput('url', FIELDS.socialURL, 'Facebook, Instagram, Twitter, etc. ', 'Social Profile'),
            tooltip: `Enter the full URL of the social media account that best represents you or your brand's web presence.`,
            optional: true,
        },
        [FIELDS.socialHandle]: {
            onChange: (e) => this.onSimpleChange(FIELDS.socialHandle)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.socialHandle]),
            maxLength: 256,
            minLength: 2,
            ...getInput('text', FIELDS.socialHandle, 'Your social network username', 'Social Profile'),
            tooltip: `Enter the full URL of the social media account that best represents you or your brand's web presence.`,
            beforeElement: '@',
            labelWidth: 'auto',
        },
        [FIELDS.facebook]: {
            onChange: (e) => this.onUrlChange(FIELDS.facebook)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.facebook]),
            maxLength: 256,
            ...getInput('url', FIELDS.facebook, 'https://facebook.com/username', 'Facebook URL', true),
        },
        [FIELDS.tumblr]: {
            onChange: (e) => this.onUrlChange(FIELDS.tumblr)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.tumblr]),
            maxLength: 256,
            ...getInput('url', FIELDS.tumblr, 'https://username.tumblr.com', 'Tumblr URL', true),
        },
        [FIELDS.instagram]: {
            onChange: (e) => this.onSimpleChange(FIELDS.instagram)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.instagram]),
            maxLength: 256,
            ...getInput('text', FIELDS.instagram, '@instagram username', 'Instagram username', true),
        },
        [FIELDS.twitter]: {
            onChange: (e) => this.onSimpleChange(FIELDS.twitter)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.twitter]),
            maxLength: 256,
            ...getInput('text', FIELDS.twitter, '@twitter username', 'Twitter username'),
            optional: true,
        },
        [FIELDS.tiktok]: {
            onChange: (e) => this.onSimpleChange(FIELDS.tiktok)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.tiktok]),
            maxLength: 256,
            ...getInput('text', FIELDS.tiktok, '@tiktok username', 'TikTok username'),
            optional: true,
        },
        [FIELDS.youtube]: {
            onChange: (e) => this.onSimpleChange(FIELDS.youtube)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.youtube]),
            maxLength: 256,
            ...getInput('text', FIELDS.youtube, 'https://www.youtube.com/xxxxxx', 'YouTube URL'),
            optional: true,
        },
        [FIELDS.organization]: {
            onChange: (e) => this.onSimpleChange(FIELDS.organization)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.organization]),
            minLength: 1,
            maxLength: 120,
            labelWidth: 'auto',
            ...getInput(
                'text',
                FIELDS.organization,
                'What brand are you applying on behalf of?',
                'Parent Company',
                true
            ),
            tooltip: `If you are applying for a Brand channel, it is recommended, but not required, to complete this field if it is relevant to your business.`,
        },
        [FIELDS.location]: {
            onChange: (e) => this.onSimpleChange(FIELDS.location)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.location]),
            minLength: 2,
            maxLength: 120,
            labelWidth: 'auto',
            ...getInput('text', FIELDS.location, 'Where are you located?', 'Location', true),
            tooltip: `Where are you located? Let us know so that we can best service your request!`,
        },
        [FIELDS.otherIndustry]: {
            onChange: (e) => this.onSimpleChange(FIELDS.otherIndustry)(e.target.value),
            onBlur: () => this.showErrors([FIELDS.otherIndustry]),
            minLength: 2,
            maxLength: 60,
            labelWidth: 'auto',
            ...getInput('text', FIELDS.otherIndustry, 'Please specify your work industry', 'Other industry', false),
        },
    }
    toggleShowPassword = () => {
        this.setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }))
    }

    render() {
        const { validated, showPassword } = this.state
        const {
            showFields,
            showLabel,
            noTooltip,
            noOptional,
            className,
            borderRadius,
            settingsPage,
            isSaving,
            saveResponse,
            changingEmail,
            saveCallback,
            user: { has_social_auth, email },
        } = this.props

        return (
            <div className={className}>
                {map(pick(this.fields, showFields), (inputProps, key) => {
                    const {
                        label,
                        placeholder,
                        tooltip,
                        optional,
                        tooltipWidth,
                        labelWidth,
                        placeholderBelow,
                        beforeElement,
                        isEmailValid,
                        pendingEmail,
                        name,
                        type,
                    } = inputProps
                    const field = validated[key] || {}
                    const { error, showError } = field

                    let inputType = type
                    if (name === FIELDS.password && showPassword) {
                        inputType = 'text'
                    }
                    return (
                        <Test id={key} key={key}>
                            <FieldOuterContainer key={key}>
                                <FieldContainer>
                                    {showLabel && (
                                        <Label
                                            {...{
                                                label: label || placeholder,
                                                tooltip: noTooltip ? '' : tooltip,
                                                optional: noOptional ? false : optional,
                                                tooltipWidth,
                                                labelWidth,
                                            }}
                                        />
                                    )}
                                    <AdaptedFormInput
                                        beforeElement={beforeElement}
                                        errorMessage={showError ? error : null}
                                        errorTooltip
                                        inputProps={{ ...inputProps, type: inputType, name }}
                                        borderRadius={borderRadius}
                                    />
                                    {placeholderBelow && <PlaceholderText>{placeholderBelow}</PlaceholderText>}
                                </FieldContainer>
                                {name === FIELDS.email &&
                                    settingsPage &&
                                    (!has_social_auth || !!email || !!pendingEmail || !!changingEmail) && (
                                        <EmailVerificationMessage
                                            isEmailValid={isEmailValid}
                                            pendingEmail={pendingEmail}
                                            currentlyEditting={changingEmail}
                                            saveCallback={saveCallback}
                                            isSaving={isSaving}
                                            saveResponse={saveResponse}
                                        />
                                    )}
                                {name === FIELDS.password && (
                                    <ShowHidePasswordIcon onClick={this.toggleShowPassword} show={showPassword} />
                                )}
                            </FieldOuterContainer>
                        </Test>
                    )
                })}
            </div>
        )
    }
}

export default Fields
