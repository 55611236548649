import { useDraggable } from '@dnd-kit/core'
import { useEffect } from 'react'
import { Coordinates } from 'types'
import useModifierKeys from '../hooks/use-modifier-keys'
import { Mover } from './style'

type Props = {
    className?: string
    dndId: string
    onMove: (coordinates: Coordinates) => void
}

const DraggableMover = ({ className, dndId, onMove }: Props) => {
    const { listeners, setNodeRef, transform } = useDraggable({
        id: dndId,
        data: { control: 'draggable', type: 'move' },
    })
    const { shift: isShiftKeyPressed } = useModifierKeys()

    useEffect(() => {
        if (!transform) return

        const { x, y } = transform

        if (isShiftKeyPressed) {
            if (Math.abs(x) > Math.abs(y)) {
                onMove({ x, y: 0 })
            } else {
                onMove({ x: 0, y })
            }
        } else {
            onMove({ x, y })
        }
    }, [transform?.x, transform?.y])

    return <Mover {...listeners} className={className} ref={setNodeRef} />
}

export default DraggableMover
