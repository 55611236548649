import { IGif } from '@giphy/js-types'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { LoggedInUser } from 'types'
import Toggle from '../../../../site/creation-tools/components/toggle'

const Privacy = styled.div<{ bulkEditMode: boolean }>`
    float: left;
    vertical-align: top;

    ${(props) =>
        props.bulkEditMode &&
        css`
            flex: 1;
            justify-content: center;
            display: flex;
            float: none;
            vertical-align: none;
        `}
`

const ToggleContainer = styled.div<{ bulkEditMode: boolean }>`
    width: 200px;

    ${(props) =>
        !props.bulkEditMode &&
        css`
            margin-top: -5px;
        `}
`

type Props = {
    permissions: any
    user: LoggedInUser
    gifs: IGif[]
    onTogglePrivacy: Function
    bulkEditMode: boolean
}

const PrivacyToggle = ({ permissions, user, gifs, onTogglePrivacy, bulkEditMode }: Props) => {
    const [isOn, setIsOn] = useState<boolean>(!gifs.some((gif) => gif.is_hidden))

    const toggle = (isOn: boolean) => {
        setIsOn(!isOn)
        // TODO: Error handling with reverting state in case this throws an error fails?
        onTogglePrivacy(isOn)
    }

    return (
        <Privacy bulkEditMode={bulkEditMode}>
            {(permissions.hide || user.isStaff) && (
                <ToggleContainer bulkEditMode={bulkEditMode}>
                    <Toggle onLabel="Public" offLabel="Private" isOn={isOn} onToggle={() => toggle(isOn)} />
                </ToggleContainer>
            )}
        </Privacy>
    )
}

export default PrivacyToggle
