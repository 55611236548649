import { IGif } from '@giphy/js-types'
import { createContext } from 'react'

type ReceivedGifOptions = { gifs: IGif[] }

export type GifContextProps = {
    receivedGifs: (options: ReceivedGifOptions) => void
    removedGifs: (string | number)[]
    getGifById: (id: string | number) => IGif
    gifs: IGif[]
    autoPlay: boolean
}

const GifContext = createContext({} as GifContextProps)

export default GifContext
