import { giphyDarkCharcoal, giphyIndigo, giphyLightGrey, giphyWhite } from '@giphy/colors'
import { ReactNode, useEffect, useRef, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import styled from 'styled-components'

const SearchContainer = styled.div`
    height: 36px;
    margin: 10px 0;
    display: flex;
`

const SearchButton = styled.div`
    background-color: ${giphyIndigo};
    color: ${giphyWhite};
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
`

const InputContainer = styled.div`
    height: 36px;
    width: calc(100% - 36px);
    position: relative;
`

const Input = styled.input<{ showChannelBox?: boolean }>`
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    font-size: 15px;
    border-radius: 0px;
    background-color: ${giphyDarkCharcoal};
    color: ${({ showChannelBox }) => (showChannelBox ? `transparent` : `${giphyWhite}`)};
    outline: 0;
    border: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    &::placeholder {
        color: ${giphyLightGrey};
        font-size: 16px;
    }
    height: 36px;
`

const SEARCH_DEBOUNCE = 500

type SearchBarProps = {
    onChange?: (searchText: string) => void
    onFocus?: () => void
    onBlur?: () => void
    value?: string
    showChannelBox?: boolean
    placeholder?: string
    channelBox?: ReactNode
}
export const SearchBar = ({
    onChange,
    onFocus,
    onBlur,
    value,
    showChannelBox,
    placeholder = 'Search Channels',
    channelBox,
}: SearchBarProps) => {
    const [debouncedInput, setDebouncedInput] = useState<string>('')
    useDebounce(() => onChange?.(debouncedInput), SEARCH_DEBOUNCE, [debouncedInput])
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (showChannelBox) {
            inputRef?.current?.blur()
        }
    }, [showChannelBox, inputRef])

    return (
        <SearchContainer>
            <InputContainer>
                {showChannelBox && <>{channelBox}</>}
                <Input
                    ref={inputRef}
                    showChannelBox={showChannelBox}
                    onFocus={() => {
                        onFocus?.()
                    }}
                    onBlur={() => onBlur?.()}
                    placeholder={placeholder}
                    onChange={({ target: { value } }) => setDebouncedInput(value)}
                    value={value}
                />
            </InputContainer>
            <SearchButton className="ss-search" />
        </SearchContainer>
    )
}
