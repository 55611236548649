import { giphyBlack, giphyGreen, giphyIndigo, giphyRed, giphyYellow } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Confirmation from 'shared/components/edit-panel/confirmation'
import EditPanelContext from 'shared/components/edit-panel/context'
import EditInfo from 'shared/components/edit-panel/info'
import * as selectors from 'shared/redux/reducers'
import saveGifs from 'shared/redux/save-gifs'
import styled, { css } from 'styled-components'
import { withGifDetailContext } from '../../gif-detail-context'
import StatusIndicator, { Status } from './status-indicator'
import isMobile from 'shared/util/is-mobile'

declare global {
    interface Window {
        showSuccess: () => void
        showError: () => void
    }
}

const Container = styled.div<{ isMobile: boolean }>`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    left: 0;
    overflow-y: auto;
    text-align: left;
    top: 0;
    margin-top: 25px;
    ${({ isMobile }) =>
        isMobile &&
        css`
            position: absolute;
            margin-top: 0;
        `}
`

const Buttons = styled.div`
    margin-top: 24px;
`

const Button = styled.div`
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin-top: 6px;
    text-align: center;
    width: 100%;
    -webkit-font-smoothing: antialiased;

    &:active {
        opacity: 0.8;
    }
`

const Trend = styled(Button)`
    background: ${giphyGreen};
    color: ${giphyBlack};
`

const Queue = styled(Button)`
    background: ${giphyYellow};
    color: ${giphyBlack};
`

const Delete = styled(Button)`
    background: ${giphyRed};
`

const Inner = styled.div`
    padding: 0 18px 20px;
`

type Props = {
    gif: IGif
    loggedInUser: any
    onSave?: (gifs: IGif[], data: any) => void
    isSaving?: boolean
}

type Confirmation = {
    message: string
    data: any
    isDelete?: boolean
    title?: string
}

const EditModal = ({ gif, loggedInUser, onSave, isSaving = false }: Props) => {
    const [confirmation, setConfirmation] = useState<Confirmation | null>(null)
    const [status, setStatus] = useState(Status.None)
    const gifLabel = gif.is_sticker ? 'sticker' : 'GIF'

    // add window error/success handlers
    useEffect(() => {
        window.showSuccess = () => {
            setStatus(Status.Success)
        }
        window.showError = () => {
            setStatus(Status.Error)
        }
    }, [])

    // update saving status
    useEffect(() => {
        if (isSaving) {
            setStatus(Status.Saving)
        }
    }, [isSaving])

    const onChange = (data: any, needsConfirm?: boolean) => {
        if (needsConfirm) {
            setConfirmation({
                message: 'Are you sure?',
                data,
            })
        } else {
            onSave?.([gif], data)
        }
    }

    const onTogglePrivacy = (isHidden: boolean) => {
        onSave?.([gif], { is_hidden: isHidden })
    }

    const onTrend = () => {
        setConfirmation({
            message: `Are you sure you want to trend this ${gifLabel}?`,
            data: { create_tag: 'giphytrending', delete_tag: 'giphytrendingqueue' },
            title: 'Trend',
        })
    }

    const onQueue = () => {
        setConfirmation({
            message: `Are you sure you want to add this ${gifLabel} to the trending queue?`,
            data: { create_tag: 'giphytrendingqueue', delete_tag: 'giphytrending' },
            title: 'Queue',
        })
    }

    const onDelete = () => {
        setConfirmation({
            message: `Are you sure you want to delete this ${gifLabel}?`,
            data: { delete_gif: true },
            isDelete: true,
            title: 'Delete',
        })
    }

    const onConfirmConfirmation = () => {
        if (!confirmation || !confirmation.data) return
        onSave?.([gif], confirmation.data)
        setConfirmation(null)
    }

    const onCancelConfirmation = () => {
        setConfirmation(null)
    }

    return (
        <EditPanelContext.Provider
            value={{
                allowBulkEditMode: false,
                bulkEditMode: true,
                onBulkEditModeChange: () => {},
                setChannels: () => {},
                editModeChannels: [],
            }}
        >
            <Container isMobile={isMobile()}>
                <Inner>
                    <EditInfo
                        gifs={[gif]}
                        permissions={loggedInUser.permissions}
                        user={loggedInUser}
                        onChange={onChange}
                        onTogglePrivacy={onTogglePrivacy}
                    />
                    {(loggedInUser.isStaff || loggedInUser.permissions.delete) && (
                        <Buttons>
                            {loggedInUser.isStaff && (
                                <>
                                    <Trend onClick={onTrend}>Trend</Trend>
                                    <Queue onClick={onQueue}>Queue</Queue>
                                </>
                            )}
                            <Delete onClick={onDelete}>Delete</Delete>
                        </Buttons>
                    )}
                </Inner>
                <StatusIndicator status={status} />
                {confirmation && (
                    <Confirmation
                        prompt={confirmation.message}
                        title={confirmation.title}
                        yesColor={confirmation.isDelete ? giphyRed : giphyIndigo}
                        yesCta={confirmation.isDelete ? 'Delete' : 'Yup!'}
                        onConfirmClick={onConfirmConfirmation}
                        onCancelClick={onCancelConfirmation}
                    />
                )}
            </Container>
        </EditPanelContext.Provider>
    )
}

export default compose(
    withGifDetailContext,
    connect(
        (state) => ({
            isSaving: selectors.getEditModeIsSaving(state),
        }),
        {
            onSave: saveGifs,
        }
    )
)(EditModal)
