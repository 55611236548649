import { LoaderSize } from '../types'
import { Container, Snake } from './style'

type Props = {
    className?: string
    size: LoaderSize
}

const LoaderSnake = ({ className, size }: Props) => {
    return (
        <Container className={className} loaderSize={size}>
            <Snake />
            <Snake />
            <Snake />
            <Snake />
        </Container>
    )
}

export default LoaderSnake
