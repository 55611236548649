import { GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import appendQuery from 'append-query'
import { useContext } from 'react'
import UserContext from 'shared/contexts/user'
import { USER_DELETE, USER_POST } from 'shared/util/fetch-methods'
import isMobile from 'shared/util/is-mobile'
import { LoggedInUser } from 'types'
import { API_URL, getResults, MOBILE_API_KEY, webFetch, WEB_API_KEY, getGifById } from '.'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'

export const useLocalFavorites = (limit) => {
    const { localFavorites } = useContext(UserContext)
    const fetchGifs = async (offset: number) => {
        const moreGifs = localFavorites?.slice(offset, offset + limit)
        const result = await webFetch.gifs(moreGifs as string[])
        result.pagination.total_count = localFavorites?.length ?? 0
        return result
    }
    return { localFavorites, fetchGifs }
}

export const favoritesByUser = (
    { userToken: access_token }: LoggedInUser,
    offset: number,
    limit: number = 25,
    fields: (keyof IGif)[] = []
) =>
    getResults(
        appendQuery(`${API_URL}user/favorites`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            offset,
            fields: fields.join(','),
            limit,
        }),
        {
            method: 'get',
            credentials: 'same-origin',
            headers: {
                Authorization: `Token ${access_token}`,
            },
        }
    ) as Promise<GifsResult>

export const addFavoritesByUser = (userToken: string, id: number | string) =>
    getResults(
        appendQuery(`${API_URL}user/favorites`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            access_token: userToken,
        }),
        USER_POST({ gif_id: id }, userToken)
    ) as Promise<IGif>

export const deleteFavoritesByUser = (userToken: string, id: number | string) =>
    getResults(
        appendQuery(`${API_URL}user/favorites`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
            access_token: userToken,
        }),
        USER_DELETE({ gif_id: id }, userToken)
    )

export const checkIsFavorite = async (gifId, userToken: string) => {
    const result = await getResults(
        appendQuery(`${API_URL}user/favorites/statuses`, {
            api_key: isMobile() ? MOBILE_API_KEY : WEB_API_KEY,
        }),
        {
            method: 'POST',
            headers: {
                Authorization: `Token ${userToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ gif_ids: [gifId] }),
        }
    )

    return result.data.length > 0
}

export async function toggleFavorite(gifId, userToken: string) {
    // Check if is favorited
    const isFavorite = await checkIsFavorite(gifId, userToken)

    if (isFavorite) {
        // Remove favorite
        deleteFavoritesByUser(userToken, gifId)
        return false
    } else {
        // Add favorite
        const data = await addFavoritesByUser(userToken, gifId)
        const response = await getGifById(gifId)

        if (data) {
            setGADataLayer({
                event: 'favorite_success',
                options: {
                    content: getContentOfGif(response.data),
                    creator: getCreatorOfGif(response.data),
                },
            })
        }

        return true
    }
}
