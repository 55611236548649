import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { heightFromControlSize } from 'utils'
import { SliderLayoutType } from '..'

const trackBaseCSS = css`
    border-radius: 1px;
    display: block;
    height: 2px;
    left: 0;
    margin-top: -1px;
    position: absolute;
    top: 50%;
`

const HandleBase = styled.div`
    cursor: ew-resize;
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.5));
    position: absolute;
`

export const HandleRectangle = styled(HandleBase)`
    border-radius: 2px;
    box-sizing: content-box;
    height: 100%;
    left: -2px;
    padding: 2px 0;
    top: -2px;
    width: 4px;
`

export const HandleCircle = styled(HandleBase)`
    border-radius: 50%;
    height: 20px;
    left: -10px;
    width: 20px;
`

export const Fill = styled.div`
    ${trackBaseCSS}
`

export const Container = styled.div<{ $layout: SliderLayoutType; $size: ControlSize; $trackColor: string }>`
    position: relative;
    width: 100%;

    ${HandleCircle} {
        margin-top: -10px;
        top: 50%;
    }

    ${({ $layout, $size, $trackColor }) => {
        if ($layout === 'circle') {
            return css`
                height: ${heightFromControlSize($size)}px;

                &:before {
                    ${trackBaseCSS}
                    background: ${$trackColor};
                    content: '';
                    width: 100%;
                }
            `
        }

        return css`
            background: ${$trackColor};
            height: ${heightFromControlSize($size)}px;
        `
    }}
`
