import { FC, useRef, useCallback } from 'react'
import * as React from 'react'
import { without } from 'lodash'
import RefreshDataContext, { RefreshChannel, RefreshChannelOptions } from './refresh-data'
import { IChannel } from 'shared/types/channel'
import { useSameObjectRef } from 'shared/hooks/use-same-ref'

type Props = {
    children?: React.ReactNode
}

const RefreshDataContextManager: FC<Props> = ({ children }) => {
    const map = useRef<RefreshChannel[]>([])

    const offRefreshChannel = useCallback(
        (cb: RefreshChannel) => {
            map.current = without(map.current, cb)
        },
        [map]
    )

    const onRefreshChannel = useCallback(
        (cb: RefreshChannel) => {
            map.current = [...map.current, cb]
            return () => offRefreshChannel(cb)
        },
        [map, offRefreshChannel]
    )

    const refreshChannel = useCallback(
        (channel: IChannel, options: RefreshChannelOptions = {}) => {
            map.current.forEach((cb) => cb(channel, options))
        },
        [map]
    )
    const value = useSameObjectRef({ onRefreshChannel, offRefreshChannel, refreshChannel })
    return <RefreshDataContext.Provider value={value}>{children}</RefreshDataContext.Provider>
}

export default RefreshDataContextManager
