import isMobile from 'is-mobile'
import { useContext, useEffect } from 'react'
import VideoContext from '../video-context'

const DocumentVisibilityManager = () => {
    const { pause } = useContext(VideoContext)
    const onVisibilityChange = () => {
        if (document.hidden) {
            pause()
        }
    }

    useEffect(() => {
        if (isMobile()) {
            document.addEventListener('visibilitychange', onVisibilityChange)
        }
        return () => document.removeEventListener('visibilitychange', onVisibilityChange)
    }, [])

    return null
}

export default DocumentVisibilityManager
