exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* grid */\n\n/* grid columns */\n\n/* grid push */", ""]);

// exports
exports.locals = {
	"gridSpacing": "16px",
	"gridWidth": "1040px",
	"gridColumnWidth": "72px",
	"gridPushWidth": "88px",
	"grid1": "72px",
	"grid2": "160px",
	"grid3": "248px",
	"grid4": "336px",
	"grid5": "424px",
	"grid6": "512px",
	"grid7": "600px",
	"grid8": "688px",
	"grid9": "776px",
	"grid10": "864px",
	"grid11": "952px",
	"grid12": "1040px",
	"gridPush1": "88px",
	"gridPush2": "176px",
	"gridPush3": "264px",
	"gridPush4": "352px",
	"gridPush5": "440px",
	"gridPush6": "528px",
	"gridPush7": "616px",
	"gridPush8": "704px",
	"gridPush9": "792px",
	"gridPush10": "880px",
	"gridPush11": "968px"
};