import { useContext, useRef, MouseEvent, FC } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import ImagePreloader from 'shared/util/image-preloader'
import { INFO_IMAGE_URLS } from '../constants'
import { AboutPageContext } from './../context'

const Container = styled.strong`
    cursor: pointer;
    font-weight: normal;
    text-decoration: underline;
`

type Props = {
    hoverId: string
    className?: string
    children?: React.ReactNode
}

const AboutPageHoverItem: FC<Props> = ({ children, hoverId, className }) => {
    const { addHoverImage } = useContext(AboutPageContext)
    const imageIndexRef = useRef<number>(0)
    const imageUrls: string[] = INFO_IMAGE_URLS[hoverId] || []
    const onMouseOver = async (e: MouseEvent) => {
        if (!imageUrls.length) return
        const imageUrl: string = imageUrls[imageIndexRef.current]
        e.persist()
        await ImagePreloader.load([imageUrl])
        addHoverImage({
            url: imageUrl,
            x: e.clientX,
            y: e.clientY,
        })
        imageIndexRef.current++
        if (imageIndexRef.current >= imageUrls.length) {
            imageIndexRef.current = 0
        }
    }
    return (
        <Container className={className} onMouseOver={onMouseOver}>
            {children}
        </Container>
    )
}

export default AboutPageHoverItem
