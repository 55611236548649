import { GiphyFetch } from '@giphy/js-fetch-api'
import { debounce } from 'lodash'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { WEB_API_KEY } from 'shared/api'
import { getGifDetailPermissions } from 'shared/api/detail'
import { setEditModeChannels } from 'shared/redux/edit-mode-channels'
import fetchChannels from 'shared/redux/fetch-channels'
import { fetchGifs, fetchGifsFromUrl } from 'shared/redux/fetch-gifs'
import { receivedGifs } from 'shared/redux/gifs'
import { resetUserPermissions, setUserPermissions } from 'shared/redux/permissions'
import * as selectors from 'shared/redux/reducers'
import routes from 'shared/routes'
import log from 'shared/util/log'
import GifDetail from '../components/detail'

const FETCH_DEBOUNCE = 500

/**
 * Don't render the GifDetail without a gif
 * If there's no gif, fetch one with the gif id
 * Also, fetch pagination gifs
 */
class EnsureGif extends PureComponent {
    static defaultProps = {
        relatedGifs: [],
    }
    state = {}
    async ensureGif() {
        const { gif, gifId, groupId, receivedGifs } = this.props
        if (!gif && gifId) {
            try {
                /**
                 * Fires on history popstate
                 * If we knew which direction we were going
                 * forward or backward, we could prepend
                 * or append the gif set, but we don't know
                 */
                const gf = new GiphyFetch(WEB_API_KEY)
                const { data, meta } = await gf.gif(gifId)
                receivedGifs({
                    gifs: [data],
                    responseId: meta.response_id,
                    groupId,
                })
            } catch (error) {
                location.href = routes.ERROR_404.pathname
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { gif, isRemoved, user = {} } = this.props
        if (isRemoved) {
            const { username } = user
            location.href = username ? `/channel/${username}` : `/`
            return
        }
        this.ensureGif()
        // avoid errors when a gif is deleted
        if (gif && prevProps.gif && gif.id !== prevProps.gif.id) {
            this.getNewGifData()
        }
    }
    componentDidMount() {
        this.ensureGif()
        this.getNewGifData()
    }
    getNewGifData = debounce(async () => {
        // gets new user permissions and view count & resets the schema markup
        const { gif, setUserPermissions, resetUserPermissions, user } = this.props
        if (!user.loggedIn) {
            resetUserPermissions()
            return
        }

        try {
            const { id } = gif
            const {
                gif: { import_type },
                permissions,
            } = await getGifDetailPermissions(id)
            user.loggedIn ? setUserPermissions(permissions) : resetUserPermissions()
            this.setState({
                importType: import_type,
            })
        } catch (e) {
            resetUserPermissions()
            log.error(`Error getting gif view count ${e}`)
        }
    }, FETCH_DEBOUNCE)
    onError = () => {
        const { gifId } = this.props
        location.href = `/explore/${gifId}`
    }
    render() {
        const { gif, isRemoved } = this.props
        const { importType } = this.state
        return <div>{gif && !isRemoved && <GifDetail {...this.props} importType={importType} />}</div>
    }
}

export default connect(
    (state, { gifId, groupId }) => {
        const relatedGroupId = `${gifId}-related`
        return {
            gifId,
            relatedGroupId,
            gif: selectors.getGifById(state, gifId, groupId),
            nextGif: selectors.getNextGif(state, gifId, groupId || relatedGroupId),
            prevGif: selectors.getPrevGif(state, gifId, groupId || relatedGroupId),
            relatedGifs: selectors.getGifs(state, relatedGroupId),
            permissions: selectors.getUserPermissions(state),
            editModeChannels: selectors.getEditModeChannels(state),
            channelSelectorItems: state.channelSelectorItems,
            user: selectors.getUser(state),
            isRemoved: selectors.getRemovedGifs(state).indexOf(gifId) !== -1,
        }
    },
    {
        fetchGifsFromUrl,
        resetUserPermissions,
        fetchGifs,
        receivedGifs,
        setUserPermissions,
        setEditModeChannels,
        fetchChannels,
    }
)(EnsureGif)
