import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { giphyDarkGrey } from '@giphy/colors'
import {
    CSSProperties,
    ForwardedRef,
    forwardRef,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { ControlSize } from 'types'
import DraggableContext, { DraggableContextProps } from '../../draggable/context'
import { Container, Dnd } from './style'

export type SliderLayoutType = 'circle' | 'bar'

type Props = {
    children?: ReactNode
    className?: string
    color?: string
    defaultValue?: number
    disabled?: boolean
    dndId?: string
    draggableOptions?: Partial<DraggableContextProps>
    fillColor?: string
    handleColor?: string
    layout?: SliderLayoutType
    size?: ControlSize
    style?: CSSProperties
    value?: number
    onChange?: (value: number) => void
    onSliderValueChange?: (value: number) => void
    customHandleTransform?: string
}

export type SliderRef = {
    setValue?: (value: number) => void
}

export const SliderWithoutContext = (
    {
        children,
        className,
        color = giphyDarkGrey,
        defaultValue = 1,
        disabled = false,
        dndId,
        fillColor,
        handleColor = '#fff',
        layout = 'circle',
        size = 'small',
        style,
        value: valueProp,
        onChange,
        onSliderValueChange,
        customHandleTransform,
    }: Props,
    ref: ForwardedRef<SliderRef>
) => {
    const initialValueRef = useRef<number | undefined>(valueProp || defaultValue)
    const containerRef = useRef<HTMLDivElement>(null)
    const [value, setValue] = useState<number>(valueProp || defaultValue)

    useEffect(() => {
        if (typeof valueProp === 'number' && valueProp !== value) {
            setValue(valueProp)
        }
    }, [valueProp])

    useImperativeHandle(ref, () => ({
        setValue(value: number) {
            setValue(value)
        },
    }))

    useEffect(() => {
        if (value !== initialValueRef.current) {
            initialValueRef.current = undefined
            onChange?.(value)
        }
    }, [value])

    return (
        <Container $disabled={disabled} $size={size} className={className} ref={containerRef} style={style}>
            {children}
            <Dnd
                disabled={disabled}
                dndId={dndId}
                fillColor={fillColor}
                handleColor={handleColor}
                layout={layout}
                size={size}
                trackColor={color}
                value={value}
                onChange={setValue}
                onSliderValueChange={onSliderValueChange}
                customHandleTransform={customHandleTransform}
            />
        </Container>
    )
}

const SliderWithoutContextWithForwardRef = forwardRef(SliderWithoutContext)

const Slider = ({ draggableOptions = {}, ...props }: Props, ref: ForwardedRef<SliderRef>) => (
    <DraggableContext
        {...draggableOptions}
        modifiers={[...(draggableOptions.modifiers || []), restrictToHorizontalAxis]}
    >
        <SliderWithoutContextWithForwardRef {...props} ref={ref} />
    </DraggableContext>
)

export default forwardRef(Slider)
