import { setGADataLayer, getCreator } from 'analytics'

export function trackGA4NavigationEvent(url, type) {
    const data = {
        event: 'creator_social_nav',
        options: {
            eventInfo: {
                navElement: 'creator',
                navSubelement: 'creator social',
                link: url,
            },
            creator: {
                ...getCreator(),
                socialLink: type,
            },
        },
    }
    setGADataLayer(data)
}
