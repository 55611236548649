import { giphyBlack } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { brightnessFromColor } from 'utils'

const inLeft = keyframes`
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
`

const inRight = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
`

export const Container = styled.div<{ $color: string; $open: boolean; $side: 'left' | 'right' }>`
    background: ${({ $color }) => $color};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    color: ${({ $color }) => (brightnessFromColor($color) > 0.5 ? giphyBlack : '#fff')};
    height: 100%;
    overflow-y: auto;
    padding: 20px 10px;
    position: fixed;
    top: 0;
    transition: transform 0.15s ease-out;
    width: 350px;

    ${({ $open, $side }) => {
        switch ($side) {
            case 'left':
                return css`
                    animation: ${inLeft} 0.15s ease-out;
                    border-bottom-right-radius: 12px;
                    border-top-right-radius: 12px;
                    left: 0;
                    transform: translateX(${$open ? 0 : -100}%);
                `
            case 'right':
                return css`
                    animation: ${inRight} 0.15s ease-out;
                    border-bottom-left-radius: 12px;
                    border-top-left-radius: 12px;
                    right: 0;
                    transform: translateX(${$open ? 0 : 100}%);
                `
            default:
                return
        }
    }}
`
