type Props = {
    className?: string
    isGradient?: boolean
}
const SearchIcon = ({ className, isGradient }: Props) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 29">
        <defs>
            <linearGradient
                xmlns="http://www.w3.org/2000/svg"
                x1="96.3160433%"
                y1="0%"
                x2="3.68395671%"
                y2="100%"
                id="searchIconGradient"
            >
                <stop stopColor="#FF6666" offset="0%" />
                <stop stopColor="#9933FF" offset="100%" />
            </linearGradient>
        </defs>
        <path
            d="M10.548 20.41l-7.3 7.79a1.5 1.5 0 01-2.19-2.05l7.348-7.842a11.066 11.066 0 01-2.308-6.79c0-6.069 4.841-11 10.827-11s10.828 4.931 10.828 11c0 6.07-4.842 11-10.828 11-2.385 0-4.589-.783-6.377-2.109zm6.377-.892c4.317 0 7.828-3.575 7.828-8 0-4.424-3.511-8-7.828-8-4.316 0-7.827 3.576-7.827 8 0 4.425 3.51 8 7.827 8z"
            fill={isGradient ? 'url(#searchIconGradient)' : '#fff'}
            fillRule="nonzero"
        />
    </svg>
)
export default SearchIcon
