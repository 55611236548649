import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { errorColor } from '../css/colors'
import IconTooltip from './icon-tooltip'

const autofill = keyframes`
    0% {
        opacity: .999;
    }
    100% {
        opacity: 1;
    }
`

const autofillCancel = keyframes`
    0% {
        opacity: .999;
    }
    100% {
        opacity: 1;
    }
`

const { object, string, bool } = PropTypes

const Wrapper = styled.div`
    height: 40px;
    width: 100%;
    margin: 12px 0;
    position: relative;
    display: flex;
`

const ErrorMessage = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
`
const ErrorContainer = styled.div`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: ${errorColor};
    background-color: transparent;
    text-align: center;
    z-index: 1;
    position: absolute;
    height: 95%;
    width: 75px;
    top: 3px;
    right: 6px;
    pointer-events: none;
`

const ErrorToolTipContainer = styled.div`
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 6px;
`

const InputStyled = styled.input`
    height: 100%;
    width: 100%;
    padding: 5px;
    padding-left: 12px;
    box-sizing: border-box;
    border-radius: 0;
    border: ${(props) => (props.isError ? `3px solid ${errorColor}` : 0)};
    &:-webkit-autofill {
        animation: ${autofill} 0.1s linear;
    }
    &:not(:-webkit-autofill) {
        animation: ${autofillCancel} 0.1s linear;
    }
`
/**
 * Wraps an input and shows error messages
 */
class Input extends PureComponent {
    static propTypes = {
        initialValue: string,
        // passed to input element
        inputProps: object.isRequired,
        // when defined, highlights the input and shows the message
        // in the top right corner
        errorMessage: string,
        // will auto focus
        autoFocus: bool,
    }

    componentDidMount() {
        const { autoFocus, initialValue } = this.props
        if (autoFocus) {
            this.input.focus()
        }
        if (initialValue) {
            this.input.value = initialValue
        }
        this.input.addEventListener('animationstart', this.checkValue, false)
    }

    componentWillUnmount() {
        this.input.removeEventListener('animationstart', this.checkValue, false)
    }

    checkValue = ({ animationName }) => {
        const { onAutoFill, onAutoFillCancel } = this.props
        if (animationName === autofill && onAutoFill) {
            onAutoFill()
        } else if (animationName === autofillCancel && onAutoFillCancel) {
            onAutoFillCancel()
        }
    }

    blur() {
        this.input.blur()
    }

    render() {
        const { errorMessage, errorTooltip, inputProps, className } = this.props
        return (
            <Wrapper className={className}>
                <InputStyled {...inputProps} isError={!!errorMessage} ref={(c) => (this.input = c)} />
                {errorTooltip && errorMessage ? (
                    <ErrorToolTipContainer>
                        <IconTooltip color={errorColor} icon="ss-alert" show>
                            {errorMessage}
                        </IconTooltip>
                    </ErrorToolTipContainer>
                ) : (
                    <ErrorContainer>
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    </ErrorContainer>
                )}
            </Wrapper>
        )
    }
}

export default Input
