import styled, { css } from 'styled-components'
import { giphyWhite, primaryCTA, secondaryCTA } from '../css/colors'
import mobileCheck from 'is-mobile'
const isMobile = mobileCheck()

const Button = styled.button`
    white-space: nowrap;
    border: 0;
    box-sizing: border-box;
    color: ${giphyWhite};
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    min-width: 36px;
    outline: none;
    padding: 0 15px;
    text-align: center;
    user-select: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;

    ${!isMobile &&
    css`
        &:hover {
            color: ${giphyWhite};
        }
    `};

    &:active {
        opacity: 0.9;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
    background-color: ${({
        color,
        secondary,
        theme: { buttonColor = primaryCTA, secondaryButtonColor = secondaryCTA },
    }) => color || (secondary ? secondaryButtonColor : buttonColor)};
`

export default Button
