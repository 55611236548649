import { ReactNode } from 'react'
import { brightnessFromColor, colorFromGradientPosition } from 'utils'
import IconButton from '../../controls/icon-button'
import { MessageProps } from '../types'
import { Container, Spacer, Wrapper } from './style'

interface Props extends MessageProps {
    children?: ReactNode
    className?: string
}

const Message = ({ children, className, gradient = 'indigo-purple', textColor, closeable = false, onClose }: Props) => {
    const gradientColor = colorFromGradientPosition(gradient, 0.5)
    const textColorFallback = brightnessFromColor(gradientColor) > 0.5 ? '#000' : '#fff'
    const color = textColor || textColorFallback

    return (
        <Container $gradient={gradient} $textColor={color} className={className}>
            <Spacer />
            <Wrapper>{children}</Wrapper>
            <Spacer>
                {closeable && (
                    <IconButton
                        color="transparent"
                        icon="nav-close"
                        iconColor={color}
                        size="medium"
                        onClick={() => onClose?.()}
                    />
                )}
            </Spacer>
        </Container>
    )
}

export default Message
