import { giphyLightestGrey } from '@giphy/colors'
import Loader from 'shared/components/loaders/dots'
import styled from 'styled-components'

const EmptyEmoji = styled.img`
    height: 44px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
`

const LoaderWrapper = styled.div`
    height: 80px;
    position: relative;
    top: -20px;
    width: 100%;
`

const Description = styled.p`
    text-align: center;
    color: ${giphyLightestGrey};
    padding: 0 10px;
`

type Props = {
    isFetching: boolean
}

const SuggestedTagsPlaceholder = ({ isFetching }: Props) => (
    <div>
        {isFetching ? (
            <>
                <LoaderWrapper>
                    <Loader isAbsolute />
                </LoaderWrapper>
                <Description>Processing your GIF for suggestions…hold on!</Description>
            </>
        ) : (
            <>
                <EmptyEmoji src="https://media.giphy.com/media/WtDLxQWQWLtw3oIXTy/giphy.gif" />
                <Description>Add more tags to get recommendations!</Description>
            </>
        )}
    </div>
)

export default SuggestedTagsPlaceholder
