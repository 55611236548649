const BASE_URL = `https://giphy.page.link?apn=com.giphy.messenger&isi=974748812&ibi=com.giphy.giphyformessenger&ius=giphy&efr=1`

const getAppDeepLink = (category?: string, slug?: string) => {
    switch (category) {
        case 'GIF Detail':
            return `${BASE_URL}&link=${encodeURIComponent(`https://giphy.com/gifs/${slug}`)}`
        case 'Search':
        case 'Explore':
            return `${BASE_URL}&link=${encodeURIComponent(`https://giphy.com/search/${slug}`)}`
        case 'GIF Maker':
        case 'Upload':
            return `https://giphy.page.link/create`
        case 'Channel':
            return `${BASE_URL}&link=${encodeURIComponent(`https://giphy.com/channel/${slug}`)}`
        case 'Videos':
            return `https://giphy.page.link/videos`
        case 'Video Detail':
            return `${BASE_URL}&link=${encodeURIComponent(`https://giphy.com/videos/${slug}`)}`
        case 'AR Scene':
            return `${BASE_URL}&link=${encodeURIComponent(`https://giphy.com/ar-scenes/${slug}`)}`
        default:
            return `https://giphy.page.link/home`
    }
}

export default getAppDeepLink
