import { useContext, useState, ReactNode } from 'react'
import { IChannel } from 'shared/types/channel'
import RefreshDataContext from 'shared/contexts/refresh-data'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { DndContext, rectIntersection, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { moveChannel } from 'shared/api/channels'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { CollectionStyleContext } from '../../context/collection-style-context'

export const checkCollisionRatio = (ratio: number) => {
    return ratio > 0.15 && ratio < 0.5
}

type Props = {
    children: ReactNode
}

const CollectionsDnd = ({ children }: Props) => {
    const { channels, setIsDragging, getChannelChildren, setCombineCollectionsSuccessMessage, activeChannelId } =
        useContext(CollectionContext)
    const { refreshChannel } = useContext(RefreshDataContext)
    const { channelColors, setChannelColors } = useContext(CollectionStyleContext)
    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 90, tolerance: 0 } }))
    const [sortables, setSortables] = useState<string[]>([])

    useAsyncEffect(async () => {
        if (channels[activeChannelId]) {
            let items = await getChannelChildren(channels[activeChannelId])
            setSortables(items.map((item) => `${item.id}`))
        }
    }, [channels[activeChannelId]])

    const onDragStart = ({ active }) => {
        if (!active) {
            return
        }
        setIsDragging(true)
    }

    const onDragEnd = async ({ active, over, collisions }) => {
        setIsDragging(false)
        if (!over) {
            return
        }
        const activeId = active.id
        const oldIndex = sortables.indexOf(active.id)
        const newIndex = sortables.indexOf(over.id)
        const sourceChannel = channels[activeChannelId]
        let targetId: string
        let isReparenting: boolean
        let newChildren: IChannel[]
        let targetChildren: IChannel[]

        const getCollision = (id1: string, val1: number, id2: string, val2: number) => {
            if (
                (newIndex < sortables.indexOf(id2) && activeId !== id1) ||
                (newIndex > sortables.indexOf(id2) && activeId === id2)
            ) {
                return { collisionId: id1, collisionRatio: val1 }
            } else {
                return { collisionId: id2, collisionRatio: val2 }
            }
        }

        const setTargetId = () => {
            if (over.id === `${sourceChannel.parent}`) {
                return over.id
            }
            if (collisions.length < 2) {
                return
            }
            const { collisionId, collisionRatio } = getCollision(
                collisions[0].id,
                collisions[0].data.value,
                collisions[1].id,
                collisions[1].data.value
            )

            if (collisionId !== activeId && collisionRatio != null && checkCollisionRatio(collisionRatio)) {
                return collisionId
            }
        }

        targetId = setTargetId()
        if (targetId) {
            isReparenting = true
            newChildren = sourceChannel.children.filter((c) => `${c.id}` !== activeId)
            targetChildren = [...(await getChannelChildren(channels[targetId])), channels[activeId]]
            setSortables(sortables.splice(oldIndex, 1))
            refreshChannel(
                {
                    ...channels[targetId],
                    children: targetChildren,
                    has_children: !!targetChildren.length,
                },
                { isReordering: true }
            )
        } else {
            isReparenting = false
            if (newIndex === oldIndex) {
                return
            }
            let reorderedChannels = arrayMove(sortables, oldIndex, newIndex)
            setChannelColors(arrayMove(channelColors, oldIndex, newIndex))
            setSortables(reorderedChannels)
            targetId = over.id
            newChildren = reorderedChannels.map((id) => channels[id])
        }
        refreshChannel(
            {
                ...sourceChannel,
                children: newChildren,
                has_children: newChildren.length > 0,
            },
            { isReordering: true }
        )
        try {
            await moveChannel(
                activeId,
                parseInt(targetId),
                isReparenting ? 'last-child' : oldIndex < newIndex ? 'right' : 'left'
            )
        } catch (error) {
            console.error(`Move channel error:`, error)
            refreshChannel({ ...sourceChannel })
        }
        isReparenting &&
            setCombineCollectionsSuccessMessage({
                draggableName: channels[activeId].display_name,
                targetChannelName: channels[targetId].display_name,
                draggableId: activeId,
                sourceId: `${sourceChannel.id}`,
                originalIndex: oldIndex,
            })
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={rectIntersection}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            autoScroll={false}
        >
            <SortableContext items={sortables} strategy={rectSortingStrategy}>
                {children}
            </SortableContext>
        </DndContext>
    )
}

export default CollectionsDnd
