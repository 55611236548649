import { Logger } from '@giphy/js-util'
import { FC, useCallback } from 'react'
import * as React from 'react'
import { matchPath, useHistory, useLocation } from 'react-router'
import useSameObjectRef from 'shared/hooks/use-same-ref'
import routes, { getRouteFromPathname } from 'shared/routes'
import { trackPageView } from 'shared/util/analytics'
import { getGifId } from 'shared/util/url'
import GAEventContext from './ga-events'

type Props = {
    children: React.ReactNode
}

const GAEventContextManager: FC<Props> = ({ children }) => {
    const { pathname } = useLocation()
    const history = useHistory()

    const onPagination = useCallback(
        (page: number) => {
            if (page === 1) {
                return
            }
            const route = getRouteFromPathname(pathname)

            let url = ''
            switch (route) {
                case routes.GIF_DETAIL: {
                    const id = getGifId(pathname)
                    if (!id) {
                        console.error(`Gif detail GA id not found in pathname: ${pathname}`)
                    } else {
                        url = `/gifs/${id}/related/page/${page}`
                    }
                    break
                }
                case routes.SEARCH: {
                    const match = matchPath<{ term: string }>(pathname, routes.SEARCH)
                    const term = match?.params?.term
                    if (!term) {
                        console.error(`Search GA term not found in pathname: ${pathname}`)
                    } else {
                        url = `/search/${term}/${page}`
                    }
                    break
                }
                case routes.EXPLORE: {
                    const match = matchPath<{ term: string }>(pathname, routes.EXPLORE)
                    const term = match?.params?.term
                    if (!term) {
                        console.error(`Explore GA term not found in pathname: ${pathname}`)
                    } else {
                        url = `/explore/${term}/${page}`
                    }
                    break
                }
                case routes.TRENDING: {
                    url = `/trending-gifs/page/${page}`
                    break
                }
                case routes.FAVORITES: {
                    url = `/favorites/page/${page}`
                    break
                }
                case routes.CHANNEL: {
                    const id = (history.location.state as any)?.channelId
                    if (id) {
                        url = `/channels/${id}/page/${page}`
                    }
                    break
                }
                default:
                    break
            }
            if (url) {
                Logger.debug(`Pagination - track GA, url: ${url}`)
                trackPageView(url, false)
            } else {
                console.error(`No GA pagination for ${pathname}`)
            }
        },
        [pathname]
    )
    const value = useSameObjectRef({
        onPagination,
    })
    return <GAEventContext.Provider value={value}>{children}</GAEventContext.Provider>
}

export default GAEventContextManager
