import { createContext } from 'react'
import { IChannel } from 'shared/types/channel'

export type RefreshChannel = (channel: IChannel, options: RefreshChannelOptions) => void
export type RefreshChannelOptions = {
    // flag to indicate we should try to refresh the entire channel tree (not ideal)
    deepRefresh?: boolean
    // flat to indicate that we are explicityly setting children, and we should not fetch
    // main use case is we reorder channel children, we need to save that state,
    // we then make the reorder request and have to leacve the children the way they are.
    // if we didn't do that the children would be fetched before the move causing weirdness
    isReordering?: boolean
}
export type RefreshDataContextProps = {
    onRefreshChannel: (cb: RefreshChannel) => () => void
    offRefreshChannel: (cb: RefreshChannel) => void
    refreshChannel: (channel: IChannel, options?: RefreshChannelOptions) => void
}

const RefreshDataContext = createContext({} as RefreshDataContextProps)

export default RefreshDataContext
