import { giphyIndigo } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { clamp } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import FrameStrip from './frame-strip'

const Container = styled.div`
    height: 33px;
    position: relative;
    border-radius: 4px;
`

const Playhead = styled.div<{ progress: number }>`
    background-color: white;
    width: 5px;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: ${(props) => Math.round(props.progress * 100)}%;
`

const Label = styled.div`
    border-radius: 8px;
    height: 25px;
    background-color: ${giphyIndigo};
    position: absolute;
    top: -30px;
    text-align: center;
    padding: 0 15px;
    vertical-align: middle;
    color: white;
    transform: translateX(-50%);
    z-index: 2;
`

type Props = {
    gif: IGif
    videoApi?: any
    className?: string
}

const Scrubber = ({ className = '', gif, videoApi }: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [containerWidth, setContainerWidth] = useState(0)
    const [containerLeftOffset, setContainerLeftOffset] = useState(0)
    const [videoProgress, setVideoProgress] = useState(0)
    const [cursorPosition, setCursorPosition] = useState(0)
    const [isHovered, setIsHovered] = useState(false)
    const { url, width, height } = gif['video']!['assets']['360p']

    const onWindowResize = () => {
        updateContainerDimensions()
    }

    const updateContainerDimensions = () => {
        setContainerWidth(containerRef.current?.clientWidth ?? 0)
        setContainerLeftOffset(containerRef.current?.getBoundingClientRect().x ?? 0)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (videoApi) {
                setVideoProgress(videoApi.getCurrentProgress())
            }
        }, 100)
        return () => clearInterval(intervalId)
    }, [videoApi])
    useEffect(() => {
        updateContainerDimensions()
        window.addEventListener('resize', onWindowResize, false)
        return () => window.removeEventListener('resize', onWindowResize, false)
    }, [containerRef])

    return (
        <Container
            className={className}
            ref={containerRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseMove={(event) => {
                if (isHovered) {
                    setCursorPosition(clamp((event.clientX - containerLeftOffset) / containerWidth, 0, 1))
                }
            }}
            onClick={() => {
                if (videoApi) {
                    videoApi.scrub(cursorPosition)
                }
            }}
        >
            <FrameStrip source={url} sourceWidth={width} sourceHeight={height} />
            <Playhead progress={isHovered ? cursorPosition : videoProgress}>
                {isHovered && videoApi && <Label>{(videoApi.getDuration() * cursorPosition).toFixed(2)}s</Label>}
            </Playhead>
        </Container>
    )
}

export default Scrubber
