import { omitBy as _omitBy } from 'lodash'

export const MESSAGE = 'MESSAGE'
export const MESSAGE_RESET = 'MESSAGE_RESET'

type DRFErrorResponseObject = {
    code: string
    detail: string
}
export type Status = 'success' | 'error' | 'info' | 'tag_warn' | 'tag_error'
export type SendMessageAction = (
    text: DRFErrorResponseObject | string,
    status?: Status,
    statusKey?: string,
    delay?: number,
    closeCallback?: () => void,
    align?: string
) => MessageAction

type MessageAction = {
    type: string
    text?: string
    status?: Status
    statusKey?: string
    delay?: number
    closeCallback?: () => void
    align?: string
}

export const message: SendMessageAction = (
    text: DRFErrorResponseObject | string,
    status: Status,
    statusKey: string,
    delay: number,
    closeCallback: () => void,
    align: string
): MessageAction => {
    return {
        type: MESSAGE,
        text: typeof text === 'string' ? text : text.detail,
        status,
        statusKey,
        delay,
        closeCallback,
        align,
    }
}
export const messageReset = () => ({ type: MESSAGE_RESET })

export const getMessage = (state: any) => state

const filterAction = (obj: MessageAction) => _omitBy(obj, (val, prop) => prop === 'type' || val === undefined)

const defaults: MessageAction = {
    type: '',
    status: 'success',
    statusKey: '',
    text: '',
    delay: 5000,
}

export default (message = { ...defaults }, action: MessageAction = { type: '' }) => {
    switch (action.type) {
        case MESSAGE:
            return { ...message, ...filterAction(action) }
        case MESSAGE_RESET:
            return defaults
        default:
            return message
    }
}
