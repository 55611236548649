import { useCallback, useEffect, useState } from 'react'

type ModifierKeys = {
    control: boolean
    shift: boolean
}

const useModifierKeys = (): ModifierKeys => {
    const [customKeys, setCustomKeys] = useState<ModifierKeys>({
        control: false,
        shift: false,
    })

    const onKeyboardEvent = useCallback(
        ({ shiftKey: shift, ctrlKey: control }: KeyboardEvent) => {
            setCustomKeys({ control, shift })
        },
        [setCustomKeys]
    )

    useEffect(() => {
        window.addEventListener('keydown', onKeyboardEvent)
        window.addEventListener('keyup', onKeyboardEvent)

        return () => {
            window.removeEventListener('keydown', onKeyboardEvent)
            window.removeEventListener('keyup', onKeyboardEvent)
        }
    }, [])

    return customKeys
}

export default useModifierKeys
