import { useState, useEffect } from 'react'
import VideoPlayerAnalytics from './analytics'

export const useVideoAnalytics = (videoId: number | string, category?: string, gridPosition?: number) => {
    const [events, setEvents] = useState<VideoPlayerAnalytics>()

    useEffect(() => {
        if (videoId) {
            setEvents(new VideoPlayerAnalytics(videoId, category || 'Video Player', gridPosition))
        }
    }, [videoId])

    return events
}
