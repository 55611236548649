import { giphyDarkestGrey, giphyGreen, giphyLightCharcoal, giphyRed, giphyWhite } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { hexToRGBA } from './utils'

export const NewCollectionHeader = styled.div`
    margin: 43px 0;
    margin-top: 43px;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Container = styled.div`
    display: flex;
    margin-left: -10px;
    margin-top: -10px;
    flex-wrap: wrap;
`

export const Separator = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgb(216, 216, 216);
    height: 1px;
    width: 100%;
    opacity: 16%;
`

const initialOpacity = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const animateUp = keyframes`
    from {

    } to {
        transform: translate(-30px, -30px);
    }
`

const animateDown = keyframes`
    from {
        transform: translate(-30px, -30px);
    } to {
        transform: translate(0);
    }
`

type ItemProps = {
    gutter?: boolean
    itemWidth?: number
    selected?: boolean
    darken?: boolean
    isDragging?: boolean
    shouldCombine?: boolean
    shouldAnimate?: boolean
}

export const Item = styled.div<ItemProps>`
    width: ${({ itemWidth }) => `${itemWidth || 153}px`};
    display: flex;
    flex-direction: column;
    position: relative;
    border: ${({ shouldCombine, isDragging, shouldAnimate }) =>
        `${isDragging || shouldAnimate ? 0 : 3}px solid ${shouldCombine ? giphyGreen : giphyDarkestGrey}`};
    border-radius: 6px;
    cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'pointer')};

    user-select: none;
    * {
        user-select: none;
    }

    ${({ shouldAnimate }) =>
        shouldAnimate &&
        css`
            animation: ${animateUp} 1.5s 0.5s 1 forwards, ${animateDown} 1.5s 4s 1 forwards;
        `}

    ${(props) =>
        props.gutter &&
        css`
            margin: 10px 0 0 10px;
        `}

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% - 30px);
        background: rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        transition: opacity 0.25s ease-in-out;
        opacity: ${({ darken }) => (darken ? 1 : 0)};
        animation: ${({ darken }) => !darken && initialOpacity} 0.25s ease-in-out;
    }

    ${(props) =>
        props.selected &&
        css`
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                border: 2px solid #00ff99;
                z-index: 1;
                box-sizing: border-box;
                opacity: 1;
            }
        `}

    &:hover {
        span {
            display: block;
        }
    }
`

export const HoverIcons = styled.div`
    position: absolute;
    top: 50px;
    padding: 10px;
    width: 100%;
    z-index: 1;

    span {
        display: none;
        width: 25px;
        cursor: pointer;
    }

    span:hover {
        color: ${giphyWhite};
    }
`

export const PrivateOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 88px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    span {
        color: ${giphyRed};
        font-size: 30px;
        margin-bottom: 15px;
    }
`

export const ItemGif = styled.img<{ showStickerBg?: boolean }>`
    height: 88px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;

    ${({ showStickerBg = true }) =>
        showStickerBg &&
        css`
            background: url(/static/img/backgrounds/bg-tile-dark.png) left top;
            background-size: 34px; // Sticker BG
        `}
`

export const ItemText = styled.div<{ background: string; opacity?: number | string }>`
    position: relative;
    border-radius: 0px 0px 6px 6px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: ${giphyWhite};
    padding: 5px 10px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${(props) => hexToRGBA(props.background, props.opacity || 1)};

    a {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`

export const ItemQuantityBox = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: 'interface';
    right: 6px;
    top: 6px;
    background: ${giphyWhite};
    color: ${giphyDarkestGrey};
    border-radius: 3px;
    height: 22px;
    width: 30px;
`

export const DashedLineDiv = styled.div<{ shouldAnimate?: boolean }>`
    height: 118px;
    margin-top: ${({ shouldAnimate }) => (shouldAnimate ? `10px` : `1px`)};
    border: 3px dashed ${giphyLightCharcoal};
    border-radius: 6px;
    ${({ shouldAnimate }) =>
        shouldAnimate &&
        css`
            margin-left: -153px;
            width: 147px;
        `}
`

export const AddToSection = styled.div`
    border-top: solid 1px rgb(216, 216, 216, 0.16);
    margin-top: 20px;
`

export const AddToCollectionContainer = styled.div<{ selected?: boolean }>`
    cursor: pointer;
    position: relative;
    height: 150px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-image: url('static/img/collections/floating-bg.gif');
    border-radius: 6px;
    border: 0.5px solid rgba(150, 150, 150, 0.1);

    ${(props) =>
        props.selected &&
        css`
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                border: 2px solid #00ff99;
                z-index: 1;
                box-sizing: border-box;
            }
        `}
`

export const AddToCollectionPlus = styled.div`
    font-size: 21px;
    margin-bottom: 4px;
`

export const AddToCollectionText = styled.div`
    font-size: 15px;
    font-family: 'interface', sans-serif;

    b {
        font-weight: bold;
    }
`

export const ToolTipContainer = styled.div`
    position: absolute;
    left: 60px;
    top: 75px;
    p {
        width: 375px;
        font-size: 17px;
        padding: 15px;
        text-align: center;
    }
`
