enum CreationToolFileType {
    UNVALIDATED_FILE = 'CT_FILE_TYPE_UNVALIDATED_FILE',
    ANIMATED_IMAGE = 'CT_FILE_TYPE_ANIMATED_IMAGE',
    STILL_IMAGE = 'CT_FILE_TYPE_STILL_IMAGE',
    VIDEO = 'CT_FILE_TYPE_VIDEO',
    VIDEO_EMBED = 'CT_FILE_TYPE_VIDEO_EMBED',
}

namespace CreationToolFileType {
    /**
     * Returns the file type's display title.
     */
    export function title(type: CreationToolFileType): string {
        switch (type) {
            case CreationToolFileType.ANIMATED_IMAGE:
                return 'Animated Image'
            case CreationToolFileType.STILL_IMAGE:
                return 'Non-Animated Image'
            case CreationToolFileType.VIDEO:
                return 'Video'
            case CreationToolFileType.VIDEO_EMBED:
                return 'YouTube or Vimeo URL'
            case CreationToolFileType.UNVALIDATED_FILE:
                return 'Unvalidated File'
        }
    }

    /**
     * Returns whether or not the type is a video.
     */
    export function isVideo(type: CreationToolFileType): boolean {
        switch (type) {
            case CreationToolFileType.VIDEO:
            case CreationToolFileType.VIDEO_EMBED:
                return true
            default:
                return false
        }
    }
}

export default CreationToolFileType
