import { IGif } from '@giphy/js-types'
import { CopyButton } from '@giphy/react-giphy-brand'
import { forEach, startCase } from 'lodash'
import { useContext, useMemo, useState } from 'react'
import * as React from 'react'
import UserContext from 'shared/contexts/user'
import InstagramModal from 'shared/components/instagram-share/modal'
import { giphyAnalyticsEvent } from 'shared/util/analytics'
import { getMp4, getShareUrl, getUrl } from 'shared/util/gif-extract'
import { getSocialUrls, socialClick } from 'shared/util/gif-social'
import styled, { css as styledCss } from 'styled-components'
import { RenditionTray } from './rendition-tray'
import { ShareUrlRenditions, ShareUrlRenditionType } from 'utils/src/media/sharing'

// @ts-ignore
import css from './social.css'
import { getContentOfGif, getCreatorOfGif, setGADataLayer, getUrlFileType } from 'analytics'

const MESSAGE_DELAY = 2500

const CopyWrapper = styled.div<{ copied?: boolean }>`
    button {
        width: 100%;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ${({ copied }) =>
        !copied &&
        styledCss`
            button {
                background: linear-gradient(-135deg, #6157ff 0%, #9933ff 100%);
            }
        `}
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const ShareContainer = styled.div`
    transform: scale(1.17);
    width: 245px;
`

const SocialContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`

const SocialButtonStyle = styled.div`
    margin-left: 0;
    border-radius: 2px;
`

const LinkSpan = styled.span`
    margin-top: 3.78px;
    margin-right: 5px;
`

type SocialLink = {
    url: string
    type: string
}

const getSocialLinks = (gif: IGif): SocialLink[] => {
    const urls = getSocialUrls(gif)
    if (urls) {
        const all: SocialLink[] = []
        forEach(urls, (url, type) => {
            if (url && type) {
                const item: SocialLink = { url, type }
                all.push(item)
            }
        })
        return all
    }
    return []
}

const onSocialClick = (e: React.MouseEvent<HTMLElement>, type, url: string, gif: IGif) => {
    const gifId = gif.id
    setGADataLayer({
        event: 'share_success',
        options: {
            social: {
                socialChannel: type,
            },
            content: getContentOfGif(gif),
            creator: getCreatorOfGif(gif),
        },
    })
    giphyAnalyticsEvent({
        shareType: type,
        gifId: gifId,
    })
    socialClick.desktop(url, type, e)
}

type SocialShareProps = {
    gif: IGif
}

const SocialShare = ({ gif }: SocialShareProps) => {
    const { isLoggedIn } = useContext(UserContext)
    const [isInstagramVisible, setIsInstagramVisible] = useState<boolean>(false)
    const [copied, setCopied] = useState<boolean>(false)
    const [currentRendition, setCurrentRendition] = useState<ShareUrlRenditionType>(ShareUrlRenditions.Social)
    const { id } = gif
    const all = getSocialLinks(gif)

    const gifLink = useMemo(() => {
        if (currentRendition === ShareUrlRenditions.MP4) {
            return getShareUrl(getMp4(gif).split('?')[0])
        } else {
            return getShareUrl(getUrl(gif, currentRendition).split('?')[0])
        }
    }, [currentRendition, id])

    function getCopyCTA() {
        return (
            <>
                <LinkSpan className="ss ss-link" />
                Copy GIF Link
            </>
        )
    }

    const closeInstagram = () => {
        setIsInstagramVisible(false)
    }

    const showInstagram = () => {
        setIsInstagramVisible(true)
    }

    if (!id) {
        return <></>
    }

    return (
        <Container>
            <ShareContainer>
                <SocialContainer>
                    {all.map(({ url, type }) => (
                        <SocialButtonStyle
                            key={url}
                            className={css[type]}
                            title={`Share to ${startCase(type)}`}
                            onClick={(e) => onSocialClick(e, type, url, gif)}
                        />
                    ))}
                    <SocialButtonStyle
                        className={`${css.instagram} rad-modal-trigger`}
                        title="Share to Instagram"
                        data-title="Share on Instagram"
                        data-description="We're going to create a GIF that you can post to Instagram. Where should we send it?"
                        data-content="#instagram-modal"
                        onClick={() => {
                            setGADataLayer({
                                event: 'share_success',
                                options: {
                                    social: {
                                        socialChannel: 'instagram',
                                    },
                                    content: getContentOfGif(gif, { rendition: ShareUrlRenditions.Original }), // TODO: what rendition gets emailed to the user for this?  does it matter since we're deprecating this feature?
                                    creator: getCreatorOfGif(gif),
                                },
                            })
                            if (isLoggedIn) {
                                showInstagram()
                            } else {
                                location.href = `/login/?next=${encodeURIComponent(
                                    location.pathname
                                )}&ref=auth_required`
                            }
                        }}
                    />
                    {isInstagramVisible && (
                        <InstagramModal {...{ gifId: id, elementId: 'content', onClose: () => closeInstagram() }} />
                    )}
                </SocialContainer>
                <CopyWrapper
                    onClick={() => {
                        setCopied(true)
                        setTimeout(() => {
                            setCopied(false)
                        }, MESSAGE_DELAY)
                        setGADataLayer({
                            event: 'share_success',
                            options: {
                                social: {
                                    socialChannel: 'copy',
                                },
                                content: getContentOfGif(gif, {
                                    rendition: currentRendition,
                                    format: getUrlFileType(gifLink),
                                }),
                                creator: getCreatorOfGif(gif),
                            },
                        })
                    }}
                    copied={copied}
                >
                    <CopyButton primary url={gifLink} CTA={getCopyCTA()} />
                </CopyWrapper>
            </ShareContainer>
            <div>
                <RenditionTray
                    currentRendition={currentRendition}
                    setRenditionType={(rendition) => setCurrentRendition(rendition)}
                    showsMp4
                    gif={gif}
                />
            </div>
        </Container>
    )
}

export default SocialShare
