import { setGADataLayer } from 'analytics'

export function trackGA4NavigationEvent(label, path, parent) {
    const data = {
        event: 'menu_nav',
        options: {
            eventInfo: {
                navElement: parent,
                navSubelement: label,
                link: new URL(path, location.origin).href,
            },
        },
    }

    setGADataLayer(data)
}
