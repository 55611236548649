import { giphyLightCharcoal, giphyWhite } from '@giphy/colors'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'shared/components/buttons'
import Input from 'shared/components/form/ui/input'
import { SendMessageAction, message } from 'shared/redux/message'
import { addProtocol, getFavicon } from 'shared/util/url'
import styled from 'styled-components'
import DescriptionInput from './video-description/input'

const StyledInput = styled(Input)<{ height: number }>`
    height: ${(props) => props.height}px;
    margin: 0;
    font-weight: normal;

    div:last-child {
        font-size: 15px;
    }
`
export const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
`

type SaveButtonProps = {
    height?: number
}
const SaveButton = styled(Button)<SaveButtonProps>`
    height: ${(props) => props.height}px;
    flex-shrink: 0;
`

const HelperText = styled.span`
    float: right;
    font-size: 12px;
    color: ${giphyLightCharcoal};

    a {
        color: ${giphyLightCharcoal};
    }
`
const FaviconContainer = styled.div`
    background-color: ${giphyWhite};
    width: 36px;
    height: 36px;
    text-align: center;
    margin-right: -5px;
`

const Favicon = styled.img`
    padding: 8px;
`

const StyledDescriptionInput = styled(DescriptionInput)<{ height: number }>`
    flex-grow: 1;
    height: ${(props) => props.height}px;

    textarea {
        resize: none;
        margin-bottom: 0;
        padding: 5px;

        div:last-child {
            font-size: 15px;
        }
    }
`

type ReduxProps = {
    message: SendMessageAction
}
type Props = {
    className?: string
    value: string
    onChange?: Function
    helperText?: string
    title: string
    maxLength?: number
    isURL?: boolean
    placeholder?: string
    avatar?: string
    height?: number
    multiline?: boolean
    customError?: boolean
    includeTitle?: boolean
}

const TextFieldAsync = ({
    value,
    message,
    className,
    onChange = () => {},
    title,
    helperText,
    maxLength,
    isURL = false,
    placeholder = '',
    height = 36,
    multiline = false,
    customError = false,
    includeTitle = true,
}: Props & ReduxProps) => {
    const initialValue = value
    const [text, setText] = useState(initialValue)
    const charCount = text ? text.length : 0
    const isTooLong = maxLength ? charCount > maxLength : false
    const favicon = getFavicon(text)

    useEffect(() => {
        setText(initialValue)
    }, [initialValue, setText])

    const onSaveClick = async (text: string) => {
        setText(text)
        try {
            await onChange(text)
        } catch (e) {
            message(customError ? e : `Failed to update ${title}.`, 'error')
        }
    }

    return (
        <div className={className}>
            {includeTitle && <span>{title}</span>}
            <HelperText>{helperText}</HelperText>
            <Wrap>
                {isURL && text && favicon && (
                    <FaviconContainer>
                        <Favicon src={favicon} />
                    </FaviconContainer>
                )}
                {multiline ? (
                    <StyledDescriptionInput value={text} onChange={(value) => setText(value)} height={height} />
                ) : (
                    <StyledInput
                        height={height}
                        errorTooltip={true}
                        errorMessage={isTooLong ? `(${charCount}/${maxLength}) characters` : ''}
                        inputProps={{
                            value: text ? (isURL ? addProtocol(text) : text) : '', // TODO: addProtocol sucks
                            onChange: (evt) => {
                                setText(evt.target.value)
                            },
                            type: 'text',
                            placeholder: placeholder,
                        }}
                    />
                )}
                <SaveButton
                    height={height}
                    hidden={text === initialValue}
                    disabled={isTooLong}
                    onClick={() => onSaveClick(text)}
                >
                    Save
                </SaveButton>
            </Wrap>
        </div>
    )
}

export default connect(undefined, { message })(TextFieldAsync)
