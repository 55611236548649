exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".xNw0DX6Og72ECNwblt8DS {\n    display: block;\n}\n\n._3WQopaexcFw1EVT8c2Dj10 {\n    margin: 0;\n    position: relative;\n    z-index: 1;\n    overflow: hidden;\n}\n._1hDypzVaR0tOjgDssitV6b {\n    height: 2000px;\n    left: -413px;\n    position: absolute;\n    top: -427px;\n    width: 463px;\n    z-index: -1;\n    transform: rotate(33deg);\n}\n._1AsrNVVVP5Jyr7RkmPMCpX {\n    height: 2000px;\n    left: 413px;\n    position: absolute;\n    top: -88px;\n    width: 463px;\n    z-index: -1;\n    transform: rotate(33deg);\n}\n._30TQqdyCYH9fMy9FwuzU8x {\n    height: 2000px;\n    left: -135px;\n    position: absolute;\n    top: -363px;\n    width: 463px;\n    z-index: -1;\n    transform: rotate(-40deg);\n}\n._2RbPGYxBesQug2P8CpSljS {\n    padding: 40px 20px 20px;\n}\n.MDCvt_1iGOTGWNVoq25EL {\n    background-color: #000;\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    border-radius: 8px;\n    display: block;\n    height: 65px;\n    margin: 0 auto 20px;\n    width: 65px;\n}\n._2rRr0-vWs7ubYJhfhOtv0S {\n    font-family: 'nexablack', sans-serif;\n    font-size: 25px;\n    line-height: 1.2;\n    margin: 0 auto 20px;\n    max-width: 300px;\n    -webkit-font-smoothing: antialiased;\n}\n._2rRr0-vWs7ubYJhfhOtv0S h2 {\n        color: rgba(255, 255, 255, 0.8);\n        font-size: 18px;\n    }\n.i5Q-OfTcohChMiYJVXAKb {\n    padding-top: 10px;\n}\n._3Pf3EIFwClayk38bF6mmBE {\n    font-size: 9px;\n    padding-left: 2px;\n}\na {\n    color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"appsPage": "xNw0DX6Og72ECNwblt8DS",
	"app": "_3WQopaexcFw1EVT8c2Dj10",
	"appBackgroundLeft": "_1hDypzVaR0tOjgDssitV6b",
	"appBackgroundRight": "_1AsrNVVVP5Jyr7RkmPMCpX",
	"appBackgroundLeftOdd": "_30TQqdyCYH9fMy9FwuzU8x",
	"appDescription": "_2RbPGYxBesQug2P8CpSljS",
	"appIcon": "MDCvt_1iGOTGWNVoq25EL",
	"appTitle": "_2rRr0-vWs7ubYJhfhOtv0S",
	"learnMore": "i5Q-OfTcohChMiYJVXAKb",
	"carrot": "_3Pf3EIFwClayk38bF6mmBE ss-icon ss-navigateright"
};