export const isVowel = (letter) => {
    switch (letter.toUpperCase()) {
        case 'A':
        case 'E':
        case 'I':
        case 'O':
        case 'U':
            return true
        default:
            return false
    }
}

export const removeWords = (fullString = '', words: string[] = []) => {
    const word = endsWithWords(fullString, words)
    const trimString = fullString.trim()

    return word ? trimString.slice(0, trimString.length - word.length) : fullString
}

export const endsWithWords = (fullString = '', words: string[] = []) => {
    if (!words.length) return false
    const regExp = new RegExp(`(${words.join('|')})$`, `gi`)
    const matches = fullString.trim().match(regExp)
    return matches ? matches[matches.length - 1] : false
}

const IMAGE_TYPES = ['gif', 'gifs', 'sticker', 'stickers', 'video', 'videos']
export const getAllTheCopy = (displayName: string, contentType: 'gif' | 'video' | 'sticker'): string => {
    // displayName may have the word GIFs in it
    const suffix = endsWithWords(displayName, IMAGE_TYPES) ? '' : getContentTypeSuffix(contentType)
    return `All the ${displayName} ${suffix}`
}
export const getContentTypeSuffix = (contentType: 'gif' | 'video' | 'sticker') => {
    switch (contentType) {
        case 'sticker':
            return 'Stickers'
        case 'video':
            return 'Videos'
        default:
            return 'GIFs'
    }
}

export default {
    isVowel,
    endsWithWords,
    removeWords,
}
