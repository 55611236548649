import { giphyAqua, giphyDarkGrey } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'

const fadeOutUp = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
`

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
`

export const Pill = styled.a<{ isHidden?: boolean }>`
    display: inline-block;
    padding: 0 16px;
    background-color: ${giphyDarkGrey};
    border-radius: 20px;
    margin: 0 8px 8px 0;
    line-height: 38px;
    height: 40px;
    overflow: visible;
    animation-name: ${fadeInUp};
    animation-duration: 1s;
    &:active {
        opacity: 0.8;
    }
    &:hover {
        color: ${giphyAqua};
    }
    ${({ isHidden }) =>
        isHidden &&
        css`
            display: none;
            animation-duration: 1s;
            animation-name: ${fadeOutUp};
        `}
`

export const TagText = styled.h3`
    margin: 0px;
    font-size: 15px;
    text-transform: lowercase;
    font-style: italic;
    letter-spacing: 0.04em;
    -webkit-font-smoothing: antialiased;
`
