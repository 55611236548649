import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import { useDebounce, useLocalStorage } from 'react-use'
import MessageContext from 'shared/contexts/message'
import UserContext from 'shared/contexts/user'
import { toggleFavorite } from 'shared/api/favorites'
import styled, { css, keyframes } from 'styled-components'

const favoriteHover = keyframes`
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`

const favorite = keyframes`
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200px 0;
    }
`

const unFavorite = keyframes`
    0% {
        background-position: 0 -40px;
    }
    100% {
        background-position: -200px -40px;
    }
`

const FavoriteIcon = styled.div<{ active: boolean; unFavorite: boolean }>`
    width: 36px;
    height: 36px;
    background: no-repeat url('/static/img/animations/favorite.png') 0 0;
    background-color: transparent;
    background-size: 240px 80px;
    overflow: hidden;
    position: relative;

    &:before,
    &:after {
        background: inherit;
        background-size: inherit;
        content: '';
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    div[class*='_favorite_']:hover &:before,
    &:hover:before {
        display: block;
        animation: ${favoriteHover} 1s ease-out 0s infinite;
    }

    div[class*='_favorite_']:hover &:after,
    &:hover:after {
        display: block;
        animation: ${favoriteHover} 1s ease-out 0.3s infinite forwards;
    }

    &[data-animated='false'] {
        animation: none;
    }

    ${(props) =>
        props.active &&
        css`
            background-position: 0 -40px;
            animation: ${favorite} 0.3s steps(5);
        `}

    ${(props) =>
        props.unFavorite &&
        css`
            animation: ${unFavorite} 0.5s steps(5);
        `}
`

const FavoriteContainer = styled.div`
    float: right;
    height: 34px;
    overflow: hidden;
    position: relative;
    width: 34px;
    margin-left: -1px;
    margin-right: 4px;
    margin-top: -2px;
    &:hover > ${FavoriteIcon}:before {
        display: block;
        animation: ${favoriteHover} 1s ease-out 0s infinite;
    }
    &:hover > ${FavoriteIcon}:after {
        display: block;
        animation: ${favoriteHover} 1s ease-out 0.3s infinite;
    }
`

type Props = {
    id: string | number
    url: string
    isFavorite: boolean
    showLabel?: boolean
    onClick?: Function
    scale?: number
    className?: string
}

const ANIMATION_TIME = 500

const Favorite = ({ id, url, showLabel = false, scale, onClick, isFavorite, className }: Props) => {
    const [hasSeenLocalFavMessage, setHasSeenLocalFavMessage] = useLocalStorage('has-seen-local-fav-message', false)
    const transform = `scale(${scale || 1})`
    const style = {
        WebkitTransform: transform,
        MozTransform: transform,
        Transform: transform,
    }

    const {
        user,
        isLoggedIn,
        removeFavorite,
        addFavorite,
        localFavorites = [],
        favorites = [],
    } = useContext(UserContext)

    // check our favorites from the user
    isFavorite = isFavorite || [...favorites, ...localFavorites].indexOf(id) !== -1

    const { sendMessage } = useContext(MessageContext)
    const showAnimation = useRef(false)
    const [isActive, setIsActive] = useState(isFavorite)
    useEffect(() => {
        if (!showAnimation.current) {
            setIsActive(isFavorite)
        }
    }, [isFavorite])

    useDebounce(
        () => {
            if (showAnimation.current) {
                // set this back to false after the debounce and the animation
                showAnimation.current = false
            }
        },
        ANIMATION_TIME,
        [isActive]
    )

    useEffect(() => {
        setIsActive(isFavorite)
    }, [id])

    const doTheToggle = (e: SyntheticEvent<HTMLElement, Event>) => {
        e.preventDefault()
        e.stopPropagation()

        if (isLoggedIn) {
            toggleFavorite(id, user.userToken)
            onClick?.({ id, url, is_favorite: isActive })
        } else {
            onClick?.({ id, url, is_favorite: isActive })

            if (isActive) {
                removeFavorite(id)
            } else {
                addFavorite(id)
            }
            if (!hasSeenLocalFavMessage) {
                setHasSeenLocalFavMessage(true)
                sendMessage(`Favorite saved! Access your favorites from the user menu.`, 'success')
            }
        }

        // flag this so we show the animation
        if (isActive) {
            showAnimation.current = true
        }
        setIsActive(!isActive)
    }

    return (
        <FavoriteContainer style={style} onClick={doTheToggle} className={className}>
            <FavoriteIcon active={isActive} unFavorite={showAnimation.current} />
            {showLabel && <span>Favorite</span>}
        </FavoriteContainer>
    )
}

export default Favorite
