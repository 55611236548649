import { useContext } from 'react'
import styled from 'styled-components'
import VideoContext from '../video-context'
import { FOOTER } from './z-index'

const Container = styled.div<{ isSmall: boolean; isVisible: boolean; dimSize: number }>`
    display: flex;
    padding: ${(props) => (props.isSmall ? 6 : 10)}px;
    pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
    position: relative;
    align-items: center;
    transform: translateY(${(props) => (props.isVisible ? 0 : 100)}%);
    transition: transform 0.2s ease-out;
    z-index: ${FOOTER};

    &:before {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(18, 18, 18, 0.6));
        bottom: 0;
        content: '';
        height: ${(props) => props.dimSize}px;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    svg {
        display: block;
    }
`
const Phil = styled.div`
    flex: 1;
`

const Footer = ({ dimSize = 0, isVisible = false, onMouseOver }) => {
    const { isSmall } = useContext(VideoContext)
    return (
        <Container dimSize={dimSize} isSmall={isSmall} isVisible={isVisible} onMouseOver={onMouseOver}>
            <Phil />
        </Container>
    )
}

export default Footer
