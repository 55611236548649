import css from './logo.css'
export default () => (
    <svg className={css.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 35" itemProp="logo">
        <g fillRule="evenodd" clipRule="evenodd">
            <path className={css.green} d="M0 3h4v29H0z" />
            <path className={css.purple} d="M24 11h4v21h-4z" />
            <path className={css.blue} d="M0 31h28v4H0z" />
            <path className={css.yellow} d="M0 0h16v4H0z" />
            <path className={css.red} d="M24 8V4h-4V0h-4v12h12V8" />
            <path className={css.shadow} d="M24 16v-4h4M16 0v4h-4" />
        </g>
    </svg>
)
