import { giphyWhite } from '@giphy/colors'
import { GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { GifOverlayProps, Grid as SDKGrid } from '@giphy/react-components'
import { ElementType, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useKeyPress } from 'react-use'
import { PublicAPIError } from 'shared/api/errors'
import BulkEditor from 'shared/components/bulk-editor'
import SectionHeader from 'shared/components/section-header'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import GAEventContext from 'shared/contexts/ga-events'
import GifContext from 'shared/contexts/gif'
import UserContext from 'shared/contexts/user'
import { useBulkEditGifClick } from 'shared/hooks/use-bulk-edit'
import { useSameObjectRef } from 'shared/hooks/use-same-ref'
import { grid12 } from 'shared/util/grid'
import isMobile from 'shared/util/is-mobile'
import styled from 'styled-components'
import SharedGridOverlay, { GridOverlayContext } from './grid-overlay'

const Container = styled.div`
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
`

const GridHeader = styled.div`
    h2 {
        color: ${giphyWhite};
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.3px;
        font-family: InterFace;
        padding-bottom: 6px;
    }
`

type Props = {
    className?: string
    fetchGifs: (offset: number) => Promise<GifsResult>
    width?: number
    gridTitle?: string
    noRedux?: boolean
    standaloneSelectionMode?: boolean // for the gif add grid, need to think of a better name
    onSelectionUpdate?: (gifs: GifId[]) => void
    onGifsSelectionUpdate?: (gifs: IGif[]) => void
    columns?: number
    onGifGAClick?: (gif: Gif, index?: number) => void
    layoutType?: 'GRID' | 'MIXED'
    // this is just for GA but didn't want to name it with GA
    onAttributionClick?: (gif: Gif) => void
    gutter?: number
    Overlay?: ElementType<GifOverlayProps>
    SelectionModeOverlay?: ElementType<GifOverlayProps>
    initialGifs?: IGif[]
    mixedGrid?: boolean
}
type GifId = string | number
type Gif = object

const defaultGutter = isMobile() ? 6 : 16

const Grid = ({
    fetchGifs,
    width = grid12,
    gridTitle = '',
    gutter = defaultGutter,
    columns = 3,
    layoutType = 'GRID',
    noRedux,
    standaloneSelectionMode,
    onSelectionUpdate,
    onGifsSelectionUpdate,
    className,
    onGifGAClick,
    onAttributionClick,
    Overlay,
    SelectionModeOverlay, // added for CurationStation to not break other instances
    initialGifs,
    mixedGrid = true,
}: Props) => {
    const { receivedGifs } = useContext(GifContext)
    const {
        isBulkEditMode,
        setBulkEditGifIds: setSelectedGifs,
        bulkEditGifIds: selectedGifs,
    } = useContext(BulkEditModeContext)
    const { user } = useContext(UserContext)
    const iSelectionMode = !!(isBulkEditMode || standaloneSelectionMode)
    const [gifs, setGifs] = useState<GifId[]>([])
    const [gifsObject, setGifsObject] = useState<IGif[]>(initialGifs || [])
    // TODO this is required because fetchWrapped,
    // even though it's recreated every render,
    // never updates in the SDK Grid maybe because its fetchGifs is defined as an instance method
    // the ref object let's the out of date function reference the current value of the gifs
    const gifsRef = useRef<GifId[]>([])
    gifsRef.current = gifs
    const GridOverlay = useCallback(
        (props: GifOverlayProps) => <SharedGridOverlay {...props} mixedGrid={mixedGrid} />,
        []
    )
    const { onPagination } = useContext(GAEventContext)
    const onGifClick = useBulkEditGifClick({
        gifs,
        iSelectionMode,
        standaloneSelectionMode,
        noRedux,
        onGifGAClick,
    })
    const onError = useCallback((e: Error) => {
        if (e instanceof PublicAPIError) {
            // TODO Add a better handler here.
        }
        console.error(`Grid fetch error: ${e?.message}`)
    }, [])
    const [isEscPressed] = useKeyPress((event: KeyboardEvent) => event.keyCode === 27)
    const page = useRef(1)
    let columnOffsets
    const fetchWrapped = async (offset: number) => {
        const result = await fetchGifs(offset)
        const { data = [] } = result
        setGifsObject(data)
        setGifs([...gifsRef.current, ...data.map((gif) => gif.id)])
        onPagination(page.current++)

        if (!noRedux) {
            receivedGifs({ gifs: data })
        }
        return result
    }
    useEffect(() => {
        setSelectedGifs([])
    }, [isEscPressed, setSelectedGifs])

    useEffect(() => {
        return () => setSelectedGifs([])
    }, [setSelectedGifs])

    useEffect(() => {
        onSelectionUpdate?.(selectedGifs)
        if (onGifsSelectionUpdate) {
            const filteredGifs = gifsObject.filter((gif) => selectedGifs.includes(gif.id))
            onGifsSelectionUpdate(filteredGifs)
        }
    }, [selectedGifs, onSelectionUpdate])

    const gridOverlayValue = useSameObjectRef({
        onGifClick,
        onAttributionClick,
        selectedGifs,
        iSelectionMode,
        Overlay: iSelectionMode ? SelectionModeOverlay : Overlay,
    })
    return (
        <>
            <Container>
                <GridHeader>{gridTitle && <SectionHeader label={gridTitle} />}</GridHeader>
                <GridOverlayContext.Provider value={gridOverlayValue}>
                    <SDKGrid
                        initialGifs={initialGifs}
                        user={user}
                        onGifsFetchError={onError}
                        width={width}
                        gutter={gutter}
                        onGifClick={onGifClick}
                        columns={columns}
                        fetchGifs={fetchWrapped}
                        overlay={GridOverlay}
                        layoutType={layoutType}
                        className={className}
                        columnOffsets={columnOffsets}
                        hideAttribution
                    />
                </GridOverlayContext.Provider>
            </Container>
            {!standaloneSelectionMode && <BulkEditor />}
        </>
    )
}

export default Grid
