import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { giphyBlack, giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { ControlSize, Gradient, Range } from 'types'
import { useNodeWidth } from 'utils'
import { Background, Children, Container, Slider } from './style'

export type RangeSliderDndMode = 'all' | 'handles-only'

export type RangeSliderProps = {
    backgroundColor?: string
    children?: ReactNode
    className?: string
    color?: string
    defaultRange?: Range
    disabled?: boolean
    dndId?: string
    dndMode?: RangeSliderDndMode
    gradient?: Gradient
    handleColor?: string
    range?: Range
    showSnapSegments?: boolean
    size?: ControlSize
    snapTo?: number[]
    style?: CSSProperties
    textColor?: string
    onChange?: (range: Range) => void
    onUpdate?: (range: Range) => void
}

export const RangeSliderWithoutContext = ({
    backgroundColor = giphyDarkGrey,
    children,
    className,
    color,
    defaultRange = { start: 0, end: 1 },
    dndId,
    dndMode = 'all',
    disabled = false,
    gradient,
    handleColor = giphyBlack,
    range: rangeProp,
    showSnapSegments = false,
    size = 'small',
    snapTo,
    style,
    textColor,
    onChange,
    onUpdate,
}: RangeSliderProps) => {
    const initialValueRef = useRef<Range | undefined>(rangeProp || defaultRange)
    const containerRef = useRef<HTMLDivElement>(null)
    const [range, setRange] = useState<Range>(rangeProp || defaultRange)
    const width = useNodeWidth(containerRef)

    useEffect(() => {
        if (rangeProp && (rangeProp.end !== range.end || rangeProp.start !== range.start)) {
            setRange(rangeProp)
        }
    }, [rangeProp?.end, rangeProp?.start])

    useEffect(() => {
        if (disabled) return

        if (range.end !== initialValueRef.current?.end || range.start !== initialValueRef.current.start) {
            initialValueRef.current = undefined
            onChange?.(range)
        }
    }, [range.end, range.start])

    return (
        <Container
            $background={backgroundColor}
            $color={color}
            $size={size}
            $textColor={textColor}
            className={className}
            ref={containerRef}
            style={style}
        >
            <Background color={color} gradient={gradient} snapTo={showSnapSegments ? snapTo : undefined} />
            <Slider
                $background={backgroundColor}
                dndId={dndId}
                handleColor={handleColor}
                handlesOnly={dndMode === 'handles-only'}
                maxWidth={width}
                range={range}
                snapTo={snapTo}
                onChange={setRange}
                onUpdate={onUpdate}
            >
                {children && <Children>{children}</Children>}
            </Slider>
        </Container>
    )
}

const RangeSlider = (props: RangeSliderProps) => {
    const pointerSensor = useSensor(PointerSensor)
    const sensors = useSensors(pointerSensor)

    return (
        <DndContext sensors={sensors}>
            <RangeSliderWithoutContext {...props} />
        </DndContext>
    )
}

export default RangeSlider
