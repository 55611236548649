import { PureComponent, Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SelectableButton from './selectable-button'

const Container = styled.div`
    display: flex;
`
class ButtonGroup extends PureComponent {
    static Button = SelectableButton
    static propTypes = {
        buttonProps: PropTypes.object,
        defaultSelectedIndex: PropTypes.number,
    }

    constructor(props) {
        super(props)
        const { defaultSelectedIndex = -1 } = this.props
        this.state = {
            selectedIndex: defaultSelectedIndex,
            hoveredIndex: -1,
        }
    }

    render() {
        const { children, className, buttonProps } = this.props
        const { selectedIndex, hoveredIndex } = this.state
        return (
            <Container className={className} onMouseLeave={() => this.setState({ hoveredIndex: -1 })}>
                {Children.toArray(children).map((child, index) =>
                    cloneElement(child, {
                        onClick: () => {
                            const { onClick = () => {} } = child.props
                            this.setState({
                                selectedIndex: index,
                            })
                            onClick(index)
                        },
                        onMouseOver: () => this.setState({ hoveredIndex: index }),
                        selected: index === selectedIndex,
                        isOtherHovered: hoveredIndex !== -1 && hoveredIndex !== index,
                        index,
                        ...buttonProps,
                    })
                )}
            </Container>
        )
    }
}

export default ButtonGroup
