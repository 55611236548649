import { giphyLightGrey } from '@giphy/colors'
import { ReactNode } from 'react'
import { Buttons, CloseButton, Container, Description, Header, Sticker, Title } from './style'

type Props = {
    children?: ReactNode
    className?: string
    closeable?: boolean
    description?: string
    stickerUrl?: string
    title: string
    onClose?: () => void
}

const Alert = ({ children, className, closeable = false, description, stickerUrl, title, onClose }: Props) => (
    <Container $hasSticker={!!stickerUrl} className={className}>
        {stickerUrl && <Sticker src={stickerUrl} alt="" />}
        {closeable && (
            <CloseButton
                color="transparent"
                icon="close"
                iconColor={giphyLightGrey}
                iconScale={0.9}
                size="medium"
                onClick={() => onClose?.()}
            />
        )}
        <Header>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
        </Header>
        {children && <Buttons>{children}</Buttons>}
    </Container>
)

export default Alert
