import * as colors from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import * as React from 'react'
import { SetStateAction } from 'react'
import { Title } from 'shared/components/text-elements'
import CornerCutout from 'ui/src/components/corner-cutout'
import styled, { keyframes } from 'styled-components'
import Download from './download'
import Embed from './embed'
import SocialShare from './social'

const Overlays = {
    share: SocialShare,
    embed: Embed,
    download: Download,
}

const OverlayIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const Container = styled.div<{ height: number | undefined }>`
    animation: ${OverlayIn} 0.2s ease-out;
    background-color: rgba(0, 0, 0, 0.8);
    height: ${({ height }) => `${height}px`};
    width: 100%;
    left: 0px;
    top: 0px;
    overflow: hidden;
    box-sizing: content-box;
    position: absolute;
    margin-top: 26px;

    &:after {
        clear: both;
        content: '';
        display: block;
    }
`

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin: 0 81px 0 24px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
`

const Header = styled(Title)`
    font-size: 16px;
    text-transform: capitalize;
`

const CloseButton = styled.button`
    background: none;
    font-size: 16px;
    height: 33px;
    padding: 0px;
`

const OverlayContainer = styled.div<{ height: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ height }) => `${height}px`};
`

const StyledCutout = styled(CornerCutout)`
    background-color: ${colors.giphyBlack};
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
`

type Props = {
    gif: IGif
    height: number
    overlayType: string
    closeOverlay: React.Dispatch<SetStateAction<string | null>>
}

const GifDetailOverlay = ({ gif, height, overlayType, closeOverlay }: Props) => {
    const headerText = `${overlayType} ${gif.type === 'gif' ? `GIF` : `Sticker`}`
    const overlayHeight = height - 50
    const Overlay = Overlays[overlayType]
    return (
        <Container height={height}>
            <StyledCutout isVisible />
            <HeaderDiv>
                <Header>{headerText}</Header>
                <CloseButton
                    className={'ss-delete'}
                    onClick={() => {
                        if (overlayType === 'share') {
                            setGADataLayer({
                                event: 'share_close',
                                options: {
                                    content: getContentOfGif(gif),
                                    creator: getCreatorOfGif(gif),
                                },
                            })
                        }

                        if (overlayType === 'embed') {
                            setGADataLayer({
                                event: 'embed_close',
                                options: {
                                    content: getContentOfGif(gif),
                                    creator: getCreatorOfGif(gif),
                                },
                            })
                        }

                        closeOverlay(null)
                    }}
                />
            </HeaderDiv>
            <OverlayContainer height={overlayHeight}>
                <Overlay gif={gif} />
            </OverlayContainer>
        </Container>
    )
}

export default GifDetailOverlay
