import { IGif } from '@giphy/js-types'
import { getGifWidth } from '@giphy/js-util'
import { Gif, PingbackContext } from '@giphy/react-components'
import { isEmpty } from 'lodash'
import { SyntheticEvent, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Media from 'react-media'
import Test from 'shared/components/test'
import UserContext from 'shared/contexts/user'
import { mobile } from 'shared/util/media-query'
import styled, { ThemeProvider } from 'styled-components'
import {
    CY_ARTIST_FEED__CAROUSEL,
    CY_ARTIST_FEED__GIF_CLASS,
    CY_ARTIST_FEED__GIF_DATA_ID,
    CY_TRENDING_FEED__CAROUSEL,
    CY_TRENDING_FEED__GIF_CLASS,
    CY_TRENDING_FEED__GIF_DATA_ID,
} from 'ui'
import MinimalChannelPreview from './minimal-channel-preview'
import { EmptyItem, Item, List, ListsScrollContainer, ListWrapper, SeeAll } from './sc'
import TrendingOverlay from './trending-overlay'

const ChannelOverlay = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    cursor: default;
`

type Props = {
    gifs: IGif[]
    height: number
    showTags?: boolean
    onClick?: (e: SyntheticEvent<HTMLElement, Event>, gif: IGif) => void
    onScroll?: Function
    saveRef?: (ref: HTMLElement) => void
    marginBottom?: number
    className?: string
    isInDropdown?: boolean
    isChannelCarousel?: boolean
    noLink?: boolean
    minItemWidth?: null | number
    numberOfItemsToRender?: number
    containerTestID?: string
}

const CY_TEST_IDS = {
    [CY_TRENDING_FEED__CAROUSEL]: {
        className: CY_TRENDING_FEED__GIF_CLASS,
        itemID: CY_TRENDING_FEED__GIF_DATA_ID,
    },
    [CY_ARTIST_FEED__CAROUSEL]: {
        className: CY_ARTIST_FEED__GIF_CLASS,
        itemID: CY_ARTIST_FEED__GIF_DATA_ID,
    },
}

const calculateItemWidth = (gif: IGif, minItemWidth: null | number, height: number) => {
    // If there's a min item width stated, use it if the calculated width is less than the min width.
    return minItemWidth && getGifWidth(gif, height) < minItemWidth ? minItemWidth : getGifWidth(gif, height)
}

const getRows = (gifs: IGif[]): [IGif[], IGif[]] => {
    const evenGifs: IGif[] = []
    const oddGifs: IGif[] = []
    if (gifs && gifs.length) {
        gifs.forEach((gif: IGif, index: number) => {
            if (index % 2 === 0) {
                evenGifs.push(gif)
            } else {
                oddGifs.push(gif)
            }
        })
    }
    return [evenGifs, oddGifs]
}

const ScrollCarousel = ({
    gifs,
    height = 100,
    marginBottom = 0,
    className,
    isInDropdown = false,
    onScroll,
    saveRef,
    onClick,
    noLink,
    isChannelCarousel = false,
    minItemWidth = null,
    numberOfItemsToRender,
    containerTestID,
}: Props) => {
    const carouselRef = useRef(null)
    const { user } = useContext(UserContext)
    const [rows, setRows] = useState<[IGif[], IGif[]]>(getRows(gifs))
    useLayoutEffect(() => {
        // when gifs change, set the rows.
        setRows(getRows(gifs))
    }, [gifs])

    useEffect(() => {
        const ref = carouselRef.current
        if (ref !== null) {
            saveRef?.(ref)
        }
    }, [carouselRef])

    const onGifClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
        const mouseEvent = e as unknown as MouseEvent
        if (mouseEvent.metaKey) {
            e.preventDefault()
            e.stopPropagation()
            window.open(gif.url, '_blank')
        } else {
            onClick?.(e, gif)
        }
    }

    let shorterRowIndex: number | undefined

    const [row1, row2] = rows
    if (row1.length) {
        const row1Width = row1.reduce((acc, cur) => acc + getGifWidth(cur, height), 0)
        const row2Width = row2.reduce((acc, cur) => acc + getGifWidth(cur, height), 0)
        shorterRowIndex = row1Width > row2Width ? 1 : 0
    }

    const getItem = (gif: IGif) => {
        if (gif) {
            // the index of the gif in the original fetch
            const index = gifs.indexOf(gif)
            // custom pingback attributes
            const attributes = {
                position: String(index),
                layout_type: isInDropdown ? 'CAROUSEL_SEARCH' : 'CAROUSEL',
            }
            // If there's a number of items to render specified, only render that number of items.
            if ((numberOfItemsToRender && index < numberOfItemsToRender) || numberOfItemsToRender === undefined) {
                return (
                    <Test id={CY_TEST_IDS[containerTestID || '']?.itemID(gif.id) || ''} key={index}>
                        <Item key={index} cover={isChannelCarousel}>
                            <PingbackContext.Provider value={{ attributes }}>
                                <Gif
                                    noLink={noLink}
                                    user={user}
                                    key={gif.id}
                                    gif={gif}
                                    hideAttribution
                                    width={isChannelCarousel ? 343 : calculateItemWidth(gif, minItemWidth, height)}
                                    height={height}
                                    onGifClick={onGifClick}
                                    overlay={TrendingOverlay}
                                    className={CY_TEST_IDS[containerTestID || '']?.className || ''}
                                />
                                {isChannelCarousel && (
                                    <ChannelOverlay onClick={(e) => onGifClick(gif, e)}>
                                        <MinimalChannelPreview user={gif.user} />
                                    </ChannelOverlay>
                                )}
                            </PingbackContext.Provider>
                        </Item>
                    </Test>
                )
                // Otherwise, render an empty item offscreen
            } else {
                return (
                    <EmptyItem
                        key={index}
                        width={isChannelCarousel ? 343 : calculateItemWidth(gif, minItemWidth, height)}
                    />
                )
            }
        }
    }
    return gifs ? (
        <Media query={mobile.query}>
            {(matches) => (
                <ThemeProvider theme={{ isInDropdown }}>
                    {rows.length > 0 && !isInDropdown && matches ? (
                        <ListWrapper height={height} marginBottom={marginBottom}>
                            <ListsScrollContainer
                                ref={carouselRef}
                                className={className}
                                onScroll={(e) => onScroll?.(e)}
                            >
                                {rows.map((row, rowIndex) => (
                                    <List key={rowIndex} height={height}>
                                        {row.map(getItem)}
                                        {rowIndex === shorterRowIndex && !isEmpty(rows[0]) && (
                                            <SeeAll height={height}>
                                                <a href="/trending-gifs">See All</a>
                                            </SeeAll>
                                        )}
                                    </List>
                                ))}
                            </ListsScrollContainer>
                        </ListWrapper>
                    ) : (
                        <ListWrapper height={height} marginBottom={marginBottom}>
                            <List
                                ref={carouselRef}
                                className={className}
                                onScroll={(e) => onScroll?.(e)}
                                height={height}
                            >
                                {gifs.map(getItem)}
                            </List>
                        </ListWrapper>
                    )}
                </ThemeProvider>
            )}
        </Media>
    ) : null
}

export default ScrollCarousel
