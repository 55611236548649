import * as colors from '@giphy/colors'
import { uniq } from 'lodash'
import styled from 'styled-components'
import Empty from './empty'
import Placeholder from './placeholder'
import SuggestedTag, { TagSuggestion } from './suggested-tag'

const TagsWrapper = styled.ul`
    display: block;
    text-align: left;
    padding: 0px 10px;
    margin-bottom: 12px;
`

const TagsWrapperVertical = styled.ul`
    padding: 0 6px;

    li {
        margin-right: 0px;
        display: flex;
        margin-bottom: 6px;

        span:first-child {
            width: 100%;
        }
    }
`

const ClickDescriptionWrapper = styled.div`
    padding: 15px 15px 8px 15px;
    color: ${colors.giphyLightGrey};
    font-size: 14px;
    line-height: normal;
    font-weight: normal;

    i {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
    }
`

const ClickToAdd = styled.b`
    font-weight: bold;
`

type Props = {
    suggestedTags: TagSuggestion[]
    onAdd: (tag: TagSuggestion) => void
    onDelete: (tag: TagSuggestion) => void
    onSeen: (tag: TagSuggestion) => void
    isVertical: boolean
    lastEnteredTags: string[]
    maxTagsDisplayed: number
}

const SuggestedTagsList = ({
    suggestedTags,
    onAdd,
    onDelete,
    onSeen,
    isVertical,
    lastEnteredTags,
    maxTagsDisplayed,
}: Props) => {
    const Tags = isVertical ? TagsWrapperVertical : TagsWrapper
    return (
        <>
            {suggestedTags.length > 0 ? (
                <>
                    <Tags>
                        {uniq(suggestedTags)
                            .slice(0, maxTagsDisplayed)
                            .map((tagSuggestion) => (
                                <SuggestedTag
                                    tagSuggestion={tagSuggestion}
                                    onAdd={onAdd}
                                    onDelete={onDelete}
                                    onSeen={onSeen}
                                    key={tagSuggestion.name}
                                    isVertical={isVertical}
                                />
                            ))}
                    </Tags>
                    <ClickDescriptionWrapper>
                        <span>
                            <ClickToAdd>Click tag to add</ClickToAdd> or click <i className="ss-icon ss-delete" /> to
                            mark as irrelevant.
                        </span>
                    </ClickDescriptionWrapper>
                </>
            ) : (
                <>{lastEnteredTags.length > 0 ? <Empty /> : <Placeholder isFetching={false} />}</>
            )}
        </>
    )
}

export default SuggestedTagsList
