import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { Component } from 'react'
import Rating from 'shared/components/rating'
import css from './rating.css'

/**
 * GIF Detail - Rating: Controls the GIF
 * rating toggles in the Edit Panel.
 *
 * @class EditPanelRating
 */
export default class extends Component {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        allowUnrated: PropTypes.bool,
    }
    render() {
        const { className, value, onChange, allowUnrated } = this.props
        return (
            <div className={`${className}`}>
                <span className={css.label}>Rating</span>
                <Rating
                    key={value}
                    className={css.ratings}
                    value={value}
                    onChange={onChange}
                    allowUnrated={allowUnrated}
                />
            </div>
        )
    }
}
