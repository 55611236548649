import styled, { css } from 'styled-components'
import { ControlSize } from 'types'
import { borderRadiusFromControlSize, colorToRgba, heightFromControlSize } from 'utils'
import MenuComponent from './menu'
import OptionsComponent from './options'

export const Container = styled.div<{ $background: string; $disabled: boolean; $size: ControlSize }>`
    background: ${({ $background }) => $background};
    border-radius: ${({ $size }) => borderRadiusFromControlSize($size, true)}px;
    position: relative;

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`

export const Dim = styled.div<{ $background: string; $size: ControlSize }>`
    border-bottom-right-radius: inherit;
    border-top-right-radius: inherit;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    ${({ $background, $size }) => css`
        background: ${`linear-gradient(90deg, ${colorToRgba($background, 0)}, ${colorToRgba(
            $background,
            1
        )}, ${colorToRgba($background, 1)})`};
        width: ${heightFromControlSize($size) * 2}px;
    `}
`

export const Menu = styled(MenuComponent)`
    position: absolute;
    right: 0;
    top: 0;
`

export const Options = styled(OptionsComponent)<{
    $size: ControlSize
    $truncated: boolean
}>`
    border-radius: inherit;
    height: 100%;
    overflow: hidden;
    width: 100%;

    ${({ $size, $truncated }) =>
        $truncated &&
        css`
            position: relative;

            > button:last-child {
                margin-right: ${heightFromControlSize($size)}px;
            }
        `}
`
