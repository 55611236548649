import styled, { css } from 'styled-components'
import { borderRadiusFromControlSize } from 'utils'
import BackgroundComponent from './background'
import MoverComponent from './mover'
import RotatorComponent from './rotator'
import ScalerComponent from './scaler'

export const Ellipsis = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    left: 100%;
    position: absolute;
    top: 0;
    width: 25px;

    svg {
        display: block;
        height: 22px;
    }
`

export const Mover = styled(MoverComponent)`
    left: 0;
    position: absolute;
    top: 0;
`

export const Scaler = styled(ScalerComponent)`
    bottom: -2px;
    position: absolute;
    right: -2px;
`

export const Rotator = styled(RotatorComponent)`
    bottom: 100%;
    left: 50%;
    margin-left: -9px;
    position: absolute;
`

export const Content = styled.div`
    border-radius: inherit;
    height: 100%;
    position: relative;
    width: 100%;
`

export const Background = styled(BackgroundComponent)`
    border-radius: ${borderRadiusFromControlSize('medium')}px;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
`

export const Container = styled.div<{ focused: boolean }>`
    position: relative;
    transition: box-shadow 0.15s ease-out;

    &:hover ${Background} {
        opacity: 1;
    }

    ${({ focused }) =>
        focused
            ? css`
                  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
                  z-index: 100;

                  ${Background} {
                      opacity: 1;
                  }

                  ${Rotator}, ${Scaler}, ${Ellipsis} {
                      opacity: 1;
                      transform: scale(1);
                      transition: opacity 0.2s ease-out, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                  }
              `
            : css`
                  ${Background} {
                      opacity: 0;
                      transition: opacity 0.15s ease-out;
                  }

                  ${Rotator}, ${Scaler}, ${Ellipsis} {
                      opacity: 0;
                      transform: scale(0);
                      transition: opacity 0.15s ease-out;
                  }
              `}
`
