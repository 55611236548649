import {
    DndContext,
    DndContextProps,
    PointerActivationConstraint,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { ReactNode } from 'react'

export interface DraggableContextProps extends DndContextProps {
    activationConstraint?: PointerActivationConstraint
}

interface Props extends DraggableContextProps {
    children?: ReactNode
}

const DraggableContext = ({ activationConstraint, children, ...contextProps }: Props) => {
    const pointerSensor = useSensor(PointerSensor, { activationConstraint })
    const sensors = useSensors(pointerSensor)

    return (
        <DndContext sensors={sensors} {...contextProps}>
            {children}
        </DndContext>
    )
}

export default DraggableContext
