import { Fragment, useState, useEffect } from 'react'
import SearchBar from 'modules/search-bar/components/search-bar'
import withWindowScroll from 'shared/components/gif-grid/with-window-scroll'
import Login from 'shared/components/navbar-dropdown'
import Message from 'shared/components/message/flash-message'
import useWindowSize from 'shared/hooks/window-size'
import styled from 'styled-components'
import CreationButtons from './creation-buttons'
import FloatingLinks from './floating-links'
import GlobalMessageManager from './global-message-manager'
import Menu from './menu'
import PromoMessages from './promo-messages'
import { NavPlaceholderSearch, NavPlaceholder, HeaderWrapper, HeaderContainer, SearchbarWrapper, Searchbar } from './sc'
import _Logo from 'ui/src/components/logo'

const STICKY_NAV_TOGGLE_POSITION = 62

export const Container = styled.div<{ isSearch?: boolean }>`
    margin-left: auto;
    margin-right: auto;
    width: 1040px;
    display: flex;
    justify-content: space-between;
`

const Logo = styled(_Logo)`
    width: 169.11px;
    margin-top: 13px;
`

const SearchbarContainer = styled(Searchbar)`
    margin-left: auto;
    margin-right: auto;
    width: 1040px;
`

const SearchWithWindowHeight = ({ searchTerm }) => {
    const { innerHeight } = useWindowSize()
    return <SearchBar isDesktop appHeight={innerHeight} isFixed searchTerm={searchTerm} />
}

type Props = {
    showSearch: boolean
    showBrowse: boolean
    searchTerm: string
    adsEnabled?: boolean
    hideLogo?: boolean
}

const Header = ({ showSearch, showBrowse, searchTerm, adsEnabled = false, hideLogo = false }: Props) => {
    const [isSticky, setIsSticky] = useState<boolean>(false)
    useEffect(() => {
        if (window.scrollY >= STICKY_NAV_TOGGLE_POSITION) {
            setIsSticky(true)
        } else {
            setIsSticky(false)
        }
    }, [window.scrollY])
    return (
        <Fragment>
            <GlobalMessageManager />
            <Message isDesktop />
            <PromoMessages />
            {showSearch ? <NavPlaceholderSearch /> : <NavPlaceholder />}
            <HeaderWrapper adsEnabled={adsEnabled} isSticky={showSearch && isSticky}>
                <HeaderContainer id="header">
                    <Container itemScope itemType="http://schema.org/Organization">
                        {!hideLogo && <Logo />}
                        {showBrowse && <Menu />}
                        {showBrowse && <CreationButtons />}
                        <Login isSupercut={false} />
                    </Container>
                </HeaderContainer>
                {showSearch && (
                    <SearchbarWrapper isSticky={isSticky}>
                        <SearchbarContainer id="searchbar">
                            <SearchWithWindowHeight searchTerm={searchTerm} />
                        </SearchbarContainer>
                    </SearchbarWrapper>
                )}
            </HeaderWrapper>
            <FloatingLinks />
        </Fragment>
    )
}

export default withWindowScroll(20)(Header)
