import { IRendition } from '@giphy/js-types'
import { createContext } from 'react'
import { SessionProps, VideoContextAPI, VideoContextState } from './context'

type UIProps = {
    isSmall: boolean
    dimensions: IRendition
    playerElement?: HTMLElement
    noShare: boolean
    noCutout: boolean
    showAttribution: boolean
}
export type VideoContextProps = {} & VideoContextState & VideoContextAPI & SessionProps & UIProps

const VideoContext = createContext({
    dimensions: {},
} as VideoContextProps)

export default VideoContext
