import { Children, useEffect, useState, useRef, TouchEvent } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    overflow: hidden;
    padding: 0.5em 0;
`

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const Content = styled.div`
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    left: 50%;
    top: 0;
    transition: transform 0.3s ease-out;
`

const Item = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0 0.6em;
    transform: translateX(-50%);
`

const Pagination = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 500px;
    padding: 1em 30px;
`

const PaginationItem = styled.div`
    cursor: pointer;
    margin: 0 0.4em;
    max-width: 2em;
    padding: 1em 0;
    transition: opacity 0.2s ease-out;
    user-select: none;
    width: 100%;

    &:after {
        background: #fff;
        content: '';
        height: 2px;
        display: block;
        width: 100%;
    }
`

type Props = {
    children: any
    onMeasured?: (height: number) => void
}

const AboutPageCarousel = ({ children, onMeasured }: Props) => {
    const [currentItem, setCurrentItem] = useState<number>(0)
    const [wrapperHeight, setWrapperHeight] = useState<number>(0)
    const contentRef = useRef<HTMLDivElement | null>(null)
    const swipeBaseRef = useRef<number>(0)
    const swipePeekRef = useRef<number>(0)

    useEffect(() => {
        if (!contentRef.current || !children.length) return

        const itemNodes: NodeList = contentRef.current.childNodes
        let tallestItemHeight: number = 0
        itemNodes.forEach((node: HTMLElement) => {
            const nodeHeight: number = node.offsetHeight
            if (nodeHeight > tallestItemHeight) {
                tallestItemHeight = nodeHeight
            }
        })
        setWrapperHeight(tallestItemHeight)
        onMeasured?.(tallestItemHeight)
    }, [children, contentRef])

    const snapToItem = (index: number) => {
        if (!contentRef.current) return
        contentRef.current.style.transform = `translate3d(${-(index / children.length) * 100}%, 0, 0)`
        contentRef.current.style.transitionDuration = ''
        setCurrentItem(index)
    }

    const onTouchStart = (e: TouchEvent) => {
        swipeBaseRef.current = e.touches[0].pageX
    }

    const onTouchMove = (e: TouchEvent) => {
        if (!contentRef.current) return
        const swipeDistance: number = e.touches[0].pageX - (swipeBaseRef.current || 0)
        if (Math.abs(swipeDistance) < 10) return
        const baseX: number = -(currentItem / children.length)
        const swipePeek: number = (swipeDistance / window.innerWidth) * 0.25
        swipePeekRef.current = swipeDistance
        contentRef.current.style.transform = `translate3d(${(baseX + swipePeek) * 100}%, 0, 0)`
        contentRef.current.style.transitionDuration = '0s'
    }

    const onTouchEnd = () => {
        if (!swipePeekRef.current) return
        if (Math.abs(swipePeekRef.current) >= 100) {
            const nextItem: number = currentItem + (swipePeekRef.current < 0 ? 1 : -1)
            snapToItem(Math.max(0, Math.min(children.length - 1, nextItem)))
        } else {
            snapToItem(currentItem)
        }
    }

    return (
        <Container>
            <Wrapper
                style={{
                    height: wrapperHeight,
                }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <Content ref={contentRef}>
                    {Children.map(children, (child) => (
                        <Item>{child}</Item>
                    ))}
                </Content>
            </Wrapper>
            <Pagination>
                {Children.map(children, (_, i) => (
                    <PaginationItem
                        key={`carousel-pagination-${i}`}
                        style={{ opacity: i === currentItem ? 1 : 0.3 }}
                        onClick={() => snapToItem(i)}
                    />
                ))}
            </Pagination>
        </Container>
    )
}

export default AboutPageCarousel
