import { FC, SVGProps } from 'react'

const VisibilityOffIcon: FC<SVGProps<SVGSVGElement>> = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Artboard</title>
            <defs>
                <polygon id="VisibilityOffIcon__path-1" points="0 0 13.3221 0 13.3221 8.104 0 8.104"></polygon>
            </defs>
            <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-8" transform="translate(1.000000, 5.000000)">
                    <g id="Group-6" transform="translate(1.026279, 0.366025)">
                        <path
                            d="M6.1465,4.2 C6.2795,3.996 6.4305,3.8 6.6135,3.616 C7.2735,2.958 8.0685,2.628 9.0005,2.628 C9.9255,2.628 10.7195,2.959 11.3815,3.621 C12.0435,4.283 12.3755,5.077 12.3755,6.003 C12.3755,6.579 12.2335,7.096 11.9815,7.569 L15.0745,9.355 C15.4285,9.054 15.7515,8.764 16.0095,8.503 C16.3695,8.138 16.7185,7.753 17.0545,7.352 C17.3925,6.95 17.6105,6.68 17.7095,6.544 C17.8095,6.405 17.8855,6.296 17.9385,6.214 C18.0205,6.073 18.0205,5.932 17.9385,5.792 L17.8945,5.73 L17.6665,5.414 C17.5195,5.203 17.3005,4.929 17.0115,4.593 C16.7205,4.255 16.3805,3.884 15.9925,3.475 C15.6015,3.069 15.1445,2.661 14.6215,2.255 C14.0955,1.846 13.5495,1.475 12.9815,1.137 C12.4135,0.802 11.7805,0.527 11.0835,0.316 C10.3855,0.105 9.6915,0 9.0005,0 C8.3085,0 7.6115,0.11 6.9085,0.329 C6.2055,0.55 5.5755,0.819 5.0185,1.137 C4.4615,1.458 3.9085,1.84 3.3575,2.285 C3.2795,2.348 3.2165,2.405 3.1425,2.467 L6.1465,4.2 Z"
                            id="Fill-1"
                            fill="#818181"
                        ></path>
                        <g id="Group-5" transform="translate(0.000000, 3.901300)">
                            <mask id="mask-2" fill="white">
                                <use href="#VisibilityOffIcon__path-1"></use>
                            </mask>
                            <g id="Clip-4"></g>
                            <path
                                d="M10.4971,5.12 C10.0431,5.349 9.5481,5.476 9.0001,5.476 C8.0621,5.476 7.2661,5.148 6.6091,4.492 C6.0061,3.889 5.6931,3.16 5.6441,2.319 L1.6281,0 C1.3981,0.247 1.1651,0.49 0.9451,0.752 C0.6071,1.154 0.3901,1.424 0.2901,1.56 C0.1901,1.699 0.1141,1.808 0.0611,1.891 C-0.0209,2.031 -0.0209,2.172 0.0611,2.312 L0.1931,2.506 C0.2871,2.641 0.4251,2.819 0.6061,3.042 C0.7881,3.265 1.0041,3.521 1.2521,3.81 C1.5021,4.101 1.7931,4.407 2.1271,4.729 C2.4611,5.052 2.8151,5.373 3.1901,5.691 C3.5651,6.012 3.9801,6.316 4.4341,6.605 C4.8891,6.896 5.3521,7.152 5.8271,7.375 C6.3021,7.598 6.8141,7.775 7.3651,7.906 C7.9161,8.039 8.4611,8.104 9.0001,8.104 C9.6911,8.104 10.3891,7.994 11.0921,7.775 C11.7951,7.555 12.4251,7.285 12.9811,6.967 C13.0961,6.901 13.2081,6.822 13.3221,6.751 L10.4971,5.12 Z"
                                id="Fill-3"
                                fill="#818181"
                                mask="url(#mask-2)"
                            ></path>
                        </g>
                    </g>
                    <rect
                        id="Rectangle"
                        fill="#828282"
                        transform="translate(10.026279, 6.366025) rotate(-60.000000) translate(-10.026279, -6.366025) "
                        x="9.02627944"
                        y="-4.6339746"
                        width="2"
                        height="22"
                    ></rect>
                </g>
            </g>
        </svg>
    )
}

export default VisibilityOffIcon
