exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sbwXHA1t1NpK1k5gv0Bt9 {\n    font-size: 16px;\n    font-weight: 900;\n    line-height: 1.1;\n    width: 100%;\n}\n.sbwXHA1t1NpK1k5gv0Bt9 a {\n        color: inherit;\n    }\n\n/* this is for mobile only, line-clamp has limited support */\n._2kmH2ZwetpUSY01KtESrEw {\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.egeecXcD3ucUqe4ygxn9S {\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    width: 50px;\n    height: 50px;\n    position: absolute;\n    left: 0;\n    top: 0;\n    flex: 0;\n}\n\n.EOo4OI8AMJtzGy4Ynpeb8 {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    min-height: 50px;\n    padding-left: 60px;\n    position: relative;\n    text-align: left;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n", ""]);

// exports
exports.locals = {
	"avatarSize": "50px",
	"username": "sbwXHA1t1NpK1k5gv0Bt9",
	"usernameTruncate": "_2kmH2ZwetpUSY01KtESrEw sbwXHA1t1NpK1k5gv0Bt9",
	"avatar": "egeecXcD3ucUqe4ygxn9S",
	"header": "EOo4OI8AMJtzGy4Ynpeb8"
};