import { giphyDarkGrey, giphyLightestGrey } from '@giphy/colors'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import Dropdown from 'shared/components/form/ui/dropdown'
import styled, { ThemeProvider } from 'styled-components'
import * as themes from '../theme'

const Container = styled.div`
    width: ${(props) => props.width};
    box-sizing: border-box;
    font-weight: normal;
    height: ${(props) => props.height};
    line-height: 30px;
    min-width: 165px;
    min-width: ${(props) => props.theme.container.minWidth};
    padding-right: 30px;
    position: relative;
`

const Button = styled.div`
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 0 10px;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    background: ${giphyDarkGrey};
    color: ${giphyLightestGrey};
    white-space: nowrap;
`

const DropdownDisplay = styled(Button)`
    color: ${(props) => props.theme.dropdownDisplay.color};
    background: ${(props) => props.theme.dropdownDisplay.background};
    text-align: ${(props) => props.theme.dropdownDisplay.textAlign};
    padding: ${(props) => props.theme.dropdownDisplay.padding};
    height: ${(props) => props.height};
    display: flex;
    align-items: center;
`

const DropdownContainer = styled.div`
    margin: ${(props) => (props.extendUp ? '0px 0px 12px 0px' : '12px 0px 0px 0px')};
    position: absolute;
    ${(props) => (!props.center ? 'right: 0;' : null)}
    top: ${(props) => (props.extendUp ? null : '100%')};
    bottom: ${(props) => (props.extendUp ? '100%' : null)};
    ${(props) => !!props.width && `width: ${props.width}px;`}
`

export const Arrow = styled.div`
    background: ${(props) => props.theme.arrow.background};
    color: ${(props) => props.theme.arrow.color};
    font-size: 12px;
    height: ${(props) => props.height || `30px`};
    line-height: 32px;
    position: absolute;
    right: 0;
    text-align: center;
    text-indent: 2px;
    top: 0;
    width: 30px;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

const DropdownLabel = styled.span`
    font-size: ${(props) => (props.labelSize ? props.labelSize : '14px')};
    font-weight: ${(props) => (props.labelWeight ? props.labelWeight : null)};
`
const MOUSE_LEAVE_TIMEOUT = 2000
export default class SelectDropdown extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        placeholder: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.string,
        extendUp: PropTypes.bool,
        labelSize: PropTypes.string,
        themeId: PropTypes.string,
        onOpenChange: PropTypes.func,
        center: PropTypes.bool,
        nipX: PropTypes.string,
    }

    static defaultProps = {
        label: '',
        placeholder: '',
        extendUp: false,
        themeId: 'light',
        onOpenChange: () => {},
        center: false,
        nipX: 'calc(100% - 15px)',
        height: '30px',
    }

    state = {
        isOpen: false,
    }

    toggleOpen = () => {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    componentDidUpdate(prevProps, prevState) {
        const { isOpen } = this.state
        if (isOpen !== prevState.isOpen) {
            const { onOpenChange } = this.props
            onOpenChange(isOpen)
            if (isOpen) {
                document.addEventListener('click', this.onDocumentClick, false)
            } else {
                document.removeEventListener('click', this.onDocumentClick)
            }
        }
    }

    onDocumentClick = ({ target }) => {
        // close the drop down if clicked outside
        if (!this.container.contains(target)) {
            this.setState({ isOpen: false })
        }
    }

    onMouseLeave = () => {
        // close the drop down after a time out of MOUSE_LEAVE_TIMEOUT
        this.onLeaveTimeout = setTimeout(() => this.setState({ isOpen: false }), MOUSE_LEAVE_TIMEOUT)
    }

    componentWillUnmount() {
        clearTimeout(this.onLeaveTimeout)
        document.removeEventListener('click', this.onDocumentClick)
    }

    onMouseOver = () => clearTimeout(this.onLeaveTimeout)

    render() {
        const {
            children,
            label,
            padding,
            labelSize,
            labelWeight,
            extendUp,
            themeId,
            placeholder,
            width,
            isDark,
            LabelComponent,
            className,
            center,
            nipX,
            height,
        } = this.props
        const { isOpen } = this.state
        const activeTheme = themes[themeId]
        const Label = LabelComponent || DropdownLabel
        return (
            <ThemeProvider theme={activeTheme}>
                <Container
                    className={className}
                    onMouseLeave={this.onMouseLeave}
                    onMouseOver={this.onMouseOver}
                    ref={(c) => (this.container = c)}
                    width={width}
                    height={height}
                >
                    <DropdownDisplay isDark={isDark} onClick={this.toggleOpen} padding={padding} height={height}>
                        <Label labelSize={labelSize} labelWeight={labelWeight}>
                            {label || placeholder}
                        </Label>
                        <Arrow
                            height={height}
                            className={
                                extendUp
                                    ? isOpen
                                        ? 'ss-dropdown'
                                        : 'ss-directup'
                                    : isOpen
                                    ? 'ss-directup'
                                    : 'ss-dropdown '
                            }
                        />
                    </DropdownDisplay>
                    {isOpen && (
                        <DropdownContainer center={center} extendUp={extendUp} width={width}>
                            <Dropdown isExtendUp={extendUp} nipX={nipX} themeId={themeId}>
                                {children}
                            </Dropdown>
                        </DropdownContainer>
                    )}
                </Container>
            </ThemeProvider>
        )
    }
}
