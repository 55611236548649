import { useHistory } from 'react-router'
import BulkEditModeContext from 'shared/contexts/bulk-edit-mode'
import { MouseEvent, SyntheticEvent, useCallback, useContext, useState } from 'react'
import ModalContext from 'shared/contexts/modal'
import { relativeUrl } from 'shared/util/url'
import { useCanBulkEdit } from 'shared/hooks/use-permissions'
import { IGif } from '@giphy/js-types'
import { uniq, without } from 'lodash'

type Gif = object
type GifId = string | number

type Props = {
    gifs: GifId[]
    iSelectionMode?: boolean
    standaloneSelectionMode?: boolean
    noRedux?: boolean
    sendGAGifClick?: (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => void
    onGifGAClick?: (gif: Gif, index?: number) => void
}

export const useBulkEditGifClick = ({
    gifs,
    iSelectionMode,
    standaloneSelectionMode,
    sendGAGifClick = () => {},
    onGifGAClick = () => {},
}: Props) => {
    const {
        isBulkEditMode,
        setBulkEditGifIds: setSelectedGifs,
        bulkEditGifIds: selectedGifs,
    } = useContext(BulkEditModeContext)
    const history = useHistory()
    const [lastSelected, setLastSelected] = useState<GifId | undefined>()
    const allowBulkEdit = useCanBulkEdit()
    const { openBulkEditPanel } = useContext(ModalContext)
    const onGifClick = useCallback(
        (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
            onGifGAClick
                ? onGifGAClick(
                      gif,
                      gifs.findIndex((g) => g == gif.id)
                  )
                : sendGAGifClick(gif, e)
            e.preventDefault()
            const { url } = gif
            const mouseEvent = e as unknown as MouseEvent

            if (!iSelectionMode) {
                if (mouseEvent.metaKey) {
                    window.open(url, '_blank')
                } else {
                    location.href = relativeUrl(gif.url)
                }
            } else {
                // Selection mode stuff
                if (!standaloneSelectionMode) {
                    // standaloneSelectionMode means no bulk editor
                    openBulkEditPanel(allowBulkEdit)
                }
                const { id } = gif
                if (mouseEvent.metaKey) {
                    if (isBulkEditMode || standaloneSelectionMode) {
                        if (selectedGifs.indexOf(id) !== -1) {
                            setSelectedGifs(without(selectedGifs, id))
                        } else {
                            setSelectedGifs([...selectedGifs, id])
                        }
                    } else {
                        window.open(url, '_blank')
                    }
                } else if (mouseEvent.shiftKey) {
                    const startIndex = lastSelected ? gifs.indexOf(lastSelected) : 0
                    const endIndex = gifs.indexOf(gif.id)
                    setSelectedGifs(
                        // TODO I think this is right...
                        uniq([
                            ...selectedGifs,
                            ...gifs.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1),
                        ])
                    )
                } else {
                    setSelectedGifs([id])
                }
                setLastSelected(id)
            }
        },
        [
            // exhaustive deps suck
            allowBulkEdit,
            gifs,
            history,
            iSelectionMode,
            lastSelected,
            openBulkEditPanel,
            selectedGifs,
            sendGAGifClick,
            setSelectedGifs,
            standaloneSelectionMode,
        ]
    )

    return onGifClick
}
