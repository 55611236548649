import { RefObject, SyntheticEvent, useContext, useEffect, useMemo } from 'react'
import { useNodeRef } from 'utils'
import { PortalTooltipContext } from '../portal-tooltip/context'
import { TooltipData } from '../types'

export interface TooltipProps extends TooltipData {
    triggerType?: 'all' | 'click' | 'contextmenu'
}

type TooltipHookProps = {
    isOpen: boolean
    listeners: Record<string, Function>
    node: RefObject<HTMLElement>
    closeTooltip: () => void
    setNodeRef: (element: HTMLElement | null) => void
}

const useTooltip = ({ id, triggerType = 'click', ...tooltipData }: TooltipProps): TooltipHookProps => {
    const { activeId, tooltips, closeTooltip, openTooltip, openTooltipAtCoordinates } = useContext(PortalTooltipContext)
    const [node, setNodeRef] = useNodeRef()

    const clickListeners = useMemo(
        () => ({
            onClick: (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
                e.preventDefault()
                openTooltip(node.current || e.currentTarget, id)
            },
        }),
        [id, node, openTooltip]
    )

    const contextMenuListeners = useMemo(
        () => ({
            onContextMenu: (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
                e.preventDefault()
                openTooltipAtCoordinates(
                    {
                        x: e.nativeEvent.pageX,
                        y: e.nativeEvent.pageY,
                    },
                    id
                )
            },
        }),
        [id, node, openTooltipAtCoordinates]
    )

    const listeners = useMemo(() => {
        switch (triggerType) {
            case 'all':
                return { ...clickListeners, ...contextMenuListeners }
            case 'click':
                return clickListeners
            case 'contextmenu':
                return contextMenuListeners
        }
    }, [triggerType])

    useEffect(() => {
        if (!tooltips) return

        tooltips.set(id, {
            ...tooltipData,
            id,
        })

        return () => {
            const data = tooltips.get(id)

            if (data) {
                tooltips.delete(id)
            }
        }
    }, [id, tooltipData, tooltips])

    return {
        isOpen: id === activeId,
        listeners,
        node,
        closeTooltip,
        setNodeRef,
    }
}

export default useTooltip
