'use client'
import { giphyWhite } from '@giphy/colors'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

// Chris says this is it, universal font size for section headers
const SECTION_HEADER_FONT_SIZE = 15

const font = css`
    font-size: ${SECTION_HEADER_FONT_SIZE}px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
`
const containerCss = css`
    ${font}
    -webkit-font-smoothing: antialiased;
    color: ${giphyWhite};
    padding: 0 8px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 6px;
    h2 {
        ${font}
    }
`

const H2Container = styled.h2<{ onClick?: any }>`
    ${containerCss}
`

const LinkContainer = styled.a`
    ${containerCss}
    cursor: pointer;
    text-decoration: none;
    justify-content: flex-start;
`

const Arrow = styled.div`
    font-size: 11px;
    margin-left: 3px;
    height: 13px;
    display: inline-block;
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
`

const H = styled.h2``

type Props = {
    label: string | ReactNode
    url?: string
    className?: string
    seeAllLabel?: string
    // naming this `as` can mess up types
    tag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}
/**
 * @deprecated use Next.js @components/section-header
 */
export const SectionHeader = ({ label, url = '', className = '', tag = 'h2' }: Props) => {
    return url ? (
        <LinkContainer href={url} className={className}>
            <H as={tag}>
                {label} <Arrow className="ss-icon ss-navigateright" />
            </H>
        </LinkContainer>
    ) : (
        <H2Container className={className} as={tag}>
            {label}
        </H2Container>
    )
}

export const SectionHeaderWithSeeAll = ({ label, url = '', className = '', seeAllLabel = 'See All' }: Props) => {
    return (
        <Title className={className}>
            <SectionHeader label={label} />
            <SectionHeader label={seeAllLabel} url={url} />
        </Title>
    )
}

export default SectionHeader
