import { GET } from 'shared/util/fetch-methods'
import { getResults } from '.'

type Result = {}
type IndustryResult = {
    id: number
    label: string
}
type GifDetailResult = {
    other_industry_id: number
    industries: IndustryResult[]
}
let requestMap: { [key: string]: Promise<Result> } = {}

function request<T>(url: string) {
    if (!requestMap[url]) {
        requestMap[url] = getResults(url, GET)
    }
    return requestMap[url] as Promise<T>
}

export function getIndustriesByGroup(groupName) {
    return request<{ data: GifDetailResult }>(`api/v3/industries/groups/?group=${groupName}`)
}
