import styled from 'styled-components'
import { IMediumPost } from '../types'
import { NEWS_LINK_COLORS } from '../constants'

const KeepReading = styled.a`
    bottom: 1.5em;
    left: 1.5em;
    position: absolute;

    span {
        font-size: 0.8em;
    }

    i {
        color: #fff;
        display: inline-block;
        font-size: 0.7em;
        margin-left: 0.4em;
    }
`

const Container = styled.div`
    background: #000;
    box-sizing: border-box;
    color: #fff;
    margin: 0 auto;
    max-width: 75vw;
    padding: 7em 1.5em 3em;
    position: relative;
    text-align: left;
    width: 25em;

    ${KeepReading} {
        text-decoration: none;
    }
`

const Thumbnail = styled.div`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 6em;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

const Description = styled.div`
    font-size: 0.8em;
    line-height: 1.5;
    padding: 0.5em 0 1em;
`

type Props = {
    post: IMediumPost
    index: number
    minHeight?: number
}

const AboutPageNewsPost = ({ post, index, minHeight = 0 }: Props) => {
    const descriptionBits: string[] = post.description.split('</p>')
    const linkColor: string = NEWS_LINK_COLORS[index % NEWS_LINK_COLORS.length]
    return (
        <Container style={{ minHeight }}>
            <Thumbnail style={{ backgroundImage: `url(${post.thumbnail})` }} />
            <h3>{post.title}</h3>
            {descriptionBits.length && <Description>{descriptionBits[0].replace(/(<([^>]+)>)/gi, '')}</Description>}
            <KeepReading href={post.link} target="_blank" style={{ color: linkColor }}>
                <span>
                    Keep Reading <i className="ss-navigateright" />
                </span>
            </KeepReading>
        </Container>
    )
}

export default AboutPageNewsPost
