import { RefObject, useEffect, useState } from 'react'

const useIconColor = (ref: RefObject<HTMLUListElement>, defaultColor?: string): string | undefined => {
    const [color, setColor] = useState<string | undefined>()

    useEffect(() => {
        if (!ref.current) return

        if (defaultColor) {
            setColor(defaultColor)
            return
        }

        const callback = () => {
            if (!ref.current) return

            const computedStyles = getComputedStyle(ref.current)
            setColor(computedStyles.getPropertyValue('color'))
        }

        const mutations = new MutationObserver(callback)
        mutations.observe(ref.current, { attributes: true })

        setTimeout(callback, 0)

        return () => {
            mutations.disconnect()
        }
    }, [defaultColor, ref])

    return color
}

export default useIconColor
