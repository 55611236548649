import { LoaderSize } from './types'

export function borderRadiusFromLoaderSize(size: LoaderSize): number {
    switch (size) {
        case 'small':
            return 0
        case 'medium':
            return 1
        case 'large':
            return 2
    }
}

export function heightFromLoaderSize(size: LoaderSize): number {
    switch (size) {
        case 'small':
            return 4
        case 'medium':
            return 6
        case 'large':
            return 10
    }
}
