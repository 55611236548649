import { IGif } from '@giphy/js-types'
import { useContext, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { IChannel } from 'shared/types/channel'
import ModalContext from 'shared/contexts/modal'
import UserContext from 'shared/contexts/user'
import { Modal } from '../modal'
import AddGifsToCollection from './add-gifs-to-collection'
import CollectionEditor from './editor'
import Header from './header'
import { Container, GifSelectorContainer, AddMultipleButton } from './sc'
import { CollectionContext } from 'shared/contexts/add-to-collections-manager'
import { addGifs } from 'shared/api/channels'
import { fitGifToSize } from 'shared/util/sizing'
import CollectionsCabinet from './collections-cabinet'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'

export const GIF_WIDTH = 500
export const GIF_HEIGHT = 573
export const MIN_HEIGHT = 380
const MODAL_TOP = 193
const MODAL_MARGIN_LEFT = -12
const MODAL_HEIGHT = 630
const MODAL_WIDTH = 988

const Overlay = styled.div`
    cursor: pointer;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
`

type ModalProps = {
    onClose: () => void
}

export type NewCollectionProps = {
    userId: number
    parentChannel: IChannel
    gif?: IGif
    redirectOnCreate?: boolean
}

export const NewCollectionModal = ({
    onClose,
    newCollectionProps,
}: ModalProps & {
    newCollectionProps: NewCollectionProps
}) => {
    const { user } = useContext(UserContext)

    useEffect(() => {
        setGADataLayer({
            event: 'create_collection_open',
        })
    }, [])

    const isRootCollection = user.channelId === newCollectionProps.parentChannel.id
    const title = isRootCollection ? 'New Collection' : 'New Sub-Collection'

    return (
        <Modal>
            <Container top={190} height={390}>
                <Header
                    title={title}
                    onClose={() => {
                        setGADataLayer({
                            event: 'create_collection_close',
                        })
                        onClose()
                    }}
                />
                <CollectionEditor
                    newCollectionProps={newCollectionProps}
                    onComplete={() => {
                        setGADataLayer({
                            event: 'create_collection_success',
                            options: {
                                formName: 'create collection',
                            },
                        })
                        onClose()
                    }}
                    title={title}
                />
            </Container>
        </Modal>
    )
}

export const EditCollectionModal = ({
    onClose,
    channel,
}: ModalProps & {
    channel: IChannel
}) => {
    return (
        <Modal>
            <Container height={695}>
                <Header title="Edit Collection" onClose={onClose} />
                <CollectionEditor channel={channel} onComplete={onClose} />
            </Container>
        </Modal>
    )
}

export const ChannelGifSelector = ({
    onClose,
    channelId,
    searchChannelId,
}: ModalProps & {
    channelId: number
    searchChannelId: number
}) => {
    const { channels, setAddGifsToCollectionSuccessMessage } = useContext(CollectionContext)

    const handleComplete = (addedGifs) => {
        if (channels[channelId]) {
            setAddGifsToCollectionSuccessMessage({
                gifs: addedGifs,
                channel: channels[channelId],
            })
        }
        onClose()
    }

    return (
        <Modal>
            <GifSelectorContainer>
                <Header title="Your Channel" onClose={onClose} />
                <AddGifsToCollection
                    channelId={channelId}
                    onComplete={handleComplete}
                    searchChannelId={searchChannelId}
                />
            </GifSelectorContainer>
        </Modal>
    )
}

// This is the Collections Cabinet
type GifSize = { height: number }
type CabinetProps = { channelId?: number } & ModalProps
export const CollectionCabinetModal = ({ onClose, channelId }: CabinetProps) => {
    const {
        channels,
        setActiveChannelId,
        gif,
        setGif,
        setAddGifToCollectionSuccessMessage,
        rootChannelId,
        setAddGifToMultipleCollectionsSuccessMessage,
    } = useContext(CollectionContext)
    const { user } = useContext(UserContext)
    const { openAddGifsToCollectionPanel } = useContext(ModalContext)

    // State for multi select
    const [selectedChannels, setSelectedChannels] = useState<IChannel[]>([])
    const [shiftHeld, setShiftHeld] = useState(false) // Check if is Holde Shift Key

    const downHandler = (evt) => {
        if (evt.key === 'Shift') {
            setShiftHeld(true)
        }
    }

    const upHandler = (evt) => {
        if (evt.key === 'Shift') {
            setShiftHeld(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        window.addEventListener('keyup', upHandler)
        return () => {
            window.removeEventListener('keydown', downHandler)
            window.removeEventListener('keyup', upHandler)
        }
    }, [])

    let gifSize: GifSize = {
        height: MIN_HEIGHT,
    }

    if (gif) {
        gifSize = fitGifToSize(gif, GIF_WIDTH, GIF_HEIGHT) as GifSize
    }

    // Reset variables before close the modal
    const handleClose = () => {
        setActiveChannelId(rootChannelId)
        setGif(undefined)
        onClose()
    }

    const handleAdd = async (channelId: number) => {
        // If shift is selected, multi select:
        if (shiftHeld) {
            const channel = channels[channelId]

            // Check if channel is already selected
            if (selectedChannels.find((channel) => channel.id === channelId)) {
                // If is already selected, unselect
                setSelectedChannels(selectedChannels.filter((channel) => channel.id !== channelId))
            } else {
                // If not, select
                setSelectedChannels([...selectedChannels, channel])
            }

            setActiveChannelId(user.channelId)
            return
        }

        if (gif) {
            setGADataLayer({
                event: 'add_to_collection',
                options: {
                    content: getContentOfGif(gif),
                    creator: getCreatorOfGif(gif),
                },
            })
            await addGifs([
                {
                    gif: gif.id,
                    channel: channelId,
                },
            ])
            const channel = channels[channelId]
            setAddGifToCollectionSuccessMessage({
                channel: channel,
                featuredGif: channel.featured_gif || gif,
            })
            handleClose()
        }
    }

    const addToMultipleChannels = async () => {
        if (gif) {
            for (const selectedChannel of selectedChannels) {
                setGADataLayer({
                    event: 'add_to_collection',
                    options: {
                        content: getContentOfGif(gif),
                        creator: getCreatorOfGif(gif),
                    },
                })
                await addGifs([
                    {
                        gif: gif.id,
                        channel: selectedChannel.id,
                    },
                ])
            }

            setAddGifToMultipleCollectionsSuccessMessage({
                channels: selectedChannels,
                gif,
            })
            handleClose()
        }
    }

    const handleClickChannel = async (channelId: number) => {
        if (gif && !channels[channelId].has_children) {
            handleAdd(channelId)
        } else {
            setActiveChannelId(channelId)
        }
    }

    const handleAddTo = (channelId: number, searchChannelId?: number) => {
        if (gif) {
            handleAdd(channelId)
        } else if (searchChannelId) {
            openAddGifsToCollectionPanel(channelId, searchChannelId)
        }
    }

    const modalHeight = useMemo(() => {
        if (gif && gif.type === 'gif') {
            return gifSize?.height > MIN_HEIGHT ? gifSize.height : MIN_HEIGHT
        }

        if (!gif) {
            return MODAL_HEIGHT
        }
    }, [gif])

    const modalTop = useMemo(() => {
        if (gif && gif.type === 'gif') {
            return MODAL_TOP
        }
    }, [gif])

    const modalMarginLeft = useMemo(() => {
        if (gif && gif.type === 'gif') {
            return MODAL_MARGIN_LEFT
        }
    }, [gif])

    const modalWidth = useMemo(() => {
        if (!gif) {
            return MODAL_WIDTH
        }
    }, [gif])

    useEffect(() => {
        // disable body scrolling
        document.body.style.overflow = 'hidden'

        return () => {
            setActiveChannelId(rootChannelId)
            setGif(undefined)

            // re-enable body scrolling
            document.body.style.overflow = ''
        }
    }, [])

    return (
        <Modal>
            <Container top={modalTop} height={modalHeight} marginLeft={modalMarginLeft} width={modalWidth}>
                <CollectionsCabinet
                    selectedChannelIds={selectedChannels.map((channel) => channel.id)}
                    onClose={handleClose}
                    channelId={channelId}
                    onClickChannel={handleClickChannel}
                    onClickAddTo={handleAddTo}
                />
                {selectedChannels.length > 0 && (
                    <AddMultipleButton onClick={addToMultipleChannels}>Add to Collections</AddMultipleButton>
                )}
            </Container>
            <Overlay onClick={handleClose} />
        </Modal>
    )
}
