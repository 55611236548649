import { giphyBlue, giphyDarkestGrey, giphyDarkGrey, giphyLightGrey, giphyWhite, primaryCTA } from '../css/colors'

const darkDropdown = {
    dropdownTextColor: giphyWhite,
    dropdownBackgroundColor: giphyDarkestGrey,
}

const lightDropdown = {
    dropdownTextColor: giphyDarkGrey,
    dropdownBackgroundColor: giphyWhite,
}

const lightSelectorBar = {
    dropdownDisplay: {
        color: giphyDarkGrey,
        background: giphyWhite,
        textAlign: 'left',
        padding: null,
    },
    arrow: {
        background: primaryCTA,
        color: giphyWhite,
    },
}
const darkSelectorBar = {
    dropdownDisplay: {
        color: giphyLightGrey,
        background: giphyDarkGrey,
        textAlign: 'left',
        padding: null,
    },
    arrow: {
        background: giphyDarkestGrey,
        color: giphyBlue,
    },
}

const transparentSelectorBar = {
    dropdownDisplay: {
        color: giphyWhite,
        background: 'transparent',
        textAlign: 'right',
        padding: '0px 0px 0px 0px',
    },
    arrow: {
        background: 'transparent',
        color: giphyWhite,
    },
}

const iconSelectorBar = {
    dropdownDisplay: {
        color: giphyLightGrey,
        background: 'transparent',
        textAlign: 'left',
        padding: null,
    },
    arrow: {
        background: 'transparent',
        color: giphyBlue,
    },
}

// icon
export const icon = {
    container: {
        minWidth: '0px',
    },
    ...iconSelectorBar,
    ...darkDropdown,
}

// white background transparent selector
export const transparent = {
    container: {
        minWidth: '10px',
    },
    ...transparentSelectorBar,
    ...lightDropdown,
}

export const transparentDark = {
    container: {
        minWidth: '10px',
    },
    ...transparentSelectorBar,
    ...darkDropdown,
}

// dark dropdown dark selector
export const dark = {
    container: {
        minWidth: '150px',
    },
    ...darkSelectorBar,
    ...darkDropdown,
}

// light background dark selector
export const darkLight = {
    container: {
        minWidth: '150px',
    },
    ...darkSelectorBar,
    ...lightDropdown,
}

// light dropdown light selector
export const light = {
    container: {
        minWidth: '150px',
    },
    ...lightSelectorBar,
    ...lightDropdown,
}

// light background dark selector
export const lightDark = {
    container: {
        minWidth: '150px',
    },
    ...lightSelectorBar,
    ...darkDropdown,
}
